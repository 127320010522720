import { handleActions } from "redux-actions";
import Swal from "sweetalert2";
import moment from "moment";
import _ from "lodash";

import app from "../../libs/apiClient";
import swal from "sweetalert2";
import { errorHandler } from "../../common/utility/constants";
import { getCart } from "./reservations";

// Constants
const service = app.service("/api/reservation/");
const serviceRefund = app.service("/api/refund/");

const DATA = "REFUND_DATA";
const LOADER = "REFUND_LOADER";
const QUERY = "REFUND_QUERY";
const LOADER_MODAL = "LOADER_MODAL_REFUND";

// Pure Actions
const setData = (data) => ({
    type: DATA,
    data,
});

export const setQuery = (query) => ({
    type: QUERY,
    query,
});

const setLoader = (loading) => ({
    type: LOADER,
    loading,
});

const setLoaderModal = (loaderModalRefund) => ({
    type: LOADER_MODAL,
    loaderModalRefund
})
// Actions
export const find = (showNotification, id) => (dispatch, getState) => {
    dispatch(setLoader(true));
    showNotification({},false);
    service.get(id).then((data)=>{
        let total = 0;
        if(data.refund)
            total = data.refund.reduce((total, item) => total + item.refundAmount, 0);
        data.totalRefund = total;
        dispatch(setData(data));
    })
    .catch((error)=>{
        let message = ""
        if(typeof(error) === "object")
            if(error.message)
                message = error.message;
            else
                for(let i = 0; i < error.length ; i++)
                    message = message + error[i];
        else message = error;
        if(!message.length)
            message = "there was an error getting the data"
        Swal.fire({
            title: "Error",
            text: `${message}`,
            icon: "error",
            type: "error",
            confirmButton: "OK",
            confirmButtonColor: "#D50032",
        });
    }).finally(()=>{
        dispatch(setLoader(false));
    })
};

export const voidCart = (id, values, closeModal = () => {}) => (dispatch) => {
    const params = {
        action: "VOID_CART",
        noteCanceled: values.noteCanceled,
    };
    dispatch(setLoaderModal(true));
    app.service("/api/cart")
        .patch(id, params)
        .then((response) => {
            console.log(response);
            Swal.fire({
                type: "success",
                title: "SUCCESS",
                text: "Transaction Voided",
            }).then(() => {
                closeModal();
                dispatch(getCart(id));
            });
        })
        .catch((error) => {
            dispatch(errorHandler(error));
        })
        .finally(() => {
            dispatch(setLoaderModal(false));
        });
};

export const postRefund = (id, closeModal) => async (dispatch, getStore) =>{
    
    dispatch(setLoaderModal(true));
    const { values = {} } = getStore().form.refundMountForm || {};

    const isCancel = values.cancel == "cancel";

    let refundData = {
        reservation: id,
        refundAmount: values.amount,
        agentNotes: values.agent_notes
    }

    const params = {
        action: `${isCancel ? 'CANCEL_AND_REFUND' : 'REFUND_TRANSACTION'}`,
        refundData,
    }

    app.service('/api/authorize-charge').patch(id, params).then((response) => {
        dispatch(setLoaderModal(true));
        Swal.fire({
            title: "Success",
            text: isCancel ? 'The trip has been canceled' : `The amount has been refunded`,
            type: "success",
            confirmButton: "OK",
            confirmButtonColor: "#D50032",
        }).then((result) => {
            closeModal()
            dispatch(setLoaderModal(false))
        })
    }).catch((err) => {
        if(err.data && err.data.warningCancel){
            console.error(err);
            Swal.fire({
                type: "warning",
                title: "Warning",
                text: err.message || "Error on trip cancel",
                focusConfirm: false,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonColor: "#D50032",
                cancelButtonColor: "#6C757D",
                cancelButtonText: "Cancel",
                confirmButtonText: "Continue"
            }).then(({ value }) => {
                if(value){
                    app.service('/api/authorize-charge').patch(id, { ...params, confirmCancel: true }).then(result=>{
                        Swal.fire({
                            title: "Success",
                            text: `The cart has been canceled`,
                            type: "success",
                            confirmButton: "OK",
                            confirmButtonColor: "#D50032",
                        }).then((result) => {
                            dispatch(setLoaderModal(false))
                            closeModal()
                        }).catch(err=>{
                            dispatch(errorHandler(err));
                            dispatch(setLoaderModal(false))
                        }
                        )
                    })
                } else {
                    dispatch(setLoaderModal(false))
                }
            })
        } else {
            dispatch(setLoaderModal(false))
            dispatch(errorHandler(err));
        }
    })
    // .finally(()=>{
    //     dispatch(setLoaderModal(false))
    // })
};

const onlyCancelTrip = () => (dispatch, getStore) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#D50032',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if(result.value){
            dispatch(setLoader(true));
            const { data } = getStore().refund;
            let { refund } = data;

            let new_data = {
                reservation: data._id,
                refundAmount: (data.transactionAmount - data.totalRefund),
                agentNotes: "Void transaction and trip cancelled"
            }

            if(refund) refund.unshift(new_data)
            else refund = [new_data]

            const params = {
                action: 'CANCEL_AND_REFUND',
                refund
            }
            service.patch(data._id, params).then((response) => {
                dispatch(setData(response))
                Swal.fire({
                    title: "Success",
                    text: `Trip cancelled!`,
                    type: "success",
                    confirmButton: "OK",
                    confirmButtonColor: "#D50032",
                }).then((result) => {
                    dispatch(find(()=>{}, data._id));
                })
            }).catch((err) => {
                console.log("error on cancel trip", err);
                Swal.fire({
                    type: "error",
                    title: "Error",
                    text: `${err}`,
                    confirmButton: "OK",
                    confirmButtonColor: "#D50032",
                });
            }).finally(()=>{
                dispatch(setLoader(false));
            })
        }
    })
}

const clear = () => (dispatch, getStore) => {
    dispatch(setData({}));
}

export const actions = {
    find,
    clear,
    voidCart,
    postRefund,
    onlyCancelTrip,
};

// Reducers
const reducers = {
    [LOADER]: (state, { loading }) => ({ ...state, loading }),
    [QUERY]: (state, { query }) => ({ ...state, query }),
    [DATA]: (state, { data }) => ({ ...state, data }),
    [LOADER_MODAL]: (state, { loaderModalRefund }) => ({ ...state, loaderModalRefund}),
};

export const initialState = {
    data: { },
    page: 1,
    query: {},
    loading: false,
    loaderModalRefund: false,
};

export default handleActions(reducers, initialState);
