import { connect } from "react-redux";
import Update from "./RolesUpdate";
import { change } from 'redux-form';
import { actions } from "../../../../redux/modules/roles";

const mstp = (state) => ({
    ...state.roles,
    userInfo: state.login.userInfo,
});

export default connect(mstp, {...actions, change })(Update);
