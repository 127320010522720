export const tableOptions = {
    sizePerPage: 10,
    hideSizePerPage: true,
    alwaysShowAllBtns: true,
    noDataText: "No data",
};

export const BreakLine = {
    whiteSpace: "normal",
};
