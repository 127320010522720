import { createActions, handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import { goBack } from "react-router-redux";
import swal from "sweetalert2";
import moment from "moment";
import { errorHandler } from '../../common/utility/constants';
import app from "../../libs/apiClient";
import _ from 'lodash';
import { actions as breadcrumbActions } from "./breadcrumb";

const service = app.service("/api/blog-post");

const SET_SEARCH = "SET_SEARCH_BLOG"

const { loading, setData, setPage, setUpdateData } = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
    SET_PAGE: (page) => ({ page }),
    SET_UPDATE_DATA: (updateData) => ({ updateData }),
});

export const create = (formData) => async (dispatch) => {
    dispatch(loading());
    const data ={
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    };
    try {
        await service.create(data , {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        dispatch(goBack());
    } catch (err) {
        if (err.message === "alreadyFeature"){
            swal({
                title: "Featured blog",
                text: "You are changing the featured blog post. Please select continue to confirm this change.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D50032",
                cancelButtonColor: "#545b62",
                confirmButtonText: "Continue",
            }).then(result => {
                if (result.value) {
                    dispatch(resendCreate(data))
                }
            });
        }else{
            dispatch(errorHandler(err));
        }
    } finally {
        dispatch(loading(false));
    }
};

export const resendCreate = (formData) => async (dispatch) => {
    dispatch(loading());
    try {
        await service.create(formData , {
            headers: {
                "content-type": "multipart/form-data",
            },
            query: {setFeatured: true, create: true}
        });
        dispatch(goBack());
    } catch (err) {
        dispatch(errorHandler(err));
    } finally {
        dispatch(loading(false));
    }
};

export const update = (formData) => async (dispatch) => {
    dispatch(loading());
    const data = {
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    };

    try {
        const response = await service.patch(data._id, data, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        console.log("RESPONSE", response)
        dispatch(goBack());
    } catch (err) {
        if (err.message === "alreadyFeature"){
            swal({
                title: "Featured blog",
                text: "You are changing the featured blog post. Please select continue to confirm this change.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D50032",
                cancelButtonColor: "#545b62",
                confirmButtonText: "Continue",
            }).then((result) => {
                if (result.value) {
                    dispatch(resend(formData))
                }
            });
        } else {
            dispatch(errorHandler(err));
        }
    } finally {
        dispatch(loading(false));
    }
};

const resend = (data) => async (dispatch) => {
    dispatch(loading());
    try {
        const response = await service.patch(data._id, data, {
            headers: {
                "content-type": "multipart/form-data",
            },
            query: {setFeatured: true}
        });
        console.log("RESPONSE RESERNDDDDDDDDDDD", response)
        dispatch(goBack());
    } catch (err) {
        dispatch(errorHandler(err));
    } finally {
        dispatch(loading(false));
    }
}

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(loading());

    try {
        const query = { query: { $skip: (page - 1) * 10 } };
        const { search } = getStore().blogPost;
        if(search){
            const responseCategory = await app.service("/api/post_category/").find({ query: {
                name: { $regex: _.escapeRegExp(search), $options: 'i'}
            }});
            query.query.$or = [
                { title: { $regex: _.escapeRegExp(search), $options: 'i'} },
                { category: { $in: responseCategory.length >= 0 ? responseCategory : responseCategory.data } }
            ]
        }
        const response = await service.find(query);
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (err) {
        dispatch(errorHandler(err));
    } finally {
        dispatch(loading(false));
    }
};

export const destroy = (id) => async (dispatch) => {
    dispatch(loading());

    try {
        await service.remove(id);
        dispatch(find());
    } catch (err) {
        dispatch(errorHandler(err));
    } finally {
        dispatch(loading(false));
    }
};

export const load2Update = (id) => async (dispatch) => {
    dispatch(loading());

    try {
        const blogPost = await service.get(id);

        dispatch(setUpdateData(blogPost));
        const { updateCustomLabel } = breadcrumbActions;
        let { title } = blogPost;
        if (title.length > 22) {
            title = `${title.slice(0, 23)}...`;
        }

        dispatch(updateCustomLabel(id, title));
        dispatch(initializeForm("blogPost", blogPost));
    } catch (err) {
        dispatch(errorHandler(err));
    } finally {
        dispatch(loading(false));
    }
};

export const setSearch = (search) => (dispatch) => {
    dispatch({type: SET_SEARCH, search});
    dispatch(find())
}

const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setUpdateData]: (state, { payload: { updateData } }) => ({
        ...state,
        updateData,
    }),
    [SET_SEARCH]: (state, { search }) => ({...state, search })
};

const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    loading: false,
    updateData: {},
    search: undefined
};

export default handleActions(reducers, initialState);
