import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import LoadMask from '../../Utils/LoadMask';
import EspecialRatesScheduleForm from './formSchedule';
import EspecialRatesVehicleForm from './formVehicle';
import ModalEdit from "./VehicleEditModal";
import { DateObject } from "react-multi-date-picker";
import moment from 'moment';


class EspecialRates extends React.Component {

    state = {
        step: 1,
        dates: [],
        modalVehicleEdit: false,
        indexEdit: undefined,
    }

    nextStep = () => this.setStep(this.state.step + 1);
    previosStep = () => this.setStep(this.state.step - 1);
    setStep = (step) => this.setState({ step });
    changeDate = (dates, resetInput = false) => {
        resetInput && this.props.resetInputCalendar();
        this.setState({ dates });
        this.props.changeRadioChecks(dates);
    }

    closeModal = () => {
        this.setState({ dates: [], step: 1 });
        this.props.toggle();
    }

    closeEditVehicle = () => {
        this.props.closeEditVehicle();
        this.setState({ modalVehicleEdit: false, indexEdit: undefined });
    }

    updateSpecialRateVehicle = (values) => {
        this.props.updateSpecialRateVehicle(values, this.state.indexEdit);
        this.closeEditVehicle();
    }

    restoreDefault = () => {
        console.log('Restore default');
    }

    openModalVehicle = (index) => {
        this.props.loadEditVehicleSpecialRate(index);
        this.setState({ modalVehicleEdit: true, indexEdit: index });
    }

    submitEspecialRate = async (data) => {
        await this.props.submitEspecialRate(data, this.closeModal);
    }

    removeSpecialRate = async () => {
        const result = await this.props.removeSpecialRate();
        if(result) this.closeModal();
    }

    componentDidUpdate(prevProps,prevState){
        if(!prevProps.updateDataEspecialRate || Object.keys(prevProps.updateDataEspecialRate).length === 0 &&
            this.props.updateDataEspecialRate && Object.keys(this.props.updateDataEspecialRate).length > 0
        ){
            const { calendar = [] } = this.props.updateDataEspecialRate;
            this.changeDate(calendar);
        }
    }

    render(){

        const { step, modalVehicleEdit } = this.state;
        const { vehicleEdit, vehicleLocationLoader, getVehicles, updateDataEspecialRate, removeSpecialRate } = this.props;

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.closeModal}
                modalClassName="no-scroll-y" className="modal-dialog--big"
            >
                <ModalBody className="modal-body--big"
                    style={{ overflowY: "auto", maxHeight: "90vh",
                        marginTop: "1%", marginBottom: "1%", display: "block",
                    }}
                >
                    <button type="button" onClick={this.closeModal}
                        className="modal-body__close"
                    >
                        <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="times"
                            className="svg-inline--fa fa-times fa-w-11 smallIcon"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 352 512"
                        >
                            <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z">
                            </path>
                        </svg>
                    </button>
                    <LoadMask loading={this.props.specialRatesLoader} blur>

                        <React.Fragment>

                            <ModalEdit
                                isOpen={modalVehicleEdit}
                                toggle={this.closeEditVehicle}
                                vehicleEdit={vehicleEdit}
                                vehicleLocationLoader={vehicleLocationLoader}
                                onSubmit={this.updateSpecialRateVehicle}
                                restoreDefault={this.restoreDefault}
                                showRestoreBtn={false}
                            />

                            {step === 1 && (
                                <EspecialRatesScheduleForm
                                    onSubmit={this.nextStep}
                                    dates={this.state.dates}
                                    toggle={this.closeModal}
                                    changeDate={this.changeDate}
                                    removeSpecialRate={this.removeSpecialRate}
                                    updateDataEspecialRate={updateDataEspecialRate}
                                    initialValues={{
                                        recurring: "weekly",
                                        type: "Days",
                                        repeatTimes: 1,
                                    }}
                                />
                            )}

                            {step === 2 && (
                                <EspecialRatesVehicleForm
                                    previosStep={this.previosStep}
                                    onSubmit={this.submitEspecialRate}
                                    openModalVehicle={this.openModalVehicle}
                                    toggle={this.closeModal}
                                    getVehicles={getVehicles}
                                />
                            )}
                        </React.Fragment>

                    </LoadMask>
                </ModalBody>
            </Modal>
        )
    }
}

export default EspecialRates
