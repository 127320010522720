import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/crewAdmin/zones';
import { Tooltip } from 'reactstrap';
import IconDownload from '../../../../icons/download';
import IconUpload from '../../../../icons/upload';
import IconQuestionCircle from '../../../../icons/questionCircle';
import swal from 'sweetalert2';


class Container extends React.Component {

    state = {
        altDownloadBtn: undefined,
        altUploadBtn: undefined,
        upload: undefined,
    }

    showAltUpload = () => {
        this.setState({ altUploadBtn: !this.state.altUploadBtn });
    }

    showAltDownload = () => {
        this.setState({ altDownloadBtn: !this.state.altDownloadBtn });
    }

    downladZips = () => {
        this.props.downloadZips(this.props.zoneId);
    }

    uploadZips = () => {
        if(!this.state.upload || !this.state.upload.file || !['xls', 'xlsx'].includes(this.state.upload.extension[0])){
            swal.fire({
                type: "error",
                title: "ERROR",
                html: `<h4>Invalid file</h4>`,
            });
        } else {
            this.props.uploadZips(this.state.upload).then(result => {
                if(result){
                    document.getElementById('file').value= null;
                    this.setState({ upload: undefined })
                }
            }).catch(error=>{
                console.log(error);
                swal.fire({
                    type: "error",
                    title: "ERROR",
                    html: `<h4>Cannot read the file</h4>`,
                }).then(()=>{
                    document.getElementById('file').value= null;
                    this.setState({ upload: undefined });
                })
            });
        }
    }

    onChangeUpload = (e, file) => {
        file = file || e.target.files[0];

        const reader = new FileReader();
        reader.onload = (e) => {
            this.setState({ upload: { name: file.name, extension: file.name.match(/[^\.][^/.]+$/g), file: reader.result } });
        };

        if(file)
            reader.readAsArrayBuffer(file);
    }

    chooseFile = () => {
        if(this.state.upload && this.state.upload.file){
            this.uploadZips();
        } else {
            document.getElementById('file').click();
        }
    }

    render(){
        return (
            <div className="d-flex flex-wrap mb-3">
                <div className="col-12 col-md-6 px-0 pr-md-1 my-1">
                    <button type="button" className="btn btn-secondary btn-block btn-lg px-4 py-2 d-flex"
                        onClick={()=>this.downladZips()}
                        id="downloadBtn"
                    >
                        <div style={{ width: '16px', height: '16px' }}>
                            <IconDownload className="w-100 h-100"/>
                        </div>
                        <label className="clickable flex-1 mb-0 px-2">DOWNLOAD</label>
                        <div style={{ width: '16px', height: '16px' }}>
                            <IconQuestionCircle className="w-100 h-100" />
                        </div>
                    </button>
                    <Tooltip placement="bottom" isOpen={this.state.altDownloadBtn} target="downloadBtn" toggle={this.showAltDownload}>
                        Export the zips associated with this zone to an excel file. You can make changes and import the updates at a later time.
                    </Tooltip>
                </div>
                <div className="col-12 col-md-6 px-0 pl-md-1 my-1">
                    <input type="file" id="file"
                        onChange={this.onChangeUpload}
                        accept={''}
                    />
                    <button className="btn btn-primary btn-block btn-lg px-4 py-2 d-flex"
                        onClick={this.chooseFile}
                        id="uploadBtn"
                    >
                        <div style={{ width: '16px' }}>
                            <IconUpload className="w-100 h-100"/>
                        </div>
                        <label className="flex-1 text-truncate mb-0 px-2 clickable">
                            {this.state.upload ? this.state.upload.name : 'UPLOAD'}
                        </label>
                        <div style={{ width: '16px', height: '16px' }}>
                            <IconQuestionCircle className="w-100 h-100" />
                        </div>
                    </button>
                    <Tooltip placement="bottom" isOpen={this.state.altUploadBtn} target="uploadBtn" toggle={this.showAltUpload}>
                        Upload an excel file with new zip codes. Duplicate zip code entries will be bypassed. Missing entries will not be removed.
                    </Tooltip>
                </div>
            </div>
        )
    }
}

const mdtp = {
    ...actions,
}

export default connect(null, mdtp)(Container);

