import { connect } from "react-redux";
import Update from "./PromoCodeUpdate";
import { update, load2Update } from "../../../../redux/modules/promoCodes";

const mdtp = { update, load2Update };

const mstp = (state) => ({
    userInfo: state.login.userInfo,
})

export default connect(mstp, mdtp)(Update);
