import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import swal from "sweetalert2";
import {hourlyOptions} from '../../../../utility/constants';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderSelectField } from '../../../Utils/renderField';
import { hourValidator } from '../../../../utility/form-validations';
import { combine, validate, validators, validatorFromFunction } from 'validate-redux-form';
import {
    renderDatePicker,
    renderField,
    renderFieldCheck,
    renderPlacesAutocomplete,
} from '../../../Utils/renderField/renderField';
import AutoSuggest from '../../../Utils/autosuggest';
import renderTimePicker from "../../../../../TimePicker";
import airlineCodes from '../../../../utility/airlines.json';

import { Stops, ButtonAddStop, validateStops } from "../../../stops/Stops";
import { NoteModal } from "../../../stops/NoteModal";
function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return fn();
        }
        didMountRef.current = true;
    }, inputs);
}

let BookForm = (props) => {

    const { typeForm, itinerary, handleSubmit, setDetails, clearForm,
        duration, submitFailed, no_flight_information, time,
        array,
        notePickUp,
        noteDropOff,
        change,
        stopsCount,
        stops,
        loadingVehicles,
        schedule,
        searchForSpecialRate,
        tripCartItem,
        removeVehicles,
        timeValue,
        valid,
        vehicles,
        updateTrip,
        onSubmit,
    } = props;

    const {
        from,
        to,
        flightsNumbers,
        isAirport,
    } = itinerary;

    const onChangeTypeForm = (type) => {
        // Remove extra stops for transfer
        if (stopsCount > 0 && type === "transfer") {
            const stop = stops[0];
            delete stop.time;
            change("stops", [stop]);
        }
    };

    const submitOnTypeFormChange = () => {
        const { time: timeItem, from: fromItem, to: toItem } = tripCartItem;
        if (
            tripCartItem &&
            timeItem &&
            fromItem &&
            toItem &&
            valid &&
            typeForm &&
            (typeForm !== "hourly" || duration)
        )
            handleSubmit();
        else removeVehicles();
    };

    useDidUpdateEffect(submitOnTypeFormChange, [duration, typeForm]);

    useEffect(() => {
        const specialRate = async () => {
            const allSpecialIncluded = await searchForSpecialRate();
            if (!allSpecialIncluded) handleSubmit();
        };

        if (
            typeof timeValue === "object" &&
            tripCartItem.edit &&
            schedule &&
            timeValue &&
            valid
        ) {
            specialRate();
        }
    }, [schedule, timeValue]);

    const setDetailsPlaces = (detail, key) => {
        const setDetailsAndLoadVehicles = () =>
            new Promise((resolve) => {
                setDetails(detail, key);
                resolve();
            });

        setDetailsAndLoadVehicles().then(() => {
            if (
                tripCartItem &&
                tripCartItem.edit &&
                tripCartItem.tripIndex !== null &&
                tripCartItem.tripIndex !== undefined
            )
                handleSubmit();
        });
    };

    const extraDetails = () => {
        if (
            tripCartItem &&
            tripCartItem.edit &&
            tripCartItem.tripIndex !== null &&
            tripCartItem.tripIndex !== undefined
        )
            removeVehicles();
    };

    return (
        <form name="typeForm" className="col-12">
            <div className="p-1 px-sm-4 py-md-3 bg-light d-flex flex-column flex-wrap radius-1">
                <div className="w-100 px-2">
                    <span style={{ color: "black" }}> Itinerary </span>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-1 d-flex form-group">
                        <Field
                            inModal
                            name="transferQt"
                            component={renderSelectField}
                            clearable={false}
                            className="m-2 w-100"
                            options={[
                                {value: "transfer", label: "Transfer"},
                                {value: "hourly", label: "Hourly"},
                            ]}
                            selectStyles={{
                                menuContainerStyle: { zIndex: 5 },
                            }}
                            myChange={(e) => onChangeTypeForm(e.value)}
                            disabled={loadingVehicles}
                        />
                    </div>
                    <div className="flex-1">
                        {typeForm === 'hourly' && (
                            <div className={`d-flex flex-column mb-md-0 ${duration || submitFailed == false ? 'form-group' : ''}`}>
                                <Field
                                    inModal
                                    name="duration"
                                    labelKey="label"
                                    valueKey="value"
                                    component={renderSelectField}
                                    options={hourlyOptions}
                                    placeholder="Duration"
                                    padding
                                    className="m-2"
                                    selectStyles={{
                                        menuContainerStyle: { zIndex: 5 },
                                    }}
                                    disabled={loadingVehicles}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-1 d-flex form-group">
                        <label htmlFor="schedule">Departing date</label>
                        <Field
                            name="schedule"
                            numberOfMonths={1}
                            component={renderDatePicker}
                            className="flex-1 w-100 m-2 p-0"
                            disabled={loadingVehicles}
                        />
                    </div>

                    <div className="flex-1 d-flex form-group">
                        <label> Time </label>
                        <Field
                            inModal
                            id="timepicker1"
                            name="time"
                            placeholder="12:00 PM"
                            component={renderTimePicker}
                            style = {{height: 'none'}}
                            className="pt-4 pl-2"
                            Value={time}
                            disabled={loadingVehicles}
                        />
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-between">
                    <div className="flex-1 d-flex form-group">
                        <Field
                            id="fromAutocomplete"
                            name="from"
                            component={renderPlacesAutocomplete}
                            additionalAddressInfo={["street_number","route"]}
                            setDetails={setDetailsPlaces}
                            lat={from.lat}
                            lng={from.lng}
                            pl="from"
                            label="Traveling from"
                            placeholder="From: address, airport, hotel..."
                            inModal
                            hasNoteModal={typeForm === "hourly"}
                            noteModal={<NoteModal name="notePickup" noteValue={notePickUp} />}
                            disabled={loadingVehicles}
                        />
                    </div>
                    <FieldArray
                        name="stops"
                        component={Stops}
                        schedule={schedule}
                        form="quotes"
                        showTime={typeForm === "hourly"}
                        inModal
                        change={change}
                        extraDetails={extraDetails}
                        disabled={loadingVehicles}
                    />
                    <div className="flex-1 d-flex form-group">
                        <Field
                            id="toAutocomplete"
                            name="to"
                            component={renderPlacesAutocomplete}
                            additionalAddressInfo={["street_number","route"]}
                            setDetails={setDetailsPlaces}
                            lat={to.lat}
                            lng={to.lng}
                            pl="to"
                            label="Traveling to"
                            placeholder="To: address, airport, hotel..."
                            inModal
                            hasNoteModal={typeForm === "hourly"}
                            noteModal={<NoteModal name="noteDropOff" noteValue={noteDropOff} />}
                            disabled={loadingVehicles}
                        />
                    </div>
                </div>
                {isAirport && (
                    <div className="px-0 mx-0">
                        <div className="d-flex flex-column flex-lg-row justify-content-between">
                            <div className="flex-1 d-flex form-group">
                                <label htmlFor="airline">Airline</label>
                                <Field
                                    name="airline"
                                    component={AutoSuggest}
                                    keyLabel='label'
                                    keyId='_id'
                                    searchKey="company"
                                    placeholder="MC"
                                    suggestions = {airlineCodes}
                                    className="w-100 m-2"
                                    inputClassName="pt-4"
                                    disabled={no_flight_information && no_flight_information || loadingVehicles}
                                    onSelectOption={value=>props.onSelectAirline(value)}
                                />
                            </div>

                            <div className="flex-1 d-flex form-group">
                                <label htmlFor="fligth_number">Flight Number</label>
                                <Field
                                    name="fligth_number"
                                    component={AutoSuggest}
                                    showNotFoundMsg
                                    notFoundMsg="Flight info not found"
                                    keyLabel='label'
                                    keyId='index'
                                    searchKey="flightNumber"
                                    placeholder="234"
                                    onChange={ (event, value) => props.getFlightInfo(value) }
                                    onSelectOption={value => props.onSelectFlight(value)}
                                    disabled={no_flight_information && no_flight_information || loadingVehicles}
                                    suggestions={flightsNumbers}
                                    typeInput="text"
                                    className="flex-1 w-100 m-2"
                                    inputClassName="pt-4"
                                    async
                                />
                            </div>
                        </div>
                        <div className="d-flex mx-2">
                            <Field
                                name="no_flight_information"
                                label="No flight information"
                                component={renderFieldCheck}
                                disabled={loadingVehicles}
                            />
                        </div>
                    </div>
                )}
                <ButtonAddStop
                    array={array}
                    fieldName="stops"
                    disabled={
                        (stopsCount > 0 && typeForm !== "hourly") ||
                        loadingVehicles
                    }
                />
                <div>
                    <hr className="mb-4 mt-0 mx-2" />
                </div>
                <div className="d-flex flex-column justify-content-between mb-4">
                    <div className="container-promo">
                        <div className="col-12 px-0">
                            <Field
                                name="bookingCode"
                                component={renderField}
                                type="text"
                                className="form-control p-2 "
                                placeholder="Booking Code"
                                disabled={loadingVehicles}
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row">
                    {tripCartItem &&
                    tripCartItem.edit &&
                    tripCartItem.tripIndex !== null &&
                    tripCartItem.tripIndex !== undefined &&
                    valid &&
                    vehicles.find((vh) => {
                        return (
                            tripCartItem &&
                            tripCartItem.edit &&
                            tripCartItem.vehicle &&
                            tripCartItem.vehicle._id === vh._id &&
                            vh.info &&
                            vh.info.price === tripCartItem.vehicle.price
                        );
                    }) ? (
                        <button
                            className="btn btn-secondary m-1"
                            disabled={loadingVehicles}
                            type="button"
                            onClick={handleSubmit((data) => updateTrip(data))}
                        >
                            Save changes
                        </button>
                    ) : null}
                    <button
                        className="btn btn-primary m-1"
                        disabled={loadingVehicles}
                        type="button"
                        onClick={handleSubmit((data) => onSubmit(data))}
                    >
                        <b>LOAD VEHICLES</b>
                    </button>
                    <button
                        type="button"
                        className="btn btn-outline-primary m-1"
                        onClick={clearForm}
                        disabled={loadingVehicles}
                    >
                        CLEAR FORM
                    </button>
                </div>
            </div>
            <div className="col-12 px-1 px-sm-4">
                <hr />
            </div>
        </form>
    );
};

const selector = formValueSelector('typeForm');
BookForm = connect(state => {
    const typeForm = selector(state, 'transferQt');
    const duration = selector(state, 'duration');
    const no_flight_information = selector(state, 'no_flight_information');
    const schedule = selector(state, 'schedule');
    let time = selector(state, 'time');
    const timeValue = selector(state, "time");
    if(time){
        if(no_flight_information){
            if(!['00', '15', '30', '45'].includes(time.minutes)){
                let minutes = parseInt(time.minutes);
                time.minutes = minutes < 15 ? '00' : minutes < 30 ? '15' : minutes < 45 ? '30' : '45'
            }
        }
        time = moment(`${schedule ? schedule.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} ${time.hour}:${time.minutes}`);
    } else time = undefined;

    const notePickUp = selector(state, 'notePickup') || '';
    const noteDropOff = selector(state, 'noteDropOff') || '';
    const stops = selector(state, "stops");
    const stopsCount = stops ? stops.length : 0;

    return {
        typeForm,
        schedule,
        time,
        duration,
        no_flight_information,
        notePickUp,
        noteDropOff,
        stopsCount,
        stops,
        timeValue,
    };
})(BookForm);

const validAddress = validatorFromFunction((value, address) => {
    return typeof address === 'object' && address.state && address.city &&
        address.lat && address.lng && address.postal_code ? true : false
});

const validateAirilineOrFlight = validatorFromFunction( (value, no_flight_information = false, isFlightNumber = false) =>{
    if(no_flight_information)
        return true
    else
        if(!value) return false;
        else if(isFlightNumber)
            return typeof value === 'object' ? true : false;
        else return true;
})

export default reduxForm({
    form: "typeForm",
    initialValues: {
        transferQt: 'transfer'
    },
    validate: (data, props) => {
        const errors = validate(data, {
            from: combine(
                validators.exists()('Required Field'),
                validAddress(props.itinerary.from)('Please select an exact address')
            ),
            to: combine(
                validators.exists()('Required Field'),
                validAddress(props.itinerary.to)('Please select an exact address')
            ),
            duration: validators.exists()('Required Field'),
            schedule: validators.exists()('Required Field'),
            time: combine(
                validators.exists()('Required Field'),
                hourValidator(data.schedule)('Please select a time in the future')
            ),
            airline: validateAirilineOrFlight(data.no_flight_information)('Required Field'),
            fligth_number: validateAirilineOrFlight(data.no_flight_information,true)('flight cannot be verified'),
        });

        const { stops, schedule, transferQt } = data;
        const { itinerary = {} } = props;
        const { from, to } = itinerary;

        if (stops && stops.length && transferQt === "transfer") {
            const firstStopIndex = 0;
            const lastStopIndex = stops.length - 1;
            const firstStop = stops[firstStopIndex];
            const lastStop = stops[lastStopIndex];

            const isAirport = (stop) =>
                stop.types && stop.types.includes("airport");
            const isSameLocation = (stop1, stop2) =>
                stop1.lat === stop2.lat && stop1.lng === stop2.lng;

            if (isSameLocation(from, firstStop)) {
                let messagePickUp =
                    "The pick up address cannot match the first stop address.";
                if (isAirport(from) || isAirport(firstStop)) {
                    messagePickUp = `${messagePickUp} Please specify specific Airport Terminal on the Notes under Passenger Info.`;
                }

                errors.from = messagePickUp;
            } else if (isSameLocation(to, lastStop)) {
                let messageDropOff =
                    "The drop off address cannot match the last stop address.";
                if (isAirport(from) || isAirport(lastStop)) {
                    messageDropOff = `${messageDropOff} Please specify specific Airport Terminal on the Notes under Passenger Info.`;
                }

                errors.to = messageDropOff;
            }
        } else if (
            from.lat &&
            to.lat &&
            from.lng &&
            to.lng &&
            from.lat === to.lat &&
            from.lng === to.lng &&
            transferQt === "transfer"
        ) {
            errors.to =
                "Pick-up location cannot match the drop-off location for a transfer trip.";

            errors.from =
                "Pick-up location cannot match the drop-off location for a transfer trip.";
        }

        return {
            ...errors,
            stops: validateStops({
                stops,
                schedule,
                validateTime: transferQt === "hourly",
                duration: { value: data.duration },
                time: data.time,
            }),
        };
    }
})(BookForm);
