import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/quote';
import moment from 'moment';
import LoadMask from "../../Utils/LoadMask";
import BookingModal from "../Create/booking/BookingForm";
import VehicleList from "../Create/booking/vehicleList";
import PassengerForm from "../Create/passengerForm";
import QuoteDetails from "../Create/QuoteDetails";
import PaymentForm from '../Create/Payment';
import SpecialNotesForm from '../Create/specialNotes';
import { Modal, ModalBody } from 'reactstrap';
import IconTimes from '../../../icons/times';
import EmailForm from '../Create/emailForm';
import swal from 'sweetalert2';

class DetailContainer extends React.Component{

    state = {
        checkout: false,
        specialNotes: false,
        email: false,
        passengerVehicle: undefined,
        loadingFormEmail: false,
        loadingSpecialNotes: false,
    }

    componentDidMount(){
        const { match: { params }, load2Update } = this.props;
        load2Update(params.id);
    }

    componentWillUnmount(){
        this.props.clearCart(true, true);
    }

    setCheckout = (value) => {
        this.props.initializeCheckout();
        this.setState({ checkout: value });
    }

    setPassengerVehicle = (index) => {
        this.props.editPassengerVehicle(index);
        this.setState({ passengerVehicle: index });
    }

    setSpecialNotes = (index) => {
        if(index !== undefined){
            this.props.editSpecialNotes(index);
        }
        this.setState({ specialNotes: index !== undefined ? true : false });
    }

    setEmail = (open = false) => {
        this.props.initializeEmailForm();
        this.setState({ email: open });
    }

    removeQuote = (id) => {
        swal.fire({
            title: "Are you sure?",
            text: "You cannot undo this action!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.destroy(id);
            }
        });
    }


    render(){

        const { clearCart, loading, modalPassengerForm, setPassenger, loadingPassengerModal,
            loadingVehiclesModal, transferQt, passengerFormModal, find, userInfo: { permission }
        } = this.props

        return(
            <div className="d-flex flex-wrap">
                <div className="col-12 d-flex justify-content-center">
                    <h3>
                        <strong>Update Quote</strong>
                    </h3>
                </div>
                <hr/>

                <PassengerForm
                    isOpen={passengerFormModal}
                    toggle={()=>modalPassengerForm(false)}
                    loading={loadingPassengerModal}
                    handlePassengerFormSubmit={values=>{
                        if(this.state.passengerVehicle != undefined){
                            this.props.setPassengerVehicle(values).then(result=>{
                                this.props.modalPassengerForm(false);
                                this.setState({ passengerVehicle: undefined });
                            })
                        } else {
                            setPassenger(values).then(result=>{
                                modalPassengerForm(false);
                            })
                        }
                    }}
                />

                <Modal isOpen={this.state.email} centered
                    toggle={()=>this.setEmail()}
                    className="modalItinerary"
                >
                    <ModalBody>
                        <button type="button" onClick={()=>this.setEmail()} className="modal-body__close">
                            <IconTimes className="smallIcon" />
                        </button>
                        <div className="col-12 pb-4 px-4">
                            <LoadMask loading={this.state.loadingFormEmail} blur>
                                <EmailForm
                                    onSubmit={values=>{
                                        this.setState({ loadingFormEmail: true });
                                        this.props.sendEmail(values).then(result=>{
                                            swal.fire({
                                                type: "success",
                                                title: "SUCCESS",
                                                text: "Email sent!"
                                            }).then(()=>{
                                                this.setEmail();
                                            })
                                        }).catch(error=>{
                                            console.log(error);
                                            swal.fire({
                                                type: "error",
                                                title: "ERROR!",
                                                text: "Error on send email",
                                            })
                                        }).finally(()=>{
                                            this.setState({ loadingFormEmail: false });
                                        })
                                    }}
                                    cancel={()=>this.setEmail()}
                                />
                            </LoadMask>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.specialNotes} centered
                    toggle={()=>this.setSpecialNotes()}
                    className="modalItinerary"
                >
                    <ModalBody>
                        <button type="button" onClick={()=>this.setSpecialNotes()} className="modal-body__close">
                            <IconTimes className="smallIcon" />
                        </button>
                        <div className="col-12 px-0">
                            <LoadMask loading={this.state.loadingSpecialNotes} blur>
                                <SpecialNotesForm
                                    onSubmit={(values) => {
                                        this.setState({ loadingSpecialNotes: true });
                                        this.props.setSpecialNotes(values).then(()=>{
                                            this.setState({ specialNotes: false });
                                        }).catch(()=>{
                                            swal.fire({
                                                type: "error",
                                                title: "ERROR!",
                                                text: "Error on set special notes",
                                            })
                                        }).finally(()=>{
                                            this.setState({ loadingSpecialNotes: false });
                                        })
                                    }}
                                    cancel={()=>this.setState({ specialNotes: false })}
                                />
                            </LoadMask>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.checkout}
                    toggle={()=>this.setCheckout(false)} modalClassName="no-scroll-y" className="modal-dialog--big modalItinerary"
                >
                    <ModalBody>
                        <button type="button" onClick={()=>this.setCheckout(false)} className="modal-body__close">
                            <IconTimes className="smallIcon" />
                        </button>

                        <div className="col-12 px-0">
                            <LoadMask loading={this.props.loadingModal} blur>
                                <PaymentForm
                                    handlePaymentFormSubmit={(values)=>this.props.setCheckout(values)}
                                />
                            </LoadMask>
                        </div>
                    </ModalBody>
                </Modal>

                <div className="col-12 col-md-7 col-lg-8 px-1">
                    <LoadMask loading={loading} dark blur>
                        <BookingModal
                            transferQt={transferQt}
                            loadingVehicles={loadingVehiclesModal}
                            find={find}
                            clear={() => clearCart(false)}
                            editPassenger={(index)=>this.setPassengerVehicle(index)}
                            removePassenger={(index)=>this.props.removePassengerVehicle(index)}
                        />
                    </LoadMask>
                </div>
                <div className="col-12 col-md-5 col-lg-4 px-1">
                    <QuoteDetails
                        {...this.props}
                        onSubmit={this.props.update}
                        sendEmail={()=>this.setEmail(true)}
                        checkout={()=>this.setCheckout(true)}
                        setSpecialNotes={this.setSpecialNotes}
                        setContactInfo={()=>this.setContactInfo(true)}
                        isUpdate={true}
                        permission={permission}
                        removeQuote={this.removeQuote}
                    />
                </div>

                <div className="col-12 px-0">
                    <VehicleList
                        transferQt={transferQt}
                        loadingVehicles={loadingVehiclesModal}
                        find={find}
                        clear={() => clearCart(false)}
                        editPassenger={(index)=>this.setPassengerVehicle(index)}
                        removePassenger={(index)=>this.props.removePassengerVehicle(index)}
                    />
                </div>

            </div>
        )
    }
}

const mstp = state => ({
    ...state.quote,
    userInfo: state.login.userInfo,
})

const mdtp = {
    ...actions,
}

export default connect(mstp, mdtp)(DetailContainer);