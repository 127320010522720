import React, { useEffect } from 'react';
import Modal from 'react-responsive-modal';
import LogDetails from './logDetails';
import CreateForm from './create/createForm';
import LoadMask from '../Utils/LoadMask';
import { connect } from 'react-redux';


export const formatStatus = (status = "") => {
    if(status == 'Unassigned')
        return {
            background: '#ebebeb',
            text: 'UNASSIGNED',
            textColor: '#000000',
        }
    else if(status == 'Assigned')
        return {
            background: '#ff0000',
            text: 'ASSIGNED',
            textColor: '#fff',
        }
    else if(status == 'Dispatched')
        return {
            background: '#ffff00',
            text: 'DISPATCHED',
            textColor: '#000000',
        }
    else if(status == 'On The Way')
        return {
            background: '#ff00ff',
            text: 'ON THE WAY',
            textColor: '#fff',
        }
    else if(status == 'Arrived')
        return {
            background: '#80ff80',
            text: 'ARRIVED',
            textColor: '#000000',
        }
    else if(status == 'Customer In Car')
        return {
            background: '#008000',
            text: 'CUSTOMER IN CAR',
            textColor: '#fff',
        }
    else if(status == 'Done')
        return {
            background: '#7a7a7a',
            text: 'DONE',
            textColor: '#fff',
        }
    else if(status == 'No Show')
        return {
            background: '#8b51f5',
            text: 'NO SHOW',
            textColor: '#fff',
        }
    else if(status == 'Canceled')
        return {
            background: '#ffb4b0',
            text: 'CANCELED',
            textColor: '#d50032',
        }
    else if(status == 'Late Cancel')
        return {
            background: '#ffc581',
            text: 'LATE CXL',
            textColor: '#dc4c18',
        }
    else
        return {
            background: '#a8c6fa',
            text: 'FO UNASSIGNED',
            textColor: '#0844a4',
        }
}

const SingleDetail = ({
    singleDetail,
    clearData,
    flights,
    updateTripLivery,
    loading,
    useModalVerify,
    loadingLogs,
    pageLogs,
    dataLogs,
    getLogs,
    btnDone,
    allowNoVerify,
    setModalLogs,
    modalLogs,
}) => {

    const data = flights && flights.find(item => item.TripID == singleDetail.TripID);

    useEffect(()=>{
        return () => clearData();
    }, []);

    const ableToEdit = singleDetail &&
        (singleDetail.liveryDBData && singleDetail.liveryDBData.sTripId) &&
        !['Canceled', 'Done', 'Late Cancel'].includes(singleDetail.TripStatusDesc.trim());

    const statusDescription = singleDetail && formatStatus(singleDetail.TripStatusDesc);

    return (
        <div className="flex-3-5">
            <Modal open={modalLogs} onClose={()=>setModalLogs(false)}
                classNames={{
                    modal: "w-100"
                }}
                styles={{
                    modal: { maxWidth: "1200px" }
                }}
            >
                <LogDetails
                    close={()=>setModalLogs(false)}
                    dataLogs={dataLogs}
                    find={(page)=>getLogs(singleDetail.liveryDBData._id, page)}
                    page={pageLogs}
                    loading={loadingLogs}
                />
            </Modal>
            <div className="mt-4">
                <div className="my-2 mb-3">
                    {!ableToEdit && singleDetail ? (
                        <div className="flex-1 mx-3 d-flex justify-content-center border bg-secondary text-white p-4 rounded mb-5">
                            <p> {['Canceled', 'Done', 'Late Cancel'].includes(singleDetail.TripStatusDesc.trim())
                                ? `This trip has been ${singleDetail.TripStatusDesc} and can no longer be edited using this tool`
                                : 'View Only - this trip can only be changed in Livery Coach'
                            } </p>
                        </div>
                    ) : ''}
                    {ableToEdit &&
                    singleDetail &&
                    singleDetail.liveryDBData &&
                    !singleDetail.liveryDBData.newVersion ? (
                        <div className="flex-1 mx-3 d-flex justify-content-center border bg-secondary text-white p-4 rounded mb-5">
                            <p>
                                This trip was created with a previous version,
                                the data will be adjusted to the new version.
                            </p>
                        </div>
                    ) : (
                        ""
                    )}
                    {singleDetail && (
                        <div className="d-flex flex-column">
                            <div className="mb-4 px-3 d-flex flex-column">
                                <div className="d-flex flex-wrap mb-3">
                                    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                                        <h5 className="text-center">
                                            TRIP ID: {singleDetail && singleDetail.TripID}
                                        </h5>
                                    </div>
                                    <div className="col-12 col-md-6 py-2 px-4"
                                        style={{ backgroundColor: statusDescription ? statusDescription.background : '#fff', }}
                                    >
                                        <h5 className="text-center" style={{ color: statusDescription ? statusDescription.textColor : '#000' }}>
                                            {statusDescription && `${statusDescription.text}`}
                                        </h5>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 offset-md-6 mb-3">
                                    <h5>{singleDetail.ChauffeurName}</h5>
                                    <h5>{singleDetail.ChauffeurPhone}</h5>
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <h5 className="bold mb-0">
                                        {singleDetail && singleDetail.CompanyName}
                                    </h5>
                                    <h5>
                                        {singleDetail && singleDetail.PassengersList && singleDetail.PassengersList.length > 0 && singleDetail.PassengersList[0].Name}
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="flex-1 d-flex flex-column">
                                    <LoadMask loading={loading} blur>
                                        <CreateForm
                                            onSubmit={(values)=>updateTripLivery(values, ()=>btnDone())}
                                            singleDetail={singleDetail}
                                            ableToEdit={ableToEdit}
                                            cancel={()=>btnDone()}
                                            useModalVerify={useModalVerify}
                                            allowNoVerify={allowNoVerify}
                                            showMoreOptions={singleDetail["Customer PO#"] &&
                                                (singleDetail["Customer PO#"].match(/-AOA$/g) != null ||
                                                singleDetail["Customer PO#"].match(/-CGO$/g) != null)
                                            }
                                            isUpdateView
                                        />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mstp = state => ({
    loading: state.liveryEdition.loading,
})

export default connect(mstp)(SingleDetail);
