import { useState } from 'react';
import { renderTimePrice } from './ExpirationRates';


const RenderTimePrice = (props) => {

    const [newTimePrice, setNewTimePrice] = useState(false);

    return renderTimePrice({
            ...props,
            newTimePrice: newTimePrice,
            setNewTimePrice: setNewTimePrice,
        })
}

export default RenderTimePrice;
