import { handleActions } from 'redux-actions';
import app from "../../libs/apiClient";
import Swal from 'sweetalert2';
import { setClientsQuery } from './clients';
import { initialize as initializeForm } from 'redux-form';
import { errorHandler } from '../../common/utility/constants';
import _ from 'lodash';

// actionTypes
const POINT_TO_POINT_LOADER = 'POINT_TO_POINT_LOADER';
const POINT_TO_POINT_LOADER_CREATE_UPDATE = 'POINT_TO_POINT_LOADER_CREATE_UPDATE';
const POINT_TO_POINT_PAGE =  "POINT_TO_POINT_PAGE";
const POINT_TO_POINT_DATA = 'POINT_TO_POINT_DATA';
const POINT_TO_POINT_UPDATE_DATA = "POINT_TO_POINT_UPDATE_DATA";
const POINT_TO_POINT_ITEM = "POINT_TO_POINT_ITEM";
const POINT_TO_POINT_SEARCH = "POINT_TO_POINT_SEARCH";

const service = app.service('/api/pointToPoint');


const find = (id, page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(POINT_TO_POINT_LOADER, true));
    const { search } = getStore().pointToPoint;

    const params = {
        query: {
            locationId: id,
            $skip: (page - 1) * 10,
            $limit: 10,
            $populate: ['vehicle']
        }
    }

    if(search){
        params.query.$or = [
            { "from.address": { $regex: _.escapeRegExp(search), $options: 'i' } },
            { "to.address": { $regex: _.escapeRegExp(search), $options: 'i' } }
        ]
    }

    service.find(params).then( response => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch((e)=>{
        dispatch(errorHandler(e));
    })
    .finally(()=>{
        dispatch(setLoader(POINT_TO_POINT_LOADER, false));
    })
};

const create = (data) => async (dispatch, getStore) => {
    dispatch(setLoader(POINT_TO_POINT_LOADER_CREATE_UPDATE, true));

    data.picture = {
        buffer: data.picture,
        mimeType: data.picture && data.picture.type,
    }
    try {
        await service.create(data);
        Swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Data created"
        })
        return true
    } catch(error){
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoader(POINT_TO_POINT_LOADER_CREATE_UPDATE, false));
    }
}

const update = (data) => async (dispatch, getStore) => {
    dispatch(setLoader(POINT_TO_POINT_LOADER_CREATE_UPDATE, true));

    data.picture = {
        buffer: data.picture,
        mimeType: data.picture && data.picture.type,
    }

    try {
        await service.update(data._id, data);
        Swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Data updated"
        })
        return true;
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoader(POINT_TO_POINT_LOADER_CREATE_UPDATE, false));
    }
}

const getPlaceFromGoogleMaps = async (placeId, address) => {

    if(!placeId){
        const { predictions, status } = await app.service('/api/googleMaps').find({ query: { search: address } });
        if(status === 'OK'){
            const [{place_id}] = predictions;
            placeId = place_id;
        }
    }
    const {results: [place]} = await app.service('/api/googleMaps').get('geocode', { query: { placeId }});
    const { address_components, geometry: { location }, formatted_address, types, place_id } = place;
    const { lat, lng } = location;

    let additionalAddressInfo = ['street_number', 'route', 'locality', 'sublocality', 'administrative_area_level_1', 'postal_code', 'administrative_area_level_2', 'street_address']
    let components = address_components.reduce((acum, current) => {
        additionalAddressInfo.forEach(item=>{
            if(current.types.includes(`${item}`)){
                acum[item] = current;
            }
        })
        return acum
    }, {})

    return {
        lat,
        lng,
        administrative_area_level_2: components.administrative_area_level_2 ? components.administrative_area_level_2.short_name : undefined,
        street_number: components.street_number ? components.street_number.short_name : undefined,
        route: components.route ? components.route.short_name : undefined,
        street_address: components.street_address ? components.street_address.short_name : undefined,
        state: components.administrative_area_level_1 ? components.administrative_area_level_1.short_name : undefined,
        city: components.locality ? components.locality.long_name : components.sublocality ? components.sublocality.long_name : '',
        postal_code: components.postal_code ? components.postal_code.short_name : undefined,
        formatted_address,
        types,
        place_id,
    }
}

const load2Update = (id) => async (dispatch, getStore) => {
    dispatch(setLoader(POINT_TO_POINT_LOADER_CREATE_UPDATE, true));

    try {
        const response = await service.get(id);
        let resultFrom = await getPlaceFromGoogleMaps(response.from.place_id, response.from.address);
        let resultTo = await getPlaceFromGoogleMaps(response.to.place_id, response.to.address);
        dispatch(setUpdateData(response));
        dispatch(initializeForm('pointToPointForm', {
            ...response,
            fromName: response.from.name,
            toName: response.to.name,
            from: resultFrom ? resultFrom.formatted_address : response.from.address,
            to: resultTo ? resultTo.formatted_address : response.to.address
        }));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoader(POINT_TO_POINT_LOADER_CREATE_UPDATE, false));
    }
};

const destroy = (id) => (dispatch, getStore) => {
    dispatch(setLoader(POINT_TO_POINT_LOADER, true));
    service.remove(id).then(response=>{
        Swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Data removed"
        }).then(()=>{
            dispatch(find())
        })
    }).catch((error)=>{
        dispatch(errorHandler(error));
    })
    .finally(()=>{
        dispatch(setLoader(POINT_TO_POINT_LOADER, false));
    })
}


// PURE ACTIONS
const setLoader = (type,loader) => ({ type, loader, })

const setData = (data) => ({
    type: POINT_TO_POINT_DATA,
    data,
});

const setItem = (item) => ({
    type: POINT_TO_POINT_ITEM,
    item,
});

const setSearch = (search) => (dispatch, getStore) => {
    const { values } = getStore().form.searchPointToPoint;
    const search = values && values.search;
    dispatch({ type: POINT_TO_POINT_SEARCH, search }),
    dispatch(find())
}

const setUpdateData = (updateData) => ({
    type: POINT_TO_POINT_UPDATE_DATA,
    updateData,
});

const setPage = (page) => ({
    type: POINT_TO_POINT_PAGE,
    page
})

export const actions = {
    find,
    create,
    update,
    load2Update,
    destroy,
    setUpdateData,
    setSearch,
}

// REDUCERS
export const reducers = {
    [POINT_TO_POINT_DATA]: (state, { data }) => ({ ...state, data }),
    [POINT_TO_POINT_LOADER]: (state, { loader }) => ({ ...state, loader }),
    [POINT_TO_POINT_LOADER_CREATE_UPDATE]: (state, { loader }) => ({ ...state, loaderCreateUpdate: loader }),
    [POINT_TO_POINT_PAGE]: (state, { page }) => ({ ...state, page }),
    [POINT_TO_POINT_UPDATE_DATA]: (state, { updateData }) => ({ ...state, updateData }),
    [POINT_TO_POINT_ITEM]: (state, { item }) => ({ ...state, item }),
    [POINT_TO_POINT_SEARCH]: (state, { search }) => ({ ...state, search }),
};

// InitialState
const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    search: undefined,
    loader: false,
    loaderCreateUpdate: false,
    updateData: undefined,
    item: {},
};

export default handleActions(reducers, initialState);
