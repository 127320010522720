import React from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import Search from './search';
import Table from "../../Utils/Grid";
import { BreakLine } from "../../Utils/tableOptions";
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import Modal from 'react-responsive-modal';
import LoadMask from "../../Utils/LoadMask";
import FormUpdatePoints from  "./pointsForm";
import moment from 'moment';
import { settingsAccountsPoints } from "../../../../routesConfiguration/paths";


export default class Grid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        find: PropTypes.func.isRequired,
    };

    state = {
        modalEdit: false,
        idEdit: undefined,
    }

    componentDidMount() {
        const { params } = this.props.match;
        this.props.find(params.id);
    }

    labelPoint = (value) => value === 1 ? `${value} pt.` : `${value} pts.`;

    setModalEdit = (value, id) => {
        this.setState({ modalEdit: value, idEdit: id });
        if(id)
            this.props.findUser(id);
    }

    onSubmit = async(values) => {
        await this.props.editUserPoints(values).then(()=>{
            this.setModalEdit(false);
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readPoints }) => readPoints))
                    this.props.find();
            }
        }
    }

    render() {

        const { data, loading, loadingModal, page, find, setSearch, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                {permission && permission.find(({ updatePoints }) => !!updatePoints) && (
                    <Modal
                        open={this.state.modalEdit}
                        onClose={() => this.setModalEdit(false)}
                        closeOnOverlayClick={false}
                    >
                        <LoadMask loading={loadingModal} blur>
                            <FormUpdatePoints
                                onSubmit={this.onSubmit}
                                onCancel={() => this.setModalEdit(false)}
                            />
                        </LoadMask>
                    </Modal>
                )}
                <div className="col-sm-12 pt-2">
                    <div className="grid-container">
                        <div className="grid-title d-flex justify-content-center align-items-center">
                            <h1 className="ml-0">
                                <strong>Reward Points</strong>
                            </h1>
                        </div>
                        {permission && permission.find(({ readPoints }) => !!readPoints) && (
                            <React.Fragment>
                                <div className="d-flex flex-md-row flex-column-reverse">
                                    <Search onSubmit = {setSearch}
                                        className="flex-1 col-12 col-md-6 px-0 mt-md-5 mb-3"
                                    />
                                </div>

                                <br />

                                <Table
                                    onPageChange={find}
                                    data={data}
                                    page={page}
                                    loading={loading}
                                >
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="_id"
                                        isKey
                                        className="hide-column-header"
                                        dataFormat={(cell, row) =>
                                            <div className="d-flex flex-column-reverse flex-md-row">
                                                <div className="flex-1 d-flex flex-column">
                                                    <label> <strong> Name: </strong> {row.firstName && row.firstName} {row.lastName && row.lastName} </label>
                                                    {row.email && (
                                                        <label> <strong> Email:&nbsp;</strong> {row.email} </label>
                                                    )}
                                                    {row.mobileNo && (
                                                        <label> <strong> Email:&nbsp;</strong> {row.mobileNo} </label>
                                                    )}
                                                    {row.points ? (
                                                        <React.Fragment>
                                                            <label> <strong> Points:&nbsp;</strong> {this.labelPoint(row.points.totalPoints)} - <RenderCurrency value={row.points.totalEarned} /> </label>
                                                            <label> <strong> Last Update:&nbsp;</strong> {moment(row.points.lastUpdate).format('l LT')} </label>
                                                        </React.Fragment>
                                                    ) : (
                                                        <label> <strong> Points:&nbsp;</strong> 0 pts - <RenderCurrency value={0} /> </label>
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    {activeFormatter({
                                                        ver: settingsAccountsPoints,
                                                        editar: permission.find(({updatePoints}) => !!updatePoints) ? (id)=>this.setModalEdit(true, id) : undefined,
                                                    })(cell, row)}
                                                </div>
                                            </div>
                                        }
                                    />
                                </Table>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
