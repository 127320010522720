import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ConfMsg from './ConfMsg';
import BookForm from './BookForm';
import WizardHeader from './WizardHeader';
import VehicleList from './VehicleList';
import Passenger from './Passenger';
import Spinner from './Spinner';
import PaymentForm from './Payment';
import CheckOut from './CheckOut';
import Receipt from './Receipt';
import {
    setTrip,
    setDetails,
    setBook,
    createQuote,
    setCheckout,
    setPassenger,
    setHourlyItinerary,
    setTransferItinerary,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
} from '../../../../../redux/modules/itinerary';
import { setVehicle, loadVehicles } from '../../../../../redux/modules/vehicle';
import IconTimes from '../../../../icons/times';
import swal from 'sweetalert2';

const Wizard = ({ step, setStep, children }) => (
    <div className="d-flex flex-column reset-print">
        <div className="d-flex justify-content-center" >
            {/* style={{ backgroundColor: "hsl(206, 17%, 24%)" }}> */}
            <div className="col-12 pb-5 px-1">
                <WizardHeader step={step} handleRoutes={setStep} />
                {children}
            </div>
        </div>
    </div>
);

const BookingModal = ({
    setTrip,
    toggle,
    itinerary,
    isOpen,
    setBook,
    setDetails,
    createQuote,
    loadVehicles,
    vehicle = [],
    setVehicle,
    setCheckout,
    setPassenger,
    setHourlyItinerary,
    setTransferItinerary,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
    find,
}) => {
    const [state, setState] = useState({ step: 0 });

    const setStep = step => setState(prevState => ({ ...prevState, step }));

    const onSubmit = values => {
        setTrip(values).then(()=>{
            setStep(1);
            loadVehicles();
        }).catch(error => {
            const msg = error.message || error;
            swal.fire({
                type: "error",
                title: "ERROR!",
                text: `${msg}`
            })
        })
    }

    const handleVehicleSelect = (vehicle, index) => {
        const allSelected = setVehicle(vehicle, index);
        if (allSelected)
            setStep(2);
    };

    const handleOnBookClick = async () => {
        const { quote } = itinerary;
        if(!quote || Object.keys(quote).length === 0){
            await createQuote();
        }
        setStep(3)
    };

    const handlePassengerFormSubmit = values => {
        setPassenger(values);
        setStep(4);
    };

    const handlePaymentFormSubmit = values => {
        setCheckout(values);
        setBook().then(isBooked => {
            if (isBooked)
                setStep(5);
        });
    };

    return (
        <Modal isOpen={isOpen} toggle={() => {
            if(state.step === 5 && itinerary.confirmed){
                find();
            }
            toggle();
        }}
            modalClassName="no-scroll-y" className="modal-dialog--big modalItinerary"
        >
            {state.step !== 0 && !itinerary.to.lat && setStep(0)}
            <ModalBody className="modal-body--big"
                style={{ overflowY: "auto", maxHeight: "90vh",
                    marginTop: "1%", marginBottom: "1%", display: "block",
                }}
            >
                <button type="button" onClick={()=>{
                    if(state.step === 5 && itinerary.confirmed){ find(); }
                    toggle();
                }}
                    className="modal-body__close"
                >
                    <IconTimes className="smallIcon" />
                </button>
                <div className="mt-1 col-12 col-md-10 offset-md-1 px-0">
                    {(state.step === 0 || state.step === 1) && (
                        <div className="row justify-content-center mb-1">
                            <BookForm
                                onSubmit={(values)=>onSubmit(values)}
                                itinerary={itinerary}
                                vehicle={vehicle}
                                setDetails={setDetails}
                                onSelectAirline={onSelectAirline}
                                getFlightInfo={getFlightInfo}
                                onSelectFlight={onSelectFlight}
                            />
                        </div>
                    )}
                    {state.step === 1 && (
                        <VehicleList
                            handleVehicleSelect={handleVehicleSelect}
                        />
                    )}
                    {state.step === 2 && (
                        <CheckOut handleOnBookClick={handleOnBookClick} toggle={toggle}/>
                    )}
                    {state.step === 3 && (
                        <Wizard step={3} setStep={setStep}>
                            <Passenger
                                handlePassengerFormSubmit={handlePassengerFormSubmit}
                            />
                        </Wizard>
                    )}
                    {state.step === 4 && (
                        <Wizard step={4} setStep={setStep}>
                            <PaymentForm
                                handlePaymentFormSubmit={handlePaymentFormSubmit}
                            />
                        </Wizard>
                    )}
                    {state.step === 5 && (
                        <React.Fragment>
                            <ConfMsg />
                            <Receipt inModal/>
                        </React.Fragment>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};

BookingModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
};

const mdtp = {
    setTrip,
    setDetails,
    setBook,
    setVehicle,
    setPassenger,
    setCheckout,
    createQuote,
    setHourlyItinerary,
    setTransferItinerary,
    loadVehicles,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
};

const mstp = (state) => ({ itinerary: state.itinerary, vehicle: state.vehicle.vehicle })

export default connect(mstp, mdtp)(BookingModal);
