import React from "react";
import { createRoot } from 'react-dom/client';
import { fromJS } from "immutable";
const createHistory = require("history").createHashHistory;
import { syncHistoryWithStore } from "react-router-redux";

import routes from "./routes";
import Root from "./Root";
import configureStore from "./redux/configureStore";
import app, { STORAGE_KEY } from "./libs/apiClient";
import LoadMask from "./common/components/Utils/LoadMask";
import { _login, _logout, setUserInfo } from "./redux/modules/login";
import { updateUploadProcess } from './redux/modules/schedule';

let initialState = {};

// rehydrate initialState for JS app
if (window.__INITIAL_STATE__) {
    initialState = window.__INITIAL_STATE__;

    // Transform into Immutable.js collections,
    // but leave top level keys untouched for Redux
    Object.keys(initialState).forEach((key) => {
        initialState[key] = fromJS(initialState[key]);
    });
}

const hashHistory = createHistory();

const store = configureStore(initialState, hashHistory);

const history = syncHistoryWithStore(hashHistory, store);
const root = createRoot(document.getElementById('app-container'));

let hasRendered = false;
const renderApp = () => {
    if (hasRendered) return;

    root.render(<Root history={history} routes={routes} store={store} />);

    hasRendered = true;
};

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools
root.render(<LoadMask loading />);

app.authenticate()
    .then(async (data) => {
        if(data.user.permission && typeof data.user.permission === 'string')
            data.user = await app.service('/user').patch(data.user._id, { permission: [] });
        store.dispatch(_login());
        store.dispatch(setUserInfo(data.user));
    })
    .catch(() => {
        // fail silently and log out
        localStorage.removeItem(STORAGE_KEY);
        store.dispatch(_logout());
    })
    .finally(() => {
        renderApp();
    });

// If the permissions for the rol has been uploaded, it's necesary re-authenticate the user
app.service('/api/roles').on('updateRole', data => {
    app.authenticate().then((data) => {
        if(data.user)
            store.dispatch(setUserInfo(data.user));
    }).catch((error) => {
        console.error(error);
    });
});


app.service('/api/schedule').on('progressUpload', data => {
    store.dispatch(updateUploadProcess(data));
})