import React from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import { renderField, renderSelectField, renderImagePicker, renderCurrency, renderNumber } from '../../Utils/renderField/renderField';
import { renderSwitch, renderDropdownSelection } from "../../Utils/renderField";
import { combine, validate, validatorFromFunction, validators } from "validate-redux-form";
import { IconOptions } from '../../../utility/constants';
import IconPlus from '../../../icons/plus';
import { required } from '../../../utility/form-validations';
import { getDefaultOverrideHours } from '../../../utility/constants';


const renderFeatures = ({ fields }) => (
    <div className="row">
        <div className="col-12 px-0">
            <hr />
            <div className="grid-title d-flex flex-colum flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
                <h3 className="ml-0 ml-sm-auto">
                    <strong> Features </strong>
                </h3>
                <button
                    type="button"
                    className="btn btn-secondary ml-auto flex-nowrap flex-sm-wrap"
                    onClick={(e) => {e.preventDefault();fields.push({})}}
                    style={{ padding: "0.375rem", borderRadius: "50%" }}
                >
                    <IconPlus className="smallIcon p-1"/>
                </button>
            </div>
            <br />
            <div className="row mx-0">
                {fields.map((item, i) => (
                    <div className="col-12 col-lg-6 col-xl-4 p-1" style={{ border: "1px solid #222" }} key={`holder${i}`}>
                        <div className="d-flex justify-content-end px-1 py-0">
                            <span
                                className="btn btn-secondary px-2 py-0"
                                onClick={(e) =>{e.preventDefault();  fields.remove(i)}}
                            >
                                X
                            </span>
                        </div>
                        <div className="d-table">
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle">
                                    <strong> Icon:&nbsp;</strong>
                                </div>
                                <div className="d-table-cell">
                                    <div className="mb-2 d-flex">
                                        <Field
                                            fluid
                                            selection
                                            search
                                            name={`${item}.icon`}
                                            component={renderDropdownSelection}
                                            className="selectIcons"
                                            placeholder="Select icon"
                                            options={IconOptions}
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle">
                                    <strong> Description:&nbsp;</strong>
                                </div>
                                <div className="d-table-cell w-100">
                                    <div className="mb-2">
                                        <Field
                                            name={`${item}.description`}
                                            component={renderField}
                                            className="form-control"
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle">
                                    <strong>Class:&nbsp;</strong>
                                </div>
                                <div className="d-table-cell w-100">
                                    <div className="mb-2">
                                        <Field
                                            fluid
                                            selection
                                            multiple
                                            name={`${item}.featureType`}
                                            component={renderDropdownSelection}
                                            options={[
                                                { key:'Standard', text: 'Standard', value: 'Standard' },
                                                { key:'Hourly', text: 'Hourly', value: 'Hourly' },
                                                { key:'Airport', text: 'Airport', value: 'Airport' },
                                            ]}
                                            placeholder="Select feature type"
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

const Form = (props) => {

    const { handleSubmit, cancel, formValues } = props;

    const positiveInt = (value) =>
        value && value < 1 ? "Must be at least 1" : undefined;

    const showDefaultHours = () => {
        const WINDOW_OVERRIDE = getDefaultOverrideHours(formValues);
        return WINDOW_OVERRIDE ? `default (${WINDOW_OVERRIDE} Hrs)` : "";
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">

                <div className="d-flex flex-wrap">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="type">Type</label>
                        <Field
                            name="type"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="description">Description</label>
                        <Field
                            name="description"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="vehicleCode">Vehicle Code</label>
                        <Field
                            name="vehicleCode"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="capacity">Capacity</label>
                        <Field
                            name="capacity"
                            component={renderField}
                            type="number"
                            className="form-control"
                            validate={positiveInt}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="minimumHours">Minimum Hours</label>
                        <Field
                            name="minimumHours"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="minimumTransfer">
                            Minimum Transfer
                        </label>
                        <Field
                            name="minimumTransfer"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="standardDwell">
                            Standard Dwell
                        </label>
                        <Field
                            name="standardDwell"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="standardBlock">
                            Standard Block
                        </label>
                        <Field
                            name="standardBlock"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="reductionRate1">
                            Reduction Rate 1
                        </label>
                        <Field
                            name="reductionRate1"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="reductionRate2">
                            Reduction Rate 2
                        </label>
                        <Field
                            name="reductionRate2"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="reductionRate3">
                            Reduction Rate 3
                        </label>
                        <Field
                            name="reductionRate3"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="reductionRate4">
                            Reduction Rate 4
                        </label>
                        <Field
                            name="reductionRate4"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="class">Luggage Space</label>
                        <Field
                            name="luggageSpace"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="class">Class</label>
                        <Field
                            name="class"
                            component={renderSelectField}
                            type="select"
                            labelKey="label"
                            valueKey="value"
                            options={[
                                { value: "PREMIERE", label: "PREMIERE" },
                                { value: "BUSINESS", label: "BUSINESS" },
                                { value: "EXPRESS", label: "EXPRESS" },
                            ]}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                        <label htmlFor="defaultRate">Rate</label>
                        <Field
                            name="defaultRate"
                            component={renderCurrency}
                            type="number"
                            placeholder="Rate"
                            min={0.01}
                        />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                        <label htmlFor="isHourlyVehicle" className="mt-3">Hourly vehicle?</label>
                        <Field name="isHourlyVehicle" component={renderSwitch} className="" />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                        <label htmlFor="picture">Photo</label>
                        <Field
                            photo={formValues && formValues.values && formValues.values.photo}
                            name="picture"
                            component={renderImagePicker}
                            useMediaUrl={false}
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="form-group">
                            <label className="mt-3">
                                In Fleet?
                            </label>
                            <Field
                                name="inFleet"
                                component={renderSwitch}
                                className=""
                            />
                        </div>
                        <div className="form-group">
                            <label className="">
                                Booking window override?
                            </label>
                            <div className="d-flex align-items-center">
                                <Field
                                    name="bookingOverride"
                                    component={renderSwitch}
                                    className=""
                                />
                                <span className="ml-4 d-flex align-items-center mb-2">
                                    {formValues &&
                                        formValues.values &&
                                        formValues.values.type &&
                                        formValues.values.bookingOverride &&
                                        showDefaultHours()}
                                </span>
                            </div>
                        </div>
                        {formValues &&
                        formValues.values &&
                        formValues.values.bookingOverride ? (
                            <div className="form-group">
                                <label className="">
                                    Hours
                                </label>
                                <Field
                                    name="bookingOverrideValue"
                                    component={renderNumber}
                                    decimalScale={2}
                                    suffix="hrs"
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="col-12 px-0 px-md-3 form-group">
                        <div className="col-12 px-0 form-group">
                            <FieldArray
                                name="features"
                                component={renderFeatures}
                            />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end">
                    <button type="button" onClick={cancel} className="btn btn-secondary m-1">
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary m-1">
                        Next
                    </button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "vehicleLocationForm",
    destroyOnUnmount: false,
    validate: data => {
        return validate(data, {
            type: validators.exists()("Required field"),
            description: validators.exists()("Required field"),
            vehicleCode: validators.exists()("Required field"),
            reductionRate1: validators.exists()("Required field"),
            reductionRate2: validators.exists()("Required field"),
            reductionRate3: validators.exists()("Required field"),
            reductionRate4: validators.exists()("Required field"),
            class: validators.exists()("Required field"),
            defaultRate: combine(
                validators.exists()('Required field'),
                validatorFromFunction(
                    (value) => typeof value === 'number' ? value > 0 : !isNaN(value) ? parseFloat(value) > 0 : false
                )()('Invalid Ammount'),
            ),
            bookingOverrideValue: combine(
                validators.exists()("Required field"),
                validatorFromFunction((value, bookingOverride) => {
                    if (bookingOverride) {
                        const WINDOW_OVERRIDE = getDefaultOverrideHours({
                            values: data,
                        });
                        if (
                            WINDOW_OVERRIDE &&
                            parseFloat(WINDOW_OVERRIDE) <=
                                parseFloat(data.bookingOverrideValue)
                        ) {
                            return true;
                        }
                    }
                    return false;
                })(data.bookingOverride)("Invalid Value")
            ),
            // picture: validators.exists()("Required field"),
            luggageSpace: validators.exists()("Required field"),
        });
    },
})(Form);
