import { connect } from "react-redux";
import AdditionalChargesGrid from "./AdditionalCharges";
import {actions} from "../../../../redux/modules/additionalCharges";

const mstp = (state) => ({
    ...state.additionalCharges,
    userInfo: state.login.userInfo,
})

const mdtp = { ...actions };

export default connect(mstp, mdtp)(AdditionalChargesGrid);
