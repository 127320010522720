import app from "../../libs/apiClient";
import { createActions, handleActions } from "redux-actions";
import { goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from 'sweetalert2';
import _ from 'lodash';
import { errorHandler } from '../../common/utility/constants';
const service = app.service("/user");

// Actions
export const create = () => (dispatch, getState) => {
    const formData = getState().form.user.values;

    for(let index = 0; index < formData.permission.length; index++ )
        formData.permission[index] = formData.permission[index]._id;

    dispatch(loading());
    service.create(formData).then((response) => {
            dispatch(goBack());
        })
        .catch((e) => {
            dispatch(errorHandler(e));
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

export const update = () => async (dispatch, getState) => {
    const formData = getState().form.user.values;

    dispatch(loading());

    try {
        const user = await service.patch(formData._id, formData);
        dispatch(goBack());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const destroy = (id) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        const remove = await service.remove(id);
        dispatch(find());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const query = { query: { $skip: (page - 1) * 10 } };
        const response = await service.find(query);
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const load2Update = (id) => async (dispatch, getState) => {
    console.log(id);
    dispatch(loading());

    try {
        const us = await service.get(id);
        console.log(us);
        dispatch(setUpdateData(us));
        dispatch(initializeForm("user", us));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const getRoles = (search) => (dispatch, getStore) => {
    const params = { query: { }};
    if(search){
        params.query = {
            $or: [ { name: { $regex: _.escapeRegExp(search), $options: 'i' }}, { description: { $regex: _.escapeRegExp(search), $options: 'i' }} ]
        }
    }
    return app.service('/api/roles').find(params).then(data => {
        if(data && data.total > 0)
            return { options: data.data };
        return [];
    }).catch ( error => {
        console.log(error);
        return [];
    });
}

export const { loading, setData, setPage, setUpdateData } = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
    SET_PAGE: (page) => ({ page }),
    SET_UPDATE_DATA: (updateData) => ({ updateData }),
});

// Reducers
const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setUpdateData]: (state, { payload: { updateData } }) => ({
        ...state,
        updateData,
    }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    loading: false,
    updateData: {},
};

export default handleActions(reducers, initialState);
