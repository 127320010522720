import React, { useState } from "react";
import { Link } from "react-router-dom";

const HotelTripReport = () => {

    const [selectedReport, setSelectedReport] = useState("");

    const handleSelectChange = (e) => {
        setSelectedReport(e.target.value);
    };

    return (
        <div className="row justify-content-center">
            <div className="col-6 pt-2">
                <div className="grid-container material-livery-list">
                    <div className="grid-title d-flex flex-column justify-content-center align-items-stretch align-items-sm-center">
                        <h1>
                            <strong>Trip Reports</strong>
                        </h1>
                    </div>

                    <div className="form-group">
                <label htmlFor="reportSelect">Choose a report</label>
                <select
                    id="reportSelect"
                    className="form-control"
                    value={selectedReport}
                    onChange={handleSelectChange}
                >
                    <option value="" disabled>
                        Select an option
                    </option>
                    <option value="hotel-Pickup">Hotel Pickup Time Report</option>
                    {/* <option value="anotherReport">Another Report</option> */}
                </select>
            </div>
            <Link
                to={`/reports/trip-report/${selectedReport}`}
                className="btn btn-block"
                style={{backgroundColor:'#D50032', color:'white'}}
            >
                SELECT
            </Link>
                </div>
            </div>
        </div>
    );
};

export default HotelTripReport