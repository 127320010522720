import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {hourlyOptions} from '../../../../utility/constants';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { renderSelectField } from '../../../Utils/renderField';
import { hourValidator } from '../../../../utility/form-validations';
import { combine, validate, validators, validatorFromFunction } from 'validate-redux-form';
import {
    renderDatePicker,
    renderField,
    renderFieldCheck,
    renderPlacesAutocomplete,
} from '../../../Utils/renderField/renderField';
import AutoSuggest from '../../../Utils/autosuggest';
import renderTimePicker from "../../../../../TimePicker";
import airlineCodes from '../../../../utility/airlines.json';
import airportCodes from '../../../../utility/airports.json';

let BookForm = (props) => {

    const { typeForm, itinerary, vehicle, handleSubmit, setDetails,
        duration, submitFailed, no_flight_information, time,
    } = props;

    const {
        from,
        to,
        flightsNumbers,
        isAirport,
    } = itinerary;

    return (
        <form name="typeForm" className="col-12" onSubmit={handleSubmit}>
            <div className="col-12 mb-3">
                <h3> Generate Quote </h3>
            </div>
            <div className="p-1 px-sm-4 py-md-3 bg-light d-flex flex-column flex-wrap radius-1">
                <div className="w-100 px-2">
                    <span style={{ color: "black" }}> Itinerary </span>
                </div>

                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-1 d-flex form-group">
                        <Field
                            inModal
                            name="transferQt"
                            component={renderSelectField}
                            clearable={false}
                            className="m-2 w-100"
                            options={[
                                {value: "transfer", label: "Transfer"},
                                {value: "hourly", label: "Hourly"},
                            ]}
                        />
                    </div>
                    <div className="flex-1">
                        {typeForm === 'hourly' && (
                            <div className={`d-flex flex-column mb-md-0 ${duration || submitFailed == false ? 'form-group' : ''}`}>
                                <Field
                                    inModal
                                    name="duration"
                                    labelKey="label"
                                    valueKey="value"
                                    component={renderSelectField}
                                    options={hourlyOptions}
                                    placeholder="Duration"
                                    padding
                                    className="m-2"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-between">
                    <div className="flex-1 d-flex form-group">
                        <Field
                            id="fromAutocomplete2"
                            name="from"
                            component={renderPlacesAutocomplete}
                            setDetails={setDetails}
                            lat={from.lat}
                            lng={from.lng}
                            pl="from"
                            label="Traveling from"
                            placeholder="From: address, airport, hotel..."
                            inModal
                        />
                    </div>
                    <div className="flex-1 d-flex form-group">
                        <Field
                            id="toAutocomplete2"
                            name="to"
                            component={renderPlacesAutocomplete}
                            setDetails={setDetails}
                            lat={to.lat}
                            lng={to.lng}
                            pl="to"
                            label="Traveling to"
                            placeholder="To: address, airport, hotel..."
                            inModal
                        />
                    </div>
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-1 d-flex form-group">
                        <label htmlFor="schedule">Departing date</label>
                        <Field
                            name="schedule"
                            numberOfMonths={1}
                            component={renderDatePicker}
                            className="flex-1 w-100 m-2 p-0"
                        />
                    </div>

                    <div className="flex-1 d-flex form-group">
                        <label> Time </label>
                        <Field
                            inModal
                            id="timepicker1"
                            name="time"
                            placeholder="12:00 PM"
                            component={renderTimePicker}
                            style = {{height: 'none'}}
                            className="pt-4 pl-2"
                            Value={time}
                        />
                    </div>
                </div>

                {isAirport && (
                    <div className="px-0 mx-0">
                        <div className="d-flex flex-column flex-lg-row justify-content-between">
                            <div className="flex-1 d-flex form-group">
                                <label htmlFor="airline">Airline</label>
                                <Field
                                    name="airline"
                                    component={AutoSuggest}
                                    keyLabel='label'
                                    keyId='_id'
                                    searchKey="company"
                                    placeholder="MC"
                                    suggestions = {airlineCodes}
                                    className="w-100 m-2"
                                    inputClassName="pt-4"
                                    disabled={no_flight_information && no_flight_information}
                                    onSelectOption={value=>props.onSelectAirline(value)}
                                />
                            </div>

                            <div className="flex-1 d-flex form-group">
                                <label htmlFor="fligth_number">Flight Number</label>
                                <Field
                                    name="fligth_number"
                                    component={AutoSuggest}
                                    showNotFoundMsg
                                    notFoundMsg="Flight info not found"
                                    keyLabel='label'
                                    keyId='index'
                                    searchKey="flightNumber"
                                    placeholder="234"
                                    onChange={ (event, value) => props.getFlightInfo(value) }
                                    onSelectOption={value => props.onSelectFlight(value)}
                                    disabled={no_flight_information && no_flight_information}
                                    suggestions={flightsNumbers}
                                    typeInput="text"
                                    className="flex-1 w-100 m-2"
                                    inputClassName="pt-4"
                                    async
                                />
                            </div>
                        </div>
                        <div className="d-flex mx-2">
                            <Field
                                name="no_flight_information"
                                label="No flight information"
                                component={renderFieldCheck}
                            />
                        </div>
                    </div>
                )}


                <div>
                    <hr className="mb-4 mt-0 mx-2"/>
                </div>
                <div className="d-flex flex-column justify-content-between mb-4">
                    <div className="container-promo">
                        <div className="col-12 px-0">
                            <Field
                                name="bookingCode"
                                component={renderField}
                                type="text"
                                className="form-control p-2 "
                                placeholder="Booking Code"
                            />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-between px-2">
                    <button className="btn btn-primary btn-block mw-100 w-100">
                        LOAD VEHICLES
                    </button>
                </div>
            </div>
            <div className="col-12 px-1 px-sm-4">
                <hr />
            </div>
        </form>
    );
};

const selector = formValueSelector('typeForm');
BookForm = connect(state => {
    const typeForm = selector(state, 'transferQt');
    const duration = selector(state, 'duration');
    const no_flight_information = selector(state, 'no_flight_information');
    const schedule = selector(state, 'schedule');
    let time = selector(state, 'time');
    if(time){
        if(no_flight_information){
            if(!['00', '15', '30', '45'].includes(time.minutes)){
                let minutes = parseInt(time.minutes);
                time.minutes = minutes < 15 ? '00' : minutes < 30 ? '15' : minutes < 45 ? '30' : '45'
            }
        }
        time = moment(`${schedule ? schedule.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')} ${time.hour}:${time.minutes}`);
    } else time = undefined;
    return { typeForm, schedule, time, duration, no_flight_information };

})(BookForm);

const validAddress = validatorFromFunction((value, address) => {
    return typeof address === 'object' && address.state && address.city &&
        address.lat && address.lng && address.postal_code ? true : false
});

const validateAirilineOrFlight = validatorFromFunction( (value, no_flight_information = false, isFlightNumber = false) =>{
    if(no_flight_information)
        return true
    else
        if(!value) return false;
        else if(isFlightNumber)
            return typeof value === 'object' ? true : false;
        else return true;
})

export default reduxForm({
    form: "typeForm",
    initialValues: {
        transferQt: 'transfer'
    },
    validate: (data, props) => {
        return validate(data, {
            from: combine(
                validators.exists()('Required Field'),
                validAddress(props.itinerary.from)('Please select an exact address')
            ),
            to: combine(
                validators.exists()('Required Field'),
                validAddress(props.itinerary.to)('Please select an exact address')
            ),
            duration: validators.exists()('Required Field'),
            schedule: validators.exists()('Required Field'),
            time: combine(
                validators.exists()('Required Field'),
                hourValidator(data.schedule)('Please select a time in the future')
            ),
            airline: validateAirilineOrFlight(data.no_flight_information)('Required Field'),
            fligth_number: validateAirilineOrFlight(data.no_flight_information,true)('flight cannot be verified'),
        });
    }
})(BookForm);
