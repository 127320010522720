import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import app from "../../../../../../libs/apiClient";
import ExternalLink from "../../../../../icons/externalLink";
import { renderField, renderSearchSelect } from "../../../../Utils/renderField/renderField";
import _ from 'lodash';
import { settingsAirlinesLocations } from "../../../../../../routesConfiguration/paths";


const Form = ({
    handleSubmit,
    showSaveBtn=true,
    cancel,
 }) => {

    const loadOptions = (search) => {
        return new Promise(async (res, rej) => {
            try {
                const paramsScheduleLocation = {
                    $paginate: false,
                    $limit: 10,
                }
                const paramsAirport = {
                    $paginate: false,
                    $limit: 10,
                }
                if(search){
                    paramsAirport.$or = [
                        { code: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { name: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { description: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { city: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { state: { $regex: _.escapeRegExp(search), $options: 'i' }},
                    ];
                    paramsScheduleLocation.$or = [
                        { name: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { street: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { city: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { state: { $regex: _.escapeRegExp(search), $options: 'i' }},
                    ]
                }
                const [resLocation, resAirport] = await Promise.all([
                    app.service('/api/scheduleLocation').find({ query: paramsScheduleLocation }),
                    app.service('/api/airport').find({ query: paramsAirport })
                ])

                const maxLocations = Math.min(10 - Math.min(5, resAirport.length), resLocation.length);
                const maxAirports = Math.min(10 - Math.min(5, resLocation.length), resAirport.length);

                let result = [];
                for(let i = 0; i < maxLocations; i++){
                    result.push({
                        ...resLocation[i],
                        label: `${resLocation[i].name}, ${resLocation[i].city}`,
                        type: 'location',
                    });
                }
                for(let i = 0; i < maxAirports; i++){
                    result.push({
                        ...resAirport[i],
                        label: `${resAirport[i].code} - ${resAirport[i].name}`,
                        type: 'airport',
                    });
                }

                result = result.sort((a, b) => a.label.localeCompare(b.label));
                res({ options: result });
            } catch (error) {
                rej(error);
            }
        })
    }

    return (
        <form onSubmit={handleSubmit} id="scheduleAirportLocationForm">
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <div className="padding-15 p-sm-0 py-sm-1">
                        <div className="row">
                            <div className="form-group col-12">
                                <div className="w-100 d-flex justify-content-between">
                                    <label htmlFor="location"> Location </label>
                                    <Link to={settingsAirlinesLocations} target="_blank" className="d-flex align-items-center text-primary">
                                        Locations
                                        <div style={{ width: '15px', height: '13px' }} className="d-flex align-items-center pl-1">
                                            <ExternalLink className="w-100 h-100"/>
                                        </div>
                                    </Link>
                                </div>
                                <Field
                                    name="location"
                                    component={renderSearchSelect}
                                    className=""
                                    valueKey="_id"
                                    labelKey="label"
                                    placeholder="Location or Airport"
                                    loadOptions={loadOptions}
                                    allKey
                                />
                            </div>
                            <div className="form-group col-12">
                                <label htmlFor="description"> Schedule Description </label>
                                <Field
                                    name="description"
                                    component={renderField}
                                    className="form-control"
                                    placeholder="WHADL"
                                />
                            </div>
                        </div>
                        {showSaveBtn ? (
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row">
                                        <button className="btn btn-primary m-1 px-4" type="submit">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        </form>
    );
 }

const scheduleAirportLocationForm = reduxForm({
    form: "scheduleAirportLocationForm",
    destroyOnUnmount: false,
    validate: (data, props) =>{
        const errors = {};
        if(!data.location) errors.location = "Required field";
        return errors;
    }
})(Form);

export default scheduleAirportLocationForm;
