import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderTextArea } from '../../../Utils/renderField/renderField';


const SpecialNotesForm = (props) =>{

    const { handleSubmit, cancel } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="text-primary">
                    <strong> SPECIAL NOTES </strong>
                </div>
                <Field
                    name="specialNotes"
                    component={renderTextArea}
                    cols={3}
                />
            </div>
            <div className="d-flex justify-content-end flex-column flex-md-row">
                <button type="button" className="btn btn-outline-primary m-1"
                    onClick={cancel}
                >
                    CANCEL
                </button>
                <button className="btn btn-primary m-1">
                    SAVE
                </button>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'specialNotesForm',
})(SpecialNotesForm);