import React from 'react';


const Pagination = ({
    total,
    page,
    find,
    itemsPerPage = 10
}) => {

    let totalPages = Math.max(Math.ceil(total / itemsPerPage), 1);

    let firstPage = Math.max(page - 2,1);
    let lastPage = Math.min(page + 2, totalPages);

    const listPages = [];
    for(let i = firstPage; i <= lastPage; i++)
        listPages.push(i);

    if(total === 0)
        return null;

    return (
        <nav>
            <ul className="pagination pt-1 justify-content-end">
                {(page - 2) > 1 && (
                    <li className={`${page == 1 ? 'disabled' : ''} page-item`}>
                        <button className="page-link" aria-label="First"
                            disabled={page == 1}
                            onClick={()=>find(1)}
                        >
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">First</span>
                        </button>
                    </li>
                )}
                <li className={`${ page == 1 ? 'disabled' : ''} page-item`}>
                    <button className="page-link" href="#" aria-label="Previous"
                        disabled={page == 1}
                        onClick={()=>find(page-1)}
                    >
                        <span aria-hidden="true">&#60;</span>
                        <span className="sr-only">Previous</span>
                    </button>
                </li>
                {listPages.map((item, index) => {
                    return (
                        <li key={index} className={`${ item==page ? 'active' : ''} page-item`}>
                            <button type="button" className="page-link"
                                disabled={item==page}
                                onClick={()=>find(item)}
                            >
                                {item}
                            </button>
                        </li>
                    )
                })}
                <li className={`${ page == totalPages ? 'disabled' : ''} page-item`}>
                    <button className="page-link" aria-label="Next"
                        disabled={page == totalPages}
                        onClick={()=>find(page+1)}
                    >
                        <span aria-hidden="true">&#62;</span>
                        <span className="sr-only">Next</span>
                    </button>
                </li>
                {(page + 2) < totalPages && (
                    <li className="page-item">
                        <button className="page-link" aria-label="Next"
                            onClick={()=>find(totalPages)}
                        >
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Last</span>
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    )
}

export default Pagination;