import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import XLSX from 'xlsx';
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm, blur } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";
import { actions as breadcrumbActions } from "../breadcrumb";

const LOADING = "LOADING_CREW_ADMIN_ZONE";
const LOADING_FORM = "LOADING_FORM_ZONE";
const SET_DATA = "SET_ZONE_DATA";
const SET_PAGE = "SET_PAGE_ZONE";
const ITEM = "ITEM_ZONE";
const SET_SEARCH = "SET_SEARCH_ZONE";
const ALL_ZIPS = "ALL_ZIPS_ZONES";


export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $sort: { name: 1, code: 1 },
            $limit: 5,
            $skip: (page - 1) * 5
        }};
        const search = getStore().crewAdmin.zones.search;
        if(search){
            params.query.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { code: { $regex: _.escapeRegExp(search), $options: 'i' }}
            ]
        }

        const data = await app.service('/api/zone').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch, getStore) => {
    let result = false
    dispatch(setLoadingForm(true));
    let { page } = getStore().crewAdmin.zones;
    try {
        await app.service('/api/zone').create(data);
        result = true;
        swal.fire({
            type: "success",
            title: "SUCCESS",
            text: "The zone has been created",
        }).then(()=>{
            dispatch(find(page));
        })
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
    return result;
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let data = await app.service('/api/zone').get(id);
        let zips = await app.service('/api/zip').find({ query: { $paginate: false, zone: id }});
        let tags = await app.service('/api/tags').find({ query: { $paginate: false, zone: id }});
        data.zips = zips;
        data.tags = tags;
        const { updateCustomLabel } = breadcrumbActions;
        dispatch(updateCustomLabel(id, data.name));
        await dispatch(initializeForm('zoneForm', data));
        dispatch(setItem(data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (data) => async (dispatch, getStore) =>{
    let result = false;
    dispatch(setLoadingForm(true));
    let { page } = getStore().crewAdmin.zones;
    try {
        await app.service('/api/zone').update(data._id, data);
        result = true;
        swal.fire({
            type: "success",
            title: "SUCCESS",
            text: "The zone has been updated",
        });
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
    return result;
}

export const destroy = (id) => async (dispatch, getStore) => {
    let result = false;
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.zones;
    try {
        await app.service('/api/zone').remove(id);
        result = true;
        swal.fire({
            type: "success",
            title: "SUCCESS",
            text: "The zone has been removed"
        }).then(() => {
            dispatch(find(page));
        })
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
    return result;
};

export const setSearch = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH, search });
    dispatch(find());
}

export const downloadZips = (zoneId) => (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    try {

        const { values = {} } = getStore().form.zoneForm;

        const fileName = values.name ? `zips_${values.name}` : 'zips';
        if(!values.zips)
            values.zips=[];

        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title:  fileName,
            Subject: "Zips",
            Author: "BBC EXPRESS",
            CreatedDate: new Date(),
        };

        // Head of workbook in spreadsheet
        let ws_data = [[ "zip", "city", "county", "state" ]];

        // Create the array of data for add to Spreadsheet
        for(let index = 0; index < values.zips.length; index++)
            ws_data.push([
                `${values.zips[index].zip_code}`,
                `${values.zips[index].city || ''}`,
                `${values.zips[index].county || ''}`,
                `${values.zips[index].state || ''}`,
            ]);
        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        // Columns width in spreadsheet
        let wscols = [ {wch:8}, {wch:25}, {wch:25}, {wch:8}];
        ws['!cols'] = wscols;

        // Add the data to new sheet in the spreadsheet
        XLSX.utils.book_append_sheet(wb, ws, fileName);
        const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
            const view = new Uint8Array(buf); // create uint8array as viewer
            for (let i = 0; i < s.length; i++)
                view[i] = s.charCodeAt(i) & 0xff; // convert to octet
            return buf;
        }

        // Save the spreadsheet
        saveAs(
            new Blob([s2ab(wbout)], { type: "application/octet-stream", }),
            `${fileName}.xlsx`
        );


    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const uploadZips = (file) => async (dispatch, getStore) => {
    let result = false;
    dispatch(setLoadingForm(true));
    try {
        if(file) {
            let workbook = XLSX.read(file.file, { type:"array" });
            let worksheet = workbook.Sheets[workbook.SheetNames[0]];
            let data = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false })
            if(!data)
                throw 'Cannot read the file';

            let zipIndex = data[0].findIndex(item => item.toLowerCase().indexOf('zip') != -1);
            let cityIndex = data[0].findIndex(item => item.toLowerCase().indexOf('city') != -1);
            let countyIndex = data[0].findIndex(item => item.toLowerCase().indexOf('county') != -1);
            let stateIndex = data[0].findIndex(item => item.toLowerCase().indexOf('state') != -1);

            if(zipIndex == -1 || cityIndex == -1)
                throw 'Cannot find the headers for zip or city';

            const { values } = getStore().form.zoneForm;
            let zips = values && values.zips ? values.zips : []

            for(let i = 1; i < data.length; i++){
                if(zips.find(item => item.zip_code.indexOf(data[i][zipIndex]) != -1 ) == undefined)
                    zips.push({
                        zip_code: data[i][zipIndex],
                        city: data[i][cityIndex],
                        county: data[i][countyIndex],
                        state: data[i][stateIndex],
                    });
            }

            zips = zips.sort((a, b) => a.zip_code.localeCompare(b.zip_code));

            dispatch(change('zoneForm', 'zips', zips));
            result = true;
        }
        return result;
    } catch(error) {
        throw error;
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const getAllZips = () => dispatch => {
    app.service('/api/zip').find({ query: { $paginate: false, $populate: ['zone'] } }).then((response) => {
        dispatch(setZips(response));
    }).catch((error)=>{
        console.error(error);
    })
}

export const clearData = () => dispatch => {
    dispatch(setItem({}));
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE, page });
export const setData = (data) => ({ type: SET_DATA, data });
export const setItem = (item) => ({ type: ITEM, item });
export const setZips = (allZips) => ({ type: ALL_ZIPS, allZips });

export const actions = {
    find,
    create,
    clearData,
    setSearch,
    load2Update,
    uploadZips,
    downloadZips,
    getAllZips,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [ITEM]: (state, { item }) => ({ ...state, item }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH]: (state, { search }) => ({...state, search }),
    [ALL_ZIPS]: (state, { allZips }) => ({ ...state, allZips }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    allZips: [],
    search: undefined,
};

export default handleActions(reducers, initialState);
