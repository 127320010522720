import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import Table from "../../Utils/Grid";

import { activeFormatter } from "../../Utils/Acciones/Acciones";
import { BreakLine } from '../../Utils/tableOptions';
import swal from 'sweetalert2';
import IconWarning from '../../../icons/warning';
import Pagination from '../../Utils/pagination';
import LoadMask from '../../Utils/LoadMask';
import { reservationsAirlineUploads } from '../../../../routesConfiguration/paths';
const watch = require("../../../../../assets/img/icons/watch.png");

const GridSchedules = ({
    data,
    page,
    find,
    destroy,
    loading,
    killReadProcess,
    permission,
}) => {

    const killProcessWarning = (id) => {
        swal.fire({
            type: "warning",
            title: "Stop processing file?",
            html: `<h5>
                If the upload is stuck, select yes. If the problem continues please send the upload file and a detailed message to <a target="_blank" href="mailto:infosystems@connectbbc.com">infosystems@connectbbc.com.</a>
            </h5>`,
            showCancelButton: true,
            confirmButtonText: "Yes!",
            cancelButtonText: "No",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            reverseButtons: true,
        }).then(({ value }) =>{
            if(value){
                killReadProcess(id).then(result => {
                    if(result) find(page);
                });
            }
        })
    }

    const killProcessBtn = (id, row) => {
        return (
            <button onClick={()=>killProcessWarning(id)}
                className="btn btn-primary p-0"
                title="Mark as failed"
            >
                <IconWarning className="smallIcon p-1"/>
            </button>
        )
    }

    return (
        <div className="mt-3">
            <LoadMask loading={loading} blur>
                {data && data.data && data.data.length > 0 ? data.data.map((row, index) => {
                    let cell = row._id;
                    return (
                        <div className="rounded mb-3" key={row._id} style={{ border: '2px solid #707070' }}>
                            <div className="p-3 px-lg-4 d-flex flex-column flex-md-row-reverse" key={index}>
                                <div className="row d-flex justify-content-end align-content-start mx-md-3">
                                    <div style={{ marginRight: "-15px" }}>
                                        {activeFormatter({
                                            ver: ['error', 'done', 'verifying'].includes(row.status) ? reservationsAirlineUploads : undefined,
                                            eliminar: permission.find(({removeAirlineSchedule}) => !!removeAirlineSchedule) && ['error', 'done', 'verifying'].includes(row.status) ? destroy : undefined,
                                            adicional: !['error', 'done', 'verifying'].includes(row.status) ? killProcessBtn : undefined
                                        })(cell, row)}
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <div className="d-flex flex-wrap">
                                        <div className="col-12 col-md-6 pl-md-0">
                                            {row.airline && (
                                                <div className="d-flex mt-3 mt-md-0 justify-content-start flex-1">
                                                    <label> <strong> Airline:&nbsp;</strong> </label>
                                                    <label className="text-truncate"> {row.airline.code} - {row.airline.company} </label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-6 pr-md-0">
                                            {row.fileType && (
                                                <div className="d-flex">
                                                    <label> <strong> File Type:&nbsp;</strong> </label>
                                                    <label> {row.fileType} </label>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <div className="col-12 col-md-6 pl-md-0">
                                            {row.airports && row.airports.length > 0 ? (
                                                <div className="d-flex">
                                                    <label> <strong> Airports:&nbsp;</strong> </label>
                                                    <label className="text-truncate"> {row.airports.map((item, i)=> i > 0 ? `, ${item}` : item)} </label>
                                                </div>
                                            ) : ''}
                                        </div>
                                        <div className="col-12 col-md-6 pr-md-0">
                                            {row.dates ? (
                                                <div className="d-flex">
                                                    <label> <strong> Dates:&nbsp;</strong> </label>
                                                    <label className="text-truncate"> {row.dates} </label>
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap">
                                        <div className="col-12 col-md-6 pl-md-0">
                                            {row.status && (
                                                <div className="d-flex flex-column">
                                                    <div className="d-flex">
                                                        <label> <strong> Status:&nbsp;</strong> </label>
                                                        {row.reloaded && row.reloaded === true && (
                                                            <label> (reloaded)&nbsp;</label>
                                                        )}
                                                        {row.status === 'in progress'
                                                            ? <span> In progress... </span>
                                                            : row.status === 'done'
                                                                ? <span> Done! </span>
                                                                : row.status === 'waiting'
                                                                    ? <span> Waiting </span>
                                                                    : row.status === 'verifying'
                                                                        ? <span> Verifying </span>
                                                                        : <span> Error </span>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-12 col-md-6 pr-md-0">
                                            <div className="d-flex">
                                                {row.status === 'done' ? (
                                                    <React.Fragment>
                                                        <label> <strong> Results:&nbsp;</strong> </label>
                                                        <label>
                                                            {row.totalSuccess !== undefined && (`${row.totalSuccess} Success`)}
                                                            {row.totalFailed !== undefined && (`, ${row.totalFailed} Failed`)}
                                                        </label>
                                                    </React.Fragment>
                                                ) : (row.msgError ? (
                                                        <React.Fragment>
                                                            <label><strong> Description:&nbsp;</strong></label>
                                                            <label> {row.msgError} </label>
                                                        </React.Fragment>
                                                    ) : row.status === 'in progress' || row.status === "waiting" ?
                                                        <React.Fragment>
                                                            <label><strong> Progress:&nbsp;</strong></label>
                                                            <div className="w-100 pt-1">
                                                                <div className="progress">
                                                                    <div className="progress-bar bg-dark" role="progressbar"
                                                                        style={{ width: `${((row.current || 0) * 100)/(row.totalRows)}%` }}
                                                                        aria-valuenow={`${((row.current || 0) * 100)/(row.totalRows)}`}
                                                                        aria-valuemin="0" aria-valuemax="100"
                                                                    ></div>
                                                                </div>
                                                                <div className="w-100 d-flex justify-content-end">
                                                                    <small className="verySmall bold">{(((row.current || 0) * 100)/(row.totalRows)).toFixed(2)}% ({row.current || 0}/{row.totalRows} trips)</small>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                        : ''
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) : (
                    <div className="list-item-rounded" style={{ border: '2px solid #707070' }}>
                        <div className="p-3 d-flex justify-content-center align-items-center">
                            <h4> No data </h4>
                        </div>
                    </div>
                )}
            </LoadMask>
            <Pagination
                total={data.total}
                page={page}
                find={find}
            />
        </div>
    )
}

export default GridSchedules;
