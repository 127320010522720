import React from "react";
import Modal from "react-responsive-modal";
import { Field, reduxForm } from "redux-form";
import { TableHeaderColumn } from "react-bootstrap-table";

import Table from "../../Utils/Grid";
import RowActions from "./RowActions";
import DownloadModal from "./DownloadModal";
import { BreakLine } from "../../Utils/tableOptions";
import { renderSwitch } from "../../Utils/renderField";
import { dateFormatter } from "../../Client/Grid/ClientGrid";
import { renderDropdownSelection, renderField, renderSelectField } from "../../Utils/renderField/renderField";
import renderDatePicker from "../../Utils/renderField/renderDatePicker";
import moment from "moment";
import IconPlus from '../../../icons/plus';
import IconFilter from '../../../icons/filter';
import { getPathWithParams, reservationsCharterBookingsDetailId } from "../../../../routesConfiguration/paths";
// import BookingModal from './bookModal/BookingModal';


const calcDiff = (date) => {
    let now = moment();
    date = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    let difYear = date.diff(now, 'years');
    let difMonth = date.diff(now, 'months');
    let difDay = date.diff(now, 'days');
    let difHour = date.diff(now, 'hours');
    let difMinute = date.diff(now, 'minutes');

    if(difYear > 0)
        return difYear + ' Y';
    else if(difMonth > 0)
        return difMonth + ' M';
    else if(difDay > 0)
        return difDay + ' D';
    else if(difHour > 0)
        return difHour + ' H';
    else if(difMinute > 0)
        return difMinute + ' m';
    else
        return 'T'
}

const ReservationList = ({
    data,
    page,
    userInfo: { permission },
    loading,
    find,
    open,
    size,
    history,
    handleModal,
    download,
    clearDates,
    resendEmail,
    destroyReserv,
    modalReservations,
    openModalReservation,
}) => {

    return (
        <div className="row">
            <div className="col-sm-12 pt-2">
                <div className="grid-container">
                    {permission && permission.find(({ readReservations }) => !!readReservations) && (
                        <React.Fragment>
                            <div className="mb-1 d-flex justify-content-between align-items-strech flex-column flex-md-row">
                                <div className="mb-2 align-self-end w-100 d-flex flex-column-reverse flex-md-column" style={{ flex: 1 }}>
                                    <div className="d-flex flex-column flex-md-row">
                                        <div style={{ flex: 1 }}>
                                            <Field
                                                name="type"
                                                type="input"
                                                component={renderField}
                                                placeholder="Search by Confirmation No, livery ID, Passenger, Pick up, Dropp off, Vehicle type"
                                                cb={()=>find(1, size)}
                                            />
                                        </div>
                                        <div className="d-flex mt-2 mt-md-0 ml-0 ml-md-3 align-items-center justify-content-end">
                                            <div className="px-1">
                                                <IconFilter className="smallIcon"/>
                                            </div>
                                            <Field
                                                name="filter"
                                                component={renderDropdownSelection}
                                                className="d-flex justify-content-end"
                                                placeholder="Filter by"
                                                clearable
                                                scrolling
                                                options={[
                                                    {key: "Upcoming", text: "Upcoming", value: "upcoming"},
                                                    {key: "Booking Date", text: "Booking Date", value: "booking"},
                                                    {key: "Passenge", text: "Passenger", value: "passenger"},
                                                ]}
                                                cb={()=>find(1, size)}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row my-2">
                                        <div className="d-flex flex-column flex-md-row" style={{ flex: 1 }}>
                                            <div style={{ flex: 1}}>
                                                <Field
                                                    name="startDate"
                                                    placeholder="Start Date"
                                                    component={renderDatePicker}
                                                    showButton={false}
                                                    timeFormat={false}
                                                    cb={()=>find(1)}
                                                />
                                            </div>
                                            <div style={{ flex: 1}}>
                                                <Field
                                                    name="endDate"
                                                    placeholder="End Date"
                                                    className="ml-0 ml-md-2 mt-2 mt-md-0"
                                                    component={renderDatePicker}
                                                    timeFormat={false}
                                                    showButton={false}
                                                    cb={()=>find(1)}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-end align-items-end">
                                            <button
                                                type="button"
                                                className="btn btn-danger ml-1"
                                                onClick={() => clearDates()}
                                            >
                                                X
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column justify-content-end" style={{ flex: 1 }}>
                                    <div className="d-flex justify-content-end">
                                        <label htmlFor="unassigned">Show Canceled&nbsp;</label>
                                        <Field
                                            name="canceled"
                                            component={renderSwitch}
                                            height={22}
                                            width={44}
                                            cb={()=>find(1, size)}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-end" >
                                        <label htmlFor="unassigned">No livery ID&nbsp;</label>
                                        <Field
                                            name="unassigned"
                                            component={renderSwitch}
                                            height={22}
                                            width={44}
                                            cb={()=>find(1, size)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <br />
                            <Table
                                onPageChange={(page)=>find(page, size)}
                                data={data}
                                page={page}
                                loading={loading}
                                sizePerPage={size}
                                onRowClick={(row) => history.push(getPathWithParams(
                                    reservationsCharterBookingsDetailId,
                                    "id",
                                    row._id
                                ))}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataFormat={dateFormatter}
                                    isKey
                                    dataField="_id"
                                    className="hide-column-header"
                                    dataFormat={(cell,row)=>(
                                        <div className="d-flex flex-row flex-md-column">
                                            <div style={{ flex: 1 }} className="d-flex flex-column flex-md-row">
                                                <div className="d-flex flex-column" style={{ flex: 1 }}>
                                                    <label> <strong> PU Date: </strong> {row.puTimeNoTimezone
                                                        ? moment.parseZone(row.puTimeNoTimezone).format('ddd MMM DD YYYY')
                                                        : moment.parseZone(row.puTime).format('ddd MMM DD YYYY')}
                                                    </label>
                                                    <label> <strong> Passenger: </strong> {row.guestFirstNm} {row.guestLastNm} </label>
                                                    <label className="d-none d-md-inline"> <strong> Vehicle: </strong> {row.vehicleType} </label>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <label> <strong> PU Address: </strong> {row.puAddress} </label>
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <label> <strong> DO Address: </strong> {row.doAddress} </label>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-md-row">
                                                <div style={{ flex: 1 }} className="d-none d-md-flex flex-column">
                                                    {row.flightAirline && row.flightNumber && (
                                                        <React.Fragment>
                                                            <h5> {row.flightAirline.code} </h5>
                                                            <h5> {row.flightNumber.flightNumber} </h5>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-column h-100">
                                                    <div className="d-flex justify-content-end">
                                                        <span className="t-center px-3"
                                                            style={{
                                                                background: "#C0C0C0",
                                                                borderRadius: "0.4rem",
                                                                fontSize: "calc(0.8em + 0.2vw)"
                                                            }}
                                                        >
                                                            {calcDiff(row.puTimeNoTimezone ? row.puTimeNoTimezone : row.puTime)}
                                                        </span>
                                                    </div>
                                                    <div className="d-md-none d-flex flex-column align-items-end" style={{ flex: 1 }}>
                                                        {row.flightAirline && row.flightNumber && (
                                                            <React.Fragment>
                                                                <h5> {row.flightAirline.code} </h5>
                                                                <h5> {row.flightNumber.flightNumber} </h5>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                    <div className="mt-2 px-3 d-flex"
                                                        style={{
                                                            background: "#C0C0C0",
                                                            borderRadius: "0.4rem",
                                                        }}
                                                    >
                                                        <small style={{ fontSize: "calc(0.8em + 0.2vw)" }}> {row.confirmationNo} </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                >
                                </TableHeaderColumn>
                            </Table>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default reduxForm({
    form: "legacyReservationListFilter",
    destroyOnUnmount: false,
})(ReservationList);
