import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../../Utils/renderField/renderField";
import LoadMask from "../../Utils/LoadMask";
import Detail from './RefundDetail';
import { required } from '../../../utility/form-validations';
import { validate, validators, combine } from "validate-redux-form";
import numeral from 'numeral';

const RefundList = ({
    loading,
    handleSubmit,
    history,
    handleModal,
    data,
    dataNotification,
    showNotification,
    onlyCancelTrip,
    userInfo: { permission }
}) => (

    <div className="row">
        <div className="col-sm-12 pt-2 mt-5">
            <div className="grid-container">
                <LoadMask loading={loading} dark blur>
                    {data && Object.keys(data).length && (
                        <React.Fragment>

                            {showNotification && (
                                <div className="col-12 p-4 mb-5 d-flex align-items-center justify-content-center"
                                    style={{borderRadius: "10px", color: "white", backgroundColor: "#D52732"}}
                                >
                                    <p className="my-0">
                                        {dataNotification.canceled ? 'You have succefully canceled trip': 'You have succefully refunded the trip'} {dataNotification.reservationId}. Refund Amount: $ {numeral(dataNotification.refund[0].refundAmount).format("0,0.00")} Transaction ID: {dataNotification.refund[0].transactionId}
                                    </p>
                                </div>
                            )}

                            <Detail
                                reservation={data}
                                handleAction={handleModal}
                                handleCancel={handleModal}
                                onlyCancelTrip={onlyCancelTrip}
                                history={history}
                                permission={permission}
                            />
                        </React.Fragment>
                    )}
                </LoadMask>
            </div>
        </div>
    </div>
);

export default RefundList;
