import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "./GroupRateForm";
import LoadMask from "../../Utils/LoadMask";


class Update extends Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.autocomplete = null;
    }

    componentDidMount() {
        const options = {
            type: ["cities"],
            componentRestrictions: { country: "us" },
        };
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            options
        );
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
        this.props.load2Update(this.props.match.params.id);
    }

    handlePlaceSelect() {
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;
        const name = addressObject.name;
        const location = addressObject.geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        address.forEach((item) => {
            // GOOGLE SETS THE STATE IN administrative_area_level_1
            if (item.types.includes("administrative_area_level_1")) {
                this.props.setState(item.short_name);
            }
            // GOOGLE SETS THE CITY IN locality
            if (item.types.includes("locality")) {
                this.props.setCity(item.long_name);
            }
        });
        this.props.setAddress(addressObject.formatted_address, lat, lng);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentWillUnmount(){
        this.props.clear()
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateLocations }) => updateLocations)){
                    const options = {
                        type: ["cities"],
                        componentRestrictions: { country: "us" },
                    };
                    this.autocomplete = new google.maps.places.Autocomplete(
                        document.getElementById("autocomplete"),
                        options
                    );
                    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
                    this.props.load2Update(this.props.match.params.id);
                }
            }
        }
    }

    render() {
        // state

        // bind
        const { update, loading, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {permission && permission.find(({updateLocations}) => !!updateLocations) && (
                        <LoadMask loading = { loading === 1 ? true : false } blur>
                            <Form onSubmit={update} />
                        </LoadMask>
                    )}
                </div>
            </div>
        );
    }
}

export default Update;
