import React from "react";

const IconTicket = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            className={className}
        >
            <path
                fill="currentColor"
                d="m348-325 368-101q17-5 25-16t3-28q-5-17-18-23.5t-29-2.5l-103 27-166-162-52 12 103 181-111 31-52-43-29 10 61 115Zm472 165H140q-25 0-42.5-17.5T80-220v-153q37-8 61.5-37.5T166-480q0-40-24.5-70T80-587v-153q0-25 17.5-42.5T140-800h680q25 0 42.5 17.5T880-740v520q0 25-17.5 42.5T820-160Z"
            />
        </svg>
    );
};

export default IconTicket;
