import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderSelectField,
    renderSearchSelect,
    renderImagePicker,
    renderSwitch,
    renderNumber,
} from "../../Utils/renderField/renderField";
import { required, email, length, confirmation } from "redux-form-validators";
import LoadMask from "../../Utils/LoadMask";
import { settingsUserAccountsUsers } from "../../../../routesConfiguration/paths";


const Form = (props) => {
    const { handleSubmit, photo, updateData, getRoles } = props;
    const minValue = (min) => (value) =>
        value && value.length < min && value.length > 0
            ? `is too short (minimum is ${min} characters)`
            : undefined;
    const minValue6 = minValue(6);

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>Admin User</strong>
                    </h2>
                </div>
                <LoadMask loading={props.loader} blur>
                    <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="firstName">First Name</label>
                                <Field
                                    name="firstName"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="lastName">Last Name</label>
                                <Field
                                    name="lastName"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="email">E-mail</label>
                                <Field
                                    name="email"
                                    component={renderField}
                                    type="email"
                                    className="form-control"
                                    validate={email()}
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="permission">Permission</label>
                                <Field
                                    multi
                                    allKey
                                    name="permission"
                                    valueKey="_id"
                                    labelKey="name"
                                    component={renderSearchSelect}
                                    loadOptions={getRoles}
                                    placeholder="Select roles"

                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="mobileNo"> Phone Number </label>
                                <Field
                                    name="mobileNo"
                                    component={renderNumber}
                                    placeholder="(201) 555 0123"
                                    formatTemplate="(###) ### ####"
                                    className="w-100"
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="password">Super user</label>
                                <Field
                                    name="superUser"
                                    component={renderSwitch}
                                    label="Super user"
                                />
                            </div>

                            {updateData && updateData.azureId ? (
                                <div className="col-12 d-flex justify-content-center flex-column align-items-center py-4">
                                    <h3> This user is for SSO authentication </h3>
                                    <small> The password is disabled </small>
                                </div>
                            ) : (
                                <React.Fragment>
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <label htmlFor="password">Password</label>
                                        <Field
                                            name="password"
                                            component={renderField}
                                            type="password"
                                            className="form-control"
                                            validate={minValue6}
                                        />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                        <label htmlFor="password">Confirmation</label>
                                        <Field
                                            name="confirmation"
                                            type="password"
                                            label="Confirmation"
                                            component={renderField}
                                            className="form-control"
                                            validate={confirmation({
                                                field: "password",
                                                fieldLabel: "Password",
                                            })}
                                        />
                                    </div>
                                </React.Fragment>
                            )}
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link
                                        className="btn btn-secondary m-1"
                                        to={settingsUserAccountsUsers}
                                    >
                                        Cancel
                                    </Link>
                                    <button
                                        type="submit"
                                        className="btn btn-primary m-1"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </LoadMask>
            </div>
        </form>
    );
};

const UserUpdateForm = reduxForm({
    form: "user",
    validate: (data) => {
        return validate(data, {
            firstName: validators.exists()("Required field"),
            lastName: validators.exists()("Required field"),
            email: validators.exists()("Required field"),
        });
    },
})(Form);

export default UserUpdateForm;
