import React from 'react';
import { connect } from 'react-redux';
import { destroy as destroyForm } from 'redux-form';
import { actions } from '../../../../../../../redux/modules/crewAdmin/accountGroups';
import { getAirline } from '../../../../../../../redux/modules/crewAdmin/airlines';
import LoadMask from '../../../../../Utils/LoadMask';
import AccountGroupForm from './accountGroupForm';
import IconAngleLeft from '../../../../../../icons/angleLeftIcon';


class Container extends React.Component{

    componentDidMount(){
        this.props.getAirline(this.props.match.params.airlineId);
    }

    submit = (values) => {
        this.props.create(values).then((valid)=>{
            if(valid) this.cancel();
        })
    }

    cancel = () => {
        this.props.destroyForm('accountGroupForm');
        this.props.history.goBack();
    }

    render(){

        const { loadingForm, airline } = this.props;

        return (
            <div className="h-100 crewContainer px-3">
                <div className="d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={this.cancel}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="flex-1 d-flex flex-wrap justify-content-center">
                        <div className="col-12 col-xl-7">
                            <div className="rounded h-100 shadow border border-dark p-4 p-xl-5" style={{ borderRadius: '10px' }}>
                                <div className="mb-4">
                                    <h5> GENERAL </h5>
                                </div>
                                <LoadMask loading={loadingForm} blur>
                                    <AccountGroupForm
                                        onSubmit={this.submit}
                                        cancel={this.cancel}
                                        airlineId={this.props.match.params.airlineId}
                                        airline={airline}
                                        modalContacts={this.props.modalContacts}
                                        setModalContacts={this.props.setModalContacts}
                                        setEditContact={this.props.setEditContact}
                                        editContact={this.props.editContact}
                                        initialValues={{
                                            commission_rate: "0",
                                        }}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = state => ({
    ...state.crewAdmin.accountGroups,
    airline: state.crewAdmin.airlines.item
})

const mdtp = {
    ...actions,
    getAirline,
    destroyForm
}

export default connect(mstp, mdtp)(Container);
