import Swal from 'sweetalert2';
import moment from 'moment';
import cardValidator from 'card-validator';
import { handleActions } from 'redux-actions';
import { change, initialize, initialize as initializeForm } from 'redux-form';
import { push } from "react-router-redux";
import app from "../../libs/apiClient";
import format from '../../../js/common/utility/format';
import { pdf } from '@react-pdf/renderer';
import TripInfoPdf from '../../common/components/quotes/List/bookModal/TripInfoPdf';
import { openModalReservation } from './quote';
import airportCodes from '../../common/utility/airports.json';
import airlineCodes from '../../common/utility/airlines.json';
import { errorHandler } from '../../common/utility/constants';


const initialState = {
    to: {
        formatted_address: null,
        lng: null,
        lat: null,
        city: null,
        state: null,
        postal_code: null,
    },
    from: {
        formatted_address: null,
        lng: null,
        lat: null,
        city: null,
        state: null,
        postal_code: null,
    },
    itinerary: {},
    passenger: {},
    checkout: {},
    authInfo: {},
    discounts: [],
    formType: null,
    bookingCode: {
        code: '',
        discount: 0,
    },
    confirmed: {},
    loader: false,
    loaderDiscount: false,
    quote: {},
    isAirport: false,
    airportCode: undefined,
    flightsNumbers: [],
};

export const actionTypes = {
    SET_ITINERARY_STATE: 'SET_ITINERARY_STATE',
    AUTHINFO: 'AUTHINFO',
    CONFIRMED: 'CONFIRMED',
    CHECKOUT: 'CHECKOUT',
    FROM: 'FROM',
    ITINERARY: 'ITINERARY',
    LOADER: 'CHECKOUT_LOADER',
    PASSENGER: 'PASSENGER',
    TO: 'TO',
    TYPE: 'TYPE',
    TRANSFER_QT: 'TRANSFER_QT',
    PROMO_CODE: 'PROMO_CODE',
    DISCOUNT: 'DISCOUNT',
    LOADER_DISCOUNT: 'LOADER_DISCOUNT',
    SET_QUOTE: "SET_QUOTE",
    SET_IS_AIRPORT: "SET_IS_AIRPORT_QUOTE",
    SET_FLIGHTS_NUMBERS: "SET_FLIGHTS_NUMBERS_QUOTE",
};

export const setDetails = (detail, key) => dispatch => {
    dispatch({ type: actionTypes[key.toUpperCase()], [key]: detail });
    let isAirport = false, airportCode;
    if(detail && detail.types && detail.types.includes('airport') && key.toUpperCase() === actionTypes.FROM){
        isAirport = true;
        airportCode = airportCodes.find(item => detail.formatted_address.match(new RegExp(item.code)) !== null);
    }
    if(key.toUpperCase() == actionTypes.FROM){
        dispatch({ type: actionTypes.SET_IS_AIRPORT, isAirport, airportCode });
        if (!isAirport) {
            dispatch(change("typeForm", "airline", null));
            dispatch(change("typeForm", "fligth_number", null));
        }
    }
};

export const getFlightInfo = (flight) => (dispatch, getStore) => {
    const store = getStore().form.typeForm.values;
    let { airportCode } = getStore().itinerary;
    const { airline, schedule, from } = store;

    flight = flight.match(/\d{3,4}/g);

    const params = { flightNumber: flight }
    if(airportCode)
        params.airportCode = airportCode.code;
    if(airline)
        params.airlineCode = typeof(airline) === 'object' ? airline.code : airline;
    if(schedule)
        params.date_of_service = schedule

    app.service('/api/airlineApi').find({ query: params }).then( data => {
        if(data && data.result != null){
            let flightsNumbers = [{
                index: 0,
                flightNumber: data.result.flightNumber,
                airportCodeDep: data.result.airportCodeDep,
                airportCodeArr: data.result.airportCodeArr,
                date: data.result.date,
                time: data.result.time,
                label: `${data.result.airlineCode}${data.result.flightNumber} - ${data.result.airportCodeDep} - ${data.result.airportCodeArr} - ARR ${moment(`${data.result.date._} ${data.result.time._}`).format('LT')}`
            }];
            dispatch({ type: actionTypes.SET_FLIGHTS_NUMBERS, flightsNumbers });
        }
    }).catch( error => {
        console.error(error);
    })
}

export const onSelectFlight = (key) => (dispatch, getStore) => {
    const store = getStore().itinerary;
    let values = store.flightsNumbers[key]
    let date = moment(`${values.date._} ${values.time._}`);
    dispatch(change('typeForm', 'schedule', moment(date.format('YYYY-MM-DD[T]18:00:00.000[Z]')) ));
    dispatch(change('typeForm', 'time', {hour:date.format('HH'), minutes: date.format('mm')} ));
    dispatch(change('typeForm', 'fligth_number', values ));
}

export const onSelectAirline = (key) => (dispatch, getStore) => {
    let values = airlineCodes.find(item => item._id == key);
    dispatch(change('typeForm', 'airline', values));
}

export const setTrip = (values) => async (dispatch, getStore) => {
    if(values.bookingCode && values.bookingCode.length > 0){
        await dispatch(getBookingCode(values.bookingCode)).catch(error => { throw error });
    }
    if(values && values.transferQt === 'transfer')
        await dispatch(setTransferItinerary(values));
    else
        await dispatch(setHourlyItinerary(values));

}

export const setHourlyItinerary = (values) => (dispatch, getStore) => {

    const { hour, minutes } = values.time;
    dispatch({
        type: actionTypes.ITINERARY,
        itinerary: {
            ...values,
            hour: { value: hour },
            minutes: { value: minutes },
        },
    });
    dispatch(setType({ formType: 'hourly' }));
};

export const setTransferItinerary = (values) => (dispatch, getStore) => {

    const { hour, minutes } = values.time;

    dispatch({
        type: actionTypes.ITINERARY,
        itinerary: {
            ...values,
            hour: { value: hour },
            minutes: { value: minutes },
            duration: undefined,
        },
    });
    dispatch(setType({ formType: 'transfer' }));
};

export const loadForm = () => (dispatch, getStore) => {
    const itinerary = getStore().itinerary;
    dispatch(initializeForm('HourlyForm', itinerary.itinerary));
    dispatch(initializeForm('TransferForm', itinerary.itinerary));
};

export const loadPassenger = () => (dispatch, getStore) => {
    const { passenger } = getStore().itinerary;
    dispatch(initializeForm('PassengerForm', {
        ...passenger,
        country: passenger.country || { label: 'United States', value: 'US' },
    }));
};

export const setPassenger = values => ({ type: actionTypes.PASSENGER, passenger: values });

export const loadCheckout = () => async (dispatch, getStore) => {
    const { itinerary } = getStore();
    dispatch(initializeForm('PaymentForm', itinerary.checkout));
};

// makes a request to create an authorize account
export const setCheckout = values => (dispatch, getStore) => {
    const {
        itinerary: { passenger },
    } = getStore();

    const checkout = {
        ...values,
        ...passenger,
        payment_type: 'card',
        country: passenger.country.value,
        card_type: cardValidator.number(values.card_number).card.niceType,
    };

    dispatch({ type: actionTypes.CHECKOUT, checkout });
};

export const createQuote = (print = false) => async (dispatch, getStore) => {
    const {
        itinerary: {
            from,
            to,
            bookingCode,
            discounts,
            itinerary: {
                duration,
                hour,
                minutes,
                schedule,
                airline,
                fligth_number,
                no_flight_information,
            },
        },
        vehicle: { vehicle },
    } = getStore();


    const fromAirport = from.types.includes('airport');
    const pu_time = format.setTime(schedule, hour.value, minutes.value);

    // need to change this for login users
    dispatch(setLoader(true));

    const data = {
        puAddress: from.formatted_address,
        doAddress: to.formatted_address,
        puTime: pu_time,
        puTimeNoTimezone: pu_time,
        vehicleId: vehicle[0].vehicle_id,
        vehicleLocation: vehicle[0].vehicleLocation_id,
        vehicleSpecialRateId: vehicle[0].vehicleSpecialRate_id,
        baseRateId: vehicle[0]._id,
        duration: vehicle[0].duration,
        hourlyDuration: duration,
        distance: vehicle[0].distance,
        bookingCode: bookingCode ? bookingCode.code : null,
        transactionAmount: vehicle[0].price,
        transactionDiscount: discounts && discounts.length > 0 ? discounts[0].discount : 0,
        vehicleType: vehicle[0].type,
        vehicleClass: vehicle[0].class,
        vehicleDescription: vehicle[0].description,
        vehiclePhoto: vehicle[0].photo,
        vehicleCapacity: vehicle[0].capacity,
        tripPrice: vehicle[0].tripPrice,
        gratuity: vehicle[0].gratuity,
        gratuityPercent: vehicle[0].gratuityPercent,
        fromAirport,
    };

    if(!no_flight_information){
        data.flightAirline = airline;
        data.flightNumber = fligth_number;
    }

    try {
        const Quote = await app.service('/api/quote').create(data);
        dispatch(setQuote(Quote));

        // if it's need to download the pdf
        if(print){
            const quoteBlob = await pdf(TripInfoPdf(Quote)).toBlob();
            saveAs(quoteBlob, `Quote_${Quote.quoteNo}.pdf`);
            Swal.fire({
                type: "success",
                title: "Success!",
                text: "Quote has been saved"
            }).then(()=>{
                dispatch(openModalReservation(false, true));
            });
        }

        return true;
    } catch (err) {
        dispatch(errorHandler(err, "Payment could not be processed. Please re-enter the information and try again."));
    } finally {
        dispatch(setLoader(false));
    }
};

export const setBook = () => async (dispatch, getStore) => {
    const {
        itinerary: { checkout, quote },
    } = getStore();

    // need to change this for login users
    dispatch(setLoader(true));

    const data = {
        ...checkout,
        month: typeof checkout.month === 'string' ? checkout.month : checkout.month.value,
        year: typeof checkout.year === 'string' ? checkout.year : checkout.year.value,
        quoteId: quote && quote._id,
        bookingAgent: true,
    };

    if (checkout.airline) data.airline = checkout.airline.value;

    try {
        const receipt = await app.service('/api/authorize-charge').create(data);
        dispatch( setItineraryState({ confirmed: receipt }));

        return true;
    } catch (err) {
        dispatch(errorHandler(err, "Payment could not be processed. Please re-enter the information and try again."));
    } finally {
        dispatch(setLoader(false));
    }
};

export const getBookingCode = code => async (dispatch, getStore) => {
    if (!code) return;

    dispatch(setBookingCode({ loading: true }));
    const { data } = await app.service('/api/promo-code').find({query: { code } });

    dispatch(setDiscount([]));

    if (!data.length) {
        throw "Sorry the code doesn't exist";
    } else if(data[0].oneTime && data[0].totalReservations && data[0].totalReservations > 0) {
        throw "Sorry the code can only be used once";
    } else if (moment().isAfter(data[0].expDate)) {
        throw "Sorry the code has expired";
    } else if (moment().isBefore(data[0].startDate)) {
        throw "Sorry the code isn't valid yet, please try again later";
    } else
        dispatch( setBookingCode({ code: data[0].code, discount: data[0].discount }) );
};

export const getDiscount = bookingCode => async (dispatch, getStore) => {

    dispatch(setLoaderDiscount(true));

    const {
        vehicle: { vehicle },
        itinerary: {
            to,
            from,
            itinerary: { duration },
        },
    } = getStore();

    try {
        const discounts = await Promise.all(
            vehicle.map((vh, i) => {
                const query = {
                    latFrom: from.lat,
                    lngFrom: from.lng,
                    latTo: to.lat,
                    lngTo: to.lng,
                    postal_code: from.postal_code,
                    to_postal_code: to.postal_code,
                    promoCode: bookingCode,
                };

                if (duration) query.duration = duration;

                return app.service('/api/distance').get(vh._id, {query});
            })
        );
        dispatch(setDiscount(discounts));
    } catch (err) {
        console.log(err);
    } finally {
        dispatch(setLoaderDiscount(false));
    }
};

export const clearItinerary = () => dispatch => {
    dispatch(initializeForm('HourlyForm', {}));
    dispatch(initializeForm('TransferForm', {}));
};

export const clearPassenger = () => dispatch => {
    dispatch(initializeForm('PassengerForm', {}));
};

export const clearCheckout = () => dispatch => {
    dispatch(initializeForm('PaymentForm', {}));
};

export const cleanDataModal = () => dispatch => {
    const obj = {
        formatted_address: null,
        lng: null,
        lat: null,
        city: null,
        state: null,
        postal_code: null,
    }
    dispatch({ type: actionTypes.ITINERARY, itinerary: {} });
    dispatch({ type: actionTypes.PASSENGER, passenger: {} });
    dispatch({ type: actionTypes.CHECKOUT, checkout: {} });
    dispatch({ type: actionTypes.TO, to: obj });
    dispatch({ type: actionTypes.FROM, from: obj });
    dispatch({ type: actionTypes.SET_IS_AIRPORT, isAirport: false, airportCode: undefined });
    dispatch(setDiscount([]));
    dispatch(setBookingCode({ code: '', discount: 0 }));
    dispatch(clearItinerary);
    dispatch(clearPassenger);
    dispatch(clearCheckout);
    dispatch(setQuote({}));
    dispatch(setConfirmed({}));
}

// PURE ACTIONS
export const setItineraryState = newState => ({
    type: actionTypes.SET_ITINERARY_STATE,
    newState,
});

export const setLoader = loader => ({
    type: actionTypes.LOADER,
    loader,
});

export const setConfirmed = confirmed => ({
    type: actionTypes.CONFIRMED,
    confirmed,
});

export const setType = formType => ({
    type: actionTypes.TYPE,
    formType,
});

export const setAuthInfo = authInfo => ({
    type: actionTypes.AUTHINFO,
    authInfo,
});

export const setQuote = quote => ({
    type: actionTypes.SET_QUOTE,
    quote,
});

export const setBookingCode = bookingCode => ({
    type: actionTypes.PROMO_CODE,
    bookingCode,
});

export const setDiscount = discounts => ({
    type: actionTypes.DISCOUNT,
    discounts,
});

export const setLoaderDiscount = loaderDiscount => ({
    type: actionTypes.LOADER_DISCOUNT,
    loaderDiscount,
});

export const actions = {
    setHourlyItinerary,
    setTransferItinerary,
    setLoader,
    setPassenger,
    setCheckout,
    setDetails,
    setType,
    loadPassenger,
    loadForm,
    clearItinerary,
    clearPassenger,
    cleanDataModal,
};

export const reducers = {
    [actionTypes.SET_ITINERARY_STATE]: (state, { newState }) => ({ ...state, ...newState }),
    [actionTypes.AUTHINFO]: (state, { authInfo }) => ({ ...state, authInfo }),
    [actionTypes.ITINERARY]: (state, { itinerary }) => ({ ...state, itinerary }),
    [actionTypes.PASSENGER]: (state, { passenger }) => ({ ...state, passenger }),
    [actionTypes.CHECKOUT]: (state, { checkout }) => ({ ...state, checkout }),
    [actionTypes.TO]: (state, { to }) => ({ ...state, to }),
    [actionTypes.FROM]: (state, { from }) => ({ ...state, from }),
    [actionTypes.TYPE]: (state, { formType }) => ({ ...state, formType }),
    [actionTypes.LOADER]: (state, { loader }) => ({ ...state, loader }),
    [actionTypes.CONFIRMED]: (state, { confirmed }) => ({ ...state, confirmed }),
    [actionTypes.PROMO_CODE]: (state, { bookingCode }) => ({ ...state, bookingCode }),
    [actionTypes.DISCOUNT]: (state, { discounts }) => ({ ...state, discounts }),
    [actionTypes.LOADER_DISCOUNT]: (state, { loaderDiscount }) => ({ ...state, loaderDiscount }),
    [actionTypes.SET_QUOTE]: (state, { quote }) => ({ ...state, quote }),
    [actionTypes.SET_IS_AIRPORT]: (state, { isAirport, airportCode }) => ({ ...state, isAirport, airportCode }),
    [actionTypes.SET_FLIGHTS_NUMBERS]: (state, { flightsNumbers }) => ({ ...state, flightsNumbers })
};

export default handleActions(reducers, initialState);
