import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Update from "./BlogPostUpdate";
import { update, load2Update } from "../../../../redux/modules/blogPosts";

const selector = formValueSelector("blogPost");
const mstp = (state) => ({
    loading: state.blogPost.loading,
    photo: selector(state, "photo"),
    userInfo: state.login.userInfo,
});

const mdtp = { update, load2Update };

export default connect(mstp, mdtp)(Update);
