import app from "../../libs/apiClient";
import { handleActions } from "redux-actions";
import { goBack } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import moment from 'moment';
import swal from "sweetalert2";
import { shuttleAddresses, shuttleCompanies, S_UIUD } from '../../common/utility/constants';
import { errorHandler } from '../../common/utility/constants';
import _, { cloneDeep } from 'lodash';
import { encrypt, getAirlineCodes, getAirportCodes, getLiveryDetails } from "./liveryEdition";

const service = app.service("/api/shuttle");

const DATA = "SET_DATA_SHUTTLE";
const PAGE = "SET_PAGE_SHUTTLE";
const LOADING = "SET_LOADING_SHUTTLE";
const ITEM = "SET_ITEM_SHUTTLE";
const DATA_LIVERY = "DATA_LIVERY_SHUTTLE";
const COMPANY_LIVERY = "COMPANY_LIVERY_SHUTTLE";
const LOADING_FORM_CREATE_LIVERY = "LOADING_FORM_CREATE_LIVERY";
const LOADING_FORM_EDIT_LIVERY = "LOADING_FORM_EDIT_LIVERY";
const MODAL_FORM_CREATE_LIVERY = "MODAL_FORM_CREATE_LIVERY";
const MODAL_EDIT_LIVERY_SHUTTLE = "MODAL_EDIT_LIVERY_SHUTTLE";
const DATA_SHUTTLE_TRIP_LIVERY = "DATA_SHUTTLE_TRIP_LIVERY";

const setCompany = (company) => ({
    type: COMPANY_LIVERY,
    company,
});

const setModalFormLivery = (modalCreateLivery) => ({
    type: MODAL_FORM_CREATE_LIVERY,
    modalCreateLivery,
});

const setModalEditLivery = (modalEditLivery) => ({
    type: MODAL_EDIT_LIVERY_SHUTTLE,
    modalEditLivery,
});

// Actions
const create = (values) => (dispatch, getState) => {

    dispatch(loading(LOADING, true));
    service.create(values).then((response) => {
        dispatch(goBack());
    }).catch((e)=>{
        dispatch(errorHandler(e));
    })
    .finally(() => {
        dispatch(loading(LOADING, false));
    });
};

const update = (values) => (dispatch, getState) => {

    dispatch(loading(LOADING, true));
    service.patch(values._id, values).then(response => {
        dispatch(goBack());
    }).catch((e)=>{
        dispatch(errorHandler(e));
    })
    .finally(()=>{
        dispatch(loading(LOADING, false));
    })
}

const destroy = (id) => (dispatch, getState) => {
    console.log('destroy')
    dispatch(loading(LOADING, true));
    service.remove(id).then(response => {
        dispatch(find());
    }).catch((e)=>{
        dispatch(errorHandler(e));
    })
    .finally(()=>{
        dispatch(loading(LOADING, false));
    })
}

const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading(LOADING, true));
    const { values = {} } = getState().form.shuttleListFilter || {};

    try {
        const query = { query: { $skip: (page - 1) * 10, $sort: { _id: -1 } } };

        if (values) {
            if (values.type) {
                query.query.$or = [
                    { code: { $regex: _.escapeRegExp(values.type), $options: 'i' } },
                ];
                if(!isNaN(parseInt(values.type))){
                    query.query.$or.push({ customerId: parseInt(values.type) });
                }
            }
        }

        const response = await service.find(query);
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(LOADING, false));
    }
};

const load2Update = (id) => (dispatch, getState) => {

    dispatch(loading(LOADING, true));
    service.get(id).then(item => {
        dispatch(initializeForm('shuttleForm', item));
        dispatch(setItem(ITEM, item));
    }).catch((e)=>{
        dispatch(errorHandler(e));
    })
    .finally(()=>{
        dispatch(loading(LOADING, false));
    })
}

export const getLiveryShuttleTrips = (data) => async (dispatch, getStore) => {
    dispatch(loading(LOADING, true));
    const company = shuttleCompanies.find(
        (item) => item.liveryId === parseInt(data.company)
    );
    try {
        if (!company) {
            swal.fire({
                type: "error",
                text: "No matching company code. Please use 2-letter IATA code."
            });
            dispatch(loading(LOADING, false));
        } else {
            dispatch(setCompany({ customer: company, date: data.date }));
            const response = await app.service('api/crew').find({
                query: {
                    path: "getTokenizedParams",
                    body: encrypt(`${company.liveryId}[[ENCRYPT]]Customer[[ENCRYPT]]0[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]]${data.date}[[ENCRYPT]]${data.date}[[ENCRYPT]]0[[ENCRYPT]]All[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]`),
                },
            });
            const shuttleTrips = await app.service('api/crew').find({
                query: {
                    path: "GetReservationListOnPhoneEN",
                    body: response.Table[0],
                },
            });
            const finalData = shuttleTrips.Table;
            dispatch(setData(DATA_LIVERY, finalData));
        }
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(loading(LOADING, false));
    }
};

const loadShuttleTripEdit = (values) => async (dispatch) => {
    dispatch(setModalEditLivery(true));
    await dispatch(loading(LOADING_FORM_EDIT_LIVERY, true));
    const data = await getLiveryDetails(values.TripID);
    if (data.Table && data.Table.length > 0) {
        const tripPassengerNotes = data.Table[0].TripNote.split('***');
        tripPassengerNotes.splice(tripPassengerNotes.length - 1, 1);
        const PassengersList = [];
        for (let i = 0; i < tripPassengerNotes.length; i += 1) {
            const dataPassenger = tripPassengerNotes[i].split("_");
            const Name = dataPassenger[0];
            const Phone = dataPassenger[1];
            const Email = dataPassenger[2];
            PassengersList.push({
                Name,
                Email,
                Phone,
            });
        }
        data.Table[0].PassengersList = PassengersList;
        dispatch(setData(DATA_SHUTTLE_TRIP_LIVERY, { ...values, ...data.Table[0] }));
        dispatch(initializeForm('shuttleEditLiveryForm', { ...values, ...data.Table[0],  }));
    }
    dispatch(loading(LOADING_FORM_EDIT_LIVERY, false));
};

const createShuttleTrip = (data) => (dispatch, getStore) => {
    dispatch(loading(LOADING_FORM_CREATE_LIVERY, true));
    const dataToList = cloneDeep(getStore().shuttle).company;
    const company = dataToList.customer;
    const PickUp = shuttleAddresses.find((item) => item.id === data.pickUp);
    const DropOff = shuttleAddresses.find((item) => item.companies.includes(company.liveryId) && item.id !== data.pickUp);
    data.from = PickUp;
    data.to = DropOff;
    data.company = company;
    service.create(data, { query: { shuttleTrip: true } }).then(response => {
        swal.fire({
            type: "success",
            title: "SUCCESS",
            text: "Trip Created"
        }).then(() => {
            dispatch(setModalFormLivery(false));
            dispatch(getLiveryShuttleTrips({ company: dataToList.customer.liveryId, date: dataToList.date }));
        });
    }).catch((error) => {
        dispatch(errorHandler(error));
    }).finally(() => {
        dispatch(loading(LOADING_FORM_CREATE_LIVERY, false));
    })
}

const updateShuttleTrip = (data) => (dispatch, getStore) => {
    dispatch(loading(LOADING_FORM_EDIT_LIVERY, true));
    const dataToList = cloneDeep(getStore().shuttle).company;
    service.patch(data.TripID, data, { query: { shuttleTrip: true } }).then(response => {
        swal.fire({
            type: "success",
            title: "SUCCESS",
            text: "Trip updated"
        }).then(() => {
            dispatch(setModalEditLivery(false));
            dispatch(getLiveryShuttleTrips({ company: dataToList.customer.liveryId, date: dataToList.date }));
        });
    }).catch((error) => {
        dispatch(errorHandler(error));
    }).finally(() => {
        dispatch(loading(LOADING_FORM_EDIT_LIVERY, false));
    })
};

export const actions = {
    find,
    load2Update,
    createShuttleTrip,
    loadShuttleTripEdit,
    getLiveryShuttleTrips,
    setModalFormLivery,
    setModalEditLivery,
    updateShuttleTrip,
    destroy,
    create,
    update,
};

const loading = (type, loading) => ({ type, loading });
const setData = (type, data) => ({ type, data });
const setPage = (type, page) => ({ type, page });
const setItem = (type, item) => ({ type, item});

// Reducers
const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [DATA]: (state, { data }) => ({ ...state, data }),
    [PAGE]: (state, { page }) => ({ ...state, page }),
    [ITEM]: (state, { item }) => ({ ...state, item }),
    [DATA_LIVERY]: (state, { data }) => ({ ...state, dataLivery: data }),
    [COMPANY_LIVERY]: (state, { company }) => ({ ...state, company }),
    [MODAL_FORM_CREATE_LIVERY]: (state, { modalCreateLivery }) => ({ ...state, modalCreateLivery }),
    [LOADING_FORM_CREATE_LIVERY]: (state, { loading }) => ({ ...state, loadingFormLivery: loading }),
    [MODAL_EDIT_LIVERY_SHUTTLE]: (state, { modalEditLivery }) => ({ ...state, modalEditLivery }),
    [LOADING_FORM_EDIT_LIVERY]: (state, { loading }) => ({ ...state, loadingEditForm: loading }),
    [DATA_SHUTTLE_TRIP_LIVERY]: (state, { data }) => ({ ...state, dataShuttleTrip: data }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataLivery: [],
    dataShuttleTrip: undefined,
    modalCreateLivery: false,
    loadingFormLivery: false,
    modalEditLivery: false,
    loadingEditForm: false,
    company: undefined,
    page: 1,
    loading: false,
    item: {},
};

export default handleActions(reducers, initialState);
