import { handleActions } from 'redux-actions';
import app from "../../libs/apiClient";
import Swal from 'sweetalert2';
import { setClientsQuery } from './clients';
import { initialize as initializeForm } from 'redux-form';
import _ from 'lodash';

// actionTypes
const PROMO_CODE_LOCATIONS_LOADER = 'PROMO_CODE_LOCATIONS_LOADER_LOADER';
const PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE = 'PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE_LOADER_CREATE_UPDATE';
const PROMO_CODE_LOCATIONS_PAGE =  "PROMO_CODE_LOCATIONS_PAGE_PAGE";
const PROMO_CODE_LOCATIONS_DATA = 'PROMO_CODE_LOCATIONS_DATA_DATA';
const PROMO_CODE_LOCATIONS_UPDATE_DATA = "PROMO_CODE_LOCATIONS_UPDATE_DATA_UPDATE_DATA";
const PROMO_CODE_LOCATIONS_ITEM = "PROMO_CODE_LOCATIONS_ITEM_ITEM";
const PROMO_CODE_LOCATIONS_SEARCH = "PROMO_CODE_LOCATIONS_SEARCH_SEARCH";

const service = app.service('/api/promo-code');


const handleError = (error) => {
    console.log(error);
    const msg = error ? typeof error === 'object' ? error.message : error : 'there was an error';
    Swal.fire({
        type: "error",
        title: "ERROR!",
        text: `${msg}`
    })
}

const find = (id, page = 1) => (dispatch, getStore) => {
    dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER, true));
    const { search } = getStore().promoCodesLocations;

    const params = {
        query: {
            locationId: id,
            $skip: (page - 1) * 10,
            $limit: 10,
        }
    }

    if(search){
        params.query.$or = [
            { code: { $regex: _.escapeRegExp(search), $options: 'i' } },
        ]
    }

    service.find(params).then( response => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(handleError)
    .finally(()=>{
        dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER, false));
    })
};

const create = (data) => async (dispatch, getStore) => {
    dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE, true));

    try {
        await service.create(data);
        Swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Data created"
        }).then(()=>{
            dispatch(find(data.locationId))
        })
        return true
    } catch(error){
        handleError(error);
    } finally {
        dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE, false));
    }
}

const update = (data) => async (dispatch, getStore) => {
    dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE, true));

    try {
        await service.update(data._id, data);
        Swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Data updated"
        }).then(()=>{
            dispatch(find(data.locationId));
        })
        return true;
    } catch(error) {
        handleError(error);
    } finally {
        dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE, false));
    }
}

const load2Update = (id) => async (dispatch, getStore) => {
    dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE, true));

    try {
        const response = await service.get(id);
        dispatch(setUpdateData(response));
        dispatch(initializeForm('promoCodeLocationsForm', response));
    } catch (error) {
        handleError(error);
    } finally {
        dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE, false));
    }
};

const destroy = (locationId, id) => (dispatch, getStore) => {
    dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER, true));
    service.remove(id).then(response=>{
        Swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Data removed"
        }).then(()=>{
            dispatch(find(locationId))
        })
    }).catch(handleError)
    .finally(()=>{
        dispatch(setLoader(PROMO_CODE_LOCATIONS_LOADER, false));
    })
}


// PURE ACTIONS
const setLoader = (type,loader) => ({ type, loader, })

const setData = (data) => ({
    type: PROMO_CODE_LOCATIONS_DATA,
    data,
});

const setItem = (item) => ({
    type: PROMO_CODE_LOCATIONS_ITEM,
    item,
});

const setSearch = (id, search) => (dispatch, getStore) => {
    dispatch({ type: PROMO_CODE_LOCATIONS_SEARCH, search }),
    dispatch(find(id))
}


const setUpdateData = (updateData) => ({
    type: PROMO_CODE_LOCATIONS_UPDATE_DATA,
    updateData,
});

const setPage = (page) => ({
    type: PROMO_CODE_LOCATIONS_PAGE,
    page
})

export const actions = {
    find,
    create,
    update,
    load2Update,
    destroy,
    setUpdateData,
    setSearch,
}

// REDUCERS
export const reducers = {
    [PROMO_CODE_LOCATIONS_DATA]: (state, { data }) => ({ ...state, data }),
    [PROMO_CODE_LOCATIONS_LOADER]: (state, { loader }) => ({ ...state, loader }),
    [PROMO_CODE_LOCATIONS_LOADER_CREATE_UPDATE]: (state, { loader }) => ({ ...state, loaderCreateUpdate: loader }),
    [PROMO_CODE_LOCATIONS_PAGE]: (state, { page }) => ({ ...state, page }),
    [PROMO_CODE_LOCATIONS_UPDATE_DATA]: (state, { updateData }) => ({ ...state, updateData }),
    [PROMO_CODE_LOCATIONS_ITEM]: (state, { item }) => ({ ...state, item }),
    [PROMO_CODE_LOCATIONS_SEARCH]: (state, { search }) => ({ ...state, search }),
};

// InitialState
const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    search: undefined,
    loader: false,
    loaderCreateUpdate: false,
    updateData: undefined,
    item: {},
};

export default handleActions(reducers, initialState);
