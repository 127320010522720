import React, { useState, useEffect } from 'react';
import FormCustomer from './formCustomer'
import { connect } from 'react-redux';
import { actions } from '../../../redux/modules/airlineLivery';
import { initializeDataAutocomplete } from '../../../redux/modules/liveryEdition';
import LoadMask from '../Utils/LoadMask';
import XLSX from 'xlsx';


const TripSearch = ({ loading, setHotelTrips, formValues, data }) => {
  const [results, setResults] = useState([]); 
  const [tripGroups, setTripGroups] = useState([]); 

  const filterObTrips = (trips)=>{
    return trips.filter(trip => 
      trip.FlightNbr === "" && 
      trip.Airline === ""
    );
  }

  const groupByHotel = (trips = []) => {
    const groups = {};
  
    trips.forEach(trip => {
      if (!groups[trip.PickupAirportDesc]) {
        groups[trip.PickupAirportDesc] = {
          PickupAirportDesc: trip.PickupAirportDesc,
          trips: []
        };
      }
      groups[trip.PickupAirportDesc].trips.push(trip);
    });
  
    return Object.values(groups);
  };

const sanitizeSheetName = (name) => {
  return name ? name.replace(/[\[\]:\/\\\?\*]/g, '').substring(0, 31) : 'Sheet';
};

const getUniqueSheetName = (wb, baseName) => {
 
  let sanitizedBase = sanitizeSheetName(baseName);
  let sheetName = sanitizedBase;
  let counter = 1;

 
  while (wb.SheetNames.includes(sheetName)) {
      const suffix = ` (${counter})`;
      sheetName = sanitizedBase.substring(0, 31 - suffix.length) + suffix;
      counter++;
  }

  return sheetName;
};

  const downloadHotelTrips = (dataArray) => {
    if (!dataArray || !Array.isArray(dataArray)) {
        console.error("dataArray es undefined o no es un arreglo");
        return;
    }

    const title = `${dataArray[0].trips[0].ContactName.trim()} Trips`
    
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: title,
        Subject: "Multi-Sheet Data",
        Author: "Connect BBC",
        CreatedDate: new Date(),
    };

    dataArray.forEach((item) => {
        const sheetTitle = getUniqueSheetName(wb, item.PickupAirportDesc)

        const ws_data = [
            ["Date", "Flight#", "PU Time", "Hotel", "Airport"] 
        ];

        if (Array.isArray(item.trips)) {
            item.trips.forEach(trip => {
                ws_data.push([
                    trip.PUDate,       
                    trip.FirstPassengerName,     
                    trip.PUTime,    
                    trip.PickupAirportDesc,    
                    trip.DropoffAirportDesc    
                ]);
            });
        }

        const ws = XLSX.utils.aoa_to_sheet(ws_data);
        const headerStyle = {
            font: { bold: true },
            alignment: { horizontal: 'center', vertical: 'center' }
        };
        
        for (let col = 0; col < ws_data[0].length; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: 0, c: col });
            if (ws[cellAddress]) {
                ws[cellAddress].s = headerStyle;
            }
        }

        ws['!cols'] = Array(ws_data[0].length).fill({ wch: 20 });

        // Añadir la hoja al libro de Excel
        XLSX.utils.book_append_sheet(wb, ws, sheetTitle);
    });

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    };

    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${title}.xlsx`);
};

  useEffect(()=>{
    if (data && data.length > 0) {
      setResults(filterObTrips(data));
    }
}, [data])

useEffect(()=>{
  if (results.length > 0){
     setTripGroups(groupByHotel(results))
  }
}, [results])

  return (
    <div className="container">
      <div className="row justify-content-center mt-4">
        <div className="col-6">
          <h1 className="text-center">Hotel Pickup Time Report</h1>
          <FormCustomer
            onSubmit={setHotelTrips}
            formValues={formValues}
          />
        </div>
      </div>

      <hr/>
      <LoadMask loading={loading} blur>
      <div className="row justify-content-center mt-1">
      <div className="col-12 text-right">
        <button
          type="button"
          onClick={()=>downloadHotelTrips(tripGroups)}
          className="btn btn-primary mb-2"
        >
          <span style={{ width: "20px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              width="20"
              height="20"
              style={{ fill: "white" }}
            >
              <path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" />
            </svg>
          </span>
        </button>
      </div>
        <div className="col-12">
          <div className="table-responsive" style={{ height: '400px', overflowY: 'auto' }}>
            <table className="table table-bordered">
              <thead className="">
                <tr>
                  <th>Date</th>
                  <th>Flight#</th>
                  <th>PU Time</th>
                  <th>Hotel</th>
                  <th>Airport</th>
                </tr>
              </thead>
              <tbody>
                {results.length > 0 ? (
                  results.map((result, index) => (
                    <tr key={index}>
                      <td>{result.PUDate}</td>
                      <td>{result.FirstPassengerName}</td>
                      <td>{result.PUTime}</td>
                      <td>{result.PickupAirportDesc}</td>
                      <td>{result.DropoffAirportDesc}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>  
      </div> </LoadMask>
    </div>
  );
};

const mstp = state => ({
  ...state.airlineLivery,
  airlineCodes: state.liveryEdition.airlineCodes,
  formValues: state.form.customerForm && state.form.customerForm.values,
});

const mdtp = {
  ...actions,
  initializeDataAutocomplete: initializeDataAutocomplete,
}

export default connect(mstp, mdtp)(TripSearch);
