import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/apply';
import Select from "react-select";
import IconTimes from '../../../icons/times';
import IconPrint from '../../../icons/print';
import { statusList } from '../../../utility/constants';
import ApplyDetailValues from './details';
import ApplyForm from './form';

const ApplyDetail = (props) => {

    const {
        load2Update,
        changeStateApply,
        item,
        isEdit, 
        update,
        setIsEdit,
        loadingForm,
        formValues,
        history,
        match: {
            params: {
                id
            }
        }
    } = props;

    React.useEffect(() => {
        load2Update(id);
        return ()=>{
            setIsEdit(false)
        }
    }, [load2Update, setIsEdit, id]);

    const print = () => {
        window.print();
    }

    return (
        <div className="d-flex flex-wrap justify-content-center">
            <div className="col-12 mb-5">
                {isEdit ? (
                    <ApplyForm
                        onSubmit={update}
                        onCancel={()=>setIsEdit(false)}
                        loading={loadingForm}
                        formValues={formValues}
                    />
                ) : item ? (
                    <React.Fragment>
                        <div className="w-100 d-flex flex-wrap no-print">
                            <div className="col-12 d-flex flex-wrap justify-content-between">
                                <div className="col-12 col-md-8 px-0 d-flex align-items-start">
                                    {!isEdit ? (
                                        <div className="">
                                            <button
                                                type="button"
                                                className="btn btn-link mr-2 text-danger"
                                                onClick={()=>history.goBack()}
                                            >
                                                <IconTimes className="smallIcon text-danger" />
                                                CLOSE
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-link mr-2 text-dark"
                                                onClick={print}
                                            >
                                                <IconPrint className="smallIcon" />
                                                PRINT
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary mr-2"
                                                onClick={()=>setIsEdit(true)}
                                            >
                                                EDIT
                                            </button>
                                        </div>
                                    ) : ''}
                                </div>
                                <div className="col-12 col-md-4 px-0 mt-3 mt-md-0 select-labeled">
                                    <label htmlFor="apply-status">
                                        Status
                                    </label>
                                    <div className="w-100">
                                        <Select
                                            id="apply-status"
                                            clearable={false}
                                            className=""
                                            backspaceRemovesValue={false}
                                            searchable={true}
                                            options={statusList}
                                            placeholder="Status"
                                            onChange={(e) => {
                                                changeStateApply(e['value'])
                                            }}
                                            value={item && item.status && item.status.value ? item.status.value : "application_submitted"}
                                            disabled={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 my-4">
                                <h4> DRIVER APPLICATION FORM </h4>
                            </div>
                        </div>
                        <ApplyDetailValues item={item}/>
                    </React.Fragment>
                    ) : (
                        <div className="">
                            <h4> No Data</h4>
                        </div>
                    )}
            </div>
        </div>
    )
}

const mstp = (state) => ({
    ...state.apply,
    formValues: state.form.applyForm && state.form.applyForm.values,
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(ApplyDetail);
