import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderSelectField } from "Utils//renderField/renderField";
import renderDatePicker from "Utils/renderField/renderDatePicker";
import { stateOptions } from "../../../utility/constants";
import { settingsAccountsCustomers } from "../../../../routesConfiguration/paths";
const Form = (props) => {
    const { handleSubmit } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2 className="mb-3">
                        <strong>Client</strong>
                    </h2>

                    <div className="padding-15 p-sm-0 py-sm-1">
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="firstName">First Name</label>
                                <Field
                                    name="firstName"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="lastName">Last Name</label>
                                <Field
                                    name="lastName"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="email">Email</label>
                                <Field
                                    name="email"
                                    component={renderField}
                                    type="email"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="email">Cellphone</label>
                                <Field
                                    name="mobileNo"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="zip">Zip Code</label>
                                <Field
                                    name="zip"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="customerSince">
                                    Customer Since
                                </label>
                                <Field
                                    name="customerSince"
                                    component={renderDatePicker}
                                    placeholder="MM/DD/YYYY"
                                    timeFormat={false}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="street">Street</label>
                                <Field
                                    name="street"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="street2">Street 2</label>
                                <Field
                                    name="street2"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="city">City</label>
                                <Field
                                    name="city"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="form-group col-sm-12 col-md-6">
                                <label htmlFor="state">State</label>
                                <Field
                                    name="state"
                                    component={renderSelectField}
                                    type="text"
                                    className="form-control p-0 no-border"
                                    labelKey="label"
                                    valueKey="value"
                                    options={stateOptions}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="password">Password</label>
                                <Field
                                    name="password"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link
                                        className="btn btn-secondary m-1"
                                        to={settingsAccountsCustomers}
                                    >
                                        Cancel
                                    </Link>
                                    <button
                                        type="submit"
                                        className="btn btn-primary m-1"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const ClientForm = reduxForm({
    form: "client",
    validate: (data) => {
        let password_validation = validators.exists()("Required Field");
        if (data.password) {
            password_validation = validators.regex(/^.{5,}$/)(
                "is too short (minimum is 5 characters)"
            );
        }
        return validate(data, {
            firstName: validators.exists()("Required field"),
            lastName: validators.exists()("Required field"),
            mobileNo: validators.exists()("Required field"),
            email: validators.exists()("Required field"),
            customerSince: validators.exists()("Required field"),
            password: password_validation,
            street: validators.exists()("Required field"),
            street2: validators.exists()("Required field"),
            city: validators.exists()("Required field"),
            state: validators.exists()("Required field"),
            zip: validators.exists()("Required field"),
        });
    },
})(Form);

export const ClientUpdateForm = reduxForm({
    form: "client",
    validate: (data) => {
        let password_validation = null;
        if (data.password) {
            console.log(data.password);
            password_validation = validators.regex(/^.{5,}$/)(
                "is too short (minimum is 5 characters)"
            );
        }
        return validate(data, {
            firstName: validators.exists()("Required field"),
            lastName: validators.exists()("Required field"),
            mobileNo: validators.exists()("Required field"),
            email: validators.exists()("Required field"),
            customerSince: validators.exists()("Required field"),
            street: validators.exists()("Required field"),
            street2: validators.exists()("Required field"),
            city: validators.exists()("Required field"),
            state: validators.exists()("Required field"),
            zip: validators.exists()("Required field"),
            password: password_validation,
        });
    },
})(Form);

export default ClientForm;
