import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../Utils/renderField";

const ChangePassForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form
            className="form-validate mb-lg d-flex flex-column"
            onSubmit={handleSubmit}
        >
            <div className="form-group has-feedback flex-1">
                <Field
                    name="password"
                    component={renderField}
                    type="password"
                    className="form-control"
                    placeholder="Password"
                />
            </div>
            <div className="form-group has-feedback flex-1">
                <Field
                    name="password_confirm"
                    component={renderField}
                    type="password"
                    className="form-control"
                    placeholder="confirm password"
                />
            </div>
            <button
                type="submit"
                className="btn btn-primary m-1 align-self-start flex-1"
            >
                Change password
            </button>
        </form>
    );
};

export default reduxForm({
    form: "changePassword", // a unique identifier for this form
    validate: (data) => {
        const errors = {}
        if(!data.password) errors.password = "Required field"
        if(!data.password_confirm) errors.password_confirm = "Required field"
        if(data.password && data.password_confirm && data.password !== data.password_confirm)
            errors.password_confirm = "Passwords doesn't match"
        return errors;
    }
})(ChangePassForm);
