import React from "react";
import PropTypes from "prop-types";
import Table from "../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import moment from 'moment';
import { Link } from "react-router-dom";
import { getPathWithParams, reservationsCharterBookingsDetailId } from "../../../../routesConfiguration/paths";


export default class Details extends React.Component {
    static propTypes = {
        findClientPoints: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { pagePoints } = this.props;
        this.props.findClientPoints(this.props.match.params.id, pagePoints);
    }

    labelPoint = (value) => value === 1 ? `${value} pt.` : `${value} pts.`;

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readPoints }) => readPoints))
                    this.props.findClientPoints(this.props.match.params.id, this.props.pagePoints);
            }
        }
    }

    render() {
        const { pagePoints, clientPoints = {}, loadingPoints, userInfo: { permission }, findClientPoints } = this.props;

        return (
            <div className="">
                <h1> Client Points </h1>
                <br/>

                {permission && permission.find(({ readPoints }) => !!readPoints) && (
                    <React.Fragment>

                        {clientPoints.user && (
                            <div className="d-flex flex-column">
                                {(clientPoints.user.firstName || clientPoints.user.lastName) && (
                                    <label>
                                        <strong> Name: </strong>
                                        {clientPoints.user.firstName && clientPoints.user.firstName} &nbsp;
                                        {clientPoints.user.lastName && clientPoints.user.lastName}
                                    </label>
                                )}
                                {clientPoints.user.email && (
                                    <label> <strong> Email:&nbsp;</strong> {clientPoints.user.email} </label>
                                )}
                                {clientPoints.user.points && (
                                    <label> <strong> Total:&nbsp;</strong>
                                        {this.labelPoint(clientPoints.user.points.totalPoints)} - <RenderCurrency value={clientPoints.user.points.totalEarned} />
                                    </label>
                                )}
                            </div>
                        )}

                        <div className="d-flex">
                            <Table
                                onPageChange={(page)=>findClientPoints(this.props.match.params.id, page)}
                                data={clientPoints.data}
                                page={pagePoints}
                                loading={loadingPoints}
                            >
                                <TableHeaderColumn
                                    dataField="_id"
                                    isKey
                                    className="hide-column-header"
                                    dataFormat={(cell, row) =>
                                        <div className="d-flex flex-column-reverse flex-md-row">
                                            <div className="flex-1 d-flex flex-column">
                                                {row.tripId && (
                                                    <label>
                                                        {row.tripId.confirmationNo && (
                                                            <React.Fragment>
                                                                <strong> Trip No. </strong> {row.tripId.confirmationNo}
                                                            </React.Fragment>
                                                        )}
                                                        {row.tripId._id && (
                                                            <Link to = {getPathWithParams(
                                                                reservationsCharterBookingsDetailId,
                                                                "id",
                                                                row.tripId._id
                                                            )} className="btn btn-link px-2 py-0"> (Details) </Link>
                                                        )}
                                                    </label>
                                                )}
                                                {row.madeBy && (row.madeBy.firstName || row.madeBy.lastName) && (
                                                    <label>
                                                        <strong> Made by: </strong>
                                                        {row.madeBy.firstName && row.madeBy.firstName} &nbsp;
                                                        {row.madeBy.lastName && row.madeBy.lastName}
                                                    </label>
                                                )}
                                                {row.date && (
                                                    <label> <strong> Assigned date:&nbsp;</strong> {moment(row.date).format('MM/DD/YYYY LT')} </label>
                                                )}
                                                {row.reason && (
                                                    <label> <strong> Description: </strong> {row.reason} </label>
                                                )}
                                                <div className="d-flex flex-column flex-md-row">
                                                    {row.pointsBefore && (
                                                        <label className="flex-1">
                                                            <strong> Before:&nbsp;</strong>
                                                            {this.labelPoint(row.pointsBefore.totalPoints)} - <RenderCurrency value={row.pointsBefore.totalEarned} />
                                                        </label>
                                                    )}
                                                    {row.pointsAddOrRemove && (
                                                        <label className="flex-1">
                                                            <strong> {row.pointsAddOrRemove.totalPoints < 0 ? 'Removed:' : 'Added:' } </strong>
                                                            {this.labelPoint(Math.abs(row.pointsAddOrRemove.totalPoints))} - <RenderCurrency value={Math.abs(row.pointsAddOrRemove.totalEarned)} />
                                                        </label>
                                                    )}
                                                    {row.pointsAfter && (
                                                        <label className="flex-1">
                                                            <strong> After:&nbsp;</strong>
                                                            {this.labelPoint(row.pointsAfter.totalPoints)} - <RenderCurrency value={row.pointsAfter.totalEarned} />
                                                        </label>
                                                    )}
                                                </div>
                                                {row.automatic !== undefined && (
                                                    <label> <strong> Type: </strong> {row.automatic === true ? 'Automatic' : 'Manual'} </label>
                                                )}
                                            </div>
                                        </div>
                                    }
                                />
                            </Table>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}
