import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "./GroupRateForm";
import ModalEdit from "./VehicleEditModal";
import LoadMask from "../../Utils/LoadMask";
import EspecialRates from './EspecialRates';

class Create extends Component {
    static propTypes = {
        create: PropTypes.func.isRequired,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = this.initialState();
        this.handleChange = this.handleChange.bind(this);
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
        this.autocomplete = null;
    }
    componentDidMount() {
        const options = {
            type: ["cities"],
            componentRestrictions: { country: "us" },
        };
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            options
        );
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    }

    handlePlaceSelect() {
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;
        const name = addressObject.name;
        const location = addressObject.geometry.location;
        const lat = location.lat();
        const lng = location.lng();
        address.forEach((item) => {
            // GOOGLE SETS THE STATE IN administrative_area_level_1
            if (item.types.includes("administrative_area_level_1")) {
                this.props.setState(item.short_name);
            }
            // GOOGLE SETS THE CITY IN locality
            if (item.types.includes("locality")) {
                this.props.setCity(item.long_name);
            }
        });
        this.props.setAddress(addressObject.formatted_address, lat, lng);
        this.setState({
            address,
            name,
        });
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    initialState() {
        return {
            name: "",
            address: [],
        };
    }

    componentWillUnmount(){
        this.props.verifyBaseRatesCreate();
        this.props.verifySpecialRates();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateVehicles }) => updateVehicles)){
                    const options = {
                        type: ["cities"],
                        componentRestrictions: { country: "us" },
                    };
                    this.autocomplete = new google.maps.places.Autocomplete(
                        document.getElementById("autocomplete"),
                        options
                    );
                    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
                }
            }
        }
    }

    render() {
        // state

        // bind
        const { create, vehicles, vehicleEdit, vehicleLocationLoader, changeRadioChecks, loading,
            createVehicleLocation, removeBaseRate, restoreDefault, submitEspecialRate, openEditVehicle,
            modalVehicleEdit, closeEditVehicle, resetInputCalendar, loadEditVehicleSpecialRate,
            specialRatesLoader, closeSpecialRates, modalSpecialRates, updateSpecialRateVehicle, getVehicles,
            updateDataEspecialRate, updateData, openModalSpecialRates, removeSpecialRate, dataToCreateSpecialRate,
            seatSearchSpecialRate, loadingSpecialRates, userInfo: { permission }
        } = this.props;

        return (
            <div className="row">
                {permission && permission.find(({createLocations}) => !!createLocations) && (
                    <React.Fragment>
                        <ModalEdit
                            isOpen={modalVehicleEdit}
                            toggle={closeEditVehicle}
                            vehicleEdit={vehicleEdit}
                            vehicleLocationLoader={vehicleLocationLoader}
                            onSubmit={createVehicleLocation}
                            restoreDefault={restoreDefault}
                            showRestoreBtn={true}
                        />

                        <EspecialRates
                            specialRatesLoader={specialRatesLoader}
                            resetInputCalendar={resetInputCalendar}
                            changeRadioChecks={changeRadioChecks}
                            toggle={closeSpecialRates}
                            isOpen={modalSpecialRates}
                            vehicleEdit={vehicleEdit}
                            getVehicles={getVehicles}
                            removeSpecialRate={removeSpecialRate}
                            closeEditVehicle={closeEditVehicle}
                            submitEspecialRate={submitEspecialRate}
                            vehicleLocationLoader={vehicleLocationLoader}
                            updateSpecialRateVehicle={updateSpecialRateVehicle}
                            loadEditVehicleSpecialRate={loadEditVehicleSpecialRate}
                            updateDataEspecialRate={updateDataEspecialRate}
                        />

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <LoadMask loading = { loading === 1 ? true : false }>
                                <Form onSubmit={create} vehicles={vehicles}
                                    openEditVehicle = {openEditVehicle}
                                    removeBaseRate={removeBaseRate}
                                    specialRatesLoader={specialRatesLoader}
                                    closeSpecialRates={closeSpecialRates}
                                    modalSpecialRates={modalSpecialRates}
                                    showSearch = { false }
                                    showDownloadButton = { false }
                                    loadingSpecialRates={loadingSpecialRates}
                                    seatSearchSpecialRate={seatSearchSpecialRate}
                                    specialRates={dataToCreateSpecialRate && dataToCreateSpecialRate.specialRates}
                                    openModalSpecialRates={openModalSpecialRates}
                                    removeSpecialRate={removeSpecialRate}
                                    update={false}
                                />
                            </LoadMask>
                        </div>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

export default Create;
