import React from 'react';
import { connect } from 'react-redux';

const ConfMsg = ({ confirmed }) => (
    <div className="mt-3 no-print">
        <h3 className="text-center">
            THANK YOU FOR BOOKING WITH US.
        </h3>
        <h6 className="mb-0 text-center">
            YOUR CONFIRMATION {confirmed.transactionId}
        </h6>
    </div>
);

const mstp = ({ itinerary: { confirmed } }) => ({
    confirmed,
});

export default connect(mstp)(ConfMsg);
