import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderTextArea } from "../../Utils/renderField";

const Form = ({ closeModal, handleSubmit }) => (
    <form name="refundMountForm" className="col-12" onSubmit={handleSubmit}>
        <div className="py-4 px-5 mb-2 border rounded-right rounded-bottom">
            <label htmlFor="agent_notes">Reason for cancellation:</label>
            <Field
                name="noteCanceled"
                component={renderTextArea}
                rows="3"
                label="Explain why the trip is being canceled"
            />
            <br style={{borderTop: "None"}}/>
            <div className="d-flex justify-content-end">
                <button className="btn btn-link mr-2" type="button" onClick={closeModal}>
                    CLOSE
                </button>
                <button className="btn btn-primary" type="submit">
                    PROCESS
                </button>
            </div>
        </div>
    </form>
);

const VoidModal = reduxForm({
    form: "voidCartForm",
    validate: () => {
        const errors = {};
        return errors;
    },
})(Form);

export default VoidModal;
