import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Nav, NavItem, NavLink } from 'reactstrap';

import format from '../../../../../utility/format';
import { setActiveVehicle } from '../../../../../../redux/modules/vehicle';

const WizardAnchor = React.forwardRef(
    ({ href, onClick, highlight, available, children }, ref) => (
        <a
            ref={ref}
            href={href}
            onClick={onClick}
            className={classNames(
                'bg-wizard flex-grow-1 mt-2 mt-md-4 p-2 text-secondary',
                {
                    'text-light bg-wizard--selected': highlight,
                    'bg-wizard--available': available,
                }
            )}
        >
            {children}
        </a>
    )
);

const WizardButton = ({ onClick, highlight, available, children }) => (
    <button
        onClick={onClick}
        className={classNames(
            'bg-wizard flex-grow-1 mt-2 mt-md-4 p-2 text-secondary',
            {
                'text-light bg-wizard--selected': highlight,
                'bg-wizard--available': available,
            }
        )}
    >
        {children}
    </button>
);

const WizardHeader = ({
    step,
    checkout,
    itinerary,
    passenger,
    handleRoutes,
    activeVehicle,
    vehicleChosen,
    setActiveVehicle,
    itinerary: { from, to, schedule, hour, minutes },
}) => {
    const routes = [
        {
            step: 3,
            route: '/passenger',
            txt: 'PASSENGER INFO',
            highlight: step === 3,
            available: vehicleChosen,
        },
        {
            step: 4,
            route: '/payment',
            txt: 'PAYMENT',
            highlight: step === 4,
            available: !isEmpty(passenger),
        },
    ];

    return (
        <div className="d-flex flex-column flex-md-row flex-wrap">
            {schedule && step && (
                <React.Fragment>
                    {routes.map(({ route, txt, ...wizardProps }, i) =>
                        <WizardButton
                            {...wizardProps}
                            key={i}
                            onClick={() => handleRoutes(routes[i].step)}
                        >
                            {txt}
                        </WizardButton>
                    )}
                    <Nav tabs className="vehicle-select d-none d-md-flex mt-1">
                        <NavItem key={1}>
                            <NavLink
                                className={classNames({
                                    selected:
                                        activeVehicle == 1 &&
                                        step === 1,
                                })}
                                onClick={() => setActiveVehicle(`${1}`)}
                            >
                                <div className="d-flex justify-content-between clickable">
                                    <span className="font-info">
                                        Traveling from: {from}
                                    </span>
                                    <span className="font-info">
                                        Traveling to: {to}
                                    </span>
                                    <span className="font-info">
                                        Date:
                                        {schedule.format('ddd, DD MMMM YYYY')}
                                    </span>
                                    <span className="font-info">
                                        Time:
                                        {format.hour( hour.value, minutes.value )}
                                    </span>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </React.Fragment>
            )}
        </div>
    );
};

WizardHeader.propTypes = {
    step: PropTypes.number,
    checkout: PropTypes.object.isRequired,
    passenger: PropTypes.object.isRequired,
    itinerary: PropTypes.object.isRequired,
    vehicleChosen: PropTypes.number.isRequired,
    activeVehicle: PropTypes.string.isRequired,
    setActiveVehicle: PropTypes.func.isRequired,
};

const mstp = state => ({
    ...state.itinerary,
    activeVehicle: state.vehicle.activeVehicle,
    vehicleChosen: state.vehicle.vehicle.length,
});

const mdtp = { setActiveVehicle };

export default connect(mstp, mdtp)(WizardHeader);
