import React from 'react';
import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/arilineCrew";
import LoadMask from  "../../Utils/LoadMask";
import FlightCard from "./flightCard";
import { getFullPathWithParams, reservationsAirlineTrackTrip, reservationsAirlineTrackTripFlightFlightsId } from '../../../../routesConfiguration/paths';


class Flights extends React.Component {

    componentDidMount(){
        if(!this.props.inputData && !this.props.flights){
            const { flight } = this.props.match.params;
            if(!flight)
                this.props.history.replace(reservationsAirlineTrackTrip);

            const [airline, airport, flightNumber] = flight.split('-');
            this.props.getFlights({ airline, airport, flightNumber });
        }
    }

    getTripDetails = (data) => {
        const { flight } = this.props.match.params;
        const [airline, airport, flightNumber] = flight.split('-');

        const path = getFullPathWithParams(
            reservationsAirlineTrackTripFlightFlightsId,
            ["flight", "id"],
            [`${airline}-${airport}-${flightNumber}`, data.TripID]
        );

        this.props.history.push(path);
    }

    render(){

        const {
            flights,
            loading,
            inputData,
            getSingleDetails,
        } = this.props;

        return (
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <div className="flex-3-5">
                        <div className="mt-5 m-2 m-lg-5 p-3">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-10 col-md-6 my-2">
                                    <h4 className="mb-4 d-flex justify-content-between">
                                        Trip Selection
                                    </h4>
                                    <LoadMask loading={loading} blur>
                                        {inputData && (
                                            <h5 style={{ fontWeight: "bolder" }}>
                                                {`${inputData.airlineCode.code}${inputData.flightNumber}`}
                                            </h5>
                                        )}
                                        {flights &&  flights.map( (item, index) => (
                                            <FlightCard
                                                key = {index}
                                                item = {item}
                                                date = {item.PUDate}
                                                time = {item.PUTime}
                                                from = {item.PickupAirportDesc}
                                                to = {item.DropoffAirportDesc}
                                                onClick = {()=>this.getTripDetails(item)}
                                            />
                                        ))}
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = (state) => ({
    ...state.airlineCrew,
    userInfo: state.login.userInfo,
});

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Flights);
