import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../../../../redux/modules/locations";
import IconEarthAmericas from "../../../icons/earth-americas";
import IconEdit from "../../../icons/edit";
import IconTag from "../../../icons/tag";
import IconVan from "../../../icons/van";
import { getPathWithParams, settingsRatesLocationsIdPromotionalRates, settingsRatesLocationsIdUpdate, settingsRatesLocationsIdVehicles, settingsRatesLocationsIdZipCodes } from "../../../../routesConfiguration/paths";

class DetailContainer extends React.Component {
    componentDidMount() {
        const { getLocationHeader, match } = this.props;
        getLocationHeader(match.params.id);
    }

    render() {
        const {
            userInfo: { permission },
            match: { params },
            header,
        } = this.props;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                {permission &&
                permission.find(({ readLocations }) => !!readLocations) ? (
                    <div className="col-12 col-xl-10 d-flex flex-wrap justify-content-center">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            {header && (
                                <React.Fragment>
                                    <h1>
                                        {`${header.city}, ${header.state} (${header.cityCode})`}
                                    </h1>
                                    &nbsp;
                                    <Link
                                        to={getPathWithParams(
                                            settingsRatesLocationsIdUpdate,
                                            "id",
                                            header._id
                                        )}
                                    >
                                        <IconEdit className="smallIcon" />
                                    </Link>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link
                                to={getPathWithParams(
                                    settingsRatesLocationsIdVehicles,
                                    "id",
                                    params.id
                                )}
                                style={{ color: "#000" }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: "2.5rem" }}>
                                    <IconVan className="h-100" />
                                </div>
                                <span className="mt-3">Vehicles</span>
                            </Link>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link
                                to={getPathWithParams(
                                    settingsRatesLocationsIdZipCodes,
                                    "id",
                                    params.id
                                )}
                                style={{ color: "#000" }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: "2.5rem" }}>
                                    <IconEarthAmericas className="h-100" />
                                </div>
                                <span className="mt-3">Zip Codes</span>
                            </Link>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link
                                to={getPathWithParams(
                                    settingsRatesLocationsIdPromotionalRates,
                                    "id",
                                    params.id
                                )}
                                style={{ color: "#000" }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: "2.5rem" }}>
                                    <IconTag className="h-100" />
                                </div>
                                <span className="mt-3 text-center">Promos</span>
                            </Link>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.locations,
    userInfo: state.login.userInfo,
});

const mdtp = { ...actions };

export default connect(mstp, mdtp)(DetailContainer);
