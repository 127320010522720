import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, change, initialize as initializeForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import app from '../../../../libs/apiClient';
import { renderCurrency, renderSearchSelect } from '../../Utils/renderField';
import { renderFieldCheck } from '../../Utils/renderField/renderField';
import IconWarning from '../../../icons/warning';
import { Tooltip } from 'reactstrap';
import _ from 'lodash';


const LabelCheckLocation = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const { item } = props;

    return (
        <span> {item.location.city} {item.location.state}
            {item.notFoundInLocation
              ? <React.Fragment>
                    <span id="Tooltip">
                        <IconWarning className="smallIcon p-1 text-danger" />
                    </span>
                    <Tooltip placement="right" isOpen={tooltipOpen} target="Tooltip" toggle={toggle}>
                        This location doesn't have a profile
                    </Tooltip>
                </React.Fragment>
              : ''
            }
        </span>
    )
}

class ItemArray extends React.Component {

    getLocations = (search) => {
        const params = {query: { $paginate: false } };
        if(search){
            params.query.$or = [
                { city: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { state: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { cityCode: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { address: { $regex: _.escapeRegExp(search), $options: 'i' }},
            ]
        }
        return app.service('/api/location').find(params).then(data=>{
            // const { data = [] } = result;
            for(let i = 0; i < data.length; i++){
                data[i].label = `${data[i].city} ${data[i].state}`
            }
            return { options: data };
        }).catch(error=>{
            console.error(error);
            return { options: [] }
        })
    }

    render(){

        const { item, name } = this.props;

        return (
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
                <Field
                    name={`${name}.apply`}
                    component={renderFieldCheck}
                    label={<LabelCheckLocation item={item}/>}
                />
            </div>
        )
    }
}

class LocationVehicle extends React.Component{

    selectAll = (fields, value) => {
        let data = fields.getAll();

        for(let i = 0; i < data.length; i++){
            data[i].apply = value;
        }

        this.props.initializeForm('locationVehicle', { locations: data });
    }

    renderArray = (props) => {

        const { fields, meta: { submitFailed, error }  } = props;

        return (
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <div className="d-flex justify-content-end">

                        <button type="button" className="btn btn-secondary m-1" onClick={()=>this.selectAll(fields, false)}>
                            Remove all
                        </button>

                        <button type="button" className="btn btn-secondary m-1" onClick={()=>this.selectAll(fields, true)}>
                            Select all
                        </button>

                    </div>
                </div>
                <div className="col-12 d-flex flex-wrap my-4">
                    {fields.map((item,index) => (
                        <ItemArray
                            key={index}
                            name={item}
                            item={fields.get(index)}
                            remove={()=>fields.remove(index)}
                        />
                    ))}
                </div>
                {submitFailed && error &&
                    <div className="invalid-feedback-array text-danger">
                        {error}
                    </div>
                }
            </div>
        )
    }

    render(){

        const { handleSubmit, previusStep } = this.props;

        return(
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                    <div className="d-flex flex-wrap">
                        <div className="col-12 form-group">
                            <FieldArray
                                name="locations"
                                component={this.renderArray}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-end">
                        <button type="button" className="btn btn-secondary m-1" onClick={previusStep}>
                            Back
                        </button>
                        <button type="submit" className="btn btn-primary m-1">
                            Apply
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

const Form = reduxForm({
    form: "locationVehicle",
    destroyOnUnmount: false,
    validate: data=> {
        return validate(data, {
            locations: validators.exists()('Required field'),
        })
    }
})(LocationVehicle);


const mstp = state => ({
    ...state.vehicles,
})

const mdtp = {
    initializeForm,
}

export default connect(mstp, mdtp)(Form);
