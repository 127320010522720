import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/crewAdmin/scheduleReport';
import LoadMask from '../../../../Utils/LoadMask';
import Modal from 'react-responsive-modal';
import ScheduleChart from '../scheduleChart';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getPathWithParams, reservationsAirlineUploadsId } from '../../../../../../routesConfiguration/paths';


class ScheduleReportContainer extends React.Component{

    constructor(props){
        super(props);
        this.updloadsBtn = React.createRef(null);
        this.csrBtn = React.createRef(null);
    }

    state = {
        modal_detail: false,
        detail: undefined,
    }

    componentDidMount(){
        this.props.reportChart(this.props.airlineId);
    }

    changeFilter(){
        const uploadsValue = this.updloadsBtn.current.checked;
        const csrValue = this.csrBtn.current.checked;
        this.props.setFilters(uploadsValue, csrValue, this.props.airlineId)
    }

    openModalDetail = (modal_detail, detail) => {
        this.setState({ modal_detail, detail });
    }

    render(){

        const { uploadsFilter, csrFilter, dataChartReport, loadingChart } = this.props;

        return (
            <LoadMask loading={loadingChart} blur>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-12 mb-3 mt-5">
                        <div className="w-100 d-flex justify-content-center mb-3">
                            <div className="">
                                <input type="checkbox" id="uploadsType" name="uploadsType" checked={uploadsFilter} ref={this.updloadsBtn} onChange={()=>this.changeFilter()}/>
                                <label type="button" htmlFor="uploadsType" className="clickable pl-2">
                                    UPLOADS
                                </label>
                            </div>
                            <div className="ml-3">
                                <input type="checkbox" id="csrType" name="csrType" checked={csrFilter} ref={this.csrBtn} onChange={()=>this.changeFilter()}/>
                                <label type="button" htmlFor="csrType" className="clickable pl-2">
                                    CSR
                                </label>
                            </div>
                        </div>
                        <h4 className="text-center"> 6-Month Trip Count </h4>
                        {dataChartReport && dataChartReport.sumaryTrips ? (
                            <ScheduleChart
                                data = {dataChartReport.sumaryTrips}
                            />
                        ) : (
                            <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: "250px" }}>
                                <h5> No Data </h5>
                            </div>
                        )}
                    </div>
                    <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-10 pb-4">
                        {dataChartReport && dataChartReport.airports && dataChartReport.airports.length > 0 ? (
                            <React.Fragment>

                                <Modal
                                    open={this.state.modal_detail}
                                    onClose={() => this.openModalDetail(false)}
                                    styles={{
                                        modal: { minWidth: "35%"}
                                    }}
                                >
                                    <div className="m-3">
                                        {this.state.detail &&
                                            Object.keys(this.state.detail).length > 0 && (
                                                <React.Fragment>
                                                    <h2
                                                        style={{
                                                            color: `${ this.state.detail.schedules && this.state.detail.schedules.length > 0 && this.state.detail.schedules.every( ({ uploads }) => uploads && uploads.length > 0 && uploads.every((item) => item.totalDays > 0))
                                                                ? '#5CB96C' : this.state.detail.schedules && this.state.detail.schedules.length > 0 && this.state.detail.schedules.some( ({ uploads }) => uploads && uploads.length > 0 && uploads.some((item) => item.totalDays > 0))
                                                                    ? '#F0AD4E' : '#D9534F'
                                                            }`,
                                                        }}
                                                    >
                                                        {this.state.detail.airport.code}, {
                                                            (this.state.detail.dates[0].month != this.state.detail.dates[1].month) ||
                                                            (this.state.detail.dates[0].year != this.state.detail.dates[1].year)
                                                                ? `${moment(this.state.detail.dates[0].month, 'M').format('MMM')} - ${moment(this.state.detail.dates[1].month, 'M').format('MMM')}`
                                                                : `${moment(this.state.detail.dates[0].month, 'M').format('MMMM')}`
                                                        } {this.state.detail.dates[1].year}
                                                    </h2>
                                                    <h4> Months </h4>
                                                    <hr/>
                                                    {this.state.detail.schedules.map( (item, index) => (
                                                        <React.Fragment key={index}>
                                                            <h5> {moment(item.month, 'M').format('MMMM')} {item.year} ({item.uploads ? item.uploads.length : 0})</h5>
                                                            <h6>
                                                                {item.uploads && item.uploads.length > 0 && item.uploads[0].total > 0 ? 'Uploaded' : 'Not uploaded' }
                                                                {item.uploads && item.uploads.length > 0 && item.uploads[0].totalDays > 0 ? ` - ${item.uploads && item.uploads.length > 0 && item.uploads[0].totalDays} ${item.uploads && item.uploads.length > 0 && item.uploads[0].totalDays === 1 ? 'day' : 'days'}` : ''}
                                                                {item.uploads && item.uploads.length > 0 && item.uploads[0].schedule && (
                                                                    <Link
                                                                        to={getPathWithParams(
                                                                            reservationsAirlineUploadsId,
                                                                            "id",
                                                                            `${item.uploads && item.uploads.length > 0 && item.uploads[0].schedule}/`,
                                                                        )}
                                                                        className="btn btn-link p-1">
                                                                        (View)
                                                                    </Link>
                                                                )}
                                                            </h6>
                                                        </React.Fragment>
                                                    ))}
                                                </React.Fragment>
                                            )}
                                    </div>
                                </Modal>

                                <div className="mb-3">
                                    <h5 className="text-center">Current Schedules</h5>
                                </div>
                                {dataChartReport.airports.map((item, index) =>
                                    <div className="d-flex my-1 py-1 border rounded border-dark clickable" key={index}
                                        onClick={()=>this.openModalDetail(true, item)}
                                    >
                                        <span className="flex-1 bold px-2 text-center"> {item.route && item.route.name} </span>
                                        <span className={`flex-1 bold px-2 text-center ${item.schedules
                                            ? item.schedules.length > 0 && item.schedules.every(({ uploads}) => uploads && uploads.length > 0 && uploads.every((item) => item.totalDays > 0))
                                                ? 'text-success'
                                                : item.schedules.length > 0 && item.schedules.some(({ uploads}) => uploads && uploads.length > 0 && uploads.some((item) => item.totalDays > 0))
                                                    ? 'text-warning'
                                                    : 'text-danger'
                                            : ''
                                        }`}>
                                            {item.schedules
                                                ? item.schedules.length > 0 && item.schedules.every(({ uploads}) => uploads && uploads.length > 0 && uploads.every((item) => item.totalDays > 0))
                                                    ? 'COMPLETE'
                                                    : item.schedules.length > 0 && item.schedules.some(({ uploads}) => uploads && uploads.length > 0 && uploads.some((item) => item.totalDays > 0))
                                                        ? 'INCOMPLETE'
                                                        : 'NO TRIPS'
                                                : ''
                                            }
                                        </span>
                                    </div>
                                )}
                                {dataChartReport.general && (
                                    <div className="d-flex my-1 py-1 border rounded border-dark clickable"
                                        onClick={()=>this.openModalDetail(true, dataChartReport.general)}
                                    >
                                        <span className="flex-1 bold px-2 text-center"> GENERAL </span>
                                        <span className={`flex-1 bold px-2 text-center ${
                                            dataChartReport.general &&
                                                dataChartReport.general.schedules &&
                                                dataChartReport.general.schedules.some(item => item.uploads && item.uploads.length > 0)
                                                    ? "text-success"
                                                    : "text-danger"
                                        }`}>
                                            {dataChartReport.general &&
                                            dataChartReport.general.schedules &&
                                            dataChartReport.general.schedules.some(item => item.uploads && item.uploads.length > 0)
                                                ? "COMPLETE"
                                                : "NO TRIPS"}
                                        </span>
                                    </div>
                                )}
                            </React.Fragment>
                        ) : (
                            <div className="d-flex align-items-center justify-content-center">
                                <h5> No Airports </h5>
                            </div>
                        )}
                    </div>
                </div>
            </LoadMask>
        )
    }
}

const mstp = state =>({
    ...state.crewAdmin.scheduleReport,
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(ScheduleReportContainer);
