
import React from 'react';
import { CalendarRange, renderField, renderFieldCheck, renderSwitch, renderDropdownSelection } from '../../Utils/renderField/renderField';
import renderTimePicker from '../../../../TimePicker';
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import moment from 'moment';


let EspecialRatesScheduleForm = (props) => {

    const {
        handleSubmit,
        addTime,
        schedule,
        dates,
        changeDate,
        startTime,
        endTime,
        indefinite,
        isFuture,
        toggle,
        type="Days",
        showWeekOption = true,
        showMonthOption = true,
        showYearOption = true,
        recurring = "",
        removeSpecialRate=()=>{}
    } = props;

    return (
        <form name="especialRatesForm" onSubmit={handleSubmit}>
            <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 d-flex justify-content-center align-items-center flex-column">
                <h3> Schedule </h3>

                <div className="flex-1 d-flex flex-column w-100 py-2">
                    <label className="m-0"> Promo Code: </label>
                    <Field
                        component={renderField}
                        name="code"
                        className=""
                        type="text"
                    />
                </div>

                <div className="flex-1 d-flex justify-content-between align-items-center w-100 py-2">
                    <label className="m-0"> Date(s): </label>
                    <div className="d-flex align-items-center">
                        <label className="mb-0"> Type: &nbsp; </label>
                        <Field
                            component={renderDropdownSelection}
                            name="type"
                            placeholder = "Type"
                            selection
                            fluid
                            options = {[
                                { key:'Days', text: "Days", value: "Days" },
                                { key:'Range', text: "Range", value: "Range" }
                            ]}
                            labeled
                            className=""
                            customOnChange={(data)=> changeDate([], true)}
                        />
                    </div>
                </div>

                <div>
                    <Field
                        component={CalendarRange}
                        name="calendar"
                        className="flex-1 py-2"
                        changeDate={changeDate}
                        dates={dates}
                        multiple = {type === 'Days' ? true : false }
                        range = {type === 'Days' ? false : true }
                    />
                </div>

                <div className="flex-1 d-flex justify-content-between align-items-center w-100 py-2">
                    <label className="m-0"> Add time of day? </label>
                    <Field
                        component={renderSwitch}
                        name="addTime"
                    />
                </div>

                {addTime && (
                    <React.Fragment>
                        <div className="flex-1 w-100 py-2">
                            <label> Start time: </label>
                            <div className="flex-1 d-flex">
                                <div className="flex-1 d-flex timePickerLocation">
                                    <Field
                                        id="timepicker1"
                                        name="startTime"
                                        placeholder="12:00 PM"
                                        className=""
                                        component={renderTimePicker}
                                        style={{ height: "3rem" }}
                                        Value={startTime}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 w-100 py-2">
                            <label> End time: </label>
                            <div className="flex-1 d-flex">
                                <div className="flex-1 d-flex timePickerLocation">
                                    <Field
                                        id="timepicker2"
                                        name="endTime"
                                        placeholder="12:00 PM"
                                        component={renderTimePicker}
                                        style={{ height: "3rem" }}
                                        Value={endTime}
                                    />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}

                <div className="flex-1 d-flex justify-content-between align-items-center w-100 py-2">
                    <label className="m-0"> Recurring Schedule? </label>
                    <Field
                        component={renderSwitch}
                        name="schedule"
                    />
                </div>

                {schedule && (
                    <div className="flex-1 d-flex flex-column w-100 py-2">

                        {showWeekOption && (
                            <React.Fragment>
                                <div className="flex-1 radio c-radio c-radio-nofont d-flex">
                                    <label className="negro font-weight-normal">
                                        <Field
                                            name="recurring"
                                            className="form-control p-0"
                                            component={"input"}
                                            type="radio"
                                            value="weekly"
                                        />
                                        <span />
                                        Weekly
                                    </label>
                                </div>
                                {recurring === 'weekly' &&
                                    <React.Fragment>
                                        <div className="ml-4 p-1 d-flex flex-row">
                                            <div className="flex-1 d-flex justify-content-start align-items-center">
                                                <Field
                                                    component={renderField}
                                                    type="number"
                                                    name="repeatTimes"
                                                    disabled={indefinite}
                                                    placeholder="TOTAL WKS"
                                                />
                                            </div>
                                            <div className="flex-1 d-flex justify-content-start align-items-center ml-2">
                                                <Field
                                                    component={renderFieldCheck}
                                                    name="indefinite"
                                                    label="Indefinite"
                                                    classNameLabel="mb-0"
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )}

                        {showMonthOption && (
                            <React.Fragment>
                                <div className="flex-1 radio c-radio c-radio-nofont d-flex">
                                    <label className="negro font-weight-normal">
                                        <Field
                                            name="recurring"
                                            className="form-control p-0"
                                            component={"input"}
                                            type="radio"
                                            value="monthly"
                                        />
                                        <span />
                                        Monthly
                                    </label>
                                </div>
                                {recurring === 'monthly' &&
                                    <React.Fragment>
                                        <div className="ml-4 p-1 d-flex flex-row">
                                            <div className="flex-1 d-flex justify-content-start align-items-center">
                                                <Field
                                                    component={renderField}
                                                    type="number"
                                                    name="repeatTimes"
                                                    disabled={indefinite}
                                                    placeholder="TOTAL MOS"
                                                />
                                            </div>
                                            <div className="flex-1 d-flex justify-content-start align-items-center ml-2">
                                                <Field
                                                    component={renderFieldCheck}
                                                    name="indefinite"
                                                    label="Indefinite"
                                                    classNameLabel="mb-0"
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )}

                        {showYearOption && (
                            <React.Fragment>
                                <div className="flex-1 radio c-radio c-radio-nofont d-flex">
                                    <label className="negro font-weight-normal">
                                        <Field
                                            name="recurring"
                                            className="form-control p-0"
                                            component={"input"}
                                            type="radio"
                                            value="yearly"
                                        />
                                        <span />
                                        Yearly
                                    </label>
                                </div>
                                {recurring === 'yearly' &&
                                    <React.Fragment>
                                        <div className="ml-4 p-1 d-flex flex-row">
                                            <div className="flex-1 d-flex justify-content-start align-items-center">
                                                <Field
                                                    component={renderField}
                                                    type="number"
                                                    name="repeatTimes"
                                                    disabled={indefinite}
                                                    placeholder="TOTAL YRS"
                                                />
                                            </div>
                                            <div className="flex-1 d-flex justify-content-start align-items-center ml-2">
                                                <Field
                                                    component={renderFieldCheck}
                                                    name="indefinite"
                                                    label="Indefinite"
                                                    classNameLabel="mb-0"
                                                />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )}
                    </div>
                )}
                <div className="flex-1 d-flex justify-content-center align-items-center w-100">
                    {isFuture && (
                        <button
                            type="button"
                            className="btn btn-secondary mt-5"
                            style={{ minWidth: "10rem" }}
                            onClick={removeSpecialRate}
                        >
                            Delete
                        </button>
                    )}
                    &nbsp;
                    <button
                        type="button"
                        onClick={toggle}
                        className="btn btn-link px-4 mt-5"
                        style={{ minWidth: "10rem" }}
                    >
                        Cancel
                    </button>
                    &nbsp;
                    <button
                        type="submit"
                        className="btn btn-primary px-4 mt-5"
                        style={{ minWidth: "10rem" }}
                    >
                        Next
                    </button>
                </div>
            </div>
        </form>
    )
}

EspecialRatesScheduleForm = reduxForm({
    form: "especialRatesForm",
    destroyOnUnmount: false,
    validate: (data, props) => {
        const errors = {};
        if(!props.dates || props.dates.length < 1) errors.calendar = "It's necesary one date";
        if(data.addTime){
            if(!data.startTime) errors.startTime = "This field is required";
            if(!data.endTime) errors.endTime = "This field is required";
            if(data.startTime && data.endTime){
                if(parseInt(data.startTime.hour) > parseInt(data.endTime.hour)){
                    errors.startTime = "Invalid Time";
                } else if(parseInt(data.startTime.hour) === parseInt(data.endTime.hour)){
                    if(parseInt(data.startTime.minutes) > parseInt(data.endTime.minutes)){
                        errors.startTime = "Invalid Time";
                    } else if(parseInt(data.startTime.minutes) === parseInt(data.endTime.minutes)) {
                        errors.startTime = "Invalid Time";
                        errors.endTime = "Invalid Time";
                    }
                }
            }
        }
        if(!data.code) errors.code = "This field is required";
        if(!data.indefinite){
            if(!data.repeatTimes) errors.repeatTimes = "Required";
            else if(data.repeatTimes <= 0) errors.repeatTimes = "Invalid";
        }
        return errors;
    }
})(EspecialRatesScheduleForm);

const selector = formValueSelector('especialRatesForm');
export default EspecialRatesScheduleForm = connect(state => {
    const type = selector(state, 'type');
    const calendar = selector(state, 'calendar');
    const addTime = selector(state, 'addTime');
    const schedule = selector(state, 'schedule');
    let startTime = selector(state, 'startTime');
    let endTime = selector(state, 'endTime');
    const recurring = selector(state, 'recurring');
    const isFuture = selector(state, 'isFuture');
    const indefinite = selector(state, 'indefinite');

    let showWeekOption = true;
    let showMonthOption = true;
    let showYearOption = true;

    if(calendar){
        let arrayDates = [];
        for(let index = 0; index < calendar.length; index++){
            arrayDates.push(moment(`${calendar[index].month.number}/${calendar[index].day}/${calendar[index].year}`));
        }
        if(arrayDates.length > 0){
            let maxDate = arrayDates.reduce((max, current) => current.isAfter(max) ? current : max, arrayDates[0]);
            let minDate = arrayDates.reduce((min, current) => current.isBefore(min) ? current : min, arrayDates[0]);
            if(maxDate.month() !== minDate.month()){
                showMonthOption = false;
                showWeekOption = false;
            } else if(minDate.week() !== maxDate.week()){
                showWeekOption = false;
            }
        }
    }

    if(startTime)
        startTime = moment(`${moment().format('YYYY-MM-DD')} ${startTime.hour}:${startTime.minutes}`);
    else startTime = undefined;

    if(endTime)
        endTime = moment(`${moment().format('YYYY-MM-DD')} ${endTime.hour}:${endTime.minutes}`);
    else endTime = undefined;

    return {
        addTime,
        schedule,
        type,
        indefinite,
        showWeekOption,
        showMonthOption,
        showYearOption,
        startTime,
        isFuture,
        endTime,
        recurring,
    }
})(EspecialRatesScheduleForm);
