import { createActions, handleActions } from "redux-actions";
import moment from "moment";
import Swal from 'sweetalert2';
import app from "../../libs/apiClient";
import { push } from 'react-router-redux';
import { change } from "redux-form";
import { errorHandler } from '../../common/utility/constants';
import { reservationsCharterBookings } from "../../routesConfiguration/paths";

const service = app.service("/api/report");
const serviceReservations = app.service("api/reservation/");

const LOADING_DATA = "LOADING_DATA_DASHBOARD";
const LOADING_REPORT_MONTHS = "LOADING_REPORT_MONTHS_DASHBOARD";
const LOADING_RESERVATION_DETAIL = "LOADING_RESERVATION_DETAIL_DASHBOARD";
const SET_DATA = "SET_DATA_DASHBOARD";
const SET_PAGE = "SET_PAGE_DASHBOARD";
const SET_REPORT_MONTHS = "SET_REPORT_MONTHS_DASHBOARD";
const SET_SORT = "SET_SHORT_DASHBOARD";
const SET_RESERVATION = "SET_RESERVATION_DASHBOARD";
const LOADING_MODAL_CANCEL = "LOADING_MODAL_CANCEL_DASHBOARD";
const LOADING_REPORTS_SCHEDULES = "LOADING_REPORTS_SCHEDULES";
const SET_DATA_SCHEDULES = "SET_DATA_SCHEDULES";

const loading = ( type, loading = true ) => ({
    type,
    loading
})

const setData = ( type, data ) => ({
    type,
    data
})

const setPage = ( page = 1 ) => ({
    type: SET_PAGE,
    page
})

const setOrdering = ( ordering ) => ({
    type: SET_SORT,
    ordering,
})

export const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading(LOADING_DATA, true));
    try {
        const query = {
            query: {
                $skip: (page - 1) * 3,
                $limit: 3,
                canceled: { $nin: [true] },
                puTime: {
                    $gte: moment().format("YYYY-MM-DD h:mm"),
                },
            }
        };
        const store = getState().dashboard;
        if(store.ordering){
            query.query.$sort = store.ordering;
        }
        const response = await serviceReservations.find(query);
        dispatch(setData(SET_DATA, response));
        dispatch(setPage(page));
    } catch (err) {
        console.log("error", err);
    } finally {
        dispatch(loading(LOADING_DATA, false));
    }
};

export const getMonthSnapshot = () => async (dispatch) => {
    dispatch(loading(LOADING_REPORT_MONTHS, true));
    let params = {
        action: "reportMonthSnapshot"
    }
    try {
        const reportMonthSnapshot = await service.get(params);
        dispatch(setData(SET_REPORT_MONTHS, reportMonthSnapshot));
    } catch (err) {
        console.log("error", err);
    } finally {
        dispatch(loading(LOADING_REPORT_MONTHS, false));
    }
};

export const getScheduleMonths = () => async (dispatch, getStore) => {
    dispatch(loading(LOADING_REPORTS_SCHEDULES, true));
    try {
        const month = moment().date() > 5
            ? moment().month() + 2
            : moment().month() + 1;
        const year = moment().date() > 5
            ? moment().month() === 11 ? moment().year() + 1 : moment().year()
            : moment().year()
        const response = await app.service('/api/schedule').find({ query: {
            $reportDashboard: true,
            month,
            year,
        }});

        // separate the results into sets of 10 elements
        let totalPages = Math.ceil(response.result.length / 10);

        const newResult = []
        for(let page = 0; page < totalPages; page++){
            let list = [];
            let initial = (page * 10);
            let final = Math.min((initial + 10), response.result.length);
            for(let index = initial; index < final; index++)
                list.push(response.result[index]);
            newResult.push(list);
        }
        response.result = newResult;

        dispatch(setData(SET_DATA_SCHEDULES, response));
    } catch ( e ) {
        console.log(e);
    }
    dispatch(loading(LOADING_REPORTS_SCHEDULES, false));

}

export const openBookingWithMonth = (month) => (dispatch) => {
    const startDay = moment(`${month}`, 'MMMM, YYYY').format('YYYY-MM-01T06:00:00.000Z');
    const endDay = moment(`${month}`, 'MMMM, YYYY').endOf('month').format('YYYY-MM-DDT06:00:00.000Z');

    dispatch(push(reservationsCharterBookings));
    dispatch(change('reservationListFilter', 'startDate', startDay));
    dispatch(change('reservationListFilter', 'endDate', endDay));
}

export const getReservation = (id) => async (dispatch) => {
    dispatch(loading(LOADING_RESERVATION_DETAIL, true));
    try {
        const reservation = await serviceReservations.get(id);
        dispatch(setData(SET_RESERVATION, reservation));
    } catch (err) {
        dispatch(errorHandler(err, "there was an error getting the data"));
    } finally {
        dispatch(loading(LOADING_RESERVATION_DETAIL, false));
    }
}

export const onSortChange = (sort) => (dispatch, getState) => {
    const store = getState().dashboard;
    if(store.ordering && Object.keys(store.ordering)[0] == sort){
        if(Object.values(store.ordering)[0] == 1)
            sort = { [sort]: -1 }
        else sort = { [sort]: 1 }
    } else {
        sort = { [sort]: 1 }
    }
    dispatch(setOrdering(sort));
    dispatch(find())
}

export const cancelAndRefund = (closeModal) => (dispatch, getState) => {
    dispatch(loading(LOADING_MODAL_CANCEL, true));
    const store = getState().dashboard;
    const { values = {} } = getState().form.refundMountForm || {};
    const data = store.reservation
    let refund = data.refund

    let new_data = {
        reservation: data._id,
        refundAmount: values.amount,
        agentNotes: values.agent_notes
    }

    if(refund) refund.unshift(new_data)
    else refund = [new_data]

    const params = {
        action: 'CANCEL_AND_REFUND',
        refund: refund,
    }

    serviceReservations
    .patch(data._id, params)
    .then((response) => {
        dispatch(setData(response))
        Swal.fire({
            title: "Success",
            text: `The amount has been refunded`,
            type: "success",
            confirmButton: "OK",
            confirmButtonColor: "#D50032",
        }).then((result) => {
            dispatch(find(store.page))
            closeModal(false)
        })
    })
    .catch((err) => {
        dispatch(errorHandler(err));
    })
    .finally(()=>{
        dispatch(loading(LOADING_MODAL_CANCEL, false));
    })
}

const reducers = {
    [LOADING_DATA]: (state, { loading }) => ({ ...state, loadingData: loading }),
    [LOADING_REPORT_MONTHS]: ( state, { loading }) => ({ ...state, loadingReportMonths: loading }),
    [LOADING_RESERVATION_DETAIL]: (state, { loading }) => ({ ...state, loadingReservationDetail: loading}),
    [LOADING_REPORTS_SCHEDULES]: (state, { loading }) => ({ ...state, loadingReportSchedules: loading }),
    [LOADING_MODAL_CANCEL]: (state, { loading }) => ({ ...state, loadingModalCancel: loading }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_DATA_SCHEDULES]: (state, { data }) => ({ ...state, report_schedules: data }),
    [SET_REPORT_MONTHS]: (state, { data }) => ({ ...state, reportMonths: data }),
    [SET_SORT]: (state, { ordering }) => ({ ...state, ordering }),
    [SET_RESERVATION]: (state, { data }) => ({ ...state, reservation: data }),
};

const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    ordering: {puTime: 1},
    reportMonths: {},
    report_schedules: {},
    loadingReportMonths: false,
    loadingReportSchedules: false,
    loadingReservationDetail: false,
    loadingModalCancel: false,
    loadingData: false,
    reservation: {},
};

export default handleActions(reducers, initialState);
