import { connect } from "react-redux";
import Grid from "./TourGrid";
import { find, destroy, loadBanner, setSearch, loadSetBanner, setSort } from "../../../../redux/modules/tours";

const mstp = (state) => ({
    ...state.tours,
    userInfo: state.login.userInfo,
})

const mdtp = { find, destroy, loadBanner, setSearch, loadSetBanner, setSort };

export default connect(mstp, mdtp)(Grid);
