import React from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderCurrency, renderTextArea } from '../Utils/renderField';
import { connect } from "react-redux";

const RefundModal = ({
    closeModal,
    handleSubmit,
}) => (
    <form name="refundMountForm" className="row" onSubmit={handleSubmit}>

        <div className="col-8">
            <Field
                name="amount"
                component={renderCurrency}
                className="form-control"
                label="Amount"
            />
            <br style={{borderTop: "None"}}/>
            <Field
                name="agent_notes"
                component={renderTextArea}
                rows="3"
                label="Agent Notes"
            />
        </div>
        <div className="col-4">
            <button className="btn btn-primary mb-2" type="submit">
                Process
            </button>
            <button className="btn btn-link" type="button" onClick={closeModal}>
                Cancel
            </button>
        </div>
    </form>
)

const formModal = reduxForm({
    form: "refundMountForm",
    validate: (data, {dataReservation}) => {
        const errors = {};
        if(!data.amount){
            errors.amount = "Required field"
        } else if (data.amount > 0){
            let totalRefund = 0;
            if(dataReservation && dataReservation.refund)
                totalRefund = dataReservation.refund.reduce((total, current) => total + current.refundAmount, 0)
            if (dataReservation && data.amount > (dataReservation.transactionAmount - totalRefund)){
                errors.amount = "The amount is higher than the transaction"
            }
        } else{
            if(data.amount < 0){
                errors.amount = "The amount must not be less than zero"
            }
        }
        return errors;
    },
})(RefundModal);

export default connect()(formModal);