import { createActions, handleActions } from "redux-actions";
import app from "../../libs/apiClient";

const service = app.service("/api/report");

// Actions
export const { loadingRevenue, setData } = createActions({
    LOADING_REVENUE: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
});

export const find = (params = {}) => async (dispatch, getState) => {
    dispatch(loadingRevenue());

    try {
        const response = await service.find(params);
        dispatch(setData(response));
    } catch (e) {
        console.log("error", e);
    } finally {
        dispatch(loadingRevenue(false));
    }
};

// Reducers
const reducers = {
    [loadingRevenue]: (state, { payload: { loading } }) => ({
        ...state,
        loading,
    }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
};

export const initialState = {
    data: {},
    loading: false,
};

export default handleActions(reducers, initialState);
