import moment from 'moment';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import app from '../../../../libs/apiClient';
import { renderSearchSelect, renderDatePicker } from '../../Utils/renderField/renderField';
import _ from 'lodash';


const Form = (props) => {

    const { handleSubmit, cancel, formValues } = props;

    const loadAirlines = (search) => {

        const params = { $paginate: false };
        if(search){
            params.$or = [
                { code: { $regex: _.escapeRegExp(search), $options: 'i' } },
                { company: { $regex: _.escapeRegExp(search), $options: 'i' } },
            ]
        }

        return app.service('/api/airline').find({
            query: params
        }).then(result => {
            for(let i = 0; i < result.length; i++)
                result[i].label = `${result[i].code} ${result[i].company}`;

            result.sort((a,b) => a.code.localeCompare(b.code));
            return { options: result };
        }).catch(error=>{
            console.log(error);
            return { options: []}
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12 mb-4">
                    <Field
                        name="customer"
                        component={renderSearchSelect}
                        loadOptions={loadAirlines}
                        placeholder="Select Customer"
                        valueKey="_id"
                        labelKey="label"
                        allKey
                    />
                </div>
                <div className="col-12 mt-3 form-group">
                    <Field
                        name="date"
                        component={renderDatePicker}
                        isOutsideRange={false}
                        numberOfMonths={1}
                        placeholder="Start Date"
                        className="p-0"
                    />
                </div>
                <div className="col-12 mt-3 form-group">
                    <Field
                        name="endDate"
                        component={renderDatePicker}
                        isOutsideRange={false}
                        numberOfMonths={1}
                        isDayBlocked={(isOutsideRange, day) => {
                            const today =
                                !isOutsideRange && formValues && formValues.date
                                    ? formValues.date
                                    : moment();
                            if (Math.abs(day.diff(today, "d")) > 14)
                                return true;
                        }}
                        placeholder="End Date"
                        className="p-0"
                    />
                </div>
                <div className="col-12 d-flex justify-content-end pt-4">
                    <button type="submit" className="btn btn-primary btn-lg">
                        SEARCH
                    </button>
                </div>
            </div>
        </form>
    )
}

const CustomerForm = reduxForm({
    form: "customerForm",
    validate: (data) => {
        const errors = {};
        if (!data.customer) errors.customer = "Required Field";
        if (!data.date) errors.date = "Required Field";
        if (!data.endDate) errors.endDate = "Required Field";
        if (data.date && data.endDate) {
            if (data.date.isAfter(data.endDate)) {
                errors.date = "Invalid Start Date";
            } else if (Math.abs(data.date.diff(data.endDate, "d")) > 14) {
                errors.endDate = "End date cannot be longer than 15 days";
            }
        }
        return errors;
    }
})(Form);

export default CustomerForm;
