import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderFieldCheck, renderNumber, renderSearchSelect, renderSelectField } from "../../../../Utils/renderField/renderField";
import app from "../../../../../../libs/apiClient";
import _ from "lodash";

const Form = (props) => {

    const {
        handleSubmit,
        onCancel,
    } = props;

    const loadAirports = (search) => {
        const params = {};
        if (search) {
            params.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: "i" } },
                {
                    description: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
                { code: { $regex: _.escapeRegExp(search), $options: "i" } },
            ];
        }

        return app
            .service("/api/airport")
            .find({ query: params })
            .then((result) => {
                for (let i = 0; i < result.data.length; i += 1) {
                    result.data[i].label = `${result.data[i].code} - ${result.data[i].name}`
                }
                return { options: result.data };
            })
            .catch(() => {
                return { options: [] };
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap mb-2">
                <div className="col-12 col-md-6">
                    Airport
                </div>
                <div className="col-12 col-md-6">
                    <Field
                        allKey
                        name="airport"
                        component={renderSearchSelect}
                        placeholder="Select airport..."
                        loadOptions={loadAirports}
                        className=""
                        valueKey="_id"
                        labelKey="label"
                    />
                </div>
            </div>
            <div className="d-flex flex-wrap mb-2">
                <div className="col-12 col-md-6">
                    Max Passengers
                </div>
                <div className="col-12 col-md-6">
                    <Field
                        name="max_passengers"
                        component={renderNumber}
                    />
                </div>
            </div>
            <div className="d-flex flex-wrap mb-2">
                <div className="col-12 col-md-6">
                    Time Threshold
                </div>
                <div className="col-12 col-md-6">
                    <Field
                        name="time_threshold"
                        component={renderNumber}
                        suffix=" mins"
                    />
                </div>
            </div>
            <div className="col-12 d-flex flex-wrap justify-content-end align-items-center">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onCancel}
                >
                    CANCEL
                </button>
                <button
                    type="submit"
                    className="btn btn-primary ml-2"
                >
                    SAVE
                </button>
            </div>
        </form>
    );
};

const SettingsForm = reduxForm({
    form: "airlineSettings",
    validate: (data) => {
        const errors = {};
        if (!data.max_passengers) errors.max_passengers = "Required Field";
        else if (data.max_passengers === 0)
            errors.max_passengers = "Invalid Value";
        if (!data.time_threshold) errors.time_threshold = "Required Field";
        if (!data.airport) errors.airport = "Required Field";
        return errors;
    },
})(Form);

export default SettingsForm;
