import { connect } from "react-redux";
import BreadCrumb from "./BreadCrumb";
import { actions } from "../../../redux/modules/breadcrumb";

const ms2p = (state) => ({
    ...state.breadcrumb,
});

const md2p = { ...actions };

export default connect(ms2p, md2p)(BreadCrumb);
