import { handleActions } from "redux-actions";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { goBack } from "react-router-redux";
import swal from "sweetalert2";
import _ from "lodash";
import app from "../../libs/apiClient";
import moment from 'moment';

const LOADING = "LOC_LOADING";
const SET_ZIP_FILTER = "SET_ZIP_FILTER";
const SET_EXCLUDE_ZIP_FILTER = "SET_EXCLUDE_ZIP_FILTER";
const SET_UPDATE_DATA = "LOC_SET_UPDATE_DATA";

const service = app.service("/api/location/");

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setUpdateData = (updateData) => ({
    type: SET_UPDATE_DATA,
    updateData,
});

export const setZipFilter = (zips) => ({
    type: SET_ZIP_FILTER,
    zipFilter: zips,
});

export const setExcludeZipFilter = (excludeZipFilter) => ({
    type: SET_EXCLUDE_ZIP_FILTER,
    excludeZipFilter,
});

const handleError = (error) => {
    console.error(error);
    let msg;
    if (typeof(error) == 'object')
        msg = error.message;
    else if(typeof(error) == 'string')
        msg = error;
    if(error.length)
        msg = 'There was an error';

    swal.fire({
        title: "Error",
        text: `${msg}`,
        type: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#D50032",
    })
}

// ------------------------------------
// Actions
// ------------------------------------

const loading = (loading = true) => (dispatch, getStore) => {
    const loader = getStore().locations.loading;
    if (loading) {
        dispatch({ type: LOADING, loading: loader + 1 });
    } else {
        dispatch({ type: LOADING, loading: loader - 1 });
    }
};

export const create = () => (dispatch, getState) => {
    dispatch(loading());
    // get form data
    const formData = getState().form.zipCodesLocationsForm.values;

    service.create(formData).then((data) => {
        // go back
        dispatch(goBack());
    })
    .catch((e) => {
        console.log("error", e);
    })
    .finally(() => {
        dispatch(loading(false));
    });
};

export const update = () => async (dispatch, getState) => {
    // get form data
    const formData = getState().form.zipCodesLocationsForm.values;
    const { zipFilter: zipArray, excludeZipFilter: excludeZipArray } = getState().zipCodesLocations;

    dispatch(loading());

    // If there is a search before update
    if(formData.zipFilter){
        const filteredZipCodes = zipArray.filter(({ zip }) => zip.includes(formData.zipFilter) );

        for(let index = formData.zipCodes.length - 1; index >= 0; index--){
            if(formData.zipCodes[index]._id){
                let foundIndex = filteredZipCodes.findIndex(({_id}) => _id === formData.zipCodes[index]._id);
                if(foundIndex !== -1) filteredZipCodes.splice(foundIndex, 1);
            }
        }

        for(let index = filteredZipCodes.length - 1; index >= 0; index--){
            let foundIndex = zipArray.findIndex(({_id}) => _id === filteredZipCodes[index]._id);
            if(foundIndex !== -1)
                zipArray.splice(foundIndex, 1);
        }

        formData.zipCodes = formData.zipCodes.reduce((acum, current) => {
            if(!current._id)
                acum.push(current);
            return acum;
        }, zipArray);
    }

    // If there is a search before update
    if(formData.excludeZipFilter){
        const filteredZipCodes = excludeZipArray.filter(({ zip }) => zip.includes(formData.excludeZipFilter) );

        for(let index = formData.excludeZipCodes.length - 1; index >= 0; index--){
            if(formData.excludeZipCodes[index]._id){
                let foundIndex = filteredZipCodes.findIndex(({_id}) => _id === formData.excludeZipCodes[index]._id);
                if(foundIndex !== -1) filteredZipCodes.splice(foundIndex, 1);
            }
        }

        for(let index = filteredZipCodes.length - 1; index >= 0; index--){
            let foundIndex = excludeZipArray.findIndex(({_id}) => _id === filteredZipCodes[index]._id);
            if(foundIndex !== -1)
                excludeZipArray.splice(foundIndex, 1);
        }

        formData.excludeZipCodes = formData.excludeZipCodes.reduce((acum, current) => {
            if(!current._id)
                acum.push(current);
            return acum;
        }, excludeZipArray);
    }

    service.patch(formData._id, formData).then(() => {
        // go back
        dispatch(setZipFilter([]));
        dispatch(goBack());
    }).catch((e) => {
        handleError(e);
    }).finally(() => {
        dispatch(loading(false));
    });
};


export const load2Update = (id) => (dispatch) => {
    dispatch(loading());
    service.get(id, { query: {
        $select: ['zipCodes', 'excludeZipCodes'],
    }}).then( async (response) => {
        dispatch(setUpdateData(response));
        dispatch(initializeForm("zipCodesLocationsForm", response));
    }).catch((e) => {
        handleError(e)
    }).finally(() => {
        dispatch(loading(false));
    });
};

const filterZipCodes = () => (dispatch, getState) => {
    const { zipFilter, zipCodes } = getState().form.zipCodesLocationsForm.values || {};
    let { zipFilter: zipArray } = getState().zipCodesLocations;

    if (!zipFilter) {
        dispatch(setZipFilter([]));
    } else if (!zipArray.length && zipFilter && zipFilter.length === 1) {
        dispatch(setZipFilter(zipCodes));
    }

    if (zipFilter) {
        zipArray = getState().zipCodesLocations.zipFilter;
        const filteredZipCodes = zipArray.filter(({ zip }) =>
            zip.includes(zipFilter)
        );

        dispatch(change("zipCodesLocationsForm", "zipCodes", filteredZipCodes));
    } else dispatch(change("zipCodesLocationsForm", "zipCodes", zipArray));
};

const filterExcludeZipCodes = () => (dispatch, getStore) => {
    const { excludeZipFilter, excludeZipCodes } = getStore().form.zipCodesLocationsForm.values || {};
    let { excludeZipFilter: zipArray } = getStore().zipCodesLocations;

    if (!excludeZipFilter) {
        dispatch(setExcludeZipFilter([]));
    } else if (!zipArray.length && excludeZipFilter && excludeZipFilter.length === 1) {
        dispatch(setExcludeZipFilter(excludeZipCodes));
    }

    if (excludeZipFilter) {
        zipArray = getStore().zipCodesLocations.excludeZipFilter;
        const filteredZipCodes = zipArray.filter(({ zip }) =>
            zip.includes(excludeZipFilter)
        );

        dispatch(change("zipCodesLocationsForm", "excludeZipCodes", filteredZipCodes));
    } else dispatch(change("zipCodesLocationsForm", "excludeZipCodes", zipArray));
}

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_ZIP_FILTER]: (state, { zipFilter }) => ({ ...state, zipFilter }),
    [SET_EXCLUDE_ZIP_FILTER]: (state, { excludeZipFilter }) => ({ ...state, excludeZipFilter}),
    [SET_UPDATE_DATA]: (state, { updateData }) => ({ ...state, updateData }),
};

export const initialState = {
    loading: 0,
    updateData: {},
    vehicleLocationLoader: false,
    excludeZipFilter: [],
    zipFilter: [],
};

export const actions = {
    create,
    update,
    load2Update,
    filterZipCodes,
    filterExcludeZipCodes,
};

export default handleActions(reducers, initialState);
