import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";


const LOADING = "LOADING_ACCOUNT_OWNER";
const LOADING_FORM = "LOADING_UPDATE_FORM_ACCOUNT_OWNER";
const SET_DATA = "SET_DATA_ACCOUNT_OWNER";
const SET_PAGE = "SET_PAGE_ACCOUNT_OWNER";
const SET_ITEM = "ITEM_ACCOUNT_OWNER";
const SET_SEARCH = "SET_SEARCH_ACCOUNT_OWNER";

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $sort: { company_name: 1 },
            $limit: 5,
            $skip: (page - 1) * 5,
            // $collation: { locale: "en" }
        }};
        const search = getStore().crewAdmin.account_owner.search;
        if(search){
            params.query.$or = [
                { contact: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { company_name: { $regex: _.escapeRegExp(search), $options: 'i'}},
            ]
        }

        const data = await app.service('/api/account_owner').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    let { page } = getStore().crewAdmin.account_owner;
    try {
        await app.service('/api/account_owner').create(data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let data = await app.service('/api/account_owner').get(id);
        await dispatch(initializeForm('ownerForm', data));
        dispatch(setItem(data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (data) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    let { page } = getStore().crewAdmin.account_owner;
    try{
        await app.service('/api/account_owner').update(data._id, data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.account_owner;
    try {
        await app.service('/api/account_owner').remove(id);
        dispatch(find(page))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setSearch = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH, search });
    dispatch(find());
}

export const clearData = () => dispatch => {
    dispatch(setItem({}));
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE, page });
export const setData = (data) => ({ type: SET_DATA, data });
export const setItem = (item) => ({ type: SET_ITEM, item });

export const actions = {
    find,
    create,
    clearData,
    setSearch,
    load2Update,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_ITEM]: (state, { item }) => ({ ...state, item }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH]: (state, { search }) => ({...state, search }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    search: undefined,
};

export default handleActions(reducers, initialState);
