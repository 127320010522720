import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { renderField } from "../Utils/renderField";
import { passwordReset } from "../../../routesConfiguration/paths";


const LoginForm = (props) => {
    const { handleSubmit, step, nextStep } = props;
    return (
        <form
            name="loginForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="form-group has-feedback">
                <label> USERNAME </label>
                <Field
                    name="email"
                    component={renderField}
                    type="text"
                    placeholder="someone@example.com"
                    className="form-control"
                />
            </div>
            {step == 2 ? (
                <React.Fragment>
                    <div className="form-group has-feedback">
                        <label> PASSWORD </label>
                        <Field
                            name="password"
                            component={renderField}
                            type="password"
                            placeholder="Password"
                            className="form-control"
                        />
                    </div>
                    <div className="clearfix" />
                    <div className="d-flex justify-content-start">
                        <Link to={passwordReset} className="text-primary text-decoration-underline"> Recover password </Link>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            type="submit"
                            className="btn btn-primary btn-lg my-1"
                        >
                            SIGN IN
                        </button>
                    </div>
                </React.Fragment>
            ) : (
                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-primary btn-lg my-1"
                    >
                        NEXT
                    </button>
                </div>
            )}
        </form>
    );
};

export default reduxForm({
    form: "login", // a unique identifier for this form
    validate: (data) => {
        const errors = {};
        if(!data.email) errors.email = "Required Field";
        if(!data.password) errors.password = "Required Field";
        return errors;
    }
})(LoginForm);
