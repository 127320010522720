import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import { activeFormatter } from "../../Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import Grid from "../../Utils/Grid/materialTable";
import { reservationsShuttlesTracking, reservationsShuttlesTrackingNew } from "../../../../routesConfiguration/paths";

const List = ({
    data,
    page,
    loading,
    find,
    change,
    destroy,
}) => {

    const tableRef = React.useRef();

    useEffect(() => {
        if (tableRef) tableRef.current.dataManager.changePageSize(10);
    }, []);

    return (
        <div className="row">
            <div className="col-sm-12 pt-2">
                <div className="grid-container material-livery-list">
                    <Grid
                        tableRef={tableRef}
                        title="Admin Shuttles"
                        columns={[
                            {
                                title: "Livery ID",
                                field: "customerId",
                            },
                            {
                                title: "Code",
                                field: "code",
                            },
                            {
                                title: "Actions",
                                field: "_id",
                                align: "center",
                                width: "13%",
                                render: (row) =>
                                    activeFormatter({
                                        editar: reservationsShuttlesTracking,
                                        eliminar: destroy,
                                    })(row._id, row),
                            },
                        ]}
                        actions={[
                            {
                                icon: () => (
                                    <Link
                                        className="btn btn-primary px-2 py-0 align-self-end rounded-circle"
                                        to={reservationsShuttlesTrackingNew}
                                    >
                                        <h3>+</h3>
                                    </Link>
                                ),
                                isFreeAction: true,
                            },
                        ]}
                        data={data.data}
                        onSearchChange={(s) => {
                            change("type", s);
                            find(1);
                        }}
                        onPageChange={(p) => find(p + 1)}
                        isLoading={loading}
                        page={page - 1}
                        totalCount={data.total}
                        options={{
                            pageSizeOptions: [],
                            filtering: false,
                            searchText: "Search by livery id, code",
                            idSynonym: "_id",
                            pageSize: 10,
                            headerStyle: {
                                backgroundColor: "#D50032",
                                color: "#FFF",
                                fontSize: "1rem",
                            },
                            cellStyle: {
                                fontSize: "1rem",
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default reduxForm({
    form: "shuttleListFilter",
    destroyOnUnmount: false,
})(List);
