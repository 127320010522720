import React from "react";
import { connect } from "react-redux";
import GenericNav from "../GenericNav";
import IconPen from "../../icons/pen";
import IconBridge from "../../icons/bridge";
import IconUser from "../../icons/user";
import IconCashRegister from "../../icons/cashRegister";
import IconPlaneTail from "../../icons/planeTail";
import IconSignsPost from "../../icons/signsPost";
import {
    settingsAccounts,
    settingsAirlines,
    settingsRates,
    settingsTours,
    settingsUserAccounts,
} from "../../../routesConfiguration/paths";

const Container = (props) => {
    const {
        userInfo: { permission, superUser },
    } = props;

    const items = [
        {
            to: settingsTours,
            label: "Tours",
            icon: <IconBridge className="h-100" />,
            show:
                permission && permission.find(({ tours }) => tours !== "none"),
        },
        {
            to: settingsAccounts,
            label: "Customers",
            icon: <IconUser className="h-100" />,
            show:
                permission &&
                permission.find(
                    ({ companies, clients, points }) =>
                        companies !== "none" ||
                        clients !== "none" ||
                        points !== "none"
                ),
        },
        {
            to: settingsRates,
            label: "Rates",
            icon: <IconCashRegister className="h-100" />,
            show:
                permission &&
                permission.find(
                    ({ vehicles, locations, aditionalCharges }) =>
                        vehicles !== "none" ||
                        locations !== "none" ||
                        aditionalCharges !== "none"
                ),
        },
        {
            to: settingsAirlines,
            label: "Airline Crew",
            icon: <IconPlaneTail className="h-100" />,
            show:
                permission &&
                permission.find(({ airlineCrew }) => airlineCrew !== "none"),
        },
        {
            to: settingsUserAccounts,
            label: "User Accounts",
            icon: <IconSignsPost className="h-100" />,
            show: superUser,
        },
    ];

    return <GenericNav items={items} />;
};

const mathStateToProps = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mathStateToProps, null)(Container);
