import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Field, reduxForm, FieldArray } from "redux-form";
import {
    renderField,
    renderSearchSelect,
    renderSelectField,
} from "Utils/renderField/renderField";
import validate from "./validate";
import app from "../../../../libs/apiClient";
import { vehicles, stateOptions } from "../../../utility/constants";
import moment from 'moment';
import LoadMask from "../../Utils/LoadMask";
import IconDownload from "../../../icons/download";
import GridSpecialRates from './specialRatesGrid';
import { settingsRatesLocations } from "../../../../routesConfiguration/paths";

const vehicleService = app.service("/api/vehicle/");

const getVehicles = (search) => {
    const query = {};
    if (search) {
        query.query = { type: search };
    }
    return vehicleService
        .find(query)
        .then((data) => {
            data.data.forEach((item) => {
                if (!_.find(vehicles, { _id: item._id })) {
                    item.type = `${item.type} ${item.class}`;
                    vehicles.push(item);
                }
            });
            return { options: vehicles };
        })
        .catch((e) => {
            console.log(e);
        });
};

class ItemArrayVehicles extends React.Component{

    state = {
        validVehicle: false
    }

    componentDidMount(){
        const { fields, index } = this.props;
        if(fields.get(index).vehicle){
            this.setState({ validVehicle: true });
        }
    }

    render(){

        const { index, vehicle, getVehicles, removeBaseRate, openEditVehicle, fields } = this.props;
        const { validVehicle } = this.state;

        return (
            <tr key={index}>
                <td>
                    <Field
                        name={`${vehicle}.vehicle`}
                        valueKey="_id"
                        labelKey="type"
                        component={renderSearchSelect}
                        label="Vehicle"
                        loadOptions={getVehicles}
                        myChange={(e)=> e
                            ? !validVehicle && this.setState({ validVehicle: true })
                            : this.setState({ validVehicle: false })
                        }
                    />
                </td>
                <td>
                    <Field
                        name={`${vehicle}.rate`}
                        type="number"
                        component={renderField}
                        placeholder="Rate"
                    />
                </td>
                <td className="text-center">
                    <button
                        type="button"
                        className="btn btn-secondary m-1"
                        onClick={() => removeBaseRate(index, (index) => fields.remove(index))}
                        style={{ width: "32px", height: "32px" }}
                    >
                        X
                    </button>
                    {validVehicle && (
                        <button type = "button"
                            className="btn btn-primary p-0 m-1"
                            onClick={() => openEditVehicle(index)}
                            style={{ width: "32px", height: "32px" }}
                        >
                            <img
                                className=""
                                title="Edit"
                                src={require("../../../../../assets/img/icons/edit.png")}
                                alt="Edit"
                                style={{
                                    minWidth: "28px",
                                    maxWidth: "28px",
                                    margin: "0 0.3rem !important",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}
                            />
                        </button>
                    )}
                </td>
            </tr>
        )
    }
}

const renderVehicles = ({
    fields,
    meta: { error, submitFailed },
    vehicles,
    openEditVehicle,
    removeBaseRate
}) => {

    return (
    <div className="row">
        <div className="col-sm-12">
            <hr />
            <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                <h3 className="ml-0 ml-sm-auto">
                    <strong>Vehicles</strong>
                </h3>
                <button
                    type="button"
                    className="btn btn-secondary ml-0 ml-sm-auto flex-nowrap flex-sm-wrap"
                    onClick={() => fields.push({})}
                >
                    Add Vehicle
                </button>
            </div>
            <br />
            <table className="table table-sm table-hover table-striped">
                <tbody>
                    <tr>
                        <th style={{ width: "40%" }}>Vehicle</th>
                        <th style={{ width: "40%" }}>Rate</th>
                        <th style={{ width: "20%" }} />
                    </tr>
                    {fields.map((vehicle, index) => (
                        <ItemArrayVehicles
                            key={index}
                            vehicle={vehicle}
                            index={index}
                            getVehicles = {getVehicles}
                            removeBaseRate={removeBaseRate}
                            openEditVehicle ={openEditVehicle}
                            fields={fields}
                        />
                    ))}
                </tbody>
            </table>
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger">
                    {error}
                </div>
            }
        </div>
    </div>
    )
};

class renderZipCode extends React.Component {
    constructor(props) {
        super(props);

        this.zipCodesContainer = React.createRef();
    }

    handleAddZipCode = () => {
        this.props.fields.push({});
        const lastRow = this.zipCodesContainer.current.lastChild;
        const [rowInput] = lastRow.childNodes[0].childNodes[0].childNodes;
        if(rowInput) rowInput.focus();
    };

    render() {
        const { filterZipCodes, zipCodes = [], nameFilter, title } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12">
                    <hr />
                    <div className="grid-title d-flex flex-column flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
                        <div className="d-flex flex-column align-items-center ml-0 ml-sm-auto">
                            <h3>
                                <strong> {title} ({zipCodes.length})</strong>
                            </h3>
                        </div>
                        <div className="d-flex flex-column flex-sm-row ml-0 ml-sm-auto">
                            <Field
                                name={nameFilter}
                                component={renderField}
                                cb={filterZipCodes}
                            />
                            <button
                                type="button"
                                onClick={this.handleAddZipCode}
                                className="btn btn-secondary flex-nowrap flex-sm-wrap ml-0 ml-sm-2 mt-2 mt-sm-0"
                            >
                                Add ZIP
                            </button>
                        </div>
                    </div>
                    <br />
                    <table className="table table-sm table-hover table-striped">
                        <tbody ref={this.zipCodesContainer}
                            className="d-flex flex-wrap justify-content-between"
                        >
                            <tr style={{ width: "100%" }}>
                                <th style={{ width: "90%" }}>ZIP Code</th>
                                <th style={{ width: "10%" }} />
                            </tr>
                            {this.props.fields.map((zip, i) => (
                                <tr key={`${zip}._id`}
                                    style={{ width: "10rem" }}
                                >
                                    <td>
                                        <Field
                                            name={`${zip}.zip`}
                                            type="text"
                                            component={renderField}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <button type="button"
                                            className="btn btn-secondary"
                                            onClick={() => this.props.fields.remove(i) }
                                        >
                                            X
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const SpecialRates = ({ row }) => (
    <div className="row mx-0"
        style={{ border: "2px solid #757575", cursor: "pointer" }}
    >
        <div className="col-12 col-sm-6 d-flex flex-column py-sm-2">
            <span style={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                <strong> Date(s):&nbsp;</strong>
                {row.calendar.dates ? row.calendar.dates.map( (date, index) =>
                    <React.Fragment key={index}>
                        {row.calendar.recurring || row.recurring
                            ? row.calendar.recurring === 'yearly' || row.recurring === 'yearly'
                                ? `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                                : row.calendar.recurring === 'monthly' || row.recurring === 'monthly'
                                    ? `${moment(`${date.month}/${date.day}/${date.year}`).format('Do')}`
                                    : `${moment(`${date.month}/${date.day}/${date.year}`).format('ddd')}`
                            : `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                        }
                        {row.calendar.dates.length - 1 !== index
                            ? row.type === 'Days' || row.calendar.type === 'Days'
                                ? ', ' : ' To '
                            : ''
                        }
                    </React.Fragment>
                ): row.calendar.map((date,index) =>
                    <React.Fragment key={index}>
                        {row.calendar.recurring || row.recurring
                            ? row.calendar.recurring === 'yearly' || row.recurring === 'yearly'
                                ? `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                                : row.calendar.recurring === 'monthly' || row.recurring === 'monthly'
                                    ? `${moment(`${date.month}/${date.day}/${date.year}`).format('Do')}`
                                    : `${moment(`${date.month}/${date.day}/${date.year}`).format('ddd')}`
                            : `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                        }
                        {row.calendar.length - 1 !== index
                            ? row.type === 'Days' || row.calendar.type === 'Days'
                                ? ', ' : ' To '
                            : ''
                        }
                    </React.Fragment>
                )}
            </span>

            {(row.calendar.recurring || row.recurring) &&
                <span> <strong> Recurring: </strong>
                    <span>
                        {row.calendar.recurring || row.recurring}&#44;&nbsp;
                    </span>
                    <span>
                        {row.calendar.dates
                            ? row.calendar.dates.reduce((min, current) =>
                                moment(`${current.month.number}/${current.day}/${current.year}`).isBefore(min) ? current : min,
                                moment(`${row.calendar.dates[0].month}/${row.calendar.dates[0].day}/${row.calendar.dates[0].year}`)
                            ).format('MMMYYYY')
                            : row.calendar.reduce((min, current) =>
                                moment(`${current.month.number}/${current.day}/${current.year}`).isBefore(min) ? current : min,
                                moment(`${row.calendar[0].month.number}/${row.calendar[0].day}/${row.calendar[0].year}`)
                            ).format('MMMYYYY')
                        }
                    </span>

                    {(row.repeatTimes > 0 || row.calendar.repeatTimes > 0)
                        ? <span> - {row.repeatTimes || row.calendar.repeatTimes}
                                {row.calendar.recurring || row.recurring
                                    ? row.calendar.recurring === 'yearly' || row.recurring === 'yearly'
                                        ? row.repeatTimes === 1 || row.calendar.repeatTimes === 1 ? 'yr' : 'yrs'
                                        : row.calendar.recurring === 'monthly' || row.recurring === 'monthly'
                                            ? row.repeatTimes === 1 || row.calendar.repeatTimes === 1 ? 'mo' : 'mos'
                                            : row.repeatTimes === 1 || row.calendar.repeatTimes === 1 ? 'wk' : 'wks'
                                    : 'Indefinite'
                                }
                            </span>
                        : <span> - Indefinite </span>
                    }
                </span>
            }
            {row.calendar.time
                ? <React.Fragment>
                    <span> <strong> Start Time: </strong> {moment(row.calendar.time.startTime, 'HH:mm').format('LT')} </span>
                    <span> <strong> End Time: </strong> {moment(row.calendar.time.endTime, 'HH:mm').format('LT')} </span>
                </React.Fragment>
                : row.startTime && row.endTime
                    ? <React.Fragment>
                        <span> <strong> Start Time: </strong> {moment(`${row.startTime.hour}:${row.startTime.minutes}`, 'HH:mm').format('LT')} </span>
                        <span> <strong> End Time: </strong> {moment(`${row.endTime.hour} ${row.endTime.minutes}`, 'HH:mm').format('LT')} </span>
                    </React.Fragment>
                    : <React.Fragment>
                        <span> <strong> Start Time: </strong> 24hrs </span>
                        <span> <strong> End Time: </strong> 24hrs </span>
                    </React.Fragment>
            }
        </div>
        <div className="col-12 col-sm-6 d-flex flex-column py-sm-2">
            <span> <strong> Code: </strong> { row.code } </span>
            <div className="flex-1">
                <span>
                    <strong> Vehicles: </strong>
                    {row.vehicles && row.vehicles.map( (vehicle, indexVehicle ) =>
                        <React.Fragment key={indexVehicle}>
                            {vehicle.vehicleInfo && vehicle.vehicleInfo.vehicleCode}{row.vehicles.length - 1 !== indexVehicle
                                ? ', ' : ''
                            }
                        </React.Fragment>
                    )}
                </span>
            </div>
        </div>
    </div>
);

const Form = (props) => {
    const { handleSubmit, vehicles, filterZipCodes, zipCodes, excludeZipCodes, openEditVehicle, removeBaseRate,
        openModalSpecialRates, specialRates, removeSpecialRate, seatSearchSpecialRate, openReportModal,
        loadingSpecialRates, showSearch, showDownloadButton, update, filterExcludeZipCodes } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>Location</strong>
                    </h2>
                </div>
                <br />
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="address">Address*</label>
                            <Field
                                id="autocomplete"
                                name="address"
                                component={renderField}
                                type="text"
                                placeholder="Search Address"
                                className="form-control"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="city">City*</label>
                            <Field
                                disabled
                                name="city"
                                component={renderField}
                                type="text"
                                placeholder="City"
                                className="form-control"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="state">State*</label>
                            <Field
                                disabled
                                name="state"
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                options={stateOptions}
                                placeholder="State"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="cityCode">City Code</label>
                            <Field
                                name="cityCode"
                                component={renderField}
                                type="text"
                                placeholder="City Code"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <FieldArray
                        name="vehicleBaseRates"
                        component={renderVehicles}
                        vehicles={vehicles}
                        openEditVehicle={openEditVehicle}
                        removeBaseRate={removeBaseRate}
                    />

                    <div className="row">
                        <div className="col-sm-12">
                            <hr />
                            <div className="grid-title d-flex flex-column flex-sm-row align-items-stretch">
                                <div className="flex-1 d-flex justify-content-center justify-content-sm-start align-items-center">
                                    <h3> <strong> Special-Rate Period </strong> </h3>
                                </div>
                                <div className="flex-1 d-flex justify-content-end align-items-center py-2 py-sm-0">
                                    {showDownloadButton &&
                                       <div className="mx-2">
                                           <span style={{ cursor: "pointer" }} onClick={openReportModal}>
                                                <IconDownload className="smallIcon"/>
                                           </span>
                                        </div>
                                    }
                                    {showSearch &&
                                        <div style={{ flex: 1 }}>
                                            <Field
                                                name="searchSpecialRate"
                                                component={renderField}
                                                type="text"
                                                placeholder="Search by code, vehicle code, recurring"
                                                onChange={ (event, value) => seatSearchSpecialRate(value) }
                                            />
                                        </div>
                                    }
                                    {specialRates && specialRates.length > 0 &&
                                        <button type="button"
                                            className="btn btn-secondary ml-2"
                                            onClick={()=>openModalSpecialRates()}
                                        >
                                            + ADD
                                        </button>
                                    }
                                </div>
                            </div>
                            <br />
                            <LoadMask loading={loadingSpecialRates} blur>
                                {specialRates && specialRates.length > 0
                                ? update
                                    ? <GridSpecialRates
                                        data={specialRates}
                                        openModalSpecialRates={openModalSpecialRates}
                                        columns={[
                                            {
                                                dataField: 'code',
                                                text: '',
                                                headerAttrs: {
                                                    hidden: true
                                                },
                                                formatter: (cell, row) => <SpecialRates row = {row} />
                                            }
                                        ]}
                                        loading={loadingSpecialRates}
                                    />
                                    : specialRates.map( (row, index) =>
                                        <span key={index} onClick={()=>openModalSpecialRates(index)}>
                                            <SpecialRates row = {row} />
                                        </span>
                                    )
                                : <div className="d-flex justify-content-center align-items-center flex-column py-4">
                                        <h4 className="py-2"> No special-rate periods </h4>
                                        <button type="button"
                                            className="btn btn-primary btn-lg"
                                            onClick={()=>openModalSpecialRates()}
                                        >
                                            + Add Special Rate
                                        </button>
                                    </div>
                                }
                            </LoadMask>
                        </div>
                    </div>

                    <FieldArray
                        name="zipCodes"
                        component={renderZipCode}
                        filterZipCodes={filterZipCodes}
                        zipCodes={zipCodes}
                        title="ZIP Codes"
                        nameFilter="zipFilter"
                    />

                    <FieldArray
                        name="excludeZipCodes"
                        component={renderZipCode}
                        showFilter={false}
                        nameFilter="excludeZipFilter"
                        title="Excluded ZIP Codes"
                        filterZipCodes={filterExcludeZipCodes}
                        zipCodes={excludeZipCodes}
                    />

                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center px-2 px-sm-0">
                        <Link className="btn btn-secondary m-1" to={settingsRatesLocations}>
                            Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary m-1">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const VehicleForm = reduxForm({
    // a unique name for the form
    form: "location",
    validate,
})(Form);

export default VehicleForm;
