import { connect } from "react-redux";
import Update from "./VehicleUpdate";
import { update, load2Update } from "../../../../redux/modules/vehicles";

function ms2p(state) {
    let photo;
    try {
        photo = state.form.vehicle.values.photo;
    } catch (e) {
        // pass
    }
    return {
        photo,
        loading: state.vehicles.loading,
        userInfo: state.login.userInfo,
    };
}

const mdtp = { update, load2Update };

export default connect(ms2p, mdtp)(Update);
