import React from "react";
import PropTypes from "prop-types";

import LoadMask from "../../Utils/LoadMask";
import Form from "../Create/RolesForm";

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.load2Update(this.props.match.params.id);
    }

    componentWillUnmount(){
        this.props.clear();
    }

    render() {
        const { update, loading, change, item, userInfo: { superUser } } = this.props;

        return (!superUser
          ? <div className="d-flex w-100 justify-content-center align-items-center">
                <label> You are not a superuser </label>
            </div>
          : <LoadMask loading={loading} blur>
                <div className="row">
                    {item && Object.keys(item).length > 0 && (
                        <Form
                            onSubmit={update}
                            change={change}
                            rolesItem={item}
                            initialValues={{
                                trendsDashboard: "none",
                                viewTrends: false,
                                viewBusiestMonth: false,
                                viewMonthlyRevenue: false,
                                monthlyManifestDashboard: "false",
                                newestTripsDashboard: "false",
                                reservations: "none",
                                readReservations: false,
                                createReservations: false,
                                updateReservations: false,
                                removeReservations: false,
                                tourReservations: "none",
                                readTourReservations: false,
                                createTourReservations: false,
                                updateTourReservations: false,
                                removeTourReservations: false,
                                airlineSchedule: "none",
                                readAirlineSchedule: false,
                                createAirlineSchedule: false,
                                updateAirlineSchedule: false,
                                removeAirlineSchedule: false,
                                reports: "false",
                                blogPost: "none",
                                readBlogPost: false,
                                createBlogPost: false,
                                updateBlogPost: false,
                                removeBlogPost: false,
                                promo: "none",
                                readPromo: false,
                                createPromo: false,
                                updatePromo: false,
                                removePromo: false,
                                administration: "false",
                                quotes: "none",
                                readQuotes: false,
                                createQuotes: false,
                                updateQuotes: false,
                                removeQuotes: false,
                                reviews: "none",
                                readReviews: false,
                                createReviews: false,
                                updateReviews: false,
                                removeReviews: false,
                                vehicles: 'none',
                                readVehicles: false,
                                createVehicles: false,
                                updateVehicles: false,
                                removeVehicles: false,
                                tours: 'none',
                                readTours: false,
                                createTours: false,
                                updateTours: false,
                                removeTours: false,
                                companies: 'none',
                                readCompanies: false,
                                createCompanies: false,
                                updateCompanies: false,
                                removeCompanies: false,
                                clients: 'none',
                                readClients: false,
                                createClients: false,
                                updateClients: false,
                                removeClients: false,
                                locations: 'none',
                                readLocations: false,
                                createLocations: false,
                                updateLocations: false,
                                removeLocations: false,
                                airlineCrew: 'none',
                                readAirlineCrew: false,
                                createAirlineCrew: false,
                                updateAirlineCrew: false,
                                removeAirlineCrew: false,
                                aditionalCharges: 'none',
                                readAditionalCharges: false,
                                createAditionalCharges: false,
                                updateAditionalCharges: false,
                                removeAditionalCharges: false,
                                points: "none",
                                readPoints: false,
                                createPoints: false,
                                updatePoints: false,
                                removePoints: false,
                            }}
                        />
                    )}
                </div>
            </LoadMask>
        );
    }
}
