import io from "socket.io-client";
import feathers from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";

const { BACKEND_URL } = process.env;

const socket = io(BACKEND_URL);
const app = feathers();

export const STORAGE_KEY = "feathers-jwt";

// Setup the transport (Rest, Socket, etc.) here
app.configure(
    socketio(socket, {
        timeout: 300000,
    })
);

app.service('/api/schedule').timeout = 600000;

const options = {
    storageKey: STORAGE_KEY,
    storage: window.localStorage,
    timeout: 90000
};
app.configure(auth(options));

export default app;
