import React from "react";
import { connect } from "react-redux";
import { change } from 'redux-form';
import Grid from "./CompaniesGrid";
import { clientTypes } from "../../../utility/constants";
import {
    find,
    destroy,
    setClientsQuery,
} from "../../../../redux/modules/clients";

const [person, company] = clientTypes;

class CompanyGridContainer extends React.Component {
    state = {
        activeTab: 0,
    };

    componentDidMount() {
        const { find, setQuery } = this.props;
        setQuery({
            userType: company,
            isVerified: false,
            rejectionReason: { $exists: false },
        });
        find();
    }

    componentWillUnmount() {
        const { setQuery } = this.props;
        setQuery({});
    }

    handlePendingQuery = () => {
        const { setQuery, find } = this.props;

        this.setState({ activeTab: 0 }, () => {
            setQuery({
                userType: company,
                isVerified: false,
                rejectionReason: { $exists: false },
            });

            find();
        });
    };

    handleValidatedQuery = () => {
        const { setQuery, find } = this.props;

        this.setState({ activeTab: 1 }, () => {
            setQuery({
                userType: company,
                isVerified: true,
            });

            find();
        });
    };

    handleRejectedQuery = () => {
        const { setQuery, find } = this.props;

        this.setState({ activeTab: 2 }, () => {
            setQuery({
                userType: company,
                isVerified: false,
                rejectionReason: { $exists: true },
            });

            find();
        });
    };

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readCompanies }) => readCompanies))
                    this.props.find()
            }
        }
    }

    render() {

        const { userInfo: { permission } } = this.props;

        return (
            <Grid
                {...this.props}
                {...this.state}
                handlePendingQuery={this.handlePendingQuery}
                handleValidatedQuery={this.handleValidatedQuery}
                handleRejectedQuery={this.handleRejectedQuery}
            />
        );
    }
}

const mstp = (state) => ({
    ...state.clients,
    userInfo: state.login.userInfo,
})

const mdtp = { find, destroy, change, setQuery: setClientsQuery };

export default connect(mstp, mdtp)(CompanyGridContainer);
