import React from "react";
import Form from "./additionalChargeForm";

import LoadMask from "../../Utils/LoadMask";

const Create = ({ create, loading, userInfo: { permission } }) => (
    <LoadMask loading={loading} dark blur>
        <div className="row">
            {permission && permission.find(({createAditionalCharges}) => !!createAditionalCharges) && (
                <div>
                    <Form
                        onSubmit={create}
                        initialValues={{
                            type: "Standard",
                        }}
                    />
                </div>
            )}
        </div>
    </LoadMask>
);

export default Create;
