import React from "react";

const IconFileDollar = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            className={className}
        >
            <path
                fill="currentColor"
                d="M220-80q-24 0-42-18t-18-42v-680q0-24 18-42t42-18h361l219 219v521q0 24-18 42t-42 18H220Zm230-121h60v-40h60q13 0 21.5-8.5T600-271v-130q0-13-8.5-21.5T570-431H420v-70h180v-60h-90v-40h-60v40h-60q-13 0-21.5 8.5T360-531v130q0 13 8.5 21.5T390-371h150v70H360v60h90v40Zm106-460h159L556-820v159Z"
            />
        </svg>
    );
};

export default IconFileDollar;
