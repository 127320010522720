import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { actions } from '../../../redux/modules/legacy-trip-edition';

import Search from "./searchTrip";

class TripEditionContainer extends React.Component {

    handleSubmit = (form) => {
        this.props.find(form)
    }

    submitEdit = (values) => {
        this.props.editReservation(values);
    }

    componentWillUnmount(){
        this.props.clearData();
    }

    componentDidMount(){
        const { id } = this.props.match.params;
        this.handleSubmit(id)
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readReservations }) => readReservations))
                    this.handleSubmit(this.props.match.params.id);
            }
        }
    }

    render() {

        const { userInfo: { permission } } = this.props;

        return ( permission && permission.find(({ readReservations }) => !!readReservations) ?
            <Search
                {...this.state}
                {...this.props}
                onSubmit={this.handleSubmit}
                submitEdit={this.submitEdit}
            /> : null
        );
    }
}

const mstp = (state) => ({
    userInfo: state.login.userInfo,
    ...state.legacyTripEdition
})

const mdtp = { ...actions };

export default connect(mstp, mdtp)(TripEditionContainer);
