import { push } from "react-router-redux";
import { createAction, handleActions } from "redux-actions";
import app, { STORAGE_KEY } from "../../libs/apiClient";
import swal from 'sweetalert2';
import { errorHandler } from '../../common/utility/constants';

const INITIAL = "INITIAL";
const SUBMIT = "SUBMIT";
const NAME_ERROR = "NAME_ERROR";
const PASS_ERROR = "PASS_ERROR";
const LOGIN_LOADER = "LOGIN_LOADER";
const SUBMIT_ERROR = "SUBMIT_ERROR";
const LOGIN = "LOGIN_LOGIN";
const SET_USER_INFO = "SET_USER_INFO";

export const constants = {
    SUBMIT,
};

// ------------------------------------
// Pure Actions
// ------------------------------------
export const _login = () => ({
    type: LOGIN,
    loggedIn: true,
});

export const _logout = () => ({
    type: LOGIN,
    loggedIn: false,
});

// ------------------------------------
// Actions
// ------------------------------------

export const onSubmit = () => (dispatch, getStore) => {
    const store = getStore();
    const { values } = store.form.login;

    dispatch({ type: LOGIN_LOADER, loader: true });
    dispatch({ type: SUBMIT_ERROR, submitError: "" });

    app.authenticate({
        strategy: "local",
        ...values,
    })
        .then(async (data) => {
            if(data.user.permission && typeof data.user.permission === 'string')
                data.user = await app.service('/user').patch(data.user._id, { permission: [] });
            dispatch(_login());
            dispatch(setUserInfo(data.user));
        })
        .catch((e) => {
            const text = e.message ? e.message : "Login error";
            dispatch({ type: SUBMIT_ERROR, submitError: text });
        })
        .finally(() => {
            dispatch({ type: LOGIN_LOADER, loader: false });
        });
};

export const logOut = () => (dispatch) => {
    localStorage.removeItem("token");
    app.logout();
    dispatch(_logout());
};

export const sendEmailRecover = (data) => async(dispatch) => {
    dispatch({ type: LOGIN_LOADER, loader: true });
    try {
        const response = await app.service('authManagement').create({
            value: {email: data.email, isAdmin: "true"},
            action: 'sendResetPwd'
        });
        if(response)
            swal.fire({
                title: '',
                text: 'Check your email to reset your password',
                type: 'success',
            });
    } catch ( err ){
        swal.fire(
            err.message
                || 'There was an error, please try again later',
            '',
            'error'
        );
    } finally {
        dispatch({ type: LOGIN_LOADER, loader: false });
    }
}

export const changePassword = (token, data) => async dispatch => {
    if (token.indexOf('___') === -1) {
        swal.fire({
            type: "error",
            text: "Token is not in the correct format."
        });
    } else {
        dispatch({ type: LOGIN_LOADER, loader: true });
        const _id = token.slice(0, token.indexOf('___'));
        try {
            const response = await app.service('/user').patch(_id,{
                token,
                "password": data.password,
                "action": 'resetPwdLong'
            })
            if(response){
                swal.fire({
                    type: "success",
                    text: "Your password has been changed"
                }).then( value => {
                    dispatch(push('/login'));
                })
            }
        } catch (error) {
            swal.fire({
                type: "error",
                text: error.message || "There was an error, please try again later"
            })
        } finally {
            dispatch({ type: LOGIN_LOADER, loader: false });
        }
    }
}

export const initialLoad = createAction(INITIAL);
export const hasNameError = (nameError) => ({
    type: NAME_ERROR,
    nameError,
});
export const hasPassError = (passError) => ({
    type: PASS_ERROR,
    passError,
});
export const setUserInfo = (userInfo) => ({
    type: SET_USER_INFO,
    userInfo,
});

const setInfoUser = dispatch => (userInfo) => {
    dispatch(setUserInfo(userInfo))
}

export const actions = {
    initialLoad,
    hasNameError,
    hasPassError,
    onSubmit,
    logOut,
    setInfoUser,
    sendEmailRecover,
    changePassword,
};

export const reducers = {
    [INITIAL]: (state) => {
        const token = localStorage.getItem("token");
        if (token) {
            return {
                ...state,
                redirect: true,
            };
        }
        return {
            ...state,
            redirect: false,
        };
    },
    [SUBMIT]: (state, { autenticado }) => {
        return {
            ...state,
            autenticado,
        };
    },
    [NAME_ERROR]: (state, { nameError }) => {
        return {
            ...state,
            nameError,
        };
    },
    [PASS_ERROR]: (state, { passError }) => {
        return {
            ...state,
            passError,
        };
    },
    [LOGIN_LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [SUBMIT_ERROR]: (state, { submitError }) => {
        return {
            ...state,
            submitError,
        };
    },
    [LOGIN]: (state, { loggedIn }) => ({ ...state, loggedIn }),
    [SET_USER_INFO]: (state, { userInfo }) => ({ ...state, userInfo }),
};

export const initialState = {
    submitError: "",
    passError: false,
    nameError: false,
    autenticado: false,
    loader: false,
    loggedIn: false,
    userInfo: {},
};

export default handleActions(reducers, initialState);
