import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, formValues, reduxForm } from 'redux-form';
import app from '../../../../../libs/apiClient';
import { renderField, renderSearchSelect, renderCurrency } from '../../../Utils/renderField';
import { renderDatePicker, renderFieldCheck, renderNumber, renderImagePicker, renderPlacesAutocomplete } from '../../../Utils/renderField/renderField';
import { Tooltip } from 'reactstrap';

const vehicleService = app.service('/api/vehicle');


class Form extends React.Component{

    state = {
      tooltipOpen: false
    };

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    getVehicles = (search) => {
        const query = {};
        if (search) {
            query.query = { type: search };
        }
        return vehicleService.find(query).then((data) => {
            const vehicles = [];
            data.data.forEach((item) => {
                if (!_.find(vehicles, { _id: item._id })) {
                    item.type = `${item.type} ${item.class}`;
                    vehicles.push(item);
                }
            });
            return { options: vehicles };
        }).catch((e) => {
            console.log(e);
        });
    };

    componentWillUnmount(){
        this.props.setUpdateData()
    }

    render(){
        const { handleSubmit, params, cancel, updateData, setDetails, formValues } = this.props;

        return(
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-wrap w-100">
                    <div className="col-12 form-group">
                        <h4> FROM </h4>
                        <Field
                            name="fromName"
                            type="text"
                            component={renderField}
                            placeholder="Name"
                        />
                    </div>
                    <div className="col-12 form-group">
                        <Field
                            id="fromAutocomplete"
                            name="from"
                            component={renderPlacesAutocomplete}
                            placeId={updateData ? updateData.from && updateData.from.place_id ? updateData.from.place_id : updateData.from.address : undefined}
                            additionalAddressInfo={['administrative_area_level_2', 'street_number', 'street_address', 'route']}
                            setDetails={(data)=>setDetails(data, 'from')}
                            pl="from"
                            placeholder="Address"
                            className="form-control"
                            getPlaceId
                        />
                    </div>
                    <div className="col-12 form-group">
                        <h4> TO </h4>
                        <Field
                            name="toName"
                            type="text"
                            component={renderField}
                            placeholder="Name"
                        />
                    </div>
                    <div className="col-12 form-group">
                        <Field
                            id="toAutocomplete"
                            name="to"
                            component={renderPlacesAutocomplete}
                            placeId={updateData ? updateData.to && updateData.to.place_id ? updateData.to.place_id : updateData.to.address : undefined}
                            additionalAddressInfo={['administrative_area_level_2', 'street_number', 'street_address', 'route']}
                            setDetails={(data)=>setDetails(data, 'to')}
                            pl="to"
                            placeholder="Address"
                            className="form-control"
                            getPlaceId
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <Field
                            name="picture"
                            component={renderImagePicker}
                            photo={updateData && updateData.photo}
                            className="mx-2"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="form-group">
                            <Field
                                name="vehicle"
                                valueKey="_id"
                                labelKey="type"
                                component={renderSearchSelect}
                                loadOptions={this.getVehicles}
                                placeholder="vehicle"
                                className="mb-2"
                            />
                        </div>
                        <div className="form-group">
                            <Field
                                name="percentOff"
                                component={renderNumber}
                                decimalScale={2}
                                suffix="%"
                                placeholder="Percent off"
                                className="mb-2"
                            />
                        </div>
                        <div className="form-group d-flex">
                            <div id="tipBothWays">
                                <Field
                                    name="bothWays"
                                    component={renderFieldCheck}
                                    label="Roundtrip promo?"
                                    className="form-control"
                                />
                            </div>
                            <Tooltip placement="left" isOpen={this.state.tooltipOpen} target="tipBothWays" toggle={this.toggle}>
                                Check this box if the promotion applies to/from either location.
                            </Tooltip>
                        </div>
                        <div className="form-group">
                            <label> <strong> Distance: </strong> {formValues && formValues.distance} </label>
                        </div>
                        <div className="form-group">
                            <label> <strong> Duration: </strong> {formValues && formValues.duration} </label>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Starting date:</label>
                        <Field
                            id="startDate"
                            name="startDate"
                            component={renderDatePicker}
                            isOutsideRange={false}
                            className="flex-1 w-100 p-0"
                            numberOfMonths={1}
                        />
                    </div>
                    <div className="col-12 col-md-6 form-group">
                        <label> Expires on:</label>
                        <Field
                            id="endDate"
                            name="endDate"
                            component={renderDatePicker}
                            isOutsideRange={false}
                            className="flex-1 w-100 p-0"
                            numberOfMonths={1}
                        />
                    </div>
                    <div className="col-12 d-flex flex-column flex-md-row align-items-end justify-content-end">
                        <button type="button" className="btn btn-secondary m-1"
                            onClick={cancel}
                        >
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary m-1">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        )
    }
}

const PointToPointForm = reduxForm({
    form: "pointToPointForm",
    validate: (data, props) => {
        const errors = {}
        if(!data.from) errors.from = 'Required Field';
        if(!data.fromName) errors.fromName = 'Required Field';
        if(!data.to) errors.to = 'Required Field';
        if(!data.to) errors.toName = 'Required Field';
        if(!data.picture && !(props.updateData && props.updateData.photo)) errors.picture = 'Required Field';
        if(!data.vehicle) errors.vehicle = 'Required Field';
        if(!data.percentOff) errors.percentOff = 'Required Field';
        else if(data.percentOff > 100 || data.percentOff < 0)
            errors.percentOff = "Invalid Percent off";
        if(!data.startDate) errors.startDate = 'Required Field';
        if(!data.endDate) errors.endDate = 'Required Field';
        return errors;
    }
})(Form);

export default PointToPointForm;
