import React from "react";
import Form from "./Form";
import LoadMask from '../../Utils/LoadMask';


export default class Create extends React.Component {

    render() {

        const { create } = this.props;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-titulo padding-15">
                        <h2>
                            <strong>New Shuttle Tracker</strong>
                        </h2>
                    </div>
                    <LoadMask loading = { this.props.loading } dark blur>
                        <Form
                            onSubmit={create}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}
