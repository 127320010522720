import React from "react";
import PropTypes from "prop-types";
import { ClientUpdateForm } from "../Create/ClientForm";

import { clientTypes } from "../../../utility/constants";

const [person] = clientTypes;

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.setClientsQuery({ userType: person });

        this.props.load2Update(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.setClientsQuery({});
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateClients }) => updateClients)){
                    this.props.setClientsQuery({ userType: person });
                    this.props.load2Update(this.props.match.params.id);
                }
            }
        }
    }

    render() {
        const { update, userInfo: { permission } } = this.props;

        return (
            <div className="row d-flex justify-content-center">
                {permission && permission.find(({ updateClients }) => !!updateClients) && (
                    <ClientUpdateForm onSubmit={update} />
                )}
            </div>
        );
    }
}
