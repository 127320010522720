import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { renderCurrency, renderField, renderSearchSelect } from '../../Utils/renderField';
import { Link } from 'react-router-dom';
import app from "../../../../libs/apiClient";
const vehicleService = app.service("/api/vehicle/");
import _ from "lodash";
import LoadMask from '../../Utils/LoadMask';
import { getPathWithParams, settingsRatesLocationsId } from '../../../../routesConfiguration/paths';


const getVehicles = (search) => {
    const query = {};
    if (search) {
        query.query = { type: search };
    }
    return vehicleService.find(query).then((data) => {
        const vehicles = [];
        data.data.forEach((item) => {
            if (!_.find(vehicles, { _id: item._id })) {
                item.type = `${item.type} ${item.class}`;
                vehicles.push(item);
            }
        });
        return { options: vehicles };
    }).catch((e) => {
        console.log(e);
    });
};

class ItemArrayVehicles extends React.Component{

    state = {
        validVehicle: false
    }

    componentDidMount(){
        const { fields, index } = this.props;
        if(fields.get(index).vehicle){
            this.setState({ validVehicle: true });
        }
    }

    render(){

        const { index, vehicle, getVehicles, removeBaseRate, openEditVehicle, fields, dataVehicle } = this.props;
        const { validVehicle } = this.state;

        return (
            <tr key={index}>
                <td>
                    <Field
                        name={`${vehicle}.vehicle`}
                        valueKey="_id"
                        labelKey="type"
                        component={renderSearchSelect}
                        label="Vehicle"
                        loadOptions={getVehicles}
                        myChange={(e)=> e
                            ? !validVehicle && this.setState({ validVehicle: true })
                            : this.setState({ validVehicle: false })
                        }
                    />
                </td>
                <td>
                    <Field
                        name={`${vehicle}.rate`}
                        type="number"
                        component={renderField}
                        placeholder="Rate"
                    />
                </td>
                <td className="text-center">
                    <button
                        type="button"
                        className="btn btn-secondary m-1"
                        onClick={() => fields.remove(index)}
                        style={{ width: "32px", height: "32px" }}
                    >
                        X
                    </button>
                    {validVehicle && (
                        <button type = "button"
                            className="btn btn-primary p-0 m-1"
                            onClick={() => openEditVehicle(dataVehicle, index)}
                            style={{ width: "32px", height: "32px" }}
                        >
                            <img
                                className=""
                                title="Edit"
                                src={require("../../../../../assets/img/icons/edit.png")}
                                alt="Edit"
                                style={{
                                    minWidth: "28px",
                                    maxWidth: "28px",
                                    margin: "0 0.3rem !important",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}
                            />
                        </button>
                    )}
                </td>
            </tr>
        )
    }
}

class Form extends React.Component{

    renderVehicles({
        fields,
        meta: { error, submitFailed },
        vehicles,
        openEditVehicle,
        removeBaseRate
    }){
        return (
        <div className="row">
            <div className="col-sm-12">
                <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                    <h3 className="ml-0 ml-sm-auto">
                        <strong>Vehicles</strong>
                    </h3>
                    <button
                        type="button"
                        className="btn btn-secondary ml-0 ml-sm-auto flex-nowrap flex-sm-wrap"
                        onClick={() => fields.push({})}
                    >
                        Add Vehicle
                    </button>
                </div>
                <br />
                <table className="table table-sm table-hover table-striped">
                    <tbody>
                        <tr>
                            <th style={{ width: "40%" }}>Vehicle</th>
                            <th style={{ width: "40%" }}>Rate</th>
                            <th style={{ width: "20%" }} />
                        </tr>
                        {fields.map((vehicle, index) => (
                            <ItemArrayVehicles
                                key={index}
                                vehicle={vehicle}
                                dataVehicle={fields.get(index)}
                                index={index}
                                getVehicles = {getVehicles}
                                removeBaseRate={removeBaseRate}
                                openEditVehicle ={openEditVehicle}
                                fields={fields}
                            />
                        ))}
                    </tbody>
                </table>
                {submitFailed && error &&
                    <div className="invalid-feedback-array text-danger">
                        {error}
                    </div>
                }
            </div>
        </div>
        )
    }

    render(){

        const { handleSubmit, vehicles, openEditVehicle, loading, removeBaseRate, params } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <LoadMask loading={loading ? true : false} blur>
                    <hr/>
                    <div className="col-12 col-md-6 col-xl-4 px-0 mb-4 form-group">
                        <label htmlFor="fuelCost">Fuel Cost (per gallon)</label>
                        <Field
                            name="fuelCost"
                            component={renderCurrency}
                            allowNegative={false}
                            label="$ 2.22"
                        />
                    </div>

                    <FieldArray
                        name="vehicleBaseRates"
                        component={this.renderVehicles}
                        vehicles={vehicles}
                        openEditVehicle={openEditVehicle}
                        removeBaseRate={removeBaseRate}
                    />
                    <div className="d-flex flex-column flex-md-row mt-3">
                        <Link to={getPathWithParams(settingsRatesLocationsId, "id", params.id)} className="btn btn-secondary m-1">
                            Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary m-1">
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        )
    }
}


const VehiclesLocationsForm = reduxForm({
    form: 'vehicleLocationsForm',
    validate: (data)=>{
        const errors = {}
        // if(!data.fuelCost) errors.fuelCost = "Required Field";
        return errors;
    }
})(Form)

export default VehiclesLocationsForm;
