import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";


const LOADING = "LOADING_CREW_ADMIN";
const LOADING_FORM = "LOADING_UPDATE_FORM";
const SET_AIRLINE_DATA = "SET_AIRLINE_DATA";
const SET_PAGE_AIRLINE = "SET_PAGE_AIRLINE";
const SET_SINGLE_DATA_AIRLINE = "SET_SINGLE_DATA_AIRLINE";
const FILTERS_REPORT = "FILTERS_REPORT_CHART";
const LOADING_CHART_REPORT = "LOADING_CHART_REPORT";
const DATA_CHART_REPORT = "DATA_CHART_REPORT";
const SET_SEARCH_AIRLINE = "SET_SEARCH_AIRLINE";

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $limit: 5,
            $skip: (page - 1) * 5
        }};
        const search = getStore().crewAdmin.airlines.search;
        if(search){
            params.query.$or = [
                { company: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { code: { $regex: _.escapeRegExp(search), $options: 'i' }},
            ]

            if(!isNaN(parseInt(search))){
                params.query.$or.push({ liveryId: parseInt(search) });
            }
        }

        const data = await app.service('/api/airline').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch) => {
    dispatch(setLoadingForm(true));
    try {
        await app.service('/api/airline').create(data);
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let params = { query: { } };
        let data = await app.service('/api/airline').get(id, params);
        dispatch(initializeForm('airlineForm', data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (data) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    try{
        await app.service('/api/airline').update(data._id, data);
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.airlines;
    try {
        await app.service('/api/airline').remove(id);
        dispatch(find(page))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const reportChart = (airline) => (dispatch, getStore) => {
    dispatch({ type: LOADING_CHART_REPORT, loading: true });
    const { uploadsFilter, csrFilter } = getStore().crewAdmin.scheduleReport;
    app.service('/api/schedule').find({ query: { $sheduleChartReport: true, uploadsFilter, csrFilter, airline } }).then(data => {
        const result = [["Month", "Uploads", "CSR"]];
        if(data){
            for(let i = 0; i < data.months.length; i++)
                result.push([data.months[i].monthName, data.months[i].uploads, data.months[i].csr]);
        }
        dispatch({ type: DATA_CHART_REPORT, data: { airports: data.airports, sumaryTrips: result, general: data.general} });
    }).catch(error=>{
        dispatch(errorHandler(error));
    }).finally(()=>{
        dispatch({ type: LOADING_CHART_REPORT, loading: false });
    })
}

export const setSearch = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH_AIRLINE, search });
    dispatch(find());
}

export const setFilters = (uploadsFilter, csrFilter, airline) => (dispatch) => {
    dispatch({ type: FILTERS_REPORT, uploadsFilter, csrFilter });
    dispatch(reportChart(airline));
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE_AIRLINE, page });
export const setData = (data) => ({ type: SET_AIRLINE_DATA, data });

export const actions = {
    find,
    create,
    setSearch,
    load2Update,
    setFilters,
    reportChart,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_AIRLINE]: (state, { page }) => ({ ...state, page }),
    [SET_AIRLINE_DATA]: (state, { data }) => ({ ...state, data }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH_AIRLINE]: (state, { search }) => ({...state, search }),
    [DATA_CHART_REPORT]: (state, { data }) => ({ ...state, dataChartReport: data }),
    [LOADING_CHART_REPORT]: (state, { loading }) => ({ ...state, loadingChart: loading }),
    [FILTERS_REPORT]: (state, { uploadsFilter, csrFilter }) => ({ ...state, uploadsFilter, csrFilter })
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    dataChartReport: undefined,
    loadingChart: false,
    uploadsFilter: true,
    csrFilter: true,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    search: undefined,
};

export default handleActions(reducers, initialState);
