import React from "react";
import PropTypes from "prop-types";

import Form from "../Create/TourForm";
import LoadMask from "../../Utils/LoadMask";

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { load2Update, match } = this.props;
        load2Update(match.params.id);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateVehicles }) => updateVehicles))
                    this.props.load2Update(this.props.match.params.id);
            }
        }
    }

    render() {
        const { update, photo, hours, loading, userInfo: { permission } } = this.props;

        return (
            <LoadMask loading={loading} dark blur>
                {permission && permission.find(({ updateTours }) => !!updateTours) && (
                    <Form onSubmit={update} photo={photo} hours={hours} />
                )}
            </LoadMask>
        );
    }
}
