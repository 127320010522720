import React from "react";
import moment from "moment";
import numeral from "numeral";
import { connect } from "react-redux";
import { Field, reduxForm, FieldArray, formValueSelector, change } from "redux-form";
import {
    renderField,
    renderTextArea,
    renderDatePicker,
    renderSelectField,
    renderSearchSelect,
    renderPlacesAutocomplete,
    renderCurrency,
    renderFieldCheck,
} from "../Utils/renderField/renderField";
import AutoSuggest from "../Utils/autosuggest";
import airlineCodes from '../../utility/airlines.json';
const reloadSvg = require("../../../../assets/img/icons/redo.svg");

import renderTimePicker from "../../../TimePicker";
import { actions } from "../../../redux/modules/legacy-trip-edition";
import { hourlyOptions, countryOptions } from '../../utility/constants';

const formatTime = (time) => {
    if (time == 1) return  time + 'Hour';
    else return time + 'Hours'
}

const renderCharges = ({ fields, chargesList, setCharge }) => (
    <div className="row">
        {fields.map((item, i) => (
            <React.Fragment key={`holder${i}`}>
                <div className="col-6 col-md-5 form-group">
                    <Field
                        name={`${item}.aditionalId`}
                        valueKey="_id"
                        labelKey="title"
                        component={renderSearchSelect}
                        myChange={e => setCharge(e,null,i)}
                        label="Charge Type"
                        loadOptions={chargesList}
                        placeholder="Charges Type"
                    />
                </div>
                <div className="col-6 col-md-3 form-group">
                    <Field
                        name={`${item}.Qcharge`}
                        component={renderField}
                        myChange={e=>{setCharge(null,e.target.value,i)}}
                        placeholder="Qty."
                        type="number"
                        className="form-control"
                    />
                </div>
                <div className="col-6 col-md-4 form-group">
                    <Field
                        name={`${item}.amount`}
                        component={renderCurrency}
                        placeholder="Amount"
                        className="form-control"
                        readOnly
                    />
                </div>
                {/* <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) =>{e.preventDefault();  fields.remove(i)}}
                >
                    X
                </button> */}
            </React.Fragment>
        ))}
        <div className="grid-title d-flex flex-colum flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
            <button className="btn btn-link d-flex align-items-center" type="button"
                style={{ textDecoration: "None", color: "#000" }}
                onClick={(e) => {e.preventDefault();fields.push({
                    Qcharge: 1,
                })}}
            >
                <span className="btn-plus p-0 d-flex justify-content-center align-items-center">
                    +
                </span>
                <style jsx="true">{`
                    .btn-plus {
                        padding: 0;
                        font-size: 1.5rem;
                        width: 30px !important;
                        height: 30px !important;
                        font-weight: bolder;
                        background: #6C757D;
                        color: #fff;
                    }
                    @media only screen and (min-width: 576px) {
                        .btn-plus {
                            border-radius: 50% !important;
                        }
                    }
                `}</style>
                &nbsp;&nbsp;Add Charge
            </button>
        </div>
    </div>
);


let disabled = false;

let ReservationEditForm = ({
    data,
    reservation,
    setCharge,
    vehicles,
    changeVehicle,
    chargesList,
    clearData,
    resendEmail,
    changeHourly,
    loaderNewPrice,
    handleSubmit,
    to,
    from,
    change,
    no_flight_information,
    isAirport = false,
    flightsNumbers,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
    setDetails,
    history,
}) => (
    <form className="row" onSubmit={handleSubmit}>
        <div className="col-12 col-md-8 col-lg-7">
            <div className="row">
                <div className="col-12">
                    <h4> Trip Information </h4>
                    <br/>
                    {moment(data.dateCompare).isAfter(moment().format())
                        ? disabled = false : disabled = true}
                </div>
                <div className="col-12 col-md-3 pr-0 form-group">
                    <Field
                        name="puDate"
                        numberOfMonths={1}
                        component={renderDatePicker}
                        className="flex-1 w-100 my-2 p-0"
                        disabled={disabled}
                        cb={(value) => {
                            change('flightAirline', null);
                            change('flightNumber', null);
                        }}
                    />
                </div>
                <div className="col-12 col-md-3 py-2 form-group">
                    <Field
                        id="timepicker1"
                        placeholder="Pick Time"
                        Value={moment.parseZone(reservation.puTime)}
                        component={renderTimePicker}
                        name="puTime"
                        style={{ fontSize: "1rem", height: "calc(2.25rem + 4px)" }}
                        disabled={ disabled }
                    />
                </div>
                <div className="col-12 col-md-6 d-flex align-items-center">
                    {/* {!disabled
                    ? <div onClick={resendEmail} style={{ cursor: "pointer" }}>
                        <img
                            className="action-img"
                            title="Resend"
                            src={reloadSvg}
                            alt="resend-email"
                        />
                        <strong>&nbsp;Resend Confirmation </strong>
                    </div>
                    :''} */}
                </div>
                <div className="col-12 col-md-6 form-group">
                    <Field
                        id="fromAutocomplete3"
                        name="puAddress"
                        Value={data.puAddress}
                        component={renderPlacesAutocomplete}
                        setDetails={setDetails}
                        lat={from.lat}
                        lng={from.lng}
                        inputLabel={from.label}
                        type="text"
                        pl="from"
                        label="Traveling from"
                        placeholder="From: address, airport, hotel..."
                        disabled={disabled}
                        additionalAddressInfo={['street_number', 'route']}
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <Field
                        id="toAutocomplete3"
                        name="doAddress"
                        Value={data.doAddress}
                        component={renderPlacesAutocomplete}
                        setDetails={setDetails}
                        lat={to.lat}
                        lng={to.lng}
                        inputLabel={to.label}
                        pl="to"
                        label="Traveling to"
                        placeholder="To: address, airport, hotel..."
                        disabled={disabled}
                        additionalAddressInfo={['street_number', 'route']}
                    />
                </div>
                {isAirport && (
                    <React.Fragment>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="flightAirline"
                                component={AutoSuggest}
                                keyLabel='label'
                                keyId='_id'
                                searchKey="company"
                                placeholder="MC"
                                suggestions={airlineCodes}
                                disabled={no_flight_information && no_flight_information}
                                onSelectOption={value=>onSelectAirline(value)}
                            />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="flightNumber"
                                component={AutoSuggest}
                                showNotFoundMsg
                                notFoundMsg="Flight info not found"
                                keyLabel='label'
                                keyId='index'
                                searchKey="flightNumber"
                                placeholder="234"
                                onChange={ (event, value) => getFlightInfo(value) }
                                onSelectOption={value => onSelectFlight(value)}
                                disabled={no_flight_information && no_flight_information}
                                suggestions={flightsNumbers}
                                typeInput="text"
                                className="flex-1"
                                async
                            />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <div className="d-flex">
                                <Field
                                    name="no_flight_information"
                                    label="No flight information"
                                    component={renderFieldCheck}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 form-group"></div>
                    </React.Fragment>
                )}

                <div className="col-12 col-md-6 form-group">
                    <Field
                        name="vehicleId"
                        valueKey="_id"
                        labelKey="label"
                        component={renderSelectField}
                        label="Vehicle"
                        clearable={false}
                        options={vehicles ? vehicles : []}
                        placeholder="Vehicle type"
                        disabled= {disabled}
                        myChange={changeVehicle}
                    />
                </div>
                {!!reservation && reservation.hourlyDuration && (
                    <div className="col-12 col-md-6 form-group">
                        <Field
                            name="hourlyDuration"
                            component={renderSelectField}
                            clearable={false}
                            options={hourlyOptions}
                            myChange={changeHourly}
                            placeholder="Duration"
                            className="w-100"
                            disabled= {disabled }
                            />
                    </div>
                )}

                <div className="col-12 mt-1 mt-md-4">
                    <h4> Passenger Information </h4>
                    <br/>
                </div>
                <div className="col-12 col-md-6 form-group">
                    <Field
                        name="guestFirstNm"
                        component={renderField}
                        placeholder="First Name"
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <Field
                        name="guestLastNm"
                        component={renderField}
                        placeholder="Last Name"
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <Field
                        name="guestEmail"
                        component={renderField}
                        placeholder="Email"
                        type="email"
                        className="form-control"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <Field
                        name="country"
                        component={renderSelectField}
                        placeholder="Country"
                        options={countryOptions}
                        type="text"
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <Field
                        name="guestMobile"
                        component={renderField}
                        placeholder="Phone Number"
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="col-12 form-group">
                    <Field
                        name="specialNotes"
                        component={renderTextArea}
                        label="Special Notes"
                        type="text"
                        //className="form-control"
                    />
                </div>

                <div className="col-12 mt-1 mt-md-4 form-group">
                    <h4> Charges </h4>
                    <br/>
                    <FieldArray
                        name="additionalCharges"
                        component={renderCharges}
                        chargesList={chargesList}
                        setCharge={setCharge}
                    />
                </div>

                <div className="col-12 d-flex justify-content-end">
                    <div className="flex-column">
                        <button type="submit" className="btn btn-primary w-100">
                            Save
                        </button>
                        <br style={{ borderTop: "None", width: "100%", marginTop: "0.1rem" }}/>
                        <button type="button" className="btn btn-link w-100"
                            onClick={() => {
                                clearData();
                                history.goBack();
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div className="col-12 col-md-4 offset-lg-1">
            <div className="row p-3" style={{ border: "1px solid #000" }}>
                <div className="col-6 py-2">
                    <strong> Date: </strong> {!!reservation && reservation.puDate && moment.parseZone(reservation.puDate).format('L')}
                </div>
                <div className="col-6 py-2">
                    <strong> Time: </strong> {!!reservation && reservation.puTime && moment.parseZone(reservation.puTime).format('HH:mm')}
                </div>
                <div className="col-12 py-2">
                    <strong> Pick Up: </strong>
                    {!!reservation && reservation.puAddress && reservation.puAddress}
                </div>
                <div className="col-12 py-2">
                    <strong> Drop Off: </strong>
                    {!!reservation && reservation.puAddress && reservation.doAddress}
                </div>
                <br/>
                <div className="col-12 py-2">
                    <strong> Passenger: </strong>
                    {!!reservation && reservation.guestFirstNm && `${reservation.guestFirstNm} ${reservation.guestLastNm && reservation.guestLastNm}`}
                </div>
                <div className="col-12 py-2">
                    <strong> Email: </strong>
                    {!!reservation && reservation.guestEmail && reservation.guestEmail}
                </div>
                <div className="col-12 py-2">
                    <strong> Phone: </strong>
                    {!!reservation && reservation.guestMobile && reservation.guestMobile}
                </div>
                <br/>
                <div className="col-12 py-2">
                    <strong> Old total: </strong>
                    {!!data && data.oldTotal &&
                    `$ ${numeral(data.oldTotal).format("0,0.00")}`}
                </div>
                <div className="col-12 py-2">
                    <strong> New Total: </strong>
                    {loaderNewPrice
                        ? <span className="ml-4">
                            <div className="spinner-border" style={{ width: "1.2rem", height: "1.2rem" }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                          </span>
                        : !!reservation && !!data && data.oldTotal != reservation.newTotal &&
                            `$ ${numeral(reservation.newTotal ? reservation.newTotal : 0).format("0,0.00")}`
                    }
                </div>
            </div>
        </div>
    </form>
);

const validateAirilineOrFlight = (value, no_flight_information = false, isFlightNumber = false) =>{
    if(no_flight_information)
        return true
    else
        if(!value) return false;
        else if(isFlightNumber)
            return typeof value === 'object' ? true : false;
        else return true;
}

ReservationEditForm = reduxForm({
    form: "tripEditForm",
    validate: (data, { typeAddress, from, to }) => {
        console.log(from, to);
        const errors = {}
        if(!data.puTime) errors.puTime = "Required field";
        if(!data.puDate) errors.puDate = "Required field";
        if(!data.puAddress) errors.puAddress = "Required field";
        else if(!from.postal_code) errors.puAddress = "Invalid address";
        if(!data.doAddress) errors.doAddress = "Required field";
        else if(!to.postal_code) errors.doAddress = "Invalid address";
        if(!data.guestFirstNm) errors.guestFirstNm = "Required field";
        if(!data.guestLastNm) errors.guestLastNm = "Required field";
        if(!data.guestEmail) errors.guestEmail = "Required field";
        if(!data.guestMobile) errors.guestMobile = "Required field";
        if(!data.country) errors.country = "Required field";
        errors.flightAirline = !validateAirilineOrFlight(data.flightAirline, data.no_flight_information) ? 'Required Field' : undefined;
        errors.flightNumber = !validateAirilineOrFlight(data.flightNumber, data.no_flight_information,true) ? 'Cannot verify flight' : undefined;
        if(data.additionalCharges){
            let arrayErrors = []
            data.additionalCharges.map((item,index) => {
                let errorItem = {}
                let repeat = [];
                if(!item.aditionalId){
                    errorItem.aditionalId = "Required Field";
                } else {
                    repeat = _.filter(data.additionalCharges, (x) => {
                        if(x.aditionalId) return x.aditionalId == item.aditionalId;
                        return false;
                    });
                    if(1 < repeat.length){
                        errorItem.aditionalId = "This charge is repeated";
                    }
                }
                if(!item.Qcharge == undefined){
                    errorItem.Qcharge = "Required Field";
                } else if(item.Qcharge < 0){
                    errorItem.Qcharge = "The Quantity shouldn't be less than zero";
                }
                if(!item.amount) errorItem.amount = "Required field";
                arrayErrors[index] = errorItem;
            })
            if(arrayErrors.length){
                errors.additionalCharges = arrayErrors;
            }
        }
        if(typeAddress == 'from' || typeAddress == 'both'){
            errors.puAddress = "Please select an exact address";
        }
        if(typeAddress == 'to' || typeAddress == 'both'){
            errors.doAddress = "Please select an exact address";
        }
        return errors;
    },
})(ReservationEditForm);

const mstp = (state) => ({
    ...state.legacyTripEdition
});

const selector = formValueSelector('tripEditForm');
ReservationEditForm = connect(state => {
    const reservation = selector(state, 'puAddress','doAddress','puDate', 'puTime', 'puTimeNoTimezone',
    'hourlyDuration','flightDetails','specialNotes','guestFirstNm','guestLastNm','guestMobile',
    'guestEmail','transactionAmount','totalRefund','totalCharges', 'newTransactionAmount',
    'additionalCharges', 'no_flight_information');

    let newTotalCharges = 0;
    if(reservation.additionalCharges)
        newTotalCharges = reservation.additionalCharges.reduce( (total, current) =>
            total + (current.amount ? parseFloat(current.amount) : 0), 0 );
    reservation.newTotal = (parseFloat(reservation.newTransactionAmount) + newTotalCharges).toFixed(2);

    return { reservation, no_flight_information: reservation.no_flight_information, from: state.legacyTripEdition.from, to: state.legacyTripEdition.to }
})(ReservationEditForm);

export default connect(mstp, { ...actions, change })(ReservationEditForm);
