import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Update from "./GroupRateUpdate";
import { actions, filterZipCodes, filterExcludeZipCodes } from "../../../../redux/modules/locations";

const selector = formValueSelector("location");

const mstp = (state) => ({
    ...state.locations,
    zipCodes: selector(state, "zipCodes"),
    excludeZipCodes: selector(state, "excludeZipCodes"),
    userInfo: state.login.userInfo,
});
const mdtp = { ...actions, filterZipCodes, filterExcludeZipCodes };

export default connect(mstp, mdtp)(Update);
