import React from 'react';
import { connect } from 'react-redux';
import { actions, setModalCompanySelect } from '../../../../redux/modules/liveryEdition';
import CreateForm from './createForm';
import LoadMask from '../../Utils/LoadMask';
import ModalCompanySelect from '../modalCompanySelect';


class Container extends React.Component {
    componentDidMount() {
        if(!localStorage.getItem('company_airline_livery_create')){
            this.props.setModalCompanySelect(true);
        }
    }

    onSubmit = (values) => {
        this.props.createLiveryTrip(values);
    }

    componentWillUnmount() {
        this.props.clearAddresses()
    }

    render() {
        const { loading } = this.props;

        return (
            <div className="d-flex flex-wrap">
                <ModalCompanySelect />
                <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <h3 className="text-center mb-4 px-4"> NEW TRIP </h3>
                    <LoadMask loading={loading} blur>
                        <CreateForm
                            onSubmit={this.onSubmit}
                            cancel={() => this.props.history.goBack()}
                            useModalVerify
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.liveryEdition,
    formValues: state.form.createLivery && state.form.createLivery.values,
});

const mdtp = {
    createLiveryTrip: actions.createLiveryTrip,
    clearAddresses: actions.clearAddresses,
    setModalCompanySelect: setModalCompanySelect,
};

export default connect(mstp, mdtp)(Container);
