import React from 'react';
import { connect } from 'react-redux';
import Receipt from './Receipt';


class Layout extends React.Component {

    render() {
        if (!this.props.itinerary.confirmed) return null;

        return (
            <Receipt
                itinerary={this.props.itinerary}
                vehicle={this.props.vehicle}
                inModal={this.props.inModal}
            />
        );
    }
}

const ms2p = ({ itinerary, vehicle: { vehicle } }) => ({
    itinerary,
    vehicle,
});

export default connect(ms2p)(Layout);
