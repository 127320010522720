import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { reduxForm } from "redux-form";
import { activeFormatter } from "../../Utils/Acciones/Acciones";
import Grid from "../../Utils/Grid/materialTable";
import { settingsAccountsCompanies } from "../../../../routesConfiguration/paths";

const CompanyList = ({
    data,
    find,
    page,
    change,
    loading,
    activeTab,
    userInfo: { permission },
    handlePendingQuery,
    handleValidatedQuery,
    handleRejectedQuery,
}) => {
    const tableRef = useRef();

    useEffect(() => {
        if (tableRef)
            tableRef.current.dataManager.changePageSize(10);
    }, []);

    return (
        <div className="row">
            <div className="col-sm-12 pt-2">
                <div className="grid-container material-livery-list">
                    <div className="grid-title d-flex flex-column justify-content-center align-items-stretch align-items-sm-center">
                        <h1>
                            <strong>Companies</strong>
                        </h1>
                    </div>

                    {permission &&
                        permission.find(({ readCompanies }) => !!readCompanies) && (
                            <React.Fragment>
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <button
                                            type="button"
                                            onClick={handlePendingQuery}
                                            className={classNames("nav-link", {
                                                "active text-primary": activeTab === 0,
                                            })}
                                        >
                                            Pending
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            type="button"
                                            onClick={handleValidatedQuery}
                                            className={classNames("nav-link", {
                                                "active text-primary": activeTab === 1,
                                            })}
                                        >
                                            Validated
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            type="button"
                                            onClick={handleRejectedQuery}
                                            className={classNames("nav-link", {
                                                "active text-primary": activeTab === 2,
                                            })}
                                        >
                                            Rejected
                                        </button>
                                    </li>
                                </ul>

                                <Grid
                                    tableRef={tableRef}
                                    title=""
                                    columns={[
                                        {
                                            title: "Company's Name",
                                            field: "companyName",
                                        },
                                        {
                                            title: "Email",
                                            field: "email",
                                        },
                                        {
                                            title: "Actions",
                                            field: "_id",
                                            width: "15%",
                                            align: "center",
                                            render: (row) =>
                                                activeFormatter({
                                                    editar: permission.find(({ updateCompanies }) => !!updateCompanies) ? settingsAccountsCompanies : undefined,
                                                })(row._id, row),
                                        },
                                    ]}
                                    data={data.data}
                                    onSearchChange={(search) => {
                                        change("search", search);
                                        find();
                                    }}
                                    onPageChange={(p) => find(p + 1)}
                                    isLoading={loading}
                                    page={page - 1}
                                    totalCount={data.total}
                                    options={{
                                        pageSizeOptions: [],
                                        filtering: false,
                                        idSynonym: "_id",
                                        searchText: "Search by code",
                                        pageSize: 10,
                                        headerStyle: {
                                            backgroundColor: "#D50032",
                                            color: "#FFF",
                                            fontSize: "1rem",
                                        },
                                        cellStyle: {
                                            fontSize: "1rem",
                                        },
                                    }}
                                />
                            </React.Fragment>
                        )}
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "companyListFilter",
})(CompanyList);
