import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { renderField, renderSearchSelect } from '../../Utils/renderField';
import { Link } from 'react-router-dom';
import app from "../../../../libs/apiClient";
const vehicleService = app.service("/api/vehicle/");
import _ from "lodash";
import LoadMask from '../../Utils/LoadMask';
import { getPathWithParams, settingsRatesLocationsId } from '../../../../routesConfiguration/paths';
import { downloadZips } from '../../../../redux/modules/crewAdmin/zones';

class renderZipCode extends React.Component {
    constructor(props) {
        super(props);

        this.zipCodesContainer = React.createRef();
    }

    handleAddZipCode = () => {
        this.props.fields.push({});
        const lastRow = this.zipCodesContainer.current.lastChild;
        const [rowInput] = lastRow.childNodes[0].childNodes[0].childNodes;
        if(rowInput) rowInput.focus();
    };

    handleDownload = () => {
        this.props.downloadZipsFile();
    };

    render() {
        const { filterZipCodes, zipCodes = [], nameFilter, title } = this.props;
    
        return (
            <div className="row">
                <div className="col-sm-12">
                    <hr />
                    <div className="grid-title d-flex flex-column flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
                        <div className="d-flex flex-column align-items-center ml-0 ml-sm-auto">
                            <h3>
                                <strong> {title} ({zipCodes.length})</strong>
                            </h3>
                        </div>
                        <div className="d-flex flex-column flex-sm-row ml-0 ml-sm-auto">
                            <Field
                                name={nameFilter}
                                component={renderField}
                                cb={filterZipCodes}
                            />
                            <button
                                type="button"
                                onClick={this.handleAddZipCode}
                                className="btn btn-secondary flex-nowrap flex-sm-wrap ml-0 ml-sm-2 mt-2 mt-sm-0"
                            >
                                Add ZIP
                            </button>
                            <div
                            type="button"
                            onClick={this.handleDownload}
                            className="btn btn-primary flex-nowrap flex-sm-wrap ml-0 ml-sm-2 mt-2 mt-sm-0"
                            >
                            <span style={{ width: "20px" }}>
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                width="20"  
                                height="20" 
                                style={{ fill: "white" }}
                                >
                                <path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" />
                                </svg>
                            </span>
                            </div>

                        </div>
                    </div>
                    <br />
                    <table className="table table-sm table-hover table-striped">
                        <tbody ref={this.zipCodesContainer}
                            className="d-flex flex-wrap justify-content-between"
                        >
                            <tr style={{ width: "100%" }}>
                                <th style={{ width: "90%" }}>ZIP Code</th>
                                <th style={{ width: "10%" }} />
                            </tr>
                            {this.props.fields.map((zip, i) => (
                                <tr key={`${zip}._id`}
                                    style={{ width: "10rem" }}
                                >
                                    <td>
                                        <Field
                                            name={`${zip}.zip`}
                                            type="text"
                                            component={renderField}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <button type="button"
                                            className="btn btn-secondary"
                                            onClick={() => this.props.fields.remove(i) }
                                        >
                                            X
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

class Form extends React.Component{

    render(){

        const { handleSubmit, zipCodes, params, loading, filterZipCodes, filterExcludeZipCodes, excludeZipCodes, downloadZipsFile } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <LoadMask loading={loading ? true : false} blur>
                    <FieldArray
                        name="zipCodes"
                        component={renderZipCode}
                        filterZipCodes={filterZipCodes}
                        zipCodes={zipCodes}
                        title="ZIP Codes"
                        nameFilter="zipFilter"
                        downloadZipsFile={downloadZipsFile}
                    />
                    <FieldArray
                        name="excludeZipCodes"
                        component={renderZipCode}
                        showFilter={false}
                        nameFilter="excludeZipFilter"
                        title="Excluded ZIP Codes"
                        filterZipCodes={filterExcludeZipCodes}
                        zipCodes={excludeZipCodes}
                        downloadZipsFile={()=>{}}
                    />
                    <div className="d-flex flex-column flex-md-row mt-3">
                        <Link to={getPathWithParams(settingsRatesLocationsId, "id", params.id)} className="btn btn-secondary m-1">
                            Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary m-1">
                            Save
                        </button>
                    </div>
                </LoadMask>
            </form>
        )
    }
}


const VehiclesLocationsForm = reduxForm({
    form: 'zipCodesLocationsForm',
    validate: ()=>{
        const errors = {}
        return errors;
    }
})(Form)

export default VehiclesLocationsForm;
