import React from 'react';
import Table from '../Utils/Grid';
import { TableHeaderColumn } from "react-bootstrap-table";
import { BootstrapTable } from "react-bootstrap-table";
import moment from 'moment';
import { getPathWithParams, reservationsCharterBookingsDetailId } from '../../../routesConfiguration/paths';

const GridUpcoming = ({
    find,
    data,
    page,
    loadingData,
    openModalDetail,
    openModalCancel,
    onSortChange,
    history
}) => {

    const calcDiff = (date) => {
        let now = moment();
        date = moment(date, 'YYYY-MM-DDTHH:mm:ss');
        let difYear = date.diff(now, 'years');
        let difMonth = date.diff(now, 'months');
        let difDay = date.diff(now, 'days');
        let difHour = date.diff(now, 'hours');
        let difMinute = date.diff(now, 'minutes');

        if(difYear > 0)
            return difYear + ' Y';
        else if(difMonth > 0)
            return difMonth + ' M';
        else if(difDay > 0)
            return difDay + ' D';
        else if(difHour > 0)
            return difHour + ' H';
        else if(difMinute > 0)
            return difMinute + ' m';
        else
            return 'T'
    }

    return (
        <React.Fragment>
            {loadingData ? '' : (
                <div className="row justify-content-start">
                    {data && data.data && data.data.map( (item) => (
                        <div key={item._id}
                            className="card col-12 p-0 col-md-4"
                            style={{ border: "1px solid #3B3B3B", cursor: "pointer" }}
                            onClick={()=>{
                                history.push(getPathWithParams(
                                    reservationsCharterBookingsDetailId,
                                    "id",
                                    item._id
                                ))
                            }}
                        >
                            <div className="card-body d-flex">
                                <div style={{ flex: 1 }}>
                                    <p>
                                        <strong> PU Date: </strong>
                                        <br style={{ border: 0, margin: 0 }}/>
                                        {item.puTimeNoTimezone ?
                                            moment.parseZone(item.puTimeNoTimezone).format("MM/DD/YYYY LT"):
                                            moment.parseZone(item.puTime).format("MM/DD/YYYY LT")
                                        }
                                    </p>
                                    <p>
                                        <strong> Passenger: </strong>
                                        <br style={{ border: 0, margin: 0 }}/>
                                        {item.guestFirstNm} {item.guestLastNm}
                                    </p>
                                    <p>
                                        <strong> PU Address: </strong>
                                        <br style={{ border: 0, margin: 0 }}/>
                                        {item.puAddress}
                                    </p>
                                    <p>
                                        <strong> DO Address: </strong>
                                        <br style={{ border: 0, margin: 0 }}/>
                                        {item.doAddress}
                                    </p>
                                </div>
                                <div className="d-flex flex-column justify-content-between">
                                    <div className="mb-2 px-3 t-center"
                                        style={{
                                            background: "#C0C0C0",
                                            borderRadius: "0.4rem",
                                        }}
                                    >
                                        <small style={{ fontSize: "calc(0.5em + 0.5vw)" }}>
                                            {calcDiff(item.puTimeNoTimezone ? item.puTimeNoTimezone : item.puTime)}
                                        </small>
                                    </div>
                                    <div style={{ flex: 1 }}
                                        className="d-flex align-items-start justify-content-end"
                                    >
                                        {item.flightAirline && item.flightNumber && (
                                            <h5 align="right">
                                                {item.flightAirline.code}
                                                <br style={{ border: 0, margin: 0 }}/>
                                                {item.flightNumber.flightNumber}
                                            </h5>
                                        )}
                                    </div>
                                    <div className="mt-2 px-3"
                                        style={{
                                            background: "#C0C0C0",
                                            borderRadius: "0.4rem",
                                        }}
                                    >
                                        <small style={{ fontSize: "calc(0.5em + 0.2vw)" }}> {item.confirmationNo} </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <BootstrapTable
                data={loadingData ? [] : data.data}
                remote
                pagination
                hover
                fetchInfo={{ dataTotalSize: Math.min(data.total, 30) }}
                tableContainerClass='border-none'
                options={{
                    page,
                    sizePerPage: 3,
                    hideSizePerPage: true,
                    alwaysShowAllBtns: true,
                    noDataText: loadingData
                    ? <div className="col-12 d-flex justify-content-center my-5">
                            <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    : "No data",
                    onPageChange: find || (() => {}),
                    onSortChange: onSortChange ? onSortChange : () => {},
                }}
            >
                <TableHeaderColumn
                    isKey
                    dataField="_id"
                    hidden
                >
                    Date/Time
                </TableHeaderColumn>
            </BootstrapTable>
        </React.Fragment>
    )
}

export default GridUpcoming;
