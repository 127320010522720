import app from "../../libs/apiClient";
import { createActions, handleActions } from "redux-actions";
import { goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";

import { setMessage } from "./message";
import _ from "lodash";
import { actions as breadcrumbActions } from "./breadcrumb";

const service = app.service("/api/promo-code");

// Actions
export const create = () => (dispatch, getState) => {
    const formData = getState().form.promoCode.values;

    dispatch(loading());
    service
        .create(formData)
        .then((response) => {})
        .catch((e) => {
            dispatch(setMessage(e.message, "alert"));
            console.log("error", e);
        })
        .finally(() => {
            dispatch(goBack());
            dispatch(loading(false));
        });
};

export const update = () => async (dispatch, getState) => {
    const formData = getState().form.promoCode.values;

    dispatch(loading());

    try {
        await service.patch(formData._id, formData);
        dispatch(goBack());
    } catch (e) {
        Swal.fire({
            icon: "success",
            text: e.message,
            confirmButtonColor: "#D50032",
        });
        console.log("error", e);
    } finally {
        dispatch(loading(false));
    }
};

export const destroy = (id) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        const remove = await service.remove(id);
        dispatch(find());
    } catch (e) {
        console.log("error", e);
    } finally {
        dispatch(loading(false));
    }
};

export const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading());
    const { search } = getState().promoCodes;
    try {
        const query = { query: { locationId: null, $skip: (page - 1) * 10 } };
        if (search)
            query.code = { $regex: _.escapeRegExp(search), $options: "i" };
        const response = await service.find(query);
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (e) {
        console.log("error", e);
    } finally {
        dispatch(loading(false));
    }
};

export const load2Update = (id) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const promoCode = await service.get(id);
        const { updateCustomLabel } = breadcrumbActions;
        dispatch(setUpdateData(promoCode));
        dispatch(updateCustomLabel(id, promoCode.code));
        dispatch(initializeForm("promoCode", promoCode));
    } catch (e) {
        console.log("error", e);
    } finally {
        dispatch(loading(false));
    }
};
export const setSearch = (search) => (dispatch) => {
    dispatch({ type: "SET_SEARCH", search });
    dispatch(find());
};

export const { loading, setData, setPage, setUpdateData } = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
    SET_PAGE: (page) => ({ page }),
    SET_UPDATE_DATA: (updateData) => ({ updateData }),
});

// Reducers
const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setUpdateData]: (state, { payload: { updateData } }) => ({
        ...state,
        updateData,
    }),
    'SET_SEARCH': (state, { search }) => ({ ...state, search }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    search: "",
    loading: false,
    updateData: {},
};

export default handleActions(reducers, initialState);
