import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/crewAdmin/zones';
import IconAngleLeft from '../../../../icons/angleLeftIcon';
import LoadMask from '../../../Utils/LoadMask';
import ZoneForm from './zoneForm';
import DownloadUploadZip from './uploadDownloadZip';

class Container extends React.Component {

    onSubmit = (values) => {
        this.props.create(values).then((valid)=>{
            if(valid)
                this.props.history.goBack();
        });
    }

    cancel = () => {
        this.props.history.goBack();
    }

    render(){

        const {
            history,
            item,
            loading,
            loadingForm,
        } = this.props;

        return (
            <div className="h-100 crewContainer px-3">
                <div className="d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>this.cancel()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-12 col-xl-7 my-2 mt-xl-4 pt-xl-2">
                        <DownloadUploadZip
                            zoneId={this.props.zoneId}
                        />
                        <div className="rounded shadow border border-dark">
                            <div className="d-flex flex-wrap pt-5 px-3">
                                <div className="col-12 mb-3 px-3 px-lg-5">
                                    <LoadMask loading={loadingForm} blur>
                                        <ZoneForm
                                            onSubmit={this.onSubmit}
                                            cancel={()=>this.cancel()}
                                            clearData={this.props.clearData}
                                            item={item}
                                        />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = state => ({
    ...state.crewAdmin.zones,
})

const mdtp = {
    ...actions,
}

export default connect(mstp, mdtp)(Container);

