import React from "react";

const IconMagnifyingGlassLocation = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 164.000000 164.000000"
            className={className}
        >
            <g
                transform="translate(0.000000,164.000000) scale(0.100000,-0.100000)"
                fill="currentColor"
                stroke="none"
            >
                <path
                    d="M535 1595 c-114 -24 -226 -85 -315 -174 -83 -82 -126 -150 -159 -249
-116 -346 68 -716 412 -828 67 -22 102 -27 192 -28 143 -1 240 25 358 96 l38
22 207 -207 207 -207 70 70 70 70 -208 208 -208 208 34 56 c18 31 45 89 58
130 21 63 24 91 23 203 0 113 -4 139 -27 203 -70 198 -220 347 -411 408 -101
32 -240 40 -341 19z m272 -321 c29 -18 59 -49 78 -79 28 -45 30 -56 30 -139 0
-78 -4 -99 -27 -146 -36 -72 -109 -182 -167 -247 l-46 -52 -24 22 c-89 82
-215 299 -226 391 -14 110 42 216 139 263 72 34 175 29 243 -13z"
                />
                <path
                    d="M609 1132 c-28 -25 -34 -37 -34 -72 0 -35 6 -47 34 -72 29 -26 39
-30 75 -25 116 16 116 178 0 194 -36 5 -46 1 -75 -25z"
                />
            </g>
        </svg>
    );
};

export default IconMagnifyingGlassLocation;
