import React, { useEffect, useState } from 'react';
import LoadMask from '../../Utils/LoadMask';
import { connect } from 'react-redux';
import { actions } from "../../../../redux/modules/arilineCrew";
import { actions as breadcrumbActions } from "../../../../redux/modules/breadcrumb";
import SingleDetailTrip from './details';
import { reservationsAirlineTrackTrip } from '../../../../routesConfiguration/paths';


class SingleDetail extends React.Component{

    componentDidMount(){
        const { flight, id } = this.props.match.params;
        if(!flight || !id)
            this.props.history.replace(reservationsAirlineTrackTrip);

        const [airline, airport, flightNumber] = flight.split('-');
        this.props.getTrip({ airline, airport, flightNumber, id });
    }

    componentDidUpdate(prevProps) {
        const { singleDetail, match = {}, updateCustomLabel } = this.props;
        const { params = {} } = match;
        const { id } = params;

        if (prevProps.singleDetail !== singleDetail) {
            updateCustomLabel(id, singleDetail.TripStatusDesc);
        }
    }

    componentWillUnmount(){
        this.props.clearData();
    }

    render(){

        const {
            loading,
        } = this.props;

        return (
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <LoadMask loading={loading} blur>
                        <SingleDetailTrip
                            {...this.props}
                            btnDone={()=>this.props.history.push(reservationsAirlineTrackTrip)}
                        />
                    </LoadMask>
                </div>
            </div>
        )
    }
}

const mstp = (state) => ({
    ...state.airlineCrew,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
    updateCustomLabel: breadcrumbActions.updateCustomLabel
};

export default connect(mstp, mdtp)(SingleDetail);
