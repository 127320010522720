import React from "react";
import NumberFormat from "react-number-format";
import ReactStars from "react-rating-stars-component";
import { EmojiSad, EmojiSmile } from "../../../icons/emojis";

export const RenderNumber = ({ value, decimalScale, className, formatTemplate }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={decimalScale ? decimalScale : 0}
            fixedDecimalScale={true}
            format={formatTemplate}
            value={value}
            thousandSeparator={true}
            prefix={""}
            displayType={"text"}
        />
    );
};

export const RenderCurrency = ({ value, className }) => {
    return (
        <NumberFormat
            className={className}
            decimalScale={2}
            fixedDecimalScale={true}
            value={value}
            thousandSeparator={true}
            prefix={"$ "}
            displayType={"text"}
        />
    );
};

export const RenderDateTime = ({ value, className }) => {
    if (value) {
        const fecha = new Date(value);
        return (
            <span className={className}>
                {fecha.toLocaleDateString()} {fecha.toLocaleTimeString()}
            </span>
        );
    }
    return <span className={className}>{value}</span>;
};

export const RenderRating = ({
    activeColor="#ffd700",
    className="",
    isHalf= false,
    count=5,
    size=24,
    value=0
}) => {

    return (
        <ReactStars
            count={count}
            value={value}
            edit={false}
            classNames={className}
            isHalf={isHalf}
            size={size}
            activeColor={activeColor}
        />
    )
}

export const RenderRange = ({ input, size = 10, value = 0 }) => {
    const Render = ({ item, index, rating }) => {
        const fill = rating - 1 === index ? "bg-secondary rounded text-white" : "";

        return (
            <React.Fragment>
                {index === 0 ? 
                    <span style={{ width: "30px", padding: "1px", color: "#FF0000", background: "black", borderRadius: "50%" }} className="mx-2">
                        <EmojiSad className="w-100 h-100"/>
                    </span>
                : ''}
                <span
                    className={`mx-2 px-2 py-1 clickable bold ${fill}`}
                    style={{ color: "#707070" }}
                >
                    {item}
                </span>
                {index === (size - 1) ? 
                    <span style={{ width: "30px", padding: "1px", color: "#57E668", background: "black", borderRadius: "50%" }} className="mx-2">
                        <EmojiSmile className="w-100 h-100"/>
                    </span>
                : ''}
            </React.Fragment>
        );
    };

    const count = (size) => {
        let arr = [];
        for(let c = 0; c < size; c++)
            arr.push(c + 1);
        return arr;
    };

    return (
        <div>
            <div className="btn-toolbar">
                <div className="btn-group btn-group-lg mr-2">
                    {count(size).map((item, index) => (
                        <Render
                            key={index}
                            index={index}
                            rating={value}
                            item={item}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export const RenderMultipleOption = ({ options = [], values = [] }) => {
    const initial = 97; // ascii code
    const end = 122; // ascii code
    const additionalItems = values.filter(item => !options.find(o => o === item));
    const allOptions = options.concat(additionalItems);
    return (
        <div className="mr-2 d-flex flex-wrap">
            {allOptions.map((item, index) => {
                const fill = values.find(itemValue => itemValue === item) ? "rounded-circle" : "";
                const actual = initial + index;
                return (
                    <div className="col-12 col-lg-6 py-2 px-0 d-flex">
                        <div>
                            <span className={`mx-2 px-2 py-1 bold ${fill}`} style={{ border: fill ? "2px solid #707070" : "" }}>
                                {String.fromCharCode(actual)}.
                            </span>
                        </div>
                        <span className="flex-1">{item}</span>
                    </div>
                );
            })}
        </div>
    );
};

export const ReadFields = {
    renderCurrency: RenderCurrency,
    renderNumber: RenderNumber,
};
