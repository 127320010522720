import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderPlacesAutocomplete } from "../../Utils/renderField/renderField";


const Form = (props) => {
    const { handleSubmit, item, setDetails, clearData } = props;

    useEffect(()=>{
        return ()=>clearData();
    }, [])

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row">
                    <div className="form-group col-12">
                        <strong htmlFor="name"> Location Name </strong>
                        <Field
                            name="name"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="Address"> Address </strong>
                        <Field
                            id="contactAddress"
                            name="address"
                            component={renderPlacesAutocomplete}
                            additionalAddressInfo={['street_number', 'route']}
                            setDetails={setDetails}
                            Value={item && item.address}
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="Address"> Address 2 </strong>
                        <Field
                            id="contactAddress2"
                            name="address2"
                            component={renderPlacesAutocomplete}
                            additionalAddressInfo={['street_number', 'route']}
                            setDetails={(data)=>setDetails(data, true)}
                            Value={item && item.address2}
                            className="form-control"
                        />
                    </div>
                </div>


                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <button type="submit"
                        className="btn btn-primary m-1 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
}

const LocationsForm = reduxForm({
    form: "locationsForm",
    validate: (data, props) =>{
        let address = props.address;
        const errors = {};
        if(!data.name) errors.name = "Required field";
        else if(!address || !address.city || !address.state || !address.postal_code)
            errors.address = "Invalid address";
        return errors;
    }
})(Form);

export default LocationsForm;
