import { createActions, handleActions } from "redux-actions";

export const { setMessage, unsetMessage } = createActions({
    SET_MESSAGE: (message, type) => ({ message, type }),
    UNSET_MESSAGE: () => ({}),
});

const reducer = {
    [setMessage]: (state, { payload: { message, type } }) => ({
        ...state,
        message,
        type,
    }),
    [unsetMessage]: (state) => ({ ...state, message: "", type: "" }),
};

const initialState = {
    message: "",
    type: "",
};

export default handleActions(reducer, initialState);
