import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import "./index.css";

const BreadCrumbComponent = ({ location, customLabelId, customLabel }) => {
    const pathSegments = useMemo(() => {
        const segments = location.pathname
            .split("/")
            .filter((segment) => segment !== "");

        return segments;
    }, [location.pathname]);

    return (
        <div className="breadcrumb-container">
            <Breadcrumb listClassName="breadcrumb-list">
                <BreadcrumbItem>
                    <NavLink
                        to="/"
                        className="breadcrumb-item"
                        activeClassName="breadcrumb-item--active"
                        exact
                    >
                        Home
                    </NavLink>
                </BreadcrumbItem>
                {pathSegments.map((segment, index) => {
                    // Build the URL path up to the current segment
                    const urlPath = `/${pathSegments
                        .slice(0, index + 1)
                        .join("/")}`;

                    // Determine the display label for the breadcrumb
                    let label = segment;
                    if (label === customLabelId) label = customLabel;
                    else {
                        label = segment
                            .split("-")
                            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
                            .join(" ");
                    }
                    // Create the breadcrumb item
                    return (
                        <BreadcrumbItem key={urlPath}>
                            <NavLink
                                to={urlPath}
                                className="breadcrumb-item"
                                activeClassName="breadcrumb-item--active"
                                exact
                            >
                                {label}
                            </NavLink>
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </div>
    );
};

export default BreadCrumbComponent;
