import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import examplesMobile from "libphonenumber-js/examples.mobile.json";
import { getExampleNumber } from "libphonenumber-js";
import { renderField, renderNumber, renderPhoneNumber, renderPlacesAutocomplete, renderSelectField } from "../../Utils/renderField/renderField";
import { countryOptions } from "../../../utility/constants";

const Form = (props) => {
    const { handleSubmit, item, setDetails, clearData, countryValue } = props;

    useEffect(()=>{
        return ()=>clearData();
    }, [])

    const [phoneFormat, setPhoneFormat] = useState({
        template: "",
        placeholder: "",
    });

    const setPhoneTemplate = (country, setInformation) => {
        if (country) {
            const phoneNumber = getExampleNumber(country, examplesMobile);
            if (phoneNumber) {
                setInformation({
                    placeholder: phoneNumber.formatNational(),
                    template: `${phoneNumber.formatNational()}`.replace(
                        /\d/g,
                        "#"
                    ),
                });
            }
        }
    };

    useEffect(() => {
        setPhoneTemplate(countryValue, setPhoneFormat);
    }, [countryValue]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row">
                    <div className="form-group col-12">
                        <strong htmlFor="fullName"> Full name </strong>
                        <Field
                            name="fullName"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="phone"> Country </strong>
                        <Field
                            name="country"
                            component={renderSelectField}
                            labelKey="label"
                            valueKey="value"
                            options={countryOptions}
                            placeholder="Country"
                            clearable={false}
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="phone"> Main Phone </strong>
                        <Field
                            name="phone"
                            component={renderPhoneNumber}
                            placeholder={phoneFormat.placeholder}
                            formatTemplate={phoneFormat.template}
                            type="text"
                            removePaddingTop
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="mobile_phone"> Mobile Phone </strong>
                        <Field
                            name="mobile_phone"
                            component={renderPhoneNumber}
                            placeholder={phoneFormat.placeholder}
                            formatTemplate={phoneFormat.template}
                            type="text"
                            removePaddingTop
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="email"> Email </strong>
                        <Field
                            name="email"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="contact_type"> Contact Type </strong>
                        <Field
                            name="contact_type"
                            component={renderSelectField}
                            options={[
                                { key: "Billing", label: "Billing", value: "Billing" },
                                { key: "Notices", label: "Notices", value: "Notices" },
                                { key: "Operations", label: "Operations", value: "Operations" },
                            ]}
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="Address"> Address </strong>
                        <Field
                            id="contactAddress"
                            name="address"
                            component={renderPlacesAutocomplete}
                            additionalAddressInfo={['street_number', 'route']}
                            setDetails={setDetails}
                            Value={item && item.address}
                            className="form-control"
                            searchOptions={{
                                restricted: false
                            }}
                        />
                    </div>
                </div>


                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <button type="submit"
                        className="btn btn-primary m-1 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
}

const ContactsForm = reduxForm({
    form: "contactsForm",
    validate: (data, props) =>{
        let address = props.address;
        const errors = {};
        if(!data.fullName) errors.fullName = "Required field";
        return errors;
    },
    initialValues: { country: "US" },
})(Form);

const selector = formValueSelector("contactsForm");
const ContactsFormWithState = connect((state) => {
    const countryValue = selector(state, "country");
    return { countryValue };
})(ContactsForm);

export default ContactsFormWithState;
