import { connect } from "react-redux";
import Update from "./UserUpdate";
import { update, load2Update, getRoles } from "../../../../redux/modules/users";

const mstp = (state) => ({
    ...state.users,
    userInfo: state.login.userInfo,
})

const mdtp = { update, load2Update, getRoles };

export default connect(mstp, mdtp)(Update);
