import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";

import "./login.css";
import LoadMask from "../Utils/LoadMask";
const { BACKEND_URL } = process.env;
const logo = require("../../../../assets/img/logo.png");

class Login extends Component {
    static propTypes = {
        nameError: PropTypes.bool.isRequired,
        passError: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        hasNameError: PropTypes.func.isRequired,
        hasPassError: PropTypes.func.isRequired,
        submitError: PropTypes.string,
        loggedIn: PropTypes.bool.isRequired,
    };

    state = {
        step: 1,
    };

    nextStep = (values) => {
        if(values && values.email && values.email.match(/@connectbbc.com/g) !== null){
            location.href = `${BACKEND_URL}auth/azure`;
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    }

    previusStep = () => {
        this.setState({ step: this.state.step - 1 });
    }

    render() {
        const { onSubmit, submitError, loggedIn, location } = this.props;
        if (loggedIn) {
            if (location.state)
                return <Redirect to={`${location.state.from.pathname}`} />;
            return <Redirect to={"/"} />;
        }
        return (
            <div className="d-flex flex-wrap justify-content-center loginForm">
                <div className="col-12 col-sm-10 col-md-7 col-lg-5 col-xl-4 pt-5">
                    <div className="border rounded shadow p-4 p-xl-5 mt-5">
                        <img src={logo} className="img-unstyled mb-4 w-100" alt="logo" style={{ maxWidth: '280px' }} />
                        <h3 className="text-left mb-4">LOGIN</h3>
                        <LoadMask loading={this.props.loader} blur>
                            <LoginForm
                                step={this.state.step}
                                onSubmit={this.state.step == 1 ? this.nextStep : onSubmit}
                            />
                            {submitError && <p>{submitError}</p>}
                        </LoadMask>
                    </div>

                    <div className="my-4">
                        <h5>Looking for our customer website? <a className="text-primary text-decoration-underline" target="_blank" href={`https://www.connectbbc.com`}> Follow this link to be redirected there </a></h5>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
