import React from "react";
import PropTypes from "prop-types";
import Form from "./UserUpdateForm";

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.load2Update(this.props.match.params.id);
    }

    render() {
        const { update, loading, getRoles, userInfo: { superUser } } = this.props;

        return (!superUser
          ? <div className="d-flex w-100 justify-content-center align-items-center">
                <label> You are not a superuser </label>
            </div>
          : <div className="row">
                <div className="col-12">
                    <Form onSubmit={update} loader={loading} updateData={this.props.updateData}
                        getRoles={getRoles}
                    />
                </div>
            </div>
        );
    }
}
