import isEmpty from 'lodash.isempty';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import { actions } from '../../../../redux/modules/liveryEdition';
import app from '../../../../libs/apiClient';
import renderTimePicker from '../../../../TimePicker';
import { validateAddress } from '../../../utility/form-validations';
import AutoSuggest from '../../Utils/autosuggest';
import { Collapse } from 'reactstrap';
import { renderDatePicker, renderField, renderFieldCheck, renderCount, renderPlacesAutocomplete } from '../../Utils/renderField/renderField';
import moment from 'moment';
import ModalVerify from './modalVerify';
import NoModalVerify from './noModalVerify';
import _ from 'lodash';
import { Icons } from '../../../icons/menu';


export const formatFlightStatus = (status) => {
        let result = "TBD";
        if(!status)
            return result;
        else if(['Cancelled', 'Scheduled', 'Proposed', 'Expected', 'Delayed', 'Landed'].includes(status))
            result = status.toUpperCase();
        else if(status == 'OutGate')
            result = 'OUT GATE';
        else if(status == 'InGate')
            result = 'IN GATE'
        else if(status == 'InAir')
            result = "IN AIR";
        else if(status == 'PastFlight')
            result = "PAST FLIGHT";
        else if(['NoTakeOffInfo', 'Unknown'].includes(status))
            result = "NA"
        return result;
    };

const Form = (props) => {

    const [moreOptions, setMoreOptions] = React.useState(false);

    React.useEffect(()=>{
        if(isEmpty(props.airlineCodes) || isEmpty(props.customerPOs))
            props.initializeDataAutocomplete();

        if(props.showMoreOptions)
            setMoreOptions(true);
    }, []);

    const [modalVerifyFlight, setModalVerifyFlight] = useState(false);

    const sortAirline = (airlineList) => {
        airlineList = airlineList.sort((a, b)=> a.label.localeCompare(b.label));
        return airlineList;
    };

    const loadAirports = (search) => {
        if (!search) return [];

        const [code] = search.trim().match(/^\w{2,3}(\s+)/g) || [search];
        const [name] = search.trim().match(/(\s+)(?!^\w{2,3}).+$/g) || [search];

        return app
            .service("/api/airport")
            .find({
                query: {
                    $paginate: false,
                    $or: [
                        {
                            code: {
                                $regex: _.escapeRegExp(code.trim()),
                                $options: "i",
                            },
                        },
                        {
                            name: {
                                $regex: _.escapeRegExp(name.trim()),
                                $options: "i",
                            },
                        },
                    ],
                    $limit: 5,
                },
            })
            .then((result) => {
                if (result.length > 0) {
                    for (let i = 0; i < result.length; i += 1) {
                        Object.assign(result[i], {
                            label: `${result[i].name} (${result[i].code})`,
                            formatted_address: `${result[i].name} (${result[i].code})`,
                            postal_code: result[i].zip,
                            street_number: result[i].street,
                            types: ["airport"],
                            fromDB: true,
                        });
                        delete result[i].street;
                        delete result[i].zip;
                    }
                }
                return result;
            })
            .catch(() => {
                return [];
            });
    };

    const { handleSubmit, cancel, setDetails, from = {}, to = {}, airlineCodes, formValues, ableToEdit = true,
            verifyFlight, singleDetail, inputData, isUpdateView,
        } = props;

    const company =
        isUpdateView && inputData
            ? inputData.airlineCode && inputData.airlineCode
            : JSON.parse(localStorage.getItem("company_airline_livery_create"));

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
                <div className="w-100 d-flex flex-wrap">
                    {company ? (
                        <div className="mt-4 col-12 form-group">
                            <h3 className="text-left mb-2">
                                {company.code}
                                &nbsp;-&nbsp;
                                {company.company}
                            </h3>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="col-12 col-md-7 form-group">
                        <strong htmlFor="date">Pick-Up Date</strong>
                        <Field
                            name="date"
                            numberOfMonths={1}
                            component={renderDatePicker}
                            className="flex-1 w-100 p-0"
                            disabled={!ableToEdit}
                        />
                    </div>

                    <div className="col-12 col-md-5 form-group">
                        <strong>Pick-Up Time </strong>
                        <Field
                            id="timepicker1"
                            name="time"
                            placeholder="12:00 PM"
                            component={renderTimePicker}
                            minuteStep={1}
                            style={{height: 'none'}}
                            Value={
                                (formValues && formValues.time && formValues.time instanceof Object && moment(`${formValues.time.hour}:${formValues.time.minutes}`, 'HH:mm')) ||
                                (singleDetail && singleDetail.PUTime && moment(singleDetail.PUTime, 'HH:mm'))
                            }
                            disabled={!ableToEdit}
                        />
                    </div>
                </div>
                <div className="w-100 d-flex flex-wrap">
                    <div className="col-12 col-md-5 form-group">
                        <strong>Passengers</strong>
                        <Field
                            name="pax"
                            component={renderCount}
                            min={1}
                            disabled={!ableToEdit}
                        />
                    </div>
                    <div className="col-12 col-md-7 form-group">
                        <strong>Customer PO</strong>
                        <Field
                            name="customer_po"
                            component={renderField}
                            placeholder="123456A"
                            className="w-100"
                            disabled={!ableToEdit}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <hr/>
                </div>
                <div className="col-12 form-group">
                    <strong>Pick-Up Location</strong>
                    <Field
                        name="pickUp"
                        component={renderPlacesAutocomplete}
                        setDetails={setDetails}
                        pl="from"
                        lat={from && from.lat}
                        lng={from && from.lng}
                        additionalAddressInfo={['street_number', 'route']}
                        placeholder="From: airport, hotel, address..."
                        aditionalAutocomplete={loadAirports}
                        disabled={!ableToEdit}
                    />
                </div>
                <div className="col-12 form-group">
                    <strong>Drop-Off Location</strong>
                    <Field
                        name="dropOff"
                        component={renderPlacesAutocomplete}
                        setDetails={setDetails}
                        additionalAddressInfo={['street_number', 'route']}
                        placeholder="To: airport, hotel, address..."
                        aditionalAutocomplete={loadAirports}
                        pl="to"
                        lat={to && to.lat}
                        lng={to && to.lng}
                        disabled={!ableToEdit}
                    />
                </div>
                <div className="col-12">
                    <hr/>
                </div>
                <div className="w-100 d-flex flex-wrap">
                    <div className="col-12 col-md-8 form-group">
                        <strong>Airline</strong>
                        <Field
                            name="airline"
                            component={AutoSuggest}
                            keyLabel='label'
                            keyId='_id'
                            searchKey="company"
                            placeholder="UA"
                            suggestions = {sortAirline(airlineCodes)}
                            className="w-100"
                            onSelectOption={value=>props.onSelectAirline(value, 'createLivery')}
                            disabled={!ableToEdit}
                        />
                    </div>
                    <div className="col-12 col-md-4 form-group">
                        <strong>Flight No.</strong>
                        <Field
                            name="flight"
                            component={renderField}
                            placeholder="951"
                            className="flex-1 w-100"
                            disabled={!ableToEdit}
                        />
                    </div>
                    {ableToEdit && (
                        <div className="col-12 form-group">
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-secondary btn-sm"
                                    onClick={()=>{
                                        setModalVerifyFlight(true);
                                        verifyFlight(formValues);
                                    }}
                                    disabled={!formValues || !formValues.airline || !formValues.flight || !formValues.date || (!formValues.pickUp && !formValues.dropOff)}
                                >
                                    VERIFY
                                </button>
                            </div>
                        </div>
                    )}

                    {props.useModalVerify ? (
                        <ModalVerify
                            modalVerifyFlight={modalVerifyFlight}
                            setModalVerifyFlight={setModalVerifyFlight}
                        />
                    ) : (
                        <NoModalVerify
                            modalVerifyFlight={modalVerifyFlight}
                            setModalVerifyFlight={setModalVerifyFlight}
                        />
                    )}

                    {formValues && !modalVerifyFlight && formValues.xmlFlightVerified && Object.keys(formValues.xmlFlightVerified).length > 0 && !formValues.xmlFlightVerified.noInfo ? (
                        <div className="col-12 form-group">
                            <div className="border border-dark rounded p-3 d-flex">
                                <div className="flex-1 d-flex flex-column align-items-start">
                                    <label>{formValues.xmlFlightVerified.flight.airline.code} {formValues.xmlFlightVerified.flight.flightNumber}</label>
                                    <label>{formValues.xmlFlightVerified.departure.airportCode} - {formValues.xmlFlightVerified.arrival.airportCode}</label>
                                </div>
                                <div className="flex-1 d-flex flex-column align-items-end">
                                    <label className="bold text-uppercase">{formatFlightStatus(formValues.xmlFlightVerified.flight.flightStatus)}</label>
                                    <label>DEP: {(formValues.xmlFlightVerified.selected.departure.time).replace(/(\d+):(\d+):(\d+)/, '$1:$2')}  ARR: {(formValues.xmlFlightVerified.selected.arrival.time).replace(/(\d+):(\d+):(\d+)/, '$1:$2')}</label>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    {(!isUpdateView && company && (company.has_aoa || company.has_cgo)) || (isUpdateView && inputData && inputData.airlineCode && (inputData.airlineCode.has_aoa || inputData.airlineCode.has_cgo)) ? (
                        <div className="col-12 mb-2">
                            <div className="w-100 border-bottom border-black mb-3 d-flex clickable" onClick={()=>setMoreOptions(!moreOptions)}>
                                {moreOptions ? (
                                    <Icons.IconCaretUp className="smallIcon"/>
                                ) : (
                                    <Icons.IconCaretDown className="smallIcon"/>
                                )}
                                <label className="clickable ml-3"> More options </label>
                            </div>
                            <Collapse isOpen={moreOptions}>
                                <div className="d-flex flex-wrap">
                                    {(!isUpdateView && company && company.has_aoa) || (isUpdateView && inputData && inputData.airlineCode && inputData.airlineCode.has_aoa ) ? (
                                        <div className="col-12 col-md-6 px-0 pr-md-3 form-group">
                                            <Field
                                                name="is_aoa"
                                                component={renderFieldCheck}
                                                label="AOA Requirement"
                                                disabled={!ableToEdit}
                                            />
                                        </div>
                                    ) : ''}
                                    {(!isUpdateView && company && company.has_cgo) || (isUpdateView && inputData && inputData.airlineCode && inputData.airlineCode.has_cgo) ? (
                                        <div className="col-12 col-md-6 px-0 pl-md-3 form-group">
                                            <Field
                                                name="is_cgo"
                                                component={renderFieldCheck}
                                                label="Cargo"
                                                disabled={!ableToEdit}
                                            />
                                        </div>
                                    ) : ''}
                                </div>
                            </Collapse>
                        </div>
                    ) : ''}
                </div>
                <div className="col-12 col-md-6 offset-md-6">
                    <div className="d-flex justify-content-end flex-column flex-md-row">
                        <button type="button" className={`btn btn-secondary btn-lg mb-2 ${ableToEdit ? 'mr-md-2' : ''}`} onClick={cancel}>
                            Back
                        </button>
                        {ableToEdit && (
                            <button type="submit" className="btn btn-primary btn-lg mb-2 ml-md-2">
                                Submit
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </form>
    )
}

const CreateForm = reduxForm({
    form: 'createLivery',
    validate: (data, props) => {
        const errors = {};
        if(!data.date) errors.date = "Required Field";
        if(!data.time) errors.time = "Required Field";
        if(!data.pax) errors.pax = "Required Field";
        if(!data.vehicle) errors.vehicle = "Required Field";
        if(isEmpty(props.from)) errors.pickUp = "Required Field";
        else if(!validateAddress(props.from))
            errors.pickUp = "Please select an exact address";
        if(isEmpty(props.to)) errors.dropOff = "Required Field";
        else if(!validateAddress(props.to))
            errors.dropOff = "Please select an exact address";
        if(!data.airline)
            errors.airline = "Required field";
        else if((!data.xmlFlightVerified || Object.keys(data.xmlFlightVerified).length == 0) && !props.allowNoVerify)
            errors.airline = "Flight not verified";
        if(!data.flight)
            errors.flight = "Require field";
        if(data.is_aoa && data.is_cgo)
            errors.is_cgo = "Select only one option"
        return errors;
    }
})(Form);

const mstp = state => ({
    ...state.liveryEdition,
    loadingVerifier: state.liveryEdition.loadingVerifier,
    xmlFlightVerified: state.liveryEdition.xmlFlightVerified,
    formValues: state.form.createLivery && state.form.createLivery.values,
})

const mdtp = {
    ...actions,
    change,
}

export default connect(mstp, mdtp)(CreateForm);
