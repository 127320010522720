import React from "react";

const IconBus = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            className={className}
        >
            <path
                fill="currentColor"
                d="M249-120q-13 0-23-7.5T216-147v-84q-29-16-42.5-46T160-341v-397q0-73.662 76.5-107.831T481-880q166 0 242.5 34T800-738v397q0 34-13.5 64T744-231v84q0 12-10 19.5t-23 7.5h-19q-14.45 0-24.225-7.5Q658-135 658-147v-55H302v55q0 12-9.775 19.5T268-120h-19Zm-29-411h520v-173H220v173Zm106.059 219Q349-312 365-328.059q16-16.059 16-39T364.941-406q-16.059-16-39-16T287-405.941q-16 16.059-16 39T287.059-328q16.059 16 39 16Zm308 0Q657-312 673-328.059q16-16.059 16-39T672.941-406q-16.059-16-39-16T595-405.941q-16 16.059-16 39T595.059-328q16.059 16 39 16Z"
            />
        </svg>
    );
};

export default IconBus;
