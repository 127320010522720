import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Page, Text, Image, View, Document, StyleSheet } from '@react-pdf/renderer';
import logo from '../../../../../../assets/img/logo.png';

const { MEDIA_URL } = process.env;

const styles = StyleSheet.create({
    page: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 24,
        textAlign: 'center',
        fontFamily: 'Oswald'
    },
    subtitle: {
        fontSize: 14,
        marginTop: 12,
        color: '#D50032'
    },
    textId: {
        fontSize: 12,
        marginTop: 12,
        color: '#D50032'
    },
    text: {
        color: '#828282',
        fontSize: 12,
        paddingRight: 15,
        textAlign: 'justify'
    },
    textColumn: {
        marginBottom: 15,
    },
    image: {
        marginRight: 35,
    },
    section: {
        flexDirection: 'row',
        padding: 10,
        marginBottom: 5
    },
    column: {
        flexDirection: 'column',
        flex: 1
    },
    column2:{
        flexDirection: 'column',
        flex:2,
        marginLeft: 15,
    },
    resume: {
    flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    textResume: {
        color: '#828282',
        fontSize: 12,
        textAlign: 'justify'
    },
    total: {
        color: '#D50032',
        fontSize: 15,
        marginBottom: -10
    },
    fees: {
        color: '#828282',
        fontSize: 9,
        marginLeft: 'auto'
    },
    end: {
        marginTop: 10
    },
    middle:{
        alignItems: 'center',
    },
    cardVehicle: {
        border: '1px solid #757575',
        boxShadow: 'box-shadow: 5px 10px 18px #888888',
        marginBottom: '30px',
    },
    vehiclePhoto:{
        height: '110px',
    },
    logoHeader: {
        height: '40px',
        width: '144px',
    }
});

const PDFDocument = ({
    quoteNo,
    puTimeNoTimezone,
    puAddress,
    doAddress,
    duration,
    distance,
    vehiclePhoto,
    flightNumber,
    vehicleDescription,
    vehicleCapacity,
    transactionDiscount = 0,
    transactionAmount,
    tripPrice,
    gratuity = 0,
    gratuityPercent,
}) => {

    const price = tripPrice ? tripPrice : transactionAmount;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image style={styles.logoHeader} src={logo} />
                <View style={styles.section}>
                    <Text style={styles.textId}> QUOTE ID# {quoteNo} </Text>
                </View>
                <View style={styles.section}>
                    <View style={styles.column}>
                        <Text style={styles.text}> Date: { moment(puTimeNoTimezone).format('ddd, DD MMMM YYYY') } </Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.text}> Time: { moment(puTimeNoTimezone).format('(HH:mm) H:mm A z') } </Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.column}>
                        <Text style={styles.text}> From: { puAddress } </Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.text}> To: { doAddress } </Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <View style={styles.column}>
                        <Text style={styles.text}> Duration: { duration } </Text>
                    </View>
                    <View style={styles.column}>
                        <Text style={styles.text}> Distance: { distance } miles </Text>
                    </View>
                </View>

                {flightNumber && (
                    <View style={styles.section}>
                        <View style={styles.column}>
                            <Text style={styles.text}> Flight: {flightNumber.label}</Text>
                        </View>
                    </View>
                )}

                <View style={[styles.section, styles.cardVehicle, styles.middle]}>
                    <View style={styles.column}>
                        <Image style={styles.vehiclePhoto} src={`${MEDIA_URL}${vehiclePhoto}`} />
                    </View>
                    <View style={styles.column2}>
                        <Text style={[styles.text, styles.textColumn]}> { vehicleDescription } </Text>
                        <Text style={[styles.text, styles.textColumn]}> Passengers: { vehicleCapacity } </Text>
                    </View>
                </View>

                <View style={[styles.section, styles.end]}>
                    <View style={styles.column} />
                    <View style={styles.column}>
                        <View style={styles.resume}>
                            <Text style={styles.textResume}> TRIP </Text>
                            <Text style={styles.textResume}>
                                ${`${numeral(price + parseFloat(transactionDiscount || 0)).format('0,0.00')}`}
                            </Text>
                        </View>

                        {gratuity > 0 && (
                            <View style={styles.resume}>
                                <Text style={styles.textResume}> GRATUITY </Text>
                                <Text style={styles.textResume}>
                                    ${parseFloat(gratuity || 0).toFixed(2)}{gratuityPercent && ` (${gratuityPercent}%)`}
                                </Text>
                            </View>
                        )}

                        {transactionDiscount > 0 && (
                            <View style={styles.resume}>
                                <Text style={styles.textResume}> DISCOUNT </Text>
                                <Text style={styles.textResume}>
                                    - ${`${parseFloat(transactionDiscount || 0).toFixed(2) }`}
                                </Text>
                            </View>
                        )}

                        <View style={styles.resume}>
                            <Text style={styles.total}> TOTAL AMOUNT </Text>
                            <Text style={styles.total}>
                                {`${ numeral(transactionAmount || 0).format('0,0.00') }`}
                            </Text>
                        </View>

                        <View style={styles.resume}>
                            <Text style={styles.fees}> (Includes VAT, fees & tip) </Text>
                        </View>
                    </View>
                </View>

            </Page>
        </Document>
    )
}


export default PDFDocument;
