import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import Passenger from '../../cart/Passenger';
import IconTimes from '../../../icons/times';
import LoadMask from '../../Utils/LoadMask';


const PassengerForm = ({
    toggle,
    isOpen,
    handlePassengerFormSubmit,
    loading,
}) => (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="no-scroll-y" className="modalItinerary" centered>
        <ModalBody>
            <button type="button" onClick={toggle}
                className="modal-body__close"
            >
                <IconTimes className="smallIcon" />
            </button>
            <div className="col-12 px-0">
                <LoadMask loading={loading} >
                    <Passenger
                        handlePassengerFormSubmit={handlePassengerFormSubmit}
                    />
                </LoadMask>
            </div>
        </ModalBody>
    </Modal>
);

PassengerForm.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    handlePassengerFormSubmit: PropTypes.func.isRequired,
};

export default PassengerForm;
