import React from "react";

const IconStarHalfStroke = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 165.000000 164.000000"
            className={className}
        >
            <g
                transform="translate(0.000000,164.000000) scale(0.100000,-0.100000)"
                fill="currentColor"
                stroke="none"
            >
                <path
                    d="M783 1598 c-12 -14 -53 -128 -99 -275 l-79 -253 -258 0 c-163 0 -266
-4 -278 -11 -29 -15 -42 -57 -29 -88 7 -15 98 -93 211 -181 111 -85 205 -160
210 -166 5 -6 -22 -108 -73 -266 -57 -181 -79 -263 -74 -282 8 -34 49 -60 80
-52 14 3 116 78 228 166 112 88 206 160 210 160 3 0 95 -70 205 -156 109 -86
208 -161 220 -167 31 -14 78 7 93 42 9 23 7 39 -9 87 -64 190 -142 447 -139
459 2 7 91 81 198 165 107 83 203 160 213 172 24 28 21 70 -6 96 -23 21 -30
22 -283 22 l-260 0 -83 258 c-55 169 -90 263 -103 275 -28 24 -70 22 -95 -5z
m103 -548 l47 -150 166 -2 165 -3 -132 -103 c-80 -62 -132 -110 -131 -120 0
-9 19 -78 43 -152 24 -74 42 -136 41 -138 -2 -2 -60 41 -129 94 l-126 97 0
314 c0 172 2 313 5 313 2 0 25 -67 51 -150z"
                />
            </g>
        </svg>
    );
};

export default IconStarHalfStroke;
