import _ from "lodash";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import app from "../../../../../libs/apiClient";
import IconCheck from "../../../../icons/check";
import {
    renderField,
    renderFieldCheck,
    renderNumber,
    renderSearchSelect,
} from "../../../Utils/renderField/renderField";

const loadAirlines = (search) => {
    const params = { $paginate: false };
    if (search) {
        params.$or = [
            { code: { $regex: _.escapeRegExp(search), $options: "i" } },
            { company: { $regex: _.escapeRegExp(search), $options: "i" } },
        ];
    }

    return app
        .service("/api/airline")
        .find({
            query: params,
        })
        .then((result) => {
            for (let i = 0; i < result.length; i += 1)
                result[i].label = `${result[i].code} ${result[i].company}`;

            result.sort((a, b) => a.code.localeCompare(b.code));
            return { options: result };
        })
        .catch((error) => {
            console.log(error);
            return { options: [] };
        });
};

const Form = ({
    handleSubmit,
    cancel,
 }) => (
    <div className="h-100 w-100 d-flex flex-column">
        <div className="d-flex justify-content-end">
            <button form="airlineForm" type="submit" className="border-0 bg-white p-0">
                <IconCheck className="smallIcon p-1 text-white bg-success rounded-circle" />
            </button>
        </div>
        <div className="flex-1 d-flex flex-column justify-content-center">
            <form id="airlineForm" className="d-flex flex-wrap h-100" onSubmit={handleSubmit}>
                <div className="d-flex flex-wrap">
                    <div className="col-6 d-flex justify-content-end">
                        <div className="col-12 col-md-8 text-left pb-3 px-1">
                            <h4 className="mt-2"> Company:</h4>
                        </div>
                    </div>
                    <div className="col-5 text-left pb-3 px-1">
                        <Field
                            name="company"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="col-12 col-md-8 text-left pb-3 px-1">
                            <h4 className="mt-2"> Livery ID:</h4>
                        </div>
                    </div>
                    <div className="col-4 text-left pb-3 px-1">
                        <Field
                            name="liveryId"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="col-12 col-md-8 text-left pb-3 px-1">
                            <h4 className="mt-2"> IATA Code:</h4>
                        </div>
                    </div>
                    <div className="col-3 text-left pb-3 px-1">
                        <Field
                            name="code"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="col-12 col-md-8 text-left pb-3 px-1">
                            <h4 className="mt-2"> Crew Ops:</h4>
                        </div>
                    </div>
                    <div className="col-4 text-left pb-3 px-1">
                        <Field
                            name="crewOps"
                            component={renderNumber}
                            formatTemplate="(###) ###-####"
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="col-12 col-md-8 text-left pb-3 px-1">
                            <h4 className="mt-2"> AOA:</h4>
                        </div>
                    </div>
                    <div className="col-4 text-left d-flex align-items-center pb-3 px-1">
                        <Field
                            name="has_aoa"
                            component={renderFieldCheck}
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="col-12 col-md-8 text-left pb-3 px-1">
                            <h4 className="mt-2"> CGO:</h4>
                        </div>
                    </div>
                    <div className="col-4 text-left d-flex align-items-center pb-3 px-1">
                        <Field
                            name="has_cgo"
                            component={renderFieldCheck}
                        />
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <div className="col-12 col-md-8 text-left pb-3 px-1">
                            <h4 className="mt-2"> Code Shares:</h4>
                        </div>
                    </div>
                    <div className="col-6 pb-3 px-1">
                        <div className="">
                            <Field
                                multi
                                allKey
                                name="sharedCodes"
                                component={renderSearchSelect}
                                placeholder="Select airline codes"
                                loadOptions={loadAirlines}
                                valueKey="_id"
                                labelKey="code"
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
);

const AirlineUpdateForm = reduxForm({
    form: "airlineForm",
    validate: (data, props) =>{
        const errors = {};
        if(!data.company) errors.company = "Required field";
        if(!data.code) errors.code = "Required field";
        if(!data.liveryId) errors.liveryId = "Required field";
        return errors;
    }
})(Form);

export default AirlineUpdateForm;
