import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { getExampleNumber } from 'libphonenumber-js';
import examplesMobile from 'libphonenumber-js/examples.mobile.json';
import { combine, validate, validators } from 'validate-redux-form';

import { countryOptions } from '../../../utility/constants';
import { phoneValidator, EMAIL_REGEX } from '../../../utility/form-validations';
import {
    renderTextArea,
    renderLabeledField,
    renderSelectField,
    renderPhoneNumber,
} from "../../Utils/renderField/renderField";

const Form = props => {
    const { handleSubmit, from, country, clear } = props;
    let fromAirport, phoneTemplate, phonePlaceholder;

    fromAirport = from.types ? from.types.some(type => type === 'airport')
        : props.fromAirport ;

    if (country) {
        let value = country;
        if(typeof country === 'object')
            value = country.value
        const phoneNumber = getExampleNumber(value, examplesMobile);
        phonePlaceholder = phoneNumber ? phoneNumber.formatNational() : '';
        phoneTemplate = phonePlaceholder ? `${phonePlaceholder}`.replace(/\d/g, '#') : '';
    }

    return (
        <form onSubmit={handleSubmit} className="w-100">
            <div className="d-flex flex-column">
                <div className="flex-1 m-2 text-primary">
                    <strong>PASSENGER INFORMATION</strong>
                </div>
                <div className="flex-1 d-flex flex-row form-group">
                    <label htmlFor="first_name">First Name</label>
                    <Field
                        name="first_name"
                        component={renderLabeledField}
                        type="text"
                        placeholder="John"
                        className="w-100 m-2"
                    />
                </div>
                <div className="flex-1 d-flex flex-row form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <Field
                        name="last_name"
                        component={renderLabeledField}
                        type="text"
                        placeholder="Smith"
                        className="w-100 m-2"
                    />
                </div>
                <div className="flex-1 d-flex flex-row form-group">
                    <label htmlFor="email">Email</label>
                    <Field
                        name="email"
                        component={renderLabeledField}
                        type="text"
                        placeholder="Email"
                        className="w-100 m-2"
                    />
                </div>
                <div className="flex-1 d-flex flex-row form-group">
                    <Field
                        name="country"
                        component={renderSelectField}
                        labelKey="label"
                        valueKey="value"
                        options={countryOptions}
                        placeholder="Country"
                        className="flex-1 w-100 m-2"
                        inModal
                    />
                </div>
                <div className="flex-1 d-flex flex-row form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <Field
                        name="phone"
                        component={renderPhoneNumber}
                        type="text"
                        placeholder={phonePlaceholder}
                        formatTemplate={phoneTemplate}
                        className="w-100 m-2"
                    />
                </div>
            </div>

            <div className="d-flex flex-column align-items-center">
                <button
                    type="submit"
                    className="flex-grow-1 btn btn-primary px-5 py-3 m-2"
                >
                    <b>SAVE</b>
                </button>
            </div>
        </form>
    );
};

const PassengerForm = reduxForm({
    form: 'PassengerForm',
    validate: data =>
        validate(data, {
            first_name: validators.exists()('Required Field'),
            last_name: validators.exists()('Required Field'),
            email: combine(
                validators.exists()('Required Field'),
                validators.regex(EMAIL_REGEX)('Please enter a valid email')
            ),
            phone: combine(
                phoneValidator(data.country
                    ? typeof data.country === 'object'
                        ? data.country.value
                        : data.country
                    : undefined
                )(
                    'Phone number is not valid'
                ),
                validators.exists()('Required Field')
            ),
            country: validators.exists()('Required Field'),
        }),
})(Form);

export default PassengerForm;
