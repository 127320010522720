import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderDatePicker, renderSelectField } from "../../../Utils/renderField/renderField";
import _ from "lodash";
import { shuttleCompanies } from "../../../../utility/constants";

const Form = (props) => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-12 mb-4">
                    <Field
                        name="company"
                        component={renderSelectField}
                        options={shuttleCompanies}
                        placeholder="Select Company"
                        valueKey="liveryId"
                        labelKey="label"
                    />
                </div>
                <div className="col-12 mt-3 form-group">
                    <Field
                        name="date"
                        component={renderDatePicker}
                        isOutsideRange={false}
                        numberOfMonths={1}
                        placeholder="Date"
                        className="p-0"
                    />
                </div>
                <div className="col-12 d-flex justify-content-end pt-4">
                    <button type="submit" className="btn btn-primary btn-lg">
                        SEARCH
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-4">
                    <a href="https://employee.connectbbc.com" target="_blank">Shuttle Reports</a>
                    <br />
                    <a href="https://ncc.connectbbc.com" target="_blank">NCC Booking Site</a>
                    <br />
                    <a href="https://lansdowne.connectbbc.com" target="_blank">Lansdowne Booking Site</a>
                </div>
            </div>
        </form>
    );
};

const CustomerForm = reduxForm({
    form: "companyShuttleForm",
    validate: (data) => {
        const errors = {};
        if (!data.company) errors.company = "Required Field";
        if (!data.date) errors.date = "Required Field";
        return errors;
    },
})(Form);

export default CustomerForm;
