import React from 'react';
import LoadMask from '../../Utils/LoadMask';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RenderMultipleOption, RenderRange } from '../../Utils/renderField/renderReadField';
import { reservationsCharterReviewsPassenger } from '../../../../routesConfiguration/paths';

const chauffeurReasonOptionsBad = [
    "Poor communication",
    "Rude",
    "Unsafe driving",
    "Not on-time",
    "Did not assist with belongings",
    "Belongings not handled with care",
    "Got lost",
];

const chauffeurReasonOptionsGod = [
    "Good communication",
    "Friendly",
    "Good driving",
    "Belongings handled with care",
    "Went above and beyond",
];

const vehicleReasonOptionsBad = [
    "Needs wash",
    "Dirty inside",
    "Bad smell",
    "Uncomfortable",
    "No air conditioning",
    "Unsafe",
];

const vehicleReasonOptionsGod = ["Clean", "Spacious", "Comfortable"];

class Details extends React.Component {

    render(){

        const { loading, updateData } = this.props;

        return (
            <div className="d-flex flex-column" style={{ color: "#707070" }}>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-3">
                        <label className="bold"> Review Type:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> Passenger Service Review  </label>
                    </div>
                    <div className="col-12 col-md-3">
                        <label className="bold"> Trip Number:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.confirmationNo ? updateData.confirmationNo : ''}  </label>
                    </div>
                    <div className="my-5" />
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Name:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.passenger ? updateData.passenger : ''} </label>
                    </div>
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Email:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.email} </label>
                    </div>
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Phone:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.phone} </label>
                    </div>
                </div>
                <div className="d-flex my-5">
                    <hr className="flex-1" style={{ borderWidth: "2px" }} />
                    <h4 className="bold">&nbsp;REVIEW&nbsp;</h4>
                    <hr className="flex-1" style={{ borderWidth: "2px" }} />
                </div>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-12 mb-5 px-0 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong>How was your service experience with the chauffeur?</strong> </div>
                            <div className="py-2"><RenderRange value={updateData.chauffeurRaiting} size={5} /></div>
                            <div className="py-2">
                                <RenderMultipleOption
                                    options={updateData.chauffeurRaiting === 5 ? chauffeurReasonOptionsGod : chauffeurReasonOptionsBad}
                                    values={updateData.chauffeurReason}
                                />
                            </div>
                            {updateData.chauffeurFeedback !== undefined && (
                                <label><strong>Additional feedback:&nbsp;</strong>{updateData.chauffeurFeedback}</label>
                            )}
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.chauffeurRaiting}</strong>
                        </div>
                    </div>
                    <div className="col-12 mb-5 px-0 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong>How was the condition of your vehicle?</strong> </div>
                            <div className="py-2"><RenderRange value={updateData.vehicleRaiting} size={5} /></div>
                            <div className="py-2">
                                <RenderMultipleOption
                                    options={updateData.vehicleRaiting === 5 ? vehicleReasonOptionsGod : vehicleReasonOptionsBad}
                                    values={updateData.vehicleReason}
                                />
                            </div>
                            {updateData.vehicleFeedback !== undefined && (
                                <label><strong>Additional feedback:&nbsp;</strong>{updateData.vehicleFeedback}</label>
                            )}
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.vehicleRaiting}</strong>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <Link to={reservationsCharterReviewsPassenger}
                        className="btn btn-primary btn-lg"
                    >
                        Back
                    </Link>
                </div>
            </div>
        );
    }
}

export default Details;
