import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/schedule";
import { actions as routeActions } from '../../../../redux/modules/crewAdmin';
import { actions as breadcrumbActions } from "../../../../redux/modules/breadcrumb";
import ViewResults from './viewResults';

const mstp = (state) => ({
    ...state.schedule,
    loadingRouteHotel: state.crewAdmin.loadingUpdate,
    userInfo: state.login.userInfo,
});

const mdtp = {
    createHotel: routeActions.create,
    createAirlineRoute: routeActions.createAirlineRoute,
    getAirlines: routeActions.getAirlines,
    getHotels: routeActions.getHotels,
    getAirportMeetRoute: routeActions.getAirportMeetRoute,
    ...actions,
    updateCustomLabel: breadcrumbActions.updateCustomLabel,
};

export default connect(mstp, mdtp)(ViewResults);
