import React from "react";
import PropTypes from "prop-types";
import Modal from 'react-responsive-modal';
import MeetingForm from '../create/meetingProcedureForm';
import { Link } from "react-router-dom";
import moment from "moment";
import Grid from "../../Utils/Grid/materialTable";
import IconEdit from "../../../icons/edit";
import { IconTrashAlt } from "../../../icons/trash";
import IconCopy from "../../../icons/copy";
import LoadMask from "../../Utils/LoadMask";
import { Tooltip } from 'reactstrap';
import MeetingProcedureUpdateForm from "../update/meetingProcedureUpdateForm";

export default class VehiclesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };
    constructor(props){
        super(props);
        this.tableRef = React.createRef();
    }

    state = {
        modalCreateUpdate: false,
        editProcedure: false,
        overwriteData: [],
        showCopyAlert: null,
    };

    setOpenCloseModal = (value) => {
        if (!value)
            this.setState({ editProcedure: false, modalCreateUpdate: value, overwriteData: [] });
        else
            this.setState({ modalCreateUpdate: value });
    };

    createProcedure = (values) => {
        if(this.state.overwriteData && this.state.overwriteData.length > 0)
            values.confirmOverwrite = true;
        this.props.create(values, () => {
            this.setOpenCloseModal(false);
        }).catch((data) => {
            if (data && data.foundDocuments && data.foundDocuments.length > 0){
                this.setState({ overwriteData: data.foundDocuments });
            }
        });
    };

    updateProcedure = (values) => {
        const { overwriteData } = this.state;
        const { update } = this.props;
        if (overwriteData && overwriteData.length > 0)
            values.confirmOverwrite = true;

        update(values, () => {
            this.setOpenCloseModal(false);
        }).catch((data) => {
            if (data && data.foundDocuments && data.foundDocuments.length > 0) {
                this.setState({ overwriteData: data.foundDocuments });
            }
        });
    };

    componentDidMount() {
        this.props.find();
        if (this.tableRef && this.tableRef.current)
            this.tableRef.current.dataManager.changePageSize(10);
    }

    loadForm = (id) => {
        this.props.load2Update(id);
        this.setState({ editProcedure: true });
        this.setOpenCloseModal(true);
    };

    submitFormProcedure = (values) => {
        if (this.state.editProcedure)
            this.updateProcedure(values);
        else
            this.createProcedure(values);
    };

    copyInstructions = (id, text) => {
        this.setState({ showCopyAlert: id });
        navigator.clipboard.writeText(text);
        setTimeout(() => {
            this.setState({ showCopyAlert: null });
        }, 1000);
    };

    render() {
        const { data, create, loading, destroy, page, find, setSearch, userInfo: { permission } } = this.props;
        const { modalCreateUpdate, overwriteData } = this.state;

        return (
            <div className="row">
                <Modal
                    open={modalCreateUpdate}
                    onClose={() => this.setOpenCloseModal(false)}
                >
                    <div
                        className={`${overwriteData && overwriteData.length > 0 ? 'd-flex flex-wrap justify-content-center' : 'd-none'}`}
                        style={{ color: "#707070" }}
                    >
                        <div className="col-12 col-md-10 my-5">
                            {overwriteData.length === 1 ? (
                                <h2 className="bold text-left">
                                    A {overwriteData[0].type} claim meeting procedure already exists at {overwriteData[0].airport.code} in a {overwriteData[0].vehicle.type}. Would you like to overwrite the existing data?
                                </h2>
                            ) : (
                                <React.Fragment>
                                    <h2 className="bold text-left">The following claims meeting procedures already exist. Would you like to overwrite the existing data?</h2>
                                    <ul>
                                        {overwriteData.map((item) => (
                                            <li key={item._id}>
                                                <h4>{item.airline} - {item.airport.code} - {item.vehicle.type} - {item.type}</h4>
                                            </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                            )}
                            <div className="mt-4 d-flex flex-column flex-sm-row justify-content-sm-end">
                                <button
                                    type="button"
                                    className="btn btn-light border mr-0 mb-2 mb-sm-0 mr-sm-2"
                                    onClick={() => this.setState({ overwriteData: [] })}
                                >
                                    CANCEL
                                </button>
                                <button
                                    type="submit"
                                    form="meetingForm"
                                    className="btn btn-primary"
                                >
                                    SAVE
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`${overwriteData && overwriteData.length > 0 ? 'd-none' : 'd-flex flex-wrap justify-content-center'}`}
                        style={{ color: "#707070" }}
                    >
                        <div className="col-12 mt-5">
                            <h2 className="bold">{this.state.editProcedure ? 'EDIT' : 'NEW'} MEETING PROCEDURE</h2>
                        </div>
                        <div className="col-12 col-md-10 col-lg-8 col-xl-6 mt-5">
                            <LoadMask loading={loading} blur>
                                {this.state.editProcedure ? (
                                    <MeetingProcedureUpdateForm
                                        onSubmit={this.submitFormProcedure}
                                        cancel={() => this.setOpenCloseModal(false)}
                                    />
                                ) : (
                                    <MeetingForm
                                        onSubmit={this.submitFormProcedure}
                                        cancel={() => this.setOpenCloseModal(false)}
                                    />
                                )}
                            </LoadMask>
                        </div>
                    </div>
                </Modal>
                <div className="col-sm-12 pt-2">
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-primary my-2"
                            onClick={() => this.setOpenCloseModal(true)}
                        >
                            ADD
                        </button>
                    </div>
                    <div className="grid-container material-livery-list">
                        <Grid
                            tableRef={this.tableRef}
                            title="MEETING PROCEDURES"
                            columns={[
                                {
                                    title: "Name",
                                    field: "_id.name",
                                    width: "15%",
                                    render: (row) =>
                                        row._id && row._id.name
                                            ? `${row._id.name}`
                                            : "---",
                                },
                                {
                                    title: "Airport Code",
                                    field: "airport.code",
                                    width: "15%",
                                    searchable: false,
                                },
                                {
                                    title: "Type",
                                    field: "_id.type",
                                    width: "15%",
                                    searchable: false,
                                },
                                {
                                    title: "Instructions",
                                    field: "_id.instructions",
                                    width: "30%",
                                    searchable: false,
                                    render: (row) => (
                                        <div
                                            className="text-truncate"
                                            style={{ maxWidth: "300px", width: "100%" }}
                                        >
                                            {row._id.instructions}
                                        </div>
                                    ),
                                },
                                {
                                    title: "Actions",
                                    field: "id",
                                    align: "center",
                                    width: "10%",
                                    searchable: false,
                                    render: (row) => {
                                        return (
                                            <div className="d-flex">
                                                <Tooltip
                                                    placement="left"
                                                    isOpen={this.state.showCopyAlert != null && JSON.stringify(this.state.showCopyAlert) === JSON.stringify(row.id)}
                                                    target={`copyBtn${row.id}`}
                                                    toggle={() => {}}
                                                >
                                                    Copied to clipboard
                                                </Tooltip>
                                                <button
                                                    type="button"
                                                    id={`copyBtn${row.id}`}
                                                    onClick={() => this.copyInstructions(row.id, row._id.instructions)}
                                                    className="btn btn-secondary p-0 mx-1"
                                                    style={{ background: "#333f48" }}
                                                >
                                                    <div style={{ width: '22px', height: '22px' }}>
                                                        <IconCopy className="w-100 h-100 p-1" />
                                                    </div>
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => this.loadForm(row.id)}
                                                    className="btn btn-secondary p-0 mx-1"
                                                    style={{ background: "#333f48" }}
                                                >
                                                    <div style={{ width: '22px', height: '22px' }}>
                                                        <IconEdit className="w-100 h-100 p-1" />
                                                    </div>
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    onClick={() => destroy(row.id)}
                                                    className="btn btn-secondary p-0 mx-1"
                                                    style={{ background: "#333f48" }}
                                                >
                                                    <div style={{ width: '22px', height: '22px' }}>
                                                        <IconTrashAlt className="w-100 h-100 p-1" />
                                                    </div>
                                                </button> */}
                                            </div>
                                        );
                                    },
                                },
                            ]}
                            data={data.data}
                            onSearchChange={setSearch}
                            onPageChange={(p) => find(p + 1)}
                            isLoading={loading}
                            page={page - 1}
                            totalCount={data.total}
                            options={{
                                pageSizeOptions: [],
                                filtering: false,
                                idSynonym: "id",
                                searchText: "Search by code",
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                headerStyle: {
                                    backgroundColor: "#D50032",
                                    color: "#FFF",
                                    fontSize: "1rem",
                                },
                                cellStyle: {
                                    fontSize: "1rem",
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
