import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators, combine } from "validate-redux-form";
import { renderField } from "../Utils/renderField";

const ResetPassForm = (props) => {
    const { handleSubmit } = props;
    return (
        <form
            className="form-validate mb-lg d-flex flex-column"
            onSubmit={handleSubmit}
        >
            <div className="form-group has-feedback flex-1">
                <Field
                    name="email"
                    component={renderField}
                    type="email"
                    className="form-control"
                    placeholder="email"
                />
            </div>
            <button
                type="submit"
                className="btn btn-primary m-1 align-self-start flex-1"
            >
                Send
            </button>
        </form>
    );
};

export default reduxForm({
    form: "resetPassword", // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            email: combine(
                validators.exists()("Required field"),
                validators.regex(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)("Format incorrect")
            )
        });
    }
})(ResetPassForm);
