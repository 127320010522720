import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderFilePicker,
    renderSearchSelect,
    renderUnstyledFilePicker,
} from "../../Utils/renderField/renderField";


const Form = (props) => {
    const { handleSubmit, getAirlines } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group mt-5">
                <div className="form-group">
                    <label className="bold"> Select Airline </label>
                    <Field
                        allKey
                        name='airline'
                        valueKey="_id"
                        labelKey="label"
                        component={renderSearchSelect}
                        label="Airline"
                        loadOptions={getAirlines}
                        placeholder="Select airline"
                    />
                </div>
                <div className="form-group">
                    <Field
                        name="file"
                        component={renderUnstyledFilePicker}
                        accept = ".csv, text/html, .xls, .xlsx, .html"
                        stringBinary={true}
                        defaultButtonType="submit"
                        classNameSubmit=""
                        classNameLabel="mw-100 w-100"
                        classNameContainer="w-100 d-flex"
                        className="w-100"
                        label="Select a file"
                    />
                </div>
            </div>
        </form>
    );
};

const ScheduleForm = reduxForm({
    form: "schedule",
    validate: (data) => {
        return validate(data, {
            airline: validators.exists()("Required field"),
            vehicle: validators.exists()("Required field"),
            file: validators.exists()("Required field"),
        });
    },
})(Form);

export default ScheduleForm;
