
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


const GridSpecialRates = ({
    data = [],
    columns = [],
    loading = false,
    openModalSpecialRates,
}) => {

    const [Page, setPage] = useState(1);

    return <BootstrapTable
        keyField='code'
        data={ data } columns={ columns }
        bootstrap4={true}
        loading={loading}
        condensed={true}
        rowClasses="withOutBorder"
        rowEvents={{
            onClick: (e, row, rowIndex) => {
                let index = ((Page - 1) * 5) + rowIndex;
                openModalSpecialRates(index);
            }
        }}
        pagination={paginationFactory({
            sizePerPage: 5,
            page:Page,
            sizePerPageRenderer: () => false,
            onPageChange: (page, sizePerPage) => setPage(page)
        })}
    />
}

export default GridSpecialRates;
