import moment from 'moment';
import React, { Fragment } from 'react';
const { MEDIA_URL } = process.env;


const TripInfo = ({ itinerary }) => {

    return (
        <Fragment>
            <div className="d-flex flex-column flex-md-row justify-content-between mt-4">
                <div className="flex-1 d-flex">
                    <div className="flex-1 m-2 text-secondary h6">
                        {moment(itinerary.puTimeNoTimezone).format('ddd, MMMM DD, YYYY')}
                    </div>
                </div>
                <div className="flex-1 d-flex">
                    <div className="d-flex flex-1 m-2 text-secondary h6">
                        <div className="mr-2">
                            Time:
                        </div>
                        <div className="flex-1">
                            {moment(itinerary.puTimeNoTimezone).format("(HH:mm) LT")}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="flex-1 d-flex">
                    <div className="d-flex flex-1 m-2 text-secondary h6">
                        <div className="mr-2">
                            From:
                        </div>
                        <div className="flex-1">
                            {(itinerary.puAddress)}
                        </div>
                    </div>
                </div>
                <div className="flex-1 d-flex">
                    <div className="d-flex flex-1 m-2 text-secondary h6">
                        <div className="mr-2">
                            To:
                        </div>
                        <div className="flex-1">
                            {(itinerary.doAddress)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-between">
                <div className="flex-1 d-flex">
                    <div className="flex-1 m-2 text-secondary h6">
                        <span className="mr-2">Duration:</span> { itinerary.duration }
                    </div>
                </div>
                <div className="flex-1 d-flex">
                    <div className="flex-1 m-2 text-secondary h6">
                        <span className="mr2">Distance:</span> { itinerary.distance } miles
                    </div>
                </div>
            </div>

            {itinerary.flightNumber && (
                <div className="d-flex flex-column flex-md-row justify-content-between">
                    <div className="flex-1 d-flex">
                        <div className="flex-1 m-2 text-secondary h6">
                            <span>Flight:</span> {itinerary.flightNumber.label}
                        </div>
                    </div>
                </div>
            )}

            <div className="d-flex flex-column flex-md-row justify-content-between shadow my-5" style={{ border: "1px solid #757575" }}>
                <div className="flex-1 d-flex flex-column flex-sm-row">
                    <div className="flex-1 px-3 py-1">
                        {itinerary.vehiclePhoto && (
                            <img className="img-fluid mx-2 mh-100" src={`${MEDIA_URL}${itinerary.vehiclePhoto}`} />
                        )}
                    </div>
                    <div className="flex-2 mx-2 my-3 d-flex flex-column justify-content-center">
                        <label className="text-secondary h6">
                            {itinerary.vehicleDescription}
                        </label>
                        {itinerary.vehicleCapacity !== undefined && (
                            <label className="text-secondary h6">
                                Passengers: {itinerary.vehicleCapacity}
                            </label>
                        )}
                    </div>
                </div>
            </div>

            <div className="break m-4" />
        </Fragment>
    );
};

export default TripInfo;

