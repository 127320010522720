import { connect } from "react-redux";
import VehiclesGrid from "./VehiclesGrid";
import { find, destroy, setSearch, getVehicleLocation, applyProfile, getLocationsVehicle, clearDataModalVehicleLocation } from "../../../../redux/modules/vehicles";

const mstp = (state) => ({
    ...state.vehicles,
    vehicleLocationFormValues: state.form.vehicleLocationForm,
    userInfo: state.login.userInfo
})

const mdtp = { find, destroy, setSearch, getVehicleLocation, applyProfile, getLocationsVehicle, clearDataModalVehicleLocation };

export default connect(mstp, mdtp)(VehiclesGrid);
