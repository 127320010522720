import React from 'react';
import { connect } from 'react-redux';
import swal from 'sweetalert2';
import { getLogs, setModalLogs, cancelTripLivery, setView } from '../../../../redux/modules/liveryEdition';


const ButtonsLiveryTrip = (props) => {

    const cancelTrip = (id) => {
        swal.fire({
            title: "Are you sure?",
            text: "You cannot undo this action!",
            type: "warning",
            input: "textarea",
            inputPlaceholder: 'Cancellation Reason',
            inputAttributes: {
                'aria-label': 'Cancellation Reason'
            },
            showCancelButton: true,
            confirmButtonText: "Yes, cancel it!",
            cancelButtonText: "No",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            reverseButtons: true,
        }).then((result) => {
            if (!result.dismiss) {
                props.cancelTripLivery(result.value, ()=>props.setView(1));
            }
        });
    }

    const openModalLogs = (id) => {
        props.getLogs(id);
        props.setModalLogs(true);
    }

    return (
        <div className="col-12">
            {props.singleDetail && props.singleDetail.liveryDBData && props.singleDetail.liveryDBData.sTripId && (
                <div className="flex-1 d-flex">
                    {!['Canceled', 'Done', 'Late Cancel'].includes(props.singleDetail.TripStatusDesc.trim()) &&  (
                        <button className="btn btn-dark px-3 mr-1 my-1" onClick={()=>props.cancelTrip ? props.cancelTrip() : cancelTrip()}
                            style={{ background: '#333F48' }}
                        >
                            <strong>CANCEL</strong> <strong className="d-none d-md-inline">TRIP</strong>
                        </button>
                    )}
                    <button className="btn btn-light px-3 ml-1 my-1" onClick={()=>openModalLogs(props.singleDetail.liveryDBData._id)}
                        style={{ background: '#DBDBDB' }}
                    >
                        <strong className="d-none d-md-inline">TRIP</strong> <strong>LOGS</strong>
                    </button>
                </div>
            )}
        </div>
    )
}


const mstp = state => ({
    singleDetail: state.liveryEdition.singleDetail,
})

const mdtp = {
    cancelTripLivery: cancelTripLivery,
    getLogs: getLogs,
    setModalLogs: setModalLogs,
    setView: setView,
}

export default connect(mstp, mdtp)(ButtonsLiveryTrip)
