import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField } from "../../../../Utils/renderField/renderField";


const Form = ({
    handleSubmit,
    cancel,
 }) => (
    <form onSubmit={handleSubmit}>
        <div className="form-group grid-container">
            <div className="grid-titulo padding-15">
                <div className="padding-15 p-sm-0 py-sm-1">
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="acct_name"> Name* </label>
                            <Field
                                name="acct_name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row">
                                <button className="btn btn-primary m-1 px-4" type="submit">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
);

const AccountForm = reduxForm({
    form: "accountForm",
    validate: (data, props) =>{
        const errors = {};
        if(!data.acct_name) errors.acct_name = "Required field";
        return errors;
    }
})(Form);

export default AccountForm;
