import React from "react";
import { connect } from "react-redux";

import Detail from "./Detail";
import { getTourResrv } from "../../../../redux/modules/tourReservations";

class DetailContainer extends React.Component {
    componentDidMount() {
        this.props.getTourResrv(this.props.match.params.id);
    }

    render() {
        return <Detail tourResrv={this.props.tourResrv} />;
    }
}

const mstp = (state) => ({
    tourResrv: state.tourReservations.tourResrv,
});

const mdtp = { getTourResrv };

export default connect(mstp, mdtp)(DetailContainer);
