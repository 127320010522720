import React from "react";
import { Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const options = {
    position: "bottom center",
    timeout: 5000,
    offset: "30px",
    transition: "scale",
};

const AlertProvider = (props) => (
    <Provider template={AlertTemplate} {...options}>
        {props.children}
    </Provider>
);

export default AlertProvider;
