import React from "react";
import { Link } from "react-router-dom";

const GenericNav = ({ items }) => {
    return (
        <div className="d-flex flex-wrap justify-content-center">
            <div className="col-12 col-xl-10 d-flex flex-wrap">
                {items.map(({ to, label, icon, show }) => {
                    if (!show) return null;
                    return (
                        <div
                            className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3"
                            key={label}
                        >
                            <Link
                                to={to}
                                style={{ color: "#000" }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: "2.5rem" }}>{icon}</div>
                                <span className="mt-3">{label}</span>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default GenericNav;
