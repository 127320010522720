import React, {useState, useRef} from 'react';
import JoditEditor from "jodit-react";
import classNames from 'classnames';

export const Editor = ({className, input, editor, meta: { touched, error },}) => {
    // const editor = useRef(null);

    const config = {
        readonly: false,
        uploader: {
            "insertImageAsBase64URI": true
        },
        askBeforePasteHTML: false,
        askBeforePasteFromWord: false,
        defaultActionOnPaste: "insert_only_text",
        disablePlugins: "addnewline,clipboard",
        buttons: "bold,strikethrough,underline,italic,eraser,|,|,ul,ol,|,fontsize,brush,paragraph,,image,file,table,link,|,align,|,hr,fullsize"
    };
    const invalid = touched && error;
    return (
        <div className={classNames({ "is-invalid": invalid })}>
            <JoditEditor
                ref={editor}
                value={input.value}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={newContent => input.onChange(newContent)}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};
