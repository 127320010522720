import React from "react";
import Form from "./UserForm";
import LoadMask from '../../Utils/LoadMask';

export default class Create extends React.Component {
    render() {
        const { create, getRoles, userInfo: { superUser } } = this.props;

        return (!superUser
          ? <div className="d-flex w-100 justify-content-center align-items-center">
                <label> You are not a superuser </label>
            </div>
          : <div className="row">
                <div className="col-12">
                    <LoadMask loading = { this.props.loading } dark blur>
                        <Form
                            onSubmit={create}
                            getRoles={getRoles}
                        />
                    </LoadMask>
                </div>
            </div>
        );
    }
}
