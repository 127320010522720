import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import "./acciones.css";
import Swal from "sweetalert2";

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.state = { redirect: false, url: "" };
        this.redirect = this.redirect.bind(this);
        this.eliminar = this.eliminar.bind(this);
        this.editar = this.editar.bind(this);
    }
    redirect(url) {
        return () => {
            this.setState({ url });
            this.setState({ redirect: true });
        };
    }
    eliminar(id) {
        return () => {
            Swal({
                title: "Are you sure?",
                text: "You cannot undo this action!",
                type: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel",
                confirmButtonColor: "#D50032",
                cancelButtonColor: "#545b62",
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        };
    }
    editar(id) {
        return () => {
            this.props.editar(id);
        };
    }

    render() {
        const { id, ver, editar, eliminar, adicional, row } = this.props;
        if (this.state.redirect) {
            return <Redirect to={`${this.state.url}/${id}`} />;
        }
        let offset = 0;
        if (ver !== undefined) {
            offset += 1;
        }
        if (editar !== undefined) {
            offset += 1;
        }
        if (eliminar !== undefined) {
            offset += 1;
        }
        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    {adicional !== undefined && adicional(id, row)}
                    {ver !== undefined && (
                        <Link to={`${ver}/${id}/`}>
                            <img
                                className="action-img"
                                title="Watch"
                                src={require("../../../../../assets/img/icons/watch.png")}
                                alt="Watch"
                            />
                        </Link>
                    )}
                    {editar !== undefined && typeof editar === "string" && (
                        <Link to={`${editar}/${id}/`}>
                            <img
                                className="action-img"
                                title="Edit"
                                src={require("../../../../../assets/img/icons/edit.png")}
                                alt="Edit"
                            />
                        </Link>
                    )}
                    {editar !== undefined && typeof editar !== "string" && (
                        <img
                            onClick={this.editar(id)}
                            className="action-img"
                            title="Edit"
                            src={require("../../../../../assets/img/icons/edit.png")}
                            alt="Edit"
                        />
                    )}
                    {eliminar !== undefined && (
                        <img
                            onClick={this.eliminar(id)}
                            className="action-img"
                            title="Delete"
                            src={require("../../../../../assets/img/icons/delete.png")}
                            alt="Delete"
                        />
                    )}
                </div>
            </div>
        );
    }
}
Acciones.propTypes = {};

export function activeFormatter(actions) {
    return (cell, row) => {
        return <Acciones id={cell} row={row} {...actions} />;
    };
}
