import React, { Component, Fragment } from "react";
import classNames from "classnames";
import $ from "jquery";

import "../BootStrapDatePicker/bootstrap-datepicker.min";
import "../BootStrapDatePicker/bootstrap-datepicker.min.css";

class RenderBSDP extends Component {
    componentDidMount() {
        const { input } = this.props;

        $(".datepicker").datepicker({
            format: "mm/dd/yyyy",
            startDate: "-3d",
            multidate: true,
        });

        $(".datepicker")
            .datepicker()
            .on("hide", ({ dates }) => {
                input.onChange(dates);
            });
    }

    render() {
        const {
            meta: { touched, error },
        } = this.props;
        const invalid = touched && error;

        return (
            <Fragment>
                <input
                    className={classNames("form-control datepicker", {
                        "is-invalid": invalid,
                    })}
                />
                {invalid && <div className="invalid-feedback">{error}</div>}
            </Fragment>
        );
    }
}

export default RenderBSDP;
