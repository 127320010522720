import React, { Fragment } from "react";

import { activeFormatter } from "../../Utils/Acciones/Acciones";

const reloadSvg = require("../../../../../assets/img/icons/redo.svg");

const RowActions = (resendEmail, destroyReserv) => (cell, row) => {
    const handleOnResendClick = () => resendEmail(cell, row);

    return (
        <Fragment>
            <div className="row justify-content-center align-content-center">
                <img
                    className="action-img"
                    title="Resend Email"
                    onClick={handleOnResendClick}
                    src={reloadSvg}
                    alt="resend-email"
                />
                {activeFormatter({
                    ver: "/reservations/charter/bookings/detail",
                    eliminar: destroyReserv,
                })(cell, row)}
            </div>
        </Fragment>
    );
};

export default RowActions;
