import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IconCalendar, IconComment, IconMoneyCheck } from "../../icons/icons";
import IconStarHalfStroke from "../../icons/star-sharp-half-stroke";
import { Icons } from "../../icons/menu";
import { reservationsCharterAirportMeetingProcedures, reservationsCharterBookings, reservationsCharterQuotes, reservationsCharterReviews } from "../../../routesConfiguration/paths";

class Container extends React.Component {

    render() {
        const { userInfo: { permission }, } = this.props;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                <div className="col-12 col-xl-10 d-flex flex-wrap">
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={reservationsCharterQuotes}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconMoneyCheck className="h-100" />
                            </div>
                            <span className="mt-3">Quotes</span>
                        </Link>
                    </div>
                    {permission && permission.find(({ reservations }) => reservations !== 'none') && (
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link to={reservationsCharterBookings}
                                style={{ color: '#000' }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: '2.5rem' }}>
                                    <IconCalendar className="h-100" />
                                </div>
                                <span className="mt-3">Bookings</span>
                            </Link>
                        </div>
                    )}
                    {permission && permission.find(({ reviews }) => reviews !== 'none') && (
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link to={reservationsCharterReviews}
                                style={{ color: '#000' }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: '2.5rem' }}>
                                    <IconStarHalfStroke className="h-100" />
                                </div>
                                <span className="mt-3">Reviews</span>
                            </Link>
                        </div>
                    )}
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={reservationsCharterAirportMeetingProcedures}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <Icons.IconUsers className="h-100" />
                            </div>
                            <span className="mt-3 text-center" style={{ fontSize: '13px' }}>Airport Meeting Procedures</span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mstp, null)(Container);
