import React from "react";
import { Field } from "redux-form";
import { Button } from "reactstrap";
import moment from "moment";
import classNames from "classnames";

import { renderPlacesAutocomplete } from "../Utils/renderField/renderField";

import renderTimePicker from "../../../TimePicker";

import { NoteModal } from "./NoteModal";
import IconPlus from "../../icons/plus";
import { IconCircleMinus } from "../../icons/icons";

export const stopNames = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelfth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
];

export const Stops = ({
    fields,
    schedule,
    form = "",
    showTime = true,
    inModal = false,
    disabled = false,
    extraDetails = () => {},
    editTrip = false,
    change = () => {},
}) => {
    const setDetails = (details, { index, stop }) => {
        const currentStop = fields.get(index);
        const newData = { ...currentStop, ...details };

        const changeValues = (name, data) =>
            new Promise((resolve) => {
                change(name, data);
                resolve();
            });
        changeValues(stop, newData).then(() => extraDetails());
    };

    return fields.map((stop, index) => {
        const currentStop = fields.get(index);
        let time = currentStop.time || null;
        if (showTime && time) {
            if (!["00", "15", "30", "45"].includes(time.minutes)) {
                const minutes = parseInt(time.minutes);
                time.minutes =
                    minutes < 15
                        ? "00"
                        : minutes < 30
                        ? "15"
                        : minutes < 45
                        ? "30"
                        : "45";
            }
            time = moment(
                `${
                    schedule
                        ? moment(schedule).format("YYYY-MM-DD")
                        : moment().format("YYYY-MM-DD")
                } ${time.hour}:${time.minutes}`
            );
        }

        return (
            <div className="mr-2 mb-2" key={stop}>
                <div className="d-flex w-100 align-items-center justify-content-end my-n2">
                    <Button
                        type="button"
                        color="link"
                        onClick={() => {
                            fields.remove(index);
                            extraDetails();
                        }}
                        disabled={disabled}
                        className={classNames(
                            "p-0 d-flex justify-content-end col-1",
                            {
                                "mr-2": inModal,
                            }
                        )}
                    >
                        <IconCircleMinus className="smallIcon p-1 text-danger" />
                    </Button>
                </div>
                <div className="d-flex w-100">
                    <div
                        className={classNames("d-flex p-0 m-0", {
                            "form-group": !editTrip,
                            "flex-2": editTrip,
                            "col-12": !showTime,
                            "col-lg-8 col-7": showTime,
                        })}
                    >
                        <Field
                            id={`toAutocomplete-${form}-${stop}`}
                            name={`${stop}.stop`}
                            component={renderPlacesAutocomplete}
                            Value={currentStop.stop}
                            additionalAddressInfo={["street_number", "route"]}
                            setDetails={setDetails}
                            lat={currentStop.lat}
                            lng={currentStop.lng}
                            inputLabel={currentStop.label}
                            pl={{ index, stop }}
                            label={
                                stopNames[index]
                                    ? `${stopNames[index]} stop`
                                    : `#${index + 1} stop`
                            }
                            placeholder="To: address, airport, hotel..."
                            hasNoteModal
                            noteModal={
                                <NoteModal
                                    id={`note-modal-${index}`}
                                    name={`${stop}.notes`}
                                    noteValue={currentStop.notes}
                                    disabled={disabled}
                                />
                            }
                            inModal={inModal}
                            disabled={disabled}
                        />
                    </div>
                    {showTime ? (
                        <div
                            className={classNames("col d-flex p-0 m-0", {
                                "form-group": !editTrip,
                                "mx-3 my-2": editTrip,
                            })}
                            style={editTrip ? { height: "fit-content" } : {}}
                        >
                            {!editTrip ? <label> Pick-up time </label> : null}
                            <Field
                                id={`timepicker-${form}-${index}`}
                                name={`${stop}.time`}
                                placeholder="12:00 PM"
                                component={renderTimePicker}
                                clearOnDelete
                                Value={time}
                                className="pt-4 pl-2"
                                style={{ height: "none" }}
                                inModal={inModal}
                                disabled={disabled}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    });
};

export const validateStops = ({
    stops,
    schedule,
    validateTime = true,
    duration,
    time,
}) => {
    if (!stops || stops.length < 1) return {};
    const errors = stops.map((stop, index, array) => {
        const stopErrors = {};
        if (!stop.stop) stopErrors.stop = "Required Field";
        else {
            const exact =
                stop.state &&
                stop.city &&
                stop.lat &&
                stop.lng &&
                stop.postal_code;
            if (!exact) stopErrors.stop = "Please select an exact address";
        }

        if (!validateTime || !stop.time) return stopErrors;
        if (
            schedule &&
            moment(schedule).isSame(moment(), "day") &&
            moment(schedule)
                .set({
                    hour: stop.time.hour,
                    minute: stop.time.minutes,
                    second: "0",
                })
                .isBefore(moment())
        ) {
            stopErrors.time = "Invalid Time";
        } else if (duration && schedule && time) {
            const currentTime = moment(schedule).set({
                hour: stop.time.hour,
                minute: stop.time.minutes,
                second: "0",
            });

            const start = moment(schedule).set({
                hour: time.hour,
                minute: time.minutes,
                second: "0",
            });

            const end = start.clone().add(Number(duration.value), "hours");

            if (!currentTime.isBetween(start, end))
                stopErrors.time = "This time is out of range";
            else if (index > 0 && array[index - 1] && array[index - 1].time) {
                const beforeTime = moment(schedule).set({
                    hour: array[index - 1].time.hour,
                    minute: array[index - 1].time.minutes,
                    second: "0",
                });

                if (!beforeTime.isBefore(currentTime))
                    stopErrors.time = "This time should be after previous stop";
            }
        }

        return stopErrors;
    });

    return errors;
};

export const ButtonAddStop = ({
    array,
    fieldName,
    className = "",
    disabled = false,
}) => {
    return (
        <div
            className={`d-flex align-items-center justify-content-center mb-2 ${className}`}
        >
            <Button
                type="button"
                color="link"
                onClick={() => {
                    array.push(fieldName, {});
                }}
                style={{
                    fontSize: "0.8rem",
                    color: "#d50032",
                }}
                disabled={disabled}
            >
                <IconPlus className="smallIcon p-1 mx-1 text-white rounded-circle btn-primary" />
                Add stop
            </Button>
        </div>
    );
};
