import { connect } from "react-redux";
import Create from "./PromoCodeCreate";
import { create } from "../../../../redux/modules/promoCodes";

const mdtp = { create };

const mstp = (state) => ({
    userInfo: state.login.userInfo,
})

export default connect(mstp, mdtp)(Create);
