import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators, combine } from "validate-redux-form";

import { stateOptions } from "../../utility/constants";
import {
    renderField,
    renderSelectField,
} from "../Utils/renderField/renderField";
import { settingsAccountsCompanies } from "../../../routesConfiguration/paths";

const Form = (props) => {
    const { handleSubmit, isVerified, rejectionReason } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2 className="mb-3">
                        <strong>Company</strong>
                    </h2>

                    <div className="padding-15 p-sm-0 py-sm-1">
                        <div className="row">
                            <div className="form-group col-12">
                                <label htmlFor="companyName">
                                    Company Name
                                </label>
                                <Field
                                    name="companyName"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-12 col-md-6">
                                <label htmlFor="email">Email</label>
                                <Field
                                    name="email"
                                    component={renderField}
                                    type="email"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-12 col-md-6">
                                <label htmlFor="email">Main Phone</label>
                                <Field
                                    name="mobileNo"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-12">
                                <label htmlFor="street">Address</label>
                                <Field
                                    name="street"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-12 col-md-6">
                                <label htmlFor="street2">Address Line 2</label>
                                <Field
                                    name="street2"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-12 col-md-6">
                                <label htmlFor="city">City</label>
                                <Field
                                    name="city"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="form-group col-12 col-md-6">
                                <label htmlFor="state">State</label>
                                <Field
                                    name="state"
                                    component={renderSelectField}
                                    type="text"
                                    className="form-control p-0 no-border"
                                    labelKey="label"
                                    valueKey="value"
                                    options={stateOptions}
                                />
                            </div>

                            <div className="form-group col-12 col-md-6">
                                <label htmlFor="zip">Zip Code</label>
                                <Field
                                    name="zip"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        {isVerified && rejectionReason && (
                            <div className="row">
                                <div className="form-group col-12 col-md-6">
                                    <label htmlFor="rejectionReason">
                                        Reason of rejection
                                    </label>
                                    <Field
                                        name="rejectionReason"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link
                                        className="btn btn-secondary m-1"
                                        to={settingsAccountsCompanies}
                                    >
                                        Cancel
                                    </Link>
                                    <button
                                        type="submit"
                                        className="btn btn-primary m-1"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const CompanyForm = reduxForm({
    form: "client",
    validate: (data) =>
        validate(data, {
            firstName: validators.exists()("Required field"),
            lastName: validators.exists()("Required field"),
            mobileNo: validators.exists()("Required field"),
            email: validators.exists()("Required field"),
            street: validators.exists()("Required field"),
            street2: validators.exists()("Required field"),
            city: validators.exists()("Required field"),
            state: validators.exists()("Required field"),
            zip: validators.exists()("Required field"),
        }),
})(Form);

export default CompanyForm;
