import React from "react";
import { connect } from "react-redux";
import LoadMask from "../Utils/LoadMask";
import TripForm from './tripForm';


const DetailTrip = ({
    loading,
    handleSubmit,
    submitEdit,
    data,
    from,
    to,
    reservation,
    no_flight_information,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
    googleMapsError,
    history,
}) => (
    <div className="row align-self-center">
        <div className="col-sm-12 pt-2 my-5">
            <div className="grid-container">
                <LoadMask loading={loading} dark blur>
                    {data && Object.keys(data).length
                        ? data.notFound
                            ? <div className="my-5 py-5">
                                <h3> No reservation found with confirmation number! </h3>
                            </div>
                            : !googleMapsError && <TripForm
                                    history={history}
                                    data={data}
                                    onSubmit={submitEdit}
                                    to={to && to.lat ? to : {}}
                                    from={from && from.lat ? from : {}}
                                    reservation={reservation}
                                    no_flight_information={no_flight_information}
                                    onSelectAirline={onSelectAirline}
                                    getFlightInfo={getFlightInfo}
                                    onSelectFlight={onSelectFlight}
                                />
                        : ''
                    }
                </LoadMask>
            </div>
        </div>
    </div>
);

export default DetailTrip;
