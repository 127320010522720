import React from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import GridPassenger from "./gridPassenger";
import GridBooking from "./gridBooking";
import GridService from "./gridService";

class ReviewsGrid extends React.Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired,
        destroyReview: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { type, find } = this.props;
        find(undefined, type);
    }

    componentDidUpdate(prevProps) {
        const { userInfo, find, type } = this.props;
        if (prevProps.userInfo && userInfo) {
            const { permission } = userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if (
                oldPermission &&
                permission &&
                JSON.stringify(oldPermission) !== JSON.stringify(permission)
            ) {
                if (permission.find(({ readReviews }) => readReviews))
                    find(undefined, type);
            }
        }
    }

    render() {
        const {
            dataPassenger,
            dataService,
            dataBooking,
            loading,
            destroyReview,
            find,
            userInfo: { permission },
            pagepassenger,
            pageBooking,
            pageService,
            type,
        } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container">
                        <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <h1 className="">
                                <strong>Reviews</strong>
                            </h1>
                        </div>
                        <br />
                        {permission &&
                            permission.find(
                                ({ readReviews }) => !!readReviews
                            ) && (
                                <React.Fragment>
                                    {type === 0 && (
                                        <GridPassenger
                                            data={dataPassenger}
                                            loading={loading}
                                            destroyReview={(id) =>
                                                destroyReview(type, id)
                                            }
                                            find={(page) => find(page, type)}
                                            type={type}
                                            page={pagepassenger}
                                            permission={permission}
                                        />
                                    )}
                                    {type === 1 && (
                                        <GridBooking
                                            data={dataBooking}
                                            loading={loading}
                                            destroyReview={(id) =>
                                                destroyReview(type, id)
                                            }
                                            find={(page) => find(page, type)}
                                            type={type}
                                            page={pageBooking}
                                            permission={permission}
                                        />
                                    )}
                                    {type === 2 && (
                                        <GridService
                                            data={dataService}
                                            loading={loading}
                                            destroyReview={(id) =>
                                                destroyReview(type, id)
                                            }
                                            find={(page) => find(page, type)}
                                            type={type}
                                            page={pageService}
                                            permission={permission}
                                        />
                                    )}
                                </React.Fragment>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "reviewsGridFilter",
    destroyOnUnmount: false,
})(ReviewsGrid);
