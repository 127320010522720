import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/crewAdmin/crewRoutes';
import { getAirline } from '../../../../../../../redux/modules/crewAdmin/airlines';
import { useLocation } from "react-router-dom";
import IconAngleLeft from '../../../../../../icons/angleLeftIcon';
import { change } from 'redux-form';
import LoadMask from '../../../../../Utils/LoadMask';
import RouteForm from './routeForm';


class Container extends React.Component {

    cancel = () => {
        this.props.cleanForm()
        this.props.history.goBack();
    }

    onSubmit = (values) => {
        values.airline = this.props.match.params.airlineId;
        this.props.create(values).then(result =>{
            if(result) this.cancel();
        });
    }

    componentDidMount() {
        if (!this.props.airline) {
            this.props.getAirline(this.props.match.params.airlineId);
        }
    }

    render(){

        const { history, loading, item, loadingForm } = this.props;

        return (
            <div className="h-100 crewContainer px-3">
                <div className="d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>this.cancel()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="flex-1 d-flex flex-wrap justify-content-center">
                        <div className="col-12 col-xl-7 my-2 mt-xl-4 pt-xl-2">
                            <div className="rounded shadow border border-dark">
                                <div className="d-flex flex-wrap pt-3 px-3">
                                    <div className="col-12 mb-3">
                                        <h3 className="text-left">Route</h3>
                                    </div>
                                    <LoadMask loading={loadingForm} blur>
                                        <RouteForm
                                            onSubmit={this.onSubmit}
                                            cancel={this.cancel}
                                            airlineId={this.props.match.params.airlineId}
                                            airline={this.props.airline}
                                            modalRates={this.props.modalRates}
                                            setModalRates={this.props.setModalRates}
                                            change={this.props.change}
                                            editRates={this.props.editRates}
                                            readRates={this.props.readRates}
                                            itemRates={this.props.itemRates}
                                            setEditRates={this.props.setEditRates}
                                            setReadRates={this.props.setReadRates}
                                            setItemFormRates={this.props.setItemFormRates}
                                            showActiveField={false}
                                        />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = state => ({
    ...state.crewAdmin.crewRoutes,
    airline: state.crewAdmin.airlines.item,
})

const mdtp = {
    ...actions,
    getAirline,
    change
}

export default connect(mstp, mdtp)(Container);
