import { connect } from "react-redux";
import UsersGrid from "./UserGrid";
import { find, destroy } from "../../../../redux/modules/users";

const mstp = (state) => ({
    ...state.users,
    userInfo: state.login.userInfo,
})

const mdtp = { find, destroy };

export default connect(mstp, mdtp)(UsersGrid);
