import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import moment from 'moment';
import { STORAGE_KEY } from "./libs/apiClient";
import LoadMask from "./common/components/Utils/LoadMask";
import Navbar from "./common/components/Navbar/NavbarContainer";
import { setGoogleMapsMsgError } from './redux/modules/trip-edition';
import Script from 'react-load-script';
const { MAP_KEY_URL } = process.env;


const ProtectedRoute = ({
    component: Component,
    login: { loggedIn, userInfo },
    path,
    applyContainer = true,
    setGoogleMapsMsgError,
    ...rest
}) => {
    const checkAuth = (props) => {
        const token = localStorage.getItem(STORAGE_KEY);
        if (!loggedIn || !token){
            if (props.location.pathname)
                localStorage.setItem("URL_STATE", JSON.stringify({ from: props.location, timestamp: moment().unix() }))
            return false;
        }

        return true;
    };

    useEffect(()=>{
        // If google maps catch
        window.gm_authFailure = setGoogleMapsMsgError;
    },[]);

    const [loadScriptMaps, setScriptMaps] = useState(false);

    return (
        <Route
            {...rest}
            render={(props) =>
                checkAuth(props) ? (
                    isEmpty(userInfo) ? (
                        <LoadMask loading />
                    ) : (
                        <React.Fragment>
                            <Script
                                url={`https://maps.googleapis.com/maps/api/js?key=${MAP_KEY_URL}&libraries=places,geometry`}
                                onLoad={()=>setScriptMaps(true)}
                            />
                            {loadScriptMaps && (
                                <React.Fragment>
                                    <Navbar location={props.location} />
                                    <div className="content-wrapper">
                                        <div className={`${applyContainer ? 'container' : ''}`}>
                                            <Component {...props} />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

const mstp = (state) => ({ login: state.login });

export default connect(mstp, { setGoogleMapsMsgError })(ProtectedRoute);
