import app from "../../libs/apiClient";
import { createActions, handleActions } from "redux-actions";
import { goBack, replace } from "react-router-redux";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import moment from 'moment';
import swal from "sweetalert2";
import { pdf } from '@react-pdf/renderer';
import { setQuote, cleanDataModal, setItineraryState } from './itinerary';
import TripInfoPdf from '../../common/components/quotes/List/bookModal/TripInfoPdf';
import _, { cloneDeep } from "lodash";
import format from "../../common/utility/format";
import { countryOptions } from "../../common/utility/constants";
import cardValidator from 'card-validator';
import { errorHandler } from '../../common/utility/constants';
import { actions as breadcrumbActions } from "./breadcrumb";
const service = app.service("/api/quote");


const SET_DATA = "SET_DATA_QUOTE";
const SET_PAGE = "SET_PAGE_QUOTE";
const SET_ITEM = "SET_ITEM_QUOTE";
const MODAL_DETAIL = "MODAL_DETAIL_QUOTE";
const MODAL_RESERVATIONS = "MODAL_RESERVATIONS_QUOTES";
const PASSENGER_FORM_MODAL_QUOTE = "PASSENGER_FORM_MODAL_QUOTE";
const TRIPS_QUOTE_CART = "TRIPS_QUOTE_CART";
const VEHICLES_LIST_QUOTE = "VEHICLES_LIST_QUOTE";
const TRIP_ITEM_CART = "TRIP_ITEM_CART";
const CONTACT_INFO = "CONTACT_INFO_CART";
// loading
const LOADING_QUOTE_CART = "LOADING_QUOTE_CART";
const SET_LOADING = "SET_LOADING_QUOTE";
const LOADING_MODAL = "LOADING_MODAL_QUOTE";
const LOADING_PASSENGER_MODAL = "LOADING_PASSENGER_MODAL";
const LOADING_VEHICLES_MODAL = "LOADING_VEHICLES_MODAL";


// Actions

const _create = (dataEmail) => (dispatch, getStore) => {
    const { trips, contactInfo } = cloneDeep(getStore().quote);

    if(trips.length > 0){
        const data = []
        for(let i = 0; i < trips.length; i++){
            const { itinerary, vehicle, passenger } = trips[i];
            const stops = itinerary && itinerary.stops ? itinerary.stops : [];
            const formattedStops = stops.map((stop) => {
                const dataStop = { ...stop };
                if (dataStop.time)
                    dataStop.arrival_time = format.setTime(
                        itinerary.schedule,
                        dataStop.time.hour,
                        dataStop.time.minutes
                    );
                return dataStop;
            });

            const obj = {};
            if(itinerary){
                obj.itinerary = {
                    ...itinerary,
                    stops: formattedStops,
                    puTime: format.setTime(itinerary.schedule, itinerary.time.hour, itinerary.time.minutes),
                    puTimeNoTimezone: format.setTime(itinerary.schedule, itinerary.time.hour, itinerary.time.minutes),
                    duration: vehicle.duration,
                    hourlyDuration: itinerary.duration,
                    distance: vehicle.distance,
                    flightAirline: itinerary.airline,
                    flightNumber: itinerary.fligth_number,
                    fromAirport: itinerary.isAirport,
                    transactionAmount: vehicle.price,
                    transactionDiscount: vehicle.discount,
                    tripPrice: vehicle.tripPrice,
                    gratuity: vehicle.gratuity,
                    discountPercent: vehicle.discountPercent,
                    gratuityPercent: vehicle.gratuityPercent,
                    regularPrice: vehicle.regularPrice,
                    totalTolls: vehicle.totalTolls,
                    totalFuel: vehicle.totalFuel,
                    driveTime: vehicle.driveTime,
                    travelTime: vehicle.travelTime,
                }
            }
            if(vehicle){
                obj.vehicle = {
                    ...vehicle,
                    vehicleId: vehicle.vehicle_id,
                    vehicleLocation: vehicle.vehicleLocation_id,
                    vehicleSpecialRate: vehicle.vehicleSpecialRate_id,
                    baseRateId: vehicle._id,
                }
            }
            if(passenger){
                obj.passenger = {
                    ...passenger,
                    country: passenger.country ? passenger.country.value : undefined,
                }
            }
            data.push(obj);
        }

        if(contactInfo && typeof contactInfo.country === 'object')
            contactInfo.country = contactInfo.country.value;

        let dataRequest = { trips: data, contactInfo }

        if(dataEmail)
            dataRequest.query = dataEmail

        return service.create(dataRequest, { query: { $populate: ['agentId'] } });
    } else {
        throw 'Empty Quote!';
    }
}

const create = () => async (dispatch, getStore) => {

    try {
        dispatch(loading(SET_LOADING, true));
        dispatch(loading(LOADING_QUOTE_CART, true));
        const { item, } = cloneDeep(getStore().quote);
        const response  = (item && item._id) ? await dispatch(_update()) : await dispatch(_create());
        swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Quote Created!"
        }).then(()=>{
            dispatch(goBack());
        })
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(loading(SET_LOADING, false));
        dispatch(loading(LOADING_QUOTE_CART, false));
    }
};

const _update = (dataEmail) => (dispatch, getStore) => {
    const { trips, item, contactInfo, } = cloneDeep(getStore().quote);

    if(trips.length > 0){
        const data = []
        for(let i = 0; i < trips.length; i++){
            const { itinerary, vehicle, passenger } = trips[i];
            const obj = {};
            if(itinerary){
                obj.itinerary = {
                    ...itinerary,
                    puTime: format.setTime(itinerary.schedule, itinerary.time.hour, itinerary.time.minutes),
                    puTimeNoTimezone: format.setTime(itinerary.schedule, itinerary.time.hour, itinerary.time.minutes),
                    duration: vehicle.duration,
                    hourlyDuration: itinerary.transferQt && itinerary.transferQt === 'hourly' ? itinerary.duration : undefined,
                    distance: vehicle.distance,
                    flightAirline: itinerary.airline,
                    flightNumber: itinerary.fligth_number,
                    fromAirport: itinerary.isAirport,
                    transactionAmount: vehicle.price,
                    transactionDiscount: vehicle.discount,
                    tripPrice: vehicle.tripPrice,
                    gratuity: vehicle.gratuity,
                    discountPercent: vehicle.discountPercent,
                    gratuityPercent: vehicle.gratuityPercent,
                    regularPrice: vehicle.regularPrice,
                    totalTolls: vehicle.totalTolls,
                    totalFuel: vehicle.totalFuel,
                    driveTime: vehicle.driveTime,
                    travelTime: vehicle.travelTime,
                }
            }
            if(vehicle){
                obj.vehicle = {
                    ...vehicle,
                    vehicleId: vehicle.vehicle_id,
                    vehicleLocation: vehicle.vehicleLocation_id,
                    vehicleSpecialRate: vehicle.vehicleSpecialRate_id,
                    baseRateId: vehicle._id,
                }
            }
            if(passenger){
                obj.passenger = {
                    ...passenger,
                    country: passenger.country ? typeof passenger.country === 'object' ? passenger.country.value : passenger.country : undefined,
                }
            }
            data.push(obj);
        }

        if(contactInfo && typeof contactInfo.country === 'object')
            contactInfo.country = contactInfo.country.value;

        let dataRequest = { trips: data, contactInfo, }

        if(dataEmail)
            dataRequest.query = dataEmail;

        return service.patch(item._id, dataRequest, { query: { $populate: ['agentId'] } });
    } else {
        throw 'Empty Quote!'
    }
}

const update = () => async (dispatch, getStore) => {

    try {
        dispatch(loading(SET_LOADING, true));
        dispatch(loading(LOADING_QUOTE_CART, true));
        const response = await dispatch(_update())
        swal.fire({
            type: "success",
            title: "SUCCESS!",
            text: "Quote Updated!"
        }).then(()=>{
            dispatch(goBack());
        })
    } catch(e){
        dispatch(errorHandler(e));
    }finally{
        dispatch(loading(SET_LOADING, false));
        dispatch(loading(LOADING_QUOTE_CART, false));
    };
};

const destroy = (id) => async (dispatch, getState) => {
    dispatch(loading(LOADING_MODAL, true));
    try {
        await service.remove(id);
        dispatch(clearCart(true, true));
        dispatch(goBack());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(LOADING_MODAL, false));
    }
};

const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading(SET_LOADING, true));
    const { values = {} } = getState().form.quoteListFilter || {};

    try {
        const query = { query: {
            $skip: (page - 1) * 10,
            $sort: { _id: -1 },
            'trips.itinerary.puTime':{ $gte: moment() },
            quoteUsed: { $ne: true },
            $populate: ['agentId']
        }};

        if (values) {
            if(values.filter){
                if(values.filter === 'upcoming'){
                    query.query['trips.itinerary.puTime'] = { $gte: moment().hours("23").minutes("59").seconds("59") };
                    query.query.$sort = { _id: 1 }
                } else if(values.filter === 'booking'){
                    query.query.$sort = { _id: -1 }
                }
            }

            if (values.type) {
                const users_list = await app.service('user').find({
                    query: {
                        $or: [
                            { firstName: { $regex: _.escapeRegExp(values.type), $options: "i" } },
                            { lastName: { $regex: _.escapeRegExp(values.type), $options: "i" } },
                        ],
                        $paginate: false,
                        $select: ["_id"],
                    },
                });
                const users = users_list.map((item) => item._id);
                query.query.$or = [
                    { 'trips.vehicle.type': { $regex: _.escapeRegExp(values.type), $options: 'i' } },
                    { 'trips.vehicle.vehicleCode': { $regex: _.escapeRegExp(values.type), $options: 'i' } },
                    { 'trips.itinerary.to.formatted_address': { $regex: _.escapeRegExp(values.type), $options: 'i' } },
                    { 'trips.itinerary.from.formatted_address': { $regex: _.escapeRegExp(values.type), $options: 'i' } },
                    { agentId: { $in: users } },
                ];
                if(!isNaN(parseInt(values.type))){
                    query.query.$or.push({ quoteNo: parseInt(values.type) });
                }
            }

            if (values.startDate && values.endDate) {
                if (
                    moment(values.startDate).isSameOrBefore(moment(values.endDate))
                ) {
                    const eD = moment(values.endDate).hours("23").minutes("59").seconds("59");
                    query.query['trips.itinerary.puTime'] = { $lte: eD, $gte: values.startDate };
                }
            }

            if (values.limitDate) {
                query.query['trips.itinerary.puTime'] = {
                    $gt: moment(values.limitDate).hours("23").minutes("59").seconds("59"),
                    $lt: moment().hours("0").minutes("0").seconds("0"),
                };
            }
        }

        const response = await service.find(query);
        dispatch(setData(SET_DATA, response));
        dispatch(setPage(SET_PAGE, page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

const load2Update = (id) => async (dispatch, getState) => {
    dispatch(loading(SET_LOADING, true));
    dispatch(loading(LOADING_QUOTE_CART, true));

    try {
        const item = await service.get(id);
        const trips = [];
        for(let i = 0; i < item.trips.length; i++){
            trips[i] = {
                itinerary: {
                    ...item.trips[i].itinerary,
                    schedule: moment(item.trips[i].itinerary.puTimeNoTimezone),
                    time: {
                        hour: moment(item.trips[i].itinerary.puTimeNoTimezone).get('hours'),
                        minutes: moment(item.trips[i].itinerary.puTimeNoTimezone).get('minutes'),
                    },
                    airline: item.trips[i].itinerary.flightAirline,
                    fligth_number: item.trips[i].itinerary.flightNumber,
                    isAirport: item.trips[i].itinerary.fromAirport,
                    price: item.trips[i].itinerary.transactionAmount,
                    discount: item.trips[i].itinerary.transactionDiscount,
                    transferQt: item.trips[i].itinerary.hourlyDuration ? 'hourly' : 'transfer'
                },
                vehicle: {
                    ...item.trips[i].vehicle,
                    duration: item.trips[i].itinerary.duration,
                    distance: item.trips[i].itinerary.distance,
                    price: item.trips[i].itinerary.transactionAmount,
                    discount: item.trips[i].itinerary.transactionDiscount,
                    tripPrice: item.trips[i].itinerary.tripPrice,
                    gratuity: item.trips[i].itinerary.gratuity,
                    discountPercent: item.trips[i].itinerary.discountPercent,
                    gratuityPercent: item.trips[i].itinerary.gratuityPercent,
                    regularPrice: item.trips[i].itinerary.regularPrice,
                    driveTime: item.trips[i].itinerary.driveTime,
                    travelTime: item.trips[i].itinerary.travelTime,
                    totalTolls: item.trips[i].itinerary.totalTolls,
                    totalFuel: item.trips[i].itinerary.totalFuel,
                    vehicle_id: item.trips[i].vehicle.vehicleId,
                    vehicleLocation_id: item.trips[i].vehicle.vehicleLocation,
                    vehicleSpecialRate_id: item.trips[i].vehicle.vehicleSpecialRate,
                    _id: item.trips[i].vehicle.baseRateId,
                },
            }

            if(item.trips[i].itinerary.hourlyDuration)
                trips[i].itinerary.duration = item.trips[i].itinerary.hourlyDuration;
            else
                delete trips[i].itinerary.duration;

            if(item.trips[i].passenger){
                trips[i].passenger = {
                    ...item.trips[i].passenger,
                    country: countryOptions.find(({value}) => value === (item.trips[i].passenger ? item.trips[i].passenger.country : 'US')),
                }
            }
        }

        let { contactInfo } = cloneDeep(item);
        if(contactInfo){
            if(typeof contactInfo.country !== 'object'){
                contactInfo.country = countryOptions.find(({ value }) => value === contactInfo.country);
            }
        }

        const { updateCustomLabel } = breadcrumbActions;
        dispatch(updateCustomLabel(id, item.quoteNo));
        dispatch(setData(TRIPS_QUOTE_CART, trips));
        dispatch(setItem(SET_ITEM, item));
        dispatch(setData(CONTACT_INFO, contactInfo));
        dispatch(setQuote(item));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
        dispatch(loading(LOADING_QUOTE_CART, false));
    }
};

const openDetails = (id) => (dispatch, getStore) => {
    let modalDetail = false;
    if(id){
        modalDetail = true
        dispatch(load2Update(id))
    } else {
        dispatch(cleanDataModal());
        dispatch(setItem(SET_ITEM, {}))
    }
    dispatch({ type: MODAL_DETAIL, modalDetail });
}

const PDFQuote = () => async (dispatch, getStore) => {
    dispatch(loading(LOADING_MODAL, true));
    const { item } = getStore().quote;

    if(item && Object.keys(item).length > 0){
        const quoteBlob = await pdf(TripInfoPdf(item)).toBlob();
        saveAs(quoteBlob, `Quote_${item.quoteNo}.pdf`);
    }
    dispatch(loading(LOADING_MODAL, false));
}

const clearDates = () => (dispatch, getStore) =>{
    dispatch(change('quoteListFilter','startDate', ""));
    dispatch(change('quoteListFilter','endDate', ""));
    dispatch(find());
}

export const clearVehicles = () => (dispatch) => {
    dispatch(setVehicles([]));
};

export const clearCart = (clearTrips = false, clearItem = false) => (dispatch) => {
    dispatch(loading(LOADING_QUOTE_CART, true));
    dispatch(cleanDataModal());
    dispatch(setVehicles([]));
    dispatch(setItem(TRIP_ITEM_CART, {}));
    dispatch(setData(CONTACT_INFO));
    dispatch(initializeForm('typeForm', { transferQt: 'transfer' }));
    if(clearTrips)
        dispatch(setData(TRIPS_QUOTE_CART, []));
    if(clearItem)
        dispatch(setItem(SET_ITEM, {}));
    dispatch(setQuote({}));
    dispatch(loading(LOADING_QUOTE_CART, false));
}

export const setTrip = () => async (dispatch, getStore) => {
    dispatch(loading(LOADING_QUOTE_CART, true));
    let { trips, tripCartItem } = cloneDeep(getStore().quote);
    const { from, to, isAirport, airportCode } = getStore().itinerary;

    if(tripCartItem.edit && tripCartItem.tripIndex !== undefined){
        trips[tripCartItem.tripIndex].itinerary = { ...tripCartItem, from, to, isAirport, airportCode }
    } else {
        if(tripCartItem.bookingCode && tripCartItem.bookingCodeOneTime){
            if(trips.length > 0){
                for(let i = 0; i < trips.length; i++){
                    if(trips[i].itinerary.bookingCode && trips[i].itinerary.bookingCode === tripCartItem.bookingCode){
                        dispatch(loading(LOADING_QUOTE_CART, false));
                        throw 'Sorry the Booking code can only be used once and is already on another trip in this cart.'
                    }
                }
            }
        }
        trips.push({ itinerary: { ...tripCartItem, from, to, isAirport, airportCode } });
    }
    dispatch(setData(TRIPS_QUOTE_CART, trips));
    dispatch(loading(LOADING_QUOTE_CART, false));
    return true;
}

export const setTripItem = (values) => async (dispatch, getStore) => {
    const { from, to, isAirport, airportCode } = getStore().itinerary;
    const { tripCartItem } = getStore().quote;

    if(values.transferQt === 'transfer' && values.duration !== undefined)
        delete values.duration;

    if(values.bookingCode){

        const { data } = await app.service('/api/promo-code').find({query: { code: values.bookingCode } });

        if (!data.length) {
            throw "Sorry the code doesn't exist";
        } else if(data[0].oneTime){
            if(data[0].totalReservations && data[0].totalReservations > 0) {
                throw "Sorry the booking code can only be used once";
            } else {
                const { trips = [] } = cloneDeep(getStore().quote);
                if(trips.length > 0){
                    for(let i = 0; i < trips.length; i++){
                        if(trips[i].itinerary.bookingCode && trips[i].itinerary.bookingCode === values.bookingCode){
                            throw 'Sorry the booking code can only be used once and is already on another trip in this cart.'
                        }
                    }
                }
            }
        } else if (moment().isAfter(data[0].expDate)) {
            throw "Sorry the code has expired";
        } else if (moment().isBefore(data[0].startDate)) {
            throw "Sorry the code isn't valid yet, please try again later";
        }

        values.bookingCodeOneTime = data[0].oneTime;
    }

    const item = {
        ...values,
        from,
        to,
        isAirport,
        airportCode,
    };

    if (
        tripCartItem &&
        tripCartItem.edit &&
        tripCartItem.tripIndex !== undefined &&
        tripCartItem.tripIndex !== null &&
        tripCartItem.vehicle
    ) {
        item.vehicle = tripCartItem.vehicle;
    }

    dispatch(setItem(TRIP_ITEM_CART, item));
    return true;
}

export const loadVehicles = () => (dispatch, getStore) => {
    const { tripCartItem } = getStore().quote;

    const {
        from,
        to,
        duration,
        schedule,
        time,
        bookingCode,
        stops = [],
    } = tripCartItem;

    const stopsValid =
        !stops.length ||
        stops.every(
            ({ lat, lng }) =>
                lat !== null &&
                lat !== undefined &&
                lng !== null &&
                lng !== undefined
        );

    if (tripCartItem && from && to && time && stopsValid) {
        dispatch(loading(LOADING_VEHICLES_MODAL, true));
        const coordsStops = stops
            .map(({ lat, lng }) => `${lat},${lng}`)
            .join("|");

        const query = {
            state: from.state,
            city: from.city,
            postal_code: from.postal_code,
            latFrom: from.lat,
            lngFrom: from.lng,
            latTo: to.lat,
            lngTo: to.lng,
            to_city: to.city,
            to_state: to.state,
            to_postal_code: to.postal_code,
            schedule: format.setTime(schedule, time.hour, time.minutes),
            timeHour: time.hour,
            promoCode: bookingCode,
            timeMinutes: time.minutes,
            stops: coordsStops,
        };
        const errorMsg = {
            title: 'This is outside of our standard service area',
            html:
                'Please call <a href="tel:1-866-222-7433">1-866-222-7433</a> to get your request booked',
            type: 'warning',
            showConfirmButton: false,
            allowOutsideClick: false,
            footer: '<a href="/">Return to Book Now</a>',
        };

        if (duration) query.duration = duration;

        const errorHandler = err => {
            console.log(err)
            if (err.status === 422)
                Swal.fire({
                    html:
                        'This trip requires a specialist to book. Please contact our sales team for more information - <a href="/contact">Contact Form</a>',
                    type: 'warning',
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Yes, delete it!',
                    footer: '<a href="/">Return to Book Now</a>',
                });
        };

        app.service('/api/distance').find({ query }).then(res => {
            if (res) {
                if (!res.data.length) Swal.fire(errorMsg);
                else {
                    dispatch(setVehicles( res.data.map(items => items.vehicleBaseRates).flat()) );
                }
            }
        }).catch(errorHandler)
        .finally(() => {
            dispatch(loading(LOADING_VEHICLES_MODAL, false));
        });
    }
};

export const setPassenger = (values) => async (dispatch, getStore) => {
    dispatch(loading(LOADING_PASSENGER_MODAL, true));
    dispatch(loading(LOADING_QUOTE_CART, true));
    let { trips } = Object.assign({}, getStore().quote);
    trips[values.index].passenger = values;
    delete values.index;
    dispatch(setData(TRIPS_QUOTE_CART, trips));
    dispatch(loading(LOADING_QUOTE_CART, false));
    dispatch(loading(LOADING_PASSENGER_MODAL, false));
    return true;
}

export const editPassenger = (index) => (dispatch, getStore) => {
    if(index !== undefined){
        const { trips } = getStore().quote;
        if(trips.length && trips[index]){
            let { passenger = { country: { label: 'United States', value: 'US' }} } = trips[index];
            passenger.index = index;
            dispatch(initializeForm('PassengerForm', passenger))
        }

        dispatch(setModal(PASSENGER_FORM_MODAL_QUOTE, true));
    } else {
        dispatch(setModal(PASSENGER_FORM_MODAL_QUOTE, false));
    }
}

export const setVehicle = (data, edit = false) => (dispatch, getStore) => {
    dispatch(loading(LOADING_QUOTE_CART, true));
    let { trips, tripCartItem } = cloneDeep(getStore().quote);
    let result = false;
    if(data){
        const { passenger } = Object.assign({}, data);
        delete data.passenger;
        const index =
            tripCartItem &&
            tripCartItem.tripIndex !== null &&
            tripCartItem.tripIndex !== undefined
                ? tripCartItem.tripIndex
                : trips.length - 1;
        trips[index].vehicle = data;
        if(passenger)
            trips[index].passenger = passenger;
        const { hourlyDuration } = data;
        if (hourlyDuration && trips[index].itinerary) {
            trips[index].itinerary.hourlyDuration = hourlyDuration;
            trips[index].itinerary.duration = hourlyDuration;
        }
        dispatch(setData(TRIPS_QUOTE_CART, trips));
        result = true;

        if (edit) {
            const dataUpdate = { ...tripCartItem, vehicle: data };
            if (hourlyDuration) {
                dataUpdate.duration = hourlyDuration;
                dataUpdate.hourlyDuration = hourlyDuration;
            }
            dispatch(setItem(TRIP_ITEM_CART, dataUpdate));
            dispatch(clearCart());
        }
    }
    dispatch(loading(LOADING_QUOTE_CART, false));
    return result;
}

const removeTrip = (index) => (dispatch, getStore) => {
    swal({
        title: "Are you sure?",
        text: "You cannot undo this action!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        reverseButtons: true,
    }).then(({ value }) => {
        if (value){
            dispatch(loading(LOADING_QUOTE_CART, true));
            let { trips } = Object.assign({}, getStore().quote);

            if(trips && trips.length > 0){
                trips.splice(index, 1);
                dispatch(setData(TRIPS_QUOTE_CART, trips))
            }
            dispatch(loading(LOADING_QUOTE_CART, false));
        }
    });
}

export const editTrip = (index) => (dispatch, getStore) => {
    const { trips } = cloneDeep(getStore().quote);
    if(trips && trips.length > 0){
        const { itinerary, vehicle } = trips[index];
        dispatch(setItineraryState({
            from: itinerary.from,
            to: itinerary.to,
            isAirport: itinerary.isAirport,
            airportCode: itinerary.airportCode,
        }));
        itinerary.edit = true;
        itinerary.tripIndex = index;
        // dispatch(setItem(TRIP_ITEM_CART, itinerary));
        if (itinerary && itinerary.stops) {
            itinerary.stops = itinerary.stops.map((stop) => {
                const data = { ...stop };
                if (stop.arrival_time) {
                    data.time = {
                        hour: moment(stop.arrival_time).get("hours"),
                        minutes: moment(stop.arrival_time).get("minutes"),
                    };
                }
                return data;
            });
        }

        dispatch(
            initializeForm("typeForm", {
                ...itinerary,
                from: itinerary.from.formatted_address,
                to: itinerary.to.formatted_address,
            })
        );
        const initializeItem = (data, ds) =>
            new Promise((resolve) => {
                ds(setItem(TRIP_ITEM_CART, data));
                resolve();
            });

        initializeItem({ ...itinerary, vehicle }, dispatch).then(() =>
            dispatch(loadVehicles())
        );
    }
};

const sendEmail = (values) => async (dispatch, getStore) => {

    let contactInfoValues = { ...values };
    delete contactInfoValues.message;
    dispatch(setContactInfo(contactInfoValues));

    if(typeof contactInfoValues.country === 'object')
        contactInfoValues.country = contactInfoValues.country.value;

    const { item } = cloneDeep(getStore().quote);

    if(!item || !item._id){
        let response = await dispatch(_create({
            action: "SEND_EMAIL",
            emailToSend: values.email,
            message: values.message,
        }));
        return dispatch(load2Update(response._id))
    } else {
        return dispatch(_update({
            action: "SEND_EMAIL",
            emailToSend: values.email,
            message: values.message,
        }));
    }
}

const initializeCheckout = () => (dispatch, getStore) => {
    const { contactInfo } = cloneDeep(getStore().quote);
    dispatch(initializeForm('PaymentForm', contactInfo));
}

const initializeEmailForm = () => (dispatch, getStore) => {
    const { contactInfo } = cloneDeep(getStore().quote);
    dispatch(initializeForm('emailForm', contactInfo));
}

const setCheckout = (values) => async (dispatch, getStore) => {

    const { item } = getStore().quote;

    dispatch(setContactInfo({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        country: values.country,
        phone: values.phone,
    }))

    const { _id } = (item && item._id)
        ? await dispatch(_update())
        : await dispatch(_create())

    if(typeof values.country === 'object')
        values.country = values.country.value;

    const data = {
        ...values,
        payment_type: 'card',
        month: typeof values.month === 'string' ? values.month : values.month.value,
        year: typeof values.year === 'string' ? values.year : values.year.value,
        card_type: cardValidator.number(values.card_number).card.niceType,
        quoteId: _id,
        bookingAgent: true,
    };

    try {
        dispatch(loading(LOADING_MODAL, true));
        const receipt = await app.service('/api/authorize-charge').create(data);
        swal.fire({
            type: "success",
            title: "SUCESS!",
            text: "Trips Created!",
        }).then(()=>{
            dispatch(clearCart(true));
            dispatch(goBack());
        })
    } catch (err) {
        dispatch(errorHandler(err, 'Payment could not be processed. Please re-enter your payment information and try again.'));
    } finally {
        dispatch(loading(LOADING_MODAL, false));
    }
};

const editPassengerVehicle = (index) => async (dispatch, getStore) => {
    const { vehicles } = cloneDeep(getStore().quote);
    if(index !== undefined){
        let { passenger = { country: { label: 'United States', value: 'US' }} } = vehicles[index];
        passenger.index = index;
        dispatch(initializeForm('PassengerForm', passenger));
        dispatch(setModal(PASSENGER_FORM_MODAL_QUOTE, true));
    } else {
        throw 'Cannot get data from vehicle';
    }
}

const setPassengerVehicle = (values) => async (dispatch, getStore) => {
    const { vehicles } = cloneDeep(getStore().quote);
    if(values.index !== undefined){
        vehicles[values.index].passenger = values;
        delete vehicles[values.index].passenger.index;
        dispatch(setVehicles(vehicles));
    } else {
        throw 'cannot set passenger for the vehicle'
    }
}

const removePassengerVehicle = (index) => async (dispatch, getStore) => {
    const { vehicles } = cloneDeep(getStore().quote);
    if(vehicles && vehicles.length > 0){
        delete vehicles[index].passenger;
        dispatch(setVehicles(vehicles));
    } else {
        throw 'Cannot remove vehicle passenger';
    }
}

const modalPassengerForm = (modal) => (dispatch, getStore) => {
    dispatch(setModal(PASSENGER_FORM_MODAL_QUOTE, modal));
}

const setSpecialNotes = (values = {}) => async (dispatch, getStore) => {
    const { trips } = cloneDeep(getStore().quote);
    if(values.index !== undefined){
        if(trips[values.index].passenger)
            Object.assign(trips[values.index].passenger, { specialNotes: values.specialNotes } );
        else
            trips[values.index].passenger = { specialNotes: values.specialNotes };
        dispatch(setData(TRIPS_QUOTE_CART, trips));
    } else {
        throw 'cannot set special notes';
    }
}

const editSpecialNotes = (index) => (dispatch, getStore) => {
    const { trips } = cloneDeep(getStore().quote);
    if(index !== undefined){
        const specialNotes = trips[index].passenger && trips[index].passenger.specialNotes;
        dispatch(initializeForm('specialNotesForm', { specialNotes, index }));
    } else {
        throw 'Cannot read the trip';
    }
}

const setContactInfo = (values) => async (dispatch, getStore) => {
    let { contactInfo } = cloneDeep(getStore().quote);
    contactInfo = values;
    dispatch(setData(CONTACT_INFO, contactInfo))
};

const findSpecialRate = ({ query, item }) => {
    const getWeekDay = (dayName) => {
        if (dayName === "Sunday") return 0;
        if (dayName === "Monday") return 1;
        if (dayName === "Tuesday") return 2;
        if (dayName === "Wednesday") return 3;
        if (dayName === "Thursday") return 4;
        if (dayName === "Friday") return 5;
        if (dayName === "Saturday") return 6;
        return null;
    };

    const today = moment(
        `${moment(query.schedule).format("MM/DD/YYYY")} ${query.timeHour}:${
            query.timeMinutes
        }`
    );

    const indexSpecialRate = item.specialRates.findIndex(
        ({ calendar: { dates, type, time, recurring, repeatTimes = 1 } }) => {
            //Function to verify time in the calendar
            const verifyTime = (time, today) => {
                let startTime = moment(time.startTime, "HH:mm");
                let endTime = moment(time.endTime, "HH:mm");
                let todayTime = moment(today.format("HH:mm"), "HH:mm");
                if (todayTime.isBetween(startTime, endTime, undefined, "[]"))
                    return true;
                else return false;
            };
            // Function to verify if the date is the same in the calendar
            const verifyDate = (date, today, time) => {
                let todayRemovedHours = moment(today.format("MM/DD/YYYY"));
                if (date.isValid() && todayRemovedHours.isSame(date)) {
                    if (time) return verifyTime(time, today);
                    else return true;
                } else return false;
            };
            // Type to increase dates in "for" for repeat
            let typeAdd = recurring
                ? recurring === "weekly"
                    ? "w"
                    : recurring === "monthly"
                    ? "M"
                    : "Y"
                : "Y";

            let foundDate;
            if (type === "Days") {
                foundDate = dates.find(({ day, month, year, dayName }) => {
                    if (repeatTimes === 0) {
                        // FOR UNDEFINITE IN REPEAT TIMES
                        let initialDate =
                            recurring && recurring === "weekly"
                                ? today.day() === getWeekDay(dayName)
                                    ? moment(today.format("MM/DD/YYYY"))
                                    : undefined
                                : moment(
                                      `${
                                          today.month() + 1
                                      }/${day}/${today.year()}`
                                  );

                        if (!initialDate) return false;

                        if (verifyDate(initialDate, today, time)) {
                            return true;
                        }
                    } else {
                        let initialDate =
                            recurring && recurring === "weekly"
                                ? today.day() === getWeekDay(dayName)
                                    ? moment(`${month}/${day}/${year}`)
                                    : undefined
                                : moment(`${month}/${day}/${year}`);

                        if (!initialDate) return false;

                        for (let count = 0; count < repeatTimes; count++) {
                            if (verifyDate(initialDate, today, time)) {
                                return true;
                            }
                            initialDate.add(1, typeAdd);
                        }
                    }
                });
            } else {
                // BY RANGE
                let between =
                    moment(
                        `${dates[0].month}/${dates[0].day}/${dates[0].year}`
                    ).week() !==
                    moment(
                        `${dates[1].month}/${dates[1].day}/${dates[1].year}`
                    ).week()
                        ? today.day() <= getWeekDay(dates[1].dayName)
                        : today.day() >= getWeekDay(dates[0].dayName) &&
                          today.day() <= getWeekDay(dates[1].dayName);
                if (repeatTimes === 0) {
                    // FOR UNDEFINITE IN REPEAT TIMES
                    let startDiff =
                        getWeekDay(dates[0].dayName) - today.day();
                    let endDiff =
                        getWeekDay(dates[1].dayName) - today.day();

                    let startDateTemp = moment(today.format("MM/DD/YYYY")).add(
                        startDiff,
                        "d"
                    );
                    let endDateTemp = moment(today.format("MM/DD/YYYY")).add(
                        endDiff,
                        "d"
                    );

                    let startDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? startDateTemp
                                : undefined
                            : moment(
                                  `${today.month() + 1}/${
                                      dates[0].day
                                  }/${today.year()}`
                              );
                    let endDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? endDateTemp
                                : undefined
                            : moment(
                                  `${today.month() + 1}/${
                                      dates[1].day
                                  }/${today.year()}`
                              );

                    if (startDate && endDate && startDate.isValid()) {
                        let todayRemovedHours = moment(
                            today.format("MM/DD/YYYY")
                        );
                        if (
                            todayRemovedHours.isBetween(
                                startDate,
                                endDate,
                                undefined,
                                "[]"
                            )
                        ) {
                            foundDate = time ? verifyTime(time, today) : true;
                        }
                    }
                } else {
                    const startDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? moment(
                                      `${dates[0].month}/${dates[0].day}/${dates[0].year}`
                                  )
                                : undefined
                            : moment(
                                  `${dates[0].month}/${dates[0].day}/${dates[0].year}`
                              );
                    const endDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? moment(
                                      `${dates[1].month}/${dates[1].day}/${dates[1].year}`
                                  )
                                : undefined
                            : moment(
                                  `${dates[1].month}/${dates[1].day}/${dates[1].year}`
                              );

                    if (startDate && endDate && startDate.isValid()) {
                        for (let count = 0; count < repeatTimes; count++) {
                            let todayRemovedHours = moment(
                                today.format("MM/DD/YYYY")
                            );
                            if (
                                todayRemovedHours.isBetween(
                                    startDate,
                                    endDate,
                                    undefined,
                                    "[]"
                                )
                            ) {
                                foundDate = time
                                    ? verifyTime(time, today)
                                    : true;
                                if (foundDate) break;
                            }
                            startDate.add(1, typeAdd);
                            endDate.add(1, typeAdd);
                        }
                    }
                }
            }
            if (foundDate) return true;
        }
    );
    if (indexSpecialRate !== -1) {
        return item.specialRates[indexSpecialRate].vehicles;
    }
    return [];
};

export const searchForSpecialRate = () => async (dispatch, getStore) => {
    const { from, to } = getStore().itinerary;
    const { vehicles = [] } = getStore().quote;
    const { values } = getStore().form.typeForm;
    const { schedule: puDate, time: puTime, puTimeNoTimezone } = values;

    try {
        dispatch(loading(LOADING_QUOTE_CART, true));
        let locationResponse = await app.service("api/location").find({
            query: {
                $or: [
                    {
                        "zipCodes.zip": from.postal_code,
                    },
                    {
                        "excludeZipCodes.zip": from.postal_code,
                    },
                ],
            },
        });

        if (locationResponse.total === 0) {
            locationResponse = await app.service("api/location").find({
                query: {
                    $or: [
                        { "zipCodes.zip": to.postal_code },
                        { "excludeZipCodes.zip": to.postal_code },
                    ],
                },
            });
        }

        const location = locationResponse.data[0];

        if (!location) return false;

        const query = {
            schedule: `${moment(puDate).format("MM/DD/YYYY")} ${typeof puTime === 'object' ? `${puTime.hour}:${puTime.minutes}` : moment(puTimeNoTimezone).format('HH:mm')}`,
            timeHour: typeof puTime === 'object' ? puTime.hour : moment(puTime).format('HH'),
            timeMinutes: typeof puTime === 'object' ? puTime.minutes : moment(puTime).format('mm')
        };


        const specialRates = findSpecialRate({ query, item: location });
        const allSpecialIncluded = specialRates.every((special) => {
            return vehicles.find((vehicle) => {
                return (
                    vehicle.vehicleSpecialRate &&
                    String(vehicle.vehicleSpecialRate) ===
                        String(special.vehicleSpecialRate)
                );
            });
        });
        const hasSpecialRate = vehicles.some(
            (vehicle) => vehicle.vehicleSpecialRate
        );

        const someSpecialRemoved = hasSpecialRate && !specialRates.length;

        return allSpecialIncluded && !someSpecialRemoved;
    } catch (error) {
        errorHandler(error);
    } finally {
        dispatch(loading(LOADING_QUOTE_CART, false));
    }
};

export const actions = {
    find,
    PDFQuote,
    clearDates,
    load2Update,
    openDetails,
    destroy,
    create,
    update,
    editTrip,
    clearCart,
    removeTrip,
    setTripItem,
    setPassenger,
    editPassenger,
    setContactInfo,
    initializeCheckout,
    setPassengerVehicle,
    initializeEmailForm,
    editPassengerVehicle,
    removePassengerVehicle,
    modalPassengerForm,
    setCheckout,
    sendEmail,
    setSpecialNotes,
    editSpecialNotes,
    searchForSpecialRate,
};

const loading = (type, loading) => ({ type, loading });
const setData = (type, data) => ({ type, data });
const setPage = (type, page) => ({ type, page });
const setItem = (type, item) => ({ type, item});
const setVehicles = (vehicles) => ({ type: VEHICLES_LIST_QUOTE, vehicles });
const setModal = (type, modal) => ({ type, modal });

// Reducers
const reducers = {
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_ITEM]: (state, { item }) => ({ ...state, item }),
    [MODAL_DETAIL]: (state, { modalDetail }) => ({ ...state, modalDetail }),
    [MODAL_RESERVATIONS]: ( state, { modalReservations }) => ({ ...state, modalReservations }),
    [PASSENGER_FORM_MODAL_QUOTE]: (state, { modal }) => ({ ...state, passengerFormModal: modal }),
    [VEHICLES_LIST_QUOTE]: (state, { vehicles }) => ({ ...state, vehicles }),
    [TRIPS_QUOTE_CART]: (state, { data }) => ({ ...state, trips: data }),
    [TRIP_ITEM_CART]: (state, { item }) => ({ ...state, tripCartItem: item}),
    //Loading
    [SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
    LOADING_QUOTE_CART: (state, { loading }) => ({ ...state, loadingQuoteCart: loading }),
    [LOADING_MODAL]: (state, { loading }) => ({ ...state, loadingModal: loading }),
    [LOADING_PASSENGER_MODAL]: (state, { loading }) => ({ ...state, loadingPassengerModal: loading }),
    [LOADING_VEHICLES_MODAL]: (state, { loading }) => ({ ...state, loadingVehiclesModal: loading }),
    [CONTACT_INFO]: (state, { data }) => ({ ...state, contactInfo: data }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    //Item
    tripCartItem: {},
    item: {},
    contactInfo: undefined,
    //list
    trips: [],
    vehicles: [],
    //modals
    modalReservations: false,
    passengerFormModal: false,
    modalDetail: false,
    //loadings
    loadingModal: false,
    loadingQuoteCart: false,
    loadingPassengerModal: false,
    loadingVehiclesModal: false,
    loading: false,
};

export default handleActions(reducers, initialState);
