import { createActions, handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import { goBack } from "react-router-redux";
import isEmpty from "lodash/isEmpty";

import app from "../../libs/apiClient";
import { notificationsFor } from "../../common/utility/constants";
import { errorHandler } from '../../common/utility/constants';
const service = app.service("/api/client/user");
const [newCompanies] = notificationsFor;
import _ from 'lodash';
import { actions as breadcrumbActions } from "./breadcrumb";

const SET_SEARCH_CLIENTS = "SET_SEARCH_CLIENTS";

// Pure Actions
export const {
    loading,
    setData,
    setPage,
    setUpdateData,
    setClientsQuery,
} = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
    SET_PAGE: (page) => ({ page }),
    SET_UPDATE_DATA: (updateData) => ({ updateData }),
    SET_CLIENTS_QUERY: (query) => ({ query }),
});

// Actions
export const create = () => (dispatch, getState) => {
    const formData = getState().form.client.values;

    dispatch(loading());
    service
        .create(formData)
        .then((response) => {
            dispatch(goBack());
        })
        .catch((e) => {
            dispatch(errorHandler(e));
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

export const update = () => async (dispatch, getState) => {
    const formData = getState().form.client.values;

    dispatch(loading());

    try {
        const client = await service.patch(formData._id, formData);
        dispatch(goBack());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const patch = (id, data) => async (dispatch) => {
    dispatch(loading(true));
    try {
        const res = await service.patch(id, data);
        dispatch(goBack());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const destroy = (id) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        const remove = await service.remove(id);
        dispatch(find());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const find = (page) => async (dispatch, getState) => {
    dispatch(loading());
    const { query, page: statePage } = getState().clients;
    const {
        userInfo,
        userInfo: { notifications = [] },
    } = getState().login;
    const stateQuery = { ...query };
    page = page || statePage;
    const { values: filterValues } = getState().form.companyListFilter || {};
    const { search } = getState().clients;
    const notification = notifications.find(
        ({ notificationFor }) => notificationFor === newCompanies
    );

    if (!isEmpty(filterValues)) {
        if (filterValues.search)
            stateQuery.$or = [
                {
                    email: {
                        $regex: _.escapeRegExp(filterValues.email),
                        $options: "i",
                    },
                },
                {
                    companyName: {
                        $regex: _.escapeRegExp(filterValues.companyName),
                        $options: "i",
                    },
                },
            ];
    }

    try {
        const query = { query: { $skip: (page - 1) * 10, ...stateQuery } };

        if (notification) {
            app.service("user").patch(
                userInfo._id,
                {
                    "notifications.$.lastNotified": new Date(),
                },
                {
                    query: { "notifications._id": notification._id },
                }
            );
        }

        if(search){
            query.query.$or = [
                { firstName: { $regex: _.escapeRegExp(search), $options: 'i' } },
                { lastName: { $regex: _.escapeRegExp(search), $options: 'i' } },
                { mobileNo: { $regex: _.escapeRegExp(search), $options: 'i' } },
                { email: { $regex: _.escapeRegExp(search), $options: 'i' } },
            ]
        }

        const response = await service.find(query);
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const load2Update = (id) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const client = await service.get(id);
        const { updateCustomLabel } = breadcrumbActions;
        if (client.accountNumber)
            dispatch(updateCustomLabel(id, client.accountNumber));
        dispatch(setUpdateData(client));
        dispatch(initializeForm("client", client));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const setSearch = () => (dispatch, getStore) => {
    const store = getStore().form.searchClients;
    const search = store.values && store.values.searchClient;
    dispatch({type: SET_SEARCH_CLIENTS, search});
    dispatch(find())
}

const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setUpdateData]: (state, { payload: { updateData } }) => ({
        ...state,
        updateData,
    }),
    [setClientsQuery]: (state, { payload: { query } }) => ({ ...state, query }),
    [SET_SEARCH_CLIENTS]: (state, { search }) => ({ ...state, search }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    search: undefined,
    query: {},
    loading: false,
    updateData: {},
};

export default handleActions(reducers, initialState);
