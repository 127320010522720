import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { actions } from "../../../../redux/modules/vehicleLocations";
import { actions as locationActions } from '../../../../redux/modules/locations';
import VehiclesLocationsForm from "./vehiclesForm";
import ModalEdit from './VehicleEditModal';
import { getPathWithParams, settingsRatesLocationsIdUpdate } from "../../../../routesConfiguration/paths";

class DetailContainer extends React.Component {
    componentDidMount() {
        const { load2Update, getLocationHeader, match } = this.props;
        getLocationHeader(match.params.id);
        load2Update(match.params.id);
    }

    render() {

        const {
            userInfo: { permission }, match: { params }, update, header,
            //VehicleLocationFeature
            removeBaseRate, modalVehicleEdit, closeEditVehicle, vehicleEdit, vehicleLocationLoader, submitVehicleEdit, restoreDefault,
            openEditVehicle, updateVehicleLocation, createVehicleLocation, loading
        } = this.props;

        return permission && permission.find(({ readLocations }) => !!readLocations)
            ? <div className="d-flex flex-column p-2 p-md-5 flex-wrap justify-content-center align-items-center">
                <div className="col-12 d-flex justify-content-center align-items-center">
                    {header && (
                        <React.Fragment>
                            <h1>{header.city}, {header.state} ({header.cityCode})</h1> &nbsp; <Link to={getPathWithParams(settingsRatesLocationsIdUpdate, "id", header._id)}>(Edit)</Link>
                        </React.Fragment>
                    )}
                </div>

                <ModalEdit
                    isOpen={modalVehicleEdit}
                    toggle={closeEditVehicle}
                    vehicleEdit={vehicleEdit}
                    vehicleLocationLoader={vehicleLocationLoader}
                    onSubmit={updateVehicleLocation}
                    restoreDefault={restoreDefault}
                    showRestoreBtn={true}
                />

                <div className="col-12">
                    <VehiclesLocationsForm
                        onSubmit={update}
                        params={params}
                        removeBaseRate={removeBaseRate}
                        openEditVehicle = {openEditVehicle}
                        loading={loading}
                        initialValues={{
                            bookingOverride: false,
                            inFleet: false,
                        }}
                    />
                </div>
            </div>
            : null
    }
}

const mstp = (state) => ({
    ...state.vehicleLocations,
    userInfo: state.login.userInfo,
    header: state.locations.header,
});

const mdtp = { ...actions, getLocationHeader: locationActions.getLocationHeader };

export default connect(mstp, mdtp)(DetailContainer);
