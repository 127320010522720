import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/shuttle";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Grid from "../../../Utils/Grid/materialTable";
import ModalForm from "./modalFormCreate";
import ModalEditForm from "./modalFormUpdate";

class ListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        const { company } = this.props.match.params;
        const [companyId, date] = company.split("-");
        this.props.getLiveryShuttleTrips({ company: companyId, date: moment.unix(date).format("MM/DD/YYYY") });
        if (this.tableRef && this.tableRef.current)
            this.tableRef.current.dataManager.changePageSize(10);
    }

    render() {
        const {
            dataLivery,
            loading,
            company,
            loadingEditForm,
            updateShuttleTrip,
            createShuttleTrip,
            modalCreateLivery,
            modalEditLivery,
            dataShuttleTrip,
            loadingFormLivery,
            setModalFormLivery,
            setModalEditLivery,
            loadShuttleTripEdit,
        } = this.props;

        return (
            <div className="">
                <ModalForm
                    open={modalCreateLivery}
                    loading={loadingFormLivery}
                    close={() => setModalFormLivery(false)}
                    submit={createShuttleTrip}
                    company={company}
                />
                <ModalEditForm
                    open={modalEditLivery}
                    loading={loadingEditForm}
                    close={() => setModalEditLivery(false)}
                    submit={updateShuttleTrip}
                    dataShuttleTrip={dataShuttleTrip}
                />
                <Grid
                    tableRef={this.tableRef}
                    title={`${company && company.customer ? `${company.customer.code} - ${company.customer.label}` : 'Shuttles'}`}
                    data={dataLivery}
                    columns={[
                        {
                            title: 'PAX',
                            field: 'Passenger_Name',
                            width: '8%',
                            render: (row) => row && row.Passenger_Name ? `${row.Passenger_Name.match(/(\+\d+)/g) ? parseInt(row.Passenger_Name.match(/(\+\d+)/g)[0]) + 1 : '1'}` : '',
                            exportTransformer: (row) => row && row.Passenger_Name ? `${row.Passenger_Name.match(/(\+\d+)/g) ? parseInt(row.Passenger_Name.match(/(\+\d+)/g)[0]) + 1 : '1'}` : '',
                            filterComponent: ({ columnDef, onFilterChanged }) => (
                                <div className="MuiFormControl-root MuiTextField-root" style={{ float: "left" }} >
                                    <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                                        <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                                            <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="Filter" data-testid="filter_list">
                                                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                                            </svg>
                                        </div>
                                        <input
                                            min={1} aria-invalid="false" placeholder="" type="number" aria-label="filter data by PAX Count"
                                            className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputTypeSearch MuiInput-inputTypeSearch"
                                            onChange={(e)=>{
                                                let value = e.target.value && !isNaN(e.target.value) ? `+${parseInt(e.target.value)-1}` : ''
                                                onFilterChanged(columnDef.tableData.id, value)
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        },
                        {
                            title: 'P/U Date',
                            field: 'PUDate',
                            width: '12%',
                            render: (row) => `${row.PUDate} ${row.PUTime}`,
                            exportTransformer: (row) => `${row.PUDate} ${row.PUTime}`
                        },
                        {
                            title: 'Pick-Up Location',
                            field: 'PickupDesc',
                            width: '26%',
                            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '1rem', maxWidth: 250 }
                        },
                        {
                            title: 'Drop-Off Location',
                            field: 'DropoffDesc',
                            width: '26%',
                            cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '1rem', maxWidth: 250 }
                        },
                        {
                            title: 'Status',
                            field: 'Status',
                            width: '15%',
                            customFilterAndSearch: (search, row) => row.Status && row.Status.trim().match(new RegExp(`^${search}`, 'g')) !== null,
                            filterComponent: ({ columnDef, onFilterChanged }) => (
                                <select className="MuiInputBase-input select-border-bottom"
                                    onChange={({ target: { value }}) => onFilterChanged(columnDef.tableData.id, value)}
                                >
                                    <option value=""> ALL </option>
                                    <option value="Pending"> PENDING </option>
                                    <option value="Unassigned"> UNASSIGNED </option>
                                    <option value="Assigned"> ASSIGNED </option>
                                    <option value="Dispatched"> DISPATCHED </option>
                                    <option value="On The Way"> ON THE WAY </option>
                                    <option value="Arrived">ARRIVED</option>
                                    <option value="Customer In Car">CUSTOMER IN CAR</option>
                                    <option value="Done"> DONE </option>
                                    <option value="Canceled"> CANCELED </option>
                                    <option value="Down">DOWN</option>
                                    <option value="Flight Time Change">FLIGHT TIME CHANGE</option>
                                    <option value="Farm Out - Unassigned">FARM OUT - UNASSIGNED</option>
                                    <option value="No Show">NO SHOW </option>
                                    <option value="Late Cancel">LATE CANCEL </option>
                                    <option value="Quote">QUOTE </option>
                                </select>
                            ),
                            render: (row) => `${row.Status.toUpperCase()}`,
                            exportTransformer: (row) => row.Status.toUpperCase(),
                        },
                    ]}
                    isLoading={loading}
                    onRowClick={(e, row) => loadShuttleTripEdit(row)}
                    actions={[
                        {
                            icon: () => (
                                <button type="button"
                                    className="btn btn-primary px-2 py-0 align-self-end rounded-circle"
                                    onClick={() => setModalFormLivery(true)}
                                >
                                    <h3>+</h3>
                                </button>
                            ),
                            isFreeAction: true,
                        },
                    ]}
                    options={{
                        idSynonym: "TripID",
                        filtering: true,
                        exportMenu: [
                            {
                                label: "Export PDF",
                                exportFunc: (cols, datas) =>
                                ExportPdf(cols, dataLivery, `${company.customer.code.toUpperCase()} - ${company.customer.label.toUpperCase()} ${moment(company.date).format('MM.DD.YY')}`),
                            },
                            {
                                label: "Export CSV",
                                exportFunc: (cols, datas) => {
                                    cols.splice(5, 0, {
                                        title: 'Vehicle',
                                        field: 'VehicleType',
                                    });
                                    cols.splice(6, 0, {
                                        title: 'Driver',
                                        field: 'ChauffeurName',
                                    });
                                    ExportCsv(cols, dataLivery, `${company.customer.code.toUpperCase()} - ${company.customer.label.toUpperCase()} ${moment(company.date).format('MM.DD.YY')}`)
                                }
                            },
                        ],
                        headerStyle: {
                            backgroundColor: '#D50032',
                            color: '#FFF',
                            fontSize: '1rem',
                        },
                        cellStyle: {
                            fontSize: '1rem',
                        },
                    }}
                />
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.shuttle,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(ListContainer);
