import React from "react";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import IconTimes from "../../../../../icons/times";
import IconCheck from "../../../../../icons/check";
import { actions } from "../../../../../../redux/modules/crewAdmin/airlines";
import AirlineSettingsForm from "./settingsForm";
import { IconTrashAlt } from "../../../../../icons/trash";
import IconEdit from "../../../../../icons/edit";

const AirlineSettings = (props) => {
    const {
        airline,
        item = {},
        settings,
        loadingSettings = false,
        loadSettings2Update,
        getAirlineSettings,
        createSettings,
        updateSettings,
        removeSettings,
    } = props;

    const [showForm, setShowForm] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);

    React.useEffect(() => {
        getAirlineSettings(airline);
    }, [getAirlineSettings, airline]);

    const saveAirlineSettings = (values) => {
        if (isEdit) {
            updateSettings(isEdit, values).then(() => {
                setShowForm(false);
                setIsEdit(false);
            });
        } else {
            const data = {
                ...values,
                airline,
            };
            createSettings(data).then(() => {
                setShowForm(false);
            });
        }
    };

    const btnAddNew = () => {
        setIsEdit(false);
        setShowForm(true);
    };

    const btnEdit = (id) => {
        setIsEdit(id);
        setShowForm(true);
        loadSettings2Update(id);
    };

    const btnRemove = (id) => {
        removeSettings(id);
    };

    const cancelEditSettingsForm = () => {
        setShowForm(false);
    };

    if (loadingSettings)
        return (
            <div className="w-100">
                <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                    <Loader
                        type="Rings"
                        color="#FB885F"
                        height="100"
                        width="100"
                    />
                    <span className="">Loading...</span>
                </div>
            </div>
        );

    return (
        <div className="w-100">
            {!showForm ? (
                <div className="d-flex flex-wrap">
                    <div className="col-12">
                        <div className="d-flex justify-content-end mb-2">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={btnAddNew}
                            >
                                ADD
                            </button>
                        </div>
                        <div className="d-flex flex-wrap">
                            {settings.data && settings.data.length > 0 ? (
                                settings.data.map((sItem) => (
                                    <div
                                        className="w-100 border border-dark rounded shadow mb-2 px-2 py-3"
                                        key={sItem._id}
                                    >
                                        <div className="d-flex justify-content-end">
                                            <div
                                                className="clickable p-1"
                                                onClick={() => btnRemove(sItem._id)}
                                            >
                                                <IconTrashAlt className="smallIcon" />
                                            </div>
                                            <div
                                                className="clickable p-1"
                                                onClick={() => btnEdit(sItem._id)}
                                            >
                                                <IconEdit className="smallIcon" />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap mb-2">
                                            <div className="col-8">
                                                <strong>Airport:</strong>
                                            </div>
                                            <div className="col-4">
                                                <span>
                                                    {sItem.airport ? (sItem.airport.code || sItem.airport) : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap mb-2">
                                            <div className="col-8">
                                                <strong>Max Passeengers:</strong>
                                            </div>
                                            <div className="col-4">
                                                <span>{sItem.max_passengers}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap mb-2">
                                            <div className="col-8">
                                                <strong>Time Threshold:</strong>
                                            </div>
                                            <div className="col-4">
                                                <span>{sItem.time_threshold} Mins</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="d-flex justify-content-center">
                                    <span> No data! </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="py-4">
                    <AirlineSettingsForm
                        onSubmit={saveAirlineSettings}
                        onCancel={cancelEditSettingsForm}
                    />
                </div>
            )}
        </div>
    );
};

const mstp = (state) => ({
    ...state.crewAdmin.airlines,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(AirlineSettings);
