import app from "../../libs/apiClient";
import { handleActions } from "redux-actions";
import { push } from "react-router-redux";
import { liveryBaseUrl, S_UIUD, S_AUTHEN_KEYS } from '../../common/utility/constants';
import moment from 'moment';
import { errorHandler } from '../../common/utility/constants';
import { encrypt, getFlightById, getLiveryDetails, getPlaceFromGoogleMaps, updateTripLivery } from "./liveryEdition";
import { isEmpty } from "lodash";
import { reservationsAirlineMultiTripSearchList } from "../../routesConfiguration/paths";

const SET_DATA = "SET_DATA_AIRLINE_LIVERY";
const HIDE_CANCEL = "HIDE_CANCEL_AIRLINE_LIVERY";
const SET_LOADING = "SET_LOADING_AIRLINE_LIVERY";
const LOADING_FORM = "LOADING_FORM_AIRLINE_LIVERY";
const SET_ITEM = "SET_ITEM_AIRLINE_LIVERY";
const COMPANY_DATA = "SET_DATA_COMPANY_LIVERY";
const MODAL_FORM = "MODAL_FORM_AIRLINE_LIVERY";
const SET_SINGLE_DETAIL = "SET_SINGLE_DETAIL_COMPANY_LIVERY";
const AIRPORT_FILTER = "SET_AIRPORT_FILTER";
const AIRPORT_FILTER_ITEM = "AIRPORT_FILTER_ITEM";


// Actions
const create = (values) => (dispatch, getStore) => {
};

const update = (data) => async (dispatch, getStore) => {
    dispatch(loading(SET_LOADING, true));

    try {
        dispatch(updateTripLivery(data, ()=>dispatch(setModalForm(false))));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

const destroy = (id) => async (dispatch, getStore) => {
    dispatch(loading(SET_LOADING, true));
    try {
        // await service.remove(id);
        dispatch(find());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

const find = (page = 0) => async (dispatch, getStore) => {
    dispatch(loading(SET_LOADING, true));
    const { company } = getStore().airlineLivery;

    try {

        const liveryId = company.customer.liveryId;
        const startDate = moment(company.date).format('l');
        const endDate = moment(company.endDate).format('l');

        const response = await app.service('api/crew').find({
            query: {
                path: "getTokenizedParams",
                body: encrypt(`-1[[ENCRYPT]]Customer[[ENCRYPT]]0[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]]${startDate}[[ENCRYPT]]${endDate}[[ENCRYPT]]0[[ENCRYPT]]All[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]`),
            }
        })
        const flights = await app.service('api/crew').find({
            query: {
                path: "GetReservationListOnPhoneEN",
                body: response.Table[0]
            }
        })

        const airports = [];
        const trips = [];
        const { airportCodes } = getStore().liveryEdition;
        for (let i = 0; i < flights.Table.length; i += 1) {
            if (company.customer.company.trim().toUpperCase() !== flights.Table[i].ContactCompany.trim().toUpperCase())
                continue;

            if (flights.Table[i].FarmStatus === 2 && ["", null].includes(flights.Table[i].ChauffeurName)) {
                const arrText = flights.Table[i].FODriverID ? flights.Table[i].FODriverID.match(/((\s+)?[a-zA-Z]+)+|(\d+)/g) : [];
                flights.Table[i].ChauffeurName = arrText && arrText.length > 0 ? arrText[0] : null;
                if (["", null].includes(flights.Table[i].ChauffeurPhone) && arrText && arrText.length > 1)
                    flights.Table[i].ChauffeurPhone = arrText[arrText.length - 1];
            }
            if(flights.Table[i].Passenger_Name.match(/(\+\d+)/g) == null){
                flights.Table[i].Passenger_Name = `${flights.Table[i].Passenger_Name} (+0)`
            }

            let pick_airport;
            if(!isEmpty(flights.Table[i].Airline)){
                pick_airport = flights.Table[i].Airline.trim().split(" ")[0];
            } else {
                const arrayPick = flights.Table[i].PickupAirportDesc.split(/\s+/g);
                pick_airport = arrayPick[arrayPick.length - 3]; //airport position;
            }

            const arrayDrop = flights.Table[i].DropoffAirportDesc.split(/\s+/g);
            let drop_airport = arrayDrop[arrayDrop.length - 3];

            let airport;
            if(!isEmpty(flights.Table[i].CustomerPO)) {
                let matchAirport = flights.Table[i].CustomerPO.match(/\w+/g);
                if(matchAirport && matchAirport.length == 3)
                    airport = matchAirport[1];
            }

            if(pick_airport && !airports.includes(pick_airport)){
                let airportTemp = airportCodes.find(item => item.code == pick_airport);
                if(airportTemp)
                    airports.push(pick_airport);
            }

            if(drop_airport && !airports.includes(drop_airport)){
                let airportTemp = airportCodes.find(item => item.code == drop_airport);
                if(airportTemp)
                    airports.push(drop_airport);
            }

            if(airport && !airports.includes(airport)){
                let airportTemp = airportCodes.find(item => item.code == airport);
                if(airportTemp)
                    airports.push(airport);
            }
            trips.push(flights.Table[i]);
        }
        dispatch(setAirportsFilterList(airports));
        dispatch(setData(SET_DATA, trips));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

export const getSingleDetails = (data) => async (dispatch, getStore) => {
    dispatch(loading(true))
    try {
        const { company } = getStore().airlineLivery;
        const result = await getLiveryDetails(data.TripID, company.customer.liveryId);
        if(result) {
            const resultPickUp = await getPlaceFromGoogleMaps(data.PickupDesc);
            const resultDropOff = await getPlaceFromGoogleMaps(data.DropoffDesc);
            const pickUp = resultPickUp && { ...resultPickUp.geometry.location, label: resultPickUp.label };
            const dropOff = resultDropOff && { ...resultDropOff.geometry.location, label: resultDropOff.label };

            const liveryDBData = await app.service('/api/livery').get(data.TripID);

            let reservationDateTime = moment(result.Table[0].ReservationDateTime, 'MMM DD YYYY hh:mm A');
            let PUDate = reservationDateTime.format('MM/DD/YYYY');
            let PUTime = reservationDateTime.format('HH:mm');
            dispatch(setSingleDetail({
                ...result.Table[0],
                TripID: data.TripID,
                PUDate,
                PUTime,
                location: { pickUp, dropOff },
                liveryDBData
            }));

            dispatch( loading(false) );
        } else {
            dispatch(errorHandler({message: 'empty data'}));
            dispatch( loading(false) );
        }
    } catch(error) {
        dispatch(errorHandler(error));
        dispatch( loading(false) );
    }
}

const load2Update = (id) => async (dispatch, getStore) => {
    dispatch(loadingForm(true));

    try {
        dispatch(setModalForm(true));
        dispatch(getFlightById({liveryNo: id}));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loadingForm(false));
    }
};

const setCompany = (data, redirect = true) => (dispatch, getStore) => {
    dispatch(setCompanyData(data));
    if(redirect) dispatch(push(reservationsAirlineMultiTripSearchList));
    dispatch(find());
}

const setHotelTrips = (data) => (dispatch, getStore) => {
    dispatch(setCompanyData(data));
    dispatch(find());
}


const clear = () => (dispatch) => {
    dispatch(setItem(SET_ITEM, {}));
}

const setModalForm = (modalForm) => (dispatch) => { dispatch({ type: MODAL_FORM, modalForm }) };
const setHideCancel = (hideCancel) => (dispatch) => { dispatch({ type: HIDE_CANCEL, hideCancel }) };
const setAirportFilter = (item, remove = false) => (dispatch, getStore) => {
    dispatch(loading(SET_LOADING, true));
    let { airportItemFilter = [] } = getStore().airlineLivery;
    if(remove){
        let index = airportItemFilter.findIndex(it=>it==item);
        airportItemFilter.splice(index, 1);
    } else {
        airportItemFilter.push(item)
    }
    dispatch({ type: AIRPORT_FILTER_ITEM, airportItemFilter });
    dispatch(loading(SET_LOADING, false));
};

export const actions = {
    find,
    setCompany,
    setModalForm,
    setHideCancel,
    setAirportFilter,
    load2Update,
    clear,
    destroy,
    create,
    update,
    setHotelTrips
}

export const setSingleDetail = (singleDetail) => ({ type: SET_SINGLE_DETAIL, singleDetail });
const loadingForm = (loading) => ({ type: LOADING_FORM, loading_form: loading });
const loading = (type, loading) => ({ type, loading });
const setData = (type, data) => ({ type, data });
const setItem = (type, item) => ({ type, item});
const setCompanyData = (data) => ({ type: COMPANY_DATA, company: data });
const setAirportsFilterList = (airportsFilter) => ({ type: AIRPORT_FILTER, airportsFilter });

// Reducers
const reducers = {
    [SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_ITEM]: (state, { item }) => ({ ...state, item }),
    [COMPANY_DATA]: (state, { company }) => ({ ...state, company }),
    [HIDE_CANCEL]: (state, { hideCancel }) => ({ ...state, hideCancel }),
    [LOADING_FORM]: (state, { loading_form }) => ({ ...state, loading_form }),
    [SET_SINGLE_DETAIL]: (state, { singleDetail }) => ({ ...state, singleDetail }),
    [MODAL_FORM]: (state, { modalForm }) => ({ ...state, modalForm }),
    [AIRPORT_FILTER]: (state, { airportsFilter }) => ({ ...state, airportsFilter }),
    [AIRPORT_FILTER_ITEM]: (state, { airportItemFilter }) => ({ ...state, airportItemFilter }),
};

export const initialState = {
    data: [],
    loading: false,
    loading_form: false,
    hideCancel: false,
    modalForm: false,
    airportItemFilter: [],
    airportsFilter: [],
    singleDetail: {},
    company: {},
    item: {},
};

export default handleActions(reducers, initialState);
