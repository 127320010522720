
import React from "react";

const IconLocation = ({ className = "" }) => (
    <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        className={`${className}`}
    >
        <g>
            <circle fill="currentColor" cx="256" cy="256" r="77.2"/>
            <path fill="currentColor"
                d="M512,230.8h-52.8c-11.8-92.9-85.1-166.2-177.2-178V0h-51.2v52.8c-92.9,11.8-166.2,85.1-178,178H0V282h52.8
                c11.8,92.2,85.1,165.4,178,177.2V512H282v-52.8c92.2-11.8,165.4-85.1,177.2-177.2H512V230.8z M256,409.6
                c-85.1,0-153.6-68.5-153.6-153.6S170.9,102.4,256,102.4S409.6,170.9,409.6,256S341.1,409.6,256,409.6z"
            />
        </g>
    </svg>
);

export const IconLocationArrow = ({ className = "" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 448 512"
        className={`${className}`}
    >
        <path
            fill="currentColor"
            d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"
        />
    </svg>
);

export default IconLocation;
