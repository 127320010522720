import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { renderSearchSelect, renderSelectField, renderTextArea } from "../../Utils/renderField";
import app from "../../../../libs/apiClient";
import _ from "lodash";
import Select, { Async } from "react-select";
import IconTimes from "../../../icons/times";
import classNames from "classnames";
import swal from "sweetalert2";
import airlineCodes from '../../../utility/airlines.json';
import { renderField, renderFieldCheck } from "../../Utils/renderField/renderField";
import { connect } from "react-redux";


export const renderAirlines = (props) => {
    const { fields, meta: { error, submitFailed }, disabled } = props;
    const invalid = submitFailed && error;
    const removeAirline = (index) => {
        fields.remove(index);
    };

    const filterAirlines = airlineCodes.filter((item) => !(fields.getAll() || []).includes(item.code.trim().toUpperCase()));

    return (
        <div className="w-100 d-flex flex-wrap">
            <Select
                className={classNames(`form-control select-reset p-0`, {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    fields.push(e.code);
                }}
                valueKey="code"
                labelKey="label"
                placeholder="AA"
                options={filterAirlines}
                disabled={disabled}
                style={{
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                }}
            />
            <div className="w-100 d-flex flex-wrap mt-3">
                {!disabled &&
                    fields.map((itemField, index) => {
                        const item = fields.get(index);
                        return (
                            <div key={index} className={`bg-dark text-white rounded px-2 py-1 m-1`}>
                                <label className="mb-0">{item}</label>

                                    <span className="ml-2 clickable" onClick={()=>removeAirline(index)}>
                                        <IconTimes className="smallIcon text-white bg-dark rounded-circle p-1"/>
                                    </span>
                            </div>
                        );
                    })
                }
            </div>
            {invalid && (
                <div className="invalid-feedback text-danger align-self-center">
                    {error}
                </div>
            )}
        </div>
    );
};

export const renderVehicles = (props) => {
    const { fields, meta: { error, submitFailed }, disabled } = props;
    const invalid = submitFailed && error;

    const removeVehicle = (index) => {
        fields.remove(index);
    };

    const loadVehicles = (search) => {
        const vehicleCodes = (fields.getAll() || []).map((item) => item.vehicleCode);
        const params = { vehicleCode: { $nin: vehicleCodes } };
        if (search) {
            params.$or = [
                { type: { $regex: _.escapeRegExp(search), $options: "i" } },
                { class: { $regex: _.escapeRegExp(search), $options: "i" } },
                {
                    vehicleCode: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
            ];
        }

        return app
            .service("/api/vehicle")
            .find({ query: params })
            .then((result) => {
                for (let i = 0; i < result.data.length; i += 1)
                    result.data[i].label = `${result.data[i].vehicleCode} ${result.data[i].type}`;
                return { options: result.data };
            })
            .catch((error) => {
                console.log(error);
                return { options: [] };
            });
    };

    return (
        <div className="w-100 d-flex flex-wrap">
            <Async
                className={classNames(`form-control select-reset p-0`, {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    fields.push(e);
                }}
                cache={false}
                searchPromptText="Type to search"
                valueKey="_id"
                labelKey="label"
                placeholder="Select vehicles"
                loadOptions={loadVehicles}
                disabled={disabled}
                style={{
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                }}
            />
            <div className="w-100 d-flex flex-wrap mt-3">
                {fields.map((itemField, index) => {
                    const item = fields.get(index);
                    return (
                        <div key={index} className={`bg-dark text-white rounded px-2 py-1 m-1`}>
                            <label className="mb-0">{item.vehicleCode}</label>
                            {!disabled && (
                                <span className="ml-2 clickable" onClick={()=>removeVehicle(index)}>
                                    <IconTimes className="smallIcon text-white bg-dark rounded-circle p-1"/>
                                </span>
                            )}
                        </div>
                    );
                })}
            </div>
            {invalid && (
                <div className="invalid-feedback text-danger align-self-center">
                    {error}
                </div>
            )}
        </div>
    );
};

const Form = (props) => {
    const loadAirports = (search) => {
        const params = {};
        if (search) {
            params.$or = [
                {
                    name: { $regex: _.escapeRegExp(search), $options: "i" },
                },
                {
                    description: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
                {
                    code: { $regex: _.escapeRegExp(search), $options: "i" },
                },
            ];
        }

        return app
            .service("/api/airport")
            .find({ query: params })
            .then((result) => {
                for (let i = 0; i < result.data.length; i += 1) {
                    result.data[i].label = `${result.data[i].code} - ${result.data[i].name}`;
                }
                return { options: result.data };
            })
            .catch(() => {
                return { options: [] };
            });
    };

    const { handleSubmit, cancel, formValues } = props;

    return (
        <form onSubmit={handleSubmit} id="meetingForm">
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="name">Name</label>
                            <Field
                                name="name"
                                component={renderField}
                                placeholder="International at IAD"
                                className="form-control"
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label htmlFor="airport">Airport</label>
                            <Field
                                name="airport"
                                component={renderSearchSelect}
                                placeholder="Select airport"
                                loadOptions={loadAirports}
                                className="form-control"
                                allKey
                            />
                        </div>

                        <div className="col-12 form-group">
                            <div className="d-flex justify-content-between">
                                <label htmlFor="airlines">Airlines</label>
                                <Field
                                    name="selectAllAirlines"
                                    component={renderFieldCheck}
                                    label="Select All"
                                />
                            </div>
                            <FieldArray
                                name="airlines"
                                component={renderAirlines}
                                disabled={formValues && formValues.selectAllAirlines}
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="vehicles">Vehicles</label>
                            <FieldArray
                                name="vehicles"
                                component={renderVehicles}
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="type">Procedure Type</label>
                            <Field
                                name="type"
                                component={renderSelectField}
                                placeholder="Select meeting procedure"
                                options={[
                                    { label: "Baggage", value: "baggage" },
                                    { label: "Curbside", value: "curbside" },
                                ]}
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="instructions">Instructions</label>
                            <Field
                                name="instructions"
                                component={renderTextArea}
                                cols={3}
                                placeholder="Type the meeting instructions here"
                            />
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button
                                    className="btn btn-secondary m-1"
                                    type="button"
                                    onClick={cancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const MeetForm = reduxForm({
    form: "meetingProcedureForm",
    validate: (data, { formValues }) => {
        const errors = {};
        if (!data.name) errors.name = "Required Field";
        if (!data.airport) errors.airport = "Required Field";
        if(!formValues || !formValues.selectAllAirlines){
            if (!data.airlines || data.airlines.length === 0) {
                errors.airlines = { _error: "Required Field" };
            } else {
                const duplicadedAirline = data.airlines.find((a) =>
                    data.airlines.filter((item) => item === a).length > 1
                );
                if (duplicadedAirline)
                    errors.airlines = { _error: `${duplicadedAirline} is duplicated` };
            }
        }
        if (!data.vehicles || data.vehicles.length === 0) {
            errors.vehicles = { _error: "Required Field" };
        } else {
            const duplicadedVehicle = data.vehicles.find((v) =>
                data.vehicles.filter(
                    (item) => JSON.stringify(item._id) === JSON.stringify(v._id)
                ).length > 1
            );
            if (duplicadedVehicle)
                errors.vehicles = { _error: `${duplicadedVehicle.vehicleCode} is duplicated` };
        }
        if (!data.type) errors.type = "Required Field";
        if (!data.instructions) errors.instructions = "Required Field";
        return errors;
    },
})(Form);

const mstp = (state) => ({
    formValues: state.form.meetingProcedureForm && state.form.meetingProcedureForm.values,
});

export default connect(mstp)(MeetForm);
