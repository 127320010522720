import React from "react";
import Form from "./VehicleForm";

import LoadMask from "../../Utils/LoadMask";

const Create = ({ create, loading, userInfo: { permission } }) => (
    <LoadMask loading={loading} dark blur>
        <div className="row">
            <div>
                {permission && permission.find(({ createVehicles }) => !!createVehicles) && (
                    <Form
                        onSubmit={create}
                        initialValues={{
                            meetGreet: false,
                            meetGreetAirport: false,
                            extraStop: false,
                            extraStopAirport: false,
                            wifi: false,
                            wifiAirport: false,
                            audioVideo: false,
                            audioVideoAirport: false,
                            publicAddress: false,
                            publicAddressAirport: false,
                            restroom: false,
                            restroomAirport: false,
                            coordinator: false,
                            coordinatorAirport: false,
                        }}
                    />
                )}
            </div>
        </div>
    </LoadMask>
)

export default Create;
