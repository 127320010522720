import React from "react";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { combine, validate, validators } from "validate-redux-form";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import isArray from "lodash/isArray";
import moment from "moment";
import $ from "jquery";

import {
    renderField,
    renderSwitch,
    renderNumber,
    renderTextArea,
    renderCurrency,
    renderSelectField,
    renderImagePicker,
} from "../../Utils/renderField/renderField";
import IconTrash from "../../../icons/trash";
import renderBSDP from "../../Utils/renderField/RenderBSDP";
import { tourCategories, tourTimes } from "../../../utility/constants";
import { renderFieldDatePicker } from "../../Utils/renderField/renderDatePicker";
import { future, required, image } from "../../../utility/form-validations";
import { settingsTours } from "../../../../routesConfiguration/paths";

const renderPlaceHolders = ({ fields }) => (
    <div className="row">
        <div className="col-12">
            <hr />
            <div className="grid-title d-flex flex-colum flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
                <h3 className="ml-0 ml-sm-auto">
                    <strong>
                        Placeholders
                        <h6>(small description for tours)</h6>
                    </strong>
                </h3>
                <button
                    type="button"
                    className="btn btn-secondary ml-0 ml-sm-auto flex-nowrap flex-sm-wrap"
                    onClick={() => fields.push({})}
                >
                    Add Placeholder
                </button>
            </div>
            <br />
            <table className="table table-sm table-hover table-striped">
                <thead>
                    <tr>
                        <th style={{ width: "90%" }}>Title - Description</th>
                        <th style={{ width: "10%" }} />
                    </tr>
                </thead>
                <tbody>
                    {fields.map((plh, i) => (
                        <React.Fragment key={`holder${i}`}>
                            <tr>
                                <td colSpan="2">
                                    <strong>{`Placeholder ${i + 1}`}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor={`${plh}.title`}>
                                        <strong>Title</strong>
                                    </label>
                                    <Field
                                        name={`${plh}.title`}
                                        component={renderField}
                                        className="form-control"
                                        validate={[required]}
                                    />
                                    <label htmlFor={`${plh}.description`}>
                                        <strong>Description</strong>
                                    </label>
                                    <Field
                                        name={`${plh}.description`}
                                        component={renderTextArea}
                                        //className="form-control"
                                        validate={[required]}
                                    />
                                </td>
                                <td className="text-center td-center">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => fields.remove(i)}
                                    >
                                        X
                                    </button>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

const renderHours = ({ fields }) => {
    const add = () => fields.push({ hour: moment().startOf("hour") });

    return (
        <React.Fragment>
            <h6>Start Time</h6>
            <div className="row">
                {fields.map((hour, i) => (
                    <div key={i} className="col-md-6 d-flex my-3">
                        <Field
                            name={`${hour}.hour`}
                            component={renderFieldDatePicker}
                            dateFormat={false}
                            validate={[required]}
                        />
                        {i + 1 === fields.length ? (
                            <button
                                type="button"
                                onClick={add}
                                className="btn btn-primary ml-1"
                            >
                                +
                            </button>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary ml-1"
                                onClick={() => {
                                    fields.remove(i);
                                }}
                            >
                                X
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
};

const renderItinerary = ({
    fields,
    itinerary,
    activeIndex,
    setActiveIndex,
    deleteItinerary,
    setFormItinerary,
}) => {
    let disabled = false;
    const index = activeIndex !== null ? activeIndex : itinerary.length;
    const formatedScheduleDate = (date) => moment(date).format("MM-DD-YYYY");
    if (
        !fields.get(index) ||
        !fields.get(index).date ||
        !fields.get(index).duration ||
        !fields.get(index).spots[0].hour
    ) {
        disabled = true;
    }

    const DeleteButton = ({ index }) => (
        <button
            type="button"
            onClick={() => {
                fields.remove(index);
                deleteItinerary(index);
            }}
            className="btn btn-outline-secondary border-0 p-0 action"
        >
            <IconTrash className="action_icon action_icon-secondary" />
        </button>
    );

    return (
        <div className="row">
            <div className="col-12">
                <hr />
                <div className="grid-title d-flex flex-colum flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
                    <h3 className="mx-auto">
                        <strong>Tour Schedule</strong>
                    </h3>
                </div>
                <br />

                <div className="row">
                    <section className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="date">Date(s)</label>
                            <Field
                                name={`virtualItinerary[${index}].date`}
                                className="p-0"
                                component={renderBSDP}
                                timeFormat={false}
                                placeholder="12/31/2015"
                                numberOfMonths={1}
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="duration">Hours</label>
                            <Field
                                name={`virtualItinerary[${index}].duration`}
                                options={tourTimes}
                                component={renderSelectField}
                            />
                        </div>

                        <FieldArray
                            name={`virtualItinerary[${index}].spots`}
                            component={renderHours}
                        />

                        <button
                            type="button"
                            disabled={disabled}
                            className="btn btn-primary w-100"
                            onClick={setFormItinerary}
                        >
                            {activeIndex === null ? "Add" : "Update"}
                        </button>
                    </section>

                    <section className="col-md-6">
                        <div className="col border border-secondary">
                            <header className="text-center my-3 h5">
                                <strong>Schedule</strong>
                            </header>
                            <div className="row mb-3">
                                {itinerary.map((it, i) => (
                                    <div key={i} className="col-12">
                                        <div className="row">
                                            {activeIndex === i ? (
                                                <React.Fragment>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        setActiveIndex(
                                                                            i
                                                                        )
                                                                    }
                                                                    className="btn btn-outline-primary active border-0"
                                                                >
                                                                    {`${formatedScheduleDate(
                                                                        it.date
                                                                    )} -`}
                                                                </button>
                                                            </div>
                                                            <DeleteButton
                                                                index={i}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            {it.spots.map(
                                                                (
                                                                    { hour },
                                                                    i
                                                                ) => (
                                                                    <div
                                                                        key={i}
                                                                        className="col-4 my-3"
                                                                    >
                                                                        {hour.format(
                                                                            "hh:mm A"
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </React.Fragment>
                                            ) : (
                                                <div className="col-12">
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    setActiveIndex(
                                                                        i
                                                                    )
                                                                }
                                                                className="btn btn-outline-primary border-0"
                                                            >
                                                                {`${formatedScheduleDate(
                                                                    it.date
                                                                )} +`}
                                                            </button>
                                                        </div>
                                                        <DeleteButton
                                                            index={i}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

class Form extends React.Component {
    state = {
        activeItinerary: null,
    };

    componentDidMount() {
        const { change } = this.props;
        this.autocomplete = new google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            {
                type: ["cities"],
                componentRestrictions: { country: "us" },
            }
        );
        this.autocomplete.addListener("place_changed", () => {
            const addressObject = this.autocomplete.getPlace();
            change("city", addressObject.formatted_address);
        });
    }

    setFormItinerary = () => {
        const { change, itinerary = [], virtualItinerary } = this.props;
        const { activeItinerary } = this.state;
        const index =
            activeItinerary !== null
                ? activeItinerary
                : virtualItinerary.length - 1;

        let flattedItinerary = virtualItinerary[index].date;

        if (isArray(flattedItinerary)) {
            flattedItinerary = virtualItinerary[index].date.map((date) => ({
                ...virtualItinerary[index],
                date,
            }));
        } else flattedItinerary = [virtualItinerary[index]];

        flattedItinerary = [...itinerary.slice(0, index), ...flattedItinerary];

        // Add itinerary
        if (activeItinerary === null) {
            change("itinerary", flattedItinerary);

            change("virtualItinerary", [
                ...flattedItinerary,
                { spots: [{ hour: moment().startOf("hour") }] },
            ]);

            $(".datepicker").datepicker("clearDates");
        } else {
            // Edit itinerary
            change("itinerary", [
                ...flattedItinerary,
                ...itinerary.slice(index + 1),
            ]);

            change("virtualItinerary", [
                ...flattedItinerary,
                ...virtualItinerary.slice(index + 1),
            ]);

            $(".datepicker").datepicker(
                "setDates",
                flattedItinerary[index].date
            );
        }
    };

    deleteItinerary = (index) => {
        const { change, itinerary } = this.props;

        this.setState({ activeItinerary: null }, () => {
            change(
                "itinerary",
                itinerary.filter((e, i) => i !== index)
            );
            $(".datepicker").datepicker("clearDates");
        });
    };

    setActiveItinerary = (activeItinerary) => {
        const { itinerary } = this.props;

        this.setState((prevState) => {
            if (prevState.activeItinerary === activeItinerary) {
                $(".datepicker").datepicker("clearDates");
                return { activeItinerary: null };
            }

            $(".datepicker").datepicker(
                "setDates",
                new Date(itinerary[activeItinerary].date)
            );
            return { activeItinerary };
        });
    };

    render() {
        const { handleSubmit, photo, change, itinerary = [] } = this.props;

        const { activeItinerary } = this.state;

        return (
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container">
                    <div className="grid-titulo">
                        <h2>
                            <strong>Tour</strong>
                        </h2>
                    </div>

                    <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                        <div className="row">
                            <div className="col-md-6 form-group">
                                <label htmlFor="name">Title</label>
                                <Field
                                    name="name"
                                    component={renderField}
                                    className="form-control"
                                />
                            </div>

                            <div className="col-md-6 form-group">
                                <label htmlFor="type">Type</label>
                                <Field
                                    name="type"
                                    options={tourCategories}
                                    component={renderSelectField}
                                />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="description">Description</label>
                                <Field
                                    name="description"
                                    component={renderTextArea}
                                    type="text"
                                    //className="form-control"
                                />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 form-group"
                                style={{zIndex: "0"}}
                            >
                                <label htmlFor="picture">Photo</label>
                                <Field
                                    photo={photo}
                                    name="picture"
                                    validateResolution={true}
                                    category="tours/"
                                    component={renderImagePicker}
                                />
                            </div>

                            <div className="col-12 form-group mb-4">
                                <FieldArray
                                    name="virtualItinerary"
                                    change={change}
                                    component={renderItinerary}
                                    itinerary={itinerary}
                                    deleteItinerary={this.deleteItinerary}
                                    activeIndex={activeItinerary}
                                    setActiveIndex={this.setActiveItinerary}
                                    setFormItinerary={this.setFormItinerary}
                                />
                                <hr className="mt-4" />
                            </div>

                            <h3 className="col-12">
                                <strong>Tour Details</strong>
                            </h3>

                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="address">Address</label>
                                <Field
                                    name="address"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                <label htmlFor="city">City</label>
                                <Field
                                    id="autocomplete"
                                    name="city"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>

                            <div className="col-12 col-md-3 form-group">
                                <label htmlFor="price">Ticket Price</label>
                                <Field
                                    name="price"
                                    type="number"
                                    component={renderCurrency}
                                    className="form-control"
                                />
                            </div>

                            <div className="col-12 col-md-3 form-group">
                                <label htmlFor="maxCount">Max Count</label>
                                <Field
                                    name="maxCount"
                                    component={renderNumber}
                                    type="text"
                                />
                            </div>

                            <article className="col-12 col-md-6">
                                <div className="col border border-secondary">
                                    <header className="text-center my-3 h5">
                                        <strong>Amenities</strong>
                                    </header>
                                    <div className="row">
                                        <div className="col-12 col-sm-6 form-group">
                                            <label htmlFor="featured">
                                                Featured Tour
                                            </label>
                                            <Field
                                                name="featured"
                                                component={renderSwitch}
                                            />
                                        </div>

                                        <div className="col-12 col-sm-6 form-group">
                                            <label htmlFor="shopping">
                                                Shopping
                                            </label>
                                            <Field
                                                name="shopping"
                                                component={renderSwitch}
                                            />
                                        </div>

                                        <div className="col-12 col-sm-6 form-group">
                                            <label htmlFor="bathroom">
                                                Bathroom
                                            </label>
                                            <Field
                                                name="bathroom"
                                                component={renderSwitch}
                                            />
                                        </div>

                                        <div className="col-12 col-sm-6 form-group">
                                            <label htmlFor="wifi">Wifi</label>
                                            <Field
                                                name="wifi"
                                                component={renderSwitch}
                                            />
                                        </div>

                                        <div className="col-12 col-sm-6 form-group">
                                            <label htmlFor="kidsFriendly">
                                                Kids Friendly
                                            </label>
                                            <Field
                                                name="kidsFriendly"
                                                component={renderSwitch}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <div className="col-12 form-group">
                                <FieldArray
                                    name="placeholders"
                                    component={renderPlaceHolders}
                                />
                            </div>

                            <div className="col-12">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <Link
                                        className="btn btn-secondary m-1"
                                        to={settingsTours}
                                    >
                                        Cancel
                                    </Link>
                                    <button
                                        type="submit"
                                        className="btn btn-primary m-1"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const rxFormComp = reduxForm({
    form: "tour",
    validate: (data) => {
        return validate(data, {
            name: validators.exists()("Required field"),
            type: validators.exists()("Required field"),
            dates: validators.exists()("Required field"),
            hours: validators.exists()("Required field"),
            endTime: combine(
                validators.exists()("Required field"),
                future(data.startTime)()("End time must be after start time")
            ),
            description: validators.exists()("Required field"),
            city: validators.exists()("Required field"),
            address: validators.exists()("Required field"),
            maxCount: validators.exists()("Required field"),
            price: validators.exists()("Required field"),
            picture: image(data.photo)("Required field"),
        });
    },
})(Form);

const selector = formValueSelector("tour");
const mstp = (state) => ({
    itinerary: selector(state, "itinerary"),
    virtualItinerary: selector(state, "virtualItinerary"),
});

export default connect(mstp)(rxFormComp);
