import React from "react";
import { connect } from "react-redux";

import Detail from "./Detail";
import LoadMask from "../../Utils/LoadMask";
import { actions } from "../../../../redux/modules/shuttle";


class DetailContainer extends React.Component {

    componentDidMount() {
        const { load2Update, match } = this.props;
        load2Update(match.params.id);
    }

    render() {
        const { loading } = this.props;

        return (
            <LoadMask loading={loading} dark blur>
                <Detail {...this.props} />
            </LoadMask>
        );
    }
}

const mstp = (state) => state.quote;

export default connect(mstp, actions)(DetailContainer);
