import React, { useEffect, useState } from "react";
import { Field, FieldArray, reduxForm, change } from "redux-form";
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { renderField, renderPlacesAutocomplete } from "../../../Utils/renderField/renderField";
import { getAllZips } from '../../../../../redux/modules/crewAdmin/zones';
import ZipForm from './zipForm';
import TagsForm from './tagsForm';
import IconPlus from "../../../../icons/plus";
import IconTimes from "../../../../icons/times";
import swal from "sweetalert2";

const renderTags = (props) => {
    const [modal, setModal] = useState(false);
    const [edit, setEdit] = useState(undefined);

    const { fields, meta: { error, submitFailed }, change } = props;
    const invalid = submitFailed && error;

    const submitCreate = (values) => {
        fields.push(values);
        setModal(false);
    }

    const submitEdit = (values) => {
        change(`areas[${edit.index}]`, values);
        setEdit();
        setModal(false);
    }

    const setItemEdit = (item, index) => {
        setEdit({ item, index });
        setModal(true);
    }

    const closeModal = () => {
        if(edit)
            setEdit();
        setModal(false);
    }

    const removeTag = (index) => {
        swal.fire({
            type: "warning",
            html: `<h3> Are you sure you want to delete the tag? </h3>`,
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                fields.remove(index)
            }
        })
    }

    return (
        <div className="row">
            <Modal open={modal} onClose={()=>closeModal(false)} styles={{ modal: { minWidth: '30%' } }}>
                <div className="pt-5 pb-4 mb-3 mx-5">
                    <div className="mb-3">
                        <h3 className="text-left">{edit ? 'EDIT' : 'NEW'} TAG</h3>
                    </div>
                    <TagsForm
                        onSubmit={(values) => {
                            if(edit)
                                submitEdit(values);
                            else
                                submitCreate(values);
                        }}
                        cancel={()=>closeModal()}
                        initialValues={ edit && edit.item ? { ...edit.item } : { }}
                    />
                </div>
            </Modal>
            <div className="form-group col-12 d-flex justify-content-between">
                <h4> TAGS </h4>
                <button type="button" className="btn border bg-white px-2" onClick={()=>setModal(true)}>
                    <IconPlus className="smallIcon text-secondary" />
                </button>
            </div>
            <div className={`form-group col-12 d-flex flex-wrap ${ invalid ? 'is-invalid': '' }`}>
                {fields.map((itemField, index) => {
                    const item = fields.get(index);
                    return (
                        <div key={index}
                            className={`border border-dark d-flex m-1 pr-3`}
                            style={{ borderRadius: '15px' }}
                        >
                            <h5 className="mb-0 flex-1 px-3 py-2 clickable" onClick={()=>setItemEdit(item, index)}>{item && `${item.tag}`}</h5>
                            <span className="clickable d-flex align-items-center" onClick={()=>removeTag(index)}>
                                <IconTimes className="smallIcon text-white bg-dark rounded-circle p-1"/>
                            </span>
                        </div>
                    )
                })}
                {invalid &&
                    <div className="invalid-feedback text-danger align-self-center">
                        {error}
                    </div>
                }
            </div>
        </div>
    )
}

const renderZipArray = (props) => {

    const [duplicated, setDuplicated] = useState(false);
    const [modalZip, setModalZip] = useState(false);
    const [search, setSearch] = useState('');

    const { fields, meta: { error, submitFailed }, formValues } = props;
    const invalid = submitFailed && error;

    const submitCreateZip = (values) => {
        let list = fields.getAll() || [];
        if(list.find(item => item.zip_code == values.zip_code) != undefined){
            setDuplicated(true);
            setTimeout(()=>setDuplicated(false), 5000);
        }
        else{
            fields.push(values);
        }
        setModalZip(false);
    }

    const removeZip = (index) => {
        swal.fire({
            type: "warning",
            html: `<h3> Are you sure you want to delete zip ${fields.get(index).zip_code}${formValues && formValues.code ? ` from ${formValues.code}` : ''}? </h3>`,
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                fields.remove(index)
            }
        })
    }

    return (
        <div className="row">
            <Modal open={modalZip} onClose={()=>setModalZip(false)} styles={{ modal: { minWidth: '30%' } }}>
                <div className="pt-5 pb-4 mb-3 mx-5">
                    <div className="mb-3">
                        <h3 className="text-left"> NEW ZIP </h3>
                    </div>
                    <ZipForm
                        onSubmit={submitCreateZip}
                        cancel={()=>setModalZip(false)}
                    />
                </div>
            </Modal>
            <div className="col-12 d-flex justify-content-between">
                <h4> ZIPS </h4>
                <button type="button" className="btn border bg-white px-2" onClick={()=>setModalZip(true)}>
                    <IconPlus className="smallIcon text-secondary" />
                </button>
            </div>
            {duplicated ? (
                <div className="col-12 is-invalid mt-0">
                    <div className="invalid-feedback text-danger align-self-center">
                        The zip code already exists.
                    </div>
                </div>
            ) : ''}
            {(invalid || (!invalid && error && error != 'Required field')) &&
                <div className="col-12 is-invalid mt-0">
                    <div className="invalid-feedback text-danger align-self-center">
                        {error}
                    </div>
                </div>
            }
            {fields.length > 0 ? (
                <div className="form-group col-12 mt-3">
                    <input
                        type="text"
                        className="form-control"
                        value={search}
                        onChange={(event) => setSearch(event.target.value)}
                        placeholder="Enter a zip to search..."
                    />
                </div>
            ) : ''}
            <div className={`form-group col-12 d-flex flex-wrap ${ invalid ? 'is-invalid': '' }`}>
                {fields.map((itemField, index) => {
                    const item = fields.get(index);
                    const valid = search && search.length > 0 ? item.zip_code.indexOf(search) != -1 : true;
                    return valid ? (
                        <div key={index} className={`border border-dark px-3 py-2 m-1`} style={{ borderRadius: '15px' }}>
                            <label className="mb-0">{item.zip_code}</label>
                            <span className="ml-3 clickable" onClick={()=>removeZip(index)}>
                                <IconTimes className="smallIcon text-white bg-dark rounded-circle p-1"/>
                            </span>
                        </div>
                    ) : ''
                })}
            </div>
        </div>
    )
}

const Form = (props) => {

    const { handleSubmit, formValues, cancel, clearData, change, zoneId } = props;

    useEffect(()=>{
        props.getAllZips();
        return ()=>clearData();
    }, [])

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row">
                    <div className="form-group col-12">
                        <label htmlFor="name"> Name </label>
                        <Field
                            name="name"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <label htmlFor="code"> Code </label>
                        <Field
                            name="code"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <FieldArray
                            name="tags"
                            component={renderTags}
                            formValues={formValues}
                            change={change}
                        />
                    </div>
                    <div className="form-group col-12">
                        <FieldArray
                            name="zips"
                            component={renderZipArray}
                            formValues={formValues}
                        />
                    </div>
                </div>


                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <button type="button"
                        className="btn btn-link text-primary m-1 px-4"
                        onClick={cancel}
                    >
                        Cancel
                    </button>
                    <button type="submit"
                        className="btn btn-primary m-1 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}

const ZoneForm = reduxForm({
    form: "zoneForm",
    validate: (data, { allZips, zoneId }) => {
        const errors = {};
        if(!data.code) errors.code = "Required field";
        if(!data.name) errors.name = "Required field";
        if(!data.areas || data.areas.length == 0) errors.areas = { _error: "Required field" };
        if(!data.zips || data.zips.length == 0) {
            errors.zips = { _error: "Required field" };
        } else {
            let duplicatedZipZone;
            if(allZips && allZips.length > 0){
                duplicatedZipZone = allZips.find(zipZone =>
                    (!zoneId || zipZone.zone._id != zoneId)
                        ? data.zips.find(z => zipZone.zip_code.indexOf(z.zip_code) != -1) != undefined
                        : false
                )
                if(duplicatedZipZone)
                    errors.zips = { _error: `The zip code ${duplicatedZipZone.zip_code} is already assigned to zone ${duplicatedZipZone.zone.name}` }
            }

            if(!duplicatedZipZone){
                let duplicatedZip = data && data.zips
                    ? data.zips.find((item, index) =>
                            data.zips.find((z, i) => z.zip_code.indexOf(item.zip_code) != -1 && i != index )
                        )
                    : false;
                if(duplicatedZip)
                    errors.zips = { _error: `The zip code ${duplicatedZip.zip_code} is duplicated` }
            }
        }
        return errors;
    }
})(Form);

const mstp = state => ({
    formValues: state.form.zoneForm && state.form.zoneForm.values,
    allZips: state.crewAdmin.zones.allZips,
})

const mdtp = {
    change,
    getAllZips,
}

export default connect(mstp, mdtp)(ZoneForm);
