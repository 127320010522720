import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderNumber, renderSearchSelect } from "../../Utils/renderField";
import { reservationsShuttlesTracking } from "../../../../routesConfiguration/paths";


const Form = (props) => {

    const { handleSubmit, photo } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="customerId">Livery customer id</label>
                            <Field
                                name="customerId"
                                component={renderNumber}
                                thousandSeparator={""}
                                className=""
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="code">Code</label>
                            <Field
                                name="code"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <div className="d-flex flex-column flex-sm-row align-items-stretch justify-content-sm-end">
                                <Link
                                    className="btn btn-secondary m-1"
                                    to={reservationsShuttlesTracking}
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const UserForm = reduxForm({
    form: "shuttleForm",
    validate: (data) => {
        return validate(data, {
            customerId: validators.exists()("Required field"),
            code: validators.exists()("Required field"),
        });
    },
})(Form);

export default UserForm;
