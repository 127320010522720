import React from 'react';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';

const navigationIcon = "m 200 50 L 30 400 c -13 26 16 51 50 30 l 145 -90 l 145 90 c 31 21 64 -1 50 -30 l -170 -350 C 237.5 25 212.5 25 200 50 Z"
const circleIcon = "M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"

class MapWithADirectionsRenderer extends React.Component {

  state = {
    coords: undefined,
    directions: undefined,
  }

  componentDidMount() {
    const DirectionsService = new google.maps.DirectionsService();

    DirectionsService.route({
      origin: new google.maps.LatLng(this.props.fromLat, this.props.fromLng),
      destination: new google.maps.LatLng(this.props.toLat, this.props.toLng),
      travelMode: google.maps.TravelMode.DRIVING,
    }, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        const coords = result.routes[0].overview_path;
        this.setState({
          directions: result,
          coords,
        });
      } else {
        console.error(`error fetching directions ${result}`);
      }
    });
  }

  onLoad = (mapInstance) => {
    const fromPosition = new google.maps.LatLng(this.props.fromLat, this.props.fromLng)
    const toPosition = new google.maps.LatLng(this.props.toLat, this.props.toLng)

    const latlngbounds = new google.maps.LatLngBounds();
    latlngbounds.extend(fromPosition)
    latlngbounds.extend(toPosition)

    mapInstance.fitBounds(latlngbounds);
  }

  render() {

    // const centerLat = ((+this.props.fromLat) + (+this.props.toLat)) / 2;
    // const centerLng = ((+this.props.fromLng) + (+this.props.toLng)) / 2;

    const { showLine = true, mapContainerStyle = {} } = this.props;
    const { coords } = this.state;

    return (
      <div className="clearImg h-100">
        <GoogleMap
          mapContainerStyle={{
            height: '400px',
            ...mapContainerStyle
          }}
          onLoad={this.onLoad}
          options={this.props.options}
        >
          {coords && showLine &&
              <Polyline
                  path={coords}
                  options={{
                      geodesic: true,
                      strokeColor: "#4AA3FF",
                      strokeOpacity: 0.9,
                      strokeWeight: 4,
                      clickable: true
                  }}
              />
          }

          {this.props.vehicleLocation && (
            <Marker
              icon={{
                path: this.props.vehicleLocation.engineStates &&
                  (this.props.vehicleLocation.engineStates.value != 'Off' && this.props.vehicleLocation.engineStates.value != 'Idle')
                    ? navigationIcon
                    : circleIcon,
                fillColor: !this.props.vehicleLocation.engineStates || this.props.vehicleLocation.engineStates.value == 'Off'
                  ? "#4D5761" // Gray Color
                  : this.props.vehicleLocation.engineStates.value == 'Idle'
                    ? "#FFA521" // Orange Color
                    : "#28C68A", // Green Color
                fillOpacity: 1,
                rotation: this.props.vehicleLocation.gps.headingDegrees,
                scale: 0.05,
                strokeColor: "#FFF",
                strokeOpacity: 1,
                strokeWeight: 2.5,
                anchor: new google.maps.Point(230, 250)
              }}
              position={{ lat: this.props.vehicleLocation.gps.latitude, lng: this.props.vehicleLocation.gps.longitude }}
            />
          )}

          <Marker
            label="A"
            position={{ lat: this.props.fromLat, lng: this.props.fromLng }}
          />

          <Marker
            label ="B"
            position={{ lat: this.props.toLat, lng: this.props.toLng }}
          />
        </GoogleMap>
      </div>
    )
  }
}

export default MapWithADirectionsRenderer;
