import React, { useState } from "react";
import Modal from "react-responsive-modal";
import { Field, reduxForm } from "redux-form";
import { TableHeaderColumn } from "react-bootstrap-table";

import Table from "../../Utils/Grid";
import RowActions from "./RowActions";
import DownloadModal from "./DownloadModal";
import { BreakLine } from "../../Utils/tableOptions";
import { renderSwitch } from "../../Utils/renderField";
import { dateFormatter } from "../../Client/Grid/ClientGrid";
import { renderDropdownSelection, renderField, renderSelectField } from "../../Utils/renderField/renderField";
import renderDatePicker from "../../Utils/renderField/renderDatePicker";
import moment from "moment";
import IconPlus from '../../../icons/plus';
import IconFilter from '../../../icons/filter';
import numeral from "numeral";
import Pagination from "../../Utils/pagination";
import LoadMask from "../../Utils/LoadMask";
import format from "../../../utility/format";
import { slide as Menu } from "react-burger-menu";
import { LegacyReservationList } from "../../legacy-Reservations";
import classNames from "classnames";
import { getPathWithParams, reservationsCharterBookingsId } from "../../../../routesConfiguration/paths";


const calcDiff = (date) => {
    let now = moment();
    date = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    let difYear = date.diff(now, 'years');
    let difMonth = date.diff(now, 'months');
    let difDay = date.diff(now, 'days');
    let difHour = date.diff(now, 'hours');
    let difMinute = date.diff(now, 'minutes');

    if(difYear > 0)
        return difYear + ' Y';
    else if(difMonth > 0)
        return difMonth + ' M';
    else if(difDay > 0)
        return difDay + ' D';
    else if(difHour > 0)
        return difHour + ' H';
    else if(difMinute > 0)
        return difMinute + ' m';
    else
        return 'T'
}

const ReservationList = ({
    data,
    page,
    userInfo: { permission },
    loading,
    find,
    open,
    size,
    history,
    handleModal,
    download,
    clearDates,
    filters = {},
    resendEmail,
    destroyReserv,
    modalReservations,
    openModalReservation,
}) => {
    const [isOpenFilters, setIsOpenFilters] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    return (
        <div className="row">
            <Menu right
                itemListElement="div"
                id={ "filters" }
                customBurgerIcon={ false }
                styles={{
                    bmItemList: {
                        color: '#000',
                    },
                    bmMenu: {
                        background: '#333f48'
                    },
                    bmCrossButton: {
                        left: '8px',
                        right: 'inherit',
                    },
                    bmOverlay: {
                        inset: '0px 0px 0px',
                    },
                    bmMenuWrap: {
                        right: "0px",
                        top: "0px",
                    }
                }}
                isOpen={isOpenFilters}
                onStateChange={({ isOpen }) => setIsOpenFilters(isOpen)}
            >
                <div className="py-2 px-5">
                    <div className="">
                        <div>
                            <label className="text-white"> Start Date </label>
                            <Field
                                name="startDate"
                                component={renderDatePicker}
                                showButton={false}
                                timeFormat={false}
                            />
                        </div>
                        <div className="mt-3">
                            <label className="text-white"> End Date </label>
                            <Field
                                name="endDate"
                                className=""
                                component={renderDatePicker}
                                timeFormat={false}
                                showButton={false}
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className="d-flex justify-content-end">
                            <label className="text-white">Show Canceled&nbsp;</label>
                            <Field
                                name="canceled"
                                component={renderSwitch}
                                height={22}
                                width={44}
                            />
                        </div>
                        <div className="d-flex justify-content-end" >
                            <label className="text-white">No livery ID&nbsp;</label>
                            <Field
                                name="unassigned"
                                component={renderSwitch}
                                height={22}
                                width={44}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-end mt-4">
                        <button
                            type="button"
                            className="btn btn-light ml-1"
                            onClick={() => clearDates()}
                        >
                            CLEAR
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary ml-1"
                            onClick={() => find(1, size)}
                        >
                            APPLY
                        </button>
                    </div>
                </div>
            </Menu>
            <div className="col-sm-12 pt-2">
                <div className="grid-container">
                    <div className="grid-title d-flex flex-column justify-content-center align-items-stretch align-items-sm-center mb-5">
                        <div className="w-100 d-flex">
                            <h1>
                                CHARTER BOOKINGS
                            </h1>
                        </div>
                    </div>

                    <Modal
                        showCloseIcon={false}
                        open={open}
                        onClose={() => handleModal(false)}
                        classNames={{ modal: "modal-sm" }}
                    >
                        <div>
                            <div className="d-flex flex-column">
                                <DownloadModal
                                    styles={{ modal: { minWidth: "100%" } }}
                                    onSubmit={download}
                                    close={() => handleModal(false)}
                                    startDate={activeTab === 0 ? filters.startDate : null}
                                    endDate={activeTab === 0 ? filters.endDate : null}
                                />
                            </div>
                        </div>
                    </Modal>

                    <ul className="nav nav-tabs mb-2">
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={()=>setActiveTab(0)}
                                className={classNames("nav-link", {
                                    "active text-primary": activeTab === 0,
                                })}
                            >
                                Trip List
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={()=>setActiveTab(1)}
                                className={classNames("nav-link", {
                                    "active text-primary": activeTab === 1,
                                })}
                            >
                                Legacy List
                            </button>
                        </li>
                    </ul>

                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            className="btn btn-primary mx-1 d-none d-md-inline"
                            onClick={() => handleModal(true)}
                        >
                            Download
                        </button>
                    </div>

                    {permission && permission.find(({ readReservations }) => !!readReservations) && (
                        <React.Fragment>
                            {activeTab === 0 && (
                                <React.Fragment>
                                    <div className="mb-1 d-flex justify-content-between align-items-strech flex-column flex-md-row">
                                        <div className="mb-2 align-self-end w-100 d-flex flex-column-reverse flex-md-column flex-1">
                                            <div className="d-flex flex-column flex-md-row">
                                                <div style={{ flex: 1 }}>
                                                    <Field
                                                        name="type"
                                                        type="input"
                                                        component={renderField}
                                                        placeholder="Search by Confirmation No, livery ID"
                                                        cb={()=>find(1, size)}
                                                    />
                                                </div>
                                                <div className="d-flex mt-2 mt-md-0 ml-0 ml-md-3 align-items-center justify-content-end">
                                                    <div className="px-1">
                                                        <IconFilter className="smallIcon"/>
                                                    </div>
                                                    <Field
                                                        name="filter"
                                                        component={renderDropdownSelection}
                                                        className="d-flex justify-content-end"
                                                        placeholder="Sorter by"
                                                        clearable
                                                        scrolling
                                                        options={[
                                                            {key: "Booking Date", text: "Booking Date", value: "booking"},
                                                            {key: "Contact Name", text: "Contact Name", value: "contactName"},
                                                        ]}
                                                        cb={()=>find(1, size)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-end mb-2 flex-1">
                                            <div className="align-self-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mx-1"
                                                    onClick={()=>setIsOpenFilters(!isOpenFilters)}
                                                >
                                                    <IconFilter className="smallIcon p-1"/>&nbsp;
                                                    FILTER
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <LoadMask loading={loading} blur>
                                        <React.Fragment>
                                            <div className="d-flex flex-column">
                                                {data && data.total && data.total > 0
                                                    ? data.data.map(({
                                                            _id,
                                                            contactFirstNm,
                                                            contactLastNm,
                                                            contactEmail,
                                                            createdAt,
                                                            contactMobile,
                                                            contactCountry,
                                                            lastEdit,
                                                            updatedAt,
                                                            trips
                                                        }, index)=>(
                                                            <div key={index}
                                                                className="my-2 pt-3 px-3 pt-lg-4 px-lg-4 border border-dark rounded cartListShadow clickable"
                                                            >
                                                                <div
                                                                    className="d-flex flex-column flex-lg-row pb-1 pb-lg-2"
                                                                    onClick={()=>history.push(getPathWithParams(
                                                                        reservationsCharterBookingsId,
                                                                        "id",
                                                                        _id
                                                                    ))}
                                                                    key={index}
                                                                >
                                                                    <div className="flex-1 d-flex flex-column">
                                                                        <div className="d-flex py-1">
                                                                            <b>Contact Name:&nbsp;</b>
                                                                            <span> {contactFirstNm} {contactLastNm} </span>
                                                                        </div>
                                                                        <div className="d-flex py-1">
                                                                            <b>Contact Email:&nbsp;</b>
                                                                            <span> {contactEmail} </span>
                                                                        </div>
                                                                        <div className="d-flex py-1">
                                                                            <b>Created On:&nbsp;</b>
                                                                            <span> {moment(createdAt).format('MMM DD, YYYY LT')} </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-1 d-flex flex-column">
                                                                        <div className="d-flex py-1">
                                                                            <b>Contact Phone:&nbsp;</b>
                                                                            <span> {format.formatPhone(contactMobile, contactCountry)} </span>
                                                                        </div>
                                                                        <div className="d-flex py-1">
                                                                            <b>Trip count:&nbsp;</b>
                                                                            <span> {trips.length} </span>
                                                                        </div>
                                                                        <div className="d-flex py-1">
                                                                            <b> Cart Total:&nbsp;</b>
                                                                            <span> ${numeral(trips.reduce((total, curr) => total + curr.transactionAmount , 0)).format('0,0.00')} </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className={`d-flex justify-content-end ${lastEdit ? 'pb-2' : 'pb-3'}`}>
                                                                    {lastEdit && (
                                                                        <small className="pr-5">
                                                                            <i>Last Edit {moment(updatedAt).format('MM/DD/YYYY LT')} ({lastEdit.firstName} {lastEdit.lastName})</i>
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))
                                                    : ''
                                                }
                                            </div>

                                            <Pagination
                                                total={data.total}
                                                page={page}
                                                find={(page)=>find(page, size)}
                                            />
                                        </React.Fragment>
                                    </LoadMask>

                                </React.Fragment>
                            )}
                            {activeTab === 1 && (
                                <LegacyReservationList history={history}/>
                            )}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
}

export default reduxForm({
    form: "reservationListFilter",
    destroyOnUnmount: false,
})(ReservationList);
