import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icons } from '../../icons/menu';
import { settingsAccountsCompanies, settingsAccountsCustomers, settingsAccountsPoints } from '../../../routesConfiguration/paths';


const Container = (props) => {
    const { userInfo: { permission, superUser }, } = props;

    return (
        <div className="d-flex flex-wrap justify-content-center">
            <div className="col-12 col-xl-10 d-flex flex-wrap">
                {permission && permission.find(({ companies }) => companies !== 'none') && (
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={settingsAccountsCompanies}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <Icons.IconCompanies className="h-100" />
                            </div>
                            <span className="mt-3">Companies</span>
                        </Link>
                    </div>
                )}
                {permission && permission.find(({ clients }) => clients !== 'none') && (
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={settingsAccountsCustomers}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <Icons.IconCustomers className="h-100" />
                            </div>
                            <span className="mt-3">Customers</span>
                        </Link>
                    </div>
                )}
                {permission && permission.find(({ points }) => points !== 'none') && (
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={settingsAccountsPoints}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <Icons.IconCustomerPoints className="h-100" />
                            </div>
                            <span className="mt-3 text-center">Points</span>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

const mstp = state => ({
    userInfo: state.login.userInfo
})

export default connect(mstp)(Container);
