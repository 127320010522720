import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { renderField, renderNumber } from "../Utils/renderField/renderField";
import AutoSuggest from "../Utils/autosuggest";
import { Link } from "react-router-dom";


class Form extends React.Component {

    render() {
        const { handleSubmit } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <h4 className="d-flex justify-content-between mb-3">
                    Livery ID
                </h4>

                <div className="form-group">
                    <label htmlFor="liveryNo">Trip Number</label>
                    <Field
                        name="liveryNo"
                        component={renderNumber}
                        thousandSeparator=""
                        placeholder="1182121"
                        className=""
                    />
                </div>

                <div className="d-flex justify-content-center justify-content-md-end form-group">
                    <button type="submit"
                        className="btn btn-primary btn-lg"
                    >
                        SEARCH
                    </button>
                </div>
            </form>
        )
    }
}

Form = reduxForm({
    form: 'FlightsLivery',
    validate: data => {
        const errors = {};
        if(!data.liveryNo) errors.liveryNo = 'Required Field';
        return errors;
    }
})(Form);

const mdtp = { change };

export default connect(null, mdtp)(Form);
