import React from "react";
import { Field, reduxForm, change } from "redux-form";
import { connect } from "react-redux";
import { renderField, renderPlacesAutocomplete, renderSelectField } from "../../../Utils/renderField/renderField";
import { stateOptions } from "../../../../utility/constants";

const Form = (props) => {

    const { handleSubmit, setDetails, change, cancel } = props;

    const onSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <div className="row">
                    <div className="form-group col-12">
                        <strong htmlFor="zip_code"> Zip Code </strong>
                        <Field
                            id="autoCompleteZip"
                            name="zip_code"
                            component={renderPlacesAutocomplete}
                            additionalAddressInfo={['administrative_area_level_2']}
                            setDetails={(details) => {
                                change('city', details.city);
                                change('county', details.administrative_area_level_2);
                                change('state', details.state);
                            }}
                            searchOptions={{ types: '(regions)' }}
                            // Value={item && item.address}
                            className="form-control"
                            placeholder="20001"
                            isZip
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="city"> City </strong>
                        <Field
                            name="city"
                            component={renderField}
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="county"> County </strong>
                        <Field
                            name="county"
                            component={renderField}
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="state"> State </strong>
                        <Field
                            name="state"
                            component={renderSelectField}
                            options={stateOptions}
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <button type="button"
                        className="btn btn-link text-primary m-1 px-4"
                        onClick={cancel}
                    >
                        Cancel
                    </button>
                    <button type="submit"
                        className="btn btn-primary m-1 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}

const ZipForm = reduxForm({
    form: "zipForm",
    validate: (data) => {
        const errors = {};
        if(!data.zip_code) errors.zip_code = "Required field";
        if(!data.city) errors.city = "Required field";
        return errors;
    }
})(Form);

const mdtp = {
    change
}

export default connect(null, mdtp)(ZipForm);
