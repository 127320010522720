import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';

import TripInfo from '../TripInfo';
import Discount from '../Discount';
import Total from '../Total';
import logo from '../../../../../../../assets/img/logo.png';


const Receipt = props => {
    const { itinerary, vehicle } = props;

    return (
        <div className="p-4 bg-light d-flex flex-column flex-wrap radius-1 reset-print" id='printDiv'>

            <img src={logo} className="logoPrintPdf" style={{ height: '70px', width: '252px' }} />

            {itinerary.confirmed && (
                <div>
                    <div className="d-flex justify-content-between">
                        <div className="flex-1 m-2 text-primary">
                            <b>
                                TRIP ID# {itinerary.confirmed.confirmationNo}
                            </b>
                        </div>
                    </div>

                    <TripInfo itinerary={itinerary.confirmed}/>
                </div>
            )}


            {!!itinerary.confirmed.transactionDiscount > 0 && (
                <Discount price={itinerary.confirmed.transactionAmount} discount={itinerary.confirmed.transactionDiscount} />
            )}

            <Total price={itinerary.confirmed.transactionAmount} />

            <div className="d-flex flex-column flex-sm-row justify-content-between mt-3 no-print">
                <div className="flex-1 d-flex"></div>
                <div className="flex-1 d-flex flex-row-reverse justify-content-between">
                    <button
                        className="btn btn-primary px-4 py-3"
                        onClick={window.print}
                    >
                        <b>PRINT</b>
                    </button>
                </div>
            </div>
        </div>
    );
};

Receipt.propTypes = {
    vehicle: PropTypes.array,
    itinerary: PropTypes.object,
};

export default Receipt;
