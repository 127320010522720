
import React from 'react';
import Form from "./RolesForm";
import LoadMask from "../../Utils/LoadMask";
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/roles";
import { change } from 'redux-form';

const Create = ({ create, loading, change, userInfo: { superUser } }) => ( !superUser
  ? <div className="d-flex w-100 justify-content-center align-items-center">
        <label> You are not a superuser </label>
    </div>
  : <LoadMask loading={loading} dark blur>
        <div className="row">
            <div>
                <Form
                    onSubmit={create}
                    change = {change}
                    initialValues={{
                        trendsDashboard: "none",
                        viewTrends: false,
                        viewBusiestMonth: false,
                        viewMonthlyRevenue: false,
                        monthlyManifestDashboard: "false",
                        newestTripsDashboard: "false",
                        reservations: "none",
                        readReservations: false,
                        createReservations: false,
                        updateReservations: false,
                        removeReservations: false,
                        tourReservations: "none",
                        readTourReservations: false,
                        createTourReservations: false,
                        updateTourReservations: false,
                        removeTourReservations: false,
                        airlineSchedule: "none",
                        readAirlineSchedule: false,
                        createAirlineSchedule: false,
                        updateAirlineSchedule: false,
                        removeAirlineSchedule: false,
                        reports: "none",
                        blogPost: "none",
                        readBlogPost: false,
                        createBlogPost: false,
                        updateBlogPost: false,
                        removeBlogPost: false,
                        promo: "none",
                        readPromo: false,
                        createPromo: false,
                        updatePromo: false,
                        removePromo: false,
                        administration: "none",
                        quotes: "none",
                        readQuotes: false,
                        createQuotes: false,
                        updateQuotes: false,
                        removeQuotes: false,
                        reviews: "none",
                        readReviews: false,
                        createReviews: false,
                        updateReviews: false,
                        removeReviews: false,
                        vehicles: 'none',
                        readVehicles: false,
                        createVehicles: false,
                        updateVehicles: false,
                        removeVehicles: false,
                        tours: 'none',
                        readTours: false,
                        createTours: false,
                        updateTours: false,
                        removeTours: false,
                        companies: 'none',
                        readCompanies: false,
                        createCompanies: false,
                        updateCompanies: false,
                        removeCompanies: false,
                        clients: 'none',
                        readClients: false,
                        createClients: false,
                        updateClients: false,
                        removeClients: false,
                        locations: 'none',
                        readLocations: false,
                        createLocations: false,
                        updateLocations: false,
                        removeLocations: false,
                        airlineCrew: 'none',
                        readAirlineCrew: false,
                        createAirlineCrew: false,
                        updateAirlineCrew: false,
                        removeAirlineCrew: false,
                        aditionalCharges: 'none',
                        readAditionalCharges: false,
                        createAditionalCharges: false,
                        updateAditionalCharges: false,
                        removeAditionalCharges: false,
                        points: "none",
                        readPoints: false,
                        createPoints: false,
                        updatePoints: false,
                        removePoints: false,
                    }}
                />
            </div>
        </div>
    </LoadMask>
);

const mstp = (state) => ({
    ...state.roles,
    userInfo: state.login.userInfo,
})

export default connect(mstp, { ...actions, change})(Create);
