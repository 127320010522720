import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import List from "./RefundList";
import { actions } from "../../../../redux/modules/legacy-refund";
import RefundModal from './RefundModal';
import LoadMask from "../../Utils/LoadMask";

class ListContainer extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        find: PropTypes.func.isRequired,
    };

    state = {
        modal_refund: false,
        isCancel: false,
        showNotification: false,
        dataNotification: {}
    }

    handleSubmit = (id) => {
        this.props.find(this.showNotification, id)
    }

    openModalRefund = (value, type = false) => {
        this.setState({ modal_refund: value, isCancel: type});
    }

    submitOnModal = (values) => {
        this.props.postRefund(
            ()=>{this.openModalRefund(false)},
            this.showNotification,
            this.state.isCancel,
        )
    }

    showNotification = (data, show) => {
        this.setState({dataNotification: data, showNotification: show})
    }

    componentDidMount(){
        const { id } = this.props.match.params;
        this.handleSubmit(id);
        if(this.props.dataNotification)
            this.showNotification(this.props.dataNotification, true);
    }

    componentWillUnmount(){
        this.props.clear();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readReservations }) => readReservations))
                    this.handleSubmit(this.props.match.params.id);
            }
        }
    }

    render() {

        const open_modal = this.state.modal_refund;
        const { loaderModalRefund, userInfo: { permission } } = this.props;

        return (
            <div>
                <Modal
                    open={open_modal}
                    showCloseIcon={false}
                    onClose={() => this.openModalRefund(false)}
                    closeOnOverlayClick={false}
                >
                    <LoadMask loading={loaderModalRefund} dark blur>
                        <div className="d-flex flex-column m-4">
                        <h3 className="mb-5"> {!!this.state.isCancel ? 'Refund and Cancel' : 'Refund Amount'} </h3>
                            <RefundModal
                                onSubmit={this.submitOnModal}
                                closeModal={()=>{this.openModalRefund(false)}}
                                isCancel={this.state.isCancel}
                            />
                        </div>
                    </LoadMask>
                </Modal>

                {permission && permission.find(({ readReservations }) => !!readReservations) && (
                    <List
                        {...this.state}
                        {...this.props}
                        handleModal={(type)=>{this.openModalRefund(true, type)}}
                        onSubmit={this.handleSubmit}
                    />
                )}
            </div>

        );
    }
}

const mstp = (state) => ({
    ...state.legacyRefund,
    userInfo: state.login.userInfo,
});

const mdtp = { ...actions };

export default connect(mstp, mdtp)(ListContainer);
