import React from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import Search from './search';
import Table from "../../Utils/Grid";
import { BreakLine } from "../../Utils/tableOptions";
import { clientTypes } from "../../../utility/constants";
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import moment from 'moment';
import { settingsAccountsCustomers, settingsAccountsCustomersNew } from "../../../../routesConfiguration/paths";

const [person] = clientTypes;

export function dateFormatter(cell) {
    if (!cell) {
        return "";
    }
    try {
        const date = new Date(cell);
        return date.toDateString();
    } catch (e) {
        return cell;
    }
}

export default class ClientsGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.setClientsQuery({ userType: person });
        this.props.find();
    }

    componentWillUnmount() {
        this.props.setClientsQuery({});
    }

    labelPoint = (value) => value === 1 ? `${value} pt.` : `${value} pts.`;

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateVehicles }) => updateVehicles)){
                    this.props.setClientsQuery({ userType: person });
                    this.props.find();
                }
            }
        }
    }

    render() {
        const { data, loading, page, destroy, find, setSearch, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container">
                        <div className="grid-title d-flex justify-content-center align-items-center">
                            <h1 className="ml-0">
                                <strong>Clients</strong>
                            </h1>
                        </div>
                        <div className="d-flex flex-md-row flex-column-reverse">
                            {permission && permission.find(({ readClients }) => !!readClients) ? (
                                <Search onSubmit = {setSearch}
                                    className="flex-1 col-12 col-md-6 px-0 mt-md-5 mb-3"
                                />
                            ): <div className="flex-1 col-12 col-md-6 px-0 mt-md-5 mb-3"/>}
                            {permission && permission.find(({ createClients }) => !!createClients) && (
                                <div className="flex-1 col-12 col-md-6 px-0 d-flex align-items-end justify-content-end pb-3 pt-md-0 pt-3">
                                    <Link to={settingsAccountsCustomersNew}
                                        className="btn btn-primary ml-0 ml-sm-auto flex-nowrap flex-sm-wrap"
                                    >
                                        + Add Client
                                    </Link>
                                </div>
                            )}
                        </div>

                        <br />
                        {permission && permission.find(({ readClients }) => !!readClients) && (
                            <Table
                                onPageChange={find}
                                data={data}
                                page={page}
                                loading={loading}
                            >
                                <TableHeaderColumn
                                    tdStyle={BreakLine}
                                    thStyle={BreakLine}
                                    dataField="_id"
                                    isKey
                                    className="hide-column-header"
                                    dataFormat={(cell, row) =>
                                        <div className="d-flex flex-column-reverse flex-md-row">
                                            <div className="flex-1 d-flex flex-column">
                                                <label> <strong> Name: </strong> {row.firstName && row.firstName} {row.lastName && row.lastName} </label>
                                                {row.email && (
                                                    <label> <strong> Email:&nbsp;</strong> {row.email} </label>
                                                )}
                                                {row.mobileNo && (
                                                    <label> <strong> Email:&nbsp;</strong> {row.mobileNo} </label>
                                                )}
                                                {row.customerSince && (
                                                    <label> <strong> Customer Since:&nbsp;</strong> {moment(row.customerSince).format('MM/DD/YYYY LT')} </label>
                                                )}
                                                {row.points && (
                                                    <label> <strong> Points:&nbsp;</strong> {this.labelPoint(row.points.totalPoints)} - <RenderCurrency value={row.points.totalEarned} /> </label>
                                                )}
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                {activeFormatter({
                                                    editar: permission.find(({updateClients}) => !!updateClients) ? settingsAccountsCustomers : undefined,
                                                    eliminar: permission.find(({removeClients}) => !!removeClients) ? destroy : undefined,
                                                })(cell, row)}
                                            </div>
                                        </div>
                                    }
                                />
                            </Table>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
