import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { loadCheckout } from '../../../../../../redux/modules/itinerary';
import PaymentForm from './PaymentForm';
import Spinner from '../Spinner';


class PaymentContainer extends React.Component {

    componentDidMount() {
        this.props.loadCheckout();
    }

    render() {
        return this.props.loader ? (
            <div className="mt-5 p-3 bg-light d-flex justify-content-around align-items-center radius-1 text-secondary">
                <Spinner
                    styles={{
                        color: '#C3CBD3',
                        background: 'white',
                        margin: 0,
                    }}
                />
            </div>
        ) : (
            <PaymentForm
                onSubmit={this.props.handlePaymentFormSubmit}
                cardNumber={this.props.cardNumber}
            />
        );
    }
}

PaymentContainer.propTypes = {
    handlePaymentFormSubmit: PropTypes.func.isRequired,
};

const selector = formValueSelector('PaymentForm');
const mstp = state => ({
    loader: state.itinerary.loader,
    paymentInfo: state.itinerary.checkout,
    cardNumber: selector(state, 'card_number'),
});

const mdtp = {
    loadCheckout,
};

export default connect(mstp, mdtp)(PaymentContainer);
