import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderSelectField,
} from "Utils/renderField/renderField";
import { renderCurrency } from "../../Utils/renderField/renderField";
import { settingsRatesFees } from "../../../../routesConfiguration/paths";

const typeOptions = [
    { value: "Standard", label: "Standard" },
    { value: "Hourly", label: "Hourly" },
    { value: "Both", label: "Both" },
]


const Form = (props) => {
    const { handleSubmit, photo } = props;

    return (
        <form onSubmit={handleSubmit} className="w-100">
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15 pb-3">
                    <h2>
                        <strong>Additional Charge</strong>
                    </h2>
                </div>

                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="title">Title</label>
                            <Field
                                name="title"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="type">Type</label>
                            <Field
                                name="type"
                                component={renderSelectField}
                                type="select"
                                labelKey="label"
                                valueKey="value"
                                options={typeOptions}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="type">Price</label>
                            <Field
                                name="price"
                                component={renderCurrency}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="description">
                                Description
                            </label>
                            <Field
                                name="description"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link
                                    className="btn btn-secondary m-1"
                                    to={settingsRatesFees}
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const AdditionalChargeForm = reduxForm({
    form: "additionalChargeForm",
    validate: (data) => {
        return validate(data, {
            title: validators.exists()("Required field"),
            type: validators.exists()("Required field"),
            price: validators.exists()("Required field"),
        });
    },
})(Form);

export default AdditionalChargeForm;
