import React from "react";
import Form from "./BlogPostForm";
import LoadMask from "../../Utils/LoadMask";

export default class Create extends React.Component {
    render() {
        const { create, loading, userInfo: { permission } } = this.props;

        return (
            permission && permission.find(({ createBlogPost }) => !!createBlogPost) ? (
                <LoadMask loading={loading} dark blur>
                    <div className="row">
                        <div className="col-12">
                            <Form onSubmit={create} />
                        </div>
                    </div>
                </LoadMask>
            ) : null
        );
    }
}
