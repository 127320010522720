import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/airlineLivery';
import LoadMask from '../../Utils/LoadMask';
import FormCustomer from './formCustomer';
import { initializeDataAutocomplete } from '../../../../redux/modules/liveryEdition';


const Container = (props) => {

    useEffect(()=>{
        if(!props.airlineCodes || props.airlineCodes.length == 0)
            props.initializeDataAutocomplete();
    }, [])

    const { loading, setCompany, formValues } = props;

    return (
        <div className="d-flex flex-wrap justify-content-center pt-4 pt-lg-5">
            <div className="col-12 col-md-8 col-xl-6 rounded py-5"
                style={{ border: '1px solid #707070' }}
            >
                <h2 className="px-2 px-md-4 px-xl-5"> Schedule Search </h2>
                <div className="pt-4 pt-md-5 px-2 px-md-4 px-xl-5">
                    <LoadMask loading={loading} blur>
                        <FormCustomer
                            onSubmit={setCompany}
                            formValues={formValues}
                        />
                    </LoadMask>
                </div>
            </div>
        </div>
    )
}

const mstp = state => ({
    ...state.airlineLivery,
    airlineCodes: state.liveryEdition.airlineCodes,
    formValues: state.form.customerForm && state.form.customerForm.values,
});

const mdtp = {
    ...actions,
    initializeDataAutocomplete: initializeDataAutocomplete,
}

export default connect(mstp, mdtp)(Container);
