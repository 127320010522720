import React from 'react';
import numeral from 'numeral';
import isEmpty from 'lodash/isEmpty';
import TripInfoPdf from "../TripInfoPdf";
import TripInfo from '../TripInfo';
import Discount from '../Discount';
import Total from '../Total';
import moment from 'moment';
import LoadMask from '../../../../Utils/LoadMask';


class CheckOut extends React.Component {

    render() {
        const { itinerary, vehicle, createQuote, onClick, toggle } = this.props;

        if (!itinerary.itinerary) return null;

        return (
            <LoadMask loading={itinerary.loader || itinerary.loaderDiscount} blur>
                <div className="p-3 bg-light d-flex flex-column flex-wrap radius-1">
                    <div className="d-flex justify-content-end">
                        <button type="submit" onClick={onClick}
                            style={{
                                width: 'auto',
                                whiteSpace: 'nowrap',
                            }}
                            className="btn btn-primary px-4 py-3 m-2"
                        >
                            <strong>BOOK NOW</strong>
                        </button>
                    </div>

                    <TripInfo
                        itinerary={{
                            puAddress: itinerary.itinerary.from,
                            doAddress: itinerary.itinerary.to,
                            puTimeNoTimezone: `${moment(itinerary.itinerary.schedule).format('L')} ${itinerary.itinerary.time.hour}:${itinerary.itinerary.time.minutes}`,
                            duration: vehicle[0].duration,
                            distance: vehicle[0].distance,
                            vehiclePhoto: vehicle[0].photo,
                            vehicleDescription: vehicle[0].description,
                            vehicleCapacity: vehicle[0].capacity,
                            flightNumber: itinerary.itinerary.fligth_number,
                        }}
                    />

                    <Discount
                        discount={itinerary.discounts && itinerary.discounts.length> 0 && itinerary.discounts[0].discount}
                        price={vehicle[0].tripPrice}
                        gratuity={vehicle[0].gratuity}
                        gratuityPercent={vehicle[0].gratuityPercent}
                    />

                    <Total price={vehicle[0].price} />

                    <div className="d-flex flex-column flex-sm-row justify-content-between mt-3">
                        <div className="ml-sm-auto d-flex flex-column flex-sm-row justify-content-between">
                            <button type="button" onClick={toggle} className="flex-grow-1 btn btn-outline-primary px-4 py-3 m-2">
                                <strong>CLOSE</strong>
                            </button>
                            <div className="btn btn-primary w-100 my-2 py-3 px-4"
                                onClick={()=>createQuote(true)}
                            >
                                <strong> SAVE & PRINT </strong>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadMask>
        );
    }
}

export default CheckOut;
