import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Redirect } from "react-router-dom";
import ResetPassForm from "./ResetPassForm";
import ChangePassword from './ChangePassForm';
import { connect } from "react-redux";
import { actions } from "../../../redux/modules/login";
import LoadMask from "../Utils/LoadMask";
const logo = require("../../../../assets/img/logo.png");

class ResetPassword extends PureComponent {
    static propTypes = {
        nameError: PropTypes.bool.isRequired,
        passError: PropTypes.bool.isRequired,
        onSubmit: PropTypes.func.isRequired,
        hasNameError: PropTypes.func.isRequired,
        hasPassError: PropTypes.func.isRequired,
        submitError: PropTypes.string,
        loggedIn: PropTypes.bool.isRequired,
    };

    render() {

        const { sendEmailRecover, changePassword, loggedIn, location, loader, match: { params } } = this.props;

        if (loggedIn) {
            if (location.state)
                return <Redirect to={`${location.state.from.pathname}`} />;
            return <Redirect to={"/"} />;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-sm-8 mx-auto d-flex justify-content-center">
                        <img src={logo} className="img-unstyled" alt="logo" />
                    </div>
                </div>
                <br />
                <div className="row login-wrapper">
                    <div className="col-lg-4 col-md-4 col-sm-11 offset-lg-4 offset-md-4 offset-sm-3 offset-sm-0">
                        <div className="panel panel-flat">
                            <div className="panel-body">
                                <LoadMask loading = {loader} blur>
                                {params.token
                                  ? <div>
                                        <h5 className="text-center pv">Change your password.</h5>
                                        <ChangePassword onSubmit={(data) => changePassword(params.token, data)} />
                                        <br />
                                    </div>
                                  : <div>
                                        <h5 className="text-center pv">Recover your password.</h5>
                                        <ResetPassForm onSubmit={sendEmailRecover} />
                                        <br />
                                    </div>
                                }
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const ms2p = (state) => state.login;

export default connect(ms2p, { ...actions })(ResetPassword);

