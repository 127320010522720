import React from "react";
import moment from "moment";
import { Field, FieldArray, reduxForm } from "redux-form";
import { renderField, renderNumber } from "../../../Utils/renderField/renderField";
import _ from "lodash";
import renderTimePicker from "../../../../../TimePicker";
import { connect } from "react-redux";

export const renderListPassengers = (props) => {
    const { fields, meta: { error, submitFailed } } = props;
    return (
        <div className="">
            <div className="form-group">
                <strong>Passengers</strong>
            </div>
            {fields.length > 0 && fields.map((item, index) => (
                <div className="flex-1 row" key={index}>
                    <div className="col-4 form-group">
                        <label> Name </label>
                        <Field
                            name={`${item}.Name`}
                            component={renderField}
                            placeholder="Name"
                        />
                    </div>
                    <div className="col-4 form-group">
                        <label> Phone </label>
                        <Field
                            name={`${item}.Phone`}
                            component={renderNumber}
                            formatTemplate="(###)-###-####"
                            placeholder="Phone"
                        />
                    </div>
                    <div className="col-4 form-group">
                        <label> Email </label>
                        <div className="d-flex">
                            <Field
                                name={`${item}.Email`}
                                component={renderField}
                                placeholder="Email"
                                className="flex-1"
                            />
                            <button
                                type="button"
                                className="btn btn-secondary ml-2"
                                onClick={()=>fields.remove(index)}
                            >
                                X
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            <div className="d-flex justify-content-between">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => fields.push({})}
                >
                    ADD
                </button>
            </div>
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger align-self-center">
                    {error}
                </div>
            }
        </div>
    );
};

const Form = (props) => {
    const { handleSubmit, cancel, formValues } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
                <div className="w-100 d-flex flex-wrap">
                    <div className="col-12 form-group">
                        <FieldArray
                            name="PassengersList"
                            component={renderListPassengers}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex flex-column-reverse flex-md-row justify-content-md-end pt-4">
                    <button
                        type="button"
                        onClick={cancel}
                        className="btn btn-secondary mr-0 mr-md-2"
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary mb-2 mb-md-0">
                        Send
                    </button>
                </div>
            </div>
        </form>
    );
};

const CreateLiveryForm = reduxForm({
    form: "shuttleEditLiveryForm",
    validate: (data) => {
        const errors = {};
        if (!data.PassengersList || data.PassengersList.length === 0)
            errors.PassengersList = { _error: "Required Field" };
        else
            errors.PassengersList = data.PassengersList.reduce(
                (acum, current) => {
                    const error = {};
                    if (!current.Name) error.Name = "Required Field";
                    acum.push(error);
                    return acum;
                },
                []
            );
        return errors;
    },
})(Form);

const mstp = (state) => ({
    formValues: state.form && state.form.shuttleEditLiveryForm && state.form.shuttleEditLiveryForm.values,
});

export default connect(mstp)(CreateLiveryForm);
