import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

import { actions } from  "../../../../../redux/modules/promoCodesLocations";
import { actions as locationActions } from '../../../../../redux/modules/locations';
import IconTimes from "../../../../icons/times";
import LoadMask from "../../../Utils/LoadMask";
import Grid from './Grid';
import PromoCodeForm from "./PromoCodeForm";


class Container extends React.Component {

    state = {
        modal: false,
        edit: false,
    }

    showForm = (modal) => this.setState({ modal });
    setEdit = (edit) => this.setState({ edit });

    componentDidMount() {
        const { getLocationHeader, find, match: { params } } = this.props;
        getLocationHeader(params.id);
        find(params.id);
    }

    showEdit = (id) => {
        this.props.load2Update(id);
        this.setEdit(true);
        this.showForm(true);
    }

    submit = (values) => {
        if(!values.locationId)
            values.locationId = this.props.match.params.id;

        if(!this.state.edit) {
            this.props.create(values).then(result=>{
                if(result){
                    this.showForm(false);
                }
            });
        } else {
            this.props.update(values).then(result=>{
                if(result){
                    this.showForm(false);
                    this.setEdit(false);
                }
            });
        }
    }

    cancel = () => {
        this.showForm(false);
        this.setEdit(false);
    }

    render() {

        const { modal, edit } = this.state;
        const { userInfo: { permission }, match: { params } } = this.props;

        return permission && permission.find(({ readLocations }) => !!readLocations)
            ? <div className="d-flex flex-column p-2 p-md-5 flex-wrap justify-content-center align-items-center">
                <Grid
                    {...this.props}
                    setSearch={(search) => this.props.setSearch(params.id, search)}
                    destroy={(id) => this.props.destroy(params.id, id)}
                    showForm = {() => this.showForm(true)}
                    showEdit={this.showEdit}
                />

                <Modal isOpen={modal} toggle={()=>this.showForm(false)}
                    modalClassName="no-scroll-y" className="modal-dialog--big"
                >
                    <ModalBody className="modal-body--big"
                        style={{ overflowY: "auto", maxHeight: "95vh", marginTop: "1%", marginBottom: "1%", display: "block", }}
                    >
                        <button type="button" onClick={()=>this.showForm(false)} className="modal-body__close">
                            <IconTimes className="smallIcon"/>
                        </button>

                        <div className="d-flex flex-wrap justify-content-md-center">
                            <div className="col-12 col-md-10 py-md-5 py-3">
                                <LoadMask loading={false} blur>
                                    <PromoCodeForm
                                        cancel={this.cancel}
                                        onSubmit={this.submit}
                                        initialValues={{
                                            type: "trip"
                                        }}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
            : null
    }
}

const mstp = (state) => ({
    ...state.promoCodesLocations,
    userInfo: state.login.userInfo,
    header: state.locations.header,
});

const mdtp = {
    ...actions,
    getLocationHeader: locationActions.getLocationHeader
};

export default connect(mstp, mdtp)(Container);
