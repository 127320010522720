import { connect } from "react-redux";
import Details from "./detail";
import { actions } from "../../../../redux/modules/reviews";

const mstpPassenger = (state) => ({
    ...state.reviews,
    userInfo: state.login.userInfo,
    type: 0,
});

const DetailPassenger = connect(mstpPassenger, actions)(Details);

const mstpBooking = (state) => ({
    ...state.reviews,
    userInfo: state.login.userInfo,
    type: 1,
});

const DetailBooking = connect(mstpBooking, actions)(Details);

const mstpService = (state) => ({
    ...state.reviews,
    userInfo: state.login.userInfo,
    type: 2,
});

const DetailService = connect(mstpService, actions)(Details);

export default { DetailPassenger, DetailBooking, DetailService };
