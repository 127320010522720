import { handleActions } from "redux-actions";
import saveAs from "file-saver";
import Swal from "sweetalert2";
import moment from "moment";
import XLSX from "xlsx";
import _ from "lodash";
import { change, reset } from "redux-form";
import { pendingTransferColumns } from "../../common/utility/constants";
import app from "../../libs/apiClient";
import { push } from "react-router-redux";
import { errorHandler } from '../../common/utility/constants';
import { reservationsCharterBookings } from "../../routesConfiguration/paths";
import { actions as breadcrumbActions } from "./breadcrumb";

// Constants
const service = app.service("/api/reservation/");
const reportService = app.service("/api/reservations-report");

const PAGE = "RESERVATIONS_PAGE";
const DATA = "RESERVATIONS_DATA";
const LOADER = "RESERVATIONS_LOADER";
const QUERY = "RESERVATIONS_QUERY";
const RESERVATION = "RESERVATIONS_RESERVATION";
const MODAL_RESERVATIONS = "MODAL_RESERVATIONS";
const LOADING_MODAL = "CART_LOADING_MODAL";
const CART_ITEM = "CART_ITEM";
const LOADING_CART = "LOADING_CART";

// Pure Actions
const setData = (data) => ({
    type: DATA,
    data,
});

const setPage = (page) => ({
    type: PAGE,
    page,
});

export const setQuery = (query) => ({
    type: QUERY,
    query,
});

const setLoader = (loading) => ({
    type: LOADER,
    loading,
});

const setReservation = (reservation) => ({
    type: RESERVATION,
    reservation,
});

// Actions
export const find = (page, size = 10) => async (dispatch, getState) => {
    dispatch(setLoader(true));
    const { values = {} } = getState().form.reservationListFilter || {};
    const { query: stateQuery, page: statePage } = getState().reservations;
    page = page || statePage;

    const queryTrips = {
        $paginate: false,
        puTimeNoTimezone: { $gte: moment().startOf("day").format('YYYY-MM-DDTHH:mm:ss') },
        $select: ["cart"],
        canceled: values && values.canceled ? true : { $ne: true },
    };

    if (values) {
        if (values.startDate && values.endDate) {
            if (moment(values.startDate).isSameOrBefore(moment(values.endDate))) {
                const eD = moment(values.endDate).hours("23").minutes("59").seconds("59");
                queryTrips.puTimeNoTimezone = { $lte: eD, $gte: values.startDate };
            }
        }
        if (values.type) queryTrips.puTimeNoTimezone = undefined;

        if(!isNaN(parseInt(values.type)) && parseInt(values.type) < 1000000000){
            queryTrips.$or = [
                { confirmationNo: parseInt(values.type) },
                { liveryNo: parseInt(values.type) }
            ];
        }
        if (values.unassigned) {
            queryTrips.liveryNo = null;
        }
    }

    let trips = await app.service('/api/reservation/').find({ query: queryTrips });
    let carts = trips.reduce((acum, curr) => {
        let found = acum.find(item=> JSON.stringify(item) === JSON.stringify(curr.cart));
        if(!found)
            acum.push(curr.cart);
        return acum;
    }, []);

    const query = {
        query: {
            $skip: (page - 1) * size,
            $limit: size,
            ...stateQuery,
            _id: { $in: carts },
            $sort: { _id: -1 },
            $populate: ['trips', 'lastEdit'],
            canceled: values && values.canceled ? true : { $ne: true },
        },
    };

    if (values) {
        if (values.filter) {
            if(values.filter === "booking"){
                query.query.$sort = { _id: -1 }
            } else if(values.filter === "contactName"){
                query.query.$sort = { contactFirstNm: 1 }
            }
        }

        if (values.type) {
            if (!isNaN(values.type)) {
                if (parseInt(values.type) > 1000000000) {
                    query.query.$or = [{ confirmationNo: parseInt(values.type) }];
                }
            } else {
                query.query.$or = [
                    { contactFirstNm: { $regex: _.escapeRegExp(values.type), $options: "i" } },
                    { contactLastNm: { $regex: _.escapeRegExp(values.type), $options: "i" } },
                    { contactEmail: { $regex: _.escapeRegExp(values.type), $options: "i" } },
                ];
            }
        }
    }

    app.service("/api/cart")
        .find(query)
        .then((response) => {
            dispatch(setData(response));
            dispatch(setPage(page));
        })
        .catch((err) => {
            dispatch(errorHandler(err));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

export const destroy = (id) => async (dispatch, getState) => {
    dispatch(setLoader(true));
    try {
        Swal({
            title: "Are you sure?",
            text: "You cannot undo this action!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            reverseButtons: true,
        }).then(async (result) => {
            if (result.value) {
                await service.remove(id);
                dispatch(push(reservationsCharterBookings));
            }
        });
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(setLoader(false));
    }
};

const download = (params = {}) => (dispatch) => {
    const {
        columns: rows,
        startDate,
        endDate,
    } = params;

    if (rows) {
        const query = {
            canceled: { $ne: true },
        };
        if (startDate && endDate) {
            query.puTimeNoTimezone = {
                $gte: startDate,
                $lte: endDate,
            };
        }
        dispatch(setLoader(true));
        reportService
            .find({ query })
            .then((data) => {
                const headers = rows.split(",");
                const collection = [
                    headers.map((value) => {
                        return _.find(pendingTransferColumns, { value }).label;
                    }),
                ];
                data.data.forEach((item) => {
                    collection.push(
                        headers.map((header) => {
                            return item[header] ? item[header] : "";
                        })
                    );
                });
                const wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Transfer Reservations",
                    Subject: "Reservations",
                    Author: "BBC EXPRESS",
                    CreatedDate: new Date(),
                };
                const ws = XLSX.utils.aoa_to_sheet(collection);
                XLSX.utils.book_append_sheet(wb, ws, "Transfer Reservations");
                const wbout = XLSX.write(wb, {
                    bookType: "xlsx",
                    type: "binary",
                });
                function s2ab(s) {
                    const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
                    const view = new Uint8Array(buf); // create uint8array as viewer
                    for (let i = 0; i < s.length; i++)
                        view[i] = s.charCodeAt(i) & 0xff; // convert to octet
                    return buf;
                }
                saveAs(
                    new Blob([s2ab(wbout)], {
                        type: "application/octet-stream",
                    }),
                    "Pending Reservations.xlsx"
                );
            })
            .catch((err) => {
                dispatch(errorHandler(err));
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    }
};

const resendEmail = (id) => (dispatch) => {
    Swal.fire({
        title: "Resend confirmation email?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Send",
        cancelButtonText: "No, cancel",
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        reverseButtons: true,
    }).then((response) => {
        if (response.dismiss) return;

        dispatch({ type: LOADING_CART, loading: true });

        app.service('/api/cart').patch(id, { action: "RESEND_EMAIL_RECEIPT" }).then(() => {
            Swal.fire({
                title: "Email sent",
                type: "success",
                confirmButtonColor: "#D50032",
            });
        }).catch((err) => {
            dispatch(errorHandler(err));
        }).finally(() => {
            dispatch({ type: LOADING_CART, loading: false });
        });
    });
};

const getReservation = (id) => (dispatch) => {
    dispatch(setLoader(true));
    service
        .get(id, { query: { $populate: ['quoteId'] }})
        .then((data) => {
            dispatch(setReservation(data));
        })
        .catch((err) => {
            dispatch(errorHandler(err));
        })
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setAssigned = (liveryNo) => async (dispatch, getState) => {
    dispatch(setLoader(true));
    const { _id } = getState().reservations.reservation;

    try {
        const res = await service.patch(_id, {
            driverAssigned: true,
            liveryNo,
        });
        return res;
    } catch (err) {
        dispatch(errorHandler(err));
    } finally {
        dispatch(setLoader(false));
    }
};

export const getCart = (id) => (dispatch, getStore) => {
    dispatch({ type: LOADING_CART, loading: true });
    app.service('/api/cart').get(id, {
        query: {
            $populate: [{
                path: 'trips',
                populate: [{
                    path: 'additionalCharges',
                    populate: ['aditionalId']
                }, {
                    path:'stops',
                    options: {
                        sort: { sequence_no: 1 }
                    }
                }]
            }]
        }
    }).then(result=>{
        dispatch({ type:  CART_ITEM, item: result });
        const { updateCustomLabel } = breadcrumbActions;
        dispatch(updateCustomLabel(id, result.confirmationNo));
    }).catch(error=>{
        errorHandler(error);
    }).finally(()=>{
        dispatch({ type: LOADING_CART, loading: false });
    })
}

export const refund = (cart, tripId, isCancel) => (dispatch, getStore) =>{
    dispatch(setLoaderModal(true));
    const { values = {} } = getStore().form.refundMountForm || {};
    let agentNotes = {
        refundAmount: values.amount,
        agentNotes: values.agent_notes
    }

    const params = {
        action: `${isCancel ? 'CANCEL_AND_REFUND' : 'REFUND_TRANSACTION'}`,
        agentNotes
    }

    service.patch(tripId, params).then((response) => {
        Swal.fire({
            title: "Success",
            text: `The amount has been refunded`,
            type: "success",
            confirmButton: "OK",
            confirmButtonColor: "#d50032",
        }).then(() => {
            dispatch(getCart(cart._id));
        })
    }).catch((err) => {
        errorHandler(error);
    }).finally(()=>{
        dispatch(setLoaderModal(false))
    })
};

const setLoaderModal = (loading) => ({
    type: LOADING_MODAL,
    loading,
})

export const openModalReservation = (modalReservations) => (dispatch) => {
    dispatch({
        type: MODAL_RESERVATIONS,
        modalReservations
    });
}

const clearDates = () => (dispatch, getStore) =>{
    dispatch(change('reservationListFilter','startDate', ""));
    dispatch(change('reservationListFilter','endDate', ""));
    dispatch(change('reservationListFilter','canceled', false));
    dispatch(change('reservationListFilter','unassigned', false));
    dispatch(find(1));
}

const clear = () => (dispatch, getStore) => {
    dispatch(setReservation({}));
}

const linkPoints = () => async (dispatch, getStore) => {
    const cartService = app.service("/api/cart");
    const clientService = app.service("/api/client");

    dispatch({ type: LOADING_CART, loading: true });
    try {
        const { cartItem = {} } = getStore().reservations;
        const { _id: id, contactEmail } = cartItem;

        if (cartItem.userId && cartItem.isPointsLinked) {
            throw new Error("Points are already linked");
        }

        // Search client that matches the email
        const clientResponse = await clientService.find({
            query: { email: contactEmail },
        });
        if (clientResponse.total === 0) {
            throw new Error(
                `Could not find a registered user with an email that matches ${contactEmail}`
            );
        }

        // Get client id
        const userId = clientResponse.data[0]._id;

        await cartService.patch(id, { userId, isPointsLinked: true });
        dispatch(getCart(id));
        Swal.fire({
            title: "Points linked",
            type: "success",
            confirmButtonColor: "#D50032",
        });
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch({ type: LOADING_CART, loading: false });
    }
};

export const actions = {
    find,
    clear,
    destroy,
    getCart,
    download,
    clearDates,
    setAssigned,
    resendEmail,
    getReservation,
    openModalReservation,
    linkPoints,
};

// Reducers
const reducers = {
    [LOADER]: (state, { loading }) => ({ ...state, loading }),
    [PAGE]: (state, { page }) => ({ ...state, page }),
    [QUERY]: (state, { query }) => ({ ...state, query }),
    [DATA]: (state, { data }) => ({ ...state, data }),
    [RESERVATION]: (state, { reservation }) => ({ ...state, reservation }),
    [MODAL_RESERVATIONS]: ( state, { modalReservations }) => ({ ...state, modalReservations }),
    [LOADING_MODAL]: (state, { loading }) => ({ ...state, loadingModal: loading }),
    [CART_ITEM]: (state, { item }) => ({ ...state, cartItem: item }),
    [LOADING_CART]: (state, { loading }) => ({ ...state, loadingCart: loading }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    query: {},
    loading: false,
    reservation: {},
    modalReservations: false,

    cartItem: {},
    loadingCart: false,
};

export default handleActions(reducers, initialState);
