import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import List from "./ReservationList";
import { actions } from "../../../../redux/modules/legacy-reservations";
import { cleanDataModal } from '../../../../redux/modules/itinerary';

const screenMd = 768;

class ListContainer extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        find: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            size: window.innerWidth >= screenMd ? 10 : 5
        };

        this.handleModal = this.handleModal.bind(this);
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        this.props.find(1, this.state.size);
        window.onresize = ()=>{
            this.checkSizeScreen(window.innerWidth);
        }
    }

    componentWillUnmount(){
        window.onresize = null;
    }

    checkSizeScreen = (sizeScreen) => {
        if(sizeScreen < screenMd && this.state.size == 10){
            this.setState({ size: 5 });
            this.props.find(this.props.page, 5);
        } else if(sizeScreen >= screenMd && this.state.size == 5){
            this.setState({ size: 10 });
            this.props.find(this.props.page, 10);
        }
    }

    handleModal(open) {
        this.setState({ open });
    }

    download(data) {
        this.props.download(data);
        this.setState({ open: false });
    }

    openModalReservation = (value) => {
        if(value == true) this.props.cleanDataModal();
        this.props.openModalReservation(value);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readReservations }) => readReservations))
                    this.props.find(1, this.state.size);
            }
        }
    }

    render() {
        return (
            <List
                {...this.state}
                {...this.props}
                destroyReserv={this.props.destroy}
                handleModal={this.handleModal}
                openModalReservation={this.openModalReservation}
            />
        );
    }
}

const mstp = (state) => ({
    ...state.legacyReservations,
    userInfo: state.login.userInfo
});

const mdtp = { ...actions, cleanDataModal };

export default connect(mstp, mdtp)(ListContainer);
