import React from "react";
import moment from "moment";
import numeral from "numeral";
import { formatAuthorizeTransactionStatus } from "../../../utility/constants";

const formatTime = (time) => {
    if (time == 1) return  time + 'Hour';
    else return time + 'Hours'
}

const ReservationRefundDetail = ({
    reservation: {
        _id,
        liveryNo,
        puAddress,
        doAddress,
        puTime,
        duration,
        hourlyDuration,
        flightDetails,
        specialNotes,
        airline,
        flightNo,
        nameSign,
        driverNote,
        promoCode,
        guestFirstNm,
        guestLastNm,
        guestMobile,
        guestEmail,
        puTimeNoTimezone,
        vehicleDescription,
        transactionId,
        confirmationNo,
        transactionAmount,
        transactionStatus,
        refund,
        canceled,
        totalRefund,
    },
    onlyCancelTrip,
    handleCancel,
    handleAction,
    history,
    permission,
}) => (
    <div className="row">
        {_id && (
            <div className="col-12">
                <h3>
                    <strong>Reservation detail</strong>
                </h3>
            </div>
        )}
        {confirmationNo && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Confirmation Number</strong>
                </div>
                <div>{confirmationNo}</div>
            </div>
        )}
        {vehicleDescription && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Vehicle</strong>
                </div>
                <div>{vehicleDescription}</div>
            </div>
        )}
        {puAddress && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Pick up Address</strong>
                </div>
                <div>{puAddress}</div>
            </div>
        )}
        {doAddress && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Drop off Address</strong>
                </div>
                <div>{doAddress}</div>
            </div>
        )}
        {puTime && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Pick up Date</strong>
                </div>
                {puTimeNoTimezone ?
                    moment.parseZone(puTimeNoTimezone).format("MM/DD/YYYY"):
                    moment.parseZone(puTime).format("MM/DD/YYYY")
                }
            </div>
        )}
        {puTime && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Pick-up Time</strong>
                </div>
                {puTimeNoTimezone ?
                    moment.parseZone(puTimeNoTimezone).format("HH:mm A"):
                    moment.parseZone(puTime).format("HH:mm A")
                }
            </div>
        )}
        {duration && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Trip Duration</strong>
                </div>
                <div>{duration}</div>
            </div>
        )}
        {hourlyDuration && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Hourly Duration</strong>
                </div>
                <div>{formatTime(hourlyDuration)} </div>
            </div>
        )}
        {airline && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Airline</strong>
                </div>
                <div>{airline}</div>
            </div>
        )}
        {flightNo && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Flight Number</strong>
                </div>
                <div>{flightNo}</div>
            </div>
        )}
        {nameSign && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Name sign</strong>
                </div>
                <div>{nameSign}</div>
            </div>
        )}
        {driverNote && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Driver Note</strong>
                </div>
                <div>{driverNote}</div>
            </div>
        )}
        {promoCode && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Promo Code</strong>
                </div>
                <div>{promoCode}</div>
            </div>
        )}
        {guestFirstNm && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>First Name</strong>
                </div>
                <div>{guestFirstNm}</div>
            </div>
        )}
        {guestLastNm && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Last Name</strong>
                </div>
                <div>{guestLastNm}</div>
            </div>
        )}
        {guestMobile && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Mobile</strong>
                </div>
                <div>{guestMobile}</div>
            </div>
        )}
        {guestEmail && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Email</strong>
                </div>
                <div>{guestEmail}</div>
            </div>
        )}
        {specialNotes && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Additional Notes</strong>
                </div>
                <div>{specialNotes}</div>
            </div>
        )}
        {flightDetails && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Flight Notes</strong>
                </div>
                <div>{flightDetails}</div>
            </div>
        )}
        {transactionId && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Transaction Id</strong>
                </div>
                <div>{transactionId}</div>
            </div>
        )}
        {transactionAmount && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Transaction Amount</strong>
                </div>
                <div>${numeral(transactionAmount).format("0,0.00")}</div>
            </div>
        )}
        {liveryNo && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Livery Trip ID</strong>
                </div>
                <div>{liveryNo}</div>
            </div>
        )}
        {transactionStatus && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong> Authorize Transaction Status </strong>
                </div>
                <div>{formatAuthorizeTransactionStatus(transactionStatus, { declinedSwal: false })}</div>
                <div>
                    {!canceled && transactionStatus !== 'settledSuccessfully'
                    ? <small className="text-danger">When the transaction does not have the status "settledSuccessfully". partial refunds cannot be made yet, only the total amount of the transaction can be cancelled.</small>
                    : ''
                    }
                </div>
            </div>
        )}
        {_id && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Total Refund</strong>
                </div>
                <div>  $ {numeral(totalRefund).format("0,0.00")} </div>
            </div>
        )}
        {canceled && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong> Trip status </strong>
                </div>
                <div> <span className="text-danger"> Canceled </span> </div>
            </div>
        )}
        {_id && !canceled && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Remaining amount</strong>
                </div>
                <div>  $ {numeral(transactionAmount - totalRefund).format("0,0.00")} </div>
            </div>
        )}

        <div className="col-12 d-flex flex-md-row flex-column justify-content-betweeen align-items-end mt-4">
            <div className="d-flex justify-content-start" style={{ flex: 1 }}>
                <button
                    type="button"
                    className="btn btn-secondary mt-2 mt-md-0"
                    onClick={() => history.goBack()}
                >
                    Back
                </button>
            </div>
            {permission && permission.find(({ updateReservations }) => !!updateReservations) &&
                _id && (transactionAmount - totalRefund > 0) && (
                <div className="d-flex justify-content-end">
                    {!canceled
                      ? transactionStatus && transactionStatus === 'settledSuccessfully'
                            ? <React.Fragment>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={()=>{handleAction(false)}}
                                    >
                                    Refund
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary ml-2"
                                    onClick={()=>{handleCancel(true)}}
                                >
                                    Refund and Cancellation
                                </button>
                            </React.Fragment>
                            : <button
                                type="button"
                                className="btn btn-primary ml-2"
                                onClick={onlyCancelTrip}
                            >
                                Void and Cancellation
                            </button>
                      : ''
                    }
                </div>
            )}
        </div>

        {refund && (
            <div className="col-12 p-3">
                <h5 style={{fontWeight:"bold"}}> Refunds  </h5>
                <hr/>
                <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th> Transaction ID </th>
                            <th> Refund amount </th>
                            <th> Agent Notes </th>
                        </tr>
                    </thead>
                    <tbody>
                        {refund.map((item,index)=>{
                            return (
                                <tr key={item._id || index}>
                                    <td> {item.transactionId ? item.transactionId : 'no transaction'} </td>
                                    <td> $ {numeral(item.refundAmount).format("0,0.00")} </td>
                                    <td> {item.agentNotes} </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )}

    </div>
);

export default ReservationRefundDetail;
