import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/apply';
import { getPathWithParams, hrApplicationsId } from '../../../../routesConfiguration/paths';
import { Link } from 'react-router-dom';
import IconAngleLeft from '../../../icons/angleLeftIcon';
import IconWatch from '../../../icons/watch';
import { IconTrashAlt } from '../../../icons/trash';
import LoadMask from '../../Utils/LoadMask';
import Pagination from '../../Utils/pagination';
import NumberFormat from 'react-number-format';
import ApplyForm from '../Details/form';

const ApplyGrid = (props) => {

    const {
        find,
        page,
        data,
        setSearch,
        search,
        history,
        create,
        destroy,
        downloadData,
        loadingGrid,
        loadingForm,
        formValues
    } = props;

    React.useEffect(() => {
        find();
    }, [find]);

    const [newApply, setNewApply] = useState(false)

    return (
        newApply ? (
            <div className="d-flex flex-wrap justify-content-center px-5">
                <div className="col-12 mb-5 px-5">
                    <div className="px-5"> 
                        <ApplyForm
                        onSubmit={create}
                        onCancel={() => setNewApply(false)}
                        loading={loadingForm}
                        formValues={formValues}
                        initialValues={{
                            employment_history: [{}],
                            references: [{}],
                            dot_license: [{}],
                            dot_addresses: [{}],
                            vehicle_type: {
                                sedans: false,
                                suv: false,
                                vans: false,
                                bus: false,
                                coach: false,
                            },
                            background_check: '1',
                            work_before: {value:"NO", label:"NO"},
                            accidents: {
                                yes: "0",
                                reason: ""
                            },
                            traffic_violations: {
                                yes: "0",
                                reason: ""
                            },
                            denied_license: {
                                yes: "0",
                                reason: ""
                            },
                            suspended_license: {
                                yes: "0",
                                reason: ""
                            },
                            convicted: {
                                yes: '0',
                                reason: ''
                            },
                            physical_limitations: {
                                yes: '0',
                                reason: ''
                            },
                            status: {value: "application_submitted",
                                     label: "Application Submitted"
                            }, 
                        }}
                    />
                    </div>
                </div>
            </div>
        ) : (
            <div className="d-flex flex-wrap justify-content-center px-4">
                <div className="col-12 d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={() => history.goBack()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="w-100 d-flex mb-1">
                        <h3 className="flex-1 text-left"> Applications </h3>
                        <button type="button" className="btn btn-primary" onClick={() => { setNewApply(!newApply) }}>
                            ADD
                        </button>
                    </div>
                    <div className="w-100 d-flex flex-wrap py-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search by name or email"
                            value={search}
                            onChange={(event) => {
                                event.preventDefault();
                                setSearch(event.target.value);
                            }}
                        />
                    </div>
                    <div className="w-100">
                        <LoadMask loading={loadingGrid} blur>
                            <div className="react-bs-table react-bs-table-bordered">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr className="px-3 py-2">
                                            <td className="w-100 d-flex">
                                                <span className="col-2 px-0 bold">Full Name</span>
                                                <span className="col-2 px-0 bold">Phone</span>
                                                <span className="col-2 px-0 bold">Email Address</span>
                                                <span className="col-1 px-0 bold">Experience</span>
                                                <span className="col-2 px-0 bold">City</span>
                                                <span className="col-1 px-0 bold">State</span>
                                                <span className="col-1 px-0 bold">Age</span>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.total > 0 &&
                                            data.data.map((item, index) => (
                                                <tr
                                                    className="px-3 py-2"
                                                    key={index}
                                                >
                                                    <td className="w-100 d-flex">
                                                        <span className="col-2 px-0 text-truncate"> {item.first_name} {item.middle_name} {item.last_name} </span>
                                                        <span className="col-2 px-0 text-truncate">
                                                            <NumberFormat
                                                                format="(###)-###-####"
                                                                value={item.phone}
                                                                displayType="text"
                                                            />
                                                        </span>
                                                        <span className="col-2 px-0 text-truncate"> {item.email} </span>
                                                        <span className="col-1 px-0 text-truncate"> {item.experience && item.experience.label ? item.experience.label : item.experience} </span>
                                                        <span className="col-2 px-0 text-truncate"> {item.city} </span>
                                                        <span className="col-1 px-0 text-truncate"> {item.state} </span>
                                                        <span className="col-1 px-0 text-truncate"> {item.age ? `${item.age} Year${item.age !== 1 ? 's' : ''}` : ''} </span>
                                                        <span className="col-1 px-0 d-flex justify-content-center">
                                                            <Link
                                                                to={getPathWithParams(
                                                                    hrApplicationsId,
                                                                    "id",
                                                                    item._id
                                                                )}
                                                                className="text-dark text-decoration-none px-1"
                                                            >
                                                                <IconWatch className="smallIcon clickable" />
                                                            </Link>
                                                            <span onClick={() => destroy(item._id)}>
                                                                <IconTrashAlt className="smallIcon px-1 clickable" />
                                                            </span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="d-flex">
                                <div className="d-flex flex-1">
                                    <div className="d-flex clickable" onClick={downloadData}>
                                        <span style={{ width: "12px" }}>
                                            <svg
                                                xmlns="SVG namespace "
                                                viewBox="0 0 384 512"
                                            >
                                                <path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" />
                                            </svg>
                                        </span>
                                        <span className="pl-2 font-bold" style={{ textDecoration: "underline" }}>
                                            Download Driver Data
                                        </span>
                                    </div>
                                </div>
                                <Pagination
                                    total={data.total}
                                    page={page}
                                    find={find}
                                    itemsPerPage={25}
                                />
                            </div>
                        </LoadMask>
                    </div>
                </div>
            </div>


        )
    )
}

const mstp = (state) => ({
    ...state.apply,
    formValues: state.form.applyForm && state.form.applyForm.values,
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(ApplyGrid);
