import React from "react";

const IconPost = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -960 960 960"
            className={className}
        >
            <path
                fill="currentColor"
                d="M120-120v-720h720v720H120Zm80-262h560v-51H200v51Zm0 119h560v-41H200v41Z"
            />
        </svg>
    );
};

export default IconPost;
