import React from "react";
import { Field, FieldArray, reduxForm } from "redux-form";
import { renderField, renderCount, renderDatePicker, renderSelectField } from "../../../Utils/renderField/renderField";
import _, { isFunction } from "lodash";
import { renderListPassengers } from "./editForm";
import renderTimePicker from "../../../../../TimePicker";
import { shuttleAddresses } from "../../../../utility/constants";

const Form = (props) => {
    const { handleSubmit, cancel, company } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
                <div className="w-100 d-flex flex-wrap">
                    <div className="col-8 form-group">
                        <strong htmlFor="date">Pick-Up Date</strong>
                        <Field
                            name="date"
                            numberOfMonths={1}
                            component={renderDatePicker}
                            className="flex-1 w-100 p-0"
                        />
                    </div>

                    <div className="col-4 form-group">
                        <strong>Pick-Up Time </strong>
                        <Field
                            id="timepicker1"
                            name="time"
                            placeholder="12:00 PM"
                            component={renderTimePicker}
                            minuteStep={30}
                            style={{ height: "none" }}
                        />
                    </div>
                    <div className="col-12 form-group">
                        <strong>Pick Up</strong>
                        <Field
                            name="pickUp"
                            component={renderSelectField}
                            labelKey="label"
                            valueKey="id"
                            options={shuttleAddresses.filter((item) => item.companies.includes(company.liveryId))}
                            placeholder="Pick up address"
                        />
                    </div>
                    <div className="col-12 form-group">
                        <FieldArray
                            name="ListPassengers"
                            component={renderListPassengers}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex flex-column flex-md-row justify-content-md-end pt-4">
                    <button
                        type="button"
                        onClick={cancel}
                        className="btn btn-secondary mb-2 mr-0 mb-md-0 mr-md-2"
                    >
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                        Send
                    </button>
                </div>
            </div>
        </form>
    );
};

const CreateLiveryForm = reduxForm({
    form: "shuttleCreateForm",
    initialValues: {
        ListPassengers: [{}]
    },
    validate: (data) => {
        const errors = {};
        if (!data.date) errors.date = "Required Field";
        if (!data.time) errors.time = "Required Field";
        if (!data.pickUp) errors.pickUp = "Required Field";
        if (!data.dropOff) errors.dropOff = "Required Field";
        if (!data.ListPassengers || data.ListPassengers.length === 0)
            errors.ListPassengers = { _error: "Required Field" };
        else
            errors.ListPassengers = data.ListPassengers.reduce(
                (acum, current) => {
                    const error = {};
                    if (!current.Name) error.Name = "Required Field";
                    acum.push(error);
                    return acum;
                },
                []
            );
        return errors;
    },
})(Form);

export default CreateLiveryForm;
