import { connect } from "react-redux";
import Grid from "./PointGrid";
import { actions } from "../../../../redux/modules/points";

const mstp = (state) => ({
    ...state.points,
    userInfo: state.login.userInfo,
})

export default connect(mstp, actions)(Grid);
