import React from 'react';
import IconDownload from '../../../icons/download';
import LoadMask from '../../Utils/LoadMask';
import moment from 'moment';
import IconCheck from '../../../icons/check';
import IconTimes from '../../../icons/times';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import AirlineRouteForm from '../../crewAdmin/create/airlineRouteForm';
import HotelForm from '../../crewAdmin/create/hotelForm';
import { reservationsAirlineUploads } from '../../../../routesConfiguration/paths';


class ViewResults extends React.Component{

    componentDidMount(){
        const { params } = this.props.match;
        this.props.viewResults(params.id)
    }

    render() {
        const {
            loadingModal: loading,
            item: data,
            downloadExcel,
            openModalRoute,
            loadingRouteHotel,
            closeModalRoute,
            openViewHotel,
            closeViewHotel,
            viewHotel,
            modalRoute,
            reLoadRejected,
            createHotel,
            match: { params },
            userInfo: { permission },
        } = this.props;

        return (
            <div>
                <Modal
                    open={modalRoute}
                    showCloseIcon={true}
                    onClose={closeModalRoute}
                    closeOnOverlayClick={false}
                >
                    {viewHotel
                      ? <LoadMask loading={loadingRouteHotel || false} blur>
                            <HotelForm
                            onSubmit = {async (data)=>{
                                await createHotel(data, 2);
                                closeViewHotel(true, data.landMark);
                            }}
                            cancel={()=>closeViewHotel()}
                            />
                        </LoadMask>
                      : <LoadMask loading={loadingRouteHotel || false} dark blur>
                            <AirlineRouteForm
                                onSubmit={async (data)=> {
                                    await this.props.createAirlineRoute(data);
                                    await this.props.checkRoutes();
                                    closeModalRoute();
                                }}
                                getAirlines = {this.props.getAirlines}
                                getHotels = {this.props.getHotels}
                                getAirportsMeet={this.props.getAirportMeetRoute}
                                cancel={closeModalRoute}
                                showBtnNewHotel={true}
                                onClickNewHotel={openViewHotel}
                            />
                        </LoadMask>
                    }
                </Modal>

                {permission && permission.find(({readAirlineSchedule}) => !!readAirlineSchedule) && (
                    <LoadMask loading={loading} dark blur>
                        <div className="d-flex flex-column">
                            <div className="flex-1">
                                <h2 className="t-green"> SUCCESS! </h2>
                                <h3 className="mb-5">
                                    {`${data && data.data_success ? data.data_success.length : 0} TRIPS ADDED `}
                                    <span onClick = {() => downloadExcel(data, true)} style={{ cursor: "pointer"}}>
                                        <IconDownload className="menu_icon t-primary" />
                                    </span>
                                </h3>
                            </div>
                            {data && data.data_rejected && data.data_rejected.length > 0 && (
                                <React.Fragment>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex">
                                            {data.missing_routes && data.missing_routes.length > 0 && (
                                                <div className="flex-1 d-flex flex-column mb-3">
                                                    <label className="d-flex justify-content-start">
                                                        <strong> Missing Routes </strong>
                                                    </label>
                                                    {data.missing_routes.map( (item, index) =>
                                                        <div key={index} className="d-flex justify-content-start align-items-center">
                                                            <span style={{ color: item.route ? 'green' : 'red' }}>
                                                                {item.route
                                                                    ? <IconCheck className="menu_icon pb-0"/>
                                                                    : <IconTimes className="menu_icon pb-0"/>
                                                                }
                                                            </span>
                                                            &nbsp;
                                                            <label className="mb-0"
                                                                style={{ color: item.route ? 'green' : 'red' }}
                                                            >
                                                                {item.from} - {item.to}
                                                            </label>
                                                            &nbsp;
                                                            {!item.route && permission && (permission.find(({createAirlineSchedule}) => !!createAirlineSchedule) ||
                                                                permission.find(({updateAirlineSchedule}) => !!updateAirlineSchedule)) && (
                                                                <button type="button"
                                                                    className="btn btn-link p-0"
                                                                    onClick={()=>openModalRoute(item)}
                                                                >
                                                                    (add)
                                                                </button>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex-1 d-flex flex-row align-items-end">
                                            <h5 className="flex-1">
                                                The following trips could not be created in livery coach &nbsp;
                                                <span onClick = {() => downloadExcel(data, false)} style={{ cursor: "pointer"}}>
                                                    <IconDownload className="menu_icon" />
                                                </span>
                                            </h5>
                                            {permission && permission.find(({updateAirlineSchedule}) => !!updateAirlineSchedule) && (
                                                <button type="button"
                                                    className="btn btn-secondary mb-2"
                                                    disabled={data.status !== 'done' ? true : false}
                                                    onClick={()=>reLoadRejected(params.id)}
                                                >
                                                    Reload data
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                <div className="flex-1 table-head-sticky">
                                    <table className="table table-striped">
                                        <thead className="thead-dark">
                                            <tr>
                                                <th> Date </th>
                                                <th> PU Time </th>
                                                <th> PU Location </th>
                                                <th> DO Location </th>
                                                <th> # Pax </th>
                                                <th> Flight </th>
                                                <th> Error </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.data_rejected.map( (row, index) =>
                                                <tr key ={index}>
                                                    <td className="center"> {row.data_file_extracted.date_of_service && moment(row.data_file_extracted.date_of_service).format('MM/DD/YYYY')} </td>
                                                    <td className="center"> {row.data_file_extracted.pick_up_time} </td>
                                                    <td> {row.data_file_extracted.from_location} </td>
                                                    <td> {row.data_file_extracted.to_location} </td>
                                                    <td className="center"> {row.data_file_extracted.number_of_passengers} </td>
                                                    <td className="center">
                                                        {row.data_file_extracted.arlineFlight
                                                            ? row.data_file_extracted.arlineFlight
                                                            : row.data_file_extracted.flight_number
                                                                ? row.data_file_extracted.airlineCode
                                                                    ? row.data_file_extracted.airlineCode + ' ' + row.data_file_extracted.flight_number
                                                                    : data && data.airline && data.airline.code + ' ' + row.data_file_extracted.flight_number
                                                                : ''
                                                        }
                                                    </td>
                                                    <td>{!!row.error && !!row.error.message && row.error.message}  </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            )}
                            <div className="d-flex justify-content-center align-items-center mt-4">
                                <Link to={reservationsAirlineUploads}
                                    className="btn btn-primary btn-lg"
                                >
                                    Back
                                </Link>
                            </div>
                        </div>
                    </LoadMask>
                )}
            </div>
        )
    }
}

export default ViewResults;
