import React from "react";
import classNames from "classnames";
import Datetime from "react-datetime";
import moment from "moment";
import "../../../../../style/datetime.css";

export default class renderDatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.renderInput = this.renderInput.bind(this);
    }

    componentDidMount() {
        const { input } = this.props;
        input.onChange(moment(input.value));
    }

    renderInput(props) {
        const { showButton = true } = this.props;
        return (
            <div className="d-flex">
                <input {...props} />
                {showButton && (
                    <button
                        type="button"
                        className="btn btn-danger ml-1"
                        onClick={() => {
                            this.props.input.onChange("");
                            if (this.props.cb) this.props.cb();
                        }}
                    >
                        X
                    </button>
                )}
            </div>
        );
    }

    render() {
        const {
            input,
            className = "",
            dateFormat = true, // false to use it as a time picker
            timeFormat = true, // false to use it as a date picker
            _id,
            cb,
            cbOnlyOnButton,
            placeholder,
            meta: { touched, error },
        } = this.props;

        const invalid = touched && error;

        return (
            <div
                className={classNames(`${className}`, {
                    "is-invalid": invalid,
                })}
            >
                <Datetime
                    value={input.value}
                    onChange={(date) => {
                        input.onChange(date);
                        if (cb && !cbOnlyOnButton) cb();
                    }}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    disabled={true}
                    inputProps={{ disabled: _id, placeholder }}
                    renderInput={this.renderInput}
                />

                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        );
    }
}

export class renderFieldDatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.renderInput = this.renderInput.bind(this);
    }

    componentDidMount() {
        const { input } = this.props;
        input.onChange(moment(input.value));
    }

    renderInput(props) {
        return (
            <div className="d-flex">
                <input {...props} />
            </div>
        );
    }

    render() {
        const {
            input,
            className = "",
            dateFormat = true, // false to use it as a time picker
            timeFormat = true, // false to use it as a date picker
            _id,
            meta: { touched, error },
        } = this.props;

        const invalid = touched && error;

        return (
            <div
                className={classNames(`${className}`, {
                    "is-invalid": invalid,
                })}
            >
                <Datetime
                    disabled={true}
                    value={input.value}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    onChange={input.onChange}
                    inputProps={{ disabled: _id }}
                    renderInput={this.renderInput}
                />

                {invalid && <div className="invalid-feedback">{error}</div>}
            </div>
        );
    }
}
