import React from "react";
import { connect } from "react-redux";

import List from "./List";
import { find, setSearch, clearDates, getReservationsList } from "../../../../redux/modules/tourReservations";

class ListContainer extends React.Component {
    componentDidMount() {
        this.props.find();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readTourReservations }) => readTourReservations))
                    this.props.find();
            }
        }
    }

    render() {

        const { userInfo: { permission }} = this.props;

        return (
            <List
                find={this.props.find}
                data={this.props.data}
                loading={this.props.loading}
                setSearch={this.props.setSearch}
                clearDates={this.props.clearDates}
                getReservationsList={this.props.getReservationsList}
                tourResrv={this.props.tourResrv}
                loadingModal={this.props.loadingModal}
                pageList={this.props.pageList}
                permission={permission}
            />
        );
    }
}

const mstp = (state) => ({
    ...state.tourReservations,
    userInfo: state.login.userInfo,
});

const mdtp = { find, setSearch, clearDates, getReservationsList };

export default connect(mstp, mdtp)(ListContainer);
