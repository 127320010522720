import moment from "moment";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderDatePicker } from "../Utils/renderField/renderField";

const Form = (props) => {
    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <label htmlFor="startDate">Start Date</label>
                <Field
                    name="startDate"
                    component={renderDatePicker}
                    placeholder="Start Date"
                    className="flex-1 w-100 p-0"
                    numberOfMonths={1}
                    isOutsideRange={false}
                />
            </div>
            <div className="mb-4">
                <label htmlFor="endDate">Start Date</label>
                <Field
                    name="endDate"
                    component={renderDatePicker}
                    placeholder="End Date"
                    className="flex-1 w-100 p-0"
                    numberOfMonths={1}
                    isOutsideRange={false}
                />
            </div>

            <div className="d-flex justify-content-center justify-content-md-end form-group">
                <button
                    type="submit"
                    className="btn btn-primary btn-lg"
                >
                    SEND
                </button>
            </div>
        </form>
    );
};

const ContainerForm = reduxForm({
    form: "downloadTrackReport",
    validate: (data) => {
        const errors = {};
        if (!data.startDate) errors.startDate = "Required Field";
        if (!data.endDate) errors.endDate = "Required Field";
        if (data.startDate && data.endDate && moment(data.startDate).isAfter(moment(data.endDate)))
            errors.startDate = "Invalid Date";
        return errors;
    },
})(Form);

export default ContainerForm;
