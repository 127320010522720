import React from "react";
import PropTypes from "prop-types";
import { activeFormatter } from "Utils/Acciones/Acciones";
import moment from 'moment';
import IconPlus from '../../../../icons/plus';
import Grid from "../../../Utils/Grid/materialTable";

export default class VehiclesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        if (this.tableRef)
            this.tableRef.current.dataManager.changePageSize(10);
    }

    render() {
        const { data, loader, destroy, find, page, showEdit, match: { params }, setSearch, showForm, header, userInfo: { permission } } = this.props;

        return (
            <div className="w-100 pt-2">
                <div className="grid-container material-livery-list">
                    {permission &&
                        permission.find(({ readLocations }) => !!readLocations) && (
                            <Grid
                                tableRef={this.tableRef}
                                title={`Promotional Rates (${header ? header.cityCode : ''})`}
                                columns={[
                                    {
                                        title: "Code",
                                        field: "code",
                                    },
                                    {
                                        title: "Discount",
                                        field: "discount",
                                        render: (row) => `${row.discount}%`,
                                    },
                                    {
                                        title: "Start Date",
                                        field: "startDate",
                                        render: (row) =>
                                            moment(row.startDate).format("MM/DD/YYYY")
                                    },
                                    {
                                        title: "Expiration Date",
                                        field: "expDate",
                                        render: (row) =>
                                            moment(row.expDate).format("MM/DD/YYYY")
                                    },
                                    {
                                        title: "Actions",
                                        field: "_id",
                                        align: "center",
                                        render: (row) =>
                                            activeFormatter({
                                                editar: permission.find(({ updateLocations }) => !!updateLocations) ? showEdit : undefined,
                                                eliminar: permission.find(({ removeLocations }) => !!removeLocations) ? destroy : undefined,
                                            })(row._id, row),
                                    },
                                ]}
                                actions={[
                                    {
                                        icon: () => permission && permission.find(({ createLocations }) => !!createLocations) && (
                                            <span
                                                className="btn btn-primary ml-auto"
                                                style={{ padding: "0.375rem", borderRadius: "50%" }}
                                            >
                                                <IconPlus className="smallIcon p-1"/>
                                            </span>
                                        ),
                                        onClick: showForm,
                                        isFreeAction: true,
                                    },
                                ]}
                                data={data.data}
                                onSearchChange={setSearch}
                                onPageChange={(p) => find(params.id, p + 1)}
                                isLoading={loader}
                                page={page - 1}
                                totalCount={data.total}
                                options={{
                                    pageSizeOptions: [],
                                    filtering: false,
                                    idSynonym: "_id",
                                    searchText: "Search by code",
                                    pageSize: 10,
                                    headerStyle: {
                                        backgroundColor: "#D50032",
                                        color: "#FFF",
                                        fontSize: "1rem",
                                    },
                                    cellStyle: {
                                        fontSize: "1rem",
                                    },
                                }}
                            />
                        )}
                </div>
            </div>
        );
    }
}
