import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import CheckOut from './CheckOut';
import { getBookingCode, createQuote } from '../../../../../../redux/modules/itinerary';

const CheckOutContainer = props => {

    return (
        <CheckOut
            itinerary={props.itinerary}
            vehicle={props.vehicle}
            onClick={props.handleOnBookClick}
            onApplyClick={props.getBookingCode}
            toggle={props.toggle}
            createQuote={props.createQuote}
        />
    );
}

const ms2p = ({ itinerary, vehicle: { vehicle } }) => ({
    itinerary,
    vehicle,
});

const md2p = { getBookingCode, createQuote };

export default connect(ms2p, md2p)(CheckOutContainer);
