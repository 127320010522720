import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import { actions } from '../../../../redux/modules/liveryEdition';
import { actions as airlineActions } from '../../../../redux/modules/airlineLivery';
import Details from '../../liveryEdition/details';
import { formatStatus } from '../../liveryEdition/details';
import LogsButtons from './buttons';


const ModalForm = (props) => {

    const [cancelView, setCancelView] = useState(false);

    useEffect(()=>{
        return ()=>{
            props.setView(1);
        }
    }, []);

    const cancelTrip = () => {
        setCancelView(true);
    }

    const onSubmitCancelTrip = () => {
        props.cancelTripLivery('Trip Canceled', ()=>closeModal())
    }

    const closeModal = () => {
        if(cancelView)
            setCancelView(false);
        props.close();
    }

    const { open, update, ...restProps } = props;
    const statusDescription = props.singleDetail && formatStatus(props.singleDetail.TripStatusDesc);


    return(
        <Modal open={open} onClose={closeModal} >
            {!cancelView && (
                <LogsButtons cancelTrip={cancelTrip}/>
            )}
            <div className="py-4">
                <h4 className="mb-4 text-center">TRIP VIEW</h4>
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {!props.singleDetail ? (
                            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                <Loader
                                    type="Rings"
                                    color="#FB885F"
                                    height="100"
                                    width="100"
                                />
                                <span className="">Loading...</span>
                            </div>
                        ) : (
                            <div className="mb-3">
                                <div className={`${cancelView ? '' : 'd-none'}`}>
                                    <div className="mb-4 px-3 d-flex flex-column">
                                        <div className="row mb-3">
                                            <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
                                                <h5 className="text-center">
                                                    TRIP ID: {props.singleDetail.TripID}
                                                </h5>
                                            </div>
                                            <div className="col-12 col-md-6 py-2 px-4"
                                                style={{ backgroundColor: statusDescription ? statusDescription.background : '#fff', }}
                                            >
                                                <h5 className="text-center" style={{ color: statusDescription ? statusDescription.textColor : '#000' }}>
                                                    {statusDescription && `${statusDescription.text}`}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column align-items-center">
                                            <h5 className="bold mb-0">
                                                {props.singleDetail.CompanyName}
                                            </h5>
                                            <h5>
                                                {props.singleDetail.PassengersList && props.singleDetail.PassengersList.length > 0 && props.singleDetail.PassengersList[0].Name}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="border p-4">
                                        <div className="mb-2 d-flex flex-wrap">
                                            <div className="col-12 form-group">
                                                <h5 className=""> ARE YOU SURE YOU WANT TO CANCEL THIS TRIP? </h5>
                                            </div>
                                            <div className="col-12 col-sm-4 form-group d-flex">
                                                <strong> Date:</strong>
                                            </div>
                                            <div className="col-12 col-sm-8 form-group d-flex">
                                                <label className="mb-0"> {props.singleDetail.PUDate} </label>
                                            </div>
                                            <div className="col-12 col-sm-4 form-group d-flex">
                                                <strong> Time:</strong>
                                            </div>
                                            <div className="col-12 col-sm-8 form-group d-flex">
                                                <label className="mb-0"> {props.singleDetail.PUTime} </label>
                                            </div>
                                            <div className="col-12 col-sm-4 form-group d-flex">
                                                <strong> Pick-Up:</strong>
                                            </div>
                                            <div className="col-12 col-sm-8 form-group d-flex">
                                                <label className="mb-0"> {props.singleDetail.PickupDesc} </label>
                                            </div>
                                            <div className="col-12 col-sm-4 form-group d-flex">
                                                <strong> Date:</strong>
                                            </div>
                                            <div className="col-12 col-sm-8 form-group d-flex">
                                                <label className="mb-0"> {props.singleDetail.DropoffDesc} </label>
                                            </div>
                                            <div className="col-12 col-sm-4 form-group d-flex">
                                                <strong> Passsengers:</strong>
                                            </div>
                                            <div className="col-12 col-sm-8 form-group d-flex">
                                                <label className="mb-0"> {props.singleDetail.NumOfPassengers} </label>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <button type="button" className="btn btn-primary px-4 mx-1"
                                                onClick={()=> setCancelView(false)}
                                            >
                                                NO
                                            </button>
                                            <button type="button" className="btn btn-dark px-4 mx-1"
                                                onClick={()=>onSubmitCancelTrip()}
                                            >
                                                YES
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className={`border ${cancelView ? 'd-none' : 'p-4'}`}>
                                    <Details
                                        {...restProps}
                                        btnDone={()=>closeModal()}
                                        useModalVerify={false}
                                        allowNoVerify={true}
                                        updateTripLivery={(values)=>update(values)}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const mstp = state => ({
    ...state.liveryEdition
})

const mdtp = {
    ...actions,
    update: airlineActions.update,
}

export default connect(mstp, mdtp)(ModalForm);
