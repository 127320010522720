import React from "react";
import Chart from "react-google-charts";

const ScheduleChart = ({ data }) => (
    <Chart
        width="100%"
        height="350px"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={data}
        options={{
            title: "",
            chartArea: { width: "60%" },
            colors: ["#ff3541", "#737f8c"],
            hAxis: {
                title: "Month",
                minValue: 0,
            },
            vAxis: {
                title: "Trip Count",
            },
            tooltip: { isHtml: true, trigger: "visible" },
        }}
    />
);

export default ScheduleChart;