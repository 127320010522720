import React from 'react';
import MaterialTable from '@material-table/core';

const Grid = (props) => {

    const { loading, columns, data, title, ...restProps } = props;

    return (
        <div className="">
            <MaterialTable
                columns={columns}
                data={data}
                title={title}
                {...restProps}
            />
        </div>
    )
}

export default Grid;
