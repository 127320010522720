import { connect } from "react-redux";
import Create from "./additionalChargeCreate";
import { actions } from "../../../../redux/modules/additionalCharges";

const mdtp = { ...actions };

const mstp = state => ({
    ...state.additionalCharges,
    userInfo: state.login.userInfo,
})

export default connect(mstp, mdtp)(Create);
