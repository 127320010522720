import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import IconCheck from "../../../icons/check";
import IconTimes from "../../../icons/times";
import { renderField } from "../../Utils/renderField/renderField";


const Form = (props) => {
    const { handleSubmit, cancel } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <div className="mb-1">
                    <Field
                        name="name"
                        component={renderField}
                        type="text"
                        className="form-control"
                    />
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-secondary my-1 mr-1" onClick={cancel}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary my-1 ml-1">
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
}

const ContactRolesForm = reduxForm({
    form: "contactRolesForm",
    validate: (data, props) =>{
        const errors = {};
        if(!data.name) errors.name = "Required field";
        return errors;
    }
})(Form);

export default ContactRolesForm;
