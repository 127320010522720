import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { renderField, renderSearchSelect } from '../../../Utils/renderField';
import { Link } from 'react-router-dom';
import app from "../../../../../libs/apiClient";
import LoadMask from '../../../Utils/LoadMask';
import GridSpecialRates from './specialRatesGrid';
const vehicleService = app.service("/api/vehicle/");
import moment from 'moment';
import _ from "lodash";
import IconDownload from '../../../../icons/download';
import { getPathWithParams, settingsRatesLocationsIdPromotionalRates } from '../../../../../routesConfiguration/paths';


const SpecialRates = ({ row }) => (
    <div className="row mx-0"
        style={{ border: "2px solid #757575", cursor: "pointer" }}
    >
        <div className="col-12 col-sm-6 d-flex flex-column py-sm-2">
            <span style={{ width: "100%", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                <strong> Date(s):&nbsp;</strong>
                {row.calendar.dates ? row.calendar.dates.map( (date, index) =>
                    <React.Fragment key={index}>
                        {row.calendar.recurring || row.recurring
                            ? row.calendar.recurring === 'yearly' || row.recurring === 'yearly'
                                ? `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                                : row.calendar.recurring === 'monthly' || row.recurring === 'monthly'
                                    ? `${moment(`${date.month}/${date.day}/${date.year}`).format('Do')}`
                                    : `${moment(`${date.month}/${date.day}/${date.year}`).format('ddd')}`
                            : `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                        }
                        {row.calendar.dates.length - 1 !== index
                            ? row.type === 'Days' || row.calendar.type === 'Days'
                                ? ', ' : ' To '
                            : ''
                        }
                    </React.Fragment>
                ): row.calendar.map((date,index) =>
                    <React.Fragment key={index}>
                        {row.calendar.recurring || row.recurring
                            ? row.calendar.recurring === 'yearly' || row.recurring === 'yearly'
                                ? `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                                : row.calendar.recurring === 'monthly' || row.recurring === 'monthly'
                                    ? `${moment(`${date.month}/${date.day}/${date.year}`).format('Do')}`
                                    : `${moment(`${date.month}/${date.day}/${date.year}`).format('ddd')}`
                            : `${moment(`${date.month}/${date.day}/${date.year}`).format('MM/DD/YYYY')}`
                        }
                        {row.calendar.length - 1 !== index
                            ? row.type === 'Days' || row.calendar.type === 'Days'
                                ? ', ' : ' To '
                            : ''
                        }
                    </React.Fragment>
                )}
            </span>

            {(row.calendar.recurring || row.recurring) &&
                <span> <strong> Recurring: </strong>
                    <span>
                        {row.calendar.recurring || row.recurring}&#44;&nbsp;
                    </span>
                    <span>
                        {row.calendar.dates
                            ? row.calendar.dates.reduce((min, current) =>
                                moment(`${current.month.number}/${current.day}/${current.year}`).isBefore(min) ? current : min,
                                moment(`${row.calendar.dates[0].month}/${row.calendar.dates[0].day}/${row.calendar.dates[0].year}`)
                            ).format('MMMYYYY')
                            : row.calendar.reduce((min, current) =>
                                moment(`${current.month.number}/${current.day}/${current.year}`).isBefore(min) ? current : min,
                                moment(`${row.calendar[0].month.number}/${row.calendar[0].day}/${row.calendar[0].year}`)
                            ).format('MMMYYYY')
                        }
                    </span>

                    {(row.repeatTimes > 0 || row.calendar.repeatTimes > 0)
                        ? <span> - {row.repeatTimes || row.calendar.repeatTimes}
                                {row.calendar.recurring || row.recurring
                                    ? row.calendar.recurring === 'yearly' || row.recurring === 'yearly'
                                        ? row.repeatTimes === 1 || row.calendar.repeatTimes === 1 ? 'yr' : 'yrs'
                                        : row.calendar.recurring === 'monthly' || row.recurring === 'monthly'
                                            ? row.repeatTimes === 1 || row.calendar.repeatTimes === 1 ? 'mo' : 'mos'
                                            : row.repeatTimes === 1 || row.calendar.repeatTimes === 1 ? 'wk' : 'wks'
                                    : 'Indefinite'
                                }
                            </span>
                        : <span> - Indefinite </span>
                    }
                </span>
            }
            {row.calendar.time
                ? <React.Fragment>
                    <span> <strong> Start Time: </strong> {moment(row.calendar.time.startTime, 'HH:mm').format('LT')} </span>
                    <span> <strong> End Time: </strong> {moment(row.calendar.time.endTime, 'HH:mm').format('LT')} </span>
                </React.Fragment>
                : row.startTime && row.endTime
                    ? <React.Fragment>
                        <span> <strong> Start Time: </strong> {moment(`${row.startTime.hour}:${row.startTime.minutes}`, 'HH:mm').format('LT')} </span>
                        <span> <strong> End Time: </strong> {moment(`${row.endTime.hour} ${row.endTime.minutes}`, 'HH:mm').format('LT')} </span>
                    </React.Fragment>
                    : <React.Fragment>
                        <span> <strong> Start Time: </strong> 24hrs </span>
                        <span> <strong> End Time: </strong> 24hrs </span>
                    </React.Fragment>
            }
        </div>
        <div className="col-12 col-sm-6 d-flex flex-column py-sm-2">
            <span> <strong> Code: </strong> { row.code } </span>
            <div className="flex-1">
                <span>
                    <strong> Vehicles: </strong>
                    {row.vehicles && row.vehicles.map( (vehicle, indexVehicle ) =>
                        <React.Fragment key={indexVehicle}>
                            {vehicle.vehicleInfo && vehicle.vehicleInfo.vehicleCode}{row.vehicles.length - 1 !== indexVehicle
                                ? ', ' : ''
                            }
                        </React.Fragment>
                    )}
                </span>
            </div>
        </div>
    </div>
);


class Form extends React.Component{

    render(){

        const { handleSubmit, openReportModal, seatSearchSpecialRate, openModalSpecialRates, specialRates, showDownloadButton, showSearch,
            loadingSpecialRates, params, update, loading } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-12">
                        <hr />
                        <div className="grid-title d-flex flex-column flex-sm-row align-items-stretch">
                            <div className="flex-1 d-flex justify-content-center justify-content-sm-start align-items-center">
                                <h3> <strong> Special-Rate Period </strong> </h3>
                            </div>
                            <div className="flex-1 d-flex justify-content-end align-items-center py-2 py-sm-0">
                                {showDownloadButton &&
                                    <div className="mx-2">
                                        <span style={{ cursor: "pointer" }} onClick={openReportModal}>
                                            <IconDownload className="smallIcon"/>
                                        </span>
                                    </div>
                                }
                                {showSearch &&
                                    <div style={{ flex: 1 }}>
                                        <Field
                                            name="searchSpecialRate"
                                            component={renderField}
                                            type="text"
                                            placeholder="Search by code, vehicle code, recurring"
                                            onChange={ (event, value) => seatSearchSpecialRate(value) }
                                        />
                                    </div>
                                }
                                {specialRates && specialRates.length > 0 &&
                                    <button type="button"
                                        className="btn btn-secondary ml-2"
                                        onClick={()=>openModalSpecialRates()}
                                    >
                                        + ADD
                                    </button>
                                }
                            </div>
                        </div>
                        <br />
                        <LoadMask loading={loading ? true : false} blur>
                            {specialRates && specialRates.length > 0
                            ? update
                                ? <GridSpecialRates
                                    data={specialRates}
                                    openModalSpecialRates={openModalSpecialRates}
                                    columns={[
                                        {
                                            dataField: 'code',
                                            text: '',
                                            headerAttrs: {
                                                hidden: true
                                            },
                                            formatter: (cell, row) => <SpecialRates row = {row} />
                                        }
                                    ]}
                                    loading={loadingSpecialRates}
                                />
                                : specialRates.map( (row, index) =>
                                    <span key={index} onClick={()=>openModalSpecialRates(index)}>
                                        <SpecialRates row = {row} />
                                    </span>
                                )
                            : <div className="d-flex justify-content-center align-items-center flex-column py-4">
                                    <h4 className="py-2"> No special-rate periods </h4>
                                    <button type="button"
                                        className="btn btn-primary btn-lg"
                                        onClick={()=>openModalSpecialRates()}
                                    >
                                        + Add Special Rate
                                    </button>
                                </div>
                            }
                        </LoadMask>
                    </div>
                    <div className="col-12">
                        <Link
                            to={getPathWithParams(
                                settingsRatesLocationsIdPromotionalRates,
                                "id",
                                params.id
                            )}
                            className="btn btn-secondary m-1">
                            Back
                        </Link>
                    </div>
                </div>
            </form>
        )
    }
}


const VehiclesLocationsForm = reduxForm({
    form: 'specialRatesLocationsForm',
    validate: ()=>{
        const errors = {}
        return errors;
    }
})(Form)

export default VehiclesLocationsForm;
