import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderNumber } from "../../Utils/renderField/renderField";

const Form = (props) => {

    const { handleSubmit, item } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row">
                    <div className="form-group col-12">
                        <strong htmlFor="company_name"> Company Name </strong>
                        <Field
                            name="company_name"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="contact"> Contact </strong>
                        <Field
                            name="contact"
                            component={renderNumber}
                            formatTemplate="(###) ###-####"
                        />
                    </div>
                </div>


                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <button type="submit"
                        className="btn btn-primary m-1 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}

const AccountOwnerForm = reduxForm({
    form: "ownerForm",
    validate: (data) => {
        const errors = {};
        if(!data.company_name) errors.code = "Required field";
        if(!data.contact) errors.name = "Required field";
        return errors;
    }
})(Form);

export default AccountOwnerForm;
