import { createActions, handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import { goBack } from "react-router-redux";
import isEmpty from "lodash/isEmpty";
import { errorHandler } from '../../common/utility/constants';
import app from "../../libs/apiClient";
import swal from "sweetalert2";
import moment from "moment";
import _ from 'lodash';
import { actions as breadcrumbActions } from "./breadcrumb";

const service = app.service("/api/pointsLog");
const clientService = app.service('/api/client/user')

const LOADING = "POINTS_LOADING";
const LOADING_POINTS = "LOADING_POINTS";
const CLIENT_POINTS = "CLIENT_POINTS";
const SEARCH = "POINTS_SEARCH";
const POINTS_PAGE = "CLIENT_POINTS_PAGE";
const PAGE = "POINTS_PAGE";
const DATA = "POINTS_DATA";
const LOADING_MODAL = "LOADING_MODAL";
const MODAL_EDIT_POINTS = "MODAL_EDIT_POINTS";
const UPDATE_POINTS_DATA = "UPDATE_POINTS_DATA";

// Actions
const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(loading(LOADING, true));
    const { search } = getStore().points;

    try {
        const query = { query: {
            $skip: (page - 1) * 10,
        }};

        if(search){
            query.query.$or = [
                { firstName: { $regex: _.escapeRegExp(search), $options: 'i' } },
                { lastName: { $regex: _.escapeRegExp(search), $options: 'i' } },
                { email: { $regex: _.escapeRegExp(search), $options: 'i' } },
            ]
        }

        const response = await clientService.find(query);
        dispatch(setData(DATA, response));
        dispatch(setPage(PAGE, page));
    } catch (e) {
        dispatch(errorHandler(e, 'We couldn\'t get the data'));
    } finally {
        dispatch(loading(LOADING, false));
    }
};

const findUser = (id) => async (dispatch, getStore) => {
    dispatch(loading(LOADING_MODAL, true));
    try {
        const response = await app.service('user').get(id);
        const { points = { totalPoints: 0, totalEarned: 0 } } = response;
        dispatch(setData(UPDATE_POINTS_DATA, response))
        dispatch(initializeForm('pointsForm', { points }));
    } catch (error) {
        dispatch(errorHandler(error, 'Error on get user information'));
    } finally {
        dispatch(loading(LOADING_MODAL, false));
    }
}

const findClientPoints = (id, page = 1) => async (dispatch, getState) => {
    dispatch(loading(LOADING_POINTS, true));

    try {
        const [user, data] = await Promise.all([
            clientService.get(id),
            service.find({ query: {
                $skip: (page - 1) * 10,
                $populate: ['tripId', 'madeBy'],
                $sort: { _id: -1, },
                user: id
            }})
        ]);
        if(user && !user.points)
            user.points = { totalPoints: 0, totalEarned: 0 }
        dispatch(setData(CLIENT_POINTS, { user, data }));
        dispatch(setPage(POINTS_PAGE, page));
        const { updateCustomLabel } = breadcrumbActions;
        if (user.accountNumber)
            dispatch(updateCustomLabel(id, user.accountNumber));
    } catch (e) {
        dispatch(errorHandler(e, 'Error on get user information'));
    } finally {
        dispatch(loading(LOADING_POINTS, false));
    }
};

const setSearch = () => (dispatch, getStore) => {
    const store = getStore().form.searchPointsClients;
    const search = store.values && store.values.search;
    dispatch({type: SEARCH, search});
    dispatch(find())
}

const editUserPoints = (values) => async (dispatch, getStore) => {
    dispatch(loading(LOADING_MODAL, true));
    const { page, updatePoints } = getStore().points;
    try {
        if(!updatePoints.points){
            updatePoints.points = { totalPoints: 0, totalEarned: 0 };
        }

        await Promise.all([
            app.service('/api/pointsLog').create({
                user: updatePoints._id,
                pointsBefore:{
                    totalPoints: updatePoints.points.totalPoints,
                    totalEarned: updatePoints.points.totalEarned,
                },
                pointsAddOrRemove: {
                    totalPoints: values.points.totalPoints - updatePoints.points.totalPoints,
                    totalEarned: values.points.totalEarned - updatePoints.points.totalEarned,
                },
                pointsAfter:{
                    totalPoints: values.points.totalPoints,
                    totalEarned: values.points.totalEarned,
                },
                reason: values.reason,
                automatic: false,
            }),
            app.service('/user').patch(updatePoints._id, {
                points: {
                    totalPoints: values.points.totalPoints,
                    totalEarned: values.points.totalEarned,
                    lastUpdate: moment(),
                }
            })
        ])

        swal.fire({
            type: "success",
            title: "Success!",
            text: "The points are been updated"
        }).then(result => {
            dispatch(find(page));
        })
    } catch(error) {
        dispatch(errorHandler(error, 'Error when editing user point information'));
    } finally {
        dispatch(loading(LOADING_MODAL, false));
    }
}

const setData = (type, data) => ({ type, data });
const loading = (type, loading) => ({ type, loading });
const setPage = (type, page) => ({ type, page });

export const actions = {
    setSearch,
    find,
    findUser,
    editUserPoints,
    findClientPoints,
}

const reducers = {
    [CLIENT_POINTS]: (state, { data }) => ({ ...state, clientPoints: data }),
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [LOADING_POINTS]: (state, { loading }) => ({ ...state, loadingPoints: loading, }),
    [SEARCH]: (state, { search }) => ({ ...state, search }),
    [PAGE]: (state, { page }) => ({ ...state, page }),
    [POINTS_PAGE]: (state, { page }) => ({ ...state, pagePoints: page }),
    [DATA]: (state, { data }) => ({ ...state, data }),
    [MODAL_EDIT_POINTS]: ( state, { modalEdit }) => ({ ...state, modalEdit }),
    [LOADING_MODAL]: ( state, { loading }) => ({ ...state, loadingModal: loading }),
    [UPDATE_POINTS_DATA]: (state, { data }) => ({ ...state, updatePoints: data }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    clientPoints: {
        user: undefined,
        data: {
            total: 0,
            limit: 10,
            skip: 0,
            data: []
        }
    },
    page: 1,
    pagePoints: 1,
    updatePoints: undefined,
    search: undefined,
    modalEdit: false,
    loadingModal: false,
    loadingPoints: false,
    loading: false,
};

export default handleActions(reducers, initialState);
