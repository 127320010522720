import React from "react";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import { Field, reduxForm, change } from "redux-form";
import renderTimePicker from "../../../TimePicker";
import { Icons } from "../../icons/menu";
import AutoSuggest from "../Utils/autosuggest";
import NoModalVerify from "../liveryEdition/create/noModalVerify";
import { formatFlightStatus } from "../liveryEdition/create/createForm";
import { verifyFlight, getAirlineCodes, getAirportCodes, useVerifyFlight, useOriginalVerifyFlight } from "../../../redux/modules/priceCheck";
import {
    renderCount,
    renderDatePicker,
    renderField,
    renderFieldCheck,
} from "../Utils/renderField/renderField";
import moment from "moment";

const Form = (props) => {
    React.useEffect(() => {
        if (!props.airlineCodes || props.airlineCodes.length === 0)
            props.getAirlineCodes();
        if (!props.airportCodes || props.airportCodes.length === 0)
            props.getAirportCodes();

        if (
            props.formValues &&
            (props.formValues.is_cgo || props.formValues.is_aoa)
        )
            setMoreOptions(true);
    });
    const [moreOptions, setMoreOptions] = React.useState(false);
    const [modalVerifyFlight, setModalVerifyFlight] = React.useState(false);


    const sortAirline = (airlineList) => {
        airlineList = airlineList.sort((a, b) => a.label.localeCompare(b.label));
        return airlineList;
    };

    const {
        handleSubmit,
        formValues,
        change,
        pickDropFormValues,
        airlineCodes,
        verifyFlight,
        useVerifyFlight,
        useOriginalVerifyFlight
    } = props;

    return (
        <form
            className="d-flex flex-wrap justify-content-center"
            onSubmit={handleSubmit}
        >
            <div className="col-12 col-xl-8 form-group">
                <strong htmlFor="date">Pick-Up Date</strong>
                <Field
                    name="date"
                    numberOfMonths={1}
                    component={renderDatePicker}
                    className="flex-1 w-100 p-0"
                />
            </div>
            <div className="col-12 col-xl-8 form-group">
                <strong>Pick-Up Time </strong>
                <Field
                    id="timepicker1"
                    name="time"
                    placeholder="12:00 PM"
                    component={renderTimePicker}
                    minuteStep={1}
                    style={{ height: "none" }}
                    Value={
                        formValues &&
                        formValues.time &&
                        moment(`${formValues.time.hour}:${formValues.time.minutes}`, 'HH:mm')
                    }
                />
            </div>
            <div className="col-12 col-xl-8 form-group">
                <strong>Customer PO</strong>
                <Field
                    name="customer_po"
                    component={renderField}
                    placeholder="123456A"
                    className="w-100"
                />
            </div>
            <div className="col-12 col-xl-8 form-group">
                <strong>Passengers</strong>
                <Field
                    name="pax"
                    component={renderCount}
                    min={1}
                    // disabled={!ableToEdit}
                />
            </div>
            <div className="col-12 col-xl-8 form-group">
                <strong>Airline</strong>
                <Field
                    name="airline"
                    component={AutoSuggest}
                    keyLabel="label"
                    keyId="_id"
                    searchKey="company"
                    placeholder="UA"
                    suggestions={sortAirline(airlineCodes)}
                    className="w-100"
                    onSelectOption={(key) => {
                        const data = airlineCodes.find(
                            (item) =>
                                JSON.stringify(item._id) === JSON.stringify(key)
                        );
                        change("airline", data);
                    }}
                />
            </div>
            <div className="col-12 col-xl-8 form-group">
                <strong>Flight No.</strong>
                <Field
                    name="flight"
                    component={renderField}
                    placeholder="951"
                    className="flex-1 w-100"
                />
            </div>
            <div className="col-12 col-xl-8 form-group">
                <div className="d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                            setModalVerifyFlight(true);
                            verifyFlight(formValues);
                        }}
                        disabled={!formValues || !formValues.airline || !formValues.flight || !formValues.date}
                    >
                        VERIFY
                    </button>
                </div>
            </div>

            <div className="col-12 col-xl-8 form-group">
                <NoModalVerify
                    modalVerifyFlight={modalVerifyFlight}
                    setModalVerifyFlight={setModalVerifyFlight}
                    customOriginalVerifyFlight={useOriginalVerifyFlight}
                    customVerifyFlight={useVerifyFlight}
                />
                {formValues && !modalVerifyFlight && formValues.xmlFlightVerified && Object.keys(formValues.xmlFlightVerified).length > 0 && !formValues.xmlFlightVerified.noInfo ? (
                    <div className="border border-dark rounded p-3 d-flex">
                        <div className="flex-1 d-flex flex-column align-items-start">
                            <label>{formValues.xmlFlightVerified.flight.airline.code} {formValues.xmlFlightVerified.flight.flightNumber}</label>
                            <label>{formValues.xmlFlightVerified.departure.airportCode} - {formValues.xmlFlightVerified.arrival.airportCode}</label>
                        </div>
                        <div className="flex-1 d-flex flex-column align-items-end">
                            <label className="bold text-uppercase">{formatFlightStatus(formValues.xmlFlightVerified.flight.flightStatus)}</label>
                            <label>DEP: {(formValues.xmlFlightVerified.selected.departure.time).replace(/(\d+):(\d+):(\d+)/, '$1:$2')}  ARR: {(formValues.xmlFlightVerified.selected.arrival.time).replace(/(\d+):(\d+):(\d+)/, '$1:$2')}</label>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>

            {pickDropFormValues && pickDropFormValues.company && (pickDropFormValues.company.has_aoa || pickDropFormValues.company.has_cgo) ? (
                <div className="col-12 col-xl-8 mb-2">
                    <div
                        className="w-100 border-bottom border-black mb-3 d-flex clickable"
                        onClick={() => setMoreOptions(!moreOptions)}
                    >
                        {moreOptions ? (
                            <Icons.IconCaretUp className="smallIcon" />
                        ) : (
                            <Icons.IconCaretDown className="smallIcon" />
                        )}
                        <label className="clickable ml-3"> More options </label>
                    </div>
                    <Collapse isOpen={moreOptions}>
                        <div className="d-flex flex-wrap">
                            {pickDropFormValues &&
                            pickDropFormValues.company &&
                            pickDropFormValues.company.has_aoa ? (
                                <div className="col-12 col-md-6 px-0 pr-md-3 form-group">
                                    <Field
                                        name="is_aoa"
                                        component={renderFieldCheck}
                                        label="AOA Requirement"
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                            {pickDropFormValues &&
                            pickDropFormValues.company &&
                            pickDropFormValues.company.has_cgo ? (
                                <div className="col-12 col-md-6 px-0 pl-md-3 form-group">
                                    <Field
                                        name="is_cgo"
                                        component={renderFieldCheck}
                                        label="Cargo"
                                    />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </Collapse>
                </div>
            ) : (
                ""
            )}
            <div className="col-12 col-xl-8 d-flex justify-content-end">
                <button type="button" className="btn btn-secondary px-3">
                    CANCEL
                </button>
                <button type="submit" className="btn btn-primary px-3 ml-2">
                    BOOK
                </button>
            </div>
        </form>
    );
};

const createForm = reduxForm({
    form: "createFormPriceCheck",
    validate: (data, props) => {
        const errors = {};
        if (!data.time) errors.time = "Required Field";
        if (!data.date) errors.date = "Required Field";
        if (!data.time) errors.time = "Required Field";
        if (!data.pax) errors.pax = "Required Field";
        if (!data.airline) errors.airline = "Required field";
        else if (
            (!data.xmlFlightVerified ||
                Object.keys(data.xmlFlightVerified).length === 0) &&
            !props.allowNoVerify
        )
            errors.airline = "Flight not verified";
        if (!data.flight) errors.flight = "Require field";
        if (data.is_aoa && data.is_cgo)
            errors.is_cgo = "Select only one option";
        return errors;
    },
})(Form);

const mstp = (state) => ({
    formValues:
        state.form.createFormPriceCheck &&
        state.form.createFormPriceCheck.values,
    pickDropFormValues:
        state.form.formPriceCheck && state.form.formPriceCheck.values,
    airlineCodes: state.priceCheck && state.priceCheck.airlineCodes,
    xmlFlightVerified: state.liveryEdition.xmlFlightVerified,
});

const mdtp = {
    change,
    verifyFlight,
    getAirlineCodes,
    getAirportCodes,
    useVerifyFlight,
    useOriginalVerifyFlight,
};

export default connect(mstp, mdtp)(createForm);
