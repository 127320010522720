import { connect } from "react-redux";
import { change } from 'redux-form';
import GroupRatesGrid from "./GroupRatesGrid";
import { find, destroy, setSearch, importXLSX } from "../../../../redux/modules/locations";

const mstp = (state) => {
    const loading = state.locations.loading !== 0;
    return {
        ...state.locations,
        loading,
        userInfo: state.login.userInfo,
    };
};

const mdtp = {
    find,
    change,
    setSearch,
    importXLSX,
    deleteLocation: destroy,
};

export default connect(mstp, mdtp)(GroupRatesGrid);
