import React from "react";
import { connect } from "react-redux";
import numeral from "numeral";
import LoadMask from "../Utils/LoadMask";
import FormPrice from "./formPrice";
import Modal from "react-responsive-modal";
import { actions } from "../../../redux/modules/priceCheck";
import { renderCount, renderDatePicker, renderField } from "../Utils/renderField/renderField";
import { Field } from "redux-form";
import renderTimePicker from "../../../TimePicker";
import FormAirlineRates from "../crewAdmin/companies/details/routes/create/rates/RateForm";
import CreateForm from "./createForm";

class PriceCheck extends React.Component {
    showForm = (index) => {
        const { showModal } = this.props;
        showModal(true, index);
    };

    closeForm = () => {
        const { showModal } = this.props;
        showModal(false);
    };

    submitCreateForm = (values) => {
        const { createLiveryTrip } = this.props;
        createLiveryTrip(values);
    };

    render() {
        const {
            loading,
            setDetails,
            getPrices,
            createForm,
            data,
            extraData,
            rateItem,
            loadingForm,
        } = this.props;

        return (
            <div className="d-flex flex-wrap justify-content-center pt-4 pt-lg-5">
                <Modal open={createForm} onClose={this.closeForm}>
                    <div className="py-4 d-flex flex-wrap">
                        <div className="col-12">
                            <div className="py-2">
                                <h3 className="text-left mb-0"> View Rate </h3>
                                {rateItem && (
                                    <small>Effective Date: {rateItem.route && rateItem.route.account_group && rateItem.route.account_group.effectiveDate}</small>
                                )}
                            </div>
                            <LoadMask loading={loadingForm} blur>
                                <FormAirlineRates
                                    onSubmit={() => {}}
                                    readOnly
                                    cancel={this.closeForm}
                                />
                            </LoadMask>
                        </div>
                    </div>
                </Modal>
                {extraData && extraData.length > 0 ? (
                    <div className="col-12 col-md-6 col-lg-7 px-0 px-md-4">
                        <LoadMask loading={loading} blur>
                            {extraData.map((item, index) => {
                                return (
                                    <div
                                        className="border rounded p-4 mb-4 d-flex flex-wrap" key={item._id}>
                                        <div className="col-12 col-lg-7">
                                            <div className="d-flex">
                                                <span className="bold">
                                                    Vehicle Type:&nbsp;
                                                </span>
                                                <span> {item.info.type} </span>
                                            </div>
                                            <div className="d-flex">
                                                <span className="bold">
                                                    Crew Size:&nbsp;
                                                </span>
                                                <span> 1-{item.info.capacity} crew </span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-5">
                                            <div className="d-flex">
                                                <span className="bold">
                                                    Trip Total:&nbsp;
                                                </span>
                                                <span>
                                                    ${numeral(item.info.price).format('0,0.00')}
                                                </span>
                                            </div> 
                                            <div style={{textAlign:'end'}}>
                                              <svg  width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 242.7l192 192 160-160 0-37.5L210.7 64 32 64l0 178.7zM224 32L416 224l0 64L246.6 457.4 224 480l-22.6-22.6L0 256 0 64 0 32l32 0 192 0zM88 144a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zM355.3 44.7L505.6 194.9l4.7 4.7 0 6.6 0 99.5 0 6.6-4.7 4.7L355.3 467.3 344 478.6 321.4 456l11.3-11.3L478.2 299.1l0-86.2L332.7 67.3 321.4 56 344 33.4l11.3 11.3z"/></svg>
                                             </div>
                                         </div>
                                       
                                    </div>
                                );
                            })}
                        </LoadMask>
                    </div>
                ) : null}

                {data && data.length > 0 ? (
                    <div className="col-12 col-md-6 col-lg-7 px-0 px-md-4">
                        <LoadMask loading={loading} blur>
                            {data.map((item, index) => {
                                const { timeBasePrice } = item;

                                return (
                                    <div
                                        className="border rounded p-4 mb-4 d-flex flex-wrap clickable"
                                        onClick={() => this.showForm(index)}
                                        onKeyPress={({ key }) => {
                                            if (key === "Enter") {
                                                this.showForm(index);
                                            }
                                        }}
                                        role="button"
                                        tabIndex={0}
                                        key={index}
                                    >
                                        <div className="col-12 col-lg-7">
                                            <div className="d-flex">
                                                <span className="bold">
                                                    Vehicle Type:&nbsp;
                                                </span>
                                                <span> {item.vehicle.type} </span>
                                            </div>
                                            <div className="d-flex">
                                                <span className="bold">
                                                    Crew Size:&nbsp;
                                                </span>
                                                <span> {item.min_pax}-{item.max_pax} crew </span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-5">
                                            <div className="d-flex">
                                                <span className="bold">
                                                    Trip Total:&nbsp;
                                                </span>
                                                <span>
                                                    ${numeral(timeBasePrice ? timeBasePrice.price : item.price).format('0,0.00')}
                                                </span>
                                            </div>
                                            <div className="d-flex">
                                                {item.route.isCGO ? (
                                                    <div
                                                        className="d-flex bg-primary px-2"
                                                        style={{
                                                            borderRadius:
                                                                "10px",
                                                        }}
                                                    >
                                                        <small
                                                            className="text-white bold"
                                                            style={{
                                                                fontSize:
                                                                    "10px",
                                                            }}
                                                        >
                                                            CGO
                                                        </small>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {item.route.isAOA ? (
                                                    <div
                                                        className="d-flex bg-primary px-2"
                                                        style={{
                                                            borderRadius:
                                                                "10px",
                                                        }}
                                                    >
                                                        <small
                                                            className="text-white bold"
                                                            style={{
                                                                fontSize:
                                                                    "10px",
                                                            }}
                                                        >
                                                            AOA
                                                        </small>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {timeBasePrice ? (
                                                    <div
                                                        className={`${
                                                            item.route.isAOA ||
                                                            item.route.isCGO
                                                                ? "ml-1"
                                                                : ""
                                                        } d-flex bg-dark px-2`}
                                                        style={{
                                                            borderRadius:
                                                                "10px",
                                                        }}
                                                    >
                                                        <small
                                                            className="text-white bold"
                                                            style={{
                                                                fontSize:
                                                                    "10px",
                                                            }}
                                                        >
                                                            Time Range:&nbsp;
                                                            {
                                                                timeBasePrice
                                                                    .start_time
                                                                    .hour
                                                            }
                                                            :
                                                            {
                                                                timeBasePrice
                                                                    .start_time
                                                                    .minutes
                                                            }
                                                            &nbsp;-&nbsp;
                                                            {
                                                                timeBasePrice
                                                                    .end_time
                                                                    .hour
                                                            }
                                                            :
                                                            {
                                                                timeBasePrice
                                                                    .end_time
                                                                    .minutes
                                                            }
                                                        </small>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </LoadMask>
                    </div>
                ) : (
                    ""
                )}
                <div className="col-12 col-md-6 col-lg-5">
                    <div
                        className="rounded py-5"
                        style={{ border: "1px solid #707070" }}
                    >
                        <h1 className="text-center bold my-2">Price Check</h1>
                        <h4 className="text-center bold my-2">Airline Crew</h4>
                        <div className="pt-4 pt-md-5 px-2 px-md-4">
                            <LoadMask loading={loading} blur>
                                <FormPrice
                                    onSubmit={getPrices}
                                    setDetails={setDetails}
                                />
                            </LoadMask>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.priceCheck,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(PriceCheck);
