import React from "react";
import { connect } from "react-redux";
import GenericNav from "../GenericNav";
import IconWindowMaximize from "../../icons/windowMaximize";
import IconMagnifyingGlassLocation from "../../icons/magnifyingGlassLocation";
import {
    reservationsCharterReviewsBooking,
    reservationsCharterReviewsPassenger,
    reservationsCharterReviewsService,
} from "../../../routesConfiguration/paths";

const Container = (props) => {
    const {
        userInfo: { permission },
    } = props;

    const items = [
        {
            to: reservationsCharterReviewsPassenger,
            label: "Passenger",
            icon: <IconWindowMaximize className="h-100" />,
            show: false,
        },
        {
            to: reservationsCharterReviewsBooking,
            label: "Booking",
            icon: <IconWindowMaximize className="h-100" />,
            show:
                permission &&
                permission.find(({ readReviews }) => !!readReviews),
        },
        {
            to: reservationsCharterReviewsService,
            label: "Service",
            icon: <IconMagnifyingGlassLocation className="h-100" />,
            show:
                permission &&
                permission.find(({ readReviews }) => !!readReviews),
        },
    ];

    return <GenericNav items={items} />;
};

const mathStateToProps = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mathStateToProps, null)(Container);
