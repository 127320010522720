import React from 'react';
import { connect } from 'react-redux';
import Loader from "react-loader-spinner";
import Switch from "react-switch";
import { actions, setActiveInactive } from '../../../../../../redux/modules/crewAdmin/crewRoutes';
import Pagination from '../../../../Utils/pagination';
import { Link } from 'react-router-dom';
import swal from 'sweetalert2';
import { getFullPathWithParams, getPathWithParams, settingsAirlinesAccountsIdRouteId, settingsAirlinesAccountsIdRouteIdUpdate, settingsAirlinesAccountsIdRouteNew } from '../../../../../../routesConfiguration/paths';


class RoutesContainer extends React.Component {

    componentDidMount(){
        this.props.find(this.props.airlineId, this.props.page);
    }

    setInactiveRoute = (data) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to change the state of the route? </h3>",
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "CHANGE"
        }).then(({ value })=>{
            if(value) {
                this.props.setActiveInactive(data).then(result=>{
                    if(result)
                        this.props.find(this.props.airlineId, this.props.page);
                });
            }
        })
    }

    redirectToUpdate = (item) => {
        const { userInfo: { permission } } = this.props;
        if (permission && permission.find(({ updateAirlineCrew }) => updateAirlineCrew == true)){
            const path = getFullPathWithParams(
                settingsAirlinesAccountsIdRouteIdUpdate,
                ["airlineId", "id"],
                [this.props.airlineId, item._id]
            );
            this.props.history.push(path);
        } else {
            const pathRead = getFullPathWithParams(
                settingsAirlinesAccountsIdRouteId,
                ["airlineId", "id"],
                [this.props.airlineId, item._id]
            );
            this.props.history.push(pathRead);
        }
    };

    render(){

        const { data, page, find, loading, search, setSearch, userInfo: { permission } } = this.props;

        return (
            <div className="w-100 p-2">
                <div className="d-flex py-2 justify-content-between">
                    <div className="flex-1 d-flex flex-wrap">
                        <input
                            type="search"
                            className="form-control col-12 col-md-10 col-xl-8"
                            placeholder="Search..."
                            value={search}
                            onChange={(e)=>setSearch(e.target.value, this.props.airlineId)}
                        />
                    </div>
                    {permission &&
                    permission.find(({ createAirlineCrew }) => !!createAirlineCrew) ? (
                        <div className="">
                            <Link
                                to={getPathWithParams(
                                    settingsAirlinesAccountsIdRouteNew,
                                    "airlineId",
                                    this.props.airlineId,
                                )}
                                className="btn btn-primary text-nowrap ml-2"
                            >
                                NEW ROUTE
                            </Link>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <Loader
                            type="Rings"
                            color="#FB885F"
                            height="100"
                            width="100"
                        />
                        <span className="">Loading...</span>
                    </div>
                ) : (data && data.total > 0
                    ? <div className="">
                        {data.data.map((item, index)=>(
                            <div className="border rounded border-dark small-shadow d-flex mt-3 mb-2" key={index}>
                                <div className="flex-1 clickable pr-0 pt-3 pl-3 pb-3"
                                    onClick={() => this.redirectToUpdate(item)}
                                >
                                    <div className="d-flex">
                                        <strong>Name:</strong>
                                        <span>&nbsp;{item.name}</span>
                                    </div>
                                    <div className="d-flex">
                                        <strong>Drive Time:</strong>
                                        <span>&nbsp;{(item.hours*60).toFixed(2)} Min</span>
                                    </div>
                                    <div className="d-flex">
                                        <strong>Distance:</strong>
                                        <span>&nbsp;{item.distance} Mi</span>
                                    </div>
                                    <div className="d-flex">
                                        <strong>Contracted Rates:</strong>
                                        <span>&nbsp;{item.totalContracted}</span>
                                    </div>
                                </div>
                                <div className="flex-1 d-flex flex-column clickable">
                                    <div className="pb-2 pt-3 pr-3"
                                        onClick={() => this.redirectToUpdate(item)}
                                    >
                                        <div className="border rounded border-dark p-2 d-flex flex-column">
                                            <strong>Account</strong>
                                            <span>{item.account_group && item.account_group && item.account_group.account ? item.account_group.account.acct_name : ''}</span>
                                            <i> {item.account_group ? item.account_group.account_group_name : ''} </i>
                                        </div>
                                    </div>
                                    <div className="flex-1 d-flex">
                                        <span
                                            className="clickable pb-3"
                                            onClick={() => this.redirectToUpdate(item)}
                                        >
                                            Active:&nbsp;
                                        </span>
                                        <div className="d-flex flex-column">
                                            <div className="d-flex">
                                                <Switch
                                                    checked={item.active == false ? false : true}
                                                    width={44}
                                                    height={22}
                                                    disabled={permission && !permission.find(({ updateAirlineCrew }) => !!updateAirlineCrew)}
                                                    onChange={(checked) => this.setInactiveRoute({ _id: item._id, active: checked })}
                                                />
                                            </div>
                                            <div className="flex-1 clickable pb-3"
                                                onClick={() => this.redirectToUpdate(item)}
                                            ></div>
                                        </div>
                                        <div className="flex-1 clickable pr-3 pb-3"
                                            onClick={() => this.redirectToUpdate(item)}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <Pagination
                            itemsPerPage={5}
                            total={data.total}
                            find={(page)=>find(this.props.airlineId, page)}
                            page={page}
                        />
                    </div>
                    : <div>
                        <h5> No routes found </h5>
                    </div>
                )}
            </div>
        )
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.crewRoutes,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
    setActiveInactive,
};

export default connect(mstp, mdtp)(RoutesContainer);
