import React from "react";
import { connect } from "react-redux";

// import Detail from './ReservationDetail';
import { actions } from "../../../../redux/modules/reservations";

class DetailContainer extends React.Component {
    componentDidMount() {
        this.props.getReservation(this.props.match.params.id);
    }

    render() {
        return <div {...this.props} />;
    }
}

const mstp = (state) => ({
    ...state.reservations,
});

const mdtp = { ...actions };

export default connect(mstp, mdtp)(DetailContainer);
