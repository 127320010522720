import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/crewAdmin/airlines';
import IconAngleLeft from '../../../../icons/angleLeftIcon';
import classNames from 'classnames';
import IconEdit from '../../../../icons/edit';
import Loader from "react-loader-spinner";
import AirlineUpdateForm from '../update';
import Accounts from './accounts';
import Locations from './locations';
import Routes from './routes';
import AirlineSettings from './settings';
import AccountGroups from './accountGroups';
import ScheduleReport from './schedules';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import IconTimes from '../../../../icons/times';
import IconCheck from '../../../../icons/check';
import { actions as breadcrumbActions } from "../../../../../redux/modules/breadcrumb";

class Container extends React.Component {

    state = {
        editAirline: false,
    }

    setActiveTab = (activeTab = 0) => {
        this.props.setActiveTab(activeTab);
    }

    componentDidMount(){
        this.props.getAirline(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        const { item, updateCustomLabel, match = {} } = this.props;
        const { params = {} } = match;
        const { id } = params;

        if (prevProps.item !== item) {
            updateCustomLabel(id, item.company);
        }
    }

    showEditAirline = (id) => {
        this.props.load2Update(id).then(()=>{
            this.setState({ editAirline: true });
        });
    }

    editAirline = (values) => {
        this.props.update(values).then(()=>{
            this.props.getAirline(this.props.match.params.id);
            this.setState({ editAirline: false });
        })
    }

    render() {
        const { history, loading, item, activeTab, userInfo: { permission } } = this.props;

        return (
            <div className="h-100 px-5 crewContainer">
                <div className="d-flex justify-content-start px-xl-2">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>history.goBack()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="d-flex flex-column crewAdminMaxHeight">
                    <div className="flex-1 d-flex flex-column flex-xl-row">
                        <div className="col-12 col-xl-4 my-2 mt-xl-4 pt-xl-2">
                            <div className="h-100 d-flex flex-column">
                                <div className="flex-1 border rounded border-dark p-4 shadow">
                                    {loading
                                        ? <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                                <Loader
                                                    type="Rings"
                                                    color="#FB885F"
                                                    height="100"
                                                    width="100"
                                                />
                                                <span className="">Loading...</span>
                                            </div>
                                        : this.state.editAirline ? (
                                            <AirlineUpdateForm
                                                onSubmit={this.editAirline}
                                                initialValues={{
                                                    company: item.company,
                                                    liveryId: item.liveryId,
                                                    code: item.code,
                                                }}
                                            />
                                        ) : item ? (
                                            <div className="h-100 w-100 d-flex flex-column">
                                                {permission &&
                                                permission.find(({ updateAirlineCrew }) => !!updateAirlineCrew) ? (
                                                    <div className="d-flex justify-content-end">
                                                        <div className="mx-1 clickable" onClick={()=>this.showEditAirline(item._id)}>
                                                            <IconEdit className="smallIcon" />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <div className="flex-1 d-flex flex-column justify-content-center crewAirlineDetail">
                                                    <div className="d-flex flex-wrap h-100">
                                                        <div className="col-6 pl-0 d-flex justify-content-end">
                                                            <div className="col-12 col-md-8 col-xl-12 col-1080-8 text-left pb-3 px-1">
                                                                <h5 className="bold"> Company:</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-left pb-3 px-1">
                                                            <h5 className="bold"> {item.company} ({item.code}) </h5>
                                                        </div>
                                                        <div className='col-6 pl-0 d-flex justify-content-end'>
                                                            <div className="col-12 col-md-8 col-xl-12 col-1080-8 text-left pb-3 px-1">
                                                                <h5 className="bold"> Livery ID:</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-left pb-3 px-1">
                                                            <h5> {item.liveryId} </h5>
                                                        </div>
                                                        <div className='col-6 pl-0 d-flex justify-content-end'>
                                                            <div className="col-12 col-md-8 col-xl-12 col-1080-8 text-left pb-3 px-1">
                                                                <h5 className="bold"> Crew Ops:</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-left pb-3 px-1">
                                                            <h5>
                                                                <RenderNumber
                                                                    value={item.crewOps}
                                                                    formatTemplate="(###) ###-####"
                                                                    className=""
                                                                />
                                                            </h5>
                                                        </div>
                                                        <div className='col-6 pl-0 d-flex justify-content-end'>
                                                            <div className="col-12 col-md-8 col-xl-12 col-1080-8 text-left pb-3 px-1">
                                                                <h5 className="bold"> # of Routes:</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-left pb-3 px-1">
                                                            <h5> {item.totalRoutes} </h5>
                                                        </div>
                                                        <div className='col-6 pl-0 d-flex justify-content-end'>
                                                            <div className="col-12 col-md-8 col-xl-12 col-1080-8 text-left pb-3 px-1">
                                                                <h5 className="bold"> AOA:</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-left pb-3 px-1">
                                                            <h5> {item.has_aoa
                                                                ? <IconCheck className="smallIcon p-1 text-white bg-success rounded-circle" />
                                                                : <IconTimes className="smallIcon p-1 text-white bg-danger rounded-circle" />
                                                            } </h5>
                                                        </div>
                                                        <div className='col-6 pl-0 d-flex justify-content-end'>
                                                            <div className="col-12 col-md-8 col-xl-12 col-1080-8 text-left pb-3 px-1">
                                                                <h5 className="bold"> CGO:</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-left pb-3 px-1">
                                                            <h5> {item.has_cgo
                                                                ? <IconCheck className="smallIcon p-1 text-white bg-success rounded-circle" />
                                                                : <IconTimes className="smallIcon p-1 text-white bg-danger rounded-circle" />
                                                            } </h5>
                                                        </div>
                                                        <div className='col-6 pl-0 d-flex justify-content-end'>
                                                            <div className="col-12 col-md-8 col-xl-12 col-1080-8 text-left pb-3 px-1">
                                                                <h5 className="bold"> Code Shares:</h5>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 text-left pb-3 px-1">
                                                            <h5>
                                                                {item.sharedCodes && item.sharedCodes.length > 0 && item.sharedCodes.map((airline, i) => (
                                                                    <span key={airline._id}>
                                                                        {i > 0 && i < item.sharedCodes.length ? ', ' : ''}{airline.code}
                                                                    </span>
                                                                ))
                                                            } </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ''
                                    }
                                </div>
                                <span className="text-secondary mt-2"> ACCOUNT GROUPS </span>
                                <div className="flex-1 p-4 shadow border rounded border-dark">
                                    <AccountGroups
                                        airlineId={this.props.match.params.id}
                                        history={this.props.history}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4 my-2 mt-xl-4 pt-xl-2">
                            <div className="rounded h-100 shadow border border-dark border-top-0">
                                <div className="d-flex flex-wrap">
                                    <ul className="w-100 nav nav-tabs">
                                        <li className="nav-item flex-1">
                                            <button type="button"
                                                onClick={()=>this.setActiveTab(0)}
                                                className={classNames("nav-link w-100 border-dark border-left-0", {
                                                    "active text-primary": activeTab === 0,
                                                })}
                                            >
                                                Routes
                                            </button>
                                        </li>
                                        <li className="nav-item flex-1">
                                            <button type="button"
                                                onClick={()=>this.setActiveTab(1)}
                                                className={classNames("nav-link w-100 border-dark border-left-0 border-right-0", {
                                                    "active text-primary": activeTab === 1,
                                                })}
                                            >
                                                Locations
                                            </button>
                                        </li>
                                        <li className="nav-item flex-1">
                                            <button type="button"
                                                onClick={()=>this.setActiveTab(2)}
                                                className={classNames("nav-link w-100 border-dark border-right-0", {
                                                    "active text-primary": activeTab === 2,
                                                })}
                                            >
                                                Accounts
                                            </button>
                                        </li>
                                        <li className="nav-item flex-1">
                                            <button type="button"
                                                onClick={()=>this.setActiveTab(3)}
                                                className={classNames("nav-link w-100 border-dark border-right-0", {
                                                    "active text-primary": activeTab === 3,
                                                })}
                                            >
                                                Settings
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="d-flex flex-wrap pt-3 px-3 h-100" style={{ maxHeight: 'calc(100vh - 13rem)',  overflowY: 'auto' }}>
                                    {activeTab == 0 && (
                                        <Routes
                                            airlineId={this.props.match.params.id}
                                            history={this.props.history}
                                        />
                                    )}
                                    {activeTab == 1 && (
                                        <Locations
                                            airlineId={this.props.match.params.id}
                                            history={this.props.history}
                                        />
                                    )}
                                    {activeTab == 2 && (
                                        <Accounts
                                            airlineId={this.props.match.params.id}
                                            history={this.props.history}
                                        />
                                    )}
                                    {activeTab === 3 && (
                                        <AirlineSettings
                                            airline={this.props.match.params.id}
                                            history={this.props.history}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-4 my-2">
                            <div className="h-100 d-flex flex-column">
                                <span className="text-secondary"> SCHEDULES </span>
                                <div className="border border-dark flex-1 rounded shadow">
                                    <ScheduleReport airlineId={this.props.match.params.id}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.airlines,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
    ...breadcrumbActions,
}

export default connect(mstp, mdtp)(Container);
