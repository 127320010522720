import React, { useState } from 'react';
import numeral from 'numeral';
import examplesMobile from 'libphonenumber-js/examples.mobile.json';
import Spinner from '../../Utils/Spinner';
import IconCart from '../../../icons/cart';
import IconCheck from '../../../icons/check';
import { IconTrashAlt } from '../../../icons/trash';
import IconUserEdit from '../../../icons/userEdit';
import NumberFormat from 'react-number-format';
import { getExampleNumber } from 'libphonenumber-js';
const { MEDIA_URL, BACKEND_URL } = process.env;

const getPhoneTemplate = (country) => {
    let value = country;
    if(typeof country === 'object')
        value = country.value
    const phoneNumber = getExampleNumber(value, examplesMobile);
    let phonePlaceholder = phoneNumber ? phoneNumber.formatNational() : '';
    let phoneTemplate = phonePlaceholder ? `${phonePlaceholder}`.replace(/\d/g, '#') : '';
    return phoneTemplate;
}

const ItemVehicle = (props) => {

    // To see if the button is clicked
    const [clickedAdd, setClicked] = useState(false);

    const onClick=()=>{
        setClicked(true);
        setTimeout(()=>setClicked(false), 500);
        handleSelect({
            ...info,
            vehicle_id: vehicle,
            vehicleLocation_id: vehicleLocation,
            vehicleSpecialRate_id: vehicleSpecialRate,
            _id,
            passenger
        });
    }

    const { _id, vehicle, vehicleLocation, vehicleSpecialRate, info, passenger, index,
        handleSelect, editPassenger, removePassenger, formType, fromAirport,
        disabled = false,
    } = props;

    return (
        <div className="d-flex flex-column mt-5 mx-1 border border-dark">
            <div className="bg-light d-flex flex-column flex-lg-row flex-wrap vehicle-card">
                <div className="flex-2 d-flex flex-column flex-sm-row flex-wrap justify-content-between">
                    <div className="d-flex d-sm-inline justify-content-center flex-2 vehicle-card-image">
                        <img className="img-fluid" alt="img"
                            src={`${BACKEND_URL}${info.photo}`}
                        />
                    </div>
                    <div className="d-flex d-sm-inline flex-column flex-2 p-2 vehicle-card-features">
                        <h2 className="vehicle-card-title">{info.type}</h2>
                        <p className="description"><i>{info.description}</i></p>
                        <div className="vehicle-passenger font-normal">
                            <div className="d-flex passengerIcon align-items-end">
                                <img
                                className="mr-1"
                                style={{ height: '20px' }}
                                src={require(`../../../../../assets/img/passengers.svg`)}
                                />
                                <span style={{fontSize: '12px'}}>{`1-${info.capacity} passengers`}</span>
                            </div>
                            <div className="d-flex luggageIcon align-items-end">
                                <img
                                className="mr-1"
                                style={{ height: '20px' }}
                                src={require(`../../../../../assets/img/luggage.svg`)}
                                />
                                <span style={{fontSize: '12px'}}>{`1-${info.luggageSpace ? info.luggageSpace : ''} luggage`}</span>
                            </div>
                        </div>
                        <hr className="line w-100"/>
                        <div>
                            {!!info && !!info.features && info.features.map((item, i)=>{
                                const renderItem = (item, i) => <div className="vehicle-card-item" key={i}>
                                    <img src={require(`../../../../../assets/img/svg/${item.icon}`)}/>
                                    <span> {item.description} </span>
                                </div>

                                return typeof item.featureType === 'string'
                                    ? fromAirport
                                        ? item.featureType == 'Airport' && renderItem(item, i)
                                        : formType == 'transfer'
                                            ? item.featureType == 'Standard' && renderItem(item, i)
                                            : item.featureType == 'Hourly' && renderItem(item, i)
                                    : fromAirport
                                        ? item.featureType.includes('Airport') && renderItem(item, i)
                                        : formType == 'transfer'
                                            ? item.featureType.includes('Standard') && renderItem(item, i)
                                            : item.featureType.includes('Hourly') && renderItem(item, i)
                            })}
                        </div>

                    </div>
                </div>
                <div className="flex-1 d-flex flex-column p-3 p-md-0 btnAddVehicleCart">
                    <h2 className="ml-md-auto mt-md-3 price">
                        $ {numeral(info.price).format('0,0.00')}{' '}
                        USD
                    </h2>
                    <p className="fit-content text-secondary ml-md-auto text-footer price-includes font-normal">
                        {info && info.hourlyDuration
                            ? `Price includes VAT, fees & tip for ${info.hourlyDuration} hours.`
                            : "All prices include VAT, fees & tip"}
                    </p>
                    <button
                        className={`btn ${clickedAdd ? 'btn-success' : 'btn-primary'} px-1 py-3 mt-2 mt-md-5 mx-auto ml-xl-auto mr-xl-0 w-75`}
                        style={{backgroundColor: '#DA291C !important'}}
                        onClick={onClick}
                        disabled={disabled}
                    >
                        {/* {clickedAdd
                            ? <IconCheck className="smallIcon" />
                            : <IconCart className="smallIcon" />
                        } */}
                        &nbsp;
                        <b>{clickedAdd || disabled ? 'SELECTED VEHICLE' : 'SELECT'}</b>
                    </button>
                </div>
            </div>
            <div className="w-100">
                <hr className="border-dark"/>
            </div>
            <div className="px-2 pb-3 d-flex flex-column-reverse flex-md-row">
                {passenger ? (
                    <div className="d-flex">
                        <label>
                            {passenger.first_name}&nbsp;{passenger.last_name}
                            {passenger.phone
                                ? <NumberFormat value={passenger.phone} format={`: ${getPhoneTemplate(passenger.country)}`} displayType="text"/>
                                : ''
                            }
                        </label>

                        <div onClick={()=>editPassenger(index)}>
                            &nbsp;<IconUserEdit className="smallIcon text-primary clickable"/>
                        </div>
                        <div onClick={()=>removePassenger(index)}>
                            &nbsp;<IconTrashAlt className="smallIcon text-primary clickable" />
                        </div>
                    </div>
                ) : (
                    <button type="button" className="btn btn-outline-primary"
                        onClick={()=>editPassenger(index)}
                        style={{ maxWidth: "14rem" }}
                    >
                        <IconUserEdit className="smallIcon" />&nbsp;
                        ADD PASSENGER
                    </button>
                )}
            </div>
        </div>
    )
}

const VehicleList = ({
    loader,
    vehicles,
    fromAirport,
    formType,
    handleSelect,
    editPassenger,
    removePassenger,
    item,
}) => (
    <div className="mx-4">
        {!loader && vehicles && vehicles.length > 0 && vehicles.map((data, index) => (
            <ItemVehicle
                {...data}
                key={index}
                index={index}
                handleSelect={handleSelect}
                editPassenger={editPassenger}
                removePassenger={removePassenger}
                formType={formType}
                fromAirport={fromAirport}
                disabled={
                    item &&
                    item.edit &&
                    item.vehicle &&
                    item.vehicle._id === data._id &&
                    data.info &&
                    data.info.price === item.vehicle.price
                }
            />
        ))}
        {loader && (
            <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-center mt-5 p-3 bg-light radius-1">
                <Spinner
                    styles={{
                        color: '#C3CBD3',
                        background: 'white',
                        margin: 0,
                    }}
                />
            </div>
        )}
    </div>
);

export default VehicleList;
