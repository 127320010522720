import React from 'react';
import PropTypes from 'prop-types';
import cardValidator from 'card-validator';
import { Field, reduxForm } from 'redux-form';
import { combine, validate, validators } from 'validate-redux-form';
import { getExampleNumber } from 'libphonenumber-js';
import examplesMobile from 'libphonenumber-js/examples.mobile.json';
import { countryOptions, months, years } from '../../../../utility/constants';
import { cardValidators, EMAIL_REGEX, phoneValidator } from '../../../../utility/form-validations';
import {
    renderLabeledField,
    renderSecurityCode,
    renderSelectField,
    renderCreditCard,
    renderPhoneNumber,
} from '../../../Utils/renderField/renderField';


const Form = ({
    cardNumber,
    handleSubmit,
    country,
    newPaymentMethod = false,
}) => {
    let phoneTemplate, phonePlaceholder;
    const numberValidation = cardValidator.number(cardNumber);
    let code = {},
        type;
    if (numberValidation.card) {
        code = numberValidation.card.code;
        // filter available images
        const imageAvailable = [
            'visa',
            'mastercard',
            'discover',
            'american-express',
        ].some(card => card === numberValidation.card.type);
        if (imageAvailable) type = numberValidation.card.type;
    }

    if (country) {
        let value = country;
        if(typeof country === 'object')
            value = country.value
        const phoneNumber = getExampleNumber(value, examplesMobile);
        phonePlaceholder = phoneNumber ? phoneNumber.formatNational() : '';
        phoneTemplate = phonePlaceholder ? `${phonePlaceholder}`.replace(/\d/g, '#') : '';
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="p-3 bg-light d-flex flex-column flex-wrap radius-1">
            <div>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="flex-1 m-2 text-primary">
                            <b>CONTACT INFO</b>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row">
                        <div className="flex-1 form-group">
                            <label htmlFor="first_name">First Name</label>
                            <Field
                                name="first_name"
                                component={renderLabeledField}
                                type="text"
                                placeholder="John"
                                className="flex-1 m-2"
                            />
                        </div>
                        <div className="flex-1 form-group">
                            <label htmlFor="last_name">Last Name</label>
                            <Field
                                name="last_name"
                                component={renderLabeledField}
                                type="text"
                                placeholder="Smith"
                                className="flex-1 m-2"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row">
                        <div className="flex-1 form-group">
                            <label htmlFor="email">Email</label>
                            <Field
                                name="email"
                                component={renderLabeledField}
                                type="text"
                                placeholder="Email"
                                className="flex-1 m-2"
                            />
                        </div>
                        <div className="flex-1 form-group">
                            <Field
                                name="country"
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                options={countryOptions}
                                placeholder="Country"
                                className="flex-1 m-2"
                                inModal
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row">
                        <div className="flex-1 form-group">
                            <label htmlFor="phone">Phone Number</label>
                            <Field
                                name="phone"
                                component={renderPhoneNumber}
                                type="text"
                                placeholder={phonePlaceholder}
                                formatTemplate={phoneTemplate}
                                className="flex-1 m-2"
                            />
                        </div>
                        <div className="flex-1 form-group">
                        </div>
                    </div>

                </div>
                <div>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="flex-1 m-2 text-primary">
                            <b>CREDIT CARD INFORMATION</b>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor="name">Full Name</label>
                            <Field
                                name="name"
                                component={renderLabeledField}
                                type="text"
                                placeholder="John Smith"
                                className="flex-1 w-100 m-2"
                            />
                        </div>
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor="card_number">
                                Debit/Credit Card Number
                            </label>
                            <Field
                                name="card_number"
                                component={renderCreditCard}
                                type="text"
                                placeholder="1234567890123456"
                                className="flex-1 w-100 m-2"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor="address">Address</label>
                                <Field
                                    name="address"
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="Address"
                                    className="flex-1 w-100 m-2"
                                />
                            </div>
                            <div className="flex-1 form-group">
                                <label htmlFor="zip">Zip Code</label>
                                <Field
                                    name="zip"
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="zip"
                                    className="flex-1 m-2"
                                />
                            </div>
                        </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <div className="flex-1 d-flex flex-column flex-md-row">
                            <div className="col mt-2 px-2">
                                <div className="flex-1 d-flex flex-column form-group form-group--tour">
                                    <label htmlFor="month">Month</label>
                                    <Field
                                        name="month"
                                        component={renderSelectField}
                                        className="label"
                                        valueKey="value"
                                        options={months}
                                        inModal
                                    />
                                </div>
                            </div>
                            <div className="col mt-2 px-2">
                                <div className="flex-1 d-flex flex-column form-group form-group--tour">
                                    <label htmlFor="year">Year</label>
                                    <Field
                                        name="year"
                                        component={renderSelectField}
                                        className="label"
                                        valueKey="value"
                                        options={years}
                                        inModal
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 d-flex flex-row">
                            <div className="flex-2 form-group">
                                <label htmlFor="cvv">Security Code</label>
                                <Field
                                    name="cvv"
                                    component={renderSecurityCode}
                                    codeSize={code.size || 3}
                                    type="text"
                                    placeholder="CVV"
                                    className="flex-1 m-2"
                                />
                            </div>
                            {/* Add image for card security code */}
                            <div className="flex-1 form-group d-flex justify-content-center align-items-center">
                                {type && (
                                    <img
                                        style={{ height: '3.6rem' }}
                                        src={require(`../../../../../../assets/img/${type}.svg`)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-sm-row">
                    <button
                        type="submit"
                        className="btn btn-primary m-2"
                    >
                        <b>{newPaymentMethod ? "SAVE" : "BOOK NOW"}</b>
                    </button>
                </div>
            </div>
        </form>
    );
};

Form.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const validation = data => {
    return validate(data, {
        first_name: validators.exists()('Required Field'),
        last_name: validators.exists()('Required Field'),
        email: combine(
            validators.exists()('Required Field'),
            validators.regex(EMAIL_REGEX)('Please enter a valid email')
        ),
        phone: combine(
            phoneValidator(data.country
                ? typeof data.country === 'object'
                    ? data.country.value
                    : data.country
                : undefined
            )(
                'Phone number is not valid'
            ),
            validators.exists()('Required Field')
        ),
        country: validators.exists()('Required Field'),
        name: validators.exists()('Required Field'),
        card_number: combine(
            cardValidators()('Card number invalid'),
            validators.exists()('Required Field')
        ),
        month: validators.exists()('Required Field'),
        year: validators.exists()('Required Field'),
        cvv: validators.exists()('Required Field'),
    });
};

export default reduxForm({
    form: 'PaymentForm',
    validate: validation,
})(Form);
