import React from "react";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import { renderCurrency, renderTextArea } from '../../Utils/renderField';
import { connect } from "react-redux";
import classNames from "classnames";

const RefundModal = ({
    closeModal,
    handleSubmit,
    change,
}) => {
    const [activeTab, setActiveTab] = React.useState(0);
    return (
        <form name="refundMountForm" className="col-12" onSubmit={handleSubmit}>
            <Field name="cancel" component="input" type="hidden" />
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <button
                        type="button"
                        onClick={() => {
                            change("cancel", "refund");
                            setActiveTab(0);
                        }}
                        className={classNames("nav-link", {
                            "active text-primary": activeTab === 0,
                        })}
                    >
                        Refund & Cancel
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        type="button"
                        onClick={() => {
                            change("cancel", "cancel");
                            change("amount", "0");
                            setActiveTab(1);
                        }}
                        className={classNames("nav-link", {
                            "active text-primary": activeTab === 1,
                        })}
                    >
                        Cancel Only
                    </button>
                </li>
            </ul>
            <div className="py-4 px-5 mb-2 border rounded-right rounded-bottom">
                {activeTab === 0 ? (
                    <React.Fragment>
                        <label htmlFor="amount">Refund Amount:</label>
                        <Field
                            name="amount"
                            component={renderCurrency}
                            className="form-control"
                            label="$ 0.00"
                        />
                        <br style={{borderTop: "None"}}/>
                    </React.Fragment>
                ) : (
                    ""
                )}
                <label htmlFor="agent_notes">Reason for cancellation:</label>
                <Field
                    name="agent_notes"
                    component={renderTextArea}
                    rows="3"
                    label="Explain why the trip is being canceled"
                />
                <br style={{borderTop: "None"}}/>
                <div className="d-flex justify-content-end">
                    <button className="btn btn-link mr-2" type="button" onClick={closeModal}>
                        CLOSE
                    </button>
                    <button className="btn btn-primary" type="submit">
                        PROCESS
                    </button>
                </div>
            </div>
        </form>
    );
};

const formModal = reduxForm({
    form: "refundMountForm",
    validate: (data, {reservation}) => {
        const errors = {};
        if(!data.amount){
            errors.amount = "Required field"
        } else if (data.amount > 0){
            if (reservation && data.amount > (reservation.transactionAmount - (reservation.totalRefund || 0) + (reservation.totalAditionalCharges || 0) )){
                errors.amount = "The amount is higher than the transaction"
            }
        } else if(data.cancel == 'refund'){
            errors.amount = "The amount must be greater than zero"
        } else{
            if(data.amount < 0){
                errors.amount = "The amount must not be less than zero"
            }
        }
        return errors;
    },
})(RefundModal);

export default connect(null, { change })(formModal);
