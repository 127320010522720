import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Field, FieldArray, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderFieldCheck,
} from "../../Utils/renderField/renderField";
import { Collapse, Card, CardHeader, CardBody, } from 'reactstrap';
import { settingsUserAccountsPermissions } from "../../../../routesConfiguration/paths";

class RenderOptions extends React.Component {

    state = {
        isOpen: false
    };

    setIsOpen = (value) => this.setState({ isOpen: value });
    toggle = (value) => this.setIsOpen(value);

    componentDidMount(){
        const { rolesItem, fieldName } = this.props;
        if(rolesItem && rolesItem[fieldName] == "some")
            this.setState({ isOpen: true });
    }

    render(){

        const { fieldName, title, change, customOptions } = this.props;
        const { isOpen } = this.state;

        const CRUDName = fieldName.trim().replace(/^\w/, (c)=>c.toUpperCase());

        return (
            <Card className="my-2">
                <CardHeader>
                    <div className="d-flex flex-row">
                        <div className="flex-1">
                            <label> {title} </label>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center "
                            style={{ flex: 2 }}
                        >
                            <div className="radio c-radio c-radio-nofont">
                                <label>
                                    <Field
                                        name={fieldName}
                                        type="radio"
                                        component="input"
                                        onClick={()=>{
                                            this.toggle(false);
                                            if(!customOptions){
                                                change(`read${CRUDName}`, true);
                                                change(`create${CRUDName}`, true);
                                                change(`update${CRUDName}`, true);
                                                change(`remove${CRUDName}`, true);
                                            } else {
                                                for(let index = 0; index < customOptions.length; index++){
                                                    change(`${customOptions[index].fieldName}`, true);
                                                }
                                            }
                                        }}
                                        value="full"
                                    />
                                    <span />
                                    Full
                                </label>
                            </div>
                            <div className="radio c-radio c-radio-nofont">
                                <label>
                                    <Field
                                        name={fieldName}
                                        type="radio"
                                        component="input"
                                        onClick={()=>this.toggle(true)}
                                        value="some"
                                    />
                                    <span />
                                    Some
                                </label>
                            </div>
                            <div className="radio c-radio c-radio-nofont">
                                <label>
                                    <Field
                                        name={fieldName}
                                        component="input"
                                        type="radio"
                                        onClick={()=>{
                                            this.toggle(false);
                                            if(!customOptions){
                                                change(`read${CRUDName}`, false);
                                                change(`create${CRUDName}`, false);
                                                change(`update${CRUDName}`, false);
                                                change(`remove${CRUDName}`, false);
                                            } else {
                                                for(let index = 0; index < customOptions.length; index++){
                                                    change(`${customOptions[index].fieldName}`, false);
                                                }
                                            }
                                        }}
                                        value="none"
                                    />
                                    <span />
                                    None
                                </label>
                            </div>
                        </div>
                    </div>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                    <CardBody className="pl-md-5">
                        <div className="d-flex flex-row justify-content-around align-items-center"
                            ref={this.checks}
                        >
                            {!customOptions ? (
                                <React.Fragment>
                                    <div className="flex-1 d-flex flex-column flex-sm-row">
                                        <Field
                                            name={`read${CRUDName}`}
                                            component={renderFieldCheck}
                                            classNameDiv="c-checkbox flex-1"
                                            label="Read"
                                        />
                                        <Field
                                            name={`create${CRUDName}`}
                                            component={renderFieldCheck}
                                            classNameDiv="c-checkbox flex-1"
                                            label="Create"
                                        />
                                    </div>
                                    <div className="flex-1 d-flex flex-column flex-sm-row">
                                        <Field
                                            name={`update${CRUDName}`}
                                            component={renderFieldCheck}
                                            classNameDiv="c-checkbox flex-1"
                                            label="Update"
                                        />
                                        <Field
                                            name={`remove${CRUDName}`}
                                            component={renderFieldCheck}
                                            classNameDiv="c-checkbox flex-1"
                                            label="Delete"
                                        />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="flex-1 d-flex flex-column flex-sm-row">
                                    {customOptions.map( (item, index) => (
                                        <Field
                                            key={index}
                                            name={`${item.fieldName}`}
                                            component={renderFieldCheck}
                                            classNameDiv="c-checkbox flex-1"
                                            label={`${item.label}`}
                                        />
                                    ))}
                                </div>
                            ) }
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        );
    }

}

const RenderAltOptions = (props) => {

    const { title, fieldName, defaultValue = true } = props;

    return (
        <Card className="my-2">
            <CardHeader>
                <div className="d-flex flex-row">
                    <div className="flex-1">
                        <label> {title} </label>
                    </div>
                    <div className="d-flex flex-row justify-content-around align-items-center "
                        style={{ flex: 2 }}
                    >
                        <div className="radio c-radio c-radio-nofont">
                            <label>
                                <Field
                                    name={fieldName}
                                    component="input"
                                    type="radio"
                                    value={`${defaultValue ? "true" : "full"}`}
                                />
                                <span />
                                Full
                            </label>
                        </div>
                        <div className="radio c-radio c-radio-nofont">
                            <label>
                                <Field
                                    name={fieldName}
                                    component="input"
                                    type="radio"
                                    value={`${defaultValue ? "false" : "none"}`}
                                />
                                <span />
                                None
                            </label>
                        </div>
                    </div>
                </div>
            </CardHeader>
        </Card>
    )
}


const Form = (props) => {
    const { handleSubmit, change, rolesItem } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>Permission</strong>
                    </h2>
                </div>

                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="name">Name</label>
                            <Field
                                name="name"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="description">Description</label>
                            <Field
                                name="description"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Dashboard</strong>
                            </label>
                            <RenderOptions
                                title="Billing Reports"
                                fieldName="trendsDashboard"
                                change={change}
                                rolesItem={rolesItem}
                                customOptions={[
                                    {
                                        fieldName: 'viewTrends',
                                        label: "View Trends"
                                    },
                                    {
                                        fieldName: 'viewBusiestMonth',
                                        label: "View Busiest Month"
                                    },
                                    {
                                        fieldName: 'viewMonthlyRevenue',
                                        label: "View Monthly Revenue"
                                    }
                                ]}
                            />
                            <RenderAltOptions
                                title="View Monthly Manifest"
                                fieldName="monthlyManifestDashboard"
                            />
                            <RenderAltOptions
                                title="View Newest Trips"
                                fieldName="newestTripsDashboard"
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Reservations</strong>
                            </label>
                            {/* <RenderOptions
                                title="Quotes"
                                fieldName="quotes"
                                rolesItem={rolesItem}
                                change={change}
                            /> */}
                            <RenderOptions
                                title="WEB Bookings"
                                fieldName="reservations"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Tour Reservations"
                                fieldName="tourReservations"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Airline Schedule"
                                fieldName="airlineSchedule"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Reviews"
                                fieldName="reviews"
                                rolesItem={rolesItem}
                                change={change}
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Reports</strong>
                            </label>
                            <RenderAltOptions
                                title="Feature"
                                fieldName="reports"
                                defaultValue={false}
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Marketing</strong>
                            </label>
                            <RenderOptions
                                title="Promo"
                                fieldName="promo"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Blog Post"
                                fieldName="blogPost"
                                rolesItem={rolesItem}
                                change={change}
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Administration</strong>
                            </label>
                            {/* <RenderAltOptions
                                title="Feature"
                                fieldName="administration"
                                defaultValue={false}
                            /> */}
                            <RenderOptions
                                title="Vehicles"
                                fieldName="vehicles"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Tours"
                                fieldName="tours"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Companies"
                                fieldName="companies"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Clients"
                                fieldName="clients"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Locations"
                                fieldName="locations"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Airline Crew"
                                fieldName="airlineCrew"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Additional Charges"
                                fieldName="aditionalCharges"
                                rolesItem={rolesItem}
                                change={change}
                            />
                            <RenderOptions
                                title="Customer Points"
                                fieldName="points"
                                rolesItem={rolesItem}
                                change={change}
                            />
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link to={settingsUserAccountsPermissions}
                                    className="btn btn-secondary m-1"
                                >
                                    Cancel
                                </Link>
                                <button type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const RolesForm = reduxForm({
    form: "rolesForm",
    validate: (data) => {
        return validate(data, {
            name: validators.exists()("Required field"),
        });
    },
})(Form);

export default RolesForm;
