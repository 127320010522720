import React, { Component } from 'react';
import classNames from "classnames";
import { Icons } from '../common/icons/menu';
import './js/jquery-3.4.1'
import './js/bootstrap-timepicker.js';
import './js/time-picker';
import "./css/time-picker.css";
import $ from './js/jquery-3.4.1';
import moment from 'moment';

class renderTimePicker extends Component {

    state = {
        isOpenWidget: false,
    }

    componentDidMount() {
        const timePicker = $(`#${this.props.id}`)
        timePicker.timepicker({
            minuteStep: this.props.minuteStep || 15,
            snapToStep: true,
            showInputs: false,
            disableFocus: false,
            showMeridian: true,
            defaultTime: '',
            icons:{
                up: '<svg aria-hidden="true" style="width: 32px; height: 32px;" focusable="false" data-prefix="fas" data-icon="chevron-up" class="svg-inline--fa fa-chevron-up fa-w-14 text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"></path></svg>',
                down:'<svg aria-hidden="true" style="width: 32px; height: 32px;" focusable="false" data-prefix="fas" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14 text-primary" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>'
            }
        })

        timePicker.on('changeTime.timepicker', (e) => this.setTimeOnState(e.time.value));

        timePicker.timepicker().on('show.timepicker', () => {
            this.setState({ isOpenWidget: true });
        })
        timePicker.timepicker().on('hide.timepicker', (e) => {
            this.setState({ isOpenWidget: false });
        });

        {this.props.Value && this.setTimeOnState(this.props.Value.format("LT A")) }
    }

    setTimeOnState = (timeString) => {
        const regexHour = /\d+:/
        const regexMinutes = /:[\d]{2}/

        let hour = timeString.match(regexHour)[0].slice(0, -1)
        const minutes = timeString.match(regexMinutes)[0].slice(1)
        const [, meridian] = timeString.split(' ')

        if (hour === '12' && meridian === 'PM') {
            //pass
        } else if (hour === '12' && meridian === 'AM') {
            hour = '0'
        } else if (meridian === 'PM') {
            hour = (+hour + 12).toString()
        }
        this.props.input.onChange({hour, minutes})
    }

    componentWillReceiveProps(nextProps){
        // if it is necessary to change the input value in real time
        if(nextProps.Value){
            if((this.props.Value && this.props.Value.format('LT') != nextProps.Value.format('LT')) || !this.props.Value )
                $(`#${this.props.id}`).val(nextProps.Value.format('LT'))
        } else if(this.props.Value){
            $(`#${this.props.id}`).timepicker('clear');
        } else if(this.props.input && this.props.input.value && JSON.stringify(this.props.input.value) !== JSON.stringify(this.props.input.value)) {
            let value = this.props.input.value instanceof Object
                ? moment(`${this.props.input.value.hour}:${this.props.input.value.minutes}`, 'HH:mm')
                : moment(`${this.props.input.value}`, 'HH:mm');
            $(`#${this.props.id}`).val(value.format('LT'));
        }
    }

    showWidget = () => {
        if(!this.state.isOpenWidget){
            this.setState({ isOpenWidget: true });
            $(`#${this.props.id}`).timepicker('showWidget')
        }
    }

    clearValue(e) {
        const { clearOnDelete, input } = this.props;
        if (
            clearOnDelete &&
            input &&
            input.onChange &&
            e &&
            e.target &&
            !e.target.value
        ) {
            input.onChange(null);
        }
    }

    render() {
        const {
            id, placeholder, style, className, disabled, type, meta: { touched, error },
            inModal = false,
        } = this.props

        const invalid = touched && error;

        return (
            <div className={`${inModal ? 'm-2' : ''} d-flex input-group bootstrap-timepicker timepicker`}>
                <input
                    id={id}
                    type={type}
                    style={style}
                    defaultValue={this.props.Value ? this.props.Value.format("LT A") : ""}
                    placeholder={placeholder}
                    disabled={disabled}
                    autoComplete="off"
                    onClick={this.showWidget}
                    className={classNames(`form-control time-picker-input ${className?className:''}`, {'is-invalid': invalid})}
                    onChange={this.clearValue.bind(this)}
                    onBlur={this.clearValue.bind(this)}
                />
                <span className="d-flex justify-content-center align-items-center input-group-addon"
                    style={{width: "1.5rem", fontSize: "1rem", height: `${style && style.height ? style.height : '100%'}` }}
                >
                    <Icons.IconTime className="h-100 w-100 d-flex align-items-center"/>
                </span>
                {invalid && (
                    <div className="invalid-feedback">
                        {invalid}
                    </div>
                )}

            </div>
        )
    }
}

export default renderTimePicker;
