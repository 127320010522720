import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { renderUnstyledFilePicker } from "Utils/renderField/renderField";
import { stateOptions } from "../../../utility/constants";
import Grid from "../../Utils/Grid/materialTable";
import { settingsRatesLocations, settingsRatesLocationsCreate } from "../../../../routesConfiguration/paths";

class GroupRatesGrid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        deleteLocation: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    constructor(props){
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.props.find();
        if (this.tableRef && this.tableRef.current)
            this.tableRef.current.dataManager.changePageSize(10);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateVehicles }) => updateVehicles))
                    this.props.find();
            }
        }
    }

    render() {
        // state
        const { data, loading } = this.props;

        // bind
        const { deleteLocation, find, importXLSX, page, setSearch, change, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 pt-2">
                    <div className="grid-container material-livery-list">
                        <div className="d-flex flex-md-row flex-column-reverse">
                            <div className="flex-1 col-12 px-0 d-flex align-items-end justify-content-end pb-3 pt-md-0 pt-3">
                                {permission && permission.find(({ createLocations }) => !!createLocations) && (
                                    <Field
                                        name="file"
                                        type="file"
                                        className="row"
                                        component={renderUnstyledFilePicker}
                                        cb={importXLSX}
                                    />
                                )}
                            </div>
                        </div>
                        {permission && permission.find(({ readLocations }) => !!readLocations) && (
                            <Grid
                                tableRef={this.tableRef}
                                title="Locations"
                                columns={[
                                    {
                                        title: "State",
                                        field: "state",
                                        render: (row) => {
                                            const state = _.find(stateOptions, {
                                                value: row.state,
                                            });
                                            if (state) {
                                                return state.label;
                                            }
                                            return row.state;
                                        }
                                    },
                                    {
                                        title: "City",
                                        field: "city",
                                    },
                                    {
                                        title: "Code",
                                        field: "cityCode",
                                    },
                                    {
                                        title: "Actions",
                                        field: "_id",
                                        width: "15%",
                                        align: "center",
                                        render: (row) =>
                                            activeFormatter({
                                                editar: permission.find(({ updateLocations }) => !!updateLocations) ? settingsRatesLocations : undefined,
                                                eliminar: permission.find(({ removeLocations }) => !!removeLocations) ? deleteLocation : undefined,
                                            })(row._id, row),
                                    },
                                ]}
                                actions={[
                                    {
                                        icon: () =>
                                            permission && permission.find(({ createLocations }) => !!createLocations) && (
                                                <Link
                                                    className="btn btn-primary px-2 py-0 align-self-end rounded-circle"
                                                    to={settingsRatesLocationsCreate}
                                                >
                                                    <h3>+</h3>
                                                </Link>
                                            ),
                                        isFreeAction: true,
                                    },
                                ]}
                                data={data.data}
                                onSearchChange={(search) => {
                                    change('searchLocations', search);
                                    setSearch();
                                }}
                                onPageChange={(p) => find(p + 1)}
                                isLoading={loading}
                                page={page - 1}
                                totalCount={data.total}
                                options={{
                                    pageSizeOptions: [],
                                    filtering: false,
                                    idSynonym: "_id",
                                    searchText: "Search by code",
                                    actionsColumnIndex: -1,
                                    pageSize: 10,
                                    headerStyle: {
                                        backgroundColor: "#D50032",
                                        color: "#FFF",
                                        fontSize: "1rem",
                                    },
                                    cellStyle: {
                                        fontSize: "1rem",
                                    },
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm({
    form: "locationList",
})(GroupRatesGrid);
