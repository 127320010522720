import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { STORAGE_KEY } from "./libs/apiClient";

const PublicRoute = ({
    component: Component,
    login: { loggedIn },
    ...rest
}) => {
    const checkAuth = () => {
        const token = localStorage.getItem(STORAGE_KEY);
        if (!loggedIn || !token) return false;

        return true;
    };

    return (
        <Route
            {...rest}
            render={(props) =>
                checkAuth() ? (
                    <Redirect to={{ pathname: "/" }} />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

const mstp = (state) => ({ login: state.login });

export default connect(mstp)(PublicRoute);
