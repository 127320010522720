import { arrayInsert, destroy } from 'redux-form';
import { createActions, handleActions } from 'redux-actions';
import XLSX from "xlsx";
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import app from '../../libs/apiClient'
import { getDistance, liveryVehicleType } from '../../common/utility/constants';
import {
    setInputData as setInputDataEdition,
    setFlights as setFlightsEdition,
    getSingleDetails as getSingleDetailEdition
} from './liveryEdition';
import { push } from 'react-router-redux';
const service = app.service('api/crew');
import { errorHandler } from '../../common/utility/constants';
import { getFullPathWithParams, getPathWithParams, reservationsAirlineSingleTripSearch, reservationsAirlineTrackTrip, reservationsAirlineTrackTripFlightFlights, reservationsAirlineTrackTripFlightFlightsId } from '../../routesConfiguration/paths';

// Livery constants
export const liveryBaseUrl = 'https://mobile-abes.liverycoach.com/service.svc/';
export const S_UIUD = "CONNECT_WEB";
export const S_AUTHEN_KEYS = "@123456789AbCdEfGh";

// actionTypes
export const actionTypes = {
    LOADER: 'CREW_LOADER',
    LOADING_TRACK_REPORT: "LOADING_TRACK_REPORT_CREW",
    ALL_OWN_FLIGTHS: 'ALL_OWN_FLIGTHS_CREW',
    SET_FLIGHTS: 'SET_FLIGHTS_CREW',
    SET_AIRLINE_CODES: "SET_AIRLINE_CODES",
    SET_AIRPORT_CODES: "SET_AIRPORT_CODES",
    SET_INPUT_DATA: "SET_INPUT_DATA",
    SET_SINGLE_DETAIL: "SET_SINGLE_DETAIL",
    SET_VEHICLE_INFO: "SET_VEHICLE_INFO_CREW",
    SET_VIEW: "SET_VIEW_AIRLINE_CREW",
};

export const loading = loading => ({
    type: actionTypes.LOADER,
    loading,
});

const encrypt = (sParamData) => {
    return {
        "sUIUD": S_UIUD,
        sParamData,
        "sAuthenKeys": S_AUTHEN_KEYS
    }
}

const removeDuplicates = (array, timeZone) => {
    if(array.length === 0 || (array.length === 1 && array[0].Status == 'Canceled')){
        array = []
    } else {
        let listIndex = [];
        const addToListIndex = (index) => {
            if(listIndex.includes(index) == false)
                listIndex.unshift(index);
        }

        for(let index = 0; index < array.length; index++){
            for(let indexCompare = index + 1; indexCompare < array.length; indexCompare++){
                if(array[index].Status == 'Canceled'){
                    addToListIndex(index)
                    if(array[indexCompare].Status == 'Canceled'){
                        addToListIndex(indexCompare);
                    }
                }else if(array[indexCompare].Status == 'Canceled'){
                    addToListIndex(indexCompare);
                } else if(
                    array[index].PickupAirportDesc.trim() == array[indexCompare].PickupAirportDesc.trim() &&
                    array[index].DropoffAirportDesc.trim() == array[indexCompare].DropoffAirportDesc.trim()
                ){
                    if( array[index].PUDate.trim() === array[indexCompare].PUDate.trim() ){
                        if( moment(array[indexCompare].PUTime).tz(timeZone).isAfter(moment(array[index].PUTime).tz(timeZone)) )
                            addToListIndex(index);
                        else
                            addToListIndex(indexCompare)
                    } else if( moment(array[index].PUDate).isAfter( moment(array[indexCompare].PUDate) ) ){
                        addToListIndex(index);
                    } else {
                        addToListIndex(indexCompare)
                    }
                }
            }
        }
        listIndex.sort((a,b) => b-a);
        listIndex.forEach(index => array.splice(index,1))
    }
    return array;
}

const getUpcomingTripsDriver = (array = [], trip) => {
    let { ChauffeurName: driverName, TripID } = trip;

    let foundIndex = array.findIndex(({ TripID: id })=> id == TripID);
    if(foundIndex !== -1)
        array.splice(foundIndex, 1);

    if(!driverName)
        return [];

    driverName = driverName.trim().toLowerCase();
    let listIndex = [];
    for(let index = 0; index < array.length; index++){
        if(!array[index].ChauffeurName){
            listIndex.unshift(index)
            continue;
        }
        if(array[index].Status == 'Done' || array[index].Status == 'Canceled' || array[index].ChauffeurName.trim().toLowerCase() != driverName)
            listIndex.unshift(index);
        else if(moment(`${array[index].PUDate} ${array[index].PUTime}`, 'MM/DD/YY HH:mm').isBefore(moment().subtract(1, 'h')) )
            listIndex.unshift(index)
    }
    listIndex.forEach(index => array.splice(index,1));
    if(array.length > 3)
        array = array.slice(0,3);
    return array;
}

export const getFlights = data => async(dispatch, getStore) => {
    dispatch(loading(true));

    const { airlineCodes: airlineCodesList, airportCodes: airportCodesList } = getStore().airlineCrew;
    if(!airlineCodesList || airlineCodesList.length == 0 || !airportCodesList || airportCodesList.length == 0){
        await dispatch(getAirlineCodes());
        await dispatch(getAirportCodes());
    }

    const { airlineCodes, airportCodes } = getStore().airlineCrew;
    const airlineCode = data.airline instanceof Object ? data.airline : airlineCodes.find( item =>
        item.code.trim().toLowerCase() == data.airline.trim().toLowerCase() ||
        item._id.trim() == data.airline.trim() ||
        item.label.trim() == data.airline.trim()
    )
    const airportCode = data.airport instanceof Object ? data.airport : airportCodes.find( item =>
        item.code.trim().toLowerCase() == data.airport.trim().toLowerCase() ||
        item._id.trim() == data.airport.trim() ||
        item.label.trim() == data.airport.trim()
    );
    dispatch(setInputData({ airlineCode, airportCode, flightNumber: data.flightNumber}));
    try {
        if(!airlineCode){
            if(!airportCode){
                Swal.fire({
                    type: "error",
                    text: "UA cannot be found at IAD"
                })
            } else {
                Swal.fire({
                    type: "error",
                    text: "No matching airline code. Please use 2-letter IATA code."
                })
            }
            dispatch( loading(false) );
        } else if(!airportCode){
            Swal.fire({
                type: "error",
                text: "No matching airport code. Please use 3-letter IATA code."
            })
            dispatch( loading(false) );
        } else {
            const today = moment().tz(airportCode.timeZone).format('l');
            const tomorrow = moment().tz(airportCode.timeZone).add(1,'days').format('l');

            const response = await service.find({
                query: {
                    path: "getTokenizedParams",
                    body: encrypt(`-1[[ENCRYPT]]Customer[[ENCRYPT]]0[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]0[[ENCRYPT]]${airportCode.code}[[ENCRYPT]]${today}[[ENCRYPT]]${tomorrow}[[ENCRYPT]]0[[ENCRYPT]]All[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]`),
                },
            });

            const flights = await service.find({
                query: {
                    path: "GetReservationListOnPhoneEN",
                    body: response.Table[0]
                },
            });
            const ownFlights = flights.Table.filter((item) => {
                const arrayPick = item.PickupAirportDesc.split(/\s+/g);
                const arrayDrop = item.DropoffAirportDesc.split(/\s+/g);
                if (
                    ((item.FlightNbr &&
                        item.FlightNbr.length &&
                        item.FlightNbr === data.flightNumber) ||
                        arrayPick[arrayPick.length - 1] === data.flightNumber ||
                        arrayDrop[arrayDrop.length - 1] ===
                            data.flightNumber) &&
                    ((["CX", "CXC"].includes(airlineCode.code) &&
                        (arrayPick[arrayPick.length - 2].match(new RegExp(`^CX`, 'g')) !== null ||
                        arrayDrop[arrayDrop.length - 2].match(new RegExp(`^CX`, 'g')) !== null)) ||
                    arrayPick[arrayPick.length - 2] === airlineCode.code ||
                        arrayDrop[arrayDrop.length - 2] === airlineCode.code) &&
                    (airportCode.code === arrayPick[arrayPick.length - 3] ||
                        airportCode.code === arrayDrop[arrayDrop.length - 3])
                )
                    return true;
                else
                    return false;
            });
            const allOwnFlights = Object.assign([], flights.Table);
            const noDuplicatedData = removeDuplicates(ownFlights, airportCode.timeZone );
            const finalData = [];
            for(let index = 0; index < noDuplicatedData.length; index++){
                const paxCount = noDuplicatedData[index].Passenger_Name.match(/(\+\d+)/g);
                finalData.push(Object.assign(noDuplicatedData[index], {Numofpassengers: paxCount ? parseInt(paxCount[0]) + 1 : 1 }));
            }
            if(finalData && finalData.length){
                dispatch(setAllOwnFlights(allOwnFlights));
                dispatch(setFlights(finalData));
                if(finalData.length > 1){
                    const pathFlights = getPathWithParams(
                        reservationsAirlineTrackTripFlightFlights,
                        "flight",
                        `${airlineCode.code}-${airportCode.code}-${data.flightNumber}`
                    );
                    dispatch(push(pathFlights));
                    dispatch( loading(false) );
                }else {
                    const pathFlightsDetails = getFullPathWithParams(
                        reservationsAirlineTrackTripFlightFlightsId,
                        ["flight", "id"],
                        [
                            `${airlineCode.code}-${airportCode.code}-${data.flightNumber}`,
                            `${finalData[0].TripID}`,
                        ]
                    );
                    dispatch(push(pathFlightsDetails));
                }
            } else {
                Swal.fire({
                    type: "error",
                    text: "The trip could not be found. Please check the data and try again."
                });
                dispatch( loading(false) );
            }
        }
    } catch (error){
        dispatch(errorHandler(error));
        dispatch( loading(false) );
    }
};

export const getFlightById = data => async(dispatch, getStore) => {
    dispatch(loading(true));
    try {
        const details = await getLiveryDetails(data.liveryNo);

        if(details && details.Table && details.Table.length > 0){
            let liveryData = details.Table[0];
            let reservationDateTime = moment(liveryData.ReservationDateTime, 'MMM DD YYYY hh:mm A');
            let PUDate = reservationDateTime.format('MM/DD/YYYY');
            const response = await service.find({
                query: {
                    path: "getTokenizedParams",
                    body: encrypt(`${liveryData.CustomerID}[[ENCRYPT]]Customer[[ENCRYPT]]0[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]]${PUDate}[[ENCRYPT]]${PUDate}[[ENCRYPT]]0[[ENCRYPT]]All[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]${data.liveryNo}[[ENCRYPT]]`),
                }
            })
            const flights = await service.find({
                query: {
                    path: "GetReservationListOnPhoneEN",
                    body: response.Table[0]
                }
            });

            if(flights && flights.Table && flights.Table.length > 0){
                if(!isEmpty(flights.Table[0].Airline)){ // From airport
                    let airlineCode = flights.Table[0].Airline.trim().split(/\s+/);
                    liveryData.airport = !isEmpty(airlineCode) ? airlineCode[0] : undefined;
                    liveryData.airline = !isEmpty(airlineCode) && airlineCode.length === 2 ? airlineCode[1] : airlineCode[0];
                    liveryData.flightNumber = flights.Table[0].FlightNbr;
                } else { // to airport
                    const arrayDrop = flights.Table[0].DropoffAirportDesc.split(/\s+/g);
                    liveryData.airport = arrayDrop[arrayDrop.length - 3];
                    liveryData.airline = arrayDrop[arrayDrop.length - 2];
                    liveryData.flightNumber = arrayDrop[arrayDrop.length - 1];
                }

                if(liveryData.airline && liveryData.airline.match(/^CXC/g) != null)
                    liveryData.airline = "CX";

                let airportCode = { code: liveryData.airport };
                let airlineCode = { code: liveryData.airline };

                const pathFlightsDetails = getFullPathWithParams(
                    reservationsAirlineTrackTripFlightFlightsId,
                    ["flight", "id"],
                    [
                        `${airlineCode.code}-${airportCode.code}-${liveryData.flightNumber}`,
                        `${data.liveryNo}`,
                    ]
                );

                dispatch(push(pathFlightsDetails));
            }

            dispatch( loading(false) );
        } else {
            Swal.fire({
                type: "error",
                text: "No matching trip found",
                confirmButtonColor: '#D50032',
            }).then(()=>{
                dispatch(push(reservationsAirlineTrackTrip));
            })
            dispatch( loading(false) );
        }
    } catch (error){
        dispatch(errorHandler(error));
        dispatch( loading(false) );
    }
};


const getLiveryDetails = async (TripID, liveryId) => {
    const tokenize = await service.find({
        query: {
            path: "getTokenizedParams",
            body: encrypt(`${TripID}[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]${liveryId}[[ENCRYPT]]-1[[ENCRYPT]]Customer[[ENCRYPT]]`)
        }
    })
    return service.find({
        query: {
            path: "GetReservationDetailForChauffeursEN",
            body: tokenize.Table[0]
        }
    })
}

export const getSingleDetails = (data) => async (dispatch, getStore) => {
    dispatch(loading(true))
    try {
        const { airlineCode, airportCode, flightNumber } = getStore().airlineCrew.inputData;
        const result = await getLiveryDetails(data.TripID, airlineCode.liveryId);
        if(result) {
            if(flightNumber)
                result.Table[0].flightNumber = flightNumber;

            const {results: [resultPickUp]} = await app.service('/api/googleMaps').get('geocode', { query: { address: data.PickupDesc } });
            const {results: [resultDropOff]} = await app.service('/api/googleMaps').get('geocode', { query: { address: data.DropoffDesc } });

            const isAOA = result.Table[0]["Customer PO#"] ? result.Table[0]["Customer PO#"].match(/-AOA$/g) != null : false;
            const isCGO = result.Table[0]["Customer PO#"] ? result.Table[0]["Customer PO#"].match(/-CGO$/g) != null : false;
            const [zip] = data.Airline // From airport
                ? await app.service("/api/zip").find({
                      query: {
                          $paginate: false,
                          zip_code: result.Table[0].PUDOList.find(
                              (i) => i.PUDOType === "Dropoff"
                          ).zip,
                      },
                  })
                : await app.service("/api/zip").find({
                      query: {
                          $paginate: false,
                          zip_code: result.Table[0].PUDOList.find(
                              (i) => i.PUDOType === "Pickup"
                          ).zip,
                      },
                  });
            const [route] = zip
                ? await app.service("/api/airline_route").find({
                      query: {
                          $paginate: false,
                          airline: airlineCode._id,
                          airport: airportCode._id,
                          zone: zip.zone,
                          isAOA,
                          isCGO,
                      },
                  })
                : [undefined];
            if (route) {
                result.Table[0].meetingProcedure = data.Airline
                    ? route.airport_pu
                    : route.airport_do;
            }

            const pickUp = resultPickUp.geometry.location;
            const dropOff = resultDropOff.geometry.location;

            const { VehicleDescription } = result.Table[0];
            const vehicleInfo = await getVehicleInfoFromSamsara(VehicleDescription)

            let etaResult;
            if(vehicleInfo){
                if(["Unassigned", "Assigned", "Dispatched", "On The Way", "Arrived", "Customer In Car", "Done"].includes(data.Status)){
                    let vehicleLocation = await app.service('/api/samsaraApi').find({query: {
                        path: `https://api.samsara.com/fleet/vehicles/stats/feed`,
                        body: { types: 'gps,engineStates', vehicleIds: vehicleInfo.id }
                    }});

                    if(vehicleLocation.data.length > 0){
                        vehicleLocation = vehicleLocation.data[0];
                        vehicleLocation.gps = vehicleLocation.gps[0];
                        vehicleLocation.engineStates = vehicleLocation.engineStates[0];
                        dispatch(setVehicleLocation(vehicleLocation));

                        // Tracking vehicle if trip status is not "Done"
                        if(data.Status != 'Done'){
                            let origins = new google.maps.LatLng(vehicleLocation.gps.latitude, vehicleLocation.gps.longitude);
                            // Tracking to drop off location if trip status is "Customer car" else tracking to pick-up location
                            let destinations = data.Status == 'Customer In Car'
                                ?  new google.maps.LatLng(dropOff.lat, dropOff.lng)
                                : new google.maps.LatLng(pickUp.lat, pickUp.lng);
                            etaResult = await getDistance(origins, destinations);
                            if(etaResult)
                                etaResult.time = moment().add(`${etaResult.duration.split(' ')[0]}`, 'm').format('HH:mm');
                        }
                    } else {
                        console.error(`cannot found a location for ${VehicleDescription} vehicle`)
                    }
                }
            } else {
                console.error(`${VehicleDescription} vehicle name doesn\'t match with any vehicles in samsara api`)
            }

            const { allOwnFlights } = getStore().airlineCrew;
            let driverUpcomingTrips;
            if(allOwnFlights && allOwnFlights.length > 0){
                const trip = allOwnFlights && allOwnFlights.find(item => item.TripID == data.TripID);
                if(trip)
                    driverUpcomingTrips = getUpcomingTripsDriver(allOwnFlights, trip)
            }

            dispatch(setSingleDetail({
                ...result.Table[0],
                FarmStatus: data.FarmStatus,
                TripID: data.TripID,
                vehicleInfo,
                etaResult,
                driverUpcomingTrips,
                location: { pickUp, dropOff }
            }));

            dispatch( loading(false) );
        } else {
            Swal.fire({
                type: "error",
                text: "Empty data"
            })
            dispatch( loading(false) );
        }
    } catch(error) {
        dispatch(errorHandler(error));
        dispatch( loading(false) );
    }
}

export const getTrip = (dataFlight) => async (dispatch, getStore) => {
    dispatch(loading(true));
    const { airlineCodes: airlineCodesField, airportCodes: airportCodesField } = getStore().airlineCrew;
    if(!airlineCodesField || airlineCodesField.length == 0 || !airportCodesField || airportCodesField.length == 0){
        await dispatch(getAirlineCodes());
        await dispatch(getAirportCodes());
    }

    const { airlineCodes, airportCodes } = getStore().airlineCrew;
    const airlineCode = airlineCodes.find( item => item.code.trim().toLowerCase() == dataFlight.airline.trim().toLowerCase());
    const airportCode = airportCodes.find( item => item.code.trim().toLowerCase() == dataFlight.airport.trim().toLowerCase());
    dispatch(setInputData({ airlineCode, airportCode, flightNumber: dataFlight.flightNumber }));

    try {
        const details = await getLiveryDetails(dataFlight.id);

        if(details && details.Table && details.Table.length > 0){
            let liveryData = details.Table[0];
            let reservationDateTime = moment(liveryData.ReservationDateTime, 'MMM DD YYYY hh:mm A');
            let PUDate = reservationDateTime.format('MM/DD/YYYY');
            let PUTime = reservationDateTime.format('HH:mm');
            let tomorrow = reservationDateTime.add(1, 'd').format('MM/DD/YYYY');
            const response = await service.find({
                query: {
                    path: "getTokenizedParams",
                    body: encrypt(`${liveryData.CustomerID}[[ENCRYPT]]Customer[[ENCRYPT]]0[[ENCRYPT]]${S_UIUD}[[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]]${PUDate}[[ENCRYPT]]${tomorrow}[[ENCRYPT]]0[[ENCRYPT]]All[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]0[[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]][[ENCRYPT]]`),
                }
            })
            const flights = await service.find({
                query: {
                    path: "GetReservationListOnPhoneEN",
                    body: response.Table[0]
                }
            });

            if(flights && flights.Table && flights.Table.length > 0){
                const ownFlights = flights.Table.filter( item => {
                    const arrayPick = item.PickupAirportDesc.split(/\s+/g);
                    const arrayDrop = item.DropoffAirportDesc.split(/\s+/g);
                    if((item.FlightNbr && item.FlightNbr.length && item.FlightNbr == dataFlight.flightNumber ||
                        arrayPick[arrayPick.length-1] == dataFlight.flightNumber ||
                        arrayDrop[arrayDrop.length-1] == dataFlight.flightNumber) &&
                        (
                            airportCode.code === arrayPick[arrayPick.length - 3] ||
                            airportCode.code === arrayDrop[arrayDrop.length - 3]
                        ) &&
                        ((["CX", "CXC"].includes(airlineCode.code) &&
                        (arrayPick[arrayPick.length - 2].match(new RegExp(`^CX`, 'g')) !== null ||
                            arrayDrop[arrayDrop.length - 2].match(new RegExp(`^CX`, 'g')) !== null)) ||
                        arrayPick[arrayPick.length - 2] === airlineCode.code ||
                            arrayDrop[arrayDrop.length - 2] === airlineCode.code)
                    )
                        return true;
                    else
                        return false;
                });
                const allOwnFlights = Object.assign([], flights.Table);
                const noDuplicatedData = removeDuplicates(ownFlights, airportCode.timeZone);
                await dispatch(setFlights(noDuplicatedData));
                dispatch(setAllOwnFlights(allOwnFlights));
            } else {
                let VehicleType = liveryVehicleType.find(item=> item.id == parseInt(liveryData.VehicleTypeID));
                if(VehicleType) VehicleType = VehicleType.label;
                dispatch(setFlights([{
                    ...liveryData, TripID: dataFlight.id, PUDate, PUTime, VehicleType,
                }]));
            }

            const data = flights && flights.Table && flights.Table.find(item => item.TripID == dataFlight.id);
            dispatch(getSingleDetails(data));
        } else {
            Swal.fire({
                type: "error",
                text: "No matching trip found",
                confirmButtonColor: '#D50032',
            }).then(()=>{
                dispatch(push(reservationsAirlineTrackTrip));
            })
            dispatch( loading(false) );
        }
    } catch (error){
        dispatch(errorHandler(error));
        dispatch( loading(false) );
    }
};

export const getAirlineCodes = () => async (dispatch) =>{
    const airlineCodes = await app.service('/api/airline').find({ query: { $paginate: false } });
    for(let index = 0; index < airlineCodes.length; index++){
        airlineCodes[index].label = airlineCodes[index].code + " - " + airlineCodes[index].company;
    }
    dispatch(setAirlineCodes(airlineCodes));
}

export const getAirportCodes = () => async (dispatch) => {
    const airportCodes = await app.service('/api/airport').find({ query: { $paginate: false } });
    for(let index = 0; index < airportCodes.length; index++){
        airportCodes[index].label = airportCodes[index].code + " - " + airportCodes[index].name;
    }
    dispatch(setAirportCodes(airportCodes));
}

export const setAllOwnFlights = allOwnFlights => ({
    type: actionTypes.ALL_OWN_FLIGTHS,
    allOwnFlights,
})

export const setFlights = flights => ({
    type: actionTypes.SET_FLIGHTS,
    flights,
});

export const setAirlineCodes = airlineCodes => ({
    type: actionTypes.SET_AIRLINE_CODES,
    airlineCodes
})

export const setAirportCodes = airportCodes => ({
    type: actionTypes.SET_AIRPORT_CODES,
    airportCodes
})

export const setInputData = inputData => ({
    type: actionTypes.SET_INPUT_DATA,
    inputData,
})

export const setSingleDetail = singleDetail => ({
    type: actionTypes.SET_SINGLE_DETAIL,
    singleDetail
})

export const cleanForm = () => (dispatch) => {
    dispatch(setInputData(undefined));
    dispatch(destroy('FindMyRideForm'));
}

export const clearData = () => dispatch => {
    dispatch(setSingleDetail());
    dispatch(setInputData());
    dispatch(setVehicleLocation());
}

export const initializeDataAutocomplete = () => (dispatch) => {
    dispatch(getAirlineCodes());
    dispatch(getAirportCodes());
}

const getVehicleInfoFromSamsara = async (vehicleName) => {
    const samsaraVehicles = await app.service('/api/samsaraApi').find({query: {
        path: `https://api.samsara.com/fleet/vehicles`,
    }});
    let vehicleInfo = samsaraVehicles.data.find(item => item.name === vehicleName);
    if(vehicleInfo && vehicleInfo.tags && vehicleInfo.tags.length > 0) {
        for(let i = 0; i < vehicleInfo.tags.length; i++){
            let dataTag = await app.service('/api/samsaraApi').find({query: {
                path: `https://api.samsara.com/tags/${vehicleInfo.tags[i].parentTagId}`,
            }})
            vehicleInfo.tags[i].data = dataTag.data;
        }
    }

    return vehicleInfo
}

const getVehicleLocationFromSamsara = (vehicleId) => async (dispatch, getStore) =>{
    let vehicleLocation;
    vehicleLocation = await app.service('/api/samsaraApi').find({query: {
        path: `https://api.samsara.com/fleet/vehicles/stats/feed`,
        body: { types: 'gps,engineStates', vehicleIds: vehicleId }
    }});
    if(vehicleLocation && vehicleLocation.data && vehicleLocation.data.length > 0){
        vehicleLocation.data[0].gps = vehicleLocation.data[0].gps[0];
        vehicleLocation.data[0].engineStates = vehicleLocation.data[0].engineStates[0];
        dispatch(setVehicleLocation(vehicleLocation.data[0]))
    }
}

export const getVehicleLocation = () => async (dispatch, getStore) => {
    const { vehicleLocation } = getStore().airlineCrew;

    if(vehicleLocation){
        dispatch(getVehicleLocationFromSamsara(vehicleLocation.id))
    } else {
        const { singleDetail } = getStore().shuttle;
        if(singleDetail && (["Unassigned", "Assigned", "Dispatched", "On The Way", "Arrived", "Customer In Car", "Done"].includes(singleDetail.TripStatusDesc))){
            const { VehicleDescription } = singleDetail
            const vehicleInfo = await getVehicleInfoFromSamsara(VehicleDescription);

            if(vehicleInfo){
                dispatch(getVehicleLocationFromSamsara(vehicleInfo.id))
            }
        }
    }
}

export const editTrip = () => (dispatch, getStore) => {
    const { inputData, flights, singleDetail } = getStore().airlineCrew;
    const data = flights.find(item => item.TripID == singleDetail.TripID);
    dispatch(setInputDataEdition(inputData));
    dispatch(setFlightsEdition(flights));
    dispatch(getSingleDetailEdition(data));
    dispatch(push(reservationsAirlineSingleTripSearch));
}

export const sendEmailNoTracking = (data) => (dispatch, getStore) => {
    Swal.fire({
        type: "warning",
        title: "Report tracking issue?",
        text: "A notification will be sent to infosystems@connectbbc.com",
        showCancelButton: true,
        confirmButtonText: 'Send',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
        confirmButtonColor: '#D50032',
    }).then(({ value }) => {
        if(value){
            dispatch(loading(true));
            app.service('/api/samsaraApi').patch('noTrackingEmail', data).then(response=>{
                Swal.fire({
                    type: 'success',
                    title: "SUCCESS",
                    text: "Email sent"
                })
            }).catch(error=>{
                dispatch(errorHandler(error));
            }).finally(()=>{
                dispatch(loading(false));
            })
        }
    })
}

export const loadingTrackReport = (loadingTrackR) => ({
    type: actionTypes.LOADING_TRACK_REPORT,
    loadingTrackReport: loadingTrackR,
});

export const downloadTrackReport = (data) => async (dispatch) => {
    dispatch(loadingTrackReport(true));
    const result = await app.service("/api/trackSubmissions").find({
        query: {
            $paginate: false,
            createdAt: {
                $gte: moment(data.startDate).set({ hour: 0, minutes: 0, second: 0 }),
                $lte: moment(data.endDate).set({ hour: 23, minutes: 59, second: 59 })
            },
        },
    });
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Issue Track Report",
        Subject: "Tracking",
        Author: "BBC EXPRESS",
        CreatedDate: new Date(),
    };

    // Head of workbook in spreadsheet
    let ws_data = [[ "livery ID", "Vehicle", "Driver", "Samara response" ]];

    // Create the array of data for add to Spreadsheet
    for(let index = 0; index < result.length; index++)
        ws_data.push([
            `${result[index].liveryId !== null ? result[index].liveryId : ''}`,
            `${result[index].vehicle}`,
            `${result[index].driver}`,
            `${result[index].samsaraResponse}`,
        ]);
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    // Columns width in spreadsheet
    let wscols = [ {wch:10}, {wch:10}, {wch:10}, {wch:10} ];
    ws['!cols'] = wscols;

    // Add the data to new sheet in the spreadsheet
    XLSX.utils.book_append_sheet(wb, ws, "Track Issues Report");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
        const view = new Uint8Array(buf); // create uint8array as viewer
        for (let i = 0; i < s.length; i++)
            view[i] = s.charCodeAt(i) & 0xff; // convert to octet
        return buf;
    }

    // Save the spreadsheet
    saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream", }),
        "Track issues Report.xlsx"
    );
    dispatch(loadingTrackReport(false));
};

export const setVehicleLocation = vehicleLocation => ({
    type: actionTypes.SET_VEHICLE_INFO,
    vehicleLocation
})

const setView = view => ({
    type: actionTypes.SET_VIEW,
    view
})

export const actions = {
    setView,
    getFlights,
    getTrip,
    clearData,
    getFlightById,
    initializeDataAutocomplete,
    downloadTrackReport,
    sendEmailNoTracking,
    getVehicleLocation,
    getSingleDetails,
    editTrip,
    cleanForm,
};

// REDUCERS
export const reducers = {
    [actionTypes.LOADER]:  (state, { loading }) => ({ ...state, loading }),
    [actionTypes.ALL_OWN_FLIGTHS]: (state, { allOwnFlights }) => ({ ...state, allOwnFlights }),
    [actionTypes.SET_FLIGHTS]: (state, { flights }) => ({ ...state, flights }),
    [actionTypes.SET_AIRLINE_CODES]: (state, { airlineCodes }) => ({ ...state, airlineCodes }),
    [actionTypes.SET_AIRPORT_CODES]: (state, { airportCodes }) => ({ ...state, airportCodes }),
    [actionTypes.SET_INPUT_DATA]: (state, { inputData }) => ({ ...state, inputData }),
    [actionTypes.SET_SINGLE_DETAIL]: (state, { singleDetail }) => ({ ...state, singleDetail }),
    [actionTypes.SET_VEHICLE_INFO]: (state, { vehicleLocation }) => ({ ...state, vehicleLocation }),
    [actionTypes.SET_VIEW]: (state, { view }) => ({ ...state, view }),
    [actionTypes.LOADING_TRACK_REPORT]: (state, { loadingTrackReport }) => ({ ...state, loadingTrackReport })
}

export const initialState = {
    loading: false,
    allOwnFlights: undefined,
    flights: undefined,
    inputData: undefined,
    singleDetail: undefined,
    vehicleLocation: undefined,
    loadingTrackReport: false,
    airportCodes: [],
    airlineCodes: [],
    view: 1,
};

export default handleActions(reducers, initialState);
