import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change } from 'redux-form';
import List from "./List";
import { actions } from "../../../../redux/modules/shuttle";


class ListContainer extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.find();
    }

    render() {
        return (
            <List
                {...this.props}
            />
        );
    }
}

const mstp = (state) => state.shuttle;

const mdtp = { ...actions, change };

export default connect(mstp, mdtp)(ListContainer);
