import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/crewAdmin/locations';
import IconAngleLeft from '../../../icons/angleLeftIcon';
import LoadMask from '../../Utils/LoadMask';
import Pagination from '../../Utils/pagination';
import Modal from 'react-responsive-modal';
import IconEdit from '../../../icons/edit';
import { IconTrashAlt } from '../../../icons/trash';
import LocationForm from './LocationForm';
import swal from 'sweetalert2';


class Container extends React.Component {

    state={
        modal: false,
        edit: false,
    }

    componentDidMount() {
        this.props.find(this.props.page);
    }

    removeContact = (id) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this location? </h3>",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                this.props.destroy(id);
            }
        })
    }

    editContact = (id) => {
        this.props.load2Update(id).then(()=>{
            this.openModal(true);
        });
    }

    submitModal = (values) => {
        if(this.state.edit){
            this.props.update(values).then(()=>{
                this.closeModal();
            });
        } else {
            this.props.create(values).then(()=>{
                this.closeModal();
            });
        }
    }

    openModal = (edit) => {
        this.setState({ modal: true, edit });
    }

    closeModal = () => {
        this.setState({ modal: false, edit: false })
    }

    render(){

        const {
            search,
            history,
            data,
            find,
            item,
            loading,
            loadingForm,
            userInfo: { permission },
            page,
        } = this.props;

        const {
            modal,
            edit,
        } = this.state;

        return (
            <div className="d-flex flex-wrap justify-content-center">

                <Modal open={modal} onClose={this.closeModal}>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 d-flex flex-wrap justify-content-center pt-5 pb-4">
                            <div className="col-12 col-md-8 col-xl-6">
                                <div className="mb-3">
                                    <h3 className="text-left"> {edit ? "Edit Location" : "New Location"} </h3>
                                </div>
                                <LoadMask loading={loadingForm} blur>
                                    <LocationForm
                                        onSubmit={this.submitModal}
                                        cancel={this.closeModal}
                                        clearData={this.props.clearData}
                                        setDetails={this.props.setDetails}
                                        address={this.props.address}
                                        item={item}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div className="col-12 d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>history.goBack()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="col-12 col-md-10 col-xl-8">
                    <div className="w-100 d-flex mb-1">
                        <h3 className="flex-1 text-left"> Locations </h3>
                        {permission && permission.find(({ createAirlineCrew }) => createAirlineCrew == true) ? (
                            <button type="button" className="btn btn-primary" onClick={()=>this.openModal()}>
                                ADD
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100 d-flex flex-wrap py-3">
                        {permission && permission.find(({ readAirlineCrew }) => readAirlineCrew == true) ? (
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by name, address"
                                value={search}
                                onChange={(event)=>{
                                    event.preventDefault();
                                    this.props.setSearch(event.target.value)
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100">
                        {permission && permission.find(({ readAirlineCrew }) => readAirlineCrew == true) ? (
                            <LoadMask loading={loading} blur>
                                <div className="react-bs-table react-bs-table-bordered">
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            {data.total > 0 && data.data.map((item, index)=> (
                                                <tr className="px-3 py-2" key={index}>
                                                    <td className="w-100 d-flex">
                                                        <span className="col-4 px-0 text-truncate"> {item.name} </span>
                                                        <span className="col-7 px-0 text-truncate"> {item.city}, {item.state} {item.zip} </span>
                                                        <div className="col-1 px-0 d-flex justify-content-center">
                                                            {permission && permission.find(({ updateAirlineCrew }) => updateAirlineCrew == true) ? (
                                                                <span onClick={()=>this.editContact(item._id)}>
                                                                    <IconEdit className="smallIcon px-1 clickable"/>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                            {permission && permission.find(({ removeAirlineCrew }) => removeAirlineCrew == true) ? (
                                                                <span onClick={()=>this.removeContact(item._id)}>
                                                                    <IconTrashAlt className="smallIcon px-1 clickable"/>
                                                                </span>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    total={data.total}
                                    page={page}
                                    find={find}
                                    itemsPerPage={5}
                                />
                            </LoadMask>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = state => ({
    ...state.crewAdmin.locations,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
}

export default connect(mstp, mdtp)(Container);
