import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { getExampleNumber } from 'libphonenumber-js';
import examplesMobile from 'libphonenumber-js/examples.mobile.json';
import { renderField, renderTextArea, renderLabeledField, renderSelectField, renderPhoneNumber } from '../../Utils/renderField/renderField';
import { countryOptions } from '../../../utility/constants';
import { connect } from 'react-redux';


const Form = (props) => {

    const { handleSubmit, cancel, formData } = props;

    let phoneTemplate, phonePlaceholder;
    if (formData && formData.values && formData.values.country) {
        let value = formData.values.country;
        if(typeof formData.values.country === 'object')
            value = formData.values.country.value
        const phoneNumber = getExampleNumber(value, examplesMobile);
        phonePlaceholder = phoneNumber ? phoneNumber.formatNational() : '';
        phoneTemplate = phonePlaceholder ? `${phonePlaceholder}`.replace(/\d/g, '#') : '';
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="bg-light d-flex flex-column radius-1">
                <div className="d-flex flex-row justify-content-between">
                    <div className="flex-1 mx-2 text-primary">
                        <b>CONTACT INFO</b>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="flex-1 form-group">
                        <label htmlFor="first_name">First Name</label>
                        <Field
                            name="first_name"
                            component={renderLabeledField}
                            type="text"
                            placeholder="John"
                            className="flex-1 m-2"
                        />
                    </div>
                    <div className="flex-1 form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <Field
                            name="last_name"
                            component={renderLabeledField}
                            type="text"
                            placeholder="Smith"
                            className="flex-1 m-2"
                        />
                    </div>
                    {/* <div className="flex-1 form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                            name="email"
                            component={renderLabeledField}
                            type="text"
                            placeholder="Email"
                            className="flex-1 m-2"
                        />
                    </div> */}
                    <div className="flex-1 form-group">
                        <Field
                            name="country"
                            component={renderSelectField}
                            labelKey="label"
                            valueKey="value"
                            options={countryOptions}
                            placeholder="Country"
                            className="flex-1 m-2"
                            inModal
                        />
                    </div>
                    <div className="flex-1 form-group">
                        <label htmlFor="phone">Phone Number</label>
                        <Field
                            name="phone"
                            component={renderPhoneNumber}
                            type="text"
                            placeholder={phonePlaceholder}
                            formatTemplate={phoneTemplate}
                            className="flex-1 m-2"
                        />
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between">
                    <div className="flex-1 mx-2 text-primary">
                        <b>EMAIL</b>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                            name="email"
                            component={renderLabeledField}
                            type="email"
                            className="flex-1 m-2"
                            placeholder="Email"
                        />
                    </div>
                    <div className="m-2 form-group">
                        <Field
                            name="message"
                            component={renderTextArea}
                            rows={5}
                            label="Message"
                        />
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-end">
                        <button type="button" onClick={cancel} className="btn btn-outline-primary m-1">
                            CANCEL
                        </button>
                        <button type="submit" className="btn btn-primary m-1">
                            SEND
                        </button>
                    </div>
                </div>
            </div>
        </form>
    )
}

const mstp = state => ({
    formData: state.form.emailForm,
})

const EmailForm = reduxForm({
    form: 'emailForm',
    initialValues: {
        country: {label: "United States", value: "US"},
    },
})(Form);


export default connect(mstp, null)(EmailForm)