import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IconLocationArrow } from '../../icons/location';
import { Icons } from "../../icons/menu";
import { liveryShuttles, reservationsShuttlesTracking } from '../../../routesConfiguration/paths';

const Container = (props) => {
    const { userInfo: { permission }, } = props;

    return (
        <div className="d-flex flex-wrap justify-content-center">
            <div className="col-12 col-xl-10 d-flex flex-wrap">
                <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                    <Link
                        to={reservationsShuttlesTracking}
                        style={{ color: '#000' }}
                        className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <IconLocationArrow className="h-100" />
                        </div>
                        <span className="mt-3">Tracking</span>
                    </Link>
                </div>
                <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                    <Link
                        to={liveryShuttles}
                        style={{ color: '#000' }}
                        className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                    >
                        <div style={{ height: '2.5rem' }}>
                            <Icons.IconCustomers className="h-100" />
                        </div>
                        <span className="mt-3">Shuttles</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

const mstp = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mstp)(Container);
