import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderSelectField,
    renderSearchSelect,
    renderImagePicker,
    renderNumber,
    renderSwitch,
} from "Utils/renderField/renderField";
import { required, email, length } from "redux-form-validators";
import { settingsUserAccountsUsers } from "../../../../routesConfiguration/paths";


const Form = (props) => {
    const { handleSubmit, photo, getRoles } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>Admin User</strong>
                    </h2>
                </div>

                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="firstName">First Name</label>
                            <Field
                                name="firstName"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="lastName">Last Name</label>
                            <Field
                                name="lastName"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="email">E-mail</label>
                            <Field
                                name="email"
                                component={renderField}
                                type="email"
                                className="form-control"
                                validate={email()}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="permission">Permission</label>
                            <Field
                                multi
                                allKey
                                name="permission"
                                valueKey="_id"
                                labelKey="name"
                                component={renderSearchSelect}
                                loadOptions={getRoles}
                                placeholder="Select roles"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="mobileNo"> Phone Number </label>
                            <Field
                                name="mobileNo"
                                component={renderNumber}
                                placeholder="(201) 555 0123"
                                formatTemplate="(###) ### ####"
                                className="w-100"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="password">Super user</label>
                            <Field
                                name="superUser"
                                component={renderSwitch}
                                label="Super user"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="password">Password</label>
                            <Field
                                name="password"
                                component={renderField}
                                type="password"
                                className="form-control"
                                validate={length({ min: 6 })}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link
                                    className="btn btn-secondary m-1"
                                    to={settingsUserAccountsUsers}
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const UserForm = reduxForm({
    form: "user",
    validate: (data) => {
        return validate(data, {
            firstName: validators.exists()("Required field"),
            lastName: validators.exists()("Required field"),
            email: validators.exists()("Required field"),
            password: validators.exists()("Required field"),
            permission: validators.exists()("Required field"),
        });
    },
})(Form);

export default UserForm;
