export const selectStyles = {
  control: (styles, { isFocused, isActivated }) => ({
    ...styles,
    height: '100%',
    border: 'none',
    boxShadow: 'none',
  }),

  loadingIndicator: () => ({
    display: 'none'
  }),
}
