import app from "../../libs/apiClient";
import { createActions, handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import Swal from "sweetalert2";
import _, { cloneDeep } from "lodash";
import { errorHandler } from "../../common/utility/constants";
import airlineCodes from '../../common/utility/airlines.json';

const service = app.service("/api/meetingProcedure");

const SET_SEARCH = "SET_SEARCH_MEETING_PROCEDURE";
// Actions
export const create = (values, callback) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        if (values.selectAllAirlines)
            values.airlines = airlineCodes.map((item) => item.code);

        const response = await service.create(values);
        if (callback) callback();
        dispatch(find());
    } catch (e) {
        if (e && e.data && e.data.exist) {
            throw e.data;
        } else {
            dispatch(errorHandler(e));
        }
    } finally {
        dispatch(loading(false));
    }
};

export const update = (values, callback) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        if (values.selectAllAirlines)
            values.airlines = airlineCodes.map((item) => item.code);

        await service.patch(values.id, { ...values, multiple: true });
        if (callback) callback();
        dispatch(find());
    } catch (e) {
        if (e && e.data && e.data.exist) {
            throw e.data;
        } else {
            dispatch(errorHandler(e));
        }
    } finally {
        dispatch(loading(false));
    }
};

export const destroy = (id) => async (dispatch, getState) => {
    const result = await Swal.fire({
        title: "Are you sure?",
        text: "You cannot undo this action!",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel",
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        reverseButtons: true,
    });

    if (result.value) {
        dispatch(loading());
        try {
            const remove = await service.remove(id);
            dispatch(find());
        } catch (e) {
            dispatch(errorHandler(e));
        } finally {
            dispatch(loading(false));
        }
    }
};

export const find = (page = 1) => async (dispatch, getState) => {
    await dispatch(loading());
    // const { search } = cloneDeep(getState().meetingProcedure);
    try {
        // const query = { query: { $skip: (page - 1) * 10, $populate: ['airport', 'vehicle'] } };
        const queryGroup = {
            query: {
                group: {
                    airport: 1,
                    type: 1,
                },
                $skip: (page - 1) * 10,
            },
        };

        // if (search) {
        //     let [airports, vehicles] = await Promise.all([
        //         app.service('/api/airport').find({ query: { code: { $regex: _.escapeRegExp(search), $options: "i" }, $paginate: false, $select: ['_id'] } }),
        //         app.service('/api/vehicle').find({ query: { vehicleCode: { $regex: _.escapeRegExp(search), $options: "i" }, $paginate: false, $select: ['_id'] } })
        //     ]);
        //     airports = airports.map((item) => item._id);
        //     vehicles = vehicles.map((item) => item._id);
        //     query.query.$or = [
        //         { airline: { $regex: _.escapeRegExp(search), $options: "i" } },
        //         { airport: { $in: airports } },
        //         { vehicle: { $in: vehicles } },
        //         { type: _.escapeRegExp(search) },
        //     ];
        // }
        // const response = await service.find(query);
        const responseGrouped = await service.find(queryGroup);
        let { data = [] } = responseGrouped;
        data = data.map((mp, index) => ({
            ...mp,
            _id: {
                ...mp._id,
                name: mp.meetingProcedures.find((m) => m.name) ? mp.meetingProcedures.find((m) => m.name).name : "",
                instructions: mp.meetingProcedures[0]
                    ? mp.meetingProcedures[0].instructions
                    : "",
            },
            id: `${mp._id.airport}${mp._id.type}${index}`,
        }));
        responseGrouped.data = data;
        await dispatch(setData(responseGrouped));
        await dispatch(setPage(page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        await dispatch(loading(false));
    }
};

export const load2Update = (id) => async (dispatch, getState) => {
    dispatch(loading());
    const { data: meetingProcedures } = cloneDeep(getState().meetingProcedure);
    const { data } = meetingProcedures;
    const meetingProcedure = data.find((mp) => mp.id === id) || {};
    const {
        airport,
        airlines,
        vehicles,
        _id = {},
        meetingProcedures: procedures = [],
    } = meetingProcedure;
    airport.label = `${airport.code} - ${airport.name}`;
    airport.value = airport._id;
    const { name, type, instructions } = _id;
    const ids = procedures.map((p) => p._id);

    const selectAllAirlines =
        airlines.length === airlineCodes.length &&
        airlines.every((code) => airlineCodes.find((ac) => ac.code === code));

    const meetingProcedureForm = {
        name,
        airport,
        airlines,
        vehicles,
        type,
        instructions,
        ids,
        id,
        selectAllAirlines,
    };

    try {
        // const data = await service.get(id, { query: { $populate: ['vehicle', 'airport'] } });
        // if (data.airport)
        //     data.airport.label = `${data.airport.code} - ${data.airport.name}`;
        // if (data.vehicle)
        //     data.vehicle.label = `${data.vehicle.vehicleCode} ${data.vehicle.type}`;

        dispatch(setUpdateData(meetingProcedureForm));
        dispatch(initializeForm("meetingProcedureForm", meetingProcedureForm));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};
export const setSearch = (search) => (dispatch) => {
    dispatch({ type: SET_SEARCH, search });
    dispatch(find(1));
};

export const { loading, setData, setPage, setUpdateData } = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
    SET_PAGE: (page) => ({ page }),
    SET_UPDATE_DATA: (updateData) => ({ updateData }),
});

// Reducers
const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setUpdateData]: (state, { payload: { updateData } }) => ({
        ...state,
        updateData,
    }),
    [SET_SEARCH]: (state, { search }) => ({ ...state, search }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    search: "",
    loading: false,
    updateData: {},
};

export default handleActions(reducers, initialState);
