import React, { useEffect } from 'react';
import MapWithADirectionsRenderer from '../../Utils/MapWithADirectionsRenderer';


const SingleDetail = (props) => {

    const {
        getVehicleLocation,
        vehicleLocation,
        singleDetail,
        editTrip,
        inputData,
        loading,
        sendEmailNoTracking,
        btnDone,
    } = props;

    useEffect(() => {
        const interval = setInterval(() => {
            getVehicleLocation()
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    const formatVehicleName = () => {
        let vehicleName = "";
        if(singleDetail.vehicleInfo && singleDetail.vehicleInfo.tags && singleDetail.vehicleInfo.tags.length > 0){
            for(let i = 0; i < singleDetail.vehicleInfo.tags.length; i++){
                let item = singleDetail.vehicleInfo.tags[i];
                if(item.data.name == "Color")
                    vehicleName = `${item.name} ${vehicleName}`;
                else if(item.data.name == "Vehicle Type")
                    vehicleName += item.name;
            }
        }
        if(singleDetail.VehicleDescription && singleDetail.VehicleDescription !== "Subcontractor")
            vehicleName += ` ${singleDetail.VehicleDescription}`;

        if(vehicleName == "")
            vehicleName="Unknown";

        return vehicleName;
    }

    return (
        <div className="d-flex flex-column flex-md-row py-4 py-md-5">
            {singleDetail && (
                <React.Fragment>
                    <div className="flex-1 py-2 px-3">
                        <div className="h-100">
                            <div className="d-flex">
                                <span>
                                    {singleDetail.etaResult && (
                                        <h4 className="d-flex"> ETA {singleDetail.etaResult.time} ({singleDetail.etaResult.duration}) </h4>
                                    )}
                                </span>
                                <span className="flex-1"></span>
                                <button type="button" className="btn btn-secondary px-3 py-1 my-2"
                                    onClick={()=>sendEmailNoTracking({ ...singleDetail, vehicleLocation })}
                                >
                                    Not Tracking
                                </button>
                            </div>
                            <MapWithADirectionsRenderer
                                fromLat={ singleDetail.location && singleDetail.location.pickUp && singleDetail.location.pickUp.lat }
                                fromLng={ singleDetail.location && singleDetail.location.pickUp && singleDetail.location.pickUp.lng }
                                toLat={ singleDetail.location && singleDetail.location.dropOff && singleDetail.location.dropOff.lat }
                                toLng={ singleDetail.location && singleDetail.location.dropOff && singleDetail.location.dropOff.lng }
                                showLine={
                                    ["Unassigned", "Assigned", "Dispatched", "On The Way", "Arrived", "Customer In Car", "Done"].includes(singleDetail.TripStatusDesc)
                                        ? false
                                        : true
                                }
                                vehicleLocation={vehicleLocation}
                                mapContainerStyle={{ height: '100%' }}
                                options = {{
                                    styles: [
                                        {
                                            featureType: "poi",
                                            stylers: [
                                                {
                                                    visibility: "off"
                                                }
                                            ]
                                        },
                                        {
                                            featureType: "transit",
                                            stylers: [
                                                {
                                                    visibility: "off"
                                                }
                                            ]
                                        },
                                    ]
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex-1 py-2 px-4 d-flex flex-column">
                        <div className="">
                            <h3 className="d-flex flex-column mb-4 px-3">
                                <div className="text-left">
                                    Trip ID: {singleDetail.TripID} | Flight: {inputData && `${inputData.airlineCode.code} ${inputData.flightNumber}`}
                                </div>
                            </h3>
                        </div>

                        <div className="d-flex">
                            <div className="flex-1 mb-2 px-3">
                                <span>
                                    <strong> Date: </strong>
                                    {singleDetail.ReservationDateTime.substring(0,singleDetail.ReservationDateTime.length-7)}
                                </span>
                            </div>
                            <div className="flex-1 mb-2 px-3">
                                <span>
                                    <strong>Time: </strong>
                                    {singleDetail.ReservationDateTime.substring(singleDetail.ReservationDateTime.length-7,singleDetail.ReservationDateTime.length)}
                                </span>
                            </div>
                        </div>
                        <div className="mb-2 px-3">
                            <span>
                                <strong>Pax: </strong>
                                {singleDetail.NumOfPassengers} {singleDetail.NumOfPassengers === 1 ? 'Passenger' : 'Passengers'}
                            </span>
                        </div>

                        <div className="w-100">
                            <hr className="border-dark"/>
                        </div>

                        <div className="mb-2 px-3">
                            <strong> From: </strong>
                            <span> {singleDetail.PUDOList[0].LandMark} </span>
                        </div>
                        <div className="mb-2 px-3">
                            <strong> To: </strong>
                            <span> {singleDetail.PUDOList[1].LandMark} </span>
                        </div>

                        <div className="w-100">
                            <hr className="border-dark"/>
                        </div>

                        <div className={`mb-2 px-3 ${singleDetail.TripStatusDesc == 'Done' ? 'text-danger' : ''}`}>
                            <strong> Status: </strong>
                            <span> {singleDetail.TripStatusDesc} </span>
                        </div>
                        <div className="mb-2 px-3">
                            <strong> Vehicle: </strong>
                            <span> {formatVehicleName()} </span>
                        </div>

                        {singleDetail.vehicleInfo && (
                            <div className="mb-2 px-3">
                                <strong> Make/Model: </strong>
                                <span> {singleDetail.vehicleInfo.make} {singleDetail.vehicleInfo.model} </span>
                            </div>
                        )}

                        {singleDetail.VehicleLicensePlate && singleDetail.VehicleLicensePlate !== "0000" && (
                            <div className="mb-2 px-3">
                                <strong> Plate Number: </strong>
                                <span> {singleDetail.VehicleLicensePlate} </span>
                            </div>
                        )}

                        {singleDetail.ChauffeurName ||
                        singleDetail.FarmStatus === 2 ? (
                            <div className="mb-2 px-3">
                                {singleDetail.ChauffeurName && (
                                    <React.Fragment>
                                        <strong> Driver: </strong>
                                        <span>
                                            {singleDetail.ChauffeurName}
                                        </span>
                                    </React.Fragment>
                                )}
                                {singleDetail.FarmStatus === 2 ? (
                                    <span
                                        className={`${singleDetail.ChauffeurName ? 'ml-4' : ''} rounded bg-dark px-3`}
                                    >
                                        <small className="text-white bold">
                                            FO
                                        </small>
                                    </span>
                                ) : (
                                    ""
                                )}
                            </div>
                        ) : (
                            ""
                        )}

                        {singleDetail.ChauffeurPhone && (
                            <div className="mb-2 px-3">
                                <strong> Phone: </strong>
                                <span> {singleDetail.ChauffeurPhone} </span>
                            </div>
                        )}

                        {singleDetail.meetingProcedure && (
                            <div className="mb-2 px-3">
                                <strong> Meeting Location </strong>
                                <h6> { singleDetail.meetingProcedure }</h6>
                            </div>
                        )}

                        <div className="d-flex justify-content-center">
                            <button className="btn btn-primary px-4 bolder" onClick={()=>editTrip()}>
                                Edit
                            </button>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button className="btn btn-link text-primary bolder text-decoration-underline px-4"
                                onClick={btnDone}
                            >
                                Done
                            </button>
                        </div>

                        <div className="pt-4 px-3">
                            <h5 className="bold">Driver Schedule</h5>
                            {singleDetail.driverUpcomingTrips && singleDetail.driverUpcomingTrips.length > 0 ? (
                                <React.Fragment>
                                    {singleDetail.driverUpcomingTrips.map((item, index)=>(
                                        <div className="border border-dark" key={index}>
                                            {item.PUTime} {item.CustomerPO} {item.Passenger_Name}
                                        </div>
                                    ))}
                                </React.Fragment>
                            ) : (
                                <div className="border border-dark d-flex justify-content-center">
                                    <h4>No upcoming</h4>
                                </div>
                            )}
                        </div>

                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default SingleDetail;
