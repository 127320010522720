import { connect } from "react-redux";
import ClientsGrid from "./ClientGrid";
import {
    find,
    destroy,
    setSearch,
    setClientsQuery,
} from "../../../../redux/modules/clients";

const mstp = (state) => ({
    ...state.clients,
    userInfo: state.login.userInfo,
})

const mdtp = { find, destroy, setClientsQuery, setSearch };

export default connect(mstp, mdtp)(ClientsGrid);
