import React from 'react';
import PropTypes from 'prop-types';
import cardValidator from 'card-validator';
import { Field, reduxForm } from 'redux-form';
import { combine, validate, validators } from 'validate-redux-form';

import { months, years } from '../../../../../utility/constants';
import { cardValidators } from '../../../../../utility/form-validations';
import {
    renderLabeledField,
    renderSecurityCode,
    renderSelectField,
    renderCreditCard,
} from '../../../../Utils/renderField/renderField';

const Form = ({
    cardNumber,
    handleSubmit,
}) => {
    const numberValidation = cardValidator.number(cardNumber);
    let code = {},
        type;
    if (numberValidation.card) {
        code = numberValidation.card.code;
        // filter available images
        const imageAvailable = [
            'visa',
            'mastercard',
            'discover',
            'american-express',
        ].some(card => card === numberValidation.card.type);
        if (imageAvailable) type = numberValidation.card.type;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-5 p-3 bg-light d-flex flex-column flex-wrap radius-1">
                <div>
                    <div className="d-flex flex-row justify-content-between">
                        <div className="flex-1 m-2 text-primary">
                            <b>CREDIT CARD INFORMATION</b>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor="name">Full Name</label>
                            <Field
                                name="name"
                                component={renderLabeledField}
                                type="text"
                                placeholder="John Smith"
                                className="flex-1 w-100 m-2"
                            />
                        </div>
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor="card_number">
                                Debit/Credit Card Number
                            </label>
                            <Field
                                name="card_number"
                                component={renderCreditCard}
                                type="text"
                                placeholder="1234567890123456"
                                className="flex-1 w-100 m-2"
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                        <div className="flex-1 d-flex flex-column flex-md-row">
                            <div className="col mt-2 px-2">
                                <div className="flex-1 d-flex flex-column form-group form-group--tour">
                                    <label htmlFor="month">Month</label>
                                    <Field
                                        name="month"
                                        component={renderSelectField}
                                        className="label"
                                        valueKey="value"
                                        options={months}
                                        inModal
                                    />
                                </div>
                            </div>
                            <div className="col mt-2 px-2">
                                <div className="flex-1 d-flex flex-column form-group form-group--tour">
                                    <label htmlFor="year">Year</label>
                                    <Field
                                        name="year"
                                        component={renderSelectField}
                                        className="label"
                                        valueKey="value"
                                        options={years}
                                        inModal
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 d-flex flex-row">
                            <div className="flex-2 form-group">
                                <label htmlFor="cvv">Security Code</label>
                                <Field
                                    name="cvv"
                                    component={renderSecurityCode}
                                    codeSize={code.size || 3}
                                    type="text"
                                    placeholder="CVV"
                                    className="flex-1 m-2"
                                />
                            </div>
                            {/* Add image for card security code */}
                            <div className="flex-1 form-group d-flex justify-content-center align-items-center">
                                {type && (
                                    <img
                                        style={{ height: '3.6rem' }}
                                        src={require(`../../../../../../../assets/img/${type}.svg`)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column flex-sm-row">
                    <button
                        type="submit"
                        className="btn btn-primary m-2"
                    >
                        <b>BOOK NOW</b>
                    </button>
                </div>
            </div>
        </form>
    );
};

Form.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
};

const validation = data => {
    return validate(data, {
        name: validators.exists()('Required Field'),
        card_number: combine(
            cardValidators()('Card number invalid'),
            validators.exists()('Required Field')
        ),
        month: validators.exists()('Required Field'),
        year: validators.exists()('Required Field'),
        cvv: validators.exists()('Required Field'),
    });
};

export default reduxForm({
    form: 'PaymentForm',
    validate: validation,
})(Form);
