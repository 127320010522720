import { connect } from "react-redux";
import MeetingProceduresGrid from "./Grid";
import { find, destroy, setSearch, create, update, load2Update } from "../../../../redux/modules/meetingProcedure";

const mstp = (state) => ({
    ...state.meetingProcedure,
    userInfo: state.login.userInfo,
});

const mdtp = { find, destroy, create, update, setSearch, load2Update };

export default connect(mstp, mdtp)(MeetingProceduresGrid);
