import { createActions, handleActions } from "redux-actions";
import { goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import app from "../../libs/apiClient";
import { errorHandler } from '../../common/utility/constants';
import _ from 'lodash';
import { actions as breadcrumbActions } from "./breadcrumb";

const service = app.service("/api/vehicle");

const SET_SEARCH = "SET_SEARCH";
const VEHICLE_LOCATION_DATA = "VEHICLE_LOCATION_DATA";

// Actions
export const { loading, setData, setPage, setUpdateData } = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
    SET_PAGE: (page) => ({ page }),
    SET_UPDATE_DATA: (updateData) => ({ updateData }),
});

export const create = (formData) => (dispatch) => {
    const payload = {
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    };

    dispatch(loading());
    service
        .create(payload, {
            headers: {
                "content-type": "multipart/form-data",
            },
        })
        .then(() => {
            dispatch(goBack());
        })
        .catch((e) => {
            dispatch(errorHandler(e));
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

export const update = (formData) => async (dispatch) => {
    const payload = {
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    };

    dispatch(loading());

    try {
        await service.patch(payload._id, payload, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        dispatch(goBack());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const destroy = (id) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        const remove = await service.remove(id);
        dispatch(find());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const { searchVehicles } = getState().vehicles;
        const query = { query: { $skip: (page - 1) * 10 } };
        if(searchVehicles)
            query.query.$or = [
                { type: { $regex: _.escapeRegExp(searchVehicles), $options: 'i' } },
                { class: { $regex: _.escapeRegExp(searchVehicles), $options: 'i' } },
                { vehicleCode: { $regex: _.escapeRegExp(searchVehicles) , $options: 'i'} },
            ]
        const response = await service.find(query);
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const load2Update = (id) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const car = await service.get(id);
        // If the feature type is an string we need to change type to array
        for(let index = 0; index < car.features.length; index++){
            if(typeof car.features[index].featureType == 'string')
                car.features[index].featureType = [car.features[index].featureType]
        }

        const { updateCustomLabel } = breadcrumbActions;
        dispatch(updateCustomLabel(id, car.type));
        dispatch(setUpdateData(car));
        dispatch(initializeForm("vehicle", car));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const setSearch = () => (dispatch, getState) => {
    const { values } = getState().form.searchVehicles;
    let search = values && values.search;
    dispatch({ type: SET_SEARCH, searchVehicles: search });
    dispatch(find());
}
export const getVehicleLocation = (id, params) => async(dispatch, getStore)=> {
    service.get(id, params).then(response => {
        response.bookingOverride = response.bookingOverride || false,
        response.inFleet = response.inFleet || false,
        dispatch(setVehicleLocation(response));
        dispatch(initializeForm('vehicleLocationForm', response));
    }).catch(error=>{
        console.error(error);
    })
}

export const getLocationsVehicle = (id) => async (dispatch, getStore) => {
    const { locationVehicle } = getStore().form;
    if(!locationVehicle || !locationVehicle.values || Object.keys(locationVehicle.values).length === 0){
        const locations = await app.service('/api/location').find({ query: { $paginate: false } });
        const formData = [];
        for(let i = 0; i < locations.length; i++){
            let found = locations[i].vehicleBaseRates.findIndex( ({ vehicle }) => JSON.stringify(vehicle) === JSON.stringify(id) )
            let obj = {
                location: locations[i],
                apply: false,
                notFoundInLocation: found === -1 ? true : false,
                foundProfile: found === -1 ? undefined : locations[i].vehicleBaseRates[found].vehicleLocation,
                idBaseRate: found === -1 ? undefined : locations[i].vehicleBaseRates[found]._id,
            };
            formData.push(obj);
        }
        dispatch(initializeForm('locationVehicle', { locations: formData }));
    }

}

export const applyProfile = (values) => async (dispatch, getStore) => {

    const { locationData, vehicleData } = values;

    if(!locationData || !vehicleData)
        throw 'No locations or vehicle data';

    for(let i = 0; i < locationData.locations.length; i++){
        if(!!locationData.locations[i].apply){
            if(vehicleData.picture){
                vehicleData.picture = {
                    buffer: vehicleData.picture,
                    mimeType: vehicleData.picture && vehicleData.picture.type,
                }
            }

            if(!!locationData.locations[i].foundProfile){
                vehicleData.vehicleDefault = vehicleData._id;
                delete vehicleData._id;
                console.log(locationData.locations[i].foundProfile, vehicleData, locationData.locations[i]);
                await app.service('/api/vehicleLocation').patch(locationData.locations[i].foundProfile, vehicleData, {
                    headers: { "content-type": "multipart/form-data" },
                });
                await app.service('/api/location').patch(locationData.locations[i].location._id, {
                    $set: { "vehicleBaseRates.$[element].rate": vehicleData.defaultRate }
                },{
                    query:{
                        arrayFilters: [ { "element._id": locationData.locations[i].idBaseRate } ],
                    }
                })
            } else {
                let obj = {
                    ...vehicleData,
                    vehicleDefault: vehicleData._id,
                }
                delete obj._id;
                delete obj.createdAt;
                delete obj.updatedAt;

                const responseVehicleLocation = await app.service('/api/vehicleLocation').create(obj, {
                    headers: { "content-type": "multipart/form-data" },
                });
                await app.service('/api/location').patch(locationData.locations[i].location._id, {
                    $push: {
                        vehicleBaseRates: {
                            vehicle: responseVehicleLocation.vehicleDefault,
                            vehicleLocation: responseVehicleLocation._id,
                            rate: vehicleData.defaultRate
                        }
                    }
                });
            }
        }
    }
};

export const clearDataModalVehicleLocation = () => (dispatch, getStore) => {
    dispatch(initializeForm('vehicleLocationForm', {}));
    dispatch(initializeForm('locationVehicle', {}));
}

const setVehicleLocation = (vehicleLocation) => ({
    type: VEHICLE_LOCATION_DATA,
    vehicleLocation
})

// Reducers
const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setUpdateData]: (state, { payload: { updateData } }) => ({ ...state, updateData, }),
    [SET_SEARCH]: (state, { searchVehicles }) => ({ ...state, searchVehicles }),
    [VEHICLE_LOCATION_DATA]: (state, { vehicleLocation }) => ({  ...state, vehicleLocation }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    loading: false,
    updateData: {},
    vehicleLocation: {},
    searchVehicles: undefined,
};

export default handleActions(reducers, initialState);
