import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";


const LOADING = "LOADING_CREW_ACCOUNTS_AIRLINE";
const LOADING_FORM = "LOADING_UPDATE_FORM_ACCOUNTS_AIRLINE";
const SET_ACCOUNTS_AIRLINE_DATA = "SET_ACCOUNTS_AIRLINE_DATA";
const SET_PAGE_ACCOUNTS_AIRLINE = "SET_PAGE_ACCOUNTS_AIRLINE";
const SET_SEARCH_ACCOUNTS_AIRLINE = "SET_SEARCH_ACCOUNTS_AIRLINE";

export const find = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $sort: { acct_name: 1 },
            $paginate: false,
            airline: id,
        }};
        const search = getStore().crewAdmin.accounts.search;
        if(search){
            params.query.$or = [
                { acct_name: { $regex: _.escapeRegExp(search), $options: 'i' }},
            ]
        }

        const data = await app.service('/api/airline_account').find(params);
        let airline_account_ids = data.map(item=>item._id);
        const account_groups = await app.service('/api/account_groups').find({ query: { $paginate: false, account: { $in: airline_account_ids } }});
        for(let i = 0; i < data.length; i++){
            data[i].account_groups = account_groups.filter(item=> JSON.stringify(item.account) == JSON.stringify(data[i]._id));
        }
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    let { item } = getStore().crewAdmin.airlines;
    try {
        await app.service('/api/airline_account').create({ ...data, airline: item._id });
        dispatch(find(item._id));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let params = { query: { } };
        let data = await app.service('/api/airline_account').get(id, params);
        dispatch(initializeForm('accountForm', data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (data) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    let { item } = getStore().crewAdmin.airlines;
    try{
        await app.service('/api/airline_account').patch(data._id, data);
        dispatch(find(item._id));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { item } = getStore().crewAdmin.airlines;
    try {
        await app.service('/api/airline_account').remove(id);
        dispatch(find(item._id));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setSearch = (search) => (dispatch, getStore) => {
    let { item } = getStore().crewAdmin.airlines;
    dispatch({ type: SET_SEARCH_ACCOUNTS_AIRLINE, search });
    dispatch(find(item._id));
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE_ACCOUNTS_AIRLINE, page });
export const setData = (data) => ({ type: SET_ACCOUNTS_AIRLINE_DATA, data });

export const actions = {
    find,
    create,
    setSearch,
    load2Update,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_ACCOUNTS_AIRLINE]: (state, { page }) => ({ ...state, page }),
    [SET_ACCOUNTS_AIRLINE_DATA]: (state, { data }) => ({ ...state, data }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH_ACCOUNTS_AIRLINE]: (state, { search }) => ({...state, search }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: [],
    search: undefined,
};

export default handleActions(reducers, initialState);
