import React from 'react';
import { TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import moment from 'moment';
import { BreakLine } from "../../Utils/tableOptions";
import Table from "Utils/Grid";
import { Field, reduxForm } from "redux-form";
import IconFilter from '../../../icons/filter';
import { RenderRating } from "../../Utils/renderField/renderReadField";
import { renderField, renderSwitch } from "../../Utils/renderField/renderField";
import { reservationsCharterReviewsService } from '../../../../routesConfiguration/paths';


const Grid = (props) => {

    const { data, loading, destroyReview, find, activeTab, permission, page } = props;

    return (
        <React.Fragment>
            <div className="d-flex flew-row">
                <div className="d-flex flex-column flex-md-row mb-3 col-12 px-0">
                    <div className="flex-2">
                        <Field
                            name="search"
                            type="input"
                            component={renderField}
                            placeholder="Search by livery ID, Confirmation No, Passenger, Email"
                            cb={find}
                        />
                    </div>
                    <div className="d-flex justify-content-md-end justify-content-start my-2 my-md-0 flex-1">
                        <div className="d-flex justify-content-end">
                            <label className="mb-0 mr-2" htmlFor="testimonial">
                                Testimonial
                            </label>
                            <Field
                                name="testimonial"
                                component={renderSwitch}
                                height={22}
                                width={36}
                                cb={find}
                            />
                        </div>
                    </div>
                    {/* <div className="d-flex mt-2 mt-md-0 ml-0 ml-md-3 align-items-center justify-content-end">
                        <div className="px-1">
                            <IconFilter className="smallIcon"/>
                        </div>
                        <Field
                            name="filter"
                            component={renderDropdownSelection}
                            className="d-flex justify-content-end"
                            placeholder="Filter by"
                            clearable
                            options={[
                                {key: "Livery No", text: "Livery No", value: "liveryNo"},
                                {key: "Date", text: "Date", value: "date"},
                                {key: "Passenger", text: "Passenger", value: "passenger"},
                            ]}
                            cb={find}
                        />
                    </div>
                    <div className="d-flex flex-column justify-content-end flex-1">
                        <div className="d-flex justify-content-end" >
                            <label className="mb-0" htmlFor="close">Show Pending&nbsp;</label>
                            <Field
                                name="close"
                                component={renderSwitch}
                                height={22}
                                width={44}
                                cb={find}
                            />
                        </div>
                    </div> */}
                </div>
            </div>

            <Table
                onPageChange={find}
                data={data}
                loading={loading}
                page={page}
            >
                <TableHeaderColumn
                    tdStyle={BreakLine}
                    thStyle={BreakLine}
                    className="hide-column-header"
                    dataField="_id"
                    width="15%"
                    isKey
                    dataAlign="center"
                    dataFormat={ (cell, row) =>
                        <div className="d-flex flex-column-reverse flex-md-row">
                            <div className="flex-1 d-flex flex-column">
                                <div className="d-flex justify-content-start align-items-center">
                                    <RenderRating
                                        isHalf={true}
                                        size={24}
                                        value={row.rating}
                                    />
                                    <span> &nbsp;{row.rating}/5 </span>
                                </div>
                                {row.confirmationNo && (
                                    <div className="d-flex justify-content-start">
                                        <label> <strong> Confirmation No:&nbsp;</strong> </label>
                                        <label> {row.confirmationNo} </label>
                                    </div>
                                )}
                                {row.date && (
                                    <div className="d-flex justify-content-start">
                                        <label> <strong> Date:&nbsp;</strong> </label>
                                        <label> {row.date && moment(row.date).format('l')} </label>
                                    </div>
                                )}
                                {row.firstName && (
                                    <div className="d-flex justify-content-start">
                                        <label> <strong> First Name:&nbsp;</strong> </label>
                                        <label> {row.firstName} </label>
                                    </div>
                                )}
                                {row.lastName && (
                                    <div className="d-flex justify-content-start">
                                        <label> <strong> Last Name:&nbsp;</strong> </label>
                                        <label> {row.lastName} </label>
                                    </div>
                                )}
                                {row.email && (
                                    <div className="d-flex justify-content-start">
                                        <label> <strong> Email:&nbsp;</strong> </label>
                                        <label> {row.email} </label>
                                    </div>
                                )}
                                {row.close !== undefined && (
                                    <div className="d-flex justify-content-start">
                                        <label> <strong> Status:&nbsp;</strong> </label>
                                        <label> {row.close ? 'done' : 'pending'} </label>
                                    </div>
                                )}
                            </div>
                            <div>
                                {activeFormatter({
                                    ver: `${reservationsCharterReviewsService}`,
                                    eliminar: permission.find(({ removeReviews }) => !!removeReviews) ? (id) => destroyReview(id) : undefined
                                })(cell, row)}
                            </div>
                        </div>
                    }
                >
                </TableHeaderColumn>
            </Table>
        </React.Fragment>
    )
}

export default Grid;
