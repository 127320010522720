import { connect } from "react-redux";
import Update from "./additionalChargeUpdate";
import { actions } from "../../../../redux/modules/additionalCharges";

const ms2p = (state) => ({
    ...state.additionalCharges,
    userInfo: state.login.userInfo,
})

const mdtp = { ...actions };

export default connect(ms2p, mdtp)(Update);
