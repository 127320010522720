import _ from "lodash";

const validate = (values) => {
    const errors = {};
    if (!values.city) {
        errors.city = "Required Field";
    }
    if (!values.state) {
        errors.state = "Required Field";
    }
    if (!values.cityCode) {
        errors.cityCode = "Required Field";
    }
    if (!values.vehicleBaseRates || !values.vehicleBaseRates.length) {
        errors.vehicleBaseRates = {
            _error: "At least one vehicle must be entered",
        };
    } else {
        const vehicleBaseRatesArrayErrors = [];
        values.vehicleBaseRates.forEach((vehicle, vehicleIndex) => {
            const vehicleErrors = {};
            if (!vehicle || !vehicle.vehicle) {
                vehicleErrors.vehicle = "Required Field";
                vehicleBaseRatesArrayErrors[vehicleIndex] = vehicleErrors;
            }
            if (!vehicle || !vehicle.rate) {
                vehicleErrors.rate = "Required Field";
                vehicleBaseRatesArrayErrors[vehicleIndex] = vehicleErrors;
            }
            if (
                vehicle &&
                _.filter(values.vehicleBaseRates, { vehicle: vehicle.vehicle })
                    .length > 1
            ) {
                vehicleErrors.vehicle = "Duplicated Field";
                vehicleBaseRatesArrayErrors[vehicleIndex] = vehicleErrors;
            }
        });
        if (vehicleBaseRatesArrayErrors.length) {
            errors.vehicleBaseRates = vehicleBaseRatesArrayErrors;
        }
    }
    return errors;
};

export default validate;
