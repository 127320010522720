import React from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { actions } from "../../../../../redux/modules/pointToPoint";
import { actions as locationActions } from '../../../../../redux/modules/locations';
import PointToPointForm from './pointToPointForm';
import Grid from './Grid';
import LoadMask from '../../../Utils/LoadMask';
import IconTimes from '../../../../icons/times';
import { change } from 'redux-form';
import { getDistance, TmTtConversion } from "../../../../utility/constants";
import { getPathWithParams, settingsRatesLocationsIdPromotionalRates } from "../../../../../routesConfiguration/paths";


class DetailContainer extends React.Component {

    state = {
        showForm: false,
        edit: false,
        from: undefined,
        to: undefined,
    }

    componentDidMount() {
        const { getLocationHeader, match } = this.props;
        getLocationHeader(match.params.id);
    }

    showForm = (value) => this.setState({ showForm: value });
    setEdit = (value) => this.setState({ edit: value });

    showEdit = (id) => {
        this.props.load2Update(id).then(()=>{
            this.setEdit(true);
            this.showForm(true);
        });
    }

    setDetails = (data, type)  => {
        data.address = data.formatted_address;
        if(data.administrative_area_level_2)
            data.county = data.administrative_area_level_2;
        if(data.route && data.street_number)
            data.street = `${data.route} ${data.street_number}`;
        else if(data.street_address)
            data.street = `${data.street_address}`;

        if(type === 'from') {
            this.setState({ from: data });
        } else {
            this.setState({ to: data });
        }

        if(this.state.from && this.state.to){
            getDistance(this.state.from, this.state.to).then(response=>{
                if(response && response.distance){
                    let tDuration = TmTtConversion(response.distance)
                    let minutes = tDuration % 1;
                    let hours = tDuration - minutes;
                    let stringHours = hours > 0 ? `${hours} ${(hours) === 1 ? 'hour' : 'hours'}` : '';
                    let stringMinutes = minutes > 0 ? `${Math.floor(minutes * 60)} ${Math.floor(minutes * 60) === 1 ? 'minute' : 'minutes'}` : '';
                    let newDuration = `${stringHours ? stringHours + ' ' : ''}${stringMinutes ? stringMinutes : ''}`;
                    this.props.change('pointToPointForm', 'distance', `${response.distance} mile${response.distance === 1 ? '' : 's' }`);
                    this.props.change('pointToPointForm', 'duration', newDuration);
                }
            }).catch(error=>{
                console.log(error);
            })
        }
    }

    submit = async (formData) => {
        let values = Object.assign({}, formData);
        const { from, to, edit, } = this.state;
        const { create, update, match: { params } } = this.props;
        values.from = { ...from, name: values.fromName };
        values.to = { ...to, name: values.toName };
        let result = false;
        if(!edit){
            values.locationId = params.id;
            result = await create(values);
        } else {
            result = await update(values);
        }
        if(result){
            this.showForm(false);
            if(!edit)
                this.setEdit(false);
            await this.props.find(params.id)
        }
    }

    render() {

        const { showForm, edit } = this.state;
        const { userInfo: { permission }, updateData, match: { params }, header, loaderCreateUpdate, setUpdateData, formValues } = this.props;

        return permission && permission.find(({ updateLocations }) => !!updateLocations)
            ? <div className="d-flex flex-column border border-dark p-2 p-md-5 flex-wrap justify-content-center align-items-center">
                <div className="col-12 d-flex justify-content-center align-items-center">
                    {header && (
                        <React.Fragment>
                            <h1> Point-to-point Special ({header.cityCode})</h1>
                        </React.Fragment>
                    )}
                </div>

                <div className="">
                    <Grid {...this.props}
                        showForm = {()=>this.showForm(true)}
                        showEdit={this.showEdit}
                    />
                </div>

                <div className="col-12">
                    <Link
                        to={getPathWithParams(
                            settingsRatesLocationsIdPromotionalRates,
                            "id",
                            params.id
                        )}
                        className="btn btn-secondary m-1">
                        Back
                    </Link>
                </div>

                <Modal isOpen={showForm} toggle={()=>this.showForm(false)}
                    modalClassName="no-scroll-y" className="modal-dialog--big"
                >
                    <ModalBody className="modal-body--big"
                        style={{ overflowY: "auto", maxHeight: "95vh", marginTop: "1%", marginBottom: "1%", display: "block", }}
                    >
                        <button type="button" onClick={()=>this.showForm(false)} className="modal-body__close">
                            <IconTimes className="smallIcon"/>
                        </button>

                        <div className="d-flex flex-wrap justify-content-md-center">
                            <div className="col-12 col-md-10 py-md-5 py-3">
                                <LoadMask loading={loaderCreateUpdate} blur>
                                    <PointToPointForm
                                        updateData={updateData}
                                        onSubmit={this.submit}
                                        cancel={()=>this.showForm(false)}
                                        setDetails={this.setDetails}
                                        params={params}
                                        setUpdateData={setUpdateData}
                                        formValues={formValues}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
            : null
    }
}

const mstp = (state) => ({
    ...state.pointToPoint,
    userInfo: state.login.userInfo,
    header: state.locations.header,
    formValues: state.form.pointToPointForm && state.form.pointToPointForm.values,
});

const mdtp = {
    ...actions,
    getLocationHeader: locationActions.getLocationHeader,
    change,
};

export default connect(mstp, mdtp)(DetailContainer);
