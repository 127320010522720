import swal from "sweetalert2";
import { reservationsCharterBookings } from "../../../../routesConfiguration/paths";

const AssignmentConfirmation = (cb, redirect) =>
    swal({
        title: "Submit the Livery Trip ID",
        input: "text",
        text: "Assignments can not be reverted",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        confirmButtonText: "Yes, confirm assignment",
        preConfirm: (liveryNo) => {
            if (!liveryNo) {
                swal.showValidationMessage("Please insert a valid number");
                return;
            }

            return cb(liveryNo)
                .then((res) => {
                    return res;
                })
                .catch((err) => {
                    swal.showValidationMessage(`Request failed: ${err}`);
                });
        },
    }).then((result) => {
        if (result.value) {
            swal.fire({
                title: "Reservation updated",
            }).then(() => redirect(reservationsCharterBookings));
        }
    });

export default AssignmentConfirmation;
