import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderFieldCheck, renderPlacesAutocomplete, renderSelectField, timezonePicker } from "../../Utils/renderField/renderField";

const Form = (props) => {
    const { handleSubmit, setDetails, item, clearData } = props;

    useEffect(() => {
        return () => clearData();
    }, []);

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row">
                    <div className="form-group col-12">
                        <strong htmlFor="code"> IATA Code </strong>
                        <Field
                            name="code"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="name"> Airport Name </strong>
                        <Field
                            name="name"
                            component={renderField}
                            type="text"
                            className="form-control"
                        />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="timeZone"> Time Zone </strong>
                        <Field name="timeZone" component={timezonePicker} />
                    </div>
                    <div className="form-group col-12">
                        <strong htmlFor="street"> Address </strong>
                        <Field
                            id="autoCompleteAirport"
                            name="address"
                            component={renderPlacesAutocomplete}
                            additionalAddressInfo={['street_number', 'route']}
                            setDetails={setDetails}
                            Value={item && item.address}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <button type="submit" className="btn btn-primary m-1 px-4">
                        Save
                    </button>
                </div>
            </div>
        </form>
    );
};

const AirportForm = reduxForm({
    form: "airportForm",
    validate: (data, props) => {
        let { address } = props;
        const errors = {};
        if(!data.code) errors.code = "Required field";
        if(!data.name) errors.name = "Required field";
        if(!data.timeZone) errors.timeZone = "Required field";
        if(!data.address) errors.address = "Required field";
        else if(!address || !address.state || !address.postal_code || !address.city || !address.street_number || !address.route)
            errors.address = 'Invalid address';
        return errors;
    }
})(Form);

export default AirportForm;
