import React from "react";
import Form from "./ScheduleForm";
import LoadMask from '../../Utils/LoadMask';
import { connect } from "react-redux";
import { actions } from "../../../../redux/modules/schedule";
import Modal from 'react-responsive-modal';
import IconDownload  from '../../../icons/download';
import moment from 'moment';
import GridSchedules from './gridSchedules';
class Create extends React.Component {

    componentDidMount(){
        this.props.find();
    }

    render() {
        const { create, getAirlines, page, data, find, getVehicles, killReadProcess,search, setSearch,
            destroy, loadingGrid, userInfo: { permission } } = this.props;

        return (
            <div className="px-3 px-lg-5">
                {permission && permission.find(({ createAirlineSchedule }) => !!createAirlineSchedule) && (
                    <div className="d-flex justify-content-center">
                        <div className="col-12 col-md-6 col-lg-5 col-xl-3 px-0">
                            <div className="rounded p-5" style={{ border: '2px solid #707070' }}>
                                <LoadMask loading = { this.props.loading } blur>
                                    <h4 className="bold"> NEW UPLOAD </h4>
                                    <Form onSubmit={create} getAirlines={getAirlines}/>
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                )}

                {permission && permission.find(({ readAirlineSchedule }) => !!readAirlineSchedule) && (
                    <div className="rounded my-5 p-3 p-lg-5 d-flex justify-content-center" style={{ border: '2px solid #707070' }}>
                        <div className="col-12 col-lg-10 col-xl-8">
                            <h4 className="bold mb-5"> ALL UPLOADS </h4>
                            <input
                                type="search"
                                placeholder="Search by airline, airport or dates..."
                                className="form-control"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value)
                                }}
                            />
                            <GridSchedules
                                data = {data}
                                page = {page}
                                find = {find}
                                destroy = {destroy}
                                loading = {loadingGrid}
                                killReadProcess = {killReadProcess}
                                permission = {permission}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.schedule,
    userInfo: state.login.userInfo,
});

export default connect(mstp, actions)(Create);
