import React,{useState, useRef} from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderSearchCreateSelect,
    renderSearchSelect,
    renderImagePicker,
} from "Utils/renderField/renderField";

import {Editor} from "Utils/renderField/renderEditor";
import { image } from '../../../utility/form-validations';
import { renderSwitch } from '../../Utils/renderField';
import _ from 'lodash';
import { categories } from '../../../utility/constants';
import app from '../../../../libs/apiClient';

const postCategoryService = app.service("/api/post_category/");

const getCategories = (search) => {
    const query = {};
    if (search) {
        query.query = { name: search };
    }
    return postCategoryService
        .find(query)
        .then((data) => {
            data.forEach((item) => {
                if (!_.find(categories, { _id: item._id })) {
                    item.name = `${item.name}`;
                    categories.push(item);
                }
            });
            return { options: categories };
        })
        .catch((e) => {
            console.log(e);
        });
};

const Form = (props) => {
    const editor = useRef(null);
    const { handleSubmit, photo } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>New blog post</strong>
                    </h2>
                </div>

                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="picture">Cover</label>
                            <Field
                                photo={photo}
                                name="picture"
                                validateResolution={true}
                                component={renderImagePicker}
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="firstName">Title</label>
                            <Field
                                name="title"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                            <div className="d-flex flex-row">
                                <div className="pr-4">
                                    <label htmlFor="published" className="mt-3">Publish post</label>
                                    <Field name="published" component={renderSwitch} />
                                </div>
                                <div className="pl-4">
                                    <label htmlFor="scholarshipBlog" className="mt-3">Scholarship article</label>
                                    <Field name="scholarshipBlog" component={renderSwitch} />
                                </div>
                            </div>

                            <label htmlFor="category" className="mt-3">Category</label>
                            <Field
                                name='category'
                                valueKey="_id"
                                labelKey="name"
                                component={renderSearchCreateSelect}
                                label="Category"
                                loadOptions={getCategories}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="email">Content</label>
                            <Field
                                name="content"
                                component={Editor}
                                editor={editor}
                                className="form-control"
                            />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <label htmlFor="featured" >Featured post</label>
                        <Field name="featured" component={renderSwitch} />
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link
                                    className="btn btn-secondary m-1"
                                    to="/blog-posts"
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const BlogPost = reduxForm({
    form: "blogPost",
    validate: (data) => {
        return validate(data, {
            title: validators.exists()("Required field"),
            content: validators.exists()("Required field"),
            picture: image(data.photo)("Required field"),
            category: validators.exists()("Required field"),
        });
    },
    initialValues: {
        published: false,
        featured: false
    }
})(Form);

export default BlogPost;
