import React from "react";
import Select from "react-select";
import moment from "moment";
import Datetime from "react-datetime";
import "../../../../../style/datetime.css";
import { pendingTransferColumns } from "../../../utility/constants";

export default class ReservationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: undefined,
            startDate: undefined,
            endDate: undefined,
        };
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    componentDidMount() {
        const { startDate, endDate } = this.props;
        if (startDate && startDate.isValid()) this.setState({ startDate });
        else this.setState({ startDate: moment().startOf("month") });
        if (endDate && endDate.isValid()) this.setState({ endDate });
        else this.setState({ endDate: moment().endOf("month") });
    }

    handleSelectChange(value) {
        this.setState({ value });
    }

    render() {
        const { close, onSubmit } = this.props;
        const { value, startDate, endDate } = this.state;
        return (
            <div className="d-flex flex-column">
                <div className="mb-2">
                    <label>Start Date</label>
                    <Datetime
                        value={startDate}
                        onChange={(date) => {
                            this.setState({ startDate: date });
                        }}
                        dateFormat="MM/DD/YYYY"
                        timeFormat={false}
                    />
                </div>
                <div className="mb-2">
                    <label>End Date</label>
                    <Datetime
                        value={endDate}
                        onChange={(date) => {
                            this.setState({ endDate: date });
                        }}
                        dateFormat="MM/DD/YYYY"
                        timeFormat={false}
                    />
                </div>
                <div>Select the columns to download</div>
                <Select
                    multi
                    onChange={this.handleSelectChange}
                    options={pendingTransferColumns}
                    placeholder="Select the columns"
                    removeSelected={this.state.removeSelected}
                    simpleValue
                    value={value}
                />
                <div className="d-flex flex-row mt-4 justify-content-between">
                    <button
                        className="btn btn-secondar"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            onSubmit({
                                startDate,
                                endDate,
                                columns: value
                            });
                        }}
                    >
                        Download
                    </button>
                </div>
            </div>
        );
    }
}
