import React from "react";
import Form from "./ClientForm";

export default class Create extends React.Component {
    render() {
        const { create, userInfo: { permission } } = this.props;

        return (
            <div className="row d-flex justify-content-center">
                {permission && permission.find(({ createClients }) => !!createClients) && (
                    <div>
                        <Form onSubmit={create} />
                    </div>
                )}
            </div>
        );
    }
}
