import { createActions, handleActions } from "redux-actions";

// Actions
export const { setCustomLabel } = createActions({
    SET_CUSTOM_LABEL: (customLabelId, customLabel) => ({
        customLabelId,
        customLabel,
    }),
});

const updateCustomLabel = (id, label) => (dispatch) => {
    dispatch(setCustomLabel(id, label));
};

export const actions = {
    updateCustomLabel,
};

// Reducers
const reducers = {
    [setCustomLabel]: (state, { payload: { customLabelId, customLabel } }) => ({
        ...state,
        customLabelId,
        customLabel,
    }),
};

export const initialState = {
    customLabelId: null,
    customLabel: null,
};

export default handleActions(reducers, initialState);
