import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actions } from "../../../../redux/modules/locations";
import IconHandHoldingDollar from "../../../icons/hand-holding-dollar";
import IconRoute from "../../../icons/route";
import IconCoins from "../../../icons/coins";
import { getPathWithParams, settingsRatesLocationsIdPromotionalRatesPointToPoint, settingsRatesLocationsIdPromotionalRatesPromoCodes, settingsRatesLocationsIdPromotionalRatesSpecialRates } from "../../../../routesConfiguration/paths";

class DetailContainer extends React.Component {
    componentDidMount() {
        const { getLocationHeader, match } = this.props;
        getLocationHeader(match.params.id);
    }

    render() {
        const {
            userInfo: { permission },
            match: { params },
            header,
        } = this.props;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                {permission &&
                permission.find(
                    ({ readLocations, updateLocations }) =>
                        !!readLocations || !!updateLocations
                ) ? (
                    <div className="col-12 col-xl-10 d-flex flex-wrap justify-content-center">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            {header && (
                                <React.Fragment>
                                    <h1>
                                        Promotional Rates
                                        {` (${header.cityCode})`}
                                    </h1>
                                </React.Fragment>
                            )}
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link
                                to={getPathWithParams(
                                    settingsRatesLocationsIdPromotionalRatesSpecialRates,
                                    "id",
                                    params.id
                                )}
                                style={{ color: "#000" }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: "2.5rem" }}>
                                    <IconHandHoldingDollar className="h-100" />
                                </div>
                                <span className="mt-3">Special Rates</span>
                            </Link>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link
                                to={getPathWithParams(
                                    settingsRatesLocationsIdPromotionalRatesPointToPoint,
                                    "id",
                                    params.id
                                )}
                                style={{ color: "#000" }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: "2.5rem" }}>
                                    <IconRoute className="h-100" />
                                </div>
                                <span className="mt-3">Point-to-Point</span>
                            </Link>
                        </div>
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link
                                to={getPathWithParams(
                                    settingsRatesLocationsIdPromotionalRatesPromoCodes,
                                    "id",
                                    params.id
                                )}
                                style={{ color: "#000" }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: "2.5rem" }}>
                                    <IconCoins className="h-100" />
                                </div>
                                <span className="mt-3 text-center">
                                    Promo Codes
                                </span>
                            </Link>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.locations,
    userInfo: state.login.userInfo,
    header: state.locations.header,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(DetailContainer);
