import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";


const LOADING = "LOADING_CREW_ADMIN";
const LOADING_FORM = "LOADING_UPDATE_FORM";
const SET_CONTACTS_DATA = "SET_CONTACTS_DATA";
const SET_PAGE_CONTACTS = "SET_PAGE_CONTACTS";
const ITEM_CONTACTS = "ITEM_CONTACTS";
const CONTACTS_ADDRESS = "CONTACTS_ADDRESS";
const SET_SEARCH_CONTACTS = "SET_SEARCH_CONTACTS";

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $sort: { fullName: 1 },
            $limit: 5,
            $skip: (page - 1) * 5,
            // $collation: { locale: "en" }
        }};
        const search = getStore().crewAdmin.contacts.search;
        if(search){
            params.query.$or = [
                { fullName: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { phone: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { email: { $regex: _.escapeRegExp(search), $options: 'i'}},
            ]
        }

        const data = await app.service('/api/contactRoute').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (values) => async (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    let { page, address = {} } = getStore().crewAdmin.contacts;
    try {
        let data = Object.assign({}, values);
        if(address){
            const{ state, postal_code, city, } = address;
            Object.assign(data, { state, zip: postal_code, city, street: address.route && address.street_number ? `${address.street_number} ${address.route}` : undefined });
        }
        await app.service('/api/contactRoute').create(data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let data = await app.service('/api/contactRoute').get(id);
        if (!data.country) {
            data.country = "US";
        }
        await dispatch(initializeForm('contactsForm', data));
        dispatch(setItem({
            ...data,
            address: `${data.street ? `${data.street}, ` : ''}${data.city ? `${data.city}, ` : ''}${data.state ? data.state : ''} ${data.zip ? data.zip : ''}`
        }));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (values) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    let { page, address = {} } = getStore().crewAdmin.contacts;
    try{
        let data = Object.assign({}, values);
        if(address){
            const{ state, postal_code, city, } = address;
            Object.assign(data, { state, zip: postal_code, city, street: address.route && address.street_number ? `${address.street_number} ${address.route}` : undefined });
        }
        await app.service('/api/contactRoute').update(data._id, data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.contacts;
    try {
        await app.service('/api/contactRoute').remove(id);
        dispatch(find(page))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setDetails = (address) => dispatch => {
    dispatch({ type: CONTACTS_ADDRESS, address });
};

export const setSearch = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH_CONTACTS, search });
    dispatch(find());
}

export const clearData = () => dispatch => {
    dispatch(setDetails());
    dispatch(setItem({}));
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE_CONTACTS, page });
export const setData = (data) => ({ type: SET_CONTACTS_DATA, data });
export const setItem = (item) => ({ type: ITEM_CONTACTS, item });

export const actions = {
    find,
    create,
    clearData,
    setDetails,
    setSearch,
    load2Update,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_CONTACTS]: (state, { page }) => ({ ...state, page }),
    [SET_CONTACTS_DATA]: (state, { data }) => ({ ...state, data }),
    [ITEM_CONTACTS]: (state, { item }) => ({ ...state, item }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH_CONTACTS]: (state, { search }) => ({...state, search }),
    [CONTACTS_ADDRESS]: (state, { address }) => ({ ...state, address }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    search: undefined,
    address: undefined,
};

export default handleActions(reducers, initialState);
