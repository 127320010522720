import React from "react";
import { connect } from "react-redux";
import { actions } from "../../../redux/modules/arilineCrew";
import Modal from 'react-responsive-modal';
import FormTripId from './tripIdForm';
import LoadMask from '../Utils/LoadMask';
import FormFlights from './filghtsForm';
import DownloadTrackForm from './downloadForm';

class Container extends React.Component {

    state = {
        searchById: false,
        modalDownload: false,
    }

    componentDidMount(){
        if(
            (!this.props.airlineCodes || this.props.airlineCodes.length == 0) ||
            (!this.props.airportCodes || this.props.airportCodes.length == 0)
        )
            this.props.initializeDataAutocomplete();
    }

    openCloseModalDownload = (value) => {
        this.setState({ modalDownload: value });
    };

    render() {
        const { getFlights, loading, getFlightById, downloadTrackReport, loadingTrackReport, userInfo: { permission } } = this.props;
        const { modalDownload } = this.state;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                <div className="col-12 col-md-8 col-xl-6">
                    {permission.find(({ readAirlineCrew }) => readAirlineCrew === true) ? (
                        <React.Fragment>
                            <Modal
                                open={modalDownload}
                                onClose={() => this.openCloseModalDownload(false)}
                                styles={{
                                    modal: { minWidth: "35%" }
                                }}
                            >
                                <h4> Download Report </h4>
                                <hr />
                                <div className="p-4">
                                    <LoadMask loading={loadingTrackReport} blur>
                                        <DownloadTrackForm onSubmit={(data) => downloadTrackReport(data)} />
                                    </LoadMask>
                                </div>
                            </Modal>
                            <div className="my-3 d-flex">
                                <button
                                    type="button"
                                    className="btn btn-dark"
                                    onClick={() => this.openCloseModalDownload(true)}
                                >
                                    Issue Report
                                </button>
                            </div>
                        </React.Fragment>
                    ) : (
                        ""
                    )}
                    <LoadMask loading={loading} blur>
                        <h3> Search </h3>
                        <div className="d-flex justify-content-end mt-5">
                            <button type="button" onClick={()=>{this.setState({ searchById: !this.state.searchById })}}
                                className="btn btn-link p-0 text-primary text-decoration-underline"
                            >
                                {this.state.searchById ? 'Search Trip Info' : 'Search Livery ID'}
                            </button>
                        </div>
                        {this.state.searchById
                            ? <FormTripId
                                    {...this.props}
                                    onSubmit={getFlightById}
                                />
                            : <FormFlights
                                    {...this.props}
                                    onSubmit={getFlights}
                                />
                        }
                    </LoadMask>
                </div>
            </div>
        )
    }
}

const mstp = (state) => ({
    ...state.airlineCrew,
    userInfo: state.login.userInfo,
});

const mdtp = { ...actions };

export default connect(mstp, mdtp)(Container);
