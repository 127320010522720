import React from "react";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";
import { validate, validators, optional } from "validate-redux-form";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { RenderRange } from "../../Utils/renderField/renderReadField";
import { reservationsCharterReviewsService } from "../../../../routesConfiguration/paths";
import { renderSwitch, renderTextArea } from "../../Utils/renderField";

const MAX_CHARACTERS_COUNT = 200;

const Details = ({
    updateData,
    showTestimonialValue,
    testimonialLen,
    handleSubmit = () => {},
}) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column" style={{ color: "#707070" }}>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-3">
                        <label className="bold"> Review Type:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> Booker Service Review </label>
                    </div>
                    {updateData.confirmationNo ? (
                        <React.Fragment>
                            <div className="col-12 col-md-3">
                                <label className="bold">
                                    {" "}
                                    Trip Number:&nbsp;
                                </label>
                            </div>
                            <div className="col-12 col-md-7">
                                <label> {updateData.confirmationNo} </label>
                            </div>
                        </React.Fragment>
                    ) : (
                        ""
                    )}
                    <div className="my-5" />
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Name:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label>
                            {" "}
                            {updateData.firstName
                                ? updateData.firstName
                                : ""}{" "}
                            {updateData.lastName ? updateData.lastName : ""}{" "}
                        </label>
                    </div>
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Email:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.email} </label>
                    </div>
                </div>
                <div
                    className={classNames("d-flex", {
                        "my-5": !updateData.testimonial,
                        "mt-5 my-2": updateData.testimonial,
                    })}
                >
                    <hr className="flex-1" style={{ borderWidth: "2px" }} />
                    <h4 className="bold">&nbsp;REVIEW&nbsp;</h4>
                    <hr className="flex-1" style={{ borderWidth: "2px" }} />
                </div>
                {updateData.testimonial ? (
                    <div className="d-flex align-items-center justify-content-end mb-5">
                        <div className="d-flex justify-content-between">
                            <Field
                                name="showTestimonial"
                                component={renderSwitch}
                                height={20}
                                width={45}
                            />
                            <label
                                className="ml-2 bold"
                                htmlFor="showTestimonial"
                            >
                                Testimonial
                            </label>
                        </div>
                    </div>
                ) : null}
                <div className="d-flex flex-wrap justify-content-center">
                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div>
                                {" "}
                                <strong>
                                    {" "}
                                    How satisfied were you with your experience
                                    with CONNECT?.&nbsp;
                                </strong>{" "}
                            </div>
                            <div className="py-2">
                                <RenderRange
                                    value={updateData.experience}
                                    size={5}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.experience}</strong>
                        </div>
                    </div>
                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div>
                                {" "}
                                <strong>
                                    {" "}
                                    Feel free to add additional feedback
                                    here&nbsp;
                                </strong>{" "}
                            </div>
                            <label>{updateData.feedBackExperience}</label>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: (below question)</strong>
                        </div>
                    </div>
                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div>
                                {" "}
                                <strong>
                                    {" "}
                                    How likely are you to recommend CONNECT to a
                                    friend or colleague?&nbsp;
                                </strong>{" "}
                            </div>
                            <div className="py-2">
                                <RenderRange
                                    value={updateData.probabilityToRecomend}
                                    size={10}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <strong>
                                ANSWER: {updateData.probabilityToRecomend}{" "}
                            </strong>
                        </div>
                    </div>
                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div>
                                {" "}
                                <strong>
                                    {" "}
                                    What is the most important reason for your
                                    score?&nbsp;
                                </strong>{" "}
                            </div>
                            <label>{updateData.reasonScore}</label>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: (below question)</strong>
                        </div>
                    </div>
                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div>
                                {" "}
                                <strong>
                                    {" "}
                                    Any additional feedback?&nbsp;
                                </strong>{" "}
                            </div>
                            <label>{updateData.additionalFeedback}</label>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: (below question)</strong>
                        </div>
                    </div>
                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div>
                                {" "}
                                <strong>
                                    {" "}
                                    May we contact you to follow up on these
                                    responses?&nbsp;
                                </strong>{" "}
                            </div>
                            <label>
                                {updateData.forContact ? "YES" : "NO"}
                            </label>
                        </div>
                        <div className="col-4">
                            <strong>
                                ANSWER: {updateData.forContact ? "YES" : "NO"}
                            </strong>
                        </div>
                    </div>
                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div>
                                {" "}
                                <strong>
                                    {" "}
                                    We may want to highlight your feedback as a
                                    testimonial to our services on our website.
                                    Will you allow us to include your basic
                                    information with your responses? This may
                                    include your first name, company name, and
                                    job title?&nbsp;
                                </strong>{" "}
                            </div>
                            <label>
                                {updateData.testimonial ? "YES" : "NO"}
                            </label>
                        </div>
                        <div className="col-4">
                            <strong>
                                ANSWER: {updateData.testimonial ? "YES" : "NO"}
                            </strong>
                        </div>
                    </div>
                    {updateData.testimonial && showTestimonialValue ? (
                        <div className="col-12 px-0 mb-5 d-flex flex-column">
                            <div className="col-8">
                                <label htmlFor="testimonialText">
                                    <strong>Testimonial</strong>
                                    <p>
                                        {testimonialLen}/{MAX_CHARACTERS_COUNT}
                                    </p>
                                </label>
                                <Field
                                    className="w-100"
                                    name="testimonialText"
                                    component={renderTextArea}
                                    label="Testimonial"
                                    type="text"
                                    rows={5}
                                    maxLength={MAX_CHARACTERS_COUNT}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="d-flex justify-content-end">
                    <Link
                        to={reservationsCharterReviewsService}
                        className="btn btn-secondary btn-lg"
                    >
                        Back
                    </Link>
                    {updateData.testimonial ? (
                        <button
                            type="submit"
                            className="btn btn-primary btn-lg mx-2"
                        >
                            Save
                        </button>
                    ) : null}
                </div>
            </div>
        </form>
    );
};

const DetailsForm = reduxForm({
    form: "detailServiceReviewForm",
    validate: (data) => {
        return validate(data, {
            testimonialText: optional(
                validators.length({
                    max: MAX_CHARACTERS_COUNT,
                })(`Max characters: ${MAX_CHARACTERS_COUNT}`)
            ),
        });
    },
})(Details);

const selector = formValueSelector("detailServiceReviewForm");
const Form = connect((state) => {
    const showTestimonialValue = selector(state, "showTestimonial");
    const testimonialTextValue = selector(state, "testimonialText");
    const testimonialLen = testimonialTextValue
        ? testimonialTextValue.length
        : 0;
    return { showTestimonialValue, testimonialLen };
})(DetailsForm);

export default Form;
