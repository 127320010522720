import React from 'react';
import numeral from 'numeral';
import { TabContent, TabPane } from 'reactstrap';

import Spinner from '../Spinner';

const { MEDIA_URL } = process.env;

const VehicleList = ({
    loader,
    vehicles,
    fromAirport,
    formType,
    handleSelect,
    activeVehicle,
}) => (
    <div className="mx-4">
        <TabContent activeTab={activeVehicle}>
            <TabPane key={0} tabId={'1'}>
                {!loader && vehicles &&
                    vehicles.length > 0 &&
                    vehicles[0].map(({ _id, vehicle, vehicleLocation, vehicleSpecialRate, info }) => (
                        <div key={_id}
                            className="mt-5 mx-1 bg-light d-flex flex-column flex-lg-row flex-wrap radius-1 vehicle-card border border-dark"
                        >
                            <div className="flex-2 d-flex flex-column flex-sm-row flex-wrap justify-content-between">
                                <div className="d-flex d-sm-inline justify-content-center flex-2 vehicle-card-image">
                                    <img className="img-fluid" alt="img"
                                        src={`${MEDIA_URL}${info.photo}`}
                                    />
                                </div>
                                <div className="d-flex d-sm-inline flex-column flex-2 p-2 vehicle-card-features">
                                    <h2 className="vehicle-card-title">{info.type}</h2>
                                    <p className="description"><i>{info.description}</i></p>
                                    <div className="vehicle-passenger font-normal">
                                        <div className="d-flex passengerIcon align-items-end">
                                            <img
                                            className="mr-1"
                                            style={{ height: '20px' }}
                                            src={require(`../../../../../../../assets/img/passengers.svg`)}
                                            />
                                            <span style={{fontSize: '12px'}}>{`1-${info.capacity} passengers`}</span>
                                        </div>
                                        <div className="d-flex luggageIcon align-items-end">
                                            <img
                                            className="mr-1"
                                            style={{ height: '20px' }}
                                            src={require(`../../../../../../../assets/img/luggage.svg`)}
                                            />
                                            <span style={{fontSize: '12px'}}>{`1-${info.luggageSpace ? info.luggageSpace : ''} luggage`}</span>
                                        </div>
                                    </div>
                                    <hr className="line w-100"/>
                                    <div>
                                        {!!info && !!info.features && info.features.map((item, i)=>{
                                            const renderItem = (item, i) => <div className="vehicle-card-item" key={i}>
                                                <img src={require(`../../../../../../../assets/img/svg/${item.icon}`)}/>
                                                <span> {item.description} </span>
                                            </div>

                                            return typeof item.featureType === 'string'
                                                ? fromAirport
                                                    ? item.featureType == 'Airport' && renderItem(item, i)
                                                    : formType.formType == 'transfer'
                                                        ? item.featureType == 'Standard' && renderItem(item, i)
                                                        : item.featureType == 'Hourly' && renderItem(item, i)
                                                : fromAirport
                                                    ? item.featureType.includes('Airport') && renderItem(item, i)
                                                    : formType.formType == 'transfer'
                                                        ? item.featureType.includes('Standard') && renderItem(item, i)
                                                        : item.featureType.includes('Hourly') && renderItem(item, i)
                                        })}
                                    </div>

                                </div>
                            </div>
                            <div className="flex-1 d-flex flex-column p-3 p-md-0">
                                <h2 className="ml-md-auto mt-md-3 price">
                                    $ {numeral(info.price).format('0,0.00')}{' '}
                                    USD
                                </h2>
                                <p className="fit-content text-secondary ml-md-auto text-footer price-includes font-normal">
                                    All prices include VAT, fees &amp; tip.
                                </p>
                                <button
                                    className="btn btn-primary px-1 py-3 mt-2 mt-md-5 mx-auto ml-xl-auto mr-xl-0 w-75"
                                    style={{backgroundColor: '#DA291C !important'}}
                                    onClick={() => {
                                        handleSelect({
                                            ...info,
                                            vehicle_id: vehicle,
                                            vehicleLocation_id: vehicleLocation,
                                            vehicleSpecialRate_id: vehicleSpecialRate,
                                            _id
                                        });
                                        window.scrollTo(0, 0);
                                    }}
                                >
                                    <b>SELECT</b>
                                </button>
                            </div>
                        </div>
                    ))}
                {loader && (
                    <div className="d-flex flex-column flex-lg-row flex-wrap justify-content-center mt-5 p-3 bg-light radius-1">
                        <Spinner
                            styles={{
                                color: '#C3CBD3',
                                background: 'white',
                                margin: 0,
                            }}
                        />
                    </div>
                )}
            </TabPane>
        </TabContent>
    </div>
);

export default VehicleList;
