import React from "react";
import Chart from "react-google-charts";

const AnnualChart = ({ annualData }) => (
    <Chart
        width="100%"
        height="350px"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={annualData}
        options={{
            title: "Current and previous year",
            chartArea: { width: "80%" },
            colors: ["#ff3541", "#737f8c"],
            hAxis: {
                title: "Months",
                minValue: 0,
            },
            vAxis: {
                title: "Money",
                format: "currency",
            },
            tooltip: { isHtml: true, trigger: "visible" },
        }}
    />
);

export default AnnualChart;
