import moment from 'moment'
import { getExampleNumber } from 'libphonenumber-js';
import examplesMobile from 'libphonenumber-js/examples.mobile.json';

const hour = (h, m) => {
  let time = ' (';

  const hours = {
    '0': '12',
    '13': '1',
    '14': '2',
    '15': '3',
    '16': '4',
    '17': '5',
    '18': '6',
    '19': '7',
    '20': '8',
    '21': '9',
    '22': '10',
    '23': '11',
  };

  (h.length < 2)
    ? time += '0' + h
    : time += h;

  time += ':' + m + ') ';

  (h in hours)
    ? time += hours[h]
    : time += h;

  time += ':' + m;

  (h > 11)
    ? time += ' PM'
    : time += ' AM'

  time += ' EST'

  return time
}

const setTime = (date, hour, minutes) => {
  const m = moment(date);
  m.hour(hour);
  m.minute(minutes);
  return m.format("YYYY-MM-DDTHH:mm:ss").toString()
};

const formatPhone = (phone, country) => {
  let phoneTemplate, phonePlaceholder;
  if (country) {
    const phoneNumber = getExampleNumber(country, examplesMobile);
    phonePlaceholder = phoneNumber.formatNational();
    phoneTemplate = `${phonePlaceholder}`.replace(/\d/g, '#');
    let regex = '';
    for(let i = 1; phoneTemplate.indexOf('#') >= 0; ++i){
      phoneTemplate = phoneTemplate.replace('#', '$'+i);
      regex += '(\\d)';
    }
    //regex += '[^]*'; // Match the rest of the string to crop characters overflowing the format.
    return phone.replace(new RegExp(regex), phoneTemplate);
  }
  return phone;
}

export default {
  hour,
  setTime,
  formatPhone,
}
