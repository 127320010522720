import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { validate, validators } from "validate-redux-form";
import {
    renderField,
    renderSelectField,
    renderImagePicker,
} from "Utils/renderField/renderField";
import { renderCurrency, renderSwitch, renderNumber } from "../../Utils/renderField";
import { settingsRatesVehicles } from "../../../../routesConfiguration/paths";
const { BACKEND_URL } = process.env;


let Form = (props) => {
    const { handleSubmit, photo, perAmount, pointValue } = props;

    const positiveInt = (value) =>
        value && value < 1 ? "Must be at least 1" : undefined;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>Vehicle</strong>
                    </h2>
                </div>

                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="type">Type</label>
                            <Field
                                name="type"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="description">Description</label>
                            <Field
                                name="description"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="vehicleCode">Vehicle Code</label>
                            <Field
                                name="vehicleCode"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="capacity">Capacity</label>
                            <Field
                                name="capacity"
                                component={renderField}
                                type="number"
                                className="form-control"
                                validate={positiveInt}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="minimumHours">Minimum Hours</label>
                            <Field
                                name="minimumHours"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="minimumTransfer">
                                Minimum Transfer
                            </label>
                            <Field
                                name="minimumTransfer"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="standardDwell">
                                Standard Dwell
                            </label>
                            <Field
                                name="standardDwell"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="standardBlock">
                                Standard Block
                            </label>
                            <Field
                                name="standardBlock"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="reductionRate1">
                                Reduction Rate 1
                            </label>
                            <Field
                                name="reductionRate1"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="reductionRate2">
                                Reduction Rate 2
                            </label>
                            <Field
                                name="reductionRate2"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="reductionRate3">
                                Reduction Rate 3
                            </label>
                            <Field
                                name="reductionRate3"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="reductionRate4">
                                Reduction Rate 4
                            </label>
                            <Field
                                name="reductionRate4"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="class">Luggage Space</label>
                            <Field
                                name="luggageSpace"
                                component={renderField}
                                type="number"
                                className="form-control"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="class">Class</label>
                            <Field
                                name="class"
                                component={renderSelectField}
                                type="select"
                                labelKey="label"
                                valueKey="value"
                                options={[
                                    { value: "PREMIERE", label: "PREMIERE" },
                                    { value: "BUSINESS", label: "BUSINESS" },
                                    { value: "EXPRESS", label: "EXPRESS" },
                                ]}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                            <label htmlFor="defaultRate">Rate</label>
                            <Field
                                name="defaultRate"
                                component={renderField}
                                type="number"
                                placeholder="Rate"
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                            <label htmlFor="isHourlyVehicle" className="mt-3">Hourly vehicle?</label>
                            <Field name="isHourlyVehicle" component={renderSwitch} />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                            <label htmlFor="picture">Photo</label>
                            <Field
                                photo={photo}
                                useMediaUrl={false}
                                name="picture"
                                component={renderImagePicker}
                            />
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                            <h3 className="mt-3">
                                <strong>Reward points</strong>
                            </h3>
                            <div className="form-group">
                                <label> Per Amount </label>
                                <div className="d-flex flex-row align-items-center">
                                    <span>$1. =&nbsp;</span>
                                    <div style={{ width: "40%" }}>
                                        <Field
                                            name="points.perAmount"
                                            component={renderNumber}
                                            decimalScale={2}
                                        />
                                    </div>
                                    <span> &nbsp;{!!perAmount ? parseInt(perAmount) === 1 ? 'pt.' : 'pts.' : ''} </span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label> Point Value </label>
                                <div className="d-flex flex-row align-items-center">
                                    <span>1pt. =&nbsp;</span>
                                    <div style={{ width: "40%" }}>
                                        <Field
                                            name="points.pointValue"
                                            component={renderNumber}
                                            decimalScale={4}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="d-flex align-items-center mt-2">
                                    <span> Earning Rate:&nbsp;{perAmount && pointValue && `${(((perAmount * pointValue)/1) * 100).toFixed(2)} %`}</span>
                                </div>
                            </div>
                        </div>



                        <h3 className="col-12 mt-3">
                            <strong>Add ons</strong>
                        </h3>

                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Meet & greet</strong>
                            </label>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Applies</label>
                                    <Field
                                        name="meetGreet"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Airport exclusive</label>
                                    <Field
                                        name="meetGreetAirport"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Cost</label>
                                    <Field
                                        name="meetGreetCost"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="">Description</label>
                                <Field
                                    name="meetGreetDescription"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Extra Stop</strong>
                            </label>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Applies</label>
                                    <Field
                                        name="extraStop"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Airport exclusive</label>
                                    <Field
                                        name="extraStopAirport"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Cost</label>
                                    <Field
                                        name="extraStopCost"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="">Description</label>
                                <Field
                                    name="extraStopDescription"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>WiFi</strong>
                            </label>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Applies</label>
                                    <Field
                                        name="wifi"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Airport exclusive</label>
                                    <Field
                                        name="wifiAirport"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Cost</label>
                                    <Field
                                        name="wifiCost"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="">Description</label>
                                <Field
                                    name="wifiDescription"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Audio/Video</strong>
                            </label>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Applies</label>
                                    <Field
                                        name="audioVideo"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Airport exclusive</label>
                                    <Field
                                        name="audioVideoAirport"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Cost</label>
                                    <Field
                                        name="audioVideoCost"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="">Description</label>
                                <Field
                                    name="audioVideoDescription"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Public Address (PA) System</strong>
                            </label>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Applies</label>
                                    <Field
                                        name="publicAddress"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Airport exclusive</label>
                                    <Field
                                        name="publicAddressAirport"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Cost</label>
                                    <Field
                                        name="publicAddressCost"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="">Description</label>
                                <Field
                                    name="publicAddressDescription"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Restroom</strong>
                            </label>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Applies</label>
                                    <Field
                                        name="restroom"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Airport exclusive</label>
                                    <Field
                                        name="restroomAirport"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Cost</label>
                                    <Field
                                        name="restroomCost"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="">Description</label>
                                <Field
                                    name="restroomDescription"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="meet_greet">
                                <strong>Coordinator</strong>
                            </label>
                            <div className="d-flex flex-row justify-content-between align-items-end">
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Applies</label>
                                    <Field
                                        name="coordinator"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Airport exclusive</label>
                                    <Field
                                        name="coordinatorAirport"
                                        component={renderSwitch}
                                    />
                                </div>
                                <div className="d-flex flex-column">
                                    <label htmlFor="">Cost</label>
                                    <Field
                                        name="coordinatorCost"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column">
                                <label htmlFor="">Description</label>
                                <Field
                                    name="coordinatorDescription"
                                    component={renderField}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <Link
                                    className="btn btn-secondary m-1"
                                    to={settingsRatesVehicles}
                                >
                                    Cancel
                                </Link>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const selector = formValueSelector('vehicle');
Form = connect(state => {
    const perAmount = selector(state, 'points.perAmount');
    const pointValue = selector(state, 'points.pointValue');
    return { perAmount, pointValue }
})(Form);

const VehicleForm = reduxForm({
    form: "vehicle",
    validate: (data) => {
        return validate(data, {
            type: validators.exists()("Required field"),
            description: validators.exists()("Required field"),
            vehicleCode: validators.exists()("Required field"),
            reductionRate1: validators.exists()("Required field"),
            reductionRate2: validators.exists()("Required field"),
            reductionRate3: validators.exists()("Required field"),
            reductionRate4: validators.exists()("Required field"),
            class: validators.exists()("Required field"),
            picture: validators.exists()("Required field"),
            luggageSpace: validators.exists()("Required field"),
            points: {
                perAmount: validators.exists()('Required field'),
                pointValue: validators.exists()('Required field'),
            },
        });
    },
})(Form);

// In update form, picture is not a required field
export const VehicleUpdateForm = reduxForm({
    form: "vehicle",
    validate: (data) => {
        return validate(data, {
            type: validators.exists()("Required field"),
            description: validators.exists()("Required field"),
            vehicleCode: validators.exists()("Required field"),
            reductionRate1: validators.exists()("Required field"),
            reductionRate2: validators.exists()("Required field"),
            reductionRate3: validators.exists()("Required field"),
            reductionRate4: validators.exists()("Required field"),
            luggageSpace: validators.exists()("Required field"),
            class: validators.exists()("Required field"),
            points: {
                perAmount: validators.exists()('Required field'),
                pointValue: validators.exists()('Required field'),
            },
        });
    },
})(Form);

export default VehicleForm;
