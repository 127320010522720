import React from "react";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderTextArea, renderNumber, renderCurrency } from "Utils//renderField/renderField";


let Form = (props) => {
    const { handleSubmit, onCancel } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2 className="mb-3">
                        <strong>Update Points</strong>
                    </h2>

                    <div className="padding-15 p-sm-0 py-sm-1">
                        <div className="row">
                            <div className="form-group col-sm-12 col-md-6">
                                <label>Total Points</label>
                                <Field
                                    name="points.totalPoints"
                                    component={renderNumber}
                                    decimalScale={2}
                                />
                            </div>

                            <div className="form-group col-sm-12 col-md-6">
                                <label>Total Earned</label>
                                <Field
                                    name="points.totalEarned"
                                    component={renderCurrency}
                                    className="form-control"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col-12">
                                <label htmlFor="reason">Reason</label>
                                <Field
                                    name="reason"
                                    component={renderTextArea}
                                    cols={3}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                    <button type="button"
                                        onClick={onCancel}
                                        className="btn btn-secondary m-1"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary m-1"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

Form = reduxForm({
    form: "pointsForm",
    validate: (data) => {
        return validate(data, {
            firstName: validators.exists()("Required field"),
            lastName: validators.exists()("Required field"),
        });
    },
})(Form);

export default Form;
