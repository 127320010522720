import { handleActions } from "redux-actions";
import Swal from "sweetalert2";
import moment from "moment";
import _, { cloneDeep } from "lodash";

import app from "../../libs/apiClient";
import swal from "sweetalert2";
import { actions as breadcrumbActions } from "./breadcrumb";

// Constants
const service = app.service("/api/reservation/");
const serviceRefund = app.service("/api/refund/");

const DATA = "LEGACY_REFUND_DATA";
const LOADER = "LEGACY_REFUND_LOADER";
const QUERY = "LEGACY_REFUND_QUERY";
const LOADER_MODAL = "LEGACY_LOADER_MODAL_REFUND";

// Pure Actions
const setData = (data) => ({
    type: DATA,
    data,
});

export const setQuery = (query) => ({
    type: QUERY,
    query,
});

const setLoader = (loading) => ({
    type: LOADER,
    loading,
});

const setLoaderModal = (loaderModalRefund) => ({
    type: LOADER_MODAL,
    loaderModalRefund
})
// Actions
export const find = (showNotification, id) => (dispatch, getState) => {
    dispatch(setLoader(true));
    showNotification({},false);
    service.get(id).then((data)=>{
        let total = 0;
        if(data.refund)
            total = data.refund.reduce((total, item) => total + item.refundAmount, 0);
        data.totalRefund = total;
        dispatch(setData(data));
        const { updateCustomLabel } = breadcrumbActions;
        dispatch(updateCustomLabel(id, data.confirmationNo));
    })
    .catch((error)=>{
        let message = ""
        if(typeof(error) === "object")
            if(error.message)
                message = error.message;
            else
                for(let i = 0; i < error.length ; i++)
                    message = message + error[i];
        else message = error;
        if(!message.length)
            message = "there was an error getting the data"
        Swal.fire({
            title: "Error",
            text: `${message}`,
            icon: "error",
            type: "error",
            confirmButton: "OK",
            confirmButtonColor: "#D50032",
        });
    }).finally(()=>{
        dispatch(setLoader(false));
    })
};

export const postRefund = (closeModal, showNotification, isCancel) => (dispatch, getStore) =>{
    dispatch(setLoaderModal(true));
    const { values = {} } = getStore().form.refundMountForm || {};
    const data = cloneDeep(getStore().legacyRefund.data);
    let refund = data.refund

    let new_data = {
        reservation: data._id,
        refundAmount: values.amount,
        agentNotes: values.agent_notes
    }

    if(refund) refund.unshift(new_data)
    else refund = [new_data]

    const params = {
        action: `${isCancel ? 'CANCEL_AND_REFUND' : 'REFUND_TRANSACTION'}`,
        refund: refund,
    }

    service
        .patch(data._id, params)
        .then((response) => {
            dispatch(setData(response))
            Swal.fire({
                title: "Success",
                text: `The amount has been refunded`,
                type: "success",
                confirmButton: "OK",
                confirmButtonColor: "#D50032",
            }).then((result) => {
                dispatch(find(showNotification, data._id));
                closeModal()
                showNotification(response, true)
            })
        })
        .catch((err) => {
            console.log("error on find", err);
            Swal.fire({
                title: "Error",
                text: `${err}`,
                icon: "error",
                type: "error",
                confirmButton: "OK",
                confirmButtonColor: "#D50032",
            });
        })
        .finally(()=>{
            dispatch(setLoaderModal(false))
        })
};

const onlyCancelTrip = () => (dispatch, getStore) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#D50032',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if(result.value){
            dispatch(setLoader(true));
            const { data } = cloneDeep(getStore().legacyRefund);
            let { refund } = data;

            let new_data = {
                reservation: data._id,
                refundAmount: (data.transactionAmount - data.totalRefund),
                agentNotes: "Void transaction and trip cancelled"
            }

            if(refund) refund.unshift(new_data)
            else refund = [new_data]

            const params = {
                action: 'CANCEL_AND_REFUND',
                refund
            }
            service.patch(data._id, params).then((response) => {
                dispatch(setData(response))
                Swal.fire({
                    title: "Success",
                    text: `Trip cancelled!`,
                    type: "success",
                    confirmButton: "OK",
                    confirmButtonColor: "#D50032",
                }).then((result) => {
                    dispatch(find(()=>{}, data._id));
                })
            }).catch((err) => {
                console.log("error on cancel trip", err);
                Swal.fire({
                    type: "error",
                    title: "Error",
                    text: `${err}`,
                    confirmButton: "OK",
                    confirmButtonColor: "#D50032",
                });
            }).finally(()=>{
                dispatch(setLoader(false));
            })
        }
    })
}

const clear = () => (dispatch, getStore) => {
    dispatch(setData({}));
}

export const actions = {
    find,
    clear,
    postRefund,
    onlyCancelTrip,
};

// Reducers
const reducers = {
    [LOADER]: (state, { loading }) => ({ ...state, loading }),
    [QUERY]: (state, { query }) => ({ ...state, query }),
    [DATA]: (state, { data }) => ({ ...state, data }),
    [LOADER_MODAL]: (state, { loaderModalRefund }) => ({ ...state, loaderModalRefund}),
};

export const initialState = {
    data: { },
    page: 1,
    query: {},
    loading: false,
    loaderModalRefund: false,
};

export default handleActions(reducers, initialState);
