import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/reservations';
import { actions as tripEditionActions } from '../../../../redux/modules/trip-edition';
import { actions as tripCancelActions } from '../../../../redux/modules/refund';
import LoadMask from '../../Utils/LoadMask';
import CartDetails from './details';


class Container extends React.Component{

    componentDidMount(){
        this.props.getCart(this.props.match.params.id);
    }

    render(){
        return (
            <LoadMask loading={this.props.loadingCart} blur>
                <CartDetails
                    {...this.props}
                />
            </LoadMask>
        )
    }
}

const mstp = state => ({
    ...state.reservations,
    loadingEdit: state.tripEdition.loading,
    loadingCancel: state.refund.loaderModalRefund,
})

const mdtp = {
    ...actions,
    editReservation: tripEditionActions.editReservation,
    postRefund: tripCancelActions.postRefund,
    voidCart: tripCancelActions.voidCart,
};

export default connect(mstp, mdtp)(Container);
