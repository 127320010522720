import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";

const LOADING = "LOADING_CREW_ADMIN_CONTACT_ROLES";
const LOADING_FORM = "LOADING_UPDATE_FORM_CONTACT_ROLES";
const SET_CONTACTS_DATA = "SET_CONTACTS_DATA_CONTACT_ROLES";
const SET_PAGE_CONTACTS = "SET_PAGE_CONTACTS_CONTACT_ROLES";
const ITEM_CONTACTS = "ITEM_CONTACTS_CONTACT_ROLES";
const SET_SEARCH_CONTACTS = "SET_SEARCH_CONTACTS_CONTACT_ROLES";

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE_CONTACTS, page });
export const setData = (data) => ({ type: SET_CONTACTS_DATA, data });
export const setItem = (item) => ({ type: ITEM_CONTACTS, item });

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        const params = {
            query: {
                $sort: { name: 1 },
                $limit: 10,
                $skip: (page - 1) * 10,
            },
        };
        const { search } = getStore().crewAdmin.contactRoles;
        if (search) {
            params.query.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: "i" } },
            ];
        }

        const data = await app.service("/api/contact_role").find(params);
        dispatch(setPage(page));
        dispatch(setData(data));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const create = (data) => async (dispatch, getStore) => {
    let result = false;
    dispatch(setLoadingForm(true));
    const { page } = getStore().crewAdmin.contactRoles;
    try {
        await app.service("/api/contact_role").create(data);
        result = true;
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
    return result;
};

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true));
    try {
        const data = await app.service("/api/contact_role").get(id);
        await dispatch(initializeForm("contactRolesForm", data));
        dispatch(setItem(data));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
};

export const update = (data) => async (dispatch, getStore) => {
    let result = false;
    dispatch(setLoadingForm(true));
    const { page } = getStore().crewAdmin.contactRoles;
    try {
        await app.service("/api/contact_role").update(data._id, data);
        result = true;
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
    return result;
};

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    const { page } = getStore().crewAdmin.contactRoles;
    try {
        await app.service("/api/contact_role").remove(id);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setSearch = (search) => (dispatch) => {
    dispatch({ type: SET_SEARCH_CONTACTS, search });
    dispatch(find());
};

export const clearData = () => (dispatch) => {
    dispatch(setItem({}));
};

export const actions = {
    find,
    create,
    clearData,
    setSearch,
    load2Update,
    update,
    destroy,
};

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_CONTACTS]: (state, { page }) => ({ ...state, page }),
    [SET_CONTACTS_DATA]: (state, { data }) => ({ ...state, data }),
    [ITEM_CONTACTS]: (state, { item }) => ({ ...state, item }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH_CONTACTS]: (state, { search }) => ({...state, search }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    search: undefined,
};

export default handleActions(reducers, initialState);
