import React from 'react';
import numeral from 'numeral';

const Total = ({ price }) => (
    <div className="d-flex flex-column flex-md-row justify-content-between">
        <div className="flex-1 d-flex flex-row">
        </div>
        <div className="flex-1 d-flex flex-column">
            <div className="d-flex flex-row">
                <div className="flex-2 mx-2 my-1 text-primary">
                    <h3 className="text-right">TOTAL:</h3>
                </div>
                <div className="flex-1 d-flex align-items-end mx-2 my-1 text-primary text-right justify-content-end">
                    <h3>
                        {`${ numeral(price || 0).format('0,0.00')}`}
                    </h3>
                </div>
            </div>
            <div className="mr-1 text-secondary text-right"><small>(Includes VAT, fees & tip)</small></div>
        </div>
    </div>
);

export default Total;
