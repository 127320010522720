import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { renderField, renderSearchSelect } from '../../../../../Utils/renderField';
import IconHistory from '../../../../../../icons/history';
import ExternalLink from '../../../../../../icons/externalLink';
import app from '../../../../../../../libs/apiClient';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { settingsAirlinesContacts } from '../../../../../../../routesConfiguration/paths';


const FormAirlineContacts = (props) => {

    const loadContacts = (search) => {
        const params = {}
        if(search){
            params.$or = [
                { fullName: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { phone: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { email: { $regex: _.escapeRegExp(search), $options: 'i'}},
            ]
        }
        return app.service('/api/contactRoute').find({ query: params }).then(result => {
            return { options: result.data };
        }).catch((error)=> {
            console.log(error);
            return { options: [] };
        })
    }

    const loadContactRoles = (search) => {
        const params = { };
        if(search) {
            params.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: 'i' }},
            ]
        }
        return app.service('/api/contact_role').find({ query: params }).then(result => {
            return { options: result.data };
        }).catch((error) => {
            console.log(error);
            return { options: [] }
        })
    }


    const { handleSubmit } = props;

    return (
        <form onSubmit={(event)=>{event.stopPropagation(); event.preventDefault(); handleSubmit()}} id="airlineContactsForm">
            <div className="d-flex justify-content-end">
                <Link to={settingsAirlinesContacts} target="_blank" className="d-flex align-items-center clickable text-primary">
                    <span className="text-decoration-underline d-none d-md-inline"> Airline Contacts </span>
                    <div style={{ width: '15px', height: '13px' }} className="d-flex pl-1">
                        <ExternalLink className="w-100 h-100" />
                    </div>
                </Link>
            </div>
            <div className="form-group">
                <Field
                    name="contact"
                    placeholder="Select contact..."
                    component={renderSearchSelect}
                    loadOptions={loadContacts}
                    valueKey="_id"
                    labelKey="fullName"
                    allKey
                />
            </div>
            <div className="form-group">
                <Field
                    name="contact_type"
                    placeholder="Select role..."
                    component={renderSearchSelect}
                    type="text"
                    loadOptions={loadContactRoles}
                    valueKey="_id"
                    labelKey="name"
                    allKey
                />
            </div>
            <div className="d-flex justify-content-end">
                <div className="">
                    <button type="submit" form="airlineContactsForm" className="btn btn-primary">
                        SAVE
                    </button>
                </div>
            </div>
        </form>
    )
}

export default reduxForm({
    form: 'airlineContactsForm',
    validate: (data) => {
        const errors = {};
        if(!data.contact) errors.contact = "Required field";
        if(!data.contact_type) errors.contact_type = "Required field";
        return errors;
    }
})(FormAirlineContacts);
