import React, { useEffect, useState } from 'react';
import { actions } from '../../../../redux/modules/crewAdmin/contactRoles';
import { connect } from 'react-redux';
import Loader from "react-loader-spinner";
import IconPen from '../../../icons/pen';
import IconPlus from '../../../icons/plus';
import ContactRolesForm from './contactRoleForm';
import LoadMask from '../../Utils/LoadMask';
import { IconTrashAlt } from '../../../icons/trash';
import swal from 'sweetalert2';


const ContactRoles = props => {

    const [showForm, setShowForm] = useState(false);
    const [edit, setEdit] = useState(undefined);

    useEffect(() => {
        props.find();
    }, []);

    const onCreate = (data) => {
        props.create(data).then((valid) => {
            if (valid) setShowForm(false);
        });
    };

    const removeContactRole = (id) => {
        swal.fire({
            type: "warning",
            html:
                "<h3> Are you sure you want to delete this contact role? </h3>",
            showCancelButton: true,
            confirmButtonColor: "#D50032",
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE",
        }).then(({ value }) => {
            if (value) {
                const { destroy } = props;
                destroy(id);
            }
        });
    };

    const onClickEdit = (item, index) => {
        setEdit({ item, index });
        setShowForm(true);
    };

    const cancelEdit = () => {
        setEdit();
        setShowForm(false);
    };

    const onSubmitEdit = (values) => {
        props.update({ ...edit.item, ...values }).then((valid) => {
            if (valid) cancelEdit();
        });
    };

    const { loading, data, loadingForm, userInfo: { permission } } = props;

    return (
        <div className="">
            <div className="col-12 d-flex justify-content-between align-items-center pt-4 mb-2">
                <h3 className="mb-0 text-left"> Contact Roles </h3>
                {data && data.total > 0 && permission && permission.find(({ createAirlineCrew }) => createAirlineCrew == true) ? (
                    <div className="">
                        <button
                            type="button"
                            className="btn btn-light border"
                            onClick={() => setShowForm(true)}
                        >
                            <IconPlus className="smallIcon text-secondary" />
                        </button>
                    </div>
                ) : (
                    ""
                )}
            </div>
            {showForm && !edit ? (
                <div className="col-12 my-2">
                    <LoadMask loading={loadingForm} blur>
                        <ContactRolesForm
                            onSubmit={onCreate}
                            cancel={() => setShowForm(false)}
                        />
                    </LoadMask>
                </div>
            ) : edit ? (
                <div className="col-12">
                    <LoadMask loading={loadingForm} blur>
                        <ContactRolesForm
                            onSubmit={onSubmitEdit}
                            cancel={() => cancelEdit()}
                            initialValues={{
                                name: edit ? edit.item.name : undefined,
                            }}
                        />
                    </LoadMask>
                </div>
            ) : (
                ""
            )}
            <div className="col-12 px-0 d-flex flex-wrap pb-3">
                {loading ? (
                    <div className="col-12 d-flex align-items-center justify-content-center">
                        <Loader
                            type="Rings"
                            color="#FB885F"
                            height="100"
                            width="100"
                        />
                        <span className="">Loading...</span>
                    </div>
                ) : data && data.total > 0 ? (
                    data.data.map((item, index) => (
                        <div className="col-12 col-md-6 my-2" key={index}>
                            <div className="w-100 border rounded small-shadow p-2 d-flex justify-content-center">
                                <React.Fragment>
                                    <h5> {item.name} </h5>
                                    {permission && permission.find(({ updateAirlineCrew }) => updateAirlineCrew == true) ? (
                                        <div
                                            className="clickable ml-2"
                                            style={{ width: 15, height: 15 }}
                                            onClick={() => onClickEdit(item, index)}
                                        >
                                            <IconPen className="w-100 h-100" />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {permission && permission.find(({ removeAirlineCrew }) => removeAirlineCrew == true) ? (
                                        <div
                                            className="clickable ml-2"
                                            style={{ width: 15, height: 15 }}
                                            onClick={() => removeContactRole(item._id)}
                                        >
                                            <IconTrashAlt className="w-100 h-100" />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            </div>
                        </div>
                    ))) : !showForm && permission && permission.find(({ createAirlineCrew }) => createAirlineCrew == true) && (
                        <div className="col-12 col-md-6 my-2">
                            <button type="button" className="btn btn-light border rounded shadow bg-white p-2" style={{ width: '100px', height: '50px' }}
                                onClick={() => setShowForm(true)}
                            >
                                <IconPlus className="w-100 h-100 text-secondary" />
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

const mstp = (state) => ({
    ...state.crewAdmin.contactRoles,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(ContactRoles);
