import React from "react";
import Modal from "react-responsive-modal";
import LoadMask from "../../../Utils/LoadMask";
import CreateLiveryForm from "./createForm";


const ModalForm = (props) => {
    const { open, loading, close, submit, company } = props;

    return (
        <Modal open={open} onClose={close}>
            <div className="py-4">
                <h4 className="mb-4 text-center">TRIP</h4>
                <LoadMask loading={loading} blur>
                    <CreateLiveryForm
                        cancel={close}
                        onSubmit={submit}
                        company={company && company.customer}
                    />
                </LoadMask>
            </div>
        </Modal>
    );
};

export default ModalForm;
