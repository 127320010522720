import { connect } from "react-redux";
import Create from "./UserCreate";
import { create, getRoles } from "../../../../redux/modules/users";

const mdtp = { create, getRoles };
const mstp = (state) => ({
    ...state.users,
    userInfo: state.login.userInfo,
})

export default connect(mstp, mdtp)(Create);
