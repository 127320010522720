import { handleActions } from "redux-actions";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import moment from 'moment';
import swal from "sweetalert2";
import app from "../../libs/apiClient";
import { loadingVerifier, setFlightVerified } from "./liveryEdition";
import { errorHandler } from '../../common/utility/constants';
import { cloneDeep, isEqual } from "lodash";

const LOADING = "LOADING_PRICE_CHECK";
const LOADING_FORM = "LOADING_FORM_PRICE_CHECK";
const CREATE_FORM = "CREATE_FORM_PRICE_CHECK";
const AIRLINE_CODES = "AIRLINE_CODES_PRICE_CHECK";
const AIRPORT_CODES = "AIRPORT_CODES_PRICE_CHECK";
const SET_FLIGHT_VERIFIED = "SET_FLIGHT_VERIFIED_PRICE_CHECK";
const FROM = "FROM_PRICE_CHECK";
const TO = "TO_PRICE_CHECK";
const DATA = "DATA_PRICE_CHECK";
const RATE_ITEM = "RATE_ITEM_PRICE_CHECK";
const EXTRA_DATA = "EXTRA_DATA_PRICE_CHECK";

// Actions
const setExtraData = (extraData) => ({ type: EXTRA_DATA, extraData });
const setLoading = (loading) => ({ type: LOADING, loading });
const setLoadingForm = (loadingForm) => ({ type: LOADING_FORM, loadingForm });
const showCreateForm = (createForm) => ({ type: CREATE_FORM, createForm });
const setData = (data) => ({ type: DATA, data });
const setRateItem = (rateItem) => ({ type: RATE_ITEM, rateItem });
const setAirlineCodes = (airlineCodes) => ({
    type: AIRLINE_CODES,
    airlineCodes,
});
const setAirportCodes = (airportCodes) => ({
    type: AIRPORT_CODES,
    airportCodes,
});

export const setDetails = (detail, key) => async (dispatch) => {
    dispatch({ type: `${key.toUpperCase()}_PRICE_CHECK`, [key]: detail });
};

export const findAirport = (data) => (dispatch, getStore) => {
    const { airportCodes } = getStore().priceCheck;
    let foundAirport;
    if (data._id) {
        foundAirport = airportCodes.find(
            (item) => JSON.stringify(item._id) === JSON.stringify(data._id)
        );
    } else {
        const street = `${data.street_number ? data.street_number : ''}${data.route ? ` ${data.route}` : ''}`.trim().toLowerCase().match(/\w+/g);
        foundAirport = airportCodes.find((a) => {
            const airportStreet = a.street.trim().toLowerCase().match(/\w+/g);
            const dataZip = !isNaN(data.postal_code) ? parseInt(data.postal_code) : data.postal_code;
            return isEqual(a.zip, dataZip) && isEqual(airportStreet, street);
        });
    }
    return foundAirport;
};

const getPrices = (values) => async (dispatch, getStore) => {
    await dispatch(setLoading(true));
    try {
        dispatch(setExtraData([]))
        const { from, to } = getStore().priceCheck;
        const fromAirport = dispatch(findAirport(from));
        const toAirport = dispatch(findAirport(to));

        const [fromLocation] = fromAirport
            ? [fromAirport]
            : await app.service("/api/zip").find({
                  query: {
                      $paginate: false,
                      zip_code: from.postal_code,
                      $populate: ["zone"],
                  },
              });

        const [toLocation] =
            !fromAirport && toAirport
                ? await app.service("/api/airport").find({
                      query: { $paginate: false, zip: to.postal_code },
                  })
                : await app.service("/api/zip").find({
                      query: {
                          $paginate: false,
                          zip_code: to.postal_code,
                          $populate: ["zone"],
                      },
                  });

        const finalData = [];
        if (fromLocation && toLocation) {

            const airlineRouteList = await app.service("/api/airline_route").find({
                query: {
                    $paginate: false,
                    active: { $ne: false },
                    airline: values.company._id,
                    airport: fromAirport ? fromLocation._id : toLocation._id,
                    zone: fromAirport ? toLocation.zone._id : fromLocation.zone._id,
                    $select: ["_id"],
                },
            });

            const routesId = airlineRouteList.map((route) => route._id);

            const data =
                routesId && routesId.length > 0
                    ? await app.service("/api/contract_route_price").find({
                          query: {
                              $paginate: false,
                              route: { $in: routesId },
                              $populate: [
                                  {
                                      path: "route",
                                      populate: [
                                          {
                                              path: "account_group",
                                              populate: [
                                                  "account",
                                                  "owner",
                                                  "contract",
                                              ],
                                          },
                                          "airport",
                                      ],
                                  },
                                  "vehicle",
                              ],
                          },
                      })
                    : [];

            if (data && data.length > 0) {
                const contractsId = data.map((item) => item._id);
                const timeBasePrices = await app
                    .service("/api/time_based_price")
                    .find({
                        query: {
                            $paginate: false,
                            contract_route_price: { $in: contractsId },
                        },
                    });

                const rateExpirations = await app
                    .service("/api/future_route_price")
                    .find({
                        query: {
                            $paginate: false,
                            current_route_price: { $in: contractsId },
                            is_expired: false,
                            is_current: false,
                        },
                    });

                const expiredRates = await app.service("/api/future_route_price").find({
                        query: {
                            $paginate: false,
                            current_route_price: { $in: contractsId },
                            $or: [
                                { is_expired: true },
                                { is_current: true }
                            ]
                        },
                    });

                for (let ir = 0; ir < airlineRouteList.length; ir += 1) {
                    const route = airlineRouteList[ir];
                    const contracts = data.filter(
                        (item) =>
                            JSON.stringify(item.route._id) ===
                            JSON.stringify(route._id)
                    );
                    const contractSorted = contracts.sort(
                        (a, b) => a.max_pax - b.max_pax
                    );
                    for (let i = 0; i < contractSorted.length; i += 1) {
                        const minPax = i > 0 ? contractSorted[i - 1].max_pax + 1 : 1;
                        contractSorted[i].rateExpirations = rateExpirations.filter(
                            (item) =>
                                JSON.stringify(item.current_route_price) ===
                                JSON.stringify(contractSorted[i]._id)
                        );
                        const contractExpiredRates = expiredRates.filter(
                            (item) =>
                                JSON.stringify(item.current_route_price) ===
                                JSON.stringify(contractSorted[i]._id)
                        );
                        contractExpiredRates.sort((a, b) => {
                            if (moment(a.effective_date, 'MM/DD/YYYY').isBefore(moment(b.effective_date, 'MM/DD/YYYY'))) {
                                return 1;
                            } else if (moment(a.effective_date, 'MM/DD/YYYY').isAfter(moment(b.effective_date, 'MM/DD/YYYY'))){
                                return -1;
                            }
                            return 0;
                        });
                        contractSorted[i].expiredRates = contractExpiredRates.slice(0,4);
                        finalData.push({
                            ...contractSorted[i],
                            min_pax: minPax,
                        });
                        const list = timeBasePrices.filter(
                            (item) =>
                                JSON.stringify(item.contract_route_price) ===
                                JSON.stringify(contractSorted[i]._id)
                        );
                        if (list && list.length > 0) {
                            list.map((item) =>
                                finalData.push({
                                    ...contractSorted[i],
                                    timeBasePrice: item,
                                    min_pax: minPax,
                                })
                            );
                        }
                    }
                }
            }
        }
        await dispatch(setData(finalData));

        if (finalData.length <= 0) {
            try {
                const response = await app.service("/api/distance").find({
                    query: {
                        state: from.state,
                        city: from.city,
                        postal_code: from.postal_code,
                        latFrom: from.lat,
                        lngFrom: from.lng,
                        latTo: to.lat,
                        lngTo: to.lng,
                        to_city: to.city,
                        to_state: to.state,
                        to_postal_code: to.postal_code
                    }
                });
        
                if (response) {
                   const generalPriceVehicleList = response.data[0].vehicleBaseRates.sort((a,b)=>a.rate-b.rate)
                    await dispatch(setExtraData(generalPriceVehicleList));
                }
            } catch (error) {
                console.error(error);
            }
        }
        
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const createLiveryTrip = (data) => (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    const { from, to, airlineCodes } = getStore().priceCheck;
    const { values: formValues = {} } = getStore().form.formPriceCheck;
    let { company } = formValues;
    const fromAirport = dispatch(findAirport(from));
    const toAirport = dispatch(findAirport(to));

    if (company && typeof company === "string") {
        company = airlineCodes.find(
            (item) =>
                item.code.trim().toLowerCase() ===
                    data.airline.trim().toLowerCase() ||
                item._id.trim() === data.airline.trim() ||
                item.label.trim() === data.airline.trim()
        );
    }

    data.company = company;

    if (!data.company) {
        swal.fire({
            type: "error",
            title: "ERROR",
            text: "Company not found",
        });
    } else {
        if (!(data.airline instanceof Object))
            data.airline = airlineCodes.find(
                (item) =>
                    item.code.trim().toLowerCase() ===
                        data.airline.trim().toLowerCase() ||
                    item._id.trim() === data.airline.trim() ||
                    item.label.trim() === data.airline.trim()
            );

        if (data.flightVerified) {
            const formDateTime = moment(data.date).set({ 'hour': data.time.hour, 'minutes': data.time.minutes });
            const oldDateTime = moment(`${data.flightVerified.date}`).set({ 'hour': data.flightVerified.time.hour, 'minutes': data.flightVerified.time.minutes });
            if (
                !formDateTime.isSame(oldDateTime) ||
                !toAirport ||
                data.flightVerified.flightViewData.airportCode !==
                    toAirport.code ||
                data.flightVerified.airline.code !== data.airline.code
            ) {
                delete data.flightVerified;
            }
        }

        app.service("/api/livery")
            .create({ ...data, from, to, fromAirport, toAirport })
            .then((response) => {
                console.log(response);
                swal.fire({
                    type: "success",
                    title: "Success",
                    text: "Trip Created",
                }).then(() => {
                    dispatch(showModal(false));
                });
            })
            .catch((error) => {
                const { data } = error;
                if(data && data.isBefore){
                    swal.fire({
                        type: "warning",
                        html: `<div>
                            <h5>
                                The pickup time is within 60 minutes of the flight departure time. Would you like to continue and add this trip?
                            </h5>
                        </div>`,
                        showCancelButton: true,
                        allowOutsideClick: false,
                        confirmButtonColor: "#D60132",
                        cancelButtonColor: "#6C757D",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                    }).then(({ dismiss, value }) => {
                        if (!dismiss || dismiss !== "overlay") {
                            dispatch(setLoadingForm(true));
                            const confirmCreate =
                                dismiss && dismiss === "cancel" ? false : value;
                            app.service("/api/livery")
                                .create({ ...data.data, confirmCreate })
                                .then((response) => {
                                    console.log(response);
                                    if (response && response.liveryId) {
                                        swal.fire({
                                            type: "success",
                                            title: "success",
                                            text: "Trip Created",
                                        }).then(() => {
                                            dispatch(showModal(false));
                                        });
                                    } else if (response && response.isAlert) {
                                        dispatch(
                                            change(
                                                "createLivery",
                                                "flightVerified",
                                                {
                                                    flightViewData:
                                                        data.flightViewData,
                                                    airline: data.data.airline,
                                                    date: data.data.date,
                                                    time: data.data.time,
                                                    logId: response._id,
                                                }
                                            )
                                        );
                                    }
                                })
                                .catch((error) => {
                                    dispatch(errorHandler(error));
                                })
                                .finally(() => {
                                    dispatch(setLoadingForm(false));
                                });
                        }
                    });
                } else {
                    dispatch(errorHandler(error));
                }
            })
            .finally(() => {
                dispatch(setLoadingForm(false));
            });
    }
};

export const verifyFlight = (data) => async (dispatch, getStore) => {
    dispatch(loadingVerifier(true));
    try {
        const { from, to, airportCodes } = getStore().priceCheck;

        const fromAirport =
            from && Object.keys(from).length > 0
                ? airportCodes.find(
                      (item) =>
                          (from.label && from.label.trim().match(new RegExp(`${item.code}[^$]`)) !== null) ||
                          (from.formatted_address.trim().match(new RegExp(`${item.code}[^$]`)) !== null)
                  )
                : undefined;
        const toAirport =
            to && Object.keys(to).length > 0
                ? airportCodes.find(
                      (item) =>
                          (to.label && to.label.trim().match(new RegExp(`${item.code}[^$]`)) !== null) ||
                          (to.formatted_address.trim().match(new RegExp(`${item.code}[^$]`)) !== null)
                  )
                 : undefined;

        let result = await app.service("/api/xmlFlight").find({
            query: {
                airlineCode: data.airline.code,
                airportCode: fromAirport ? fromAirport.code : toAirport ? toAirport.code : '',
                flightNumber: data.flight,
                date_of_service: data.date,
                departure: fromAirport ? false : true,
            },
        });
        if (!result) {
            result = { noInfo: true };
        } else {
            const filterDateTime = (final, item) => {
                const keys = Object.keys(item);
                const objKey = (keys.includes('Scheduled') && keys.includes('GateTime')) ? "scheduled"
                    : (keys.includes('Estimated') && keys.includes('GateTime')) ? "estimated"
                    : (keys.includes('Actual') && keys.includes('GateTime')) ? "actual" : null;

                if (objKey) {
                    final[objKey] = {
                        date: item.Date[0]._,
                        time: item.Time[0]._,
                    };
                }
                return final;
            };

            result.departure.dateTime = result.departure.dateTime.reduce(filterDateTime, {});
            result.arrival.dateTime = result.arrival.dateTime.reduce(filterDateTime, {});
        }

        dispatch(setFlightVerified(result));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(loadingVerifier(false));
    }
};

export const initializeCreateForm = (index) => (dispatch, getStore) => {
    const { data } = getStore().priceCheck;
    const item = data[index];
    if (item) {
        const initialData = {};

        if (item.min_pax) initialData.pax = item.min_pax;

        if (item.timeBasePrice)
            initialData.time = {
                hour: item.timeBasePrice.start_time.hour,
                minutes: item.timeBasePrice.start_time.minutes,
            };
        if (item.route) {
            if (item.route.isCGO) initialData.is_cgo = item.route.isCGO;
            if (item.route.isAOA) initialData.is_aoa = item.route.isAOA;
        }
        dispatch(initializeForm("createFormPriceCheck", initialData));
    }
};

export const initializeRateForm = (index) => (dispatch, getStore) => {
    const { data } = getStore().priceCheck;
    const item = data[index];
    const allSameContracts = data.filter((cItem) => JSON.stringify(cItem._id) === JSON.stringify(item._id));
    if (item) {
        const initialData = cloneDeep(item);
        initialData.timePrice = allSameContracts.reduce((acum, cItem) => {
            if (cItem.timeBasePrice) acum.push(cItem.timeBasePrice);
            return acum;
        }, []);
        if (initialData.timeBasePrice) delete initialData.timeBasePrice;
        dispatch(setRateItem(initialData));
        dispatch(initializeForm("airlineRatesForm", initialData));
    }
};

export const getAirlineCodes = () => async (dispatch) => {
    const airlineCodes = await app
        .service("/api/airline")
        .find({ query: { $paginate: false } });
    for (let index = 0; index < airlineCodes.length; index += 1) {
        airlineCodes[index].label = `${airlineCodes[index].code} - ${airlineCodes[index].company}`;
    }
    dispatch(setAirlineCodes(airlineCodes));
};

export const getAirportCodes = () => async (dispatch) => {
    const airportCodes = await app.service('/api/airport').find({ query: { $paginate: false } });
    for(let index = 0; index < airportCodes.length; index++){
        airportCodes[index].label = airportCodes[index].code + " - " + airportCodes[index].name;
    }
    dispatch(setAirportCodes(airportCodes));
};

export const showModal = (value, index) => (dispatch) => {
    if (value) {
        dispatch(setLoadingForm(true));
        dispatch(initializeRateForm(index));
        dispatch(setLoadingForm(false));
    }
    dispatch(showCreateForm(value));
};

export const useVerifyFlight = () => (dispatch, getStore) => {
    const { from } = getStore().priceCheck;
    const { xmlFlightVerified } = getStore().liveryEdition;
    const fromAirport =
        from && Object.keys(from).length > 0
            ? dispatch(findAirport(from))
            : undefined;
    if (xmlFlightVerified && !xmlFlightVerified.noInfo) {
        xmlFlightVerified.selected = {
            departure: xmlFlightVerified.departure.dateTime.estimated || xmlFlightVerified.departure.dateTime.actual,
            arrival: xmlFlightVerified.arrival.dateTime.estimated || xmlFlightVerified.arrival.dateTime.actual,
        };
        if (fromAirport) {
            const time = xmlFlightVerified.selected.arrival.time.split(":");
            dispatch(change("createFormPriceCheck", 'time', { hour: time[0], minutes: time[1] }));
        }
    }
    dispatch(change("createFormPriceCheck", "xmlFlightVerified", xmlFlightVerified));
};
export const useOriginalVerifyFlight = () => (dispatch, getStore) => {
    const { from } = getStore().priceCheck;
    const { xmlFlightVerified } = getStore().liveryEdition;
    const fromAirport =
        from && Object.keys(from).length > 0
            ? dispatch(findAirport(from))
            : undefined;
    if (xmlFlightVerified && !xmlFlightVerified.noInfo) {
        xmlFlightVerified.selected = {
            departure: xmlFlightVerified.departure.dateTime.scheduled,
            arrival: xmlFlightVerified.arrival.dateTime.scheduled,
        };
        if (fromAirport) {
            const time = xmlFlightVerified.selected.arrival.time.split(":");
            dispatch(change("createFormPriceCheck", "time", { hour: time[0], minutes: time[1] }));
        }
    }
    dispatch(change("createFormPriceCheck", "xmlFlightVerified", xmlFlightVerified));
};

export const actions = {
    setDetails,
    createLiveryTrip,
    getAirlineCodes,
    getAirportCodes,
    useVerifyFlight,
    useOriginalVerifyFlight,
    showModal,
    getPrices,
};

// Reducers
const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [LOADING_FORM]: (state, { loadingForm }) => ({ ...state, loadingForm }),
    [CREATE_FORM]: (state, { createForm }) => ({ ...state, createForm }),
    [AIRLINE_CODES]: (state, { airlineCodes }) => ({ ...state, airlineCodes }),
    [AIRPORT_CODES]: (state, { airportCodes }) => ({ ...state, airportCodes }),
    [SET_FLIGHT_VERIFIED]: (state, { xmlFlightVerified }) => ({
        ...state,
        xmlFlightVerified,
    }),
    [FROM]: (state, { from }) => ({ ...state, from }),
    [TO]: (state, { to }) => ({ ...state, to }),
    [DATA]: (state, { data }) => ({ ...state, data:data }),
    [EXTRA_DATA]: (state, { extraData }) => ({ ...state, extraData: extraData }),
    [RATE_ITEM]: (state, { rateItem }) => ({ ...state, rateItem }),
};

export const initialState = {
    loading: false,
    loadingForm: false,
    createForm: false,
    airlineCodes: [],
    airportCodes: [],
    xmlFlightVerified: {},
    rateItem: undefined,
    from: {},
    to: {},
    data: [],
    extraData: []
};

export default handleActions(reducers, initialState);
