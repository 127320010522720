import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import moment from "moment";
import _ from "lodash";


const LOADING = "LOADING_CREW_ADMIN_ACCOUNT_GROUPS";
const LOADING_FORM = "LOADING_UPDATE_FORM_ACCOUNT_GROUPS";
const MODAL_CONTACTS = "SET_MODAL_CONTACTS_ACCOUNT_GROUPS";
const EDIT_CONTACT = "SET_EDIT_CONTACT_ACCOUNT_GROUPS";
const SET_DATA = "SET_ACCOUNT_GROUPS_DATA";
const SET_PAGE = "SET_PAGE_ACCOUNT_GROUPS";
const SET_ITEM = "SET_ITEM_ACCOUNT_GROUPS";
const SET_SEARCH = "SET_SEARCH_ACCOUNT_GROUPS";
const MODAL_ROUTES = "MODAL_ROUTES_ACCOUNT_GROUPS_ROUTES";


export const find = (airlineId, page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $limit: 3,
            $sort: {  _id: -1 },
            $skip: (page - 1) * 3
        }};
        const search = getStore().crewAdmin.accountGroups.search;
        if(search){
            params.query.$or = [
                { contractId: { $regex: _.escapeRegExp(search), $options: 'i' }},
            ]
        }

        let accounts = await app.service('/api/airline_account').find({ query: { $paginate: false, airline: airlineId, $select: ['_id'] } });
        accounts = accounts.map(item => item = item._id);
        params.query.account = { $in: accounts };

        const data = await app.service('/api/account_groups').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {1
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    const { item } = getStore().crewAdmin.airlines;
    try {
        if(!item || !item._id)
            throw 'Cannot find airline';
        await app.service('/api/account_groups').create(data);
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
        return true;
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true));
    try {
        let params = { query: { $populate: ['owner', { path: 'account', populate: ['airline']  }] } };
        let data = await app.service('/api/account_groups').get(id, params);
        data.airports = await app.service('/api/airport').find({ query: { $paginate: false, "account_group.account_group": id } });
        data.routes = await app.service('/api/airline_route').find({ query: { $paginate: false, account_group: data._id } });
        if(data.account)
            data.account.label = `${data.account.airline ? `${data.account.airline.code} - ` : ''}${data.account.acct_name}`;

        data.contacts = await app.service('/api/airline_route_contact').find({ query: { $paginate: false, account_group: id, $populate: ['contact', 'contact_type'] }});

        data.effectiveDate = moment(data.effectiveDate).format('MM/DD/YYYY');
        data.expirationDate = moment(data.expirationDate).format('MM/DD/YYYY');
        data.commission_rate = data.commission_rate ? data.commission_rate.toString() : "0"
        dispatch(initializeForm('accountGroupForm', data));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
};

export const update = (data) => async (dispatch, getStore) =>{
    let result = false;
    dispatch(setLoadingForm(true));
    try{
        await app.service('/api/account_groups').update(data._id, data);
        result = true;
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
    return result;
};

export const destroy = (id, airlineId) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    const { page } = getStore().crewAdmin.accountGroups;
    try {
        await app.service("/api/account_groups").remove(id);
        dispatch(find(airlineId, page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setSearch = (airlineId, search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH, search });
    dispatch(find(airlineId));
};

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE, page });
export const setData = (data) => ({ type: SET_DATA, data });
export const setItem = (item) => ({ type: SET_ITEM, item });
export const setModalRoutes = (modalRoutes) => ({ type: MODAL_ROUTES, modalRoutes });
export const setModalContacts = (modalContacts) => ({ type: MODAL_CONTACTS, modalContacts });
export const setEditContact = (editContact) => ({ type: EDIT_CONTACT, editContact });

export const actions = {
    find,
    create,
    setSearch,
    load2Update,
    setModalContacts,
    setEditContact,
    setModalRoutes,
    update,
    destroy,
};

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [MODAL_CONTACTS]: (state, { modalContacts }) => ({ ...state, modalContacts }),
    [EDIT_CONTACT]: (state, { editContact }) => ({ ...state, editContact }),
    [SET_SEARCH]: (state, { search }) => ({...state, search }),
    [SET_ITEM]: (state, { item }) => ({ ...state, item }),
    [MODAL_ROUTES]: (state, { modalRoutes }) => ({ ...state, modalRoutes }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    modalContacts: false,
    editContact: undefined,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    modalRoutes: false,
    search: undefined,
};

export default handleActions(reducers, initialState);
