import React, { Component } from "react";
import IconUpload from "../../../icons/upload";
import "./FileUploader.css";
window.URL = window.URL || window.webkitURL;
const defaultProps = {
    baseColor: "gray",
    activeColor: "green",
    overlayColor: "rgba(255,255,255,0.3)",
    opacity: 0,
};

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            imageSrc: "",
            loaded: false,
            invalidResolution: false
        };

        this.onDragEnter = this.onDragEnter.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }
    componentDidUpdate() {
        if (
            this.state.imageSrc === "" &&
            this.props.source !== undefined &&
            this.props.source !== null
        ) {
            this.setState({ imageSrc: this.props.source, loaded: true });
        }
    }

    componentDidMount() {
        if (this.props.img !== null && this.props.img !== undefined) {
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: this.props.img,
                loaded: true,
            });
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.img !== null && nextProps.img !== undefined) {
            // setea la imágen si se le envia una
            this.setState({
                imageSrc: nextProps.img,
                loaded: true,
            });
        }
    }

    onDragEnter(e) {
        this.setState({ active: true });
    }

    onDragLeave(e) {
        this.setState({ active: false });
    }

    onDragOver(e) {
        e.preventDefault();
    }

    onDrop(e) {
        e.preventDefault();
        this.setState({ active: false });
        this.onFileChange(e, e.dataTransfer.files[0]);
    }

    onFileChange(e, file) {
        if (!this.props.validateResolution)
            this.props.onFileChange(e, file);
        file = file || e.target.files[0];
        const pattern = /image-*/;
        const reader = new FileReader();
        var img = new Image();

        if (file) {
            if (!file.type.match(pattern)) {
                alert("Formato inválido");
                return;
            }

            this.setState({ loaded: false });

            reader.onload = (e) => {
                img.src = reader.result;
                //if the flag is sent validate the resolution
                if (this.props.validateResolution){
                    img.onload = () => {
                        //
                        // it is validated that the width and height of the image correspond to the minimum
                        // In this case it is 1800 by 1000 pixels
                        if (img.width < 1800 || img.height<1000){
                            //If it does not meet the condition, a flag is placed to show an error to the user
                            this.setState({
                                invalidResolution: true
                            });
                            return;
                        }else{
                            //Otherwise the file is sent to the parent component and is also placed in the state
                            this.props.onFileChange(e, file);
                            this.setState({
                                imageSrc: reader.result,
                                loaded: true,
                                invalidResolution: false
                            });
                        }
                    };
                } else{
                    this.setState({
                        imageSrc: reader.result,
                        loaded: true,
                        invalidResolution: false
                    });
                }
            };
            reader.readAsDataURL(file);
        }
    }

    getFileObject() {
        return this.refs.input.files[0];
    }

    getFileString() {
        return this.state.imageSrc;
    }

    render() {
        let state = this.state,
            props = defaultProps,
            labelClass = `uploader ${state.loaded && "loaded"}`,
            borderColor = state.active ? props.activeColor : props.baseColor,
            iconColor = state.active
                ? props.activeColor
                : state.loaded
                ? props.overlayColor
                : props.baseColor,
            hideIcon = state.loaded ? 0 : 1;

        return (
            <div>
                <label
                    className={labelClass}
                    onDragEnter={this.onDragEnter}
                    onDragLeave={this.onDragLeave}
                    onDragOver={this.onDragOver}
                    onDrop={this.onDrop}
                    style={{ outlineColor: borderColor, height: !this.props.showImage ? 'auto' : null }}
                >
                    {this.props.showImage ? (
                        <React.Fragment>
                            <img
                                src={state.imageSrc}
                                className={state.loaded ? "loaded" : undefined}
                            />
                            <img
                                style={{ color: iconColor, opacity: hideIcon }}
                                className="icon icon-upload"
                                src={require(`../../../../../assets/img/icons/upload.png`)}
                                alt=""
                            />
                            <p
                                className="texto gris text-center"
                                style={{ opacity: hideIcon }}
                            >
                                Upload image
                            </p>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <IconUpload className="smallIcon" />
                            &nbsp;{this.props.alternativeText}
                        </React.Fragment>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={this.onFileChange}
                        ref="input"
                    />
                </label>
                {/*Resolution validation information*/}
                {this.props.validateResolution && (
                    <div className="d-flex flex-column flex-md-row justify-content-md-between">
                        <div className="custom-invalid-feedback d-flex justify-content-center justify-content-md-start">
                            {this.state.invalidResolution && "Invalid Resolution"} &nbsp;
                        </div>
                        <div className="resolution-information d-flex justify-content-center justify-content-md-start">
                            Min Resolution 1800x1000 Pixels
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default FileUploader;
