import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import VehicleLocationForm from './vehicleProfileForm';
import IconTimes from '../../../icons/times';
import LocationsForm from './locationForm';
import LoadMask from '../../Utils/LoadMask';
import swal from 'sweetalert2';


class VehicleLocationModal extends React.Component {
    state = {
        valuesVehicleForm: undefined,
        loadingLocationsForm: false,
        step: 0,
    };

    setStep = (next = true) => {
        this.setState({ step: next ? this.state.step + 1 : this.state.step - 1 });
    };

    onSubmitVehicleForm = values => {
        this.setState({ valuesVehicleForm: values });
        this.setStep();
        this.loadFormLocations();
    };

    loadFormLocations = () => {
        this.setState({ loadingLocationsForm: true });;
        this.props.getLocationsVehicle(this.props.id).then(() => {
            this.setState({ loadingLocationsForm: false });
        });
    };

    onSubmitLocationForm = (values) => {
        const { valuesVehicleForm } = this.state;
        const data = {
            vehicleData: valuesVehicleForm,
            locationData: values,
        };
        this.setState({ loadingLocationsForm: true });
        this.props.onSubmit(data).then(()=>{
            swal.fire({
                type: "success",
                title: "SUCCESS!",
                text: "Profile applied"
            }).then(()=>{
                this.setState({ step: 0 });
                this.props.close();
            })
        }).catch((error)=>{
            console.error(error);
            const msg = error.message || error || 'There was an error on apply vehicle profile';
            swal.fire({
                type: "error",
                title: "ERROR!",
                text: `${msg}`
            })
        }).finally(()=>{
            this.setState({ loadingLocationsForm: false });
        });
    }

    close = () => {
        this.setState({ step: 0 });
        this.props.close();
    };

    render(){

        const { isOpen } = this.props;

        return(
            <Modal isOpen={isOpen} toggle={this.close}
                modalClassName="no-scroll-y" className="modal-dialog--big"
            >
                <ModalBody className="modal-body--big"
                    style={{ overflowY: "auto", maxHeight: "90vh", marginTop: "1%", marginBottom: "1%", display: "block" }}
                >
                    <button type="button" onClick={this.close}
                        className="modal-body__close"
                    >
                        <IconTimes className="smallIcon" />
                    </button>
                    <div className="d-flex flex-column">
                        <div className="">
                            <h3> Apply vehicle profile </h3>
                            <hr/>
                        </div>
                        <div className="">
                            {this.state.step === 0 && (
                                <VehicleLocationForm
                                    cancel={this.close}
                                    onSubmit={this.onSubmitVehicleForm}
                                    formValues={this.props.vehicleLocationFormValues}
                                    initialValues={{
                                        bookingOverride: false,
                                        inFleet: false,
                                    }}
                                />
                            )}
                            {this.state.step === 1 && (
                                <LoadMask loading={this.state.loadingLocationsForm} blur>
                                    <LocationsForm
                                        onSubmit={this.onSubmitLocationForm}
                                        previusStep = {()=>this.setStep(false)}
                                        id={this.props.id}
                                    />
                                </LoadMask>
                            )}
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default VehicleLocationModal;
