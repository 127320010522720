import React from "react";
import PropTypes from "prop-types";

import LoadMask from "../../Utils/LoadMask";
import AdditionalChargeForm from "../Create/additionalChargeForm";

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.load2Update(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateAditionalCharges }) => updateAditionalCharges))
                    this.props.load2Update(this.props.match.params.id);
            }
        }
    }

    render() {
        const { update, loading, userInfo: { permission } } = this.props;

        return (
            <LoadMask loading={loading} dark blur>
                {permission && permission.find(({updateAditionalCharges}) => !!updateAditionalCharges) && (
                    <div className="row">
                        <AdditionalChargeForm
                            onSubmit={update}
                        />
                    </div>
                )}
            </LoadMask>
        );
    }
}
