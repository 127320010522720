import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icons } from '../../icons/menu';
import { settingsRatesFees, settingsRatesLocations, settingsRatesVehicles } from '../../../routesConfiguration/paths';


const Container = (props) => {
    const { userInfo: { permission, superUser }, } = props;

    return (
        <div className="d-flex flex-wrap justify-content-center">
            <div className="col-12 col-xl-10 d-flex flex-wrap">
                {permission && permission.find(({ vehicles }) => vehicles !== 'none') && (
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={settingsRatesVehicles}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <Icons.IconVehicles className="h-100" />
                            </div>
                            <span className="mt-3">Vehicles</span>
                        </Link>
                    </div>
                )}
                {permission && permission.find(({ locations }) => locations !== 'none') && (
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={settingsRatesLocations}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <Icons.IconLocations className="h-100" />
                            </div>
                            <span className="mt-3">Locations</span>
                        </Link>
                    </div>
                )}
                {permission && permission.find(({ aditionalCharges }) => aditionalCharges !== 'none') && (
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={settingsRatesFees}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <Icons.IconAditionalCharges className="h-100" />
                            </div>
                            <span className="mt-3 text-center">Additional Charges</span>
                        </Link>
                    </div>
                )}
            </div>
        </div>
    )
}

const mstp = state => ({
    userInfo: state.login.userInfo
})

export default connect(mstp)(Container);
