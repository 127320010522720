import React from "react";
import { connect } from "react-redux";
import GenericNav from "../GenericNav";
import IconPost from "../../icons/post";
import IconPen from "../../icons/pen";
import {
    marketingBlogPosts,
    marketingPromoCodes,
} from "../../../routesConfiguration/paths";

const Container = (props) => {
    const {
        userInfo: { permission },
    } = props;

    const items = [
        {
            to: marketingPromoCodes,
            label: "Promo Codes",
            icon: <IconPen className="h-100" />,
            show:
                permission && permission.find(({ promo }) => promo !== "none"),
        },
        {
            to: marketingBlogPosts,
            label: "Blog Post",
            icon: <IconPost className="h-100" />,
            show:
                permission &&
                permission.find(({ blogPost }) => blogPost !== "none"),
        },
    ];

    return <GenericNav items={items} />;
};

const mathStateToProps = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mathStateToProps, null)(Container);
