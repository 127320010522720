import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { renderDatePicker, renderField, renderLabeledField, renderNumber } from "../Utils/renderField/renderField";
import AutoSuggest from "../Utils/autosuggest";
import moment from 'moment';
import { Link } from "react-router-dom";


class Form extends React.Component {

    render() {
        const { handleSubmit, change, airlineCodes, airportCodes } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <h4 className="d-flex justify-content-between mb-3">
                    Trip Information
                </h4>

                <div className="form-group">
                    <div className="d-flex justify-content-between align-items-end">
                        <label htmlFor="date">Date</label>
                        <button type="button" className="btn btn-sm btn-secondary my-2" onClick={()=>change('date', moment())}>
                            Today
                        </button>
                    </div>
                    <Field
                        name="date"
                        component={renderDatePicker}
                        placeholder="Date"
                        className="flex-1 w-100 p-0"
                        numberOfMonths={1}
                        isOutsideRange={false}
                    />
                </div>


                <div className="row">
                    <div className="form-group col-12 col-md-6 px-0 px-md-3">
                        <label htmlFor="airline">Airline Company</label>
                        <Field
                            name="airline"
                            component={AutoSuggest}
                            keyLabel='label'
                            keyId='_id'
                            placeholder="MC"
                            suggestions = {airlineCodes}
                            className="w-100"
                            // inputClassName="pt-4"
                            onSelectOption={(value)=>change('airline', airlineCodes.find(({ _id }) => _id == value ))}
                        />
                    </div>
                    <div className="form-group col-12 col-md-6 px-0 px-md-3">
                        <label htmlFor="flightNumber">Flight Number</label>
                        <Field
                            name="flightNumber"
                            component={renderField}
                            placeholder="4514"
                            className="w-100"
                        />
                    </div>
                </div>

                <div className="d-flex justify-content-center justify-content-md-end form-group">
                    <button type="submit"
                        className="btn btn-primary btn-lg"
                    >
                        SEARCH
                    </button>
                </div>
            </form>
        )
    }
}

Form = reduxForm({
    form: 'FlightsLivery',
    validate: data => {
        const errors = {};
        if(!data.date) errors.date = 'Required Field';
        if(!data.airline) errors.airline = 'Required Field';
        if(!data.airport) errors.airport = 'Required Field';
        if(!data.flightNumber) errors.flightNumber = "Required Field";
        return errors;
    }
})(Form);

const mdtp = { change };

export default connect(null, mdtp)(Form);
