import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/airlineLivery';
import moment from 'moment';
import Grid from '../../Utils/Grid/materialTable';
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import ModalForm from './modalForm';
import Select, { Async } from 'react-select';
import { isEmpty } from 'lodash';
import IconWatch, { IconWatchSlash } from '../../../icons/watch';
import { reservationsAirlineMultiTripSearch } from '../../../../routesConfiguration/paths';


const Container = (props) => {

    const tableRef = useRef();

    useEffect(()=>{
        if(!props.company || Object.keys(props.company).length == 0)
            props.history.replace(reservationsAirlineMultiTripSearch);

        if(tableRef && tableRef.current)
            tableRef.current.dataManager.changePageSize(10)

    }, []);

    const { loading, company, load2Update, modalForm, setModalForm, setAirportFilter, airportItemFilter } = props;
    let { data, airportsFilter } = props;
    if(data && data.length){
        if(props.hideCancel)
            data = data.filter(item => !['Canceled', 'Late Cancel'].includes(item.Status));

        if(props.airportItemFilter){
            data = data.filter(item => {
                let pick_airport;
                if(!isEmpty(item.Airline)){
                    pick_airport = item.Airline.trim().split(" ")[0];
                } else {
                    const arrayPick = item.PickupAirportDesc.split(/\s+/g);
                    pick_airport = arrayPick[arrayPick.length - 3]; //airport position;
                }

                const arrayDrop = item.DropoffAirportDesc.split(/\s+/g);
                let drop_airport = arrayDrop[arrayDrop.length - 3];

                let airport;
                if(!isEmpty(item.CustomerPO)) {
                    let matchAirport = item.CustomerPO.match(/\w+/g);
                    if(matchAirport && matchAirport.length == 3)
                        airport = matchAirport[1];
                }

                if(airportItemFilter.length == 0 ||
                    ((pick_airport && airportItemFilter.includes(pick_airport)) ||
                    (drop_airport && airportItemFilter.includes(drop_airport)) ||
                    (airport && airportItemFilter.includes(airport)))
                ) return true;
            });
        }
    }

    if(airportItemFilter.length > 0) {
        airportsFilter = airportsFilter.filter(item => !airportItemFilter.includes(item));
    }

    return (
        <div className="d-flex flex-wrap justify-content-center pt-4 pt-lg-5">
            {company && company.customer ? (
                <div className="col-12 material-livery-list">
                    <ModalForm
                        open={modalForm}
                        close={()=>setModalForm(false)}
                    />
                    <div className="d-flex flex-wrap justify-content-md-between mb-4">
                        <div className="col-12 col-md-3 col-lg-2 px-0 mt-2">
                            <Select
                                clearable={true}
                                className="form-control select-reset p-0 select-border-bottom bold"
                                backspaceRemovesValue={false}
                                searchable={true}
                                labelKey="code"
                                valueKey="code"
                                options={airportsFilter.map(item=>item={code:item})}
                                placeholder="Select Airport"
                                onChange={(e) => {
                                    setAirportFilter(e && e.code);
                                }}
                                value={""}
                                disabled={loading}
                            />
                        </div>
                        <div className="col-12 col-md-6 col-lg-8 mt-2 px-0 px-md-3 d-flex flex-wrap">
                            {airportItemFilter.length > 0 ? airportItemFilter.map((item, i)=>
                                <div key={i} className="border rounded bg-dark text-white p-1 m-1 d-flex">
                                    <span className="flex-1 px-3">
                                        {item}
                                    </span>
                                    <span className="px-1 clickable" onClick={()=>setAirportFilter(item, true)}>
                                        x
                                    </span>
                                </div>
                            ) : ''}
                        </div>
                        <div className="col-12 col-md-3 col-lg-2 px-0 mt-2 d-flex justify-content-end align-items-end">
                            <div className={`${!loading ? 'clickable' : ''}`}
                                onClick={() => {
                                    if(!loading) props.setHideCancel(!props.hideCancel)
                                }}
                            >
                                <label className={`mb-0 mr-2 ${!loading ? 'clickable' : ''}`}>{!props.hideCancel ? 'Hide' : 'Show'} Canceled</label>
                                {props.hideCancel
                                    ? <IconWatch className="smallIcon" />
                                    : <IconWatchSlash className="smallIcon"/>
                                }
                            </div>
                        </div>
                    </div>
                    <Grid
                        tableRef={tableRef}
                        title={`${company.customer.code.toUpperCase()} - ${company.customer.company.toUpperCase()} ${moment(company.date).format('MM.DD.YY')} - ${moment(company.endDate).format('MM.DD.YY')}`}
                        data={data}
                        columns={[
                            {
                                title: 'Flight',
                                field: 'Passenger_Name',
                                width: '13%',
                                render: (row) => row && row.FirstPassengerName ? row.FirstPassengerName : `${row.Passenger_Name.replace(/(\(\+\d+\))/g, '').trim()}`,
                                exportTransformer: (row) => row && row.FirstPassengerName ? row.FirstPassengerName : `${row.Passenger_Name.replace(/(\(\+\d+\))/g, '').trim()}`
                            },
                            {
                                title: 'PAX',
                                field: 'Passenger_Name',
                                width: '8%',
                                render: (row) => row && row.Passenger_Name ? `${row.Passenger_Name.match(/(\+\d+)/g) ? parseInt(row.Passenger_Name.match(/(\+\d+)/g)[0]) + 1 : '1'}` : '',
                                exportTransformer: (row) => row && row.Passenger_Name ? `${row.Passenger_Name.match(/(\+\d+)/g) ? parseInt(row.Passenger_Name.match(/(\+\d+)/g)[0]) + 1 : '1'}` : '',
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <div className="MuiFormControl-root MuiTextField-root" style={{ float: "left" }} >
                                        <div className="MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart">
                                            <div className="MuiInputAdornment-root MuiInputAdornment-positionStart">
                                                <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true" title="Filter" data-testid="filter_list">
                                                    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                                                </svg>
                                            </div>
                                            <input
                                                min={1} aria-invalid="false" placeholder="" type="number" aria-label="filter data by PAX Count"
                                                className="MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputTypeSearch MuiInput-inputTypeSearch"
                                                onChange={(e)=>{
                                                    let value = e.target.value && !isNaN(e.target.value) ? `+${parseInt(e.target.value)-1}` : ''
                                                    onFilterChanged(columnDef.tableData.id, value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                )
                            },
                            {
                                title: 'P/U Date',
                                field: 'PUDate',
                                width: '12%',
                                render: (row) => `${row.PUDate} ${row.PUTime}`,
                                exportTransformer: (row) => `${row.PUDate} ${row.PUTime}`
                            },
                            {
                                title: 'Pick-Up Location',
                                field: 'PickupDesc',
                                width: '26%',
                                cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '1rem', maxWidth: 250 }
                            },
                            {
                                title: 'Drop-Off Location',
                                field: 'DropoffDesc',
                                width: '26%',
                                cellStyle: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '1rem', maxWidth: 250 }
                            },
                            {
                                title: 'Status',
                                field: 'Status',
                                width: '15%',
                                customFilterAndSearch: (search, row) => row.Status && row.Status.trim().match(new RegExp(`^${search}`, 'g')) !== null,
                                filterComponent: ({ columnDef, onFilterChanged }) => (
                                    <select className="MuiInputBase-input select-border-bottom"
                                        onChange={({ target: { value }}) => onFilterChanged(columnDef.tableData.id, value)}
                                    >
                                        <option value=""> ALL </option>
                                        <option value="Pending"> PENDING </option>
                                        <option value="Unassigned"> UNASSIGNED </option>
                                        <option value="Assigned"> ASSIGNED </option>
                                        <option value="Dispatched"> DISPATCHED </option>
                                        <option value="On The Way"> ON THE WAY </option>
                                        <option value="Arrived">ARRIVED</option>
                                        <option value="Customer In Car">CUSTOMER IN CAR</option>
                                        <option value="Done"> DONE </option>
                                        <option value="Canceled"> CANCELED </option>
                                        <option value="Down">DOWN</option>
                                        <option value="Flight Time Change">FLIGHT TIME CHANGE</option>
                                        <option value="Farm Out - Unassigned">FARM OUT - UNASSIGNED</option>
                                        <option value="No Show">NO SHOW </option>
                                        <option value="Late Cancel">LATE CANCEL </option>
                                        <option value="Quote">QUOTE </option>
                                    </select>
                                ),
                                render: (row) => `${row.Status.toUpperCase()}`,
                                exportTransformer: (row) => row.Status.toUpperCase(),
                            },
                        ]}
                        isLoading={loading}
                        onRowClick={(e, row) => load2Update(row.TripID)}
                        options={{
                            idSynonym: "TripID",
                            filtering: true,
                            exportMenu: [
                                {
                                    label: "Export PDF",
                                    exportFunc: (cols, datas) =>
                                    ExportPdf(cols, data, `${company.customer.code.toUpperCase()} - ${company.customer.company.toUpperCase()} ${moment(company.date).format('MM.DD.YY')} - ${moment(company.endDate).format('MM.DD.YY')}`),
                                },
                                {
                                    label: "Export CSV",
                                    exportFunc: (cols, datas) => {
                                        cols.splice(5, 0, {
                                            title: 'Vehicle',
                                            field: 'VehicleType',
                                        });
                                        cols.splice(6, 0, {
                                            title: 'Driver',
                                            field: 'ChauffeurName',
                                        });
                                        ExportCsv(cols, data, `${company.customer.code.toUpperCase()} - ${company.customer.company.toUpperCase()} ${moment(company.date).format('MM.DD.YY')} - ${moment(company.endDate).format('MM.DD.YY')}`)
                                    }
                                },
                            ],
                            headerStyle: {
                                backgroundColor: '#D50032',
                                color: '#FFF',
                                fontSize: '1rem',
                            },
                            cellStyle: {
                                fontSize: '1rem',
                            }
                        }}
                    />
                </div>
            ) : ''}
        </div>
    )
}

const mstp = state => ({
    ...state.airlineLivery,
});

const mdtp = {
    ...actions,
}

export default connect(mstp, mdtp)(Container);
