import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { actions } from "../../../../../redux/modules/specialRatesLocations";
import { actions as locationActions } from '../../../../../redux/modules/locations';
import EspecialRates from './EspecialRates';
import SpecialRatesForm from './specialRatesForm';
import ModalReport from './modalReport';

class DetailContainer extends React.Component {

    componentDidMount() {
        const { getLocationHeader, load2Update, match } = this.props;
        getLocationHeader(match.params.id);
        load2Update(match.params.id);
    }

    render() {

        const { userInfo: { permission }, match: { params }, header,
            // Special Rate Vars
            specialRatesLoader, resetInputCalendar, changeRadioChecks, closeSpecialRates, modalSpecialRates, vehicleEdit,
            getVehicles, removeSpecialRate, closeEditVehicle, submitEspecialRate, vehicleLocationLoader, updateSpecialRateVehicle,
            loadEditVehicleSpecialRate, updateDataEspecialRate, openReportModal, seatSearchSpecialRate, openModalSpecialRates,
            updateData, loadingSpecialRates,
            // modal report vars
            modalReport, closeModalReport, generarteReport, loaderReport, loading
        } = this.props;

        return permission && permission.find(({ updateLocations }) => !!updateLocations)
            ? <div className="d-flex flex-column border border-dark p-2 p-md-5 flex-wrap justify-content-center align-items-center">
                <div className="col-12 d-flex justify-content-center align-items-center">
                    {header && (
                        <React.Fragment>
                            <h1> Promotional Rates ({header.cityCode})</h1>
                        </React.Fragment>
                    )}
                </div>

                <EspecialRates
                    specialRatesLoader={specialRatesLoader}
                    resetInputCalendar={resetInputCalendar}
                    changeRadioChecks={changeRadioChecks}
                    toggle={closeSpecialRates}
                    isOpen={modalSpecialRates}
                    vehicleEdit={vehicleEdit}
                    getVehicles={getVehicles}
                    removeSpecialRate={removeSpecialRate}
                    closeEditVehicle={closeEditVehicle}
                    submitEspecialRate={submitEspecialRate}
                    vehicleLocationLoader={vehicleLocationLoader}
                    updateSpecialRateVehicle={updateSpecialRateVehicle}
                    loadEditVehicleSpecialRate={loadEditVehicleSpecialRate}
                    updateDataEspecialRate={updateDataEspecialRate}
                />

                <ModalReport
                    modalReport={modalReport}
                    toggle={closeModalReport}
                    onSubmit={generarteReport}
                    loaderReport={loaderReport}
                />

                <div className="col-12">
                    <SpecialRatesForm
                        onSubmit={()=>{}}
                        openReportModal={openReportModal}
                        seatSearchSpecialRate={seatSearchSpecialRate}
                        openModalSpecialRates={openModalSpecialRates}
                        specialRates={updateData && updateData.specialRates}
                        showDownloadButton={true}
                        showSearch={true}
                        params={params}
                        loadingSpecialRates={loadingSpecialRates}
                        loading={loading}
                        update
                    />
                </div>

            </div>
            : null
    }
}

const mstp = (state) => ({
    ...state.specialRatesLocations,
    userInfo: state.login.userInfo,
    header: state.locations.header,
});

const mdtp = {
    ...actions,
    getLocationHeader: locationActions.getLocationHeader
};

export default connect(mstp, mdtp)(DetailContainer);
