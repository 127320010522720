import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { HashRouter as Router } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';
import AlertProvider from "./AlertProvider";

export default class Root extends Component {
    get content() {
        return <Router>{this.props.routes}</Router>;
    }

    render() {
        return (
            <Provider store={this.props.store}>
                <HttpsRedirect>
                    <AlertProvider>{this.content}</AlertProvider>
                </HttpsRedirect>
            </Provider>
        );
    }
}

Root.propTypes = {
    routes: PropTypes.element.isRequired,
    store: PropTypes.object.isRequired,
};
