import { connect } from "react-redux";
import PromoCodeGrid from "./PromoCodeGrid";
import { find, destroy, setSearch } from "../../../../redux/modules/promoCodes";
import { unsetMessage } from "../../../../redux/modules/message";
import { withAlert } from "react-alert";

const mstp = (state) => ({
    ...state.promoCodes,
    ...state.message,
    userInfo: state.login.userInfo
});

const mdtp = { find, destroy, unsetMessage, setSearch };

export default withAlert(connect(mstp, mdtp)(PromoCodeGrid));
