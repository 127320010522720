import React from "react";
import { Modal, ModalBody } from 'reactstrap';
import { Field, reduxForm } from "redux-form";
import { TableHeaderColumn } from "react-bootstrap-table";

import Table from "../../Utils/Grid";
import ModalQuote from "./modalQuote";
import { BreakLine } from "../../Utils/tableOptions";
import { renderSwitch } from "../../Utils/renderField";
import { dateFormatter } from "../../Client/Grid/ClientGrid";
import { renderDropdownSelection, renderField, renderSelectField } from "../../Utils/renderField/renderField";
import renderDatePicker from "../../Utils/renderField/renderDatePicker";
import moment from "moment";
import IconPlus from '../../../icons/plus';
import IconFilter from '../../../icons/filter';
import IconTimes from "../../../icons/times";
import BookingModal from './bookModal/BookingModal';
import { Link } from "react-router-dom";
import { getPathWithParams, reservationsCharterQuotesId, reservationsCharterQuotesNew } from "../../../../routesConfiguration/paths";


const calcDiff = (date) => {
    let now = moment();
    date = moment(date, 'YYYY-MM-DDTHH:mm:ss');
    let difYear = date.diff(now, 'years');
    let difMonth = date.diff(now, 'months');
    let difDay = date.diff(now, 'days');
    let difHour = date.diff(now, 'hours');
    let difMinute = date.diff(now, 'minutes');

    if(difYear > 0)
        return difYear + ' Y';
    else if(difMonth > 0)
        return difMonth + ' M';
    else if(difDay > 0)
        return difDay + ' D';
    else if(difHour > 0)
        return difHour + ' H';
    else if(difMinute > 0)
        return difMinute + ' m';
    else
        return 'T'
}

const List = ({
    data,
    page,
    loading,
    find,
    item,
    PDFQuote,
    loadingModal,
    modalDetail,
    openDetails,
    clearDates,
    remove,
    history,
    sendEmail,
    userInfo: { permission },
    modalReservations,
    openModalReservation,
}) => {

    return (
        <div className="row">
            <div className="col-sm-12 pt-2">
                <div className="grid-container">
                    <div className="grid-title d-flex flex-column justify-content-center align-items-stretch align-items-sm-center">
                        <h1>
                            <strong>Quotes</strong>
                        </h1>
                    </div>

                    <BookingModal
                        isOpen={modalReservations}
                        toggle={() => openModalReservation(false)}
                        find={find}
                    />

                    <hr/>

                    <div className="mb-1 d-flex justify-content-between align-items-strech flex-column flex-md-row">
                        <div className="mb-2 align-self-end w-100 d-flex flex-column-reverse flex-md-column" style={{ flex: 1 }}>
                            <div className="d-flex flex-column flex-md-row">
                                <div style={{ flex: 1 }}>
                                    <Field
                                        name="type"
                                        type="input"
                                        component={renderField}
                                        placeholder="Search by Quote No, Pick up, Dropp off, Vehicle type"
                                        cb={()=>find(1)}
                                    />
                                </div>
                                <div className="d-flex mt-2 mt-md-0 ml-0 ml-md-3 align-items-center justify-content-end">
                                    <div className="px-1">
                                        <IconFilter className="smallIcon"/>
                                    </div>
                                    <Field
                                        name="filter"
                                        component={renderDropdownSelection}
                                        className="d-flex justify-content-end"
                                        placeholder="Order by"
                                        clearable
                                        scrolling
                                        options={[
                                            {key: "Upcoming", text: "Upcoming", value: "upcoming"},
                                            {key: "Booking Date", text: "Booking Date", value: "booking"},
                                        ]}
                                        cb={()=>find(1)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-row my-2">
                                <div className="d-flex flex-column flex-md-row" style={{ flex: 1 }}>
                                    <div style={{ flex: 1}}>
                                        <Field
                                            name="startDate"
                                            placeholder="Start Date"
                                            component={renderDatePicker}
                                            showButton={false}
                                            timeFormat={false}
                                            cb={()=>find(1)}
                                        />
                                    </div>
                                    <div style={{ flex: 1}}>
                                        <Field
                                            name="endDate"
                                            placeholder="End Date"
                                            className="ml-0 ml-md-2 mt-2 mt-md-0"
                                            component={renderDatePicker}
                                            timeFormat={false}
                                            showButton={false}
                                            cb={()=>find(1)}
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end align-items-end">
                                    <button
                                        type="button"
                                        className="btn btn-danger ml-1"
                                        onClick={() => clearDates()}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-end mb-2" style={{ flex: 1 }}>
                            <div className="w-100 d-flex justify-content-end">
                                <Link to = {reservationsCharterQuotesNew} className="btn btn-primary ml-2">
                                    New Quote
                                </Link>
                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={modalDetail}
                        toggle={() => openDetails()}
                        modalClassName="no-scroll-y" className="modal-dialog--big modalItinerary"
                    >
                        <ModalBody className="modal-body--big"
                            style={{ overflowY: "auto", maxHeight: "90vh",
                                marginTop: "1%", marginBottom: "1%", display: "block",
                            }}
                        >
                            <button type="button" onClick={() => openDetails()}
                                className="modal-body__close"
                            >
                                <IconTimes className="smallIcon" />
                            </button>
                            <div className="mt-1 col-12 col-md-10 offset-md-1 px-0">
                                <ModalQuote
                                    quote={item}
                                    openDetails={openDetails}
                                    PDFQuote={PDFQuote}
                                    loading={loadingModal}
                                    remove={remove}
                                    sendEmail={sendEmail}
                                />
                            </div>
                        </ModalBody>
                    </Modal>

                    <br />
                    <Table
                        onPageChange={find}
                        data={data}
                        page={page}
                        loading={loading}
                        onRowClick={(row) => history.push(getPathWithParams(reservationsCharterQuotesId, "id", row._id))}
                    >
                        <TableHeaderColumn
                            tdStyle={BreakLine}
                            thStyle={BreakLine}
                            dataFormat={dateFormatter}
                            isKey
                            dataField="_id"
                            className="hide-column-header"
                            dataFormat={(cell, row)=>(
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-1 flex-column flex-md-row">
                                            <div className="d-flex flex-column" style={{ flex: 0.9 }}>
                                                <label>
                                                    <strong> Total trips: </strong> {row.trips.length}
                                                </label>
                                                <label>
                                                    <strong> Total: </strong> ${row.trips.reduce((total, current) => total += current.itinerary.transactionAmount, 0).toFixed(2)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {row.trips && row.trips.map(({ itinerary }, index, trips) => (
                                        <React.Fragment key={index}>
                                            <div className="d-flex flex-row">
                                                <div className="d-flex flex-column flex-md-row flex-1">
                                                    {index === trips.length - 1 && (
                                                        <div className="d-flex flex-column pr-md-3">
                                                            <label> <strong> Created: </strong> {moment(row.createdAt).format('MM/DD/YYYY')} </label>
                                                            <label> <strong> Agent: </strong> {row.agentId && `${row.agentId.firstName} ${row.agentId.lastName}`} </label>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="d-flex flex-column h-100">
                                                    {index === trips.length - 1 && (
                                                        <React.Fragment>
                                                            <div className="d-md-none d-flex flex-column align-items-end" style={{ flex: 1 }}>
                                                                {itinerary.flightAirline && itinerary.flightNumber && (
                                                                    <React.Fragment>
                                                                        <h5> {itinerary.flightAirline.code} </h5>
                                                                        <h5> {itinerary.flightNumber.flightNumber} </h5>
                                                                    </React.Fragment>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <div className="mt-2 px-3 d-flex"
                                                                    style={{
                                                                        background: "#C0C0C0",
                                                                        borderRadius: "0.4rem",
                                                                    }}
                                                                >
                                                                    <small style={{ fontSize: "calc(0.8em + 0.2vw)" }}> {row.quoteNo} </small>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        >
                        </TableHeaderColumn>
                    </Table>
                </div>
            </div>
        </div>
    );
}

export default reduxForm({
    form: "quoteListFilter",
    destroyOnUnmount: false,
})(List);
