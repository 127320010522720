import { connect } from "react-redux";
import Create from "./ClientCreate";
import { create } from "../../../../redux/modules/clients";

const mdtp = { create };

const mstp = state => ({
    userInfo: state.login.userInfo
})

export default connect(mstp, mdtp)(Create);
