import React from "react";
import LoadMask from "../../Utils/LoadMask";
import DetailPassenger from "./detailPassenger";
import DetailBooking from "./detailBooking";
import DetailService from "./detailService";

class Details extends React.Component {
    componentDidMount() {
        const { type, match, load2Update } = this.props;
        const { params } = match;
        if (params.id) load2Update(type, params.id);
    }

    componentDidUpdate(prevProps) {
        const { userInfo, load2Update, type, match } = this.props;
        const { params } = match;

        if (prevProps.userInfo && userInfo) {
            const { permission } = userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if (
                oldPermission &&
                permission &&
                JSON.stringify(oldPermission) !== JSON.stringify(permission)
            ) {
                if (permission.find(({ readReviews }) => readReviews))
                    load2Update(type, params.id);
            }
        }
    }

    componentWillUnmount() {
        const { clearUpdateData } = this.props;
        clearUpdateData();
    }

    patchReview(values) {
        const { type, match, patch } = this.props;
        const { params } = match;
        if (params.id) patch(type, params.id, values);
    }

    render() {
        const {
            loading,
            itemPassenger,
            itemBooking,
            itemService,
            type,
        } = this.props;

        const patch = this.patchReview.bind(this);

        return (
            <LoadMask loading={loading} blur>
                {type === 0 && <DetailPassenger updateData={itemPassenger} />}
                {type === 1 && <DetailBooking updateData={itemBooking} />}
                {type === 2 && (
                    // eslint-disable-next-line react/jsx-no-bind
                    <DetailService updateData={itemService} onSubmit={patch} />
                )}
            </LoadMask>
        );
    }
}

export default Details;
