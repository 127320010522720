import React from "react";
import classNames from "classnames";
import { Field, reduxForm } from "redux-form";
import { activeFormatter } from "../../Utils/Acciones/Acciones";
import renderDatePicker from "../../Utils/renderField/renderDatePicker";
import moment from "moment";
import Grid from "../../Utils/Grid/materialTable";

const ReservationList = ({
    data,
    find,
    page,
    change,
    loading,
    activeTab,
    handlePastQuery,
    handleTodayQuery,
    handleFutureQuery,
}) => {
    const tableRef = React.useRef();
    React.useEffect(() => {
        if (tableRef)
            tableRef.current.dataManager.changePageSize(10);
    }, []);

    return (
        <div className="row">
            <div className="col-sm-12 pt-2">
                <div className="grid-container material-livery-list">
                    <div className="grid-title d-flex flex-column justify-content-center align-items-stretch align-items-sm-center">
                        <h1>
                            <strong>Trips Report</strong>
                        </h1>
                    </div>

                    <ul className="nav nav-tabs">
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={handlePastQuery}
                                className={classNames("nav-link", {
                                    "active text-primary": activeTab === 0,
                                })}
                            >
                                Past
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={() => handleTodayQuery(change)}
                                className={classNames("nav-link", {
                                    "active text-primary": activeTab === 1,
                                })}
                            >
                                Today
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                type="button"
                                onClick={() => handleFutureQuery(change)}
                                className={classNames("nav-link", {
                                    "active text-primary": activeTab === 2,
                                })}
                            >
                                Futures
                            </button>
                        </li>
                    </ul>

                    {activeTab === 0 && (
                        <div className="row my-3">
                            <div className="col-4">
                                <label htmlFor="limitDate">Start date</label>
                                <Field
                                    name="limitDate"
                                    component={renderDatePicker}
                                    cb={find}
                                />
                            </div>
                        </div>
                    )}

                    <Grid
                        tableRef={tableRef}
                        title=""
                        columns={[
                            {
                                title: "Pick up date",
                                field: "puTimeNoTimezone",
                                render: (row) =>
                                    row.puTimeNoTimeZone
                                        ? moment.parseZone(cell).format('MM/DD/YYYY')
                                        : moment.parseZone(row.puTime).format('MM/DD/YYYY')
                            },
                            {
                                title: "Pick up Address",
                                field: "puAddress",
                            },
                            {
                                title: "Client's name",
                                field: "guestFirstNm",
                                render: (row) =>
                                    `${row.guestFirstNm} ${row.guestLastNm}`,
                            },
                            {
                                title: "Actions",
                                field: "_id",
                                width: "15%",
                                align: "center",
                                render: (row) =>
                                    activeFormatter({
                                        ver:
                                            "/reservations/charter/bookings/detail",
                                    })(row._id, row),
                            },
                        ]}
                        data={data.data}
                        onSearchChange={(search) => {
                            change("guestName", search);
                            find();
                        }}
                        onPageChange={(p) => find(p + 1)}
                        isLoading={loading}
                        page={page - 1}
                        totalCount={data.total}
                        options={{
                            pageSizeOptions: [],
                            filtering: false,
                            idSynonym: "_id",
                            searchText: "Search by code",
                            pageSize: 10,
                            headerStyle: {
                                backgroundColor: "#D50032",
                                color: "#FFF",
                                fontSize: "1rem",
                            },
                            cellStyle: {
                                fontSize: "1rem",
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default reduxForm({
    form: "reservationListFilter",
})(ReservationList);
