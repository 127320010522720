
import React, { useState } from 'react';
import { renderField, renderCurrency, renderSearchSelect } from '../../Utils/renderField/renderField';
import { Field, FieldArray, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import IconPlus from '../../../icons/plus';
import app from '../../../../libs/apiClient';
import _ from "lodash";



class ItemArrayVehicles extends React.Component{

    state = {
        validVehicle: false
    }

    getVehicles = (search) => {
        return this.props.getVehicles(search).then( vehicles => {
            for(let index = 0; index < vehicles.length; index++)
                vehicles[index].type = `${vehicles[index].type} ${vehicles[index].class}`;
            return { options: vehicles};
        }).catch( (e) => {
            console.log(e);
        });
    };

    componentDidMount(){
        const { fields, index } = this.props;
        if(fields.get(index).vehicle){
            this.setState({ validVehicle: true });
        }
    }


    render(){

        const { index, vehicle, removeBaseRate, openEditVehicle, fields } = this.props;
        const { validVehicle } = this.state;

        return (
            <tr key={index}>
                <td>
                    <Field
                        name={`${vehicle}.vehicle`}
                        valueKey="_id"
                        labelKey="type"
                        component={renderSearchSelect}
                        label="Vehicle"
                        loadOptions={this.getVehicles}
                        myChange={(e)=> e
                            ? !validVehicle && this.setState({ validVehicle: true })
                            : this.setState({ validVehicle: false })
                        }
                    />
                </td>
                <td>
                    <Field
                        name={`${vehicle}.rate`}
                        type="number"
                        component={renderCurrency}
                        placeholder="Rate"
                    />
                </td>
                <td className="text-center">
                    <button
                        type="button"
                        className="btn btn-secondary m-1"
                        onClick={() => fields.remove(index)}
                        style={{ width: "32px", height: "32px" }}
                    >
                        X
                    </button>
                    {validVehicle && (
                        <button type = "button"
                            className="btn btn-primary p-0 m-1"
                            onClick={() => openEditVehicle(index)}
                            style={{ width: "32px", height: "32px" }}
                        >
                            <img
                                className=""
                                title="Edit"
                                src={require("../../../../../assets/img/icons/edit.png")}
                                alt="Edit"
                                style={{
                                    minWidth: "28px",
                                    maxWidth: "28px",
                                    margin: "0 0.3rem !important",
                                    cursor: "pointer",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}
                            />
                        </button>
                    )}
                </td>
            </tr>
        )
    }
}

const renderVehicle = ({
    fields,
    meta: { error, submitFailed },
    openEditVehicle,
    getVehicles,
}) => {
    return (
        <div className="flex-1 d-flex flex-column">
            {fields.length > 0 ? (
                <React.Fragment>
                    <button
                        type="button"
                        className="btn btn-secondary align-self-end my-2"
                        onClick={() => fields.push({})}
                    >
                        Add Vehicle
                    </button>
                    <table className="table table-sm table-hover table-striped">
                        <tbody>
                            <tr>
                                <th style={{ width: "40%" }}>Vehicle</th>
                                <th style={{ width: "40%" }}>Rate</th>
                                <th style={{ width: "20%" }} />
                            </tr>
                            {fields.map((vehicle, index) => (
                                <ItemArrayVehicles
                                    key={index}
                                    vehicle={vehicle}
                                    index={index}
                                    fields={fields}
                                    openEditVehicle={openEditVehicle}
                                    getVehicles={getVehicles}
                                />
                            ))}
                        </tbody>
                    </table>
                </React.Fragment>
            ) : (
                <span className="align-self-center"
                    onClick={() => fields.push({})}
                    style={{ cursor: "pointer" }}
                >
                    <IconPlus className="plusIconLocation m-5"/>
                </span>
            )}

            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger align-self-center">
                    {error}
                </div>
            }

        </div>
    )
}


let EspecialRatesVehicleForm = (props) => {

    return (
        <React.Fragment>
            <form name="especialRatesForm" className="d-flex flex-column" onSubmit={props.handleSubmit}>
                <h3 className="flex-1 align-self-center"> Vehicles </h3>
                <FieldArray
                    name="specialRates"
                    component={renderVehicle}
                    getVehicles={props.getVehicles}
                    openEditVehicle={props.openModalVehicle}
                />
                <div className="flex-1 col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 d-flex flex-column justify-content-center align-items-center">
                    <button type="submit"
                        className="btn btn-primary my-2"
                        style={{minWidth: "10rem"}}
                    >
                        Save
                    </button>
                    <button type="button"
                        className="btn btn-secondary my-2"
                        style={{minWidth: "10rem"}}
                        onClick={props.previosStep}
                    >
                        Back
                    </button>
                    <button type="button"
                        className="btn btn-link my-2"
                        style={{minWidth: "10rem"}}
                        onClick={props.toggle}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </React.Fragment>
    )
}

EspecialRatesVehicleForm = reduxForm({
    form: "especialRatesForm",
    destroyOnUnmount: false,
    validate: (values) => {
        const errors = {}
        if (!values.specialRates || !values.specialRates.length) {
            errors.specialRates = { _error: "At least one vehicle must be entered" };
        } else {
            const specialRatesArrayErrors = [];
            values.specialRates.forEach((vehicle, vehicleIndex) => {
                const vehicleErrors = {};
                if (!vehicle || !vehicle.vehicle) {
                    vehicleErrors.vehicle = "Required Field";
                    specialRatesArrayErrors[vehicleIndex] = vehicleErrors;
                }
                if (!vehicle || !vehicle.rate) {
                    vehicleErrors.rate = "Required Field";
                    specialRatesArrayErrors[vehicleIndex] = vehicleErrors;
                }
                if ( vehicle &&
                    _.filter(values.specialRates, { vehicle: vehicle.vehicle }).length > 1
                ) {
                    vehicleErrors.vehicle = "Duplicated Field";
                    specialRatesArrayErrors[vehicleIndex] = vehicleErrors;
                }
            });
            if (specialRatesArrayErrors.length) {
                errors.specialRates = specialRatesArrayErrors;
            }
        }
        return errors;
    }
})(EspecialRatesVehicleForm);

// const selector = formValueSelector('especialRatesForm');
// EspecialRatesVehicleForm = connect(state => {
//     const addTime = selector(state, 'addTime');
//     const schedule = selector(state, 'schedule');
//     const ranges = selector(state, 'calendar')
//     return { addTime, schedule, ranges }
// })(EspecialRatesVehicleForm);

export default EspecialRatesVehicleForm;