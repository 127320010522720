import { createActions, handleActions } from "redux-actions";
import { initialize as initializeForm } from "redux-form";
import { goBack } from "react-router-redux";
import moment from "moment";
import _ from 'lodash';
import app from "../../libs/apiClient";
import { actions as breadcrumbActions } from "./breadcrumb";

const service = app.service("/api/tour");
const serviceResources = app.service("api/resources");

const SET_SEARCH = "SET_SEARCH";
const LOADING_BANNER = "LOADING_BANNER";
const SET_SORT = "SET_SORT";
const { loading, setData, setPage, setBanner, setUpdateData } = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_DATA: (data) => ({ data }),
    SET_PAGE: (page) => ({ page }),
    SET_BANNER: (banner) => ({ banner }),
    SET_UPDATE_DATA: (updateData) => ({ updateData }),
});

export const create = (formData) => async (dispatch) => {
    dispatch(loading());
    const tour = {
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    };
    delete tour.virtualItinerary;

    try {
        await service.create(tour, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        dispatch(goBack());
    } catch (err) {
        console.log("error ", err);
    } finally {
        dispatch(loading(false));
    }
};


export const loadBanner = (picture, bannerId) => async (dispatch) => {
    dispatch(loading());
    const resource = {
        picture: {
            buffer: picture,
            mimeType: picture && picture.type,
        },
    };

    try {
        if (bannerId){
            const response = await serviceResources.update(bannerId, resource, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            });
            dispatch(setBanner([response]))
        }
        else
            await serviceResources.create(resource, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            });
    } catch (err) {
        console.log("error ", err);
    } finally {
        dispatch(loading(false));
    }
};

export const update = (formData) => async (dispatch) => {
    dispatch(loading());
    const tour = {
        ...formData,
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    };

    delete tour.virtualItinerary;

    try {
        await service.patch(tour._id, tour, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        dispatch(goBack());
    } catch (err) {
        console.log("error", err);
    } finally {
        dispatch(loading(false));
    }
};

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(loading());

    try {
        const { search, sort } = getStore().tours;
        const query = { query: { $skip: (page - 1) * 10 } };
        if(search){
            query.query.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: 'i'} },
                { city: { $regex: _.escapeRegExp(search), $options: 'i'} },
                { address: { $regex: _.escapeRegExp(search), $options: 'i'} },
            ]
        }
        if(sort){
            let sortValue;
            if(sort === 'name')
                sortValue = { name: 1 };
            else if(sort === 'next_date')
                sortValue = { 'itinerary.date': 1 };
            else if(sort === 'address')
                sortValue = { address: 1 };
            query.query.$sort = sortValue;
        }
        const response = await service.find(query);
        for(let index = 0; index < response.data.length; index++){
            response.data[index] = await service.get(response.data[index]._id);
            let item = response.data[index];
            let dateNext = item.itinerary.reduce( (next, current) =>{
                let now=moment();
                let nextDate = moment(next.date);
                let currentDate = moment(current.date);
                if(currentDate.isBefore(now))
                    return next;
                else if(currentDate.isBefore(nextDate))
                    return current;
                else return next;
            }, item.itinerary[0] )
            response.data[index].dateNext = dateNext;
            if(dateNext){
                response.data[index].dateNext.nextHour = response.data[index].dateNext.spots.reduce( (next, current) => {
                    let nowHour = moment();
                    let currentHour = moment(current.hour);
                    let nextHour = moment(next.hour);
                    if(currentHour.isBefore(nowHour))
                        return next;
                    else if(currentHour.isBefore(nextHour))
                        return currentHour;
                    else return next;
                }, response.data[index].dateNext.spots[0]);
            }
        }
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (err) {
        console.log("error", err);
    } finally {
        dispatch(loading(false));
    }
};

export const loadSetBanner = () => async (dispatch) => {
    dispatch(loadingBanner(true));
    try {
        const banner = await app.service('api/resources').find({
            query: { $limit: 1, $sort: { createdAt: -1} }
        })
        if(banner){
            dispatch(initializeForm('imageTour', banner[0]))
        }
        dispatch(setBanner(banner))
    } catch (err) {
        console.log("error", err);
    } finally {
        dispatch(loadingBanner(false));
    }
}

export const destroy = (id) => async (dispatch) => {
    dispatch(loading());

    try {
        await service.remove(id);
        dispatch(find());
    } catch (err) {
        console.log("error", err);
    } finally {
        dispatch(loading(false));
    }
};

export const load2Update = (id) => async (dispatch) => {
    dispatch(loading());

    try {
        const tour = await service.get(id);
        tour.itinerary = tour.itinerary.map((it) => ({
            ...it,
            spots: it.spots.map((spot) => ({
                ...spot,
                hour: moment(spot.hour),
            })),
        }));
        tour.virtualItinerary = [
            ...tour.itinerary,
            { spots: [{ hour: moment().startOf("hour") }] },
        ];

        dispatch(setUpdateData(tour));
        const { updateCustomLabel } = breadcrumbActions;
        let { name: title } = tour;
        if (title.length > 22) {
            title = `${title.slice(0, 23)}...`;
        }

        dispatch(updateCustomLabel(id, title));
        dispatch(initializeForm("tour", tour));
    } catch (err) {
        console.log("error", err);
    } finally {
        dispatch(loading(false));
    }
};

const loadingBanner = (value) => ({
    type: LOADING_BANNER,
    loading_banner: value
})

export const setSearch = () => (dispatch, getStore) => {
    const { values } = getStore().form.searchTours;
    let search = values && values.search;
    dispatch({ type: SET_SEARCH, search });
    dispatch(find());
}

export const setSort = () => (dispatch, getStore) => {
    const { values } = getStore().form.imageTour;
    let sort = values && values.sort;
    dispatch({ type: SET_SORT, sort });
    dispatch(find());
}

const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setBanner]: (state, { payload: { banner } }) => ({ ...state, banner }),
    [setUpdateData]: (state, { payload: { updateData } }) => ({
        ...state,
        updateData,
    }),
    [SET_SEARCH]: ( state, { search }) => ({ ...state, search }),
    [LOADING_BANNER]: ( state, { loading_banner }) => ({ ...state, loading_banner }),
    [SET_SORT]: (state, { sort }) => ({ ...state, sort })
};

const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    banner: [],
    loading: false,
    updateData: {},
    loading_banner: false,
    sort: undefined,
    search: undefined,
};

export default handleActions(reducers, initialState);
