import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Swal from 'sweetalert2';
import List from "./QuoteList";
import { actions } from "../../../../redux/modules/quote";
import { cleanDataModal } from '../../../../redux/modules/itinerary';


class ListContainer extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.find();
    }

    openModalReservation = (value) => {
        if(value == true) this.props.cleanDataModal();
        this.props.openModalReservation(value);
    }

    remove=(id)=>{
        Swal({
            title: "Are you sure?",
            text: "You cannot undo this action!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                this.props.destroy(id);
            }
        });
    }

    render() {
        return (
            <List
                {...this.state}
                {...this.props}
                remove={(id)=>this.remove(id)}
                openModalReservation={this.openModalReservation}
            />
        );
    }
}

const mstp = (state) => ({
    ...state.quote,
    userInfo: state.login.userInfo
})

const mdtp = { ...actions, cleanDataModal }

export default connect(mstp, mdtp)(ListContainer);
