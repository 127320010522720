import React from 'react';
import numeral from 'numeral';

const Discount = ({ price, discount, gratuity, gratuityPercent }) => (
    <div className="d-flex flex-column flex-md-row justify-content-end">
        <div className="w-50 d-flex flex-column font-bolder">
            <div className="flex-1 d-flex align-items-end font-bold">
                <div className="flex-2 mx-2 text-secondary text-right">TRIP:</div>
                <div className="flex-1 mx-2 text-secondary text-right">
                    ${`${numeral(price + parseFloat(discount || 0)).format('0,0.00')}`}
                </div>
            </div>

            {(gratuity && !isNaN(gratuity) && parseFloat(gratuity) > 0) ? (
                <div className="flex-1 d-flex">
                    <div className="flex-2 m-2 text-secondary text-right h6">GRATUITY:</div>
                    <div className="flex-1 m-2 text-secondary text-right h6">
                        ${`${parseFloat(gratuity || 0).toFixed(2)}${gratuityPercent && ` (${gratuityPercent}%)`}`}
                    </div>
                </div>
            ) : ''}

            {discount && !isNaN(discount) && parseFloat(discount) > 0 ? (
                <div className="flex-1 d-flex">
                    <div className="flex-2 m-2 text-secondary text-right h6">DISCOUNT:</div>
                    <div className="flex-1 m-2 text-secondary text-right h6">
                        - ${`${parseFloat(discount || 0).toFixed(2) }`}
                    </div>
                </div>
            ) : ''}
        </div>
    </div>
);

export default Discount;
