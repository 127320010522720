import React from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import { BreakLine } from "../../../Utils/tableOptions";
import Table from "Utils/Grid";
import { RenderCurrency } from '../../../Utils/renderField/renderReadField';
import moment from 'moment';
import IconPlus from '../../../../icons/plus';
import Search from './search';
const { MEDIA_URL } = process.env;

export default class VehiclesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { match: { params } } = this.props;
        this.props.find(params.id);
    }

    render() {
        const { data, loader, destroy, find, page, showEdit, match: { params }, setSearch, showForm, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container">
                        <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            {permission && permission.find(({ createLocations }) => !!createLocations) && (
                                <button
                                    type="button"
                                    className="btn btn-primary ml-auto"
                                    onClick={showForm}
                                    style={{ padding: "0.375rem", borderRadius: "50%" }}
                                >
                                    <IconPlus className="smallIcon p-1"/>
                                </button>
                            )}
                        </div>
                        <br />
                        {permission && permission.find(({ readLocations }) => !!readLocations) && (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-12 col-md-7 col-lg-6 px-0 px-md-3">
                                        <Search onSubmit={setSearch}/>
                                        <br style={{ border: 0,  }}/>
                                    </div>
                                </div>
                                <Table
                                    onPageChange={(page) => find(params.id, page)}
                                    data={data}
                                    page={page}
                                    loading={loader}
                                >
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="_id"
                                        isKey
                                        className="hide-column-header"
                                        dataFormat={(cell, row) =>
                                            <div className="d-flex flex-column-reverse">
                                                <div className="flex-1 d-flex flex-md-wrap flex-column flex-md-row">
                                                    <div className="col-12 col-md-6">
                                                        <label className="w-100"> <strong> From: </strong> {row.from && row.from.address} </label>
                                                        <label className="w-100"> <strong> To: </strong> {row.to && row.to.address} </label>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="w-100"> <strong> Vehicle: </strong> {row.vehicle && row.vehicle.type} </label>
                                                        <label className="w-100"> <strong> Percent Off: </strong> {row.percentOff}%</label>
                                                        <label className="w-100"> <strong> Start: </strong> {moment(row.startDate).format('l')} </label>
                                                        <label className="w-100"> <strong> Expires: </strong> {moment(row.endDate).format('l')} </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-end">
                                                    {activeFormatter({
                                                        editar: permission.find(({ updateLocations }) => !!updateLocations) ? showEdit : undefined,
                                                        eliminar: permission.find(({ removeLocations }) => !!removeLocations) ? destroy : undefined,
                                                    })(cell, row)}
                                                </div>
                                            </div>
                                        }
                                    />
                                </Table>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
