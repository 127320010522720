import { createActions, handleActions } from "redux-actions";
import moment from "moment";
import { change } from "redux-form";
import app from "../../libs/apiClient";
import _ from 'lodash';

const serviceTourResrv = app.service("/api/tour-reservation/");
const serviceTour = app.service("/api/tour");

const SET_SEARCH = "SET_SEARCH";
const LOADING_MODAL = "LOADING_MODAL";
const SET_TOURLIST_PAGE = "SET_TOURLIST_PAGE";

export const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading(true));
    const { values } = getState().form.tourResrvFilter;

    const params = {
        query: {
            $skip: (page - 1) * 10,
            metaInfo: "reservations",
        },
    };

    if (values) {
        if (values.search)
            params.query.$or = [
                { name: { $regex: _.escapeRegExp(values.search), $options: 'i' }},
                { address: { $regex: _.escapeRegExp(values.search), $options: 'i' }},
                { city: { $regex: _.escapeRegExp(values.search), $options: 'i' }},
            ]

        if (values.startDate && values.endDate) {
            if (moment(values.startDate).isSameOrBefore(moment(values.endDate))) {
                const eD = moment(values.endDate).hours("23").minutes("59").seconds("59");
                params.query = {
                    ...params.query,
                    'itinerary.date': { $lte: eD, $gte: values.startDate }
                }
            }
        }
    }

    try {
        const response = await serviceTour.find(params);
        const reservations = [];

        response.data.forEach((reservation) => {
            reservation.itinerary.forEach((hour) => {
                let pushArr = true;
                if(values && values.startDate && values.startDate.isValid() && values.endDate && values.endDate.isValid()){
                    let isBetween = moment(hour.date).isBetween(values.startDate, values.endDate)
                    if(!isBetween)
                        pushArr = false;
                }

                if(pushArr){
                    reservations.push({
                        ...hour,
                        name: reservation.name,
                        type: reservation.type,
                        price: reservation.price,
                        tourId: reservation._id,
                        address: reservation.address,
                    });
                }
            });
        });

        dispatch(setTourResrvData({ ...response, total: reservations.length, data: reservations }));
        dispatch(setTourResrvPage(page));
    } catch (err) {
        console.log("Error while fetching tour reservations", err);
    } finally {
        dispatch(loading(false));
    }
};

export const getTourResrv = (hourId) => async (dispatch) => {
    dispatch(loading(true));

    try {
        const response = await serviceTourResrv.find({
            query: { $limit: 50, spotId: hourId },
        });
        dispatch(setTourResrv(response));
    } catch (err) {
        console.log("Error while fetching reservation information", err);
    } finally {
        dispatch(loading(false));
    }
};

export const getReservationsList = (reserv, page = 1) => async (dispatch, getStore) => {
    dispatch({ type: LOADING_MODAL, loadingModal: true });

    try {
        const idArray = [];
        if(reserv && reserv.spots){
            for(let index = 0; index < reserv.spots.length; index++){
                idArray.push(reserv.spots[index]._id);
            }
        }

        const params = { query: {
            $limit: 15,
            $skip: (page - 1) * 15,
            spotId: { $in: idArray },
        }}

        const { values } = getStore().form.tourResrvFilter;
        let searchReservation = values && values.searchReservationList;
        if(searchReservation){
            params.query.$or = [
                { guestFirstNm: { $regex: _.escapeRegExp(searchReservation), $options: 'i' }},
                { guestLastNm: { $regex: _.escapeRegExp(searchReservation), $options: 'i' }},
            ]
        }
        if (values && values.category){
            let sort;
            if(values.category === 'name')
                sort = { guestFirstNm: 1 };
            else if(values.category === 'date')
                sort = { tourDt: 1 };
            else if(values.category  === 'tour')
                sort = { tourType: 1 }
            params.query.$sort = sort;
        }

        if(values && values.canceled){
            params.query.canceled = true;
        }

        const response = await serviceTourResrv.find(params);

        dispatch(setTourResrv(response));
        dispatch({ type: SET_TOURLIST_PAGE, pageList: page });
    } catch (err) {
        console.log("Error while fetching reservation information", err);
    } finally {
        dispatch({ type: LOADING_MODAL, loadingModal: false });
    }
}


export const setSearch = () => (dispatch, getStore) => {
    const { values } = getStore().form.tourResrvFilter;
    let search = values && values.searchTourReservation;
    dispatch({ type: SET_SEARCH, search });
    dispatch(find());
}


export const clearDates = () => (dispatch, getStore) => {
    dispatch(change('tourResrvFilter', 'startDate', ""));
    dispatch(change('tourResrvFilter', 'endDate', ""));
    dispatch(find());
}

export const {
    loading,
    setTourResrvData,
    setTourResrvPage,
    setTourResrv,
} = createActions({
    LOADING: (loading = true) => ({ loading }),
    SET_TOUR_RESRV_DATA: (data) => ({ data }),
    SET_TOUR_RESRV_PAGE: (page) => ({ page }),
    SET_TOUR_RESRV: (tourResrv) => ({ tourResrv }),
});

const reducers = {
    [loading]: (state, { payload: { loading } }) => ({ ...state, loading }),
    [setTourResrvData]: (state, { payload: { data } }) => ({ ...state, data }),
    [setTourResrvPage]: (state, { payload: { page } }) => ({ ...state, page }),
    [setTourResrv]: (state, { payload: { tourResrv } }) => ({
        ...state,
        tourResrv,
    }),
    [SET_SEARCH]: (state, { search }) => ({ ...state, search }),
    [LOADING_MODAL]: (state, { loadingModal }) => ({ ...state, loadingModal }),
    [SET_TOURLIST_PAGE]: (state, { pageList }) => ({ ...state, pageList }),
};

const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    pageList: 1,
    loading: false,
    updateData: {},
    tourResrv: {},
    loadingModal: false,
    search: undefined,
};

export default handleActions(reducers, initialState);
