import React, { Fragment } from "react";
import moment from "moment";

import { RenderCurrency } from "../../Utils/renderField/renderReadField";

const Detail = ({ tourResrv: { data } }) => {
    const trName = data ? data.find((data) => data.tourName) : null;
    const trDate = data ? data.find((data) => data.date) : null;

    return (
        <div className="row">
            {data && (
                <Fragment>
                    <div className="col-12">
                        <h3>
                            <strong>
                                Tour {trName && data.tourName} Reservations{" "}
                            </strong>
                        </h3>
                    </div>
                    <div className="col-12 text-center">
                        <h6>
                            {trDate && moment(trDate.date).format("MMM, D YYYY HH:mm A")}
                        </h6>
                        <br className="mb-4" />
                    </div>
                    {data.map(
                        (
                            {
                                _id,
                                guestFirstNm,
                                guestLastNm,
                                guestEmail,
                                guestMobile,
                                noTickets,
                                transactionAmount,
                            },
                            i
                        ) => (
                            <div key={_id} className="col-12 mb-3 bb-black">
                                <div className="row ">
                                    <h5 className="col-12">
                                        {" "}
                                        Reservation #{i + 1}{" "}
                                    </h5>
                                    {guestFirstNm && guestLastNm && (
                                        <div className="col-sm-6 p-3">
                                            <div>
                                                <strong> Name </strong>
                                            </div>
                                            <div>{`${guestFirstNm} ${guestLastNm}`}</div>
                                        </div>
                                    )}
                                    {guestEmail && (
                                        <div className="col-sm-6 p-3">
                                            <div>
                                                <strong> Email </strong>
                                            </div>
                                            <div>{guestEmail}</div>
                                        </div>
                                    )}
                                    {guestMobile && (
                                        <div className="col-sm-6 p-3">
                                            <div>
                                                <strong> Phone </strong>
                                            </div>
                                            <div>{guestMobile}</div>
                                        </div>
                                    )}
                                    {noTickets && (
                                        <div className="col-sm-6 p-3">
                                            <div>
                                                <strong>
                                                    {" "}
                                                    Tickets Bought{" "}
                                                </strong>
                                            </div>
                                            <div>{noTickets}</div>
                                        </div>
                                    )}
                                    {transactionAmount && (
                                        <div className="col-sm-6 p-3">
                                            <div>
                                                <strong> Paid </strong>
                                            </div>
                                            <RenderCurrency
                                                value={transactionAmount}
                                            />
                                        </div>
                                    )}
                                    <br className="mx-3 mb-4 br-black" />
                                </div>
                            </div>
                        )
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default Detail;
