import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import GenericNav from "../GenericNav";
import IconVan from "../../icons/van";
import IconTicket from "../../icons/ticket";
import IconBus from "../../icons/bus";
import IconFileDollar from "../../icons/fileDollar";
import {
    reservationsAirline,
    reservationsCharter,
    reservationsShuttles,
    reservationsTours,
} from "../../../routesConfiguration/paths";

const Container = (props) => {
    const {
        userInfo: { permission },
    } = props;

    const items = [
        {
            to: reservationsAirline,
            label: "Airline",
            icon: <IconTicket className="h-100" />,
            show: true,
        },
        {
            to: reservationsCharter,
            label: "Charter",
            icon: <IconFileDollar className="h-100" />,
            show: true,
        },
        {
            to: reservationsTours,
            label: "Tour",
            icon: <IconBus className="h-100" />,
            show:
                permission &&
                permission.find(
                    ({ tourReservations }) => tourReservations !== "none"
                ),
        },
        {
            to: reservationsShuttles,
            label: "Shared-Ride",
            icon: <IconVan className="h-100" />,
            show: true,
        },
    ];

    return <GenericNav items={items} />;
};

const mathStateToProps = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mathStateToProps, null)(Container);
