import React from "react";
import { connect } from "react-redux";
import { actions, setModalCompanySelect } from "../../../redux/modules/liveryEdition";
import LoadMask from '../Utils/LoadMask';
import FormFlights from './filghtsForm';
import FormTripId from './tripIdForm';
import IconAngleLeft from '../../icons/angleLeftIcon';
import Flights from './flights';
import Details from './details';
import ModalCompanySelect from './modalCompanySelect';
import { IconPlusLight } from "../../icons/plus";
import LogsButtons from "../airlineLivery/list/buttons";

class Container extends React.Component {

    state = {
        searchById: false,
    }

    componentDidMount(){
        this.props.initializeDataAutocomplete();
    }

    componentWillUnmount(){
        this.props.setView(1)
    }

    render() {
        const { getFlights, getFlightById, view, loading, history, setModalCompanySelect } = this.props;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                <div className="col-12 col-md-10 col-xl-8 d-flex justify-content-between">
                    <div className="d-flex text-primary d-flex align-items-center clickable"
                        onClick={()=> view === 1 ? history.goBack() : this.props.setView(1)}
                    >
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                    <div onClick={()=>setModalCompanySelect(true)} className="d-flex align-items-center clickable">
                        <span className="text-dark font-medium mr-1"> ADD </span>
                        <div className="d-flex align-items-center justify-content-center text-white bg-danger rounded-circle p-1 shadow-lg mx-1"
                            style={{ width: '30px' }}
                        >
                            <IconPlusLight className="w-100 h-100"/>
                        </div>
                    </div>
                </div>
                <ModalCompanySelect/>
                <div className="col-12 col-md-8 col-xl-6">
                    <LoadMask loading={loading} blur>
                        {view === 1
                            ? <React.Fragment>
                                <h3> Search </h3>
                                <div className="d-flex justify-content-end mt-5">
                                    <button type="button" onClick={()=>{this.setState({ searchById: !this.state.searchById })}}
                                        className="btn btn-link p-0 text-primary text-decoration-underline"
                                    >
                                        {this.state.searchById ? 'Search Trip Info' : 'Search Livery ID'}
                                    </button>
                                </div>
                                    {this.state.searchById
                                        ? <FormTripId
                                                {...this.props}
                                                onSubmit={getFlightById}
                                            />
                                        : <FormFlights
                                                {...this.props}
                                                onSubmit={getFlights}
                                            />
                                    }
                                </React.Fragment>
                            : view === 2
                                ? <React.Fragment>
                                        <h3> TRIP LIST </h3>
                                        <Flights {...this.props} />
                                    </React.Fragment>
                                : <React.Fragment>
                                        <LogsButtons/>
                                        <h4 className="mt-3 text-center"> TRIP VIEW </h4>
                                        <Details {...this.props}
                                            btnDone={()=>this.props.setView(1)}
                                            cancelTrip={this.cancelTrip}
                                            allowNoVerify={true}
                                            useModalVerify
                                        />
                                    </React.Fragment>
                        }
                    </LoadMask>
                </div>
            </div>
        )
    }
}

const mstp = (state) => ({
    ...state.liveryEdition,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
    setModalCompanySelect: setModalCompanySelect,
};

export default connect(mstp, mdtp)(Container);
