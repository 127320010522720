import app from "../../libs/apiClient";
import { createActions, handleActions } from "redux-actions";
import { goBack, push } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import moment from 'moment';
import swal from "sweetalert2";
import { errorHandler } from '../../common/utility/constants';
import { settingsUserAccountsPermissions } from "../../routesConfiguration/paths";

const service = app.service("/api/roles");


const SET_DATA = "SET_DATA_ROLES";
const SET_PAGE = "SET_PAGE_ROLES";
const SET_LOADING = "SET_LOADING_ROLES";
const SET_ITEM = "SET_ITEM_ROLES";


const getAdministrationValue = (values) => {

    const administrationSubFields = ['vehicles', 'tours', 'companies', 'clients', 'locations', 'airlineCrew', 'aditionalCharges', 'points']

    let newAdministrationValue = 'none';
    for(let i = 0; i < administrationSubFields.length; i++){
        let item = administrationSubFields[i].trim();
        if(values[item] && values[item] != 'none'){
            let CRUDName = item.trim().replace(/^\w/, (c)=>c.toUpperCase());
            if(values[`read${CRUDName}`] == true || values[`create${CRUDName}`] == true || values[`update${CRUDName}`] == true || values[`remove${CRUDName}`] == true){
                newAdministrationValue = 'some';
                break;
            }
        }

    }
    return newAdministrationValue;
}

// Actions
const create = (values) => (dispatch, getState) => {
    dispatch(loading(SET_LOADING, true));

    values.administration = getAdministrationValue(values);

    service.create(values).then((response) => {
        swal.fire({
            type: "success",
            title: "Success!",
            text: "Role Created"
        }).then(result => {
            dispatch(push(settingsUserAccountsPermissions))
        })
    }).catch((e) => {
        dispatch(errorHandler(e));
    }).finally(() => {
        dispatch(goBack());
        dispatch(loading(SET_LOADING, false));
    });
};

const update = () => async (dispatch, getState) => {
    const formData = getState().form.rolesForm.values;

    dispatch(loading(SET_LOADING, true));

    formData.administration = getAdministrationValue(formData);

    try {
        await service.patch(formData._id, formData);
        dispatch(goBack());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

const destroy = (id) => async (dispatch, getState) => {
    dispatch(loading(SET_LOADING, true));
    try {
        await service.remove(id);
        dispatch(find());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading(SET_LOADING, true));
    const { values = {} } = getState().form.quoteListFilter || {};

    try {
        const query = { query: { $skip: (page - 1) * 10, $sort: { _id: -1 } } };

        if (values) {
            //TODO: Filter
        }

        const response = await service.find(query);
        dispatch(setData(SET_DATA, response));
        dispatch(setPage(SET_PAGE, page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

const load2Update = (id) => async (dispatch, getState) => {
    dispatch(loading(SET_LOADING, true));

    try {
        const item = await service.get(id);
        item.monthlyManifestDashboard = item.monthlyManifestDashboard === true ? 'true' : 'false';
        item.newestTripsDashboard = item.newestTripsDashboard === true ? 'true' : 'false';
        dispatch(setItem(SET_ITEM, item));
        dispatch(initializeForm('rolesForm', item));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(SET_LOADING, false));
    }
};

const clear = () => (dispatch) => {
    dispatch(setItem(SET_ITEM, {}));
}

export const actions = {
    find,
    load2Update,
    clear,
    destroy,
    create,
    update,
}

const loading = (type, loading) => ({ type, loading });
const setData = (type, data) => ({ type, data });
const setPage = (type, page) => ({ type, page });
const setItem = (type, item) => ({ type, item});

// Reducers
const reducers = {
    [SET_LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_ITEM]: (state, { item }) => ({ ...state, item }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    loading: false,
    item: {},
};

export default handleActions(reducers, initialState);
