import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import Modal from 'react-responsive-modal';
import { Tooltip } from 'reactstrap';
import { renderField, renderSelectField, renderSearchSelect, renderNumber, renderSwitch } from '../../../../../Utils/renderField';
import { renderFieldCheck } from '../../../../../Utils/renderField/renderField';
import { setActiveInactive } from '../../../../../../../redux/modules/crewAdmin/crewRoutes';
import ExternalLink from '../../../../../../icons/externalLink';
import IconPlus from '../../../../../../icons/plus';
import FormAirlineRates from './rates/RateForm';
import { IconTrashAlt } from '../../../../../../icons/trash';
import IconWatch from '../../../../../../icons/watch';
import swal from 'sweetalert2';
import app from '../../../../../../../libs/apiClient';
import IconQuestionCircle from '../../../../../../icons/questionCircle';
import IconEdit from '../../../../../../icons/edit';
import { connect } from 'react-redux';
import _ from 'lodash';
import { settingsAirlinesAirports, settingsAirlinesZones } from '../../../../../../../routesConfiguration/paths';


const renderRates = (props) => {

    const { fields, meta: { error, submitFailed }, modalRates, setModalRates, change, disabled = false,
    editRates, setEditRates, itemRates, readRates, setReadRates, setItemFormRates } = props;

    const removeContact = (index) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this Rate? </h3>",
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                fields.remove(index)
            }
        })
    }

    const submitEdit = (values) => {
        change(`rates[${itemRates.index}]`, values);
        setItemFormRates();
        setReadRates(false);
        setEditRates(false);
        setModalRates(false);
    }

    const submitCreate = (values) => {
        fields.push(values);
        setModalRates(false);
    }

    const cancelModal = () => {
        if(itemRates){
            setReadRates(false);
            setEditRates(false);
            setItemFormRates();
        }
        setModalRates(false)
    }

    const setReadRateItem = (item, index) => {
        setReadRates(true);
        setItemFormRates({ item, index });
        setModalRates(true);
    }

    const setEditRateItem = () => {
        setReadRates(false);
        setEditRates(true);
    }

    return (
        <div className="d-flex flex-wrap">
            <Modal open={modalRates} onClose={()=>cancelModal()}>
                <div className="d-flex flex-wrap">
                    <div className="col-12 d-flex flex-wrap justify-content-center pt-5 pb-4">
                        <div className="mb-3">
                            <div className="d-flex justify-content-between">
                                <h3 className="text-left mb-3"> {readRates ? 'View' : editRates ? 'Edit' : 'New'} Rate </h3>
                                {readRates && !editRates ? (
                                    <button type="button" className="btn btn-link text-primary p-0" onClick={()=>setEditRateItem()}>
                                        Edit
                                    </button>
                                ) : ''}
                            </div>
                            <FormAirlineRates
                                onSubmit={(values) => {
                                    if(editRates)
                                        submitEdit(values);
                                    else
                                        submitCreate(values);
                                }}
                                readOnly={readRates && !editRates}
                                cancel={()=>cancelModal()}
                                initialValues={itemRates && itemRates.item ? { ...itemRates.item } : {}}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            {fields.length > 0 ? (
                <div className="w-100">
                    {fields.map((itemField, index) => {
                        const item = fields.get(index);
                        return (
                            <div className="border p-2 d-flex flex-wrap" key={index}>
                                <div className="col-4 text-truncate"> {item && item.vehicle && item.vehicle.type} </div>
                                <div className="col-3 text-truncate"> {item && `$${item.price}`} </div>
                                <div className="col-3 text-truncate"> {item && `${item.max_pax} PAX`} </div>
                                {!disabled ? (
                                    <div className="col-2 d-flex">
                                        <div className="mx-1 clickable" onClick={()=>removeContact(index)}>
                                            <IconTrashAlt className="smallIcon"/>
                                        </div>
                                        <div className="mx-1 clickable" onClick={()=>setReadRateItem(item, index)}>
                                            <IconWatch className="smallIcon"/>
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        )
                    })}
                    {!disabled ? (
                        <button type="button" className="btn btn-light border my-2" onClick={() => setModalRates(true)}>
                            <IconPlus className="smallIcon text-secondary" />
                        </button>
                    ) : ''}
                </div>
            ) : ( !disabled ?
                    <button type="button" className="btn btn-light border rounded shadow bg-white p-2"
                        style={{ width: '100px', height: '50px' }}
                        onClick={() => setModalRates(true)}
                    >
                        <IconPlus className="w-100 h-100 text-secondary"/>
                    </button>
                : ''
            )}
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger align-self-center">
                    {error}
                </div>
            }
        </div>
    )
}


const Form = (props) => {

    const [tooltip, setTooltip] = useState([false, false, false, false])

    const loadAirports = (search) => {
        const params = {};
        if(search){
            params.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { description: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { code: { $regex: _.escapeRegExp(search), $options: 'i' }}
            ]
        }

        return app.service('/api/airport').find({ query: params }).then(result => {
            for(let i = 0; i < result.data.length; i++){
                result.data[i].label = `${result.data[i].code} - ${result.data[i].name}`
            }
            return { options: result.data }
        }).catch(()=>{
            return { options: [] }
        });
    }

    const loadZones = (search) => {
        const params = {}
        if(search){
            params.$or = [
                { code: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { name: { $regex: _.escapeRegExp(search), $options: 'i'}},
            ]
        }
        return app.service('/api/zone').find({ query: params }).then(result => {
            return { options: result.data };
        }).catch((error)=> {
            console.log(error);
            return { options: [] };
        })
    }

    const loadAccountGroups = async (search) => {
        try {
            const params = {};
            if(search){
                params.$or = [
                    { account_group_name: { $regex: _.escapeRegExp(search), $options: 'i' } },
                    { customer_po: { $regex: _.escapeRegExp(search), $options: 'i' } },
                ]
            }
            let accounts = await app.service('/api/airline_account').find({ query: { $paginate: false, airline: props.airlineId, $select: ['_id'] } });
            accounts = accounts.map(item => item = item._id);
            params.account = { $in: accounts };
            const { data } = await app.service('/api/account_groups').find({ query: params });
            return { options: data };
        } catch(error) {
            console.log(error);
            return { options: [] };
        }
    }

    const toggleTooltip = (index) => {
        let tooltipTemp = Object.assign([], tooltip);
        tooltipTemp[index] = !tooltipTemp[index];
        setTooltip(tooltipTemp);
    }

    const setInactiveRoute = (data) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to change the state of the route? </h3>",
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "CHANGE"
        }).then(({ value })=>{
            if(value) {
                props.setActiveInactive(data).then(result=>{
                    if(result) props.cancel();
                });
            } else {
                props.change('active', !data.active)
            }
        })
    }

    const { airline, handleSubmit, cancel, modalRates, setModalRates, change, readOnly = false,
        editRates, setEditRates, readRates, itemRates, setReadRates, setItemFormRates, formValues } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap py-3">
                <div className="col-12 my-3">
                    <div className="d-flex justify-content-between">
                        <h5 className="mb-0"> General </h5>
                        {props.showActiveField ? (
                            <div className="d-flex align-items-center">
                                <label>Active&nbsp;</label>
                                <Field
                                    name="active"
                                    component={renderSwitch}
                                    cb={()=>{
                                        setInactiveRoute({ _id: formValues._id, active: !formValues.active });
                                    }}
                                />
                            </div>
                        ) : ''}
                    </div>
                </div>
                <div className="col-12 form-group">
                    <div className="d-flex justify-content-between">
                        <strong> Zones </strong>
                        <Link to={settingsAirlinesZones} target="_blank" className="d-flex text-primary align-items-center">
                            <span className="d-none d-md-inline"> Zones </span>
                            <div style={{ width: '15px', height: '13px' }} className="d-flex pl-1">
                                <ExternalLink className="w-100 h-100"/>
                            </div>
                        </Link>
                    </div>
                    <Field
                        allKey
                        name="zone"
                        component={renderSearchSelect}
                        placeholder="Select zone..."
                        loadOptions={loadZones}
                        valueKey="_id"
                        labelKey="name"
                        className=""
                        disabled={readOnly}
                    />
                </div>
                <div className="col-12 form-group">
                    <div className="d-flex justify-content-between">
                        <strong> Airport </strong>
                        <Link to={settingsAirlinesAirports} target="_blank" className="d-flex text-primary align-items-center">
                            <span className="d-none d-md-inline"> Airports </span>
                            <div style={{ width: '15px', height: '13px' }} className="d-flex pl-1">
                                <ExternalLink className="w-100 h-100"/>
                            </div>
                        </Link>
                    </div>
                    <Field
                        allKey
                        name="airport"
                        component={renderSearchSelect}
                        placeholder="Select airport..."
                        loadOptions={loadAirports}
                        className=""
                        valueKey="_id"
                        labelKey="label"
                        disabled={readOnly}
                    />
                </div>
                <div className="col-12 form-group">
                    <strong> Cancellation Policy </strong>
                    <Field
                        name="cxl_policy"
                        component={renderNumber}
                        suffix="min"
                        className=""
                        disabled={readOnly}
                    />
                </div>
                <div className="col-12 mb-3 mt-5">
                    <h5> Airport Information </h5>
                </div>
                <div className="col-12 form-group">
                    <div className="d-flex">
                        <strong> Pickup Location </strong>
                        <span id="PickToolTip" className="clickable">
                            <IconQuestionCircle className="pl-1 smallIcon text-dark" />
                        </span>
                        <Tooltip placement="right" isOpen={tooltip[1]} target="PickToolTip" toggle={()=>toggleTooltip(1)}>
                            This will serve as the pickup/drop off location description for crews arriving/departing to/from this airport.
                        </Tooltip>
                    </div>
                    <Field
                        name="airport_pu"
                        component={renderField}
                        placeholder="Door 4, Lower Level, First Curb 1D"
                        type="text"
                        className="form-control"
                        disabled={readOnly}
                    />
                </div>
                <div className="col-12 form-group">
                    <div className="d-flex">
                        <strong> Drop Off Location </strong>
                        <span id="DropToolTip" className="clickable">
                            <IconQuestionCircle className="pl-1 smallIcon text-dark" />
                        </span>
                        <Tooltip placement="right" isOpen={tooltip[2]} target="DropToolTip" toggle={()=>toggleTooltip(2)}>
                            This will serve as the pickup/drop off location description for crews arriving/departing to/from this airport.
                        </Tooltip>
                    </div>
                    <Field
                        name="airport_do"
                        component={renderField}
                        placeholder="Door 4, Lower Level, First Curb 1D"
                        type="text"
                        className="form-control"
                        disabled={readOnly}
                    />
                </div>
                {airline ? (
                    <React.Fragment>
                        {airline.has_aoa && (
                            <div className="col-12 form-group">
                                <div className="d-flex">
                                    <Field
                                        name="isAOA"
                                        component={renderFieldCheck}
                                        label="AOA Requirement"
                                        className=""
                                        classNameLabel="mb-0"
                                        disabled={readOnly}
                                    />
                                    <span id="AOAToolTip" className="clickable">
                                        <IconQuestionCircle className="pl-1 smallIcon text-dark" />
                                    </span>
                                    <Tooltip
                                        placement="right"
                                        isOpen={tooltip[3]}
                                        target="AOAToolTip"
                                        toggle={() => toggleTooltip(3)}
                                    >
                                        Pickups are made inside the restricted area at the airport
                                    </Tooltip>
                                </div>
                            </div>
                        )}
                        {airline.has_cgo && (
                            <div className="col-12 form-group">
                                <Field
                                    name="isCGO"
                                    component={renderFieldCheck}
                                    label="CGO Requirement"
                                    classNameLabel="mb-0"
                                    disabled={readOnly}
                                />
                            </div>
                        )}
                    </React.Fragment>
                ) : (
                    ""
                )}
                <div className="col-12">
                    <h5 className="bold"> Rates </h5>
                    <FieldArray
                        name="rates"
                        component={renderRates}
                        modalRates={modalRates}
                        setModalRates={setModalRates}
                        change={change}
                        editRates={editRates}
                        itemRates={itemRates}
                        readRates={readRates}
                        setEditRates={setEditRates}
                        setReadRates={setReadRates}
                        setItemFormRates={setItemFormRates}
                        disabled={readOnly}
                    />
                </div>
                <div className="col-12 form-group mt-5">
                    <div className="d-flex">
                        <strong> Account Group </strong>
                    </div>
                    <Field
                        allKey
                        name="account_group"
                        component={renderSearchSelect}
                        placeholder="Select an existing account group from the list..."
                        loadOptions={loadAccountGroups}
                        valueKey="_id"
                        labelKey="account_group_name"
                        className=""
                        disabled={readOnly}
                    />
                </div>
                <div className="col-12 d-flex justify-content-end">
                    <button type="button" className="btn btn-link text-primary m-1" onClick={()=>cancel()}>
                        {readOnly ? 'Back' : 'Cancel'}
                    </button>
                    {!readOnly ?
                        <button type="submit" className="btn btn-primary m-1">
                            SAVE ROUTE
                        </button>
                    : ''}
                </div>
            </div>
        </form>
    )
}

const RouteForm = reduxForm({
    form: 'airlineRouteForm',
    destroyOnUnmount: false,
    validate: (data) => {
        const errors = {};
        if(!data.frecuency) errors.frecuency = "Required field";
        if(!data.zone) errors.zone = "Required Field";
        if(!data.airport) errors.airport = "Required Field";
        if(!data.cxl_policy) errors.cxl_policy = "Required Field";
        if(!data.scheduleFrecuency) errors.scheduleFrecuency = "Required Field";
        if(!data.contacts) errors.contacts = "Required Field";
        if(!data.rates) errors.rates = "Required Field";
        if(data.isAOA && data.isCGO){
            errors.isCGO = "Select only one option";
        }
        if (!data.account_group) errors.account_group = "Required Field";
        return errors;
    }
})(Form);

const mstp = state => ({
    formValues: state.form.airlineRouteForm && state.form.airlineRouteForm.values,
})

const mdtp = {
    change,
    setActiveInactive
}

export default connect(mstp, mdtp)(RouteForm);
