import React from 'react';
import PropTypes from 'prop-types';
import VehicleList from './VehiclesList';


class VehicleListContainer extends React.Component {

    static propTypes = {
        handleVehicleSelect: PropTypes.func.isRequired,
    };

    render() {
        const { vehicles, handleVehicleSelect, from, formType, item, ...restProps } = this.props;

        const ascendingSort = (a, b) => a.info.price - b.info.price;
        if (vehicles  && vehicles.length > 0) vehicles.sort(ascendingSort);

        const fromAirport = from.types ? from.types.some(type => type === 'airport') : undefined;

        return (
            <VehicleList
                {...restProps}
                vehicles={vehicles}
                handleSelect={handleVehicleSelect}
                fromAirport={fromAirport}
                formType={formType}
                item={item}
            />
        );
    }
}

export default VehicleListContainer;
