import React from 'react';
import { connect } from 'react-redux';
import Loader from "react-loader-spinner";
import Modal from 'react-responsive-modal';
import LoadMask from '../../../../Utils/LoadMask';
import swal from 'sweetalert2';
import { actions } from '../../../../../../redux/modules/crewAdmin/accounts';
import IconEdit from '../../../../../icons/edit';
import { IconTrashAlt } from '../../../../../icons/trash';
import AccountForm from './AccountForm';


class AccountsContainer extends React.Component {

    state = {
        modal: false,
        edit: false,
    }

    componentDidMount(){
        if(this.props.airlineId)
            this.props.find(this.props.airlineId);
    }

    editAccount = (id) => {
        this.props.load2Update(id).then(()=>{
            this.openModal(true);
        });
    }

    openModal = (edit = false) => {
        this.setState({ modal: true, edit});
    }

    closeModal = () => {
        this.setState({ modal: false, edit: false });
    }

    submitModal = (values) => {
        if(this.state.edit)
            this.props.update(values);
        else
            this.props.create(values);
        this.closeModal();
    }

    removeAccount = (id) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this account from the customer profile? </h3>",
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                this.props.destroy(id);
            }
        })
    }

    render(){

        const { data, loading, search, setSearch, loadingForm, userInfo: { permission } } = this.props;
        const { modal } = this.state;

        return (
            <div className="w-100 p-2">

                <Modal open={modal} onClose={this.closeModal}>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 d-flex flex-wrap justify-content-center pt-5 pb-4">
                            <div className="col-12 ">
                                <div className="mb-3">
                                    <h3 className="text-left"> {this.state.edit ? 'Update Account' : 'New Account'} </h3>
                                </div>
                                <LoadMask loading={loadingForm} blur>
                                    <AccountForm
                                        onSubmit={this.submitModal}
                                        cancel={this.closeModal}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div className="d-flex py-2 justify-content-between">
                    <div className="flex-1 d-flex flex-wrap">
                        <input type="text"
                            className="form-control col-12 col-md-10 col-xl-8"
                            placeholder="Search..."
                            onChange={(event)=>{
                                setSearch(event.target.value);
                            }}
                            value={search}
                        />
                    </div>
                    {permission &&
                    permission.find(({ createAirlineCrew }) => !!createAirlineCrew) ? (
                        <div className="">
                            <button type="button" className="btn btn-primary text-nowrap ml-2" onClick={()=>this.openModal()}>
                                NEW ACCOUNT
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {loading ? (
                    <div className="d-flex justify-content-center align-items-center">
                        <Loader
                            type="Rings"
                            color="#FB885F"
                            height="100"
                            width="100"
                        />
                        <span className="">Loading...</span>
                    </div>
                ) : (
                    data.length > 0 && data.map((item, index) => (
                        <div className="border rounded border-dark small-shadow p-3 d-flex flex-wrap mt-3" key={index}>
                            <div className="col-10 px-0 d-flex flex-wrap">
                                <div className="col-12 col-sm-6 px-0">
                                    <strong>Name:</strong>
                                </div>
                                <div className="col-12 col-sm-6 px-0 ps-sm-1">
                                    <span>{item.acct_name}</span>
                                </div>
                                <div className="col-12 col-sm-6 px-0">
                                    <strong>Account Groups:</strong>
                                </div>
                                <div className="col-12 col-sm-6 px-0 ps-sm-1">
                                    <span>{item.account_groups ? item.account_groups.length : 0}</span>
                                </div>
                            </div>
                            <div className="col-2 px-0 d-flex justify-content-end align-items-start">
                                {permission &&
                                permission.find(({ removeAirlineCrew }) => !!removeAirlineCrew) ? (
                                    <div className="mx-1 clickable" onClick={()=>this.removeAccount(item._id)}>
                                        <IconTrashAlt className="smallIcon" />
                                    </div>    
                                ) : (
                                    ""
                                )}
                                {permission &&
                                permission.find(({ updateAirlineCrew }) => !!updateAirlineCrew) ? (
                                    <div className="mx-1 clickable" onClick={()=>this.editAccount(item._id)}>
                                        <IconEdit className="smallIcon" />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.accounts,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(AccountsContainer);
