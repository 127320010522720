import React, { useEffect } from "react";
import moment from "moment";
import numeral from "numeral";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { connect } from "react-redux";
import classNames from "classnames";
import { Field, reduxForm, FieldArray, formValueSelector, change } from "redux-form";
import {
    renderField,
    renderTextArea,
    renderDatePicker,
    renderSelectField,
    renderSearchSelect,
    renderPlacesAutocomplete,
    renderCurrency,
    renderFieldCheck,
} from "../../Utils/renderField/renderField";
import AutoSuggest from "../../Utils/autosuggest";
import airlineCodes from '../../../utility/airlines.json';
import renderTimePicker from "../../../../TimePicker";
import { actions } from "../../../../redux/modules/trip-edition";
import { hourlyOptions, countryOptions } from '../../../utility/constants';
import { stopNames, Stops, ButtonAddStop, validateStops } from "../../stops/Stops";
import IconTimes from "../../../icons/times";
import PaymentForm from "../../quotes/Create/Payment";

const renderCharges = ({ fields, chargesList, setCharge, firstTransactionDone = true }) => (
    <div className="row">
        {fields.map((item, i) => (
            <React.Fragment key={`holder${i}`}>
                <div className="col-6 col-md-5 form-group">
                    <Field
                        name={`${item}.aditionalId`}
                        valueKey="_id"
                        labelKey="title"
                        component={renderSearchSelect}
                        myChange={e => setCharge(e,null,i)}
                        label="Charge Type"
                        loadOptions={chargesList}
                        placeholder="Charges Type"
                    />
                </div>
                <div className="col-6 col-md-3 form-group">
                    <Field
                        name={`${item}.Qcharge`}
                        component={renderField}
                        myChange={e=>{setCharge(null,e.target.value,i)}}
                        placeholder="Qty."
                        type="number"
                        className="form-control"
                    />
                </div>
                <div className="col-6 col-md-4 form-group">
                    <Field
                        name={`${item}.amount`}
                        component={renderCurrency}
                        placeholder="Amount"
                        className="form-control"
                        readOnly
                    />
                </div>
                {/* <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) =>{e.preventDefault();  fields.remove(i)}}
                >
                    X
                </button> */}
            </React.Fragment>
        ))}
        <div className="grid-title d-flex flex-colum flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
            <button className="btn btn-link d-flex align-items-center" type="button"
                style={{ textDecoration: "None", color: "#000" }}
                onClick={(e) => {e.preventDefault();fields.push({
                    Qcharge: 1,
                })}}
                disabled={!firstTransactionDone}
            >
                <span className="btn-plus p-0 d-flex justify-content-center align-items-center">
                    +
                </span>
                <style jsx="true">{`
                    .btn-plus {
                        padding: 0;
                        font-size: 1.5rem;
                        width: 30px !important;
                        height: 30px !important;
                        font-weight: bolder;
                        background: #6C757D;
                        color: #fff;
                    }
                    @media only screen and (min-width: 576px) {
                        .btn-plus {
                            border-radius: 50% !important;
                        }
                    }
                `}</style>
                &nbsp;&nbsp;Add Charge
            </button>
        </div>
    </div>
);


let ReservationEditForm = (props) => {

    const {
        data,
        trip,
        reservation,
        setCharge,
        vehicles,
        changeVehicle,
        chargesList,
        clearData,
        resendEmail,
        changeHourly,
        loaderNewPrice,
        handleSubmit,
        to,
        from,
        change,
        cancel,
        no_flight_information,
        isAirport = false,
        flightsNumbers,
        onSelectAirline,
        getFlightInfo,
        onSelectFlight,
        setDetails,
        history,
        firstTransactionDone,
        searchForSpecialRate,
        changeAddress,
        array,
        showCheckoutForm,
        toggleCheckoutForm,
        onSubmit,
    } = props;

    useEffect(()=>{
        if(trip && trip._id)
            props.find(trip._id);
        return () => props.clearData();
    }, [trip])

    useEffect(() => {
        const { puDate, puTime, baseRateId } = reservation;
        const specialRate = async () => {
            const allSpecialIncluded = await searchForSpecialRate();
            if (!allSpecialIncluded) changeAddress();
        }
        if (
            (typeof puTime === "object" || typeof puDate === "object") &&
            baseRateId
        ) {
            specialRate();
        }
    }, [reservation.puDate, reservation.puTime]);

    const disabled = moment(data.dateCompare).isBefore(moment().format()) || loaderNewPrice;
    const stopsCount = reservation.stops ? reservation.stops.length : 0;

    return (
        <React.Fragment>
            <Modal
                isOpen={showCheckoutForm}
                toggle={() => toggleCheckoutForm(false)}
                modalClassName="no-scroll-y"
                className="modalItinerary modal-xl"
            >
                <ModalHeader>
                    <div className="col-12">
                        <p className="text-left">
                            The original payment method declined. Please provide
                            an updated card for the difference of
                            {" $"}
                            {reservation &&
                            data &&
                            data.oldTotal &&
                            reservation.newTotal
                                ? numeral(
                                    reservation.newTotal - data.oldTotal
                                ).format("0,0.00")
                                : ""}
                        </p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <button
                        type="button"
                        onClick={() => toggleCheckoutForm(false)}
                        className="modal-body__close"
                    >
                        <IconTimes className="smallIcon" />
                    </button>

                    <div className="col-12 px-0">
                        <PaymentForm
                            handlePaymentFormSubmit={(values) => {
                                change("newPaymentMethod", values);
                                toggleCheckoutForm(false);
                            }}
                            newPaymentMethod
                        />
                    </div>
                </ModalBody>
            </Modal>
            <form className="row" onSubmit={handleSubmit}>
                <div className="col-12 col-md-8 col-lg-7">
                    <div className="row">
                        <div className="col-12">
                            <h4> Trip Information </h4>
                            <br/>
                        </div>
                        <div className="col-12 col-md-3 pr-0 form-group">
                            <Field
                                name="puDate"
                                numberOfMonths={1}
                                component={renderDatePicker}
                                className="flex-1 w-100 my-2 p-0"
                                disabled={disabled}
                                cb={(value) => {
                                    change('flightAirline', null);
                                    change('flightNumber', null);
                                }}
                            />
                        </div>
                        <div className="col-12 col-md-3 py-2 form-group">
                            <Field
                                id="timepicker1"
                                placeholder="Pick Time"
                                Value={moment.parseZone(reservation.puTime)}
                                component={renderTimePicker}
                                name="puTime"
                                style={{ fontSize: "1rem", height: "calc(2.25rem + 4px)" }}
                                disabled={ disabled }
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-center">
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                id="fromAutocomplete3"
                                name="puAddress"
                                Value={trip && trip.puAddress}
                                component={renderPlacesAutocomplete}
                                setDetails={setDetails}
                                lat={from.lat}
                                lng={from.lng}
                                inputLabel={from.label}
                                type="text"
                                pl="from"
                                label="Traveling from"
                                placeholder="From: address, airport, hotel..."
                                disabled={disabled || !firstTransactionDone}
                                additionalAddressInfo={['street_number', 'route']}
                            />
                        </div>
                    <div className="col-12">
                        <FieldArray
                            name="stops"
                            component={Stops}
                            schedule={reservation.puDate}
                            form="tripEdit"
                            showTime={
                                reservation.hourlyDuration !== null &&
                                reservation.hourlyDuration !== undefined
                            }
                            disabled={disabled || !firstTransactionDone}
                            extraDetails={() => changeAddress()}
                            editTrip
                            change={change}
                        />
                    </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                id="toAutocomplete3"
                                name="doAddress"
                                Value={trip && trip.doAddress}
                                component={renderPlacesAutocomplete}
                                setDetails={setDetails}
                                lat={to.lat}
                                lng={to.lng}
                                inputLabel={to.label}
                                pl="to"
                                label="Traveling to"
                                placeholder="To: address, airport, hotel..."
                                disabled={disabled || !firstTransactionDone}
                                additionalAddressInfo={['street_number', 'route']}
                            />
                        </div>
                        {isAirport && (
                            <React.Fragment>
                                <div className="col-12 col-md-6 form-group">
                                    <Field
                                        name="flightAirline"
                                        component={AutoSuggest}
                                        keyLabel='label'
                                        keyId='_id'
                                        searchKey="company"
                                        placeholder="MC"
                                        suggestions={airlineCodes}
                                        disabled={disabled || (no_flight_information && no_flight_information)}
                                        onSelectOption={value=>onSelectAirline(value)}
                                    />
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                    <Field
                                        name="flightNumber"
                                        component={AutoSuggest}
                                        showNotFoundMsg
                                        notFoundMsg="Flight info not found"
                                        keyLabel='label'
                                        keyId='index'
                                        searchKey="flightNumber"
                                        placeholder="234"
                                        onChange={ (event, value) => getFlightInfo(value) }
                                        onSelectOption={value => onSelectFlight(value)}
                                        disabled={disabled || (no_flight_information && no_flight_information)}
                                        suggestions={flightsNumbers}
                                        typeInput="text"
                                        className="flex-1"
                                        async
                                    />
                                </div>
                                <div className="col-12 col-md-6 form-group">
                                    <div className="d-flex">
                                        <Field
                                            name="no_flight_information"
                                            label="No flight information"
                                            disabled={disabled}
                                            component={renderFieldCheck}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 form-group"></div>
                            </React.Fragment>
                        )}
                    <div className="col-12 d-flex align-items-center justify-content-start">
                        <ButtonAddStop
                            array={array}
                            fieldName="stops"
                            disabled={
                                disabled ||
                                !firstTransactionDone ||
                                (stopsCount && !reservation.hourlyDuration)
                            }
                        />
                    </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="vehicleId"
                                valueKey="_id"
                                labelKey="label"
                                component={renderSelectField}
                                label="Vehicle"
                                clearable={false}
                                options={vehicles ? vehicles : []}
                                placeholder="Vehicle type"
                                disabled={disabled || !firstTransactionDone}
                                myChange={changeVehicle}
                            />
                        </div>
                        {!!reservation && reservation.hourlyDuration && (
                            <div className="col-12 col-md-6 form-group">
                                <Field
                                    name="hourlyDuration"
                                    component={renderSelectField}
                                    clearable={false}
                                    options={hourlyOptions}
                                    myChange={changeHourly}
                                    placeholder="Duration"
                                    className="w-100"
                                    disabled={disabled || !firstTransactionDone}
                                />
                            </div>
                        )}

                        <div className="col-12 mt-1 mt-md-4">
                            <h4> Passenger Information </h4>
                            <br/>
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="guestFirstNm"
                                component={renderField}
                                placeholder="First Name"
                                type="text"
                                className="form-control"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="guestLastNm"
                                component={renderField}
                                placeholder="Last Name"
                                type="text"
                                className="form-control"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="guestEmail"
                                component={renderField}
                                placeholder="Email"
                                type="email"
                                className="form-control"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="country"
                                component={renderSelectField}
                                placeholder="Country"
                                options={countryOptions}
                                type="text"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <Field
                                name="guestMobile"
                                component={renderField}
                                placeholder="Phone Number"
                                type="text"
                                className="form-control"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-12 form-group">
                            <Field
                                name="specialNotes"
                                component={renderTextArea}
                                label="Special Notes"
                                type="text"
                                disabled={disabled}
                            />
                        </div>

                        <div className="col-12 mt-1 mt-md-4 form-group">
                            <h4> Charges </h4>
                            <br />
                            <FieldArray
                                name="additionalCharges"
                                component={renderCharges}
                                chargesList={chargesList}
                                setCharge={setCharge}
                                firstTransactionDone={firstTransactionDone}
                            />
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                            <div className="flex-column">
                                <button type="submit" className="btn btn-primary w-100" disabled={loaderNewPrice} >
                                    Save
                                </button>
                                <br style={{ borderTop: "None", width: "100%", marginTop: "0.1rem" }}/>
                                <button type="button" className="btn btn-link w-100"
                                    onClick={() => {
                                        // clearData();
                                        cancel();
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-12 col-md-4 offset-lg-1">
                    <div className="row p-3" style={{ border: "1px solid #000" }}>
                        <div className="col-6 py-2">
                            <strong> Date: </strong> {!!reservation && reservation.puDate && moment.parseZone(reservation.puDate).format('L')}
                        </div>
                        <div className="col-6 py-2">
                            <strong> Time: </strong> {!!reservation && reservation.puTime && moment.parseZone(reservation.puTime).format('HH:mm')}
                        </div>
                        <div className="col-12 py-2">
                            <strong> Pick Up: </strong>
                            {!!reservation && reservation.puAddress && reservation.puAddress}
                        </div>

                    {reservation.stops
                        ? reservation.stops.filter((stop) => stop.type === "S")
                        .map((stop, index) => (
                            <div className="col-12 py-2" key={index}>
                                <strong> {stopNames[index] ? `${stopNames[index]} stop` : `$Stop #${index + 1}`} </strong>
                                {stop.stop ? stop.stop : stop.street}
                            </div>
                        ))
                        : null}
                        <div className="col-12 py-2">
                            <strong> Drop Off: </strong>
                            {!!reservation && reservation.puAddress && reservation.doAddress}
                        </div>
                        <br/>
                        <div className="col-12 py-2">
                            <strong> Passenger: </strong>
                            {!!reservation && reservation.guestFirstNm && `${reservation.guestFirstNm} ${reservation.guestLastNm && reservation.guestLastNm}`}
                        </div>
                        <div className="col-12 py-2">
                            <strong> Email: </strong>
                            {!!reservation && reservation.guestEmail && reservation.guestEmail}
                        </div>
                        <div className="col-12 py-2">
                            <strong> Phone: </strong>
                            {!!reservation && reservation.guestMobile && reservation.guestMobile}
                        </div>
                        <br/>
                        <div className="col-12 py-2">
                            <strong> Old total: </strong>
                            {!!data && data.oldTotal &&
                            `$ ${numeral(data.oldTotal).format("0,0.00")}`}
                        </div>
                        <div className="col-12 py-2">
                            <strong> New Total: </strong>
                            {loaderNewPrice
                                ? <span className="ml-4">
                                    <div className="spinner-border" style={{ width: "1.2rem", height: "1.2rem" }} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </span>
                                : !!reservation && !!data && data.oldTotal != reservation.newTotal &&
                                    `$ ${numeral(reservation.newTotal ? reservation.newTotal : 0).format("0,0.00")}`
                            }
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    )
}

const validateAirilineOrFlight = (value, no_flight_information = false, isFlightNumber = false) =>{
    if(no_flight_information)
        return true
    else
        if(!value) return false;
        else if(isFlightNumber)
            return typeof value === 'object' ? true : false;
        else return true;
}

ReservationEditForm = reduxForm({
    form: "tripEditForm",
    validate: (data, { typeAddress, from, to }) => {
        const errors = {};
        const isOld = moment(data.dateCompare).isBefore(moment().format());
        if(!data.puTime) errors.puTime = "Required field";
        if(!data.puDate) errors.puDate = "Required field";
        if(!data.puAddress) errors.puAddress = "Required field";
        else if(!from.postal_code) errors.puAddress = "Invalid address";
        if(!data.doAddress) errors.doAddress = "Required field";
        else if(!to.postal_code) errors.doAddress = "Invalid address";
        if(!data.guestFirstNm) errors.guestFirstNm = "Required field";
        if(!data.guestLastNm) errors.guestLastNm = "Required field";
        if(!data.guestEmail) errors.guestEmail = "Required field";
        if(!data.guestMobile) errors.guestMobile = "Required field";
        if (!isOld && !data.country) errors.country = "Required field";
        errors.flightAirline = !validateAirilineOrFlight(data.flightAirline, data.no_flight_information) ? 'Required Field' : undefined;
        errors.flightNumber =
            !isOld &&
            !validateAirilineOrFlight(
                data.flightNumber,
                data.no_flight_information,
                true
            )
                ? "Cannot verify flight"
                : undefined;
        if(data.additionalCharges){
            let arrayErrors = []
            data.additionalCharges.map((item,index) => {
                let errorItem = {}
                let repeat = [];
                if(!item.aditionalId){
                    errorItem.aditionalId = "Required Field";
                } else {
                    repeat = _.filter(data.additionalCharges, (x) => {
                        if(x.aditionalId) return x.aditionalId == item.aditionalId;
                        return false;
                    });
                    if(1 < repeat.length){
                        errorItem.aditionalId = "This charge is repeated";
                    }
                }
                if(!item.Qcharge == undefined){
                    errorItem.Qcharge = "Required Field";
                } else if(item.Qcharge < 0){
                    errorItem.Qcharge = "The Quantity shouldn't be less than zero";
                }
                if(!item.amount) errorItem.amount = "Required field";
                arrayErrors[index] = errorItem;
            })
            if(arrayErrors.length){
                errors.additionalCharges = arrayErrors;
            }
        }
        if(typeAddress == 'from' || typeAddress == 'both'){
            errors.puAddress = "Please select an exact address";
        }
        if(typeAddress == 'to' || typeAddress == 'both'){
            errors.doAddress = "Please select an exact address";
        }

        let time = data.puTime;
        if (typeof data.puTime !== "object") {
            const convertTime = moment(data.puTime);
            time = {
                hour: `${convertTime.hour()}`,
                minutes: `${convertTime.minutes()}`,
            };
        }

        const { stops } = data;

        if (
            stops &&
            stops.length &&
            (data.hourlyDuration === null || data.hourlyDuration === undefined)
        ) {
            const firstStopIndex = 0;
            const lastStopIndex = stops.length - 1;
            const firstStop = stops[firstStopIndex];
            const lastStop = stops[lastStopIndex];

            const isAirport = (stop) =>
                stop.types && stop.types.includes("airport");
            const isSameLocation = (stop1, stop2) =>
                stop1.lat === stop2.lat && stop1.lng === stop2.lng;

            if (isSameLocation(from, firstStop)) {
                let messagePickUp =
                    "The pick up address cannot match the first stop address.";
                if (isAirport(from) || isAirport(firstStop)) {
                    messagePickUp = `${messagePickUp} Please specify specific Airport Terminal on the Notes under Passenger Info.`;
                }

                errors.puAddress = messagePickUp;
            } else if (isSameLocation(to, lastStop)) {
                let messageDropOff =
                    "The drop off address cannot match the last stop address.";
                if (isAirport(from) || isAirport(lastStop)) {
                    messageDropOff = `${messageDropOff} Please specify specific Airport Terminal on the Notes under Passenger Info.`;
                }

                errors.doAddress = messageDropOff;
            }
        } else if (
            from.lat &&
            to.lat &&
            from.lng &&
            to.lng &&
            from.lat === to.lat &&
            from.lng === to.lng &&
            (data.hourlyDuration === null || data.hourlyDuration === undefined)
        ) {
            errors.to =
                "Pick-up location cannot match the drop-off location for a transfer trip.";

            errors.from =
                "Pick-up location cannot match the drop-off location for a transfer trip.";
        }

        return {
            ...errors,
            stops: validateStops({
                stops,
                schedule: data.puDate,
                validateTime:
                    data.hourlyDuration !== null &&
                    data.hourlyDuration !== undefined,
                duration: { value: data.hourlyDuration },
                time,
            }),
        };
    },
})(ReservationEditForm);

const selector = formValueSelector('tripEditForm');
const mstp = (state) => {

    const reservation = selector(state, 'puAddress','doAddress','puDate', 'puTime', 'puTimeNoTimezone',
    'hourlyDuration','flightDetails','specialNotes','guestFirstNm','guestLastNm','guestMobile',
    'guestEmail','transactionAmount','totalRefund','totalCharges', 'newTransactionAmount',
    'additionalCharges', 'no_flight_information', "baseRateId",
     "stops");

    let newTotalCharges = 0;
    if(reservation.additionalCharges)
        newTotalCharges = reservation.additionalCharges.reduce( (total, current) => total + (current.amount ? parseFloat(current.amount) : 0), 0 );
    reservation.newTotal = (parseFloat(reservation.newTransactionAmount) + newTotalCharges).toFixed(2);

    return {
        ...state.tripEdition,
        reservation,
        no_flight_information: reservation.no_flight_information,
        from: state.tripEdition.from,
        to: state.tripEdition.to
    }
};

export default connect(mstp, { ...actions, change })(ReservationEditForm);
