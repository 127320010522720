import React from "react";
import PropTypes from "prop-types";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import moment from "moment";
import Grid from "../../Utils/Grid/materialTable";
import { marketingPromoCodes, marketingPromoCodesNew } from "../../../../routesConfiguration/paths";

export default class VehiclesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };
    constructor(props){
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.props.find();

        if (this.props.message) {
            this.props.alert.show(this.props.message);

            setTimeout(this.props.unsetMessage(), 6000);
        }
        if (this.tableRef && this.tableRef.current)
            this.tableRef.current.dataManager.changePageSize(10);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readPromo }) => readPromo))
                    this.props.find();
            }
        }
    }

    render() {
        const { data, loading, destroy, page, find, setSearch, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container material-livery-list">
                        {permission &&
                            permission.find(({ readPromo }) => !!readPromo) && (
                                <Grid
                                    tableRef={this.tableRef}
                                    title="Promo Codes"
                                    columns={[
                                        {
                                            title: "Code",
                                            field: "code",
                                        },
                                        {
                                            title: "Discount",
                                            field: "discount",
                                            render: (row) => `${row.discount}%`,
                                        },
                                        {
                                            title: "Start Date",
                                            field: "startDate",
                                            render: (row) =>
                                                moment(row.startDate).format("MM/DD/YYYY")
                                        },
                                        {
                                            title: "Expiration Date",
                                            field: "expDate",
                                            render: (row) =>
                                                moment(row.expDate).format("MM/DD/YYYY")
                                        },
                                        {
                                            title: "Actions",
                                            field: "_id",
                                            align: "center",
                                            render: (row) =>
                                                activeFormatter({
                                                    editar: permission.find(({ updatePromo }) => !!updatePromo) && marketingPromoCodes,
                                                    eliminar: permission.find(({ removePromo }) => !!removePromo) && destroy,
                                                })(row._id, row),
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: () =>
                                                permission && permission.find(({ createPromo }) => !!createPromo) && (
                                                    <Link
                                                        className="btn btn-primary px-2 py-0 align-self-end rounded-circle"
                                                        to={marketingPromoCodesNew}
                                                    >
                                                        <h3>+</h3>
                                                    </Link>
                                                ),
                                            isFreeAction: true,
                                        },
                                    ]}
                                    data={data.data}
                                    onSearchChange={setSearch}
                                    onPageChange={(p) => find(p + 1)}
                                    isLoading={loading}
                                    page={page - 1}
                                    totalCount={data.total}
                                    options={{
                                        pageSizeOptions: [],
                                        filtering: false,
                                        idSynonym: "_id",
                                        searchText: "Search by code",
                                        actionsColumnIndex: -1,
                                        pageSize: 10,
                                        headerStyle: {
                                            backgroundColor: "#D50032",
                                            color: "#FFF",
                                            fontSize: "1rem",
                                        },
                                        cellStyle: {
                                            fontSize: "1rem",
                                        },
                                    }}
                                />
                            )}
                    </div>
                </div>
            </div>
        );
    }
}
