import app from "../../libs/apiClient";
import { handleActions } from "redux-actions";
import Swal from 'sweetalert2';
import XLSX from "xlsx";
import numeral from 'numeral';
import moment from 'moment';
import { initialize as initializeForm } from "redux-form";
import { push } from "react-router-redux";
import { errorHandler } from '../../common/utility/constants';
import _ from "lodash";
import { reservationsAirlineUploads } from "../../routesConfiguration/paths";
import { actions as breadcrumbActions } from "./breadcrumb";

const LOADER = "SCHEDULE_LOADER";
const ITEM_SCHEDULE = "ITEM_SCHEDULE";
const SET_MODAL_ROUTE = "SET_MODAL_ROUTE";
const SET_LOADING_MODAL = "SET_LOADING_MODAL";
const LOADING_GRID = "LOADING_GRID_SCHEDULE";
const PAGE_SCHEDULE = "PAGE_SCHEDULE";
const DATA_SCHEDULE = "DATA_SCHEDULE";
const VIEW_HOTEL = "VIEW_HOTEL";
const SEARCH_GRID = "SEARCH_GRID_SCHEDULES";
const SAVE_VALUES_NEW_ROUTE = "SAVE_VALUES_NEW_ROUTE";
const READING_PROGRESS = "READING_PROGRESS_UPLOAD";

// code for errors in read file
const AIRLINE_FLIGHT_ERROR = { code: 1, message: 'No match airline or invalid flight number' };
const ROUTE_ERROR = { code: 2, message: "No route found" };
const LIVERY_ERROR = { code: 3, message: "Error on livery request" };

const service = app.service("/api/schedule");

// Actions
export const create = (data) => (dispatch) => {
    dispatch(setLoading(true));
    service.create(data)
        .then((response) => {
            Swal.fire({
                type: 'success',
                text: 'Your file has been uploaded, check the progress down',
            }).then(()=>{
                dispatch(find());
            })
        })
        .catch((e) => {
            if (e.errors && e.errors.length > 0) {
                const { errors } = e;
                Swal.fire({
                    type: "warning",
                    title: "Are you sure?",
                    text: `${errors[0].message}`,
                    focusConfirm: false,
                    showCancelButton: true,
                    confirmButtonColor: "#DF2710",
                    cancelButtonColor: "#6C757D",
                    cancelButtonText: "Cancel",
                    confirmButtonText: "Continue",
                }).then(({ value }) => {
                    if (errors.length > 1) {
                        Swal.fire({
                            type: "warning",
                            title: "Are you sure?",
                            text: `${errors[1].message}`,
                            focusConfirm: false,
                            showCancelButton: true,
                            confirmButtonColor: "#DF2710",
                            cancelButtonColor: "#6C757D",
                            cancelButtonText: "Cancel",
                            confirmButtonText: "Continue",
                        }).then(({ value: res2 }) => {
                            app.service("/api/schedule")
                                .create({
                                    _id: e.data._id,
                                    airline: e.data.airline,
                                    file: e.data.file,
                                    isVerified: true,
                                    confirm: res2,
                                })
                                .then(() => {
                                    if (res2) dispatch(find());
                                });
                        });
                    } else {
                        app.service("/api/schedule")
                            .create({
                                _id: e.data._id,
                                airline: e.data.airline,
                                file: e.data.file,
                                isVerified: true,
                                confirm: value,
                            })
                            .then(() => {
                                if (value) dispatch(find());
                            });
                    }
                });
            } else {
                dispatch(errorHandler(e));
            }
        })
        .finally(() => {
            dispatch(setLoading(false));
        });
};

const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoadingGrid(true));
    const { searchSchedule, data } = getStore().schedule;
    try {
        const params = {
            $schedules: true,
            $skip: (page - 1) * 10,
            $limit: 10,
            $sort: { _id: -1 },
            $populate: ['airline']
        }
        if(searchSchedule){
            let airlines = await app.service("/api/airline").find({ query: {
                $paginate: false,
                $or: [
                    {company: { $regex: _.escapeRegExp(searchSchedule), $options: 'i' }},
                    {code: { $regex: _.escapeRegExp(searchSchedule), $options: 'i' }},
                ],
                $select: ['_id']
            } });
            airlines = airlines.map(item => item._id);
            params.filters = {
                $or: [
                    { airports: { $regex: _.escapeRegExp(searchSchedule), $options: 'i' }},
                    { dates: { $regex: _.escapeRegExp(searchSchedule), $options: 'i' }},
                    { airline: { $in: airlines }},
                ]
            }
        }
        const response = await service.find({ query: params });
        if (response.data && response.data.length > 0) {
            for (let i = 0; i < response.data.length; i += 1) {
                if (data && data.data && data.data.length > 0) {
                    const index = data.data.findIndex(
                        (item) =>
                            JSON.stringify(item._id) ===
                            JSON.stringify(response.data[i]._id)
                    );
                    if (index !== -1 && data.data[index].current)
                        response.data[i].current = data.data[index].current;
                }
                if (!response.data[i].current && response.data[i].reloaded)
                    response.data[i].current = response.data[i].totalSuccess;
            }
        }
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch ( e ) {
        console.log(e);
    }
    dispatch(setLoadingGrid(false));
}

const viewResults = (id) => async (dispatch, getStore) => {
    dispatch({ type: SET_LOADING_MODAL, loadingModal: true });
    try {
        const response = await service.get(id);
        let result;
        if(typeof response.result === 'string')
            result = JSON.parse(response.result);
        else result = response.result;

        // if(result.data_rejected && result.data_rejected.length > 0){
        //     let missing_routes = [];
        //     for(let index = 0; index < result.data_rejected.length; index++){
        //         let from = result.data_rejected[index].data_file_extracted.from_location;
        //         let to = result.data_rejected[index].data_file_extracted.to_location;

        //         let foundInMissingRoutes = missing_routes.find( item =>
        //             (item.from === from && item.to === to) || (item.from === to && item.to === from)
        //         );
        //         if(foundInMissingRoutes === undefined &&
        //             (result.data_rejected[index].error && result.data_rejected[index].error.code === ROUTE_ERROR.code)
        //         ){
        //             let route = await app.service('/api/airline_route').find({ query: {
        //                 $or : [
        //                     { airline_hotelDescription: to, airline_airportDescription: from },
        //                     { airline_hotelDescription: from, airline_airportDescription: to }
        //                 ],
        //                 $paginate: false,
        //                 $populate: ['airportMeet']
        //             }});
        //             route = route.find( item => item && item.airportMeet.airlineCode === result.airline._id);
        //             let airport = await app.service('/api/airport').find({ query: { code: from.toUpperCase(), $paginate: false }});
        //             let fromAirport = airport && airport.length > 0 ? true : false;
        //             if(!airport || airport.length === 0)
        //                 airport = await app.service('/api/airport').find({ query: { code: to.toUpperCase(), $paginate: false }});
        //             missing_routes.push({ from, to, route, fromAirport, airport });
        //         }
        //     }
        //     result.missing_routes = missing_routes;
        // }

        result.status = response.status;

        console.log(result);
        const { updateCustomLabel } = breadcrumbActions;
        const { airline } = result;
        const time = moment(response.timeStamp).format("MM/YY");
        const label = `${airline.code} ${time}`;
        dispatch(updateCustomLabel(id, label));
        dispatch(setItem(result));
    } catch (error){
        console.log(error);
    } finally {
        dispatch({ type: SET_LOADING_MODAL, loadingModal: false });
    }
}

const getAirlines = search => dispatch => {
    const params = { query: { $paginate: false } };
    if(search)
        params.query.$or = [ { code: { $regex: _.escapeRegExp(search), $options: 'i' }}, { company: { $regex: _.escapeRegExp(search), $options: 'i' }} ];
    return app.service('/api/schedule').find(params).then(data => {
        if(data && data.data && data.data.length > 0){
            let results = data.data;
            for(let index = 0; index < results.length; index++ )
                results[index].label = `${results[index].code} - ${results[index].company}`

            results = results.sort((a, b) => a.label.localeCompare(b.label));
            return { options: results };
        }
        return [];
    }).catch ( error => {
        console.log(error);
        return [];
    })
}

const downloadExcel = (data, success = false) => (dispatch) => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Schedule Reservations",
        Subject: "Reservations",
        Author: "BBC EXPRESS",
        CreatedDate: new Date(),
    };

    // Head of workbook in spreadsheet
    let ws_data = [[ "livery ID", "Date", "PU Time", "PU Location", "DO Location", "# Pax", "Flight", "Group Code", "Price" ]];

    // Change if the data is for success trips or rejected trips
    let data_extracted;
    if(success)
        data_extracted = data.data_success;
    else
        data_extracted = data.data_rejected;

    // Create the array of data for add to Spreadsheet
    for(let index = 0; index < data_extracted.length; index++)
        ws_data.push([
            `${data_extracted[index].livery_trip_id !== null ? data_extracted[index].livery_trip_id : ''}`,
            `${moment(data_extracted[index].data_file_extracted.date_of_service).format('MM/DD/YYYY')}`,
            `${data_extracted[index].data_file_extracted.pick_up_time}`,
            `${data_extracted[index].data_file_extracted.from_location}`,
            `${data_extracted[index].data_file_extracted.to_location}`,
            `${data_extracted[index].data_file_extracted.number_of_passengers}`,
            `${data_extracted[index].data_file_extracted.arlineFlight
                ? data_extracted[index].data_file_extracted.arlineFlight
                : data_extracted[index].data_file_extracted.flight_number
                    ? data_extracted[index].data_file_extracted.airlineCode
                        ? data_extracted[index].data_file_extracted.airlineCode + ' ' + data_extracted[index].data_file_extracted.flight_number
                        : data.airline.code + ' ' + data_extracted[index].data_file_extracted.flight_number
                    : '' }`,
            `${data_extracted[index].db_airline_route ? data_extracted[index].db_airline_route.name : ''}`,
            `${data_extracted[index].contract_route_price
                ? '$ ' + numeral(data_extracted[index].contract_route_price.price).format("0,0.00")
                : ''}`
        ]);
    const ws = XLSX.utils.aoa_to_sheet(ws_data);

    // Columns width in spreadsheet
    let wscols = [ {wch:9}, {wch:10}, {wch:7}, {wch:30}, {wch:30}, {wch:5}, {wch:9}, {wch:11}, {wch:8} ];
    ws['!cols'] = wscols;

    // Add the data to new sheet in the spreadsheet
    XLSX.utils.book_append_sheet(wb, ws, "Transfer Reservations");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
        const view = new Uint8Array(buf); // create uint8array as viewer
        for (let i = 0; i < s.length; i++)
            view[i] = s.charCodeAt(i) & 0xff; // convert to octet
        return buf;
    }

    // Save the spreadsheet
    saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream", }),
        `${success ? 'Trips created.xlsx' : 'Trips Rejected.xlsx'}`
    );
}

const destroy = (id) => async (dispatch) => {
    dispatch(setLoadingGrid(true));
    try {
        await service.remove(id);
        dispatch(find());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(setLoadingGrid(false));
    }
}

const checkRoutes = () => async (dispatch, getStore) => {
    const { item: data } = getStore().schedule;
    if(data && data.missing_routes && data.missing_routes.length > 0){
        for(let index = 0; index < data.missing_routes.length; index++){
            let from = data.missing_routes[index].from;
            let to = data.missing_routes[index].to;
            let route = await app.service('/api/airline_route').find({ query: {
                $or : [
                    { airline_hotelDescription: to, airline_airportDescription: from },
                    { airline_hotelDescription: from, airline_airportDescription: to }
                ],
                $paginate: false,
                $populate: ['airportMeet']
            }});
            route = route.find( item => item && item.airportMeet.airlineCode === data.airline._id);
            data.missing_routes[index].route = route;
        }
    }
}

const openModalRoute = (item) => async (dispatch, getStore) => {
    const { item: data } = getStore().schedule;

    let airportMeet = await app.service('/api/airportMeet').find({ query: {
        airlineCode: data.airline._id,
        airportCode: item.airport && item.airport.length > 0 ? item.airport[0]._id : data.airline._id,
        $populate: ['airportCode'],
        $paginate: false,
    }});

    let hotel = await app.service('/api/hotel').find({ query: {
        landMark: item.fromAirport ? item.to : item.from,
        $paginate: false,
    }});

    data.airline.label = `${data.airline.code} - ${data.airline.company}`;

    if(airportMeet.length > 0)
        airportMeet[0].label = `${item.airport[0].code}`;

    if(hotel.length > 0)
        hotel[0].label = hotel[0].landMark;

    dispatch(initializeForm('airlineRouteForm',{
        airline: data.airline,
        airportMeet: airportMeet.length > 0 ? airportMeet[0] : null,
        hotel: hotel.length > 0 ? hotel[0] : null,
        airline_airportDescription: item.fromAirport ? item.from : item.to,
        airline_hotelDescription: item.fromAirport ? item.to : item.from,
        active: true,
    }))
    dispatch({ type: SET_MODAL_ROUTE, modalRoute: true });
}

const reLoadRejected = (id) => (dispatch, getStore) => {
    app.service('/api/schedule').patch(id, { reload: true }).then( response => {
        Swal.fire({
            type: "success",
            title: "Success!",
            text: "The data has been reloaded"
        }).then(()=>{
            dispatch(setData({ skip: 0, limit: 10, total: 0, data: [] }));
            dispatch(push(reservationsAirlineUploads))
        })
    }).catch( error => {
        dispatch(errorHandler(error));
    })
}

const openViewHotel = () => (dispatch, getStore) => {
    const { values } = getStore().form.airlineRouteForm;
    dispatch({ type: SAVE_VALUES_NEW_ROUTE, values });
    dispatch(initializeForm('hotelForm', { landMark: values.airline_hotelDescription }));
    dispatch({ type: VIEW_HOTEL, viewHotel: true });
}

const closeViewHotel = (submit = false, landMark) => async (dispatch, getStore) => {
    const { values } = getStore().schedule;
    if(submit){
        let hotel = await app.service('/api/hotel').find({ query: { landMark, $paginate: false }});
        if(hotel.length > 0 ){
            hotel[0].label = hotel[0].landMark;
            values.hotel = hotel[0];
        }
    }
    dispatch(initializeForm('airlineRouteForm', values));
    dispatch({ type: SAVE_VALUES_NEW_ROUTE, values: undefined });
    dispatch({ type: VIEW_HOTEL, viewHotel: false });
}

const killReadProcess = (id) => async (dispatch) => {
    let result = false;
    dispatch(setLoadingGrid(true));
    try {
        await service.patch(id, { cancel_process: true });
        result = true;
    } catch (e) {
        const msg = e.message;
        dispatch(errorHandler(e, null, { confirmButtonText: "Close", confirmButtonColor: "#D50032", html: `<h5>${msg}</h5>` }));
    } finally {
        dispatch(setLoadingGrid(false));
    }
    return result;
}

export const updateUploadProcess = (dataUpdated) => (dispatch, getStore) => {
    const { data, readingProgress } = getStore().schedule;
    if(data && data.data && data.data.length > 0 ){
        let foundIndex = data.data.findIndex(item => JSON.stringify(item._id) === JSON.stringify(dataUpdated._id));
        if(foundIndex !== -1){
            data.data[foundIndex] = {
                ...data.data[foundIndex],
                ...dataUpdated,
            }
            dispatch(setData(data));
            let newReadingProgress = dataUpdated.current || 0;
            dispatch({ type: READING_PROGRESS, readingProgress: newReadingProgress });
        }
    }
}

const setItem = item => ({
    type: ITEM_SCHEDULE,
    item,
})

const setLoading = loading => ({
    type: LOADER,
    loading,
})

const setData = (data) => ({
    type: DATA_SCHEDULE,
    data
})

const setPage = (page) => ({
    type: PAGE_SCHEDULE,
    page,
})

const setLoadingGrid = loadingGrid => ({
    type: LOADING_GRID,
    loadingGrid
})

const closeModalRoute = () => (dispatch) => {
    dispatch({ type: SET_MODAL_ROUTE, modalRoute: false });
}

const setSearch = (search) => (dispatch) => {
    dispatch({ type: SEARCH_GRID, searchSchedule: search });
    dispatch(find());
}

export const actions = {
    find,
    create,
    destroy,
    setSearch,
    viewResults,
    getAirlines,
    openModalRoute,
    killReadProcess,
    closeModalRoute,
    downloadExcel,
    openViewHotel,
    closeViewHotel,
    checkRoutes,
    reLoadRejected,
}

// Reducers
const reducers = {
    [LOADER]: (state, { loading }) => ({ ...state, loading }),
    [ITEM_SCHEDULE]: (state, { item }) => ({ ...state, item }),
    [SET_MODAL_ROUTE]: (state, { modalRoute }) => ({ ...state, modalRoute }),
    [SET_LOADING_MODAL]: (state, { loadingModal }) => ({ ...state, loadingModal }),
    [LOADING_GRID]: ( state, { loadingGrid }) => ({ ...state, loadingGrid }),
    [PAGE_SCHEDULE]: ( state, { page }) => ({ ...state, page }),
    [DATA_SCHEDULE]: ( state, { data }) => ({ ...state, data }),
    [VIEW_HOTEL]: ( state, { viewHotel }) => ({ ...state, viewHotel }),
    [SAVE_VALUES_NEW_ROUTE]: (state, { values }) => ({ ...state, values }),
    [READING_PROGRESS]: (state, { readingProgress }) => ({ ...state, readingProgress }),
    [SEARCH_GRID]: (state, { searchSchedule }) => ({ ...state, searchSchedule }),
};

export const initialState = {
    loading: false,
    modalRoute: false,
    loadingModal: false,
    loadingGrid: false,
    viewHotel: false,
    readingProgress: 0,
    page: 1,
    searchSchedule: undefined,
    data:{
        total: 0,
        skip: 0,
        limit: 10,
        data: []
    },
    values: undefined,
    item: { },
};

export default handleActions(reducers, initialState);
