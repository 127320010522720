import React from 'react';
import Pagination from '../Utils/pagination';
import LoadMask from '../Utils/LoadMask';
import moment from 'moment';


const LogDetails = (props) => {

    const { dataLogs, find, page, loading } = props;

    return (
        <div className="d-flex flex-wrap w-100">
            <div className="col-12 mt-4">
                <h2 className="text-left"> TRIP CHANGES </h2>
                <hr className="w-100"/>
            </div>
            <div className="col-12 pb-4">
                <LoadMask loading={loading} dark blur>
                    <div className="table-responsive">
                        <table className="table table-bordered table-rowspan-striped">
                            <thead>
                                <tr>
                                    <td className="bold"> Log Date/Time </td>
                                    <td className="bold"> Event </td>
                                    <td className="bold"> Field </td>
                                    <td className="bold"> From Data </td>
                                    <td className="bold"> To Data </td>
                                    <td className="bold"> User </td>
                                </tr>
                            </thead>
                            {dataLogs && dataLogs.total > 0
                                ? ( dataLogs.data.map((item, index)=>{
                                        return (
                                            <tbody key={index}>
                                                {item.changes.map((change, i) => (
                                                    <tr key={i}>
                                                        {i === 0 && (
                                                            <React.Fragment>
                                                                <td rowSpan={item.changes.length}
                                                                    className="align-middle"
                                                                >
                                                                    {moment(item.createdAt).format('L LT')}
                                                                </td>
                                                                <td rowSpan={item.changes.length}
                                                                    className="align-middle"
                                                                >
                                                                    {item.event}
                                                                </td>
                                                            </React.Fragment>
                                                        )}
                                                        <td> {change.field} </td>
                                                        <td> {change.oldValue} </td>
                                                        <td> {change.newValue} </td>
                                                        {i === 0 && (
                                                            <td rowSpan={item.changes.length}
                                                                className="align-middle"
                                                            >
                                                                {item.user ? `${item.user.firstName} ${item.user.lastName}` : ''}
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        )
                                    })
                                ) : (
                                    <tbody>
                                        <tr>
                                            <td colSpan="6" className="p-4">
                                                <h4 className="bold text-center">No data</h4>
                                            </td>
                                        </tr>
                                    </tbody>
                                )
                            }
                        </table>
                    </div>
                    <Pagination
                        total={dataLogs.total}
                        page={page}
                        find={find}
                    />
                </LoadMask>
            </div>
        </div>
    )
}

export default LogDetails;
