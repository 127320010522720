import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/crewAdmin/crewRoutes';
import { getAirline } from '../../../../../../../redux/modules/crewAdmin/airlines';
import IconAngleLeft from '../../../../../../icons/angleLeftIcon';
import { change } from 'redux-form';
import LoadMask from '../../../../../Utils/LoadMask';
import RouteForm from '../create/routeForm';
import swal from 'sweetalert2';


class Container extends React.Component {
    componentDidMount() {
        if (!this.props.airline)
            this.props.getAirline(this.props.match.params.airlineId);
        this.props.load2Update(this.props.match.params.id, this.props.match.params.airlineId);
    }

    cancel = (fromBackBtn = false) => {
        if (fromBackBtn && this.props.idFormChanged) {
            swal.fire({
                title: "Are you sure?",
                text: "All changes will be lost. Would you like to continue or stay here?",
                type: "warning",
                showCancelButton: true,
                cancelButtonColor: "#343a40",
                cancelButtonText: "STAY HERE",
                confirmButtonColor: "#D50032",
                confirmButtonText: "CONTINUE",
            }).then(({ value }) => {
                if (value) {
                    this.props.cleanForm();
                    this.props.history.goBack();
                }
            })
        } else {
            this.props.cleanForm();
            this.props.history.goBack();
        }
    }

    onSubmit = (values) => {
        values.airline = this.props.match.params.airlineId;
        this.props.update(values).then(result =>{
            if(result) this.cancel();
        });
    }    

    render() {
        const { loadingForm, userInfo: { permission } } = this.props;

        return (
            <div className="h-100 crewContainer px-3">
                <div className="d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>this.cancel(true)}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <div className="flex-1 d-flex flex-wrap justify-content-center">
                        <div className="col-12 col-xl-7 my-2 mt-xl-4 pt-xl-2">
                            <div className="rounded h-100 shadow border border-dark">
                                <div className="d-flex flex-wrap pt-3 px-3">
                                    <div className="col-12 mb-3">
                                        <h3 className="text-left">Edit Route</h3>
                                    </div>
                                    <LoadMask loading={loadingForm} blur>
                                        <RouteForm
                                            {...this.props}
                                            airlineId={this.props.match.params.airlineId}
                                            onSubmit={this.onSubmit}
                                            cancel={this.cancel}
                                            showActiveField
                                            readOnly={permission.find(({ updateAirlineCrew }) => updateAirlineCrew == true) ? false : true}
                                        />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.crewRoutes,
    airline: state.crewAdmin.airlines.item,
    userInfo: state.login.userInfo,
    idFormChanged:
        state.form.airlineRouteForm &&
        JSON.stringify(state.form.airlineRouteForm.initial) !==
            JSON.stringify(state.form.airlineRouteForm.values),
});

const mdtp = {
    ...actions,
    getAirline,
    change,
};

export default connect(mstp, mdtp)(Container);
