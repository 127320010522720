import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import format from '../../../utility/format';
import moment from 'moment';
import numeral from 'numeral';
import Modal from 'react-responsive-modal';
import LoadMask from '../../Utils/LoadMask';
import RefundModal from './RefundModal';
import TripEditModal from './EditModal';
import IconSync from '../../../icons/sync';
import { Collapse, Tooltip } from 'reactstrap';
import IconPlus from '../../../icons/plus';
import IconMinus from '../../../icons/minus';
import VoidModal from './voidModal';
import { stopNames } from '../../stops/Stops';


const AddChargesCollapse = (props) => {

    const [isOpen, setAddCharges] = useState(false);
    const { children, total = 0, trip } = props;

    return (
        <div className="my-2 d-flex">
            <div className="px-0 col-6 d-flex justify-content-end align-items-end">
                <div className="">
                    <div className="px-0 text-right">
                        <b style={{ fontSize: '1.35rem' }}>Add. Charges:</b>
                    </div>
                    <Collapse isOpen={isOpen}>
                        <div className="d-flex flex-column justify-content-end align-items-center">
                            <div className="w-100 rounded-left border border-right-0 cartListShadow p-2">
                                {trip.totalTolls ? (
                                    <div className="flex-1 text-left text-secondary">
                                        <small>
                                            Tolls:
                                        </small>
                                    </div>
                                ) : ""}
                                {trip.additionalCharges.length > 0 && trip.additionalCharges.map((aditional, i)=>(
                                    <div className="flex-1 text-left text-secondary" key={i}>
                                        <small>
                                            {aditional.aditionalId.title}:
                                        </small>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
            <div className="d-flex align-items-end justify-content-start">
                <div className="">
                    <div className="text-left">
                        <span
                            className="p-0 clickable d-flex align-items-center"
                            onClick={()=>setAddCharges(!isOpen)}
                        >
                            &nbsp;${total}
                            {isOpen ? <IconMinus className="smallIcon p-1 text-secondary"/> : <IconPlus className="smallIcon p-1 text-secondary"/>}
                        </span>
                    </div>
                    <Collapse isOpen={isOpen}>
                        <div className="d-flex flex-column justify-content-start align-items-center cartListShadowBottom">
                            <div className="w-100 rounded-right border border-left-0 p-2">
                                {trip.totalTolls ? (
                                    <div className="text-right text-secondary">
                                        <small>
                                            ${numeral(trip.totalTolls).format('0,0.00')}
                                        </small>
                                    </div>
                                ) : ''}
                                {trip.additionalCharges.length > 0 && trip.additionalCharges.map((aditional, i)=>(
                                    <div className=" text-right text-secondary" key={i}>
                                        <small>
                                            ${numeral(aditional.amount).format('0,0.00')}
                                        </small>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    )
}

const TextWithTooltip = (props) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { text, id, className, children } = props;

    return (
        <React.Fragment>
            {children ? children : (
                <div className={`${className}`} id={id}>
                    {text}
                </div>
            )}
            <Tooltip
                hideArrow
                placement="bottom"
                target={id}
                innerClassName="mw-100"
                isOpen={tooltipOpen} toggle={()=>setTooltipOpen(!tooltipOpen)}
            >
                {text}
            </Tooltip>
        </React.Fragment>
    )
}

const Details = (props) => {

    const [isOpenModalCancel, setOpenModalCancel] = useState(false);
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);
    const [tripItem, setTripItem] = useState(null);
    const [modalVoid, setModalVoid] = useState(false);
    const { cartItem, resendEmail, linkPoints } = props;

    const openModalCancel = (trip) => {
        setTripItem(trip);
        setOpenModalCancel(true);
    }

    const closeModalCancel = (reloadData = false) => {
        if(reloadData )
            props.getCart(cartItem._id);
        setTripItem(null);
        setOpenModalCancel(false);
    }

    const openModalEdit = (trip) => {
        setTripItem(trip);
        setOpenModalEdit(true);
    }

    const closeModalEdit = () => {
        setTripItem(null);
        setOpenModalEdit(false);
    }

    const firstTransactionDone = cartItem && cartItem.trips && cartItem.trips.find(({ transactions }) => transactions && transactions.find(item => item.isInitial && item.transactionStatus == 'settledSuccessfully') ) !== undefined;
    const ableToCancelTrip = (cartItem && cartItem.trips && cartItem.trips.length == 1) || firstTransactionDone;

    return (
        <div className="d-flex flex-column">
            <Modal
                open={modalVoid}
                onClose={() => setModalVoid(false)}
            >
                <LoadMask loading={props.loadingCancel} blur>
                    <div className="m-4 d-flex flex-wrap">
                        <div className="col-12">
                            <h3 className="mb-5"> Cancel </h3>
                        </div>
                        <VoidModal
                            onSubmit={(values) => {
                                props.voidCart(cartItem._id, values, () => setModalVoid(false));
                            }}
                            closeModal={() => setModalVoid(false)}
                        />
                    </div>
                </LoadMask>
            </Modal>
            <Modal
                open={isOpenModalCancel}
                showCloseIcon={false}
                onClose={() => closeModalCancel()}
            >
                <LoadMask loading={props.loadingCancel} dark blur>
                    <div className="m-4 d-flex flex-wrap">
                        <div className="col-12">
                            <h3 className="mb-5 text-left">Refund or Cancel</h3>
                        </div>
                        <RefundModal
                            onSubmit={() => {
                                props.postRefund(tripItem._id, () => closeModalCancel(true));
                            }}
                            initialValues={{
                                cancel: ableToCancelTrip ? "refund" : "cancel",
                            }}
                            closeModal={() => {
                                closeModalCancel();
                            }}
                            reservation={tripItem}
                        />
                    </div>
                </LoadMask>
            </Modal>

            <Modal
                open={isOpenModalEdit}
                showCloseIcon={false}
                onClose={() => closeModalEdit()}
                classNames={{ modal: "modal-xl" }}
            >
                <div className="p-3 w-100">
                    <LoadMask loading={props.loadingEdit} blur>
                        <TripEditModal
                            trip={tripItem}
                            firstTransactionDone={firstTransactionDone}
                            onSubmit={(values)=>{
                                props.editReservation(values).then(data=>{
                                    if(data){
                                        closeModalEdit();
                                        props.getCart(cartItem._id);
                                    }
                                })
                            }}
                            cancel={()=>closeModalEdit()}
                        />
                    </LoadMask>
                </div>
            </Modal>

            <div className="d-flex flex-column-reverse flex-md-row mt-2 mb-5">
                <div className="flex-1 d-flex align-items-end">
                    <h2> BOOKING DETAIL </h2>
                </div>
                {cartItem.canceled ? (
                    <TextWithTooltip
                        text={cartItem.noteCanceled}
                        id="voidTransactionMsg"
                    >
                        <div id="voidTransactionMsg" className="flex-1 d-flex align-items-end justify-content-center">
                            <div className="bg-primary btn text-white px-5 py-2">
                                VOID TRANSACTION
                            </div>
                        </div>
                    </TextWithTooltip>
                ) : !firstTransactionDone ? (
                    <TextWithTooltip
                        text="Credit card transaction has not cleared. To make changes, void the transaction and book new trips."
                        id="voidCartMsg"
                    >
                        <div id="voidCartMsg" className="flex-1 d-flex align-items-end justify-content-center">
                            <button type="button" className="bg-primary btn text-white px-5 py-2"
                                onClick={() => setModalVoid(true)}
                            >
                                VOID TRANSACTION
                            </button>
                        </div>
                    </TextWithTooltip>
                ) : ""}
                <div className="flex-1 d-flex flex-column">
                    <div className="d-flex justify-content-end my-2">
                        <i> Booked on {moment(cartItem.createdAt).format('MM/DD/YYYY LT')} </i>
                    </div>
                    <div className="d-flex justify-content-end clickable my-2" onClick={()=>resendEmail(cartItem._id)}>
                        <div className="">
                            <IconSync className="smallIcon p-1 text-primary"/>
                        </div>
                        Resend Confirmation
                    </div>
                </div>
            </div>
            <div className="d-flex my-2">
                <h4> CONTACT INFORMATION </h4>
                {!cartItem.isPointsLinked && !cartItem.userId ? (
                    <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        style={{
                            height: "max-content",
                            margin: "0px 5px",
                            padding: "2px 5px",
                        }}
                        onClick={linkPoints}
                    >
                        <u>Link to account</u>
                    </button>
                ) : (
                    <span
                        className="mx-3 badge badge-secondary d-flex align-items-center justify-content-center"
                        style={{ background: "#cbe8ba", color: "#557439" }}
                    >
                        Points linked
                    </span>
                )}
            </div>
            <div className="d-flex flex-column my-2">
                <div className="d-flex flex-column flex-lg-row">
                    <div className="flex-1 d-flex flex-column">
                        <div className="d-flex my-2">
                            <b>Full Name:&nbsp;</b>
                            <span>{cartItem.contactFirstNm} {cartItem.contactLastNm}</span>
                        </div>
                        <div className="d-flex my-2">
                            <b>Email:&nbsp;</b>
                            <span>{cartItem.contactEmail}</span>
                        </div>
                        <div className="d-flex my-2">
                            <b>Phone Number:&nbsp;</b>
                            <span>{format.formatPhone(cartItem.contactMobile, cartItem.contactCountry)}</span>
                        </div>
                    </div>
                    <div className="flex-1 d-flex flex-column">
                        <div className="d-flex my-2">
                            <b>Address:&nbsp;</b>
                            <span>{cartItem.contactAddress}</span>
                        </div>
                        <div className="d-flex my-2">
                            <b>Address 2:&nbsp;</b>
                            <span>{cartItem.contactStreet2}</span>
                        </div>
                        <div className="d-flex my-2">
                            <b>City, State, Zip:&nbsp;</b>
                            <span>{cartItem.contactCity}, {cartItem.contactState}, {cartItem.contactZip}</span>
                        </div>
                    </div>
                </div>
                <div className="">
                    <hr style={{ borderBottom: "3px solid #C0C0C0" }}/>
                </div>
                <div className="d-flex my-2">
                    <h4> TRIPS </h4>
                </div>
                {cartItem.trips && cartItem.trips.length > 0 ? cartItem.trips.map((trip, i)=>{
                    const totalAdditionalCharges = trip.totalTolls + (trip.totalAditionalCharges || 0);

                    return (
                        <div className="border border-dark rounded cartListShadow p-3 my-2" key={i}>
                            <div className="d-flex flex-wrap pt-2">
                                <div className="col-12 col-md-7">
                                    <div className="d-flex my-2 my-md-3">
                                        <div className="flex-1">
                                            <b>Confirmation:&nbsp;</b>
                                        </div>
                                        <div className="" style={{ flex: 2 }}>
                                            <span>{trip.confirmationNo}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex my-2 my-md-3">
                                        <div className="flex-1">
                                            <b>Date/Time:&nbsp;</b>
                                        </div>
                                        <div className="" style={{ flex: 2 }}>
                                            <span>{moment(trip.puTimeNoTimezone).format('MM/DD/YYYY LT')}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex my-2 my-md-3">
                                        <div className="flex-1">
                                            <b>Pickup Address:&nbsp;</b>
                                        </div>
                                        <TextWithTooltip
                                            className = "flex-2 text-truncate"
                                            text = {trip.puAddress}
                                            id = {`textToolTipPick${i}`}
                                        />
                                    </div>
                                    {trip.stops ? trip.stops.filter(({ type }) => type === "S").map((stop, index) => (
                                        <div className="d-flex my-2 my-md-3" key={index}>
                                            <div className="flex-1">
                                                <b>{stopNames[index] ? `${stopNames[index]} stop` : `Stop#${index + 1}`}</b>
                                            </div>
                                            <TextWithTooltip
                                                className = "flex-2 text-truncate"
                                                text = {stop.street}
                                                id = {`textToolTipStop${index}${i}`}
                                            />
                                    </div>
                                    )): null}
                                    <div className="d-flex my-2 my-md-3">
                                        <div className="flex-1">
                                            <b>Dropoff Address:&nbsp;</b>
                                        </div>
                                        <TextWithTooltip
                                            className = "flex-2 text-truncate"
                                            text = {trip.doAddress}
                                            id = {`textToolTipDrop${i}`}
                                        />
                                    </div>
                                    <div className="d-flex my-2 my-md-3">
                                        <div className="flex-1">
                                            <b>Flight:&nbsp;</b>
                                        </div>
                                        <div className="flex-2">
                                            <span>
                                                {(
                                                    () => {
                                                        if (!trip.flightAirline) return "NA";
                                                        const { code } = trip.flightAirline;
                                                        const { flightNumber } = trip;
                                                        if (!flightNumber) return `${code} TBD`;
                                                        const number = typeof flightNumber === "object" ? flightNumber.flightNumber : flightNumber;
                                                        return `${code} ${number}`;
                                                    }
                                                )()}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex my-2 my-md-3">
                                        <div className="flex-1">
                                            <b>Vehicle Ordered:&nbsp;</b>
                                        </div>
                                        <div className="flex-2">
                                            <span>{trip.vehicleType}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex my-2 my-md-3">
                                        <div className="flex-1">
                                            <b>Passenger:&nbsp;</b>
                                        </div>
                                        <div className="d-flex flex-column" style={{ flex: 2 }}>
                                            <span>{trip.guestFirstNm} {trip.guestLastNm}</span>
                                            <span>{format.formatPhone(trip.guestMobile, trip.country)} </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 d-flex flex-column flex-md-row">
                                    <div className="flex-2">
                                        <div className="py-2" style={{ background: '#cccccc' }}>
                                            <div className="d-flex" style={{ lineHeight: 0.75 }}>
                                                <div className="flex-1 text-right">
                                                    <b style={{ fontSize: '1.35rem' }}>Base Fare:</b>
                                                </div>
                                                <div className="flex-1 d-flex align-items-end justify-content-start">
                                                    <span>&nbsp;${numeral(trip.tripPrice).format('0,0.00')}</span>
                                                </div>
                                            </div>
                                            {trip.hourlyDuration ? (
                                                <div className="d-flex">
                                                    <i className="flex-1 text-right">
                                                        <small> (${numeral(trip.tripPrice / trip.hourlyDuration).format('0,0.00')} x {trip.hourlyDuration} hr.) </small>
                                                    </i>
                                                    <div className="flex-1"></div>
                                                </div>
                                            ) : ''}
                                        </div>
                                        <div className="my-2 my-md-3">
                                            <div className="d-flex" style={{ lineHeight: 0.75 }}>
                                                <div className="flex-1 text-right">
                                                    <b style={{ fontSize: '1.35rem' }}>Gratuity:</b>
                                                </div>
                                                <div className="flex-1 d-flex align-items-end justify-content-start">
                                                    <span>&nbsp;${trip.gratuity}</span>
                                                </div>
                                            </div>
                                            <div className="d-flex">
                                                <i className="flex-1 text-right">
                                                    <small>({trip.gratuityPercent}%)&nbsp;</small>
                                                </i>
                                                <div className="flex-1"></div>
                                            </div>
                                        </div>
                                        {totalAdditionalCharges > 0 ? (
                                            <AddChargesCollapse
                                                total={totalAdditionalCharges}
                                                trip={trip}
                                            />
                                        ) : ''}
                                        {trip.transactionDiscount && trip.transactionDiscount > 0 ? (
                                            <div className="d-flex my-2 my-md-3">
                                                <div className="flex-1 text-right">
                                                    <b style={{ fontSize: '1.35rem' }}>Discounts:</b>
                                                </div>
                                                <div className="flex-1 d-flex align-items-end justify-content-start">
                                                    <span>&nbsp;-${trip.transactionDiscount}</span>
                                                </div>
                                            </div>
                                        ) : ''}
                                        {trip.totalRefund > 0 ? (
                                            <div className="d-flex my-2 my-md-3">
                                                <div className="flex-1 text-right">
                                                    <b style={{ fontSize: '1.35rem' }}>Refunds:</b>
                                                </div>
                                                <div className="flex-1 d-flex align-items-end justify-content-start">
                                                    <span>&nbsp;-${trip.totalRefund}</span>
                                                </div>
                                            </div>
                                        ) : ''}
                                        <div className="mt-2" style={{ borderTop: "3px solid #C0C0C0" }}></div>
                                        <div className="d-flex my-2 my-md-3">
                                            <div className="flex-1 text-right">
                                                <b style={{ fontSize: '1.35rem' }}>Total:</b>
                                            </div>
                                            <div className="flex-1 d-flex align-items-end justify-content-start">
                                                <span>&nbsp;${numeral(trip.transactionAmount - (trip.totalRefund || 0) + (trip.totalAditionalCharges || 0)).format('0,0.00')}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-1 pt-2 py-md-0">
                                        <div className="d-flex flex-column flex-md-row justify-content-end">
                                            <div className="bg-secondary px-4 py-1 m-1 text-center text-white" style={{ borderRadius: '35px' }}>
                                                {trip.hourlyDuration ? `${trip.hourlyDuration} Hrs` : `${Number(trip.distance).toFixed(2)} Mi.`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap pb-2">
                                <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-md-start">
                                    {trip.liveryNo ? (
                                        <span className="bg-secondary text-white py-1 px-4 m-1 text-center" style={{ borderRadius: "35px" }}>{trip.liveryNo}</span>
                                    ) : ''}
                                </div>
                                    <div className="col-12 col-md-6 d-flex flex-column flex-md-row justify-content-md-end">
                                        {!cartItem.canceled && !trip.canceled && firstTransactionDone && (
                                            <span onClick={()=>openModalCancel(trip)}
                                                className="clickable bg-danger py-1 px-4 text-white m-1 text-center"
                                                style={{ borderRadius: '35px' }}
                                            >
                                                CXL TRIP
                                            </span>
                                        )}
                                        {!cartItem.canceled && !trip.canceled && (
                                            <span onClick={()=>openModalEdit(trip)}
                                                className="clickable bg-warning py-1 px-4 m-1 text-center"
                                                style={{ borderRadius: '35px' }}
                                            >
                                                EDIT
                                            </span>
                                        )}
                                    </div>
                            </div>
                        </div>
                    )
                }) : ''}

                <div className="">
                    <hr style={{ borderBottom: "3px solid #C0C0C0" }}/>
                </div>
                <div className="d-flex my-2">
                    <h4> PAYMENT </h4>
                </div>
                <div className="border border-dark rounded cartListShadow p-3 my-2">
                    <div className="d-flex flex-wrap">
                        <div className="col-12 col-md-6">
                            <div className="my-2 my-md-3">
                                <b> Name: </b>
                                <span> {cartItem.cardHolder} </span>
                            </div>
                            <div className="my-2 my-md-3">
                                <b> Billing Address: </b>
                                <span> {cartItem.billing_address} </span>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 px-2 px-md-0">
                            <div className="px-2 my-2 my-md-3">
                                <b> Payment Method: </b>
                                <span> {cartItem.cardType} {cartItem.cardNumber} </span>
                            </div>
                            <div className="d-flex">
                                <div className="p-2" style={{ background: "#cbe8ba" }}>
                                    <b> Total Charged: </b>
                                    <span>
                                        ${cartItem.trips && numeral(cartItem.trips.reduce((total,current) =>
                                                total + (current.transactionAmount - (current.totalRefund || 0) +
                                                (current.totalAditionalCharges || 0)
                                            ), 0)).format('0,0.00')
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Details;
