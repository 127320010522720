import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Update from "./TourUpdate";
import { update, load2Update } from "../../../../redux/modules/tours";

const selector = formValueSelector("tour");
const mstp = (state) => ({
    loading: state.tours.loading,
    photo: selector(state, "photo"),
    hours: selector(state, "hours"),
    userInfo: state.login.userInfo,
});

const mdtp = { update, load2Update };

export default connect(mstp, mdtp)(Update);
