import { handleActions } from "redux-actions";
import app from "../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../common/utility/constants';
const types = {
    airline: 0,
    airport: 1,
    hotel: 2,
    meeting: 3,
    airlineAccount: 4,
    airlineRoute: 5,
    airlineAirportAccount: 6,
}

const LOADING = "LOADING_CREW_ADMIN";
const LOADING_UPDATE = "LOADING_UPDATE";
const SET_AIRPORT_DATA = "SET_AIRPORT_DATA";
const SET_AIRLINE_DATA = "SET_AIRLINE_DATA";
const SET_MEETING_DATA = "SET_MEETING_DATA";
const SET_HOTEL_DATA = "SET_HOTEL_DATA";
const SET_AIRLINE_ROUTE_DATA = "SET_AIRLINE_ROUTE_DATA";
const SET_AIRLINE_ACCOUNT_DATA = "SET_AIRLINE_ACCOUNT_DATA";
const SET_AIRLINE_AIRPORT_ACCOUNT_DATA = "SET_AIRLINE_AIRPORT_ACCOUNT_DATA";
const SET_PAGE_AIRLINE = "SET_PAGE_AIRLINE";
const SET_PAGE_AIRPORT = "SET_PAGE_AIRPORT";
const SET_PAGE_MEETING = "SET_PAGE_MEETING";
const SET_PAGE_HOTEL = "SET_PAGE_HOTEL";
const SET_PAGE_AIRLINE_ACCOUNT = "SET_PAGE_AIRLINE_ACCOUNT";
const SET_PAGE_AIRLINE_AIRPORT_ACCOUNT = "SET_PAGE_AIRLINE_AIRPORT_ACCOUNT";
const SET_PAGE_AIRLINE_ROUTE = "SET_PAGE_AIRLINE_ROUTE";
const SET_SINGLE_DATA_AIRLINE = "SET_SINGLE_DATA_AIRLINE";
const SET_SINGLE_DATA_AIRPORT = "SET_SINGLE_DATA_AIRPORT";
const SET_SINGLE_DATA_MEETING = "SET_SINGLE_DATA_MEETING";
const SET_SINGLE_DATA_HOTEL = "SET_SINGLE_DATA_HOTEL";
const SET_SINGLE_DATA_AIRLINE_ACCOUNT = "SET_SINGLE_DATA_AIRLINE_ACCOUNT";
const SET_SINGLE_DATA_AIRLINE_AIRPORT_ACCOUNT = "SET_SINGLE_DATA_AIRLINE_AIRPORT_ACCOUNT";
const SET_SINGLE_DATA_AIRLINE_ROUTE = "SET_SINGLE_AIRLINE_ROUTE";
const ACCOUNTS_NAMES = "ACCOUNTS_NAMES";
const SET_SEARCH_AIRLINE = "SET_SEARCH_AIRLINE";
const SET_SEARCH_AIRPORT = "SET_SEARCH_AIRPORT";
const SET_SEARCH_AIRPORT_MEET = "SET_SEARCH_AIRPORT_MEET";
const SET_SEARCH_HOTEL = "SET_SEARCH_HOTEL";
const SET_SEARCH_AIRLINE_ACCOUNT = "SET_SEARCH_AIRLINE_ACCOUNT";
const SET_SEARCH_AIRLINE_AIRPORT_ACCOUNT = "SET_SEARCH_AIRLINE_AIRPORT_ACCOUNT";
const SET_SEARCH_AIRLINE_ROUTE = "SET_SEARCH_AIRLINE_ROUTE";

const actionTypes = [
    {
        type: types.airline,
        service: app.service('/api/airline'),
        form: "airlineForm",
        pageVar: "pageAirline",
        searchVar: "searchAirline",
        actionTypeSearch: SET_SEARCH_AIRLINE,
        actionTypeData: SET_AIRLINE_DATA,
        actionTypePage: SET_PAGE_AIRLINE,
        actionTypeSingleData: SET_SINGLE_DATA_AIRLINE,
        searchOptions: [{field: 'company'}, {field: 'code'}, { field: 'liveryId', number: true }],
    },
    {
        type: types.airport,
        service: app.service('/api/airport'),
        form: "airportForm",
        pageVar: "pageAirport",
        searchVar: "searchAirport",
        actionTypeSearch: SET_SEARCH_AIRPORT,
        actionTypeData: SET_AIRPORT_DATA,
        actionTypePage: SET_PAGE_AIRPORT,
        actionTypeSingleData: SET_SINGLE_DATA_AIRPORT,
        searchOptions: [{ field: 'name' }, { field: 'description' }, { field: 'code' }]
    },
    {
        type: types.meeting,
        service: app.service('/api/airportMeet'),
        form: "meetingProcedureForm",
        pageVar: "pageMeeting",
        searchVar: "searchMeeting",
        label: { airportCode:['code','name'], airlineCode: ['code', 'company'] },
        actionTypeSearch: SET_SEARCH_AIRPORT_MEET,
        actionTypeData: SET_MEETING_DATA,
        actionTypePage: SET_PAGE_MEETING,
        actionTypeSingleData: SET_SINGLE_DATA_MEETING,
        queryOptions: { $populate: ['airportCode', 'airlineCode']}
    },
    {
        type: types.hotel,
        service: app.service('/api/hotel'),
        form: "hotelForm",
        pageVar: "pageHotel",
        searchVar: "searchHotel",
        actionTypeSearch: SET_SEARCH_HOTEL,
        actionTypeData: SET_HOTEL_DATA,
        actionTypePage: SET_PAGE_HOTEL,
        actionTypeSingleData: SET_SINGLE_DATA_HOTEL,
        searchOptions: [{ field: 'landMark'}, {field: 'street'}, {field:'city'}, {field:'state'}, {field:'zip'}]
    },
    {
        type: types.airlineAccount,
        service: app.service('/api/airline_account'),
        form: "airlineAccountForm",
        pageVar: "pageAirlineAccount",
        searchVar: "searchAirlineAccount",
        label: { airline: ['code', 'company'] },
        actionTypeSearch: SET_SEARCH_AIRLINE_ACCOUNT,
        actionTypeData: SET_AIRLINE_ACCOUNT_DATA,
        actionTypePage: SET_PAGE_AIRLINE_ACCOUNT,
        actionTypeSingleData: SET_SINGLE_DATA_AIRLINE_ACCOUNT,
        queryOptions: { $populate: ['airline'] }
    },
    {
        type: types.airlineAirportAccount,
        service: app.service('/api/airline_airport_account'),
        form: "airlineAirportAccountForm",
        pageVar: "pageAirlineAirportAccount",
        searchVar: "searchAirlineAirportAccount",
        label: { airline_account: ['acct_name','customer_acct'], airport: ['code', 'name'] },
        actionTypeSearch: SET_SEARCH_AIRLINE_AIRPORT_ACCOUNT,
        actionTypeData: SET_AIRLINE_AIRPORT_ACCOUNT_DATA,
        actionTypePage: SET_PAGE_AIRLINE_AIRPORT_ACCOUNT,
        actionTypeSingleData: SET_SINGLE_DATA_AIRLINE_AIRPORT_ACCOUNT,
        queryOptions: { $populate: [{ path: 'airline_account', populate: ['airline'] }, 'airport'] }
    },
    {
        type: types.airlineRoute,
        service: app.service('/api/airline_route'),
        form: "airlineRouteForm",
        pageVar: "pageAirlineRoute",
        searchVar: "searchAirlineRoute",
        label: { airportMeet: ['airlineCode','airportCode'], hotel: ['landMark'], airline_airport_account: [{airline_account: ['acct_name','customer_acct'] },{airport: ['code', 'name'] }] },
        actionTypeSearch: SET_SEARCH_AIRLINE_ROUTE,
        actionTypeData: SET_AIRLINE_ROUTE_DATA,
        actionTypePage: SET_PAGE_AIRLINE_ROUTE,
        actionTypeSingleData: SET_SINGLE_DATA_AIRLINE_ROUTE,
        queryOptions: { $populate: [ { path: 'airportMeet', populate: ['airportCode','airlineCode']}, 'hotel', { path: 'airline_airport_account', populate: [{path:'airline_account', populate: ['airline']},'airport'] } ] }
    }
]

const getLabel = (obj, label, dash) => {
    let result = "";
    for(let index = 0; index < label.length; index++){
        if(typeof(label[index]) === 'string'){
            if(index === 0) result += obj[label[index]];
            else result += ' ' + obj[label[index]]
        } else if(typeof(label[index]) === 'object'){
            let keys = Object.keys(label[index]);
            for(let indexKey = 0; indexKey < keys.length; indexKey++)
                result += getLabel(obj[keys[indexKey]],label[index][keys[indexKey]], false);
        }
        if(dash)
            result += `${index == (label.length/2) - 1 ? ' - ': ''}`
    }
    return result;
}

const findType = type => actionTypes.find( item => item.type == type);

const concatSearch = (typeItem, search) => {
    let $or = [];
    for(let index = 0; index < typeItem.searchOptions.length; index++){
        let obj = {}
        if(typeItem.searchOptions[index].number){
            if(!isNaN(parseInt(search)))
                obj[typeItem.searchOptions[index].field] = parseInt(search);
        } else {
            obj[typeItem.searchOptions[index].field] = { $regex: search, $options: 'i' };
        }
        if(Object.keys(obj).length > 0) $or.push(obj);
    }
    return $or
}

const concatSearchMeeting = async( search ) => {
    const response = await findType(types.airline).service.find({ query: {
        code: { $regex: search, $options: 'i'}, $paginate: false
    }});
    const responseAirport = await findType(types.airport).service.find({ query: {
        code: { $regex: search, $options: 'i' }, $paginate: false
    }});

    let idArrayAirline = [], idArrayAirport = [];

    for(let index = 0; index < response.length; index++)
        idArrayAirline.push(response[index]._id);
    for(let index = 0; index < responseAirport.length; index++)
        idArrayAirport.push(responseAirport[index]._id);

    return [
        { airlineCode: { $in: idArrayAirline } },
        { airportCode: { $in: idArrayAirport } }
    ];
}

const findConcatType = (typeItem, search) => {
    const type = typeItem.type;
    let response;
    if(type === types.airline || type === types.airport || type === types.hotel)
        response = concatSearch(typeItem, search);
    else if(type === types.meeting)
        response = concatSearchMeeting(search)
    else if(type === types.airlineAccount)
        response = findConcatAirlineAccount(search)
    else if(type === types.airlineAirportAccount)
        response = findConcatAirlineAirportAccount(search);
    else if(type === types.airlineRoute)
        response = findConcatAirlineRoute(search);
    else response = [];
    return response
}

const findConcatAirlineAccount = async (search) => {
    const responseAirline = await findType(types.airline).service.find({ query: {
        code: { $regex: search, $options: 'i'}, $paginate: false
    }});
    let idArray = [];
    for(let index = 0; index < responseAirline.length; index++)
        idArray.push(responseAirline[index]._id);

    return [
        { airline: { $in: idArray } },
        { customer_acct: { $regex: search, $options: 'i' } },
        { acct_name: { $regex: search, $options: 'i' } }
    ];
}

const findConcatAirlineAirportAccount = async (search) => {
    const responseAirline = await findType(types.airline).service.find({ query: {
        code: { $regex: search, $options: 'i' }, $paginate: false
    }});
    const responseAirport = await findType(types.airport).service.find({ query: {
        code: { $regex: search, $options: 'i' }, $paginate: false
    }});

    let idArrayAirline = [], idArrayAirport = [], idArrayAirlineAccount = [];

    for(let index = 0; index < responseAirline.length; index++)
        idArrayAirline.push(responseAirline[index]._id);
    const responseAirlineAccount = await findType(types.airlineAccount).service.find({ query: {
        airline: { $in: idArrayAirline }, $paginate: false
    }});
    for(let index = 0; index < responseAirlineAccount.length; index++)
        idArrayAirlineAccount.push(responseAirlineAccount[index]._id);
    for(let index = 0; index < responseAirport.length; index++)
        idArrayAirport.push(responseAirport[index]._id);

    return [
        { airline_account: { $in: idArrayAirlineAccount } },
        { airport: { $in: idArrayAirport } }
    ]
}

const findConcatAirlineRoute = async (search) => {
    let idArrayMeet = [], idArrayHotel = [], idArrayAirlineAirportAccount = [];

    const queryMeeting = await concatSearchMeeting(search);
    const responseMeet = await findType(types.meeting).service.find({ query: {
        $or: queryMeeting,
        $paginate: false
    }});
    for(let index = 0; index < responseMeet.length; index++)
        idArrayMeet.push(responseMeet[index]._id);

    const responseHotel = await findType(types.hotel).service.find({ query: { $or: [
            { landMark: { $regex: search, $options: 'i'}}, { city: { $regex: search, $options: 'i' }},
            { state: { $regex: search, $options: 'i' }}, { zip: { $regex: search, $options: 'i'}},
        ],
        $paginate: false
    }});
    for(let index = 0; index < responseHotel.length; index++)
        idArrayHotel.push(responseHotel[index]._id);

    const queryAirlineAirport = await findConcatAirlineAirportAccount(search);
    const responseAirlineAirportAccount = findType(types.airlineAirportAccount).service.find({ query: {
        $or: queryAirlineAirport,
        $paginate: false
    }});
    for(let index = 0; index < responseAirlineAirportAccount.length; index++)
        idArrayAirlineAirportAccount.push(responseAirlineAirportAccount[index]._id);

    return [
        { airportMeet: { $in: idArrayMeet } },
        { hotel: { $in: idArrayHotel } },
        { airline_airport_account: { $in: idArrayAirlineAirportAccount } },
        { group_code: { $regex: search, $options: 'i'} },
    ]
}

export const find = (page = 1, type) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $limit: 5,
            $skip: (page - 1) * 5
        }};
        const typeItem = findType(type);
        if(typeItem.queryOptions)
            params.query = { ...params.query, ...typeItem.queryOptions };
        const search = getStore().crewAdmin[typeItem.searchVar]
        if(search)
            params.query.$or = await findConcatType(typeItem, search);
        const data = await typeItem.service.find(params);
        dispatch(setPage(page, typeItem.actionTypePage))
        dispatch(setData(data, typeItem.actionTypeData))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data, type) => async (dispatch) => {
    dispatch(setLoadingCreateUpdate(true));
    try {
        const typeItem = findType(type);
        await typeItem.service.create(data);
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false));
    }
}

export const load2Update = (id, type) => async (dispatch) => {
    dispatch(setLoadingCreateUpdate(true))
    try {
        const typeItem = findType(type);
        let params = { query: { } };
        if(typeItem.queryOptions)
            params.query = { ...typeItem.queryOptions }
        let data = await typeItem.service.get(id, params);
        if(typeItem.form){
            if(typeItem.label){
                let keys = Object.keys(typeItem.label);
                for(let index = 0; index < keys.length; index++){
                    if(typeof(data[keys[index]]) === 'object'){
                        data[keys[index]].label = getLabel(data[keys[index]], typeItem.label[keys[index]], true);
                    }
                }
            }
            dispatch(initializeForm(typeItem.form, data));
        }
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false))
    }
}

export const update = (data, type) => async (dispatch, getStore) =>{
    dispatch(setLoadingCreateUpdate(true));
    try{
        const typeItem = findType(type);
        await typeItem.service.update(data._id,data);
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false))
    }
}

export const destroyAirline = (id, type) => async(dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        const typeItem = findType(type);
        const page = getStore().crewAdmin[typeItem.pageVar]

        let dataMeeting = await app.service('/api/airportMeet').find({
            query: { airlineCode: id, $paginate: false }
        });

        let dataAccount = await app.service('/api/airline_account').find({
            query: { airline: id, $paginate: false }
        });

        for(let index = 0; index < dataAccount.length; index++){
            let data = await app.service('/api/airline_airport_account').find({ query: {
                airline_account: dataAccount[index]._id, $paginate: false,
            }});
            for(let index = 0; index < data.length; index++)
                await app.service('/api/airline_airport_account').remove(data[index]._id);

            await app.service('/api/airline_account').remove(dataAccount[index]._id);
        }

        for(let index = 0; index < dataMeeting.length; index++){
            let result = await app.service('/api/airline_route').find({
                query: { airportMeet: dataMeeting[index]._id, $paginate: false }
            })
            for(let indexResult = 0; indexResult < result.length; indexResult++)
                await app.service('/api/airline_route').remove(result[indexResult]._id);

            await app.service('/api/airportMeet').remove(dataMeeting[index]._id);
        }

        await typeItem.service.remove(id);
        dispatch(find(page,type))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
}

export const destroy = (id, type) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let page;
    const typeItem = findType(type);
    page = getStore().crewAdmin[typeItem.pageVar]
    try {
        await typeItem.service.remove(id);
        dispatch(find(page,type))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setSearch = (type) => (dispatch, getStore) => {
    const store = getStore().form.searchCrewAdmin;
    const typeItem = findType(type);
    const search = store.values && store.values[typeItem.searchVar]
    dispatch({ type: typeItem.actionTypeSearch, search });
    dispatch(find(1, type));
}

export const setSearchAlt = (search, type) => (dispatch, getStore) => {
    const typeItem = findType(type);
    dispatch({ type: typeItem.actionTypeSearch, search });
    dispatch(find(1, type));
}

export const setLoading = (loading) => ({ type: LOADING, loading });

export const setLoadingForm = ( type, loading ) => ({ type, loading });

export const setLoadingCreateUpdate = ( loading ) => ({ type: LOADING_UPDATE, loading });

export const setPage = (page, type) => ({ type, page });

export const setData = (data, type) => ({ type, data });

export const setTemporalData = ( type, data ) => ({ type, data });

export const setSingleData = (data, type) => ({ type, data });

export const setCustomerAcct = (id) => (dispatch, getStore) => {
    let account = getStore().crewAdmin.account_names.find( item => item._id === id);
    if(account)
        dispatch(change('airlineAccountForm', 'customer_acct', account.customer_acct));
}

export const getAccounts = () => async (dispatch) => {
    const dataAccounts = await app.service('/api/airline_account').find({
        query: { $paginate: false }
    });
    let result = []
    for(let index = 0; index < dataAccounts.length; index++)
        if(result.find( item => item.acct_name === dataAccounts[index].acct_name) === undefined)
            result.push(dataAccounts[index])
    dispatch({
        type: ACCOUNTS_NAMES,
        data: result
    })
}

export const getAirlines = search => dispatch => {
    const typeItem = findType(types.airline);
    let params = { query: { }}
    if(search)
        params.query.$or = [
            { code: { $regex: search, $options: 'i'}},
            { company: { $regex: search, $options: 'i' }}
        ];
    return typeItem.service.find(params).then( data => {
        data = data.data;
        for(let index = 0; index < data.length; index++)
            data[index].label = `${data[index].code} - ${data[index].company}`;
        return { options: data }
    }).catch( () => { return { options: [] }} );
}

export const getAirports = search => dispatch => {
    const typeItem = findType(types.airport);
    let params = { query: { }};
    if(search)
        params.query.$or = [
            { code: { $regex: search, $options: 'i' }},
            { name: { $regex: search, $options: 'i' }}
        ];
    return typeItem.service.find(params).then(data=>{
        data = data.data;
        for(let index = 0; index < data.length; index ++)
            data[index].label = `${data[index].code} - ${data[index].name}`;
        return { options: data }
    }).catch( () => { return { options: []} } );
}

export const getAirlinesAccount = search => dispatch => {
    const typeItem = findType(types.airlineAccount);
    let params = { query: { }};
    if(search){
        params.query.$or = [
            { acct_name: { $regex: search, $options: 'i' }},
            { customer_acct: { $regex: search, $options: 'i' }}
        ];
    }
    return typeItem.service.find(params).then(data=>{
        data = data.data;
        for(let index = 0; index < data.length; index++)
            data[index].label = `${data[index].acct_name} - ${data[index].customer_acct}`;
        return { options: data };
    }).catch( () => { return { options: [] }} );
}

export const getHotels = search => dispatch => {
    const typeItem = findType(types.hotel);
    let params = { query: { }};
    if(search)
        params.query.$or = [
            { landMark: { $regex: search, $options: 'i'} }
        ]
    return typeItem.service.find(params).then( data => {
        data = data.data;
        for(let index = 0; index < data.length; index++)
            data[index].label = `${data[index].landMark}`;
        return { options: data }
    }).catch( () => { return { options: [] }});
}

export const getAirlineAirportAccount = (search = "") => dispatch => {
    const typeItem = findType(types.airlineAirportAccount);
    let params = { query: { $populate: [{
        path: 'airline_account',
        $or: [
            { customer_acct: { $regex: search, $options: 'i' }},
            { acct_name: { $regex: search, $options: 'i' }}
        ]
    }, {
        path: 'airport',
        $or: [
            { code: { $regex: search, $options: 'i' }},
            { name: { $regex: search, $options: 'i'}}
        ]
    }] }};
    return typeItem.service.find(params).then( data => {
        data = data.data;
        for(let index = 0; index < data.length; index++)
            data[index].label = `${data[index].airline_account.acct_name} ${data[index].airline_account.customer_acct} - ${data[index].airport.code} ${data[index].airport.name}`;
        return { options : data };
    }).catch( () => { return { options: [] }});
}

export const getAirlineAirport = (search) => dispatch => {
    const typeItem = findType(types.meeting);
    let params = { query: { }};
    if(search)
        params.query.$or = [
            { airlineCode: { $regex: search, $options: 'i' }},
            { airportCode: { $regex: search, $options: 'i' }}
        ]
    return typeItem.service.find(params).then( data => {
        data = data.data;
        for(let index=0; index < data.length; index++)
            data[index].label = `${data[index].airlineCode} - ${data[index].airportCode}`;
        return { options: data };
    }).catch( () => { return { options: [] }});
}

const load2UptadeAirlineAccount = (id, type) => async (dispatch, getStore) => {
    dispatch(setLoadingCreateUpdate(true))
    try {
        const typeItem = findType(type);
        let params = { query: { } };
        if(typeItem.queryOptions)
            params.query = { ...typeItem.queryOptions }
        let data = await typeItem.service.get(id, params);
        const responseAirlineAirportAccount = await app.service('/api/airline_airport_account').find({
            query: {
                airline_account: id,
                $paginate: false,
            }
        })
        if(responseAirlineAirportAccount && responseAirlineAirportAccount.length > 0) {
            const responseMeeting = await app.service('/api/airportMeet').find({ query: {
                airlineCode: data.airline._id,
                airportCode: { $in: responseAirlineAirportAccount[0].airport },
                $populate: ['airportCode'],
                $paginate: false,
            }});
            for(let index = 0; index < responseMeeting.length; index++){
                responseMeeting[index].label = `${responseMeeting[index].airportCode.code}`
            }
            data.airport = responseMeeting;
        }
        if(typeItem.form){
            if(typeItem.label){
                let keys = Object.keys(typeItem.label);
                for(let index = 0; index < keys.length; index++){
                    if(typeof(data[keys[index]]) === 'object'){
                        data[keys[index]].label = getLabel(data[keys[index]], typeItem.label[keys[index]], true);
                    }
                }
            }

            dispatch(initializeForm(typeItem.form, data));
        }
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false))
    }
}

const load2UpdateAirlineRoute = (id, type) => async (dispatch, getStore) => {
    dispatch(setLoadingCreateUpdate(true))
    try {
        const typeItem = findType(type);
        let params = { query: { } };
        if(typeItem.queryOptions)
            params.query = { ...typeItem.queryOptions }
        let data = await typeItem.service.get(id, params);
        data.airline = {
            ...data.airportMeet.airlineCode,
            label: `${data.airportMeet.airlineCode.code} - ${data.airportMeet.airlineCode.company}`
        };
        data.airportMeet.label = `${data.airportMeet.airportCode.code}`;
        data.hotel.label = `${data.hotel.landMark}`;
        data.active = data.active !== undefined ? data.active : true;
        if(typeItem.form){
            dispatch(initializeForm(typeItem.form, data));
        }
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false))
    }
}

const getAirportMeet = async (search, values) => {

    if(!values || !values.airline)
        return { options: [] };

    const params = {
        query: {
            airlineCode: values.airline._id,
            $populate: ['airportCode']
        }
    };

    if(search){
        const airportCode = await app.service('/api/airport').find({
            query: {
                code: { $regex: search, $options: 'i' },
                $paginate: false,
            }
        });
        params.query.airportCode = { $in: airportCode };
    }
    return app.service('/api/airportMeet').find(params).then( result => {
        for(let index = 0; index < result.data.length; index++){
            result.data[index].label = `${result.data[index].airportCode.code}`
        }
        return { options: result.data }
    });
}

const getAirportMeetRoute = (search) => (dispatch, getStore) => {
    const { values } = getStore().form.airlineRouteForm;
    return getAirportMeet(search, values);
}

const getAirportMeetAccount = (search) => (dispatch, getStore) => {
    const { values } = getStore().form.airlineAccountForm;
    return getAirportMeet(search, values);
}

const createAirlineAccount = (data) => async (dispatch, getStore) => {
    dispatch(setLoadingCreateUpdate(true));
    try {
        for(let index = 0; index < data.airport.length; index++){
            data.airport[index] = data.airport[index].airportCode._id;
        }
        const response = await app.service('/api/airline_account').create(data);
        await app.service('/api/airline_airport_account').create({
            airline_account: response._id,
            airport: data.airport
        });
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false));
    }
}

const updateAirlineAccount = (data) => async (dispatch, getStore) => {
    dispatch(setLoadingCreateUpdate(true))
    try {

        const airlineAirportValues = await app.service('/api/airline_airport_account').find({
            query: {
                airline_account: data._id,
                $paginate: false,
            }
        });

        const newAirports = []
        for(let index = 0; index < data.airport.length; index++)
            newAirports.push(data.airport[index].airportCode._id);

        const oldAirports = []
        for(let index = 0; index < airlineAirportValues[0].airport.length; index++)
            oldAirports.push(airlineAirportValues[0].airport[index]);

        const finalAirports = []
        for(let index = 0; index < oldAirports.length; index++){
            if(newAirports.includes(oldAirports[index]))
                finalAirports.push(oldAirports[index])
        }
        for(let index = 0; index < newAirports.length; index++){
            if(finalAirports.includes(newAirports[index]) === false)
                finalAirports.push(newAirports[index]);
        }

        await app.service('/api/airline_airport_account').patch(airlineAirportValues[0]._id,{ airport: finalAirports });
        await app.service('/api/airline_account').patch(data._id, data);

    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false));
    }
}

const createAirlineRoute = (data, isUpdate = false) => async (dispatch, getStore) => {

    const Route = findType(types.airlineRoute);

    try {
        dispatch(setLoadingCreateUpdate(true));
        const airlineAccount = await app.service('/api/airline_account').find({
            query: { airline: data.airline._id, $paginate: false }
        })
        for(let index = 0; index < airlineAccount.length; index++){
            airlineAccount[index] = airlineAccount[index]._id;
        }
        const responseAirlineAirportAccount = await app.service('/api/airline_airport_account').find({
            query: {
                airline_account: { $in: airlineAccount },
                $paginate: false,
            }
        })
        let airline_airport_account;
        for(let index=0; index < responseAirlineAirportAccount.length; index++){
            if(typeof responseAirlineAirportAccount[index].airport === 'string')
                responseAirlineAirportAccount[index].airport = [responseAirlineAirportAccount[index].airport];
            if(responseAirlineAirportAccount[index].airport.find( item =>
                item === data.airportMeet.airportCode._id ) !== undefined){
                    airline_airport_account = responseAirlineAirportAccount[index];
            }
        }
        if(airline_airport_account){
            let obj = {
                airportMeet: data.airportMeet._id,
                hotel: data.hotel._id,
                airline_airport_account: airline_airport_account._id,
                hotel_pickUpLocation: data.hotel_pickUpLocation,
                airline_airportDescription: data.airline_airportDescription,
                airline_hotelDescription: data.airline_hotelDescription,
                group_code: data.group_code,
                fixed_rate: data.fixed_rate,
                fixed_rate2: data.fixed_rate2,
                pax_for_rate2: data.pax_for_rate2,
                hours: data.hours,
                active: data.active,
            }
            if(isUpdate){
                await Route.service.patch(data._id, obj);
            } else {
                await Route.service.create(obj);
            }
        } else {
            swal.fire({
                type: "error",
                title: "Account not found",
                text:"Not found an airline account for this airport"
            })
        }
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingCreateUpdate(false));
    }
}

export const actions = {
    find,
    create,
    setSearch,
    setSearchAlt,
    load2Update,
    update,
    destroy,
    destroyAirline,
    getAccounts,
    getAirlines,
    getAirports,
    getAirportMeetRoute,
    updateAirlineAccount,
    createAirlineAccount,
    getAirportMeetAccount,
    load2UpdateAirlineRoute,
    load2UptadeAirlineAccount,
    createAirlineRoute,
    getAirlinesAccount,
    getHotels,
    getAirlineAirportAccount,
    getAirlineAirport,
    setCustomerAcct,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_AIRLINE]: (state, { page }) => ({ ...state, pageAirline: page }),
    [SET_PAGE_AIRPORT]: (state, { page }) => ({ ...state, pageAirport: page }),
    [SET_PAGE_MEETING]: (state, { page }) => ({ ...state, pageMeeting: page }),
    [SET_PAGE_HOTEL]: (state, { page }) => ({ ...state, pageHotel: page }),
    [SET_PAGE_AIRLINE_ACCOUNT]: (state, { page }) => ({ ...state, pageAirlineAccount: page }),
    [SET_PAGE_AIRLINE_AIRPORT_ACCOUNT]: (state, { page }) => ({ ...state, pageAirlineAirportAccount: page }),
    [SET_PAGE_AIRLINE_ROUTE]: ( state, { page }) => ({ ...state, pageAirlineRoute: page }),
    [SET_AIRLINE_DATA]: (state, { data }) => ({ ...state, dataAirline: data }),
    [SET_AIRPORT_DATA]: (state, { data }) => ({ ...state, dataAirport: data }),
    [SET_MEETING_DATA]: (state, { data }) => ({ ...state, dataMeeting: data }),
    [SET_HOTEL_DATA]: (state, { data }) => ({ ...state, dataHotel: data }),
    [SET_AIRLINE_ACCOUNT_DATA]: (state, { data }) => ({ ...state, dataAirlineAccount: data }),
    [SET_AIRLINE_AIRPORT_ACCOUNT_DATA]: (state, { data }) => ({ ...state, dataAirlineAirportAccount: data }),
    [SET_AIRLINE_ROUTE_DATA]: (state, { data }) => ({ ...state, dataAirlineRoute: data}),
    [SET_SINGLE_DATA_AIRLINE]: (state, { data }) => ({ ...state, singleDataAirline: data }),
    [SET_SINGLE_DATA_AIRPORT]: (state, { data }) => ({ ...state, singleDataAirport: data }),
    [SET_SINGLE_DATA_MEETING]: (state, { data }) => ({ ...state, singleDataMeeting: data }),
    [SET_SINGLE_DATA_HOTEL]: (state, { data }) => ({ ...state, singleDataHotel: data }),
    [SET_SINGLE_DATA_AIRLINE_ACCOUNT]: (state, { data }) => ({ ...state, singleDataAirlineAccount: data }),
    [SET_SINGLE_DATA_AIRLINE_AIRPORT_ACCOUNT]: (state, { data }) => ({ ...state, singleDataAirlineAirportAccount: data }),
    [SET_SINGLE_DATA_AIRLINE_ROUTE]: (state, { data }) => ({ ...state, singleDataAirlineRoute: data }),
    [LOADING_UPDATE]: (state, { loading }) => ({ ...state, loadingUpdate: loading }),
    [ACCOUNTS_NAMES]: (state, { data }) => ({ ...state, account_names: data }),
    [SET_SEARCH_AIRLINE]: (state, { search }) => ({...state, searchAirline: search}),
    [SET_SEARCH_AIRPORT]: (state, { search }) => ({...state, searchAirport: search}),
    [SET_SEARCH_AIRPORT_MEET]: (state, { search }) => ({...state, searchMeeting: search}),
    [SET_SEARCH_HOTEL]: (state, { search }) => ({...state, searchHotel: search}),
    [SET_SEARCH_AIRLINE_ACCOUNT]: (state, { search }) => ({...state, searchAirlineAccount: search}),
    [SET_SEARCH_AIRLINE_AIRPORT_ACCOUNT]: (state, { search }) => ({...state, searchAirlineAirportAccount: search}),
    [SET_SEARCH_AIRLINE_ROUTE]: (state, { search }) => ({...state, searchAirlineRoute: search}),
};

const initialState = {
    loading: false,
    loadingUpdate: false,
    pageAirline: 1,
    pageAirport: 1,
    pageMeeting: 1,
    pageHotel: 1,
    pageAirlineAccount: 1,
    pageAirlineAirportAccount: 1,
    pageAirlineRoute: 1,
    dataAirline: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataAirport: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataMeeting: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataHotel: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataAirlineAccount: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataAirlineAirportAccount: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataAirlineRoute: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    singleDataAirline: undefined,
    singleDataAirport: undefined,
    singleDataMeeting: undefined,
    singleDataHotel: undefined,
    singleDataAirlineAccount: undefined,
    singleDataAirlineAirportAccount: undefined,
    singleDataAirlineRoute: undefined,
    account_names: undefined,
    searchAirline: undefined,
    searchAirport: undefined,
    searchMeeting: undefined,
    searchHotel: undefined,
    searchAirlineAccount: undefined,
    searchAirlineAirportAccount: undefined,
    searchAirlineRoute: undefined,
};

export default handleActions(reducers, initialState);
