import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BookForm from './BookForm';
import VehicleList from '../../../cart/Vehicles';
import {
    setDetails,
    setHourlyItinerary,
    setTransferItinerary,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
} from '../../../../../redux/modules/itinerary';
import {
    setTrip,
    setTripItem,
    loadVehicles,
    setVehicle,
} from '../../../../../redux/modules/quote';
import swal from 'sweetalert2';


const VehicleListCont = ({
    setTrip,
    itinerary,
    setDetails,
    loadVehicles,
    transferQt,
    setTripItem,
    clear,
    loadingVehicles = false,
    vehicles = [],
    vehicle = [],
    setVehicle,
    editPassenger,
    removePassenger,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
    tripCartItem,
}) => {

    const handleVehicleSelect = (index) => {
        setTrip().then(()=>{
            setVehicle(index, tripCartItem ? tripCartItem.edit : false);
        }).catch(error => {
            const msg = error.message || error;
            swal.fire({
                type: "error",
                title: "ERROR!",
                text: `${msg}`
            })
        })
    };

    return (
        <div className="modalItinerary">
            <VehicleList
                handleVehicleSelect={handleVehicleSelect}
                vehicles={vehicles}
                formType={transferQt}
                from={itinerary.from}
                loader={loadingVehicles}
                editPassenger={editPassenger}
                removePassenger={removePassenger}
                item={tripCartItem}
            />
        </div>

    );
};

const mdtp = {
    setTrip,
    setDetails,
    setVehicle,
    setTripItem,
    setHourlyItinerary,
    setTransferItinerary,
    loadVehicles,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
};

const mstp = (state) => ({
    itinerary: state.itinerary,
    vehicles: state.quote.vehicles,
    vehicle: state.vehicle.vehicle,
    tripCartItem: state.quote.tripCartItem,
});

export default connect(mstp, mdtp)(VehicleListCont);
