import React, { Component } from "react";
import { connect } from "react-redux";
import { getMonthSnapshot, find, onSortChange, getReservation, cancelAndRefund,
    getScheduleMonths, openBookingWithMonth } from "../../../redux/modules/dashboard";
import LoadMask from '../Utils/LoadMask';
import numeral from "numeral";
import Modal from 'react-responsive-modal';
import GridMonths from './gridMonths';
import GridUpcoming from "./gridUpcoming";
import RefundModal from './RefundModal';
import moment from "moment";
import { RatingIcon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getPathWithParams, reservationsAirlineUploadsId } from "../../../routesConfiguration/paths";

const arrowDown =  <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-down-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M4.646 7.646a.5.5 0 0 1 .708 0L8 10.293l2.646-2.647a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708z"/>
    <path fillRule="evenodd" d="M8 4.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5z"/>
    </svg>

const arrowUp = <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-arrow-up-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5z"/>
    <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8 5.707 5.354 8.354a.5.5 0 1 1-.708-.708l3-3z"/>
    </svg>

class Privado extends Component {

    state = {
        modal_detail: false,
        modal_cancel: false,
        data_cancel: {},
        data_month_schedules: {}
    }

    componentDidMount(){
        this.props.getScheduleMonths();
        this.props.getMonthSnapshot();
        this.props.find();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ viewTrends, viewBusiestMonth, viewMonthlyRevenue }) => viewTrends || viewBusiestMonth || viewMonthlyRevenue))
                    this.props.getMonthSnapshot();
                if(permission.find(({ monthlyManifestDashboard }) => !!monthlyManifestDashboard))
                    this.props.getScheduleMonths();
                if(permission.find(({ newestTripsDashboard}) => !!newestTripsDashboard))
                    this.props.find();
            }
        }
    }

    renderArrow = (percentChange) => {
        let result = undefined
        if(percentChange && percentChange >= 0){
            result = <span style={{color: "green"}}> {arrowUp} {percentChange}% </span>
        } else {
            result = <span style={{color: "red"}}> {arrowDown} {percentChange}% </span>
        }
        return result;
    }

    renderModalDetail = () => {
        return (
            <Modal
                open={this.state.modal_detail}
                onClose={() => this.openModalDetail(false)}
                styles={{
                    modal: { minWidth: "35%"}
                }}
            >
                <div className="m-3">
                    {this.state.data_month_schedules && Object.keys(this.state.data_month_schedules).length > 0 && (
                        <React.Fragment>
                            <h2 style={{
                                color: `${ this.state.data_month_schedules.airports.every( item => item.total > 0)
                                    ? '#5CB96C' : this.state.data_month_schedules.airports.some( item => item.total > 0)
                                        ? '#F0AD4E' : '#D9534F'
                                }`
                            }}>
                                {this.state.data_month_schedules.airline.code} - {this.state.data_month_schedules.month}
                            </h2>
                            <h4> Airports </h4>
                            <hr/>
                            {this.state.data_month_schedules.airports.map( airport => (
                                <React.Fragment key={airport.airport + airport.airportCode}>
                                    <h5> { airport.airportCode }</h5>
                                    <h6>
                                        { airport.total > 0 ? 'Uploaded' : 'Not uploaded' }
                                        { airport.totalDays > 0 ? ` - ${airport.totalDays} ${airport.totalDays === 1 ? 'day' : 'days'}` : ''}
                                        {airport.schedule && (
                                            <Link
                                                to={getPathWithParams(
                                                    reservationsAirlineUploadsId,
                                                    "id",
                                                    airport.schedule,
                                                )}
                                                className="btn btn-link p-1"
                                            >
                                                (View)
                                            </Link>
                                        )}
                                    </h6>
                                </React.Fragment>
                            ))}
                        </React.Fragment>
                    )}
                </div>
            </Modal>
        )
    }

    renderModalCancel = () => {
        return (
            <Modal
                open={this.state.modal_cancel}
                showCloseIcon={false}
                onClose={() => this.openModalCancel(false)}
            >
                <LoadMask loading={this.props.loadingModalCancel} dark blur>
                    <div className="d-flex flex-column m-4">
                        <h3 className="mb-5"> Refund and Cancel </h3>
                        <RefundModal
                            onSubmit={this.submitCancelModal}
                            closeModal={()=>{this.openModalCancel(false)}}
                            dataReservation={this.state.data_cancel}
                        />
                    </div>
                </LoadMask>
            </Modal>
        )
    }

    submitCancelModal = () => {
        this.props.cancelAndRefund(this.openModalCancel);
    }

    openModalDetail = (value, detail) => {
        if(detail) this.setState({data_month_schedules: detail});
        else this.setState({ data_month_schedules: {}})
        this.setState({ modal_detail: value})
    }

    openModalCancel = (value, data = {}) => {
        this.setState({ modal_cancel: value, data_cancel: data })
    }

    render() {

        const {
            loadingReportMonths,
            loadingReportSchedules,
            openBookingWithMonth,
            loadingData,
            onSortChange,
            data,
            report_schedules,
            reportMonths,
            find,
            page,
            history,
            userInfo: { permission }
        } = this.props;

        return (
            <div className="container w-100">
                {this.renderModalDetail()}
                {this.renderModalCancel()}
                <h1>Dashboard</h1>
                <div className="row my-5">
                    <div className="col-12 px-0 px-lg-3">
                        <div className="row">
                            {permission && permission.find(({ viewTrends }) => !!viewTrends) && (
                                <div className="card col-12 col-md-4 py-4 d-flex justify-content-start align-items-start"
                                    style={{ border: "1px solid #3B3B3B", minHeight: "20rem" }}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <h5> <strong> Trends </strong> </h5>
                                        </div>
                                        {loadingReportMonths ? (
                                            <div className="col-12 d-flex justify-content-center my-5">
                                                <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="col-12">
                                                <h6> Revenue change from last month </h6>
                                                <h6 style={{marginLeft: "-0.55rem"}}>
                                                    {this.renderArrow(reportMonths.revenueChange)}
                                                </h6>
                                                <h6> Job count change from last month </h6>
                                                <h6 style={{marginLeft: "-0.55rem"}}>
                                                    {this.renderArrow(reportMonths.tripChange)}
                                                </h6>
                                                <h6> Total customer account signed up </h6>
                                                <h6 style={{color: "red", fontWeight: "bold"}}> {reportMonths.count} </h6>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {permission && permission.find(({ viewBusiestMonth }) => !!viewBusiestMonth) && (
                                <div className="card col-12 col-md-4 py-4" style={{ border: "1px solid #3B3B3B", minHeight: "20rem" }}>
                                    {loadingReportMonths ? (
                                        <div className="d-flex justify-content-center">
                                            <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <h5 className="bold t-center"> Busiest Month </h5>
                                            <h5 style={{color: "green"}} className="t-center">
                                                {reportMonths && reportMonths.busiestMonth && (
                                                    `${reportMonths.busiestMonth.month} - $ ${numeral(reportMonths.busiestMonth.revenue).format("0,0.00")}`
                                                )}
                                            </h5>
                                            <br style={{ border: "none"}} className="mt-4"/>
                                            <h5 className="bold t-center"> Slowest Month </h5>
                                            <h5 style={{color: "red"}} className="t-center">
                                                {reportMonths && reportMonths.slowlestMonth && (
                                                    `${reportMonths.slowlestMonth.month} - $ ${numeral(reportMonths.slowlestMonth.revenue).format("0,0.00")}`
                                                )}
                                            </h5>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                            {permission && permission.find(({ monthlyManifestDashboard }) => !!monthlyManifestDashboard) && (
                                <div className="card col-12 col-md-4 py-4" style={{ border: "1px solid #3B3B3B", minHeight: "20rem" }}>
                                    <h5 className="t-center bold mb-0"> Monthly Manifests </h5>
                                    {loadingReportSchedules ? (
                                        <div className="d-flex align-items-center justify-content-center">
                                            <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        report_schedules && Object.keys(report_schedules).length > 0 && (
                                            <React.Fragment>
                                                <h6 className="t-center"> {report_schedules.month} </h6>
                                                <div className="d-flex flex-wrap justify-content-center">
                                                    {report_schedules.result.map( (list, index) => (
                                                        <div className="col-6 col-md-4 col-xl-3 d-flex flex-column py-3" key={index}>
                                                            {list.map(item => (
                                                                <div key={item.airline._id} className="d-flex justify-content-center pb-1">
                                                                    <span  className="bold t-center px-4"
                                                                        style={{
                                                                            background: `${ item.airports.every( item => item.total > 0)
                                                                                    ? '#5CB96C' : item.airports.some( item => item.total > 0)
                                                                                        ? '#F0AD4E' : '#D9534F'
                                                                                }`,
                                                                            borderRadius: "10px",
                                                                            color: "#fff",
                                                                            fontSize: "0.8rem",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={()=>this.openModalDetail(true, { ...item, month: report_schedules.month })}
                                                                    >
                                                                        {item.airline.code}
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {permission && permission.find(({ newestTripsDashboard }) => !!newestTripsDashboard) && (
                    <div className="row mb-5">
                        <h4 className="bold"> Upcoming trips </h4>
                        <div className="col-12 px-0 px-lg-3">
                            <GridUpcoming
                                find={find}
                                data={data}
                                page={page}
                                loadingData={loadingData}
                                openModalDetail={this.openModalDetail}
                                openModalCancel={this,this.openModalCancel}
                                onSortChange={onSortChange}
                                history={history}
                            />
                        </div>
                    </div>
                )}
                {permission && permission.find(({ viewMonthlyRevenue }) => !!viewMonthlyRevenue) && (
                    <div className="row mb-5">
                        <h4 className="bold t-right"> Monthly Revenue </h4>
                        <div className="col-12 px-0 px-lg-3">
                            <GridMonths
                                reportMonths={reportMonths}
                                loadingReportMonths={loadingReportMonths}
                                openBookingWithMonth={openBookingWithMonth}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.dashboard,
    userInfo: state.login.userInfo,
});

const mdtp = { getMonthSnapshot, find, onSortChange, getReservation,
    cancelAndRefund, getScheduleMonths, openBookingWithMonth };

export default connect(mstp, mdtp)(Privado);
