import React from 'react';
import LoadMask from '../../Utils/LoadMask';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { RenderMultipleOption, RenderRange } from '../../Utils/renderField/renderReadField';
import { reservationsCharterReviewsBooking } from '../../../../routesConfiguration/paths';


const valuableItems = [
    'Easy process',
    'Vehicle options',
    'Price',
];

const hearAbout = [
    "Search engine (Google, Yahoo, etc.)",
    "Recommended by friend or colleague",
    "Social media",
    "Blog or publication",
    "Seen vehicle",
];

class Details extends React.Component {

    render(){

        const { loading, updateData } = this.props;

        return (
            <div className="d-flex flex-column" style={{ color: "#707070" }}>
                <div className="d-flex flex-wrap">
                    <div className="col-12 col-md-3">
                        <label className="bold"> Review Type:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> Reservations Booking Tool Review  </label>
                    </div>
                    <div className="col-12 col-md-3">
                        <label className="bold"> Trip Number:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.confirmationNo ? updateData.confirmationNo : ''}  </label>
                    </div>
                    <div className="my-5" />
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Name:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.firstName ? updateData.firstName : ''} {updateData.lastName ? updateData.lastName : ''} </label>
                    </div>
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Email:&nbsp;</label>
                    </div>
                    <div className="col-12 col-md-7">
                        <label> {updateData.email} </label>
                    </div>
                    <div className="col-12 col-md-3">
                        <label className="bold"> Customer Phone:&nbsp;</label>
                    </div>
                </div>
                <div className="d-flex my-5">
                    <hr className="flex-1" style={{ borderWidth: "2px" }} />
                    <h4 className="bold">&nbsp;REVIEW&nbsp;</h4>
                    <hr className="flex-1" style={{ borderWidth: "2px" }} />
                </div>
                <div className="d-flex flex-wrap justify-content-center">
                    {/* {updateData.dateBooking && (
                        <div className="col-12 form-group">
                            <div> <strong> Date:&nbsp;</strong> </div>
                            <label> {moment(updateData.dateBooking).format('l')} </label>
                        </div>
                    )}
                    {updateData.sended !== undefined && (
                        <div className="col-12 form-group">
                            <div> <strong> Sent:&nbsp;</strong> </div>
                            <label> {moment(updateData.sended).format('l')} </label>
                        </div>
                    )}
                    {updateData.attempts !== undefined && (
                        <div className="col-12 form-group">
                            <div> <strong> Attempts:&nbsp;</strong> </div>
                            <label> {updateData.attempts} </label>
                        </div>
                    )}
                    {updateData.close !== undefined && (
                        <div className="col-12 form-group">
                            <div> <strong> Status:&nbsp;</strong> </div>
                            <label> {updateData.close ? 'done' : 'pending'} </label>
                        </div>
                    )} */}

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> The booking process was a smooth and hassle-free experience.&nbsp;</strong> </div>
                            <div className="py-2"><RenderRange value={updateData.bookingProcess} size={5} /></div>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.bookingProcess}</strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> The times available to schedule my pick-up were sufficient.&nbsp;</strong> </div>
                            <div className="py-2"><RenderRange value={updateData.enoughtTime} size={5} /></div>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.enoughtTime}</strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> What matters to you most when booking transportation?&nbsp;</strong> </div>
                            <RenderMultipleOption options={valuableItems} values={updateData.valuableItems}/>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.valuableItems ? updateData.valuableItems.toString() : ''}</strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> If there was ONE thing we could do to improve our booking process, what would it be and why?&nbsp;</strong> </div>
                            <label> {updateData.valuableComments ? updateData.valuableComments : 'Blank'} </label>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: (below question) </strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> May we contact you to follow up on these responses?&nbsp;</strong> </div>
                            <label> {updateData.forContact !== null ? updateData.forContact ? "YES" : "NO" : "Blank"} </label>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.forContact !== null ? updateData.forContact ? "YES" : "NO" : "Blank"}</strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> Do you have any questions that you could not find answers to On our site?&nbsp;</strong> </div>
                            <label> {updateData.anyQuestions} </label>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: (below question)</strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> How likely are you to use this booking tool again?:&nbsp;</strong> </div>
                            <div className="py-2"> <RenderRange value={updateData.bookingAgain} size={5} /> </div>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.bookingAgain}</strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> How did you hear about us?:&nbsp;</strong> </div>
                            <RenderMultipleOption options={hearAbout} values={updateData.hearAbout}/>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.hearAbout ? updateData.hearAbout.toString() : ''}</strong>
                        </div>
                    </div>

                    <div className="col-12 px-0 mb-5 d-flex flex-wrap">
                        <div className="col-8">
                            <div> <strong> Any additional feedback?:&nbsp;</strong> </div>
                            <label> {updateData.feedback} </label>
                        </div>
                        <div className="col-4">
                            <strong>ANSWER: {updateData.feedback}</strong>
                        </div>
                    </div>

                </div>
                <div className="d-flex justify-content-end">
                    <Link to={reservationsCharterReviewsBooking}
                        className="btn btn-primary btn-lg"
                    >
                        Back
                    </Link>

                </div>
            </div>
        )
    }
}

export default Details;
