import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { renderDatePicker, renderLabeledField, renderNumber } from "../Utils/renderField/renderField";
import AutoSuggest from "../Utils/autosuggest";


class Form extends React.Component {

    render() {
        const { handleSubmit, change, airlineCodes, airportCodes } = this.props;

        return (
            <form onSubmit={handleSubmit} className="flex-3-5 modalItinerary">
                <div className="row justify-content-center">
                    <div className="col-12 pt-5">
                        <h4 className="m-2 d-flex justify-content-between">
                            Find Ride
                        </h4>

                        <div className="d-flex form-group">
                            <label htmlFor="airportCode">Airport Code</label>
                            <Field
                                name="airport"
                                component={AutoSuggest}
                                keyLabel='label'
                                keyId='_id'
                                placeholder="LAX"
                                className="w-100 m-2"
                                inputClassName="pt-4"
                                suggestions = {airportCodes}
                                onSelectOption={(value)=>change('airport', airportCodes.find(({ _id }) => _id == value ))}
                            />
                        </div>

                        <div className="d-flex form-group">
                            <label htmlFor="airline">Airline Code</label>
                            <Field
                                name="airline"
                                component={AutoSuggest}
                                keyLabel='label'
                                keyId='_id'
                                placeholder="MC"
                                suggestions = {airlineCodes}
                                className="w-100 m-2"
                                inputClassName="pt-4"
                                onSelectOption={(value)=>change('airline', airlineCodes.find(({ _id }) => _id == value ))}
                            />
                        </div>

                        <div className="d-flex form-group">
                            <label htmlFor="flightNumber">Flight Number</label>
                            <Field
                                name="flightNumber"
                                component={renderLabeledField}
                                placeholder="4514"
                                className="w-100 m-2"
                            />
                        </div>

                        <div className="row d-flex justify-content-center justify-content-md-end form-group m-2">
                            <button type="submit"
                                className="btn btn-primary btn-block col-12 col-lg-6 h-100 py-3"
                            >
                                <b> Search </b>
                            </button>
                        </div>

                    </div>
                </div>
            </form>
        )
    }
}

Form = reduxForm({
    form: 'FlightsLivery',
    validate: data => {
        const errors = {};
        if(!data.airline) errors.airline = 'Required Field';
        if(!data.airport) errors.airport = 'Required Field';
        if(!data.flightNumber) errors.flightNumber = "Required Field";
        return errors;
    }
})(Form);

const mdtp = { change };

export default connect(null, mdtp)(Form);
