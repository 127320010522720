import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import {
    renderField,
    renderSelectField,
} from "Utils/renderField/renderField";
import { stateOptions } from "../../../utility/constants";
import { settingsRatesLocations } from "../../../../routesConfiguration/paths";


const Form = (props) => {

    const { handleSubmit } = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="grid-titulo padding-15">
                    <h2>
                        <strong>Location</strong>
                    </h2>
                </div>
                <br />
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="address">Address*</label>
                            <Field
                                id="autocomplete"
                                name="address"
                                component={renderField}
                                type="text"
                                autoComplete="off"
                                placeholder="Search Address"
                                className="form-control"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="city">City*</label>
                            <Field
                                disabled
                                name="city"
                                component={renderField}
                                type="text"
                                placeholder="City"
                                className="form-control"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="state">State*</label>
                            <Field
                                disabled
                                name="state"
                                component={renderSelectField}
                                labelKey="label"
                                valueKey="value"
                                options={stateOptions}
                                placeholder="State"
                            />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                            <label htmlFor="cityCode">City Code</label>
                            <Field
                                name="cityCode"
                                component={renderField}
                                type="text"
                                placeholder="City Code"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center px-2 px-sm-0">
                        <Link className="btn btn-secondary m-1" to={settingsRatesLocations}>
                            Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary m-1">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

const VehicleForm = reduxForm({
    // a unique name for the form
    form: "location",
    validate: (values) => {
        const errors = {};
        if (!values.city) errors.city = "Required Field";
        if (!values.state) errors.state = "Required Field";
        if (!values.cityCode) errors.cityCode = "Required Field";
        return errors;
    }
})(Form);

export default VehicleForm;
