import React from "react";
import PropTypes from "prop-types";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import numeral from 'numeral';
import Grid from "../../Utils/Grid/materialTable";
import { settingsRatesFees, settingsRatesFeesNew } from "../../../../routesConfiguration/paths";

export default class AdditionalChargesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    constructor(props){
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.props.find();
        if (this.tableRef && this.tableRef.current)
            this.tableRef.current.dataManager.changePageSize(10);
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readAditionalCharges }) => !!readAditionalCharges))
                    this.props.find();
            }
        }
    }

    render() {
        const { data, loading, page, destroy, find, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container material-livery-list">
                        {permission &&
                            permission.find(({ readAditionalCharges }) => !!readAditionalCharges) && (
                                <Grid
                                    tableRef={this.tableRef}
                                    title="Additional Charges"
                                    columns={[
                                        {
                                            title: "Title",
                                            field: "title",
                                        },
                                        {
                                            title: "Type",
                                            field: "type",
                                            width: "15%",
                                        },
                                        {
                                            title: "Price",
                                            field: "price",
                                            width: "15%",
                                            render: (row) =>
                                                row.price ? `$ ${numeral(row.price).format("0,0.00")}` : '$ 0.00'
                                        },
                                        {
                                            title: "Description",
                                            field: "description",
                                        },
                                        {
                                            title: "Actions",
                                            field: "_id",
                                            width: "15%",
                                            align: "center",
                                            render: (row) =>
                                                activeFormatter({
                                                    editar: permission.find(({ updateAditionalCharges }) => !!updateAditionalCharges) ? settingsRatesFees : undefined,
                                                    eliminar: permission.find(({ removeAditionalCharges }) => !!removeAditionalCharges) ? destroy : undefined,
                                                })(row._id, row),
                                        },
                                    ]}
                                    actions={[
                                        {
                                            icon: () =>
                                                permission &&
                                                permission.find(({ createAditionalCharges }) => !!createAditionalCharges) && (
                                                    <Link
                                                        className="btn btn-primary px-2 py-0 align-self-end rounded-circle"
                                                        to={settingsRatesFeesNew}
                                                    >
                                                        <h3>+</h3>
                                                    </Link>
                                                ),
                                            isFreeAction: true,
                                        },
                                    ]}
                                    data={data.data}
                                    onPageChange={(p) => find(p + 1)}
                                    isLoading={loading}
                                    page={page - 1}
                                    totalCount={data.total}
                                    options={{
                                        pageSizeOptions: [],
                                        filtering: false,
                                        idSynonym: "_id",
                                        searchText: "Search by code",
                                        search: false,
                                        pageSize: 10,
                                        headerStyle: {
                                            backgroundColor: "#D50032",
                                            color: "#FFF",
                                            fontSize: "1rem",
                                        },
                                        cellStyle: {
                                            fontSize: "1rem",
                                        },
                                    }}
                                />
                            )}
                    </div>
                </div>
            </div>
        );
    }
}
