import React from "react";

const IconPlaneLock = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            className={className}
        >
            <path
                fill="currentColor"
                d="M192 93.7C192 59.5 221 0 256 0c36 0 64 59.5 64 93.7v84.6l101.8 58.2C418 247.6 416 259.6 416 272v24.6c-17.9 10.4-30.3 29.1-31.8 50.9L320 329.1V400l57.6 43.2c4 3 6.4 7.8 6.4 12.8v24 18c0 7.8-6.3 14-14 14c-1.3 0-2.6-.2-3.9-.5L256 480 145.9 511.5c-1.3 .4-2.6 .5-3.9 .5c-7.8 0-14-6.3-14-14V456c0-5 2.4-9.8 6.4-12.8L192 400l0-70.9-171.6 49C10.2 381.1 0 373.4 0 362.8V297.3c0-5.7 3.1-11 8.1-13.9L192 178.3V93.7zM528 240c-17.7 0-32 14.3-32 32v48h64V272c0-17.7-14.3-32-32-32zm-80 32c0-44.2 35.8-80 80-80s80 35.8 80 80v48c17.7 0 32 14.3 32 32V480c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V352c0-17.7 14.3-32 32-32V272z"
            />
        </svg>
    );
};

export default IconPlaneLock;
