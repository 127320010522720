import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import renderTimePicker from '../../../../../../../../TimePicker';
import IconDash from '../../../../../../../icons/dash';
import { renderCurrency, renderNumber } from '../../../../../../Utils/renderField';


const Form = (props) => {
    const { handleSubmit, cancel, submitFailed, error } = props;

    const submitForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        handleSubmit();
    };

    return (
        <form onSubmit={submitForm} className="row">
            <div className="col-12 col-lg-6 px-0 d-flex flex-wrap">
                <div className="col-12 col-lg-6 px-0 px-md-3 form-group">
                    <strong> Start Time </strong>
                    <Field
                        id="start_time_price"
                        name="start_time"
                        component={renderTimePicker}
                        minuteStep={1}
                        style={{ height: "none" }}
                        placeholder="12:00 PM"
                    />
                </div>
                <div className="col-12 col-lg-6 px-0 px-md-3 form-group">
                    <strong> End Time </strong>
                    <Field
                        id="end_time_price"
                        name="end_time"
                        component={renderTimePicker}
                        minuteStep={1}
                        style={{ height: "none" }}
                        placeholder="12:00 PM"
                    />
                </div>
                {submitFailed && error && (
                    <div className="col-12 form-group">
                        <div className="invalid-feedback-array text-danger align-self-center">
                            {error}
                        </div>
                    </div>
                )}
            </div>
            <div className="col-12 col-lg-3 px-0 px-md-3 form-group">
                <strong> Price </strong>
                <Field
                    name="price"
                    component={renderCurrency}
                    placeholder="$50.00"
                />
            </div>
            <div className="col-12 col-lg-3 px-0 px-md-3 form-group">
                <strong>Min Driver Duration</strong>
                <Field
                    name="min_drive_duration"
                    component={renderNumber}
                    decimalScale={2}
                    suffix="hrs"
                />
            </div>
            <div className="col-12 d-flex px-0 px-md-3 justify-content-between">
                <button
                    type="button"
                    onClick={cancel}
                    className="border bg-white small-shadow"
                    style={{
                        width: "3rem",
                        borderRadius: "8px",
                        border: "1px solid #929292",
                        color: "#929292",
                    }}
                >
                    <IconDash className="w-100 h-100"/>
                </button>
                <button type="button" className="btn btn-secondary" onClick={submitForm}>
                    SAVE
                </button>
            </div>
        </form>
    );
};

const FormTimePrices = reduxForm({
    form: "timePricesForm",
    validate: (data, { formValues }) => {
        const errors = {};
        if (!data.start_time) errors.start_time = "Required Field";
        if (!data.end_time) errors.end_time = "Required Field";
        else if(data.start_time && (parseInt(data.start_time.hour + data.start_time.minutes) > parseInt(data.end_time.hour + data.end_time.minutes)))
            errors.start_time = "Invalid Time";
        if (formValues && formValues.timePrice && formValues.timePrice.length > 0 && data.start_time && data.end_time){
            let found = formValues.timePrice.find(({ start_time, end_time }) => {
                    let oldStartTime = parseInt(start_time.hour + start_time.minutes);
                    let oldEndTime = parseInt(end_time.hour + end_time.minutes);
                    let startTime = parseInt(data.start_time.hour + data.start_time.minutes);
                    let endTime = parseInt(data.end_time.hour + data.end_time.minutes);
                    if((startTime < oldStartTime || startTime >= oldEndTime) &&
                        (endTime <= oldStartTime || endTime > oldEndTime))
                        return false;
                    else return true;
                });
            if (found)
                errors._error = "The time range already exists";
        }
        if (!data.price) errors.price = "Required Field";
        return errors;
    },
})(Form);

export default FormTimePrices;
