import React from "react";
import PropTypes from "prop-types";

import LoadMask from "../../Utils/LoadMask";
import Form from "../Create/Form";

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.load2Update(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        const { item, match = {}, updateCustomLabel } = this.props;
        const { params = {} } = match;
        const { id } = params;

        if (prevProps.item !== item) {
            updateCustomLabel(id, item.code);
        }
    }

    render() {
        const { update, loading, change, item } = this.props;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="grid-titulo padding-15">
                        <h2>
                            <strong>Update Shuttle Tracker</strong>
                        </h2>
                    </div>
                    <LoadMask loading={loading} blur>
                        {item && Object.keys(item).length > 0 && (
                            <Form
                                onSubmit={update}
                                change={change}
                                item={item}
                            />
                        )}
                    </LoadMask>
                </div>
            </div>
        );
    }
}
