import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Field, reduxForm } from "redux-form";
import LoadMask from '../../../Utils/LoadMask';
import { renderDatePicker } from '../../../Utils/renderField/renderField';
import moment from 'moment';
import IconTimes from '../../../../icons/times';


let ModalReport = (props) => {

    const { handleSubmit, loaderReport } = props;

    return (
        <Modal isOpen={props.modalReport} toggle={props.toggle} className="modal-dialog--big"
        >
            <ModalBody className="modal-body--big"
                style={{ maxHeight: "90vh", marginTop: "1%", marginBottom: "1%", display: "block" }}
            >
                <button type="button" onClick={props.toggle}
                    className="modal-body__close"
                >
                    <IconTimes className="smallIcon" />
                </button>
                <LoadMask loading={loaderReport} blur>
                    <div className="container-fluid col-12" style={{ minHeight: "20rem" }}>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group grid-container">
                                <div className="grid-titulo padding-15">
                                    <h2>
                                        <strong>Report Special Rates</strong>
                                    </h2>
                                </div>

                                <div className="padding-15 p-sm-0 pt-sm-4 pb-sm-1">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="startDate"> Start Date</label>
                                            <Field
                                                name="startDate"
                                                numberOfMonths={1}
                                                component={renderDatePicker}
                                                isOutsideRange={false}
                                                className="flex-1 w-100 m-2 p-0"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="endDate"> End Date </label>
                                            <Field
                                                name="endDate"
                                                numberOfMonths={1}
                                                component={renderDatePicker}
                                                isOutsideRange={false}
                                                className="flex-1 w-100 m-2 p-0"
                                            />
                                        </div>

                                        <div className="col-12 mt-sm-4">
                                            <div className="d-flex justify-content-start flex-column-reverse flex-sm-row align-items-stretch align-items-sm-center">
                                                <button
                                                    className="btn btn-secondary m-1"
                                                    type="button" onClick = {props.toggle}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary m-1"
                                                >
                                                    Generate Report
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </LoadMask>
            </ModalBody>
        </Modal>
    )
}

// In update form, picture is not a required field
ModalReport = reduxForm({
    form: "reportSpecialRatesForm",
    validate: (data) => {
        const errors = {};
        if(!data.startDate) errors.startDate = "Required field";
        if(!data.endDate) errors.endDate = "Required field";
        if(data.startDate && data.endDate){
            if(moment(data.startDate).isAfter(moment(data.endDate)))
                errors.startDate = "The date should be before the end date";
        }
        return errors;
    }
})(ModalReport);

export default ModalReport;