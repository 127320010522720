import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import numeral from "numeral";

import { find } from "../../../redux/modules/revenue";
import AnnualChart from "./AnnualChart";
import WeeklyChart from "./WeeklyChart";
import LoadMask from "../Utils/LoadMask";
import FutureEarnings from "./FutureEarnings";
import Select from "../Utils/FieldComponents/Select";

class RevenueContainer extends React.Component {
    constructor(props) {
        super(props);
        const year = moment().startOf("year");
        const prevYear = moment().subtract(1, "year").startOf("year");

        this.state = {
            year: { label: year.format("YYYY"), value: year },
            prevYear: { label: prevYear.format("YYYY"), value: prevYear },
        };
    }

    componentDidMount() {
        this.props.find();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ reports }) => reports === 'full'))
                    this.props.find();
            }
        }
    }

    getYears() {
        const initialDate = moment("2019-01-01");
        const currentDate = moment();
        const years = [];

        const diffYears = currentDate.diff(initialDate, "years");
        for (let i = 0; i <= diffYears; i++) {
            const cycleYear = moment(initialDate).add(i, "years");
            years.push({
                label: cycleYear.format("YYYY"),
                value: cycleYear,
            });
        }

        return years;
    }

    handlePrevYearChange = (e) => {
        const { find } = this.props;
        const {
            year: { value },
        } = this.state;
        const prevYear = e.value;
        find({ query: { year: value, prevYear } });
        this.setState({ prevYear: e });
    };

    render() {
        const { data, loading } = this.props;
        const weekOfYear = `${moment().format("wo")} week`;
        let currentYear = "";
        let prevYear = "";
        const parsedDataAnnual = data.totalEarned
            ? data.totalEarned.map((monthlyProfit, i) => {
                  const [date, profit] = monthlyProfit;
                  const [prevDate, lstYrProfit] = data.totalEarnedLstYr[i];
                  const month = moment(date).format("MMMM");
                  const year = moment(date).format("YYYY");
                  const lastYear = moment(prevDate).format("YYYY");
                  currentYear = year;
                  prevYear = lastYear;

                  return [
                      month,
                      profit,
                      numeral(profit).format("$0,0.00"),
                      `<div class="p-2"><div class="mb-2"><strong>${month}</strong></div><div>${year}: <strong>${numeral(
                          profit
                      ).format("$0,0.00")}</strong></div></div>`,
                      lstYrProfit,
                      numeral(lstYrProfit).format("$0,0.00"),
                      `<div class="p-2"><div class="mb-2"><strong>${month}</strong></div><div>${lastYear}: <strong>${numeral(
                          lstYrProfit
                      ).format("$0,0.00")}</strong></div></div>`,
                  ];
              })
            : [];

        const parsedDataWeekly = data.totalEarnedWeekly
            ? data.totalEarnedWeekly.map((dailyProfit) => {
                  const [day, profit] = dailyProfit;
                  const dayOfWeek = moment(day).format("dddd");
                  return [
                      dayOfWeek,
                      profit,
                      `<div class="p-2"><div class="mb-2"><strong>${dayOfWeek}</strong></div><div>${weekOfYear}: <strong>${numeral(
                          profit
                      ).format("$0,0.00")}</strong></div></div>`,
                      numeral(profit).format("$0,0.00"),
                  ];
              })
            : [];

        parsedDataAnnual.unshift([
            "Month",
            currentYear,
            { role: "annotation", type: "string" },
            { role: "tooltip", type: "string", p: { html: true } },
            prevYear,
            { role: "annotation", type: "string" },
            { role: "tooltip", type: "string", p: { html: true } },
        ]);
        parsedDataWeekly.unshift([
            "Days",
            weekOfYear,
            { role: "tooltip", type: "string", p: { html: true } },
            { role: "annotation", type: "string" },
        ]);

        const { year, prevYear: prevYr } = this.state;
        const { userInfo: { permission } } = this.props;

        return ( permission && permission.find(({ reports }) => reports === 'full') ?
            <LoadMask loading={loading} dark blur>
                <h1>Revenue</h1>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <label htmlFor="">Current Year</label>
                        <Select
                            labelKey="label"
                            valueKey="value"
                            options={this.getYears()}
                            onChange={this.handleCurrentYearChange}
                            value={year}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="">Previous Year</label>
                        <Select
                            labelKey="label"
                            valueKey="value"
                            options={this.getYears()}
                            onChange={this.handlePrevYearChange}
                            value={prevYr}
                        />
                    </div>
                </div>
                {parsedDataAnnual.length && (
                    <AnnualChart annualData={parsedDataAnnual} />
                )}
                {parsedDataWeekly.length && (
                    <WeeklyChart weeklyData={parsedDataWeekly} />
                )}
                {data.totalUnassignedTrips && (
                    <FutureEarnings
                        futureEarnings={data.totalUnassignedTrips}
                    />
                )}
            </LoadMask> : null
        );
    }
}

const mstp = (state) => ({
    ...state.revenue,
    userInfo: state.login.userInfo,
});

const mdtp = {
    find,
};

export default connect(mstp, mdtp)(RevenueContainer);
