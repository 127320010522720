import React from 'react';
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';

class AutoSuggest extends React.Component {

    state = {
        suggestions: []
    };

    getSuggestions = value => {
        if(typeof value === 'object')
            value = value[this.props.keyLabel];

        const inputValue = value.trim().toLowerCase();
        const { searchKey } = this.props;

        if(inputValue.length === 0) return []
        else {
            let result = this.props.suggestions.filter(item =>
                (searchKey && item[searchKey] && item[searchKey].toLowerCase().indexOf(inputValue) !== -1) ||
                (item[this.props.keyLabel] && item[this.props.keyLabel].toLowerCase().indexOf(inputValue) !== -1)
            );
            if(result.length === 0 && this.props.showNotFoundMsg && this.props.showNotFoundMsg == true)
                result = [{ notFound: true }];
            return result;
        }
    };

    renderSuggestion = suggestion => (
        suggestion && suggestion.notFound
            ? <span> {`${this.props.notFoundMsg || 'Not Found!'}`} </span>
            : <span id={suggestion[this.props.keyId]}>  {suggestion[this.props.keyLabel]} </span>
    )

    getSuggestionValue = suggestion => suggestion[this.props.keyLabel];

    renderSuggestionDefault = suggestion => (
        <span id={suggestion[this.props.keyId]}>  {suggestion[this.props.keyLabel]} </span>
    )

    onChange = (event, { newValue }) => {
        let children = event.target.children;
        this.props.input.onChange(this.props.onChangeValue
            ? this.props.onChangeValue(event, { newValue })
            : (children.length ? children[0].id : event.target.id ? event.target.id : event.target.value));

        if(this.props.onSelectOption){
            let isOptionFromList = children.length && children[0].id || event.target.id || undefined;
            if(isOptionFromList)
                this.props.onSelectOption(isOptionFromList)
        }
    };

    onSuggestionSelected = (event, selected) => {
        if(selected.method == 'enter' || selected.method == 'click'){
            this.props.onSelectOption(selected.suggestion[this.props.keyId || '_id'])
        }
    }

    onBlur = (event, { highlightedSuggestion }) => {
        if(highlightedSuggestion)
            this.onSuggestionSelected(event, { suggestion: highlightedSuggestion, method: 'enter' })
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({ suggestions: this.getSuggestions(value) });
    };

    onSuggestionsClearRequested = () => {
        this.setState({ suggestions: [] });
    };

    renderSuggestionsContainer = ({ containerProps, children }) => (
        <div {...containerProps}>
            <div className="suggestions-container">
                <div className="children" style={{ pointerEvents: `${children && children.props && children.props.items &&
                    children.props.items.find(item => item.notFound) != undefined ? "none" : "auto" }` }}>
                    {children}
                </div>
            </div>
        </div>
    );

    componentDidUpdate(prevProps, prevState){
        if(this.props.async){
            if(prevProps.suggestions && this.props.suggestions &&
                JSON.stringify(prevProps.suggestions) != JSON.stringify(this.props.suggestions)){
                    this.onSuggestionsFetchRequested(this.props.input)
            }
        }
    }

    render() {
        const { suggestions } = this.state;

        const {
            id,
            placeholder,
            meta: { touched, error },
            className,
            inputClassName,
            typeInput="text",
            disabled=false,
        } = this.props;

        const invalid = touched && error;

        return (
            <div className={className}>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    renderSuggestionsContainer={this.renderSuggestionsContainer}
                    onSuggestionSelected={this.onSuggestionSelected}
                    inputProps={{
                        id,
                        placeholder,
                        value: typeof this.props.input.value === 'object'
                            ? this.props.input.value[this.props.keyLabel]
                            : this.props.input.value,
                        name: this.props.input.name,
                        onBlur: this.onBlur,
                        onChange: this.onChange
                    }}
                    renderInputComponent={ inputProps => (
                        <input
                            {...inputProps}
                            className={classNames(`form-control pl-2 ${inputClassName}`, { 'is-invalid': invalid } )}
                            type={typeInput}
                            disabled={disabled}
                        />
                    )}
                />
                {invalid && <div className="invalid-feedback" style={{ display: "block" }}>{error}</div>}
            </div>
        );
    }
}

export default AutoSuggest;
