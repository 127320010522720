import { connect } from "react-redux";
import Update from "./ClientUpdate";
import {
    update,
    load2Update,
    setClientsQuery,
} from "../../../../redux/modules/clients";

const mdtp = { update, load2Update, setClientsQuery };

const mstp = state => ({
    userInfo: state.login.userInfo,
})

export default connect(mstp, mdtp)(Update);
