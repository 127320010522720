import React from "react";
import { connect } from "react-redux";
import Modal from 'react-responsive-modal';
import { actions, setModalCompanySelect } from '../../../redux/modules/liveryEdition';
import { Field, reduxForm } from 'redux-form';
import app from '../../../libs/apiClient';
import { renderSearchSelect, renderField } from '../Utils/renderField/renderField';
import _ from 'lodash';


const Form = (props) => {

    const loadCompanies = (search) => {
        const params = { $paginate: false, $populate: ['sharedCodes'] }
        if(search){
            params.$or = [
                { company: { $regex: _.escapeRegExp(search), $options: 'i'  } },
                { code: { $regex: _.escapeRegExp(search), $options: 'i'  } }
            ]
        }
        return app.service('/api/airline').find({ query: params }).then(result => {
            for(let i = 0; i < result.length; i++)
                result[i].label = `${result[i].code} ${result[i].company}`;

            result.sort((a,b) => a.code.localeCompare(b.code));
            result.unshift({
                company: 'None',
                label: 'None (credit card required)',
                noCompany: true,
            })
            return { options: result };
        }).catch(()=>{
            return { options: [] }
        })
    }

    const { handleSubmit, formValues } = props;

    return (
        <form onSubmit={handleSubmit} className="row">
            <div className="col-12">
                <div className="mb-3">
                    <strong> Company </strong>
                    <Field
                        name="company"
                        component={renderSearchSelect}
                        className="form-control"
                        labelKey="label"
                        valueKey="_id"
                        loadOptions={loadCompanies}
                        allKey
                    />
                </div>
                {formValues && formValues.company && formValues.company.noCompany ? (
                    <div className="mb-3">
                        <strong> Enter company name </strong>
                        <Field
                            name="companyName"
                            component={renderField}
                            className="form-control"
                        />
                    </div>
                ) : ''}
            </div>
            <div className="mt-4 col-12 d-flex justify-content-end">
                <button type="submit" className="btn btn-primary">
                    CONTINUE
                </button>
            </div>
        </form>
    )
}

const CompanyForm = reduxForm({
    form: 'companyForm',
    validate: data => {
        const errors = {};
        if(!data.company) errors.company = "Required Field";
        if(!data.companyName) errors.companyName = "Required Field";
        return errors;
    }
})(Form);


const ModalCompanySelect = (props) => {

    const { setModalCompanySelect, modalCompanySelect, setCompanyCreateForm } = props;

    return (
        <Modal open={modalCompanySelect}
            onClose={()=>setModalCompanySelect(false)}
            styles={{ modal: { minWidth: '30%', borderRadius: '8px' } }}
        >
            <div className="p-4">
                <div className="text-center mb-3">
                    <h3> NEW  TRIP </h3>
                    <label>Select Company</label>
                </div>
                <div className="mt-3">
                    <CompanyForm
                        {...props}
                        onSubmit={(values)=>{
                            setCompanyCreateForm(values);
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}

const mstp = state => ({
    formValues: state.form.companyForm && state.form.companyForm.values,
    modalCompanySelect: state.liveryEdition.modalCompanySelect,
})

const mdtp = {
    setCompanyCreateForm: actions.setCompanyCreateForm,
    setModalCompanySelect: setModalCompanySelect,
}

export default connect(mstp, mdtp)(ModalCompanySelect);
