import React from "react";
import { Field, reduxForm } from "redux-form";
import { renderField, renderSelectField } from "../../../Utils/renderField/renderField";

const Form = (props) => {

    const { handleSubmit, item, cancel } = props;

    const onSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleSubmit();
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <div className="form-group">
                    <Field
                        name="tag"
                        component={renderField}
                        className="mb-2"
                    />
                </div>

                <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <button type="button"
                        className="btn btn-link text-primary m-1 px-4"
                        onClick={cancel}
                    >
                        Cancel
                    </button>
                    <button type="submit"
                        className="btn btn-primary m-1 px-4"
                    >
                        Save
                    </button>
                </div>
            </div>
        </form>
    )
}

const AreaForm = reduxForm({
    form: "tagForm",
    validate: (data) => {
        const errors = {};
        return errors;
    }
})(Form);

export default AreaForm;
