import React from "react";
import Modal from "react-responsive-modal";
import LoadMask from "../../../Utils/LoadMask";
import EditLiveryForm from "./editForm";


const ModalForm = (props) => {
    const { open, loading, close, submit, dataShuttleTrip } = props;

    return (
        <Modal open={open} onClose={close}>
            <div className="py-4">
                <h4 className="mb-4 text-center">TRIP</h4>
                <LoadMask loading={loading} blur>
                    <div className="d-flex flex-wrap">
                        {dataShuttleTrip && (dataShuttleTrip.PUDate || dataShuttleTrip.PUTime) ? (
                            <React.Fragment>
                                {dataShuttleTrip && dataShuttleTrip.PUDate && (
                                    <div className="col-8 form-group">
                                        <strong> Date: </strong>
                                        <span>{dataShuttleTrip.PUDate}</span>
                                    </div>
                                )}
                                {dataShuttleTrip && dataShuttleTrip.PUTime && (
                                    <div className="col-4 form-group">
                                        <strong> Time: </strong>
                                        <span>{dataShuttleTrip.PUTime}</span>
                                    </div>
                                )}
                            </React.Fragment>
                        ) : ""}
                        {dataShuttleTrip && dataShuttleTrip.PickupDesc && (
                            <div className="col-12 form-group">
                                <strong> Pick Up: </strong>
                                <span>{dataShuttleTrip.PickupDesc}</span>
                            </div>
                        )}
                        {dataShuttleTrip && dataShuttleTrip.DropoffDesc && (
                            <div className="col-12 form-group">
                                <strong> Drop Off: </strong>
                                <span>{dataShuttleTrip.DropoffDesc}</span>
                            </div>
                        )}
                    </div>
                    <EditLiveryForm
                        cancel={close}
                        onSubmit={submit}
                    />
                </LoadMask>
            </div>
        </Modal>
    );
};

export default ModalForm;
