import React from 'react';
import PassengerForm from './PassengerForm';

const Passenger = ({
    from,
    from2,
    phone,
    country,
    fromAirport,
    clearPassenger,
    handlePassengerFormSubmit,
}) => {
    return (
        <div className="d-flex justify-content-center mt-5 p-3 bg-light radius-1">
            <PassengerForm
                from={from}
                from2={from2}
                phone={phone}
                country={country}
                clear={clearPassenger}
                fromAirport={fromAirport}
                onSubmit={handlePassengerFormSubmit}
            />
        </div>
    );
};

export default Passenger;
