import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { actions } from "../../../redux/modules/priceCheck";
import app from "../../../libs/apiClient";
import { renderSearchSelect } from "../Utils/renderField";
import { renderPlacesAutocomplete } from "../Utils/renderField/renderField";

const Form = (props) => {
    useEffect(() => {
        if (!props.airlineCodes || props.airlineCodes.length === 0)
            props.getAirlineCodes();
        if (!props.airportCodes || props.airportCodes.length === 0)
            props.getAirportCodes();
    }, []);

    const { handleSubmit, setDetails } = props;

    const loadAirlines = (search) => {
        const params = { $paginate: false };
        if (search) {
            params.$or = [
                { code: { $regex: _.escapeRegExp(search), $options: "i" } },
                { company: { $regex: _.escapeRegExp(search), $options: "i" } },
            ];
        }

        return app
            .service("/api/airline")
            .find({
                query: params,
            })
            .then((result) => {
                for (let i = 0; i < result.length; i++)
                    result[i].label = `${result[i].code} ${result[i].company}`;

                result.sort((a, b) => a.code.localeCompare(b.code));
                return { options: result };
            })
            .catch((error) => {
                console.log(error);
                return { options: [] };
            });
    };

    const loadAirports = (search) => {
        if (!search) return [];

        const [code] = search.trim().match(/^\w{2,3}(\s+)/g) || [search];
        const [name] = search.trim().match(/(\s+)(?!^\w{2,3}).+$/g) || [search];

        return app
            .service("/api/airport")
            .find({
                query: {
                    $paginate: false,
                    $or: [
                        {
                            code: {
                                $regex: _.escapeRegExp(code.trim()),
                                $options: "i",
                            },
                        },
                        {
                            name: {
                                $regex: _.escapeRegExp(name.trim()),
                                $options: "i",
                            },
                        },
                    ],
                    $limit: 3,
                },
            })
            .then((result) => {
                if (result.length > 0) {
                    for (let i = 0; i < result.length; i += 1) {
                        Object.assign(result[i], {
                            label: `${result[i].name} (${result[i].code})`,
                            formatted_address: `${result[i].name} (${result[i].code})`,
                            postal_code: result[i].zip,
                            street_number: result[i].street,
                            types: ["airport"],
                            fromDB: true,
                        });
                        delete result[i].street;
                        delete result[i].zip;
                    }
                }
                return result;
            })
            .catch(() => {
                return [];
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap">
                <div className="col-12 form-group">
                    <label> Select Customer </label>
                    <Field
                        name="company"
                        component={renderSearchSelect}
                        loadOptions={loadAirlines}
                        placeholder="AA - American Airlines"
                        valueKey="_id"
                        labelKey="label"
                        allKey
                    />
                </div>
                <div className="col-12 form-group">
                    <label> Pick-Up Location </label>
                    <Field
                        name="pickUp"
                        component={renderPlacesAutocomplete}
                        setDetails={setDetails}
                        pl="from"
                        // lat={from && from.lat}
                        // lng={from && from.lng}
                        additionalAddressInfo={["street_number", "route"]}
                        placeholder="DCA - Ronald Reagan Airport"
                        aditionalAutocomplete={loadAirports}
                        // disabled={!ableToEdit}
                    />
                </div>
                <div className="col-12 form-group">
                    <label> Drop-Off Location </label>
                    <Field
                        name="dropOff"
                        component={renderPlacesAutocomplete}
                        setDetails={setDetails}
                        pl="to"
                        // lat={from && from.lat}
                        // lng={from && from.lng}
                        additionalAddressInfo={["street_number", "route"]}
                        placeholder="Washington HIlton, 1919 Connecticut..."
                        aditionalAutocomplete={loadAirports}
                        // disabled={!ableToEdit}
                    />
                </div>
                <div className="col-12 mt-3 form-group">
                    <button
                        type="submit"
                        className="btn btn-primary btn-block py-2"
                    >
                        <strong>CHECK PRICE</strong>
                    </button>
                </div>
            </div>
        </form>
    );
};

const FormPrice = reduxForm({
    form: "formPriceCheck",
    validate: (data) => {
        const errors = {};
        if (!data.customer) errors.customer = "Required Field";
        if (!data.pickUp) errors.pickUp = "Required Field";
        if (!data.dropOff) errors.dropOff = "Required Field";
        return errors;
    },
})(Form);

const mstp = (state) => ({
    formValues: state.form.formPriceCheck && state.form.formPriceCheck.values,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(FormPrice);
