import { connect } from "react-redux";
import Create from "./VehicleCreate";
import { create } from "../../../../redux/modules/vehicles";

const mstp = state => ({
    userInfo: state.login.userInfo,
    loading: state.vehicles.loading
})

const mdtp = { create };

export default connect(mstp, mdtp)(Create);
