import { connect } from "react-redux";
import { change } from "redux-form";
import Update from "./ShuttleUpdate";
import { actions } from "../../../../redux/modules/shuttle";
import { actions as breadcrumbActions } from "../../../../redux/modules/breadcrumb";

const mstp = (state) => state.shuttle;

export default connect(mstp, {
    ...actions,
    change,
    updateCustomLabel: breadcrumbActions.updateCustomLabel,
})(Update);
