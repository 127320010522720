import React from "react";
import numeral from "numeral";

const FutureEarnings = ({ futureEarnings }) => (
    <h2 className="my-5">
        Pending earnings ${numeral(futureEarnings).format("0,0.00")}
    </h2>
);

export default FutureEarnings;
