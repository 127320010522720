import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import moment from 'moment';
import app, { STORAGE_KEY } from "./libs/apiClient";
import { _login, setUserInfo } from "./redux/modules/login";
import LoadMask from "./common/components/Utils/LoadMask";

const SuccessLoginOauth = ({
    component: Component,
    _login,
    setUserInfo,
    match,
    history,
    ...rest
}) => {

    const authenticate = () => {
        const { token } = match.params;
        if(token){
            window.localStorage.setItem(STORAGE_KEY, token);
            const url_state = JSON.parse(window.localStorage.getItem("URL_STATE") || {});
            app.authenticate().then(data =>{
                _login();
                setUserInfo(data.user);
                let path = '/';
                if (url_state && url_state.timestamp && moment().unix() - url_state.timestamp <= 1800) { // 30 mins
                    path = url_state.from.pathname;
                    window.localStorage.removeItem('URL_STATE')
                }
                history.replace(path)
            }).catch( (error) => {
                console.error(error);
                history.replace('/login');
            })
        } else {
            history.replace('/login');
        }
    }

    useEffect(()=>{
        authenticate();
    },[]);

    return (
        <Route {...rest} render={(props) => <LoadMask loading />} />
    );
};

const mdtp = { _login, setUserInfo };

export default connect(null, mdtp)(SuccessLoginOauth);
