import React from 'react';


const ArrowRight = () => (
    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
    </svg>
)

const FlightCard = (props) => (
    <div className="w-100 p-3 mb-4"
        style={{ border: '1px solid #929292', fontSize: "0.8rem",
        cursor: "pointer" }}
        onClick={props.onClick}
    >
        <div className="d-flex flex-row">
            <div style={{ flex: '12' }}>
                <div className = "flex-1">
                    <div className="d-flex flex-md-row flex-column">
                        <div className="flex-1 d-flex flex-column">
                            <span className="mb-2"> <strong> Id: </strong> {props.item.TripID} </span>
                            <span className="mb-2"> <strong> Date: </strong> {props.date} </span>
                        </div>
                        <div className="flex-1 d-flex flex-column">
                            <span className="mb-2"> <strong> Status: </strong> {props.item.Status} </span>
                            <span className="mb-2"> <strong> Time: </strong> {props.time} </span>
                        </div>
                    </div>
                </div>
                <div className="flex-1 d-flex flex-row">
                    <strong>Pax: </strong>
                    <div className="flex-1 mb-2">
                        <span> &nbsp;{props.item.Numofpassengers} {props.item.Numofpassengers === 1 ? 'Passenger' : 'Passengers'} </span>
                    </div>
                </div>
                <div className="flex-1 d-flex flex-row">
                    <strong>Pick Up: </strong>
                    <div className="flex-1 mb-2">
                        <span> &nbsp;{props.from} </span>
                    </div>
                </div>
                <div className="flex-1 d-flex flex-row">
                    <strong>Drop off: </strong>
                    <div className="flex-1 mb-2">
                        <span>  &nbsp;{props.to} </span>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-1 align-items-center justify-content-center">
                <ArrowRight/>
            </div>
        </div>
    </div>
)

export default FlightCard;
