import { connect } from "react-redux";
import BlogPostsGrid from "./BlogPostsGrid";
import { find, destroy, setSearch } from "../../../../redux/modules/blogPosts";

const mstp = (state) => ({
    ...state.tours,
    userInfo: state.login.userInfo,
});

const mdtp = { find, destroy, setSearch };

export default connect(mstp, mdtp)(BlogPostsGrid);
