import React from 'react';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import examplesMobile from 'libphonenumber-js/examples.mobile.json';
import { getExampleNumber } from 'libphonenumber-js';
import IconUserEdit from '../../icons/userEdit';
import { IconTimesSharp } from "../../icons/times";
import { RenderCurrency } from '../Utils/renderField/renderReadField';
import { stopNames } from '../stops/Stops';
import { IconAddressBook, IconCalendarPen, IconMemoPad } from "../../icons/icons";

const { MEDIA_URL } = process.env;


class ItemCart extends React.Component {

    getPhoneTemplate = (country) => {
        let value = country;
        if(typeof country === 'object')
            value = country.value
        const phoneNumber = getExampleNumber(value, examplesMobile);
        let phonePlaceholder = phoneNumber ? phoneNumber.formatNational() : '';
        let phoneTemplate = phonePlaceholder ? `${phonePlaceholder}`.replace(/\d/g, '#') : '';
        return phoneTemplate;
    }

    render(){

        const { edit, editPassenger, remove, trip, index, setSpecialNotes, total } = this.props;
        const { itinerary = {} } = trip;
        const { stops = [] } = itinerary;

        return (
            <div className="d-flex flex-wrap">
                <div className="px-1 d-flex flex-wrap" style={{ width: 'calc(100% - 28px)' }}>
                    {trip.itinerary && (
                        <React.Fragment>
                            <div className="text-truncate">
                                <strong> Date:&nbsp; </strong>
                                {trip.itinerary.schedule.format('MM/DD/YYYY')}&nbsp;&nbsp;
                                {(trip.itinerary.time) ? moment(`${trip.itinerary.time.hour}:${trip.itinerary.time.minutes}`, 'HH:mm').format('LT') : ''}
                            </div>
                            <div className="text-truncate">
                                <strong> From:&nbsp; </strong>{(trip.itinerary.from) ? trip.itinerary.from.formatted_address : ''}
                            </div>
                            {stops && stops.length
                                ? stops.map((stop, index) => (
                                    <div
                                        className="text-truncate"
                                        key={index}
                                    >
                                        <strong> {stopNames[index] ? `${stopNames[index]} stop` : `#${index + 1} stop` } :&nbsp; </strong>{stop ? stop.formatted_address : ''}
                                    </div>
                                ))
                                : null}
                            <div className="text-truncate">
                                <strong> To:&nbsp; </strong>{(trip.itinerary.to) ? trip.itinerary.to.formatted_address : ''}
                            </div>
                            {trip.itinerary.fligth_number && (
                                <div className="text-truncate">
                                    <strong> Flight:&nbsp;</strong>{trip.itinerary.fligth_number.label}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                    {trip.passenger &&  trip.passenger.first_name && (
                        <div className="w-100 d-flex flex-column">
                            <div className="text-truncate">
                                <strong>PAX:</strong>&nbsp;
                                {trip.passenger.first_name}&nbsp;{trip.passenger.last_name}
                                {trip.passenger.phone
                                    ? <NumberFormat value={trip.passenger.phone} format={` - ${this.getPhoneTemplate(trip.passenger.country)}`} displayType="text"/>
                                    : ''
                                }
                            </div>
                        </div>
                    )}
                    {trip.vehicle && (
                        <div className="flex-1 d-flex flex-wrap align-items-end justify-content-start">
                            <div className="d-flex mr-2">
                                <strong>Total:</strong>&nbsp;<RenderCurrency value={trip.vehicle.price}/>
                            </div>
                            <div className="d-flex mr-2">
                                <strong>Vehicle:</strong>&nbsp;{trip.vehicle.vehicleCode}
                            </div>
                            <div className="d-flex align-items-end">
                                {trip.itinerary && trip.itinerary.duration && (
                                    <span className="mr-2">{trip.itinerary.duration} Hours</span>
                                )}
                                {(trip.itinerary.transferQt)
                                    ?
                                        <span>
                                            <strong>
                                                Type:
                                            </strong>
                                            &nbsp;{trip.itinerary.transferQt.slice(0,1).toUpperCase()}{trip.itinerary.transferQt.slice(1,trip.itinerary.transferQt.length)}
                                        </span>
                                    : ''
                                }
                            </div>
                        </div>
                    )}
                </div>
                <div className="px-1 d-flex flex-column align-items-center justify-content-start">
                    <div onClick={remove} className="mb-1">
                        <IconTimesSharp className="smallIcon text-primary clickable" />
                    </div>
                    <div onClick={edit} className="mb-1">
                        <IconCalendarPen className="smallIcon text-primary clickable" />
                    </div>
                    <div onClick={editPassenger} className="mb-1">
                        <IconAddressBook className="smallIcon text-primary clickable" />
                    </div>
                    <div onClick={setSpecialNotes}>
                        <IconMemoPad className={`smallIcon ${trip.passenger && trip.passenger.specialNotes ? 'text-success' : 'text-primary'} clickable`}/>
                    </div>
                </div>
                {index !== total - 1 && (
                    <hr className="w-100 border-dark"/>
                )}
            </div>
        )
    }
}

export default ItemCart;
