import { createActions, handleActions } from "redux-actions";
import { goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import app from "../../libs/apiClient";
import Swal from 'sweetalert2';
import { errorHandler } from '../../common/utility/constants';
import { actions as breadcrumbActions } from "./breadcrumb";
const service = app.service("/api/additionalCharges");

const typeOptions = [
    { value: "Standard", label: "Standard" },
    { value: "Hourly", label: "Hourly" },
    { value: "Both", label: "Both" },
]

// Actions
const LOADING = "LOADING_ADDITIONAL_CHARGES";
const SET_DATA = "SET_DATA_ADDITIONAL_CHARGES";
const SET_PAGE = "SET_PAGE_ADITIONAL_CHARGES";
const SET_UPDATE_DATA = "SET_UPDATE_DATA_ADDITIONAL_CHARGES";

const loading = (loading = true) => ({
    type: LOADING,
    loading,
})

const setData = ( data ) => ({
    type: SET_DATA,
    data,
})

const setPage = ( page ) => ({
    type: SET_PAGE,
    page,
})

const setUpdateData = ( updateData ) => ({
    type: SET_UPDATE_DATA,
    updateData,
})


const create = (formData) => (dispatch) => {
    const payload = {
        ...formData,
    };

    dispatch(loading());
    service.create(payload)
        .then(() => {
            dispatch(goBack());
        })
        .catch((e) => {
            dispatch(errorHandler(e));
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

const update = (formData) => async (dispatch) => {
    const payload = {
        ...formData,
    };

    dispatch(loading());

    try {
        await service.patch(payload._id, payload);
        dispatch(goBack());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

const destroy = (id) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        await service.remove(id);
        dispatch(find());
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

const find = (page = 1) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const query = { query: { $skip: (page - 1) * 10 } };
        const response = await service.find(query);
        dispatch(setData(response));
        dispatch(setPage(page));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

const load2Update = (id) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const additionalCharges = await service.get(id);
        dispatch(setUpdateData(additionalCharges));
        const { updateCustomLabel } = breadcrumbActions;
        dispatch(updateCustomLabel(id, additionalCharges.title));
        dispatch(initializeForm("additionalChargeForm", additionalCharges));
    } catch (e) {
        dispatch(errorHandler(e));
    } finally {
        dispatch(loading(false));
    }
};

export const actions = {
    create,
    update,
    destroy,
    find,
    load2Update,
}

// Reducers
const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_UPDATE_DATA]: (state, { updateData }) => ({...state,updateData, }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    page: 1,
    loading: false,
    updateData: {},
};

export default handleActions(reducers, initialState);
