import React, { useState } from 'react';
import { Field, FieldArray, reduxForm, change } from 'redux-form';
import Modal from 'react-responsive-modal';
import IconEdit from '../../../../../../icons/edit';
import IconPlus from '../../../../../../icons/plus';
import { IconTrashAlt } from '../../../../../../icons/trash';
import { renderDatePicker, renderSearchSelect, DatePickerAlt, renderFieldCheck, renderNumber } from '../../../../../Utils/renderField/renderField';
import { connect } from 'react-redux';
import { Tooltip } from 'reactstrap';
import { setModalRoutes } from '../../../../../../../redux/modules/crewAdmin/accountGroups';
import IconWatch from '../../../../../../icons/watch';
import RoutesList from './routeList';
import swal from 'sweetalert2';
import FormAirlineContacts from './contactForm';
import classNames from "classnames";
import { Async } from "react-select";
import app from '../../../../../../../libs/apiClient';
import { renderField, renderSelectField } from '../../../../../Utils/renderField/renderField';
import IconTimes from '../../../../../../icons/times';
import _, { find } from 'lodash';
import IconQuestionCircle from '../../../../../../icons/questionCircle';
import { airlineRoutesScheduleFrecuency } from '../../../../../../utility/constants';
import ExternalLink from '../../../../../../icons/externalLink';
import { Link } from 'react-router-dom';
import { settingsAirlinesContacts } from '../../../../../../../routesConfiguration/paths';


const renderContacts = (props) => {

    const { fields, meta: { error, submitFailed }, modalContacts, setModalContacts, change,
    setEditContact, editContact, disabled } = props;

    const removeContact = (index) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this contact? </h3>",
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                fields.remove(index)
            }
        })
    }

    const submitCreate = (values) => {
        fields.push(values);
        setModalContacts(false);
    }

    const submitEdit = (values) => {
        change(`contacts[${editContact.index}]`, values);
        setEditContact();
        setModalContacts(false);
    }

    const cancelModal = () => {
        if(editContact)
            setEditContact();
        setModalContacts(false)
    }

    const setEditContactItem = (item, index) => {
        setEditContact({ index, item });
        setModalContacts(true);
    }

    return (
        <div className="d-flex flex-wrap">
            <Modal open={modalContacts} onClose={()=>cancelModal()}
                styles={{ modal: { minWidth: "20%", width: "100%", maxWidth: "500px" } }}
            >
                <div className="d-flex flex-wrap">
                    <div className="col-12 pt-5 pb-4">
                        <div className="mb-3">
                            <h3 className="text-left"> {editContact ? 'Edit' : 'Select'} Contact </h3>
                            <FormAirlineContacts
                                onSubmit={(values) => {
                                    if(editContact)
                                        submitEdit(values);
                                    else
                                        submitCreate(values);
                                }}
                                cancel={()=>cancelModal()}
                                initialValues={ editContact && editContact.item ? { ...editContact.item  } : {} }
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            {fields.length > 0 ? (
                <div className="w-100">
                    {fields.map((itemField, index) => {
                        const item = fields.get(index)
                        return (
                            <div className="border p-2 d-flex flex-wrap" key={index}>
                                <div className="col-4 text-truncate"> {item && item.contact && item.contact.fullName} </div>
                                <div className="col-3 text-truncate"> {item && item.contact_type && item.contact_type.name} </div>
                                <div className="col-3 text-truncate"> {item && item.contact && item.contact.phone} </div>
                                {!disabled ? (
                                    <div className="col-2 d-flex">
                                        <div className="mx-1 clickable" onClick={()=>removeContact(index)}>
                                            <IconTrashAlt className="smallIcon"/>
                                        </div>
                                        <div className="mx-1 clickable" onClick={()=>setEditContactItem(item, index)}>
                                            <IconEdit className="smallIcon"/>
                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                        )
                    })}
                    {!disabled ? (
                        <button type="button" className="btn btn-light border my-2" onClick={() => setModalContacts(true)}>
                            <IconPlus className="smallIcon text-secondary" />
                        </button>
                    ) : ''}
                </div>
            ) : ( !disabled ?
                <button type="button" className="btn btn-light border rounded shadow bg-white p-2" style={{ width: '100px', height: '50px' }} onClick={() => setModalContacts(true)}>
                    <IconPlus className="w-100 h-100 text-secondary"/>
                </button> : ''
            )}
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger align-self-center">
                    {error}
                </div>
            }
        </div>
    )
}


const renderRoutes = (props) => {

    const { fields, meta: { error, submitFailed }, airlineId, disabled } = props;
    const invalid = submitFailed && error;

    const removeRoute = (index) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this Route? </h3>",
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                fields.remove(index)
            }
        })
    }

    const loadRoutes = (search) => {
        const params = { airline: airlineId, $populate: ['airline', 'zone']};
        if(search){
            params.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: 'i' } },
            ]
        }
        return app.service('/api/airline_route').find({ query: params }).then(result=>{
            for(let i = 0; i < result.data.length; i++){
                result.data[i].label = `${result.data[i].airline ? `${result.data[i].airline.code}` : ''}${result.data[i].zone ? ` - ${result.data[i].zone}` : ''}`;
            }
            return { options: result.data }
        }).catch((error)=>{
            console.log(error);
            return { options: [] }
        })
    }

    return (
        <div className="w-100 d-flex flex-wrap">
            <Async
                className={classNames(`form-control select-reset p-0`, {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    fields.push(e);
                }}
                cache={false}
                searchPromptText="Type to search"
                valueKey="_id"
                labelKey="name"
                placeholder="AA:DCA-WSHN"
                loadOptions={loadRoutes}
                disabled={disabled}
                style = {{
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                }}
            />
            <div className="w-100 d-flex flex-wrap mt-3">
                {fields.map((itemField, index) => {
                    const item = fields.get(index)
                    return (
                        <div key={index} className={`border border-dark px-3 py-2 m-1`} style={{ borderRadius: '15px' }}>
                            <label className="mb-0">{item.name}</label>
                            {!disabled && (
                                <span className="ml-3 clickable" onClick={()=>removeRoute(index)}>
                                    <IconTimes className="smallIcon text-white bg-dark rounded-circle p-1"/>
                                </span>
                            )}
                        </div>
                    )})
                }
            </div>
            {submitFailed && error &&
                <div className="invalid-feedback text-danger align-self-center">
                    {error}
                </div>
            }
        </div>
    )
}

const renderAirports = (props) => {
    const { fields, meta: { error, submitFailed }, airlineId, disabled } = props;
    const invalid = submitFailed && error;

    const removeAirport = (index) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this Airport? </h3>",
            showCancelButton: true,
            confirmButtonColor: "#D50032",
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE",
        }).then(({ value }) => {
            if (value) {
                fields.remove(index);
            }
        });
    };

    const loadAirports = (search) => {
        const params = { "account_group.airline": { $ne: airlineId } };
        if (search) {
            params.$or = [
                {
                    name: { $regex: _.escapeRegExp(search), $options: "i" },
                },
                {
                    description: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
                {
                    code: { $regex: _.escapeRegExp(search), $options: "i" },
                },
            ];
        }

        return app.service('/api/airport').find({ query: params }).then(result => {
            for(let i = 0; i < result.data.length; i++){
                result.data[i].label = `${result.data[i].code} - ${result.data[i].name}`
            }
            return { options: result.data }
        }).catch(() => {
            return { options: [] }
        });
    }

    return (
        <div className="w-100 d-flex flex-wrap">
            <Async
                className={classNames(`form-control select-reset p-0`, {
                    "is-invalid": invalid,
                })}
                onChange={(e) => {
                    fields.push(e);
                }}
                cache={false}
                searchPromptText="Type to search"
                valueKey="_id"
                labelKey="label"
                placeholder="IAD"
                loadOptions={loadAirports}
                disabled={disabled}
                style = {{
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                }}
            />
            <div className="w-100 d-flex flex-wrap mt-3">
                {fields.map((itemField, index) => {
                    const item = fields.get(index);
                    return (
                        <div key={index} className={`border border-dark px-3 py-2 m-1`} style={{ borderRadius: '15px' }}>
                            <label className="mb-0">{item.code}</label>
                            {!disabled && (
                                <span className="ml-3 clickable" onClick={()=>removeAirport(index)}>
                                    <IconTimes className="smallIcon text-white bg-dark rounded-circle p-1"/>
                                </span>
                            )}
                        </div>
                    )})
                }
            </div>
            {submitFailed && error && (
                <div className="invalid-feedback text-danger align-self-center">
                    {error}
                </div>
            )}
        </div>
    );
};

const Form = (props) => {
    const [tooltip, setTooltip] = useState(false);

    const { handleSubmit, cancel, formValues, airlineId, airline, isUpdate = false, change, userInfo: { permission },
        modalRoutes, setModalRoutes, modalContacts, setModalContacts, setEditContact, editContact
    } = props;
    const disabled =
        permission.find(({ updateAirlineCrew }) => updateAirlineCrew === true) && isUpdate
            ? false
            : permission.find(({ createAirlineCrew }) => createAirlineCrew === true) && !isUpdate
                ? false
                : true;

    const loadAccountOwner = (search) => {
        const params = {};
        if (search) {
            params.$or = [
                {
                    company_name: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
            ];
        }
        return app
            .service("/api/account_owner")
            .find({ query: params })
            .then((result) => {
                return { options: result.data };
            })
            .catch((error) => {
                console.log(error);
                return { options: [] };
            });
    };

    const loadAccounts = (search) => {
        const params = { airline: airlineId, $populate: ["airline"] };
        if (search) {
            params.$or = [
                {
                    acct_name: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
            ];
        }
        return app
            .service("/api/airline_account")
            .find({ query: params })
            .then((result) => {
                for (let i = 0; i < result.data.length; i++){
                    result.data[i].label = `${result.data[i].airline ? `${result.data[i].airline.code} - ` : ''}${result.data[i].acct_name}`;
                }
                return { options: result.data };
            })
            .catch((error) => {
                console.log(error);
                return { options: [] };
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <Modal open={modalRoutes} onClose={() => setModalRoutes(false)}>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 pt-5 pb-4">
                            <RoutesList
                                airlineId={airlineId}
                                disabled={disabled}
                                account_group={props.id}
                            />
                        </div>
                    </div>
                </Modal>
                <div className="col-12 form-group">
                    <strong> Contract ID </strong>
                    <Field
                        name="contractId"
                        component={renderField}
                        className="form-control"
                        disabled={disabled}
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <strong> Effective Date </strong>
                    <Field
                        name="effectiveDate"
                        component={DatePickerAlt}
                        className="my-2 p-0"
                        disabled={disabled}
                    />
                </div>
                <div className="col-12 col-md-6 form-group">
                    <strong> Expiration Date </strong>
                    <Field
                        name="expirationDate"
                        component={DatePickerAlt}
                        className="my-2 p-0"
                        disabled={disabled}
                    />
                </div>
                <div className="col-12 form-group">
                    <Field
                        name="autoRenew"
                        component={renderFieldCheck}
                        label=" Check this box if the contract is set to automatically renew"
                        className="form-control"
                        disabled={disabled}
                    />
                </div>
                {formValues && formValues.autoRenew === true && (
                    <React.Fragment>
                        <div className="col-12 col-md-6 form-group">
                            <strong> Auto Renewal Period </strong>
                            <Field
                                name="autoRenewalPeriod"
                                component={renderNumber}
                                suffix="mos"
                                className="flex-1 w-100 my-2 p-0"
                                disabled={disabled}
                            />
                        </div>
                        <div className="col-12 col-md-6 form-group">
                            <strong> Percent Increase </strong>
                            <Field
                                name="percentIncrease"
                                component={renderNumber}
                                suffix="%"
                                className="flex-1 w-100 my-2 p-0"
                                disabled={disabled}
                            />
                        </div>
                    </React.Fragment>
                )}
                {/* Account_group */}
                <div className="col-12 form-group">
                    <h4> Account Group </h4>
                </div>
                <div className="col-12 form-group">
                    <strong> Name: </strong>
                    <Field
                        name="account_group_name"
                        component={renderField}
                        className="form-control"
                        disabled={disabled}
                    />
                </div>
                <div className="col-12 form-group">
                    <strong> Owner: </strong>
                    <Field
                        name="owner"
                        component={renderSearchSelect}
                        className="form-control"
                        placeholder="Select Owner"
                        loadOptions={loadAccountOwner}
                        disabled={disabled}
                        valueKey="_id"
                        labelKey="company_name"
                        allKey
                    />
                </div>
                <div className="col-12 form-group">
                    <strong> PO Number: </strong>
                    <Field
                        name="customer_po"
                        component={renderField}
                        placeholder="91029345A"
                        className="form-control"
                        disabled={disabled}
                    />
                </div>
                <div className="col-12 form-group">
                    <strong> Payment Method: </strong>
                    <Field
                        name="payment_method"
                        component={renderSelectField}
                        disabled={disabled}
                        options={[
                            {
                                label: "Credit Card",
                                value: "creditCard",
                            },
                            {
                                label: "Billing Account",
                                value: "billingAccount",
                            },
                        ]}
                    />
                </div>
                <div className="col-12 form-group">
                    <strong> Commission Rate: </strong>
                    <Field
                        name="commission_rate"
                        component={renderNumber}
                        disabled={disabled}
                        suffix="%"
                        className=""
                    />
                </div>
                <div className="col-12 form-group">
                    <strong> Account: </strong>
                    <Field
                        name="account"
                        component={renderSearchSelect}
                        disabled={disabled}
                        className="form-control"
                        placeholder="Account"
                        loadOptions={loadAccounts}
                        allKey
                    />
                </div>

                <div className="col-12 form-group">
                    <strong> Schedule Frequency </strong>
                    <Field
                        name="scheduleFrecuency"
                        component={renderSelectField}
                        disabled={disabled}
                        placeholder="How often does the airline send their schedules?"
                        options={airlineRoutesScheduleFrecuency}
                        className=""
                    />
                </div>
                <div className="col-12 form-group d-flex">
                    <div className="d-flex">
                        <Field
                            name="has_daily_update"
                            component={renderFieldCheck}
                            disabled={disabled}
                            label="Daily Fax All"
                            className=""
                        />
                        <span id="DailyToolTip" className="clickable">
                            <IconQuestionCircle className="pl-1 smallIcon text-dark" />
                        </span>
                        <Tooltip
                            placement="right"
                            isOpen={tooltip}
                            target="DailyToolTip"
                            toggle={() => setTooltip(!tooltip)}
                        >
                            Does the company provide a daily schedule update for the next day?
                        </Tooltip>
                    </div>
                </div>
                <div className="col-12 form-group d-flex">
                    <div className="d-flex">
                        <Field
                            name="ad_hoc"
                            component={renderFieldCheck}
                            disabled={disabled}
                            label="AD HOC"
                            className=""
                        />
                    </div>
                </div>

                {formValues && formValues.ad_hoc ? (
                    <div className="col-12 form-group">
                        <strong> Account: </strong>
                        <Field
                            name="account_hoc"
                            component={renderField}
                            disabled={disabled}
                            className="form-control"
                            placeholder="Account Hoc"
                        />
                    </div>
                ) : (
                    ""
                )}

                <div className="col-12 form-group">
                    <div className="d-flex justify-content-between">
                        <h5 className="bold"> Contacts </h5>
                        <Link
                            to={settingsAirlinesContacts}
                            target="_blank"
                            className="text-primary d-flex align-items-center"
                        >
                            <span className="d-none d-md-inline">
                                Airline Contacts
                            </span>
                            <div
                                style={{ width: "15px", height: "13px" }}
                                className="d-flex pl-1"
                            >
                                <ExternalLink className="w-100 h-100" />
                            </div>
                        </Link>
                    </div>
                    <FieldArray
                        name="contacts"
                        component={renderContacts}
                        modalContacts={modalContacts}
                        setModalContacts={setModalContacts}
                        change={change}
                        setEditContact={setEditContact}
                        editContact={editContact}
                        disabled={disabled}
                    />
                </div>

                <div className="col-12 form-group">
                    <strong> Airports: </strong>
                    <FieldArray
                        name="airports"
                        component={renderAirports}
                        disabled={disabled}
                        airlineId={airlineId}
                    />
                </div>

                <div className="col-12 form-group">
                    <strong> Routes: </strong>
                    <FieldArray
                        name="routes"
                        component={renderRoutes}
                        disabled={disabled}
                        airlineId={airlineId}
                    />
                </div>
                <div className="col-12 form-group my-3">
                    <button
                        type="button"
                        className="btn btn-light bg-white btn-block py-2 border small-shadow d-flex align-items-center justify-content-center mb-3"
                        onClick={() => setModalRoutes(true)}
                    >
                        <label className="mb-0 mr-1 clickable">VIEW ROUTES</label>
                        <div className="" style={{ height: 25, width: 25 }}>
                            <IconWatch />
                        </div>
                    </button>
                </div>
                <div className="col-12 d-flex flex-column flex-md-row justify-content-end">
                    <button
                        type="button"
                        className="btn btn-link m-1"
                        onClick={cancel}
                    >
                        Cancel
                    </button>
                    {!disabled && (
                        <button type="submit" className="btn btn-primary m-1 px-5">
                            SAVE
                        </button>
                    )}
                </div>
            </div>
        </form>
    )
}

const AccountGroupForm = reduxForm({
    form: "accountGroupForm",
    destroyOnUnmount: false,
    validate: (data) => {
        const errors = {};
        if (!data.contractId) errors.contractId = "Required field";
        if (!data.effectiveDate) errors.effectiveDate = "Required field";
        if (!data.expirationDate) errors.expirationDate = "Required field";
        // Account_group
        if (!data.account_group_name)
            errors.account_group_name = "Required Field";
        // if(!data.owner) errors.owner = "Required Field";
        // if(!data.customer_po) errors.customer_po = "Required Field";
        if (!data.payment_method) errors.payment_method = "Required Field";
        if (!data.commission_rate) errors.commission_rate = "Required Field";
        if (!data.account) errors.account = "Required Field";
        if (data.airports && data.airports.length > 0) {
            let duplicatedAirport = data && data.airports
                ? data.airports.find((item, index) =>
                        data.airports.find((r, i) => JSON.stringify(r._id) === JSON.stringify(item._id) && i != index)
                    )
                : false;
            if (duplicatedAirport)
                errors.airports = { _error: `The Airport ${duplicatedAirport.code} is duplicated`, airport: duplicatedAirport._id }
        }
        if (data.routes && data.routes.length > 0) {
            let duplicatedRoute = data && data.routes
                ? data.routes.find((item, index) =>
                        data.routes.find((r, i) => JSON.stringify(r._id) === JSON.stringify(item._id) && i != index)
                    )
                : false;
            if (duplicatedRoute)
                errors.routes = { _error: `The Route ${duplicatedRoute.name} is duplicated`, route: duplicatedRoute._id }
        }
        if (data.ad_hoc) {
            if (!data.account_hoc) errors.account_hoc = "Required Field";
        }
        return errors;
    },
})(Form);

const mstp = (state) => ({
    formValues:
        state.form.accountGroupForm && state.form.accountGroupForm.values,
    modalRoutes: state.crewAdmin.accountGroups.modalRoutes,
    userInfo: state.login.userInfo,
});

const mdtp = {
    change,
    setModalRoutes,
};

export default connect(mstp, mdtp)(AccountGroupForm);
