import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import moment from "moment";
import _ from "lodash";
import { getAirline } from "./airlines";


const LOADING = "LOADING_CREW_ADMIN_ROUTES";
const LOADING_FORM = "LOADING_UPDATE_FORM_ROUTES";
const SET_DATA = "SET_AIRLINE_DATA_ROUTES";
const SET_PAGE = "SET_PAGE_AIRLINE_ROUTES";
const SET_ITEM = "SET_ITEM_AIRLINE_ROUTES";
const SET_SEARCH = "SET_SEARCH_AIRLINE_ROUTES";
const MODAL_RATES = "SET_MODAL_RATES_AIRLINES_ROUTES";
const EDIT_RATE = "SET_EDIT_RATE_AIRLINE_ROUTES";
const READ_RATES = "SET_READ_RATES_AIRLINE_ROUTES";
const ITEM_FORM_RATES = "SET_ITEM_FORM_RATES_AIRLINE_ROUTES";
const SWITCH_INACTIVE_ROUTES = "SWITCH_INACTIVE_ROUTES_AIRLINE_ROUTES";

export const find = (airline, page = 1, queryParams = {}) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $limit: 5,
            $skip: (page - 1) * 5,
            airline,
            $populate: [{ path: 'account_group', populate: ['account'] }],
            ...queryParams,
        }};

        const { search, inactiveRoutes } = getStore().crewAdmin.crewRoutes;

        if(search){
            params.query.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: 'i' }},
            ]
        }

        if(inactiveRoutes)
            params.query.active = false

        const data = await app.service('/api/airline_route').find(params);
        for(let i = 0; i < data.data.length; i++){
            let contracts = await app.service('/api/contract_route_price').find({ query: { $limit: 1, route: data.data[i]._id, $select: ['_id'] }});
            data.data[i].totalContracted = contracts.total;
        }
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch) => {
    dispatch(setLoadingForm(true));
    let result = false;
    try {
        await app.service('/api/airline_route').create(data);
        result = true;
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
    return result;
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let params = { query: { validateFuturePrice: true, $populate: ['zone', 'airport', 'account_group'] } };
        let data = await app.service('/api/airline_route').get(id, params);
        if(data.airport)
            data.airport.label = `${data.airport.code} - ${data.airport.name}`;
        // data.contacts = await app.service('/api/airline_route_contact').find({ query: { $paginate: false, route: id, $populate: ['contact', 'contact_type'] }});
        data.rates = await app.service('/api/contract_route_price').find({ query: { $paginate: false, route: id, $populate: ['vehicle'] }});

        for(let i = 0; i < data.rates.length; i++){
            data.rates[i].timePrice = await app.service('/api/time_based_price').find({ query: { $paginate: false, contract_route_price: data.rates[i]._id } });
            let rateExpirations = await app.service('/api/future_route_price').find({ query: { $paginate: false, current_route_price: data.rates[i]._id, is_expired: false, is_current: false } })
            
            const contractExpiredRates = await app.service("/api/future_route_price").find({
                query: {
                    $paginate: false,
                    current_route_price: data.rates[i]._id,
                    $or: [
                        { is_expired: true },
                        { is_current: true }
                    ]
                },
            });
            contractExpiredRates.sort((a, b) => {
                if (moment(a.effective_date, 'MM/DD/YYYY').isBefore(moment(b.effective_date, 'MM/DD/YYYY'))) {
                    return 1;
                } else if (moment(a.effective_date, 'MM/DD/YYYY').isAfter(moment(b.effective_date, 'MM/DD/YYYY'))){
                    return -1;
                }
                return 0;
            });
            data.rates[i].expiredRates = contractExpiredRates.slice(0,4);

            if(rateExpirations && rateExpirations.length > 0){
                for(let index = 0; index < rateExpirations.length; index++){
                    rateExpirations[index].timePrice = await app.service('/api/time_based_price').find({
                        query: { $paginate: false, future_route_price: rateExpirations[index]._id }
                    })
                }
            }
            data.rates[i].rateExpirations = rateExpirations;
        }
        console.log(data);
        dispatch(initializeForm('airlineRouteForm', data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (data) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    let result = false;
    try{
        await app.service('/api/airline_route').update(data._id, data);
        result = true;
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
    return result;
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.crewRoutes;
    try {
        await app.service('/api/airline_route').remove(id);
        dispatch(find(page))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setSearch = (search, airline, params) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH, search });
    dispatch(find(airline, 1, params));
}

export const cleanForm = () => (dispatch) => {
    dispatch(destroyForm('airlineRouteForm'));
}

export const switchInactiveRoutes = (inactiveRoutes, airlineId) => (dispatch) => {
    dispatch({ type: SWITCH_INACTIVE_ROUTES, inactiveRoutes });
    dispatch(find(airlineId))
}

export const setActiveInactive = (data) => async (dispatch) => {
    let result = false;
    dispatch(setLoadingForm(true));
    try {
        await app.service('/api/airline_route').patch(data._id, data);
        result = true;
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
    return result;
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE, page });
export const setData = (data) => ({ type: SET_DATA, data });
export const setItem = (item) => ({ type: SET_ITEM, item });
export const setModalRates = (modalRates) => ({ type: MODAL_RATES, modalRates });
export const setEditRates = (editRates) => ({ type: EDIT_RATE, editRates });
export const setReadRates = (readRates) => ({ type: READ_RATES, readRates });
export const setItemFormRates = (itemRates) => ({ type: ITEM_FORM_RATES, itemRates });

export const actions = {
    find,
    create,
    setSearch,
    cleanForm,
    load2Update,
    setModalRates,
    setItemFormRates,
    setEditRates,
    setReadRates,
    switchInactiveRoutes,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH]: (state, { search }) => ({ ...state, search }),
    [SET_ITEM]: (state, { item }) => ({ ...state, item }),
    [MODAL_RATES]: (state, { modalRates }) => ({ ...state, modalRates }),
    [EDIT_RATE]: (state, { editRates }) => ({ ...state, editRates }),
    [READ_RATES]: (state, { readRates }) => ({ ...state, readRates }),
    [ITEM_FORM_RATES]: (state, { itemRates }) => ({ ...state, itemRates }),
    [SWITCH_INACTIVE_ROUTES]: (state, { inactiveRoutes }) => ({ ...state, inactiveRoutes }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    modalRates: false,
    readRates: false,
    editRates: false,
    itemRates: undefined,
    inactiveRoutes: false,
    search: undefined,
};

export default handleActions(reducers, initialState);
