import { handleActions } from "redux-actions";
import Swal from "sweetalert2";
import moment from "moment";
import { goBack } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import airportCodes from '../../common/utility/airports.json';
import airlineCodes from '../../common/utility/airlines.json';
import app from "../../libs/apiClient";
import _ from 'lodash';
import { actions as breadcrumbActions } from "./breadcrumb";

// Constants
const service = app.service("/api/reservation/");

const DATA = "LEGACY_TRIP_EDITION_DATA";
const LOADER = "LEGACY_TRIP_EDITION_LOADER";
const LOADER_NEW_PRICE = "LEGACY_TRIP_EDITION_LOADER_NEW_PRICE";
const QUERY = "LEGACY_TRIP_EDITION_QUERY";
const INVALID_ADDRESS = "LEGACY_TRIP_EDITION_INVALID_ADDRESS";
const VEHICLES_TRIP_EDITION = "LEGACY_VEHICLES_TRIP_EDITION";
const GOOGLE_MAPS_ERROR = "LEGACY_GOOGLE_MAPS_ERROR";
const SET_IS_AIRPORT = "LEGACY_TRIP_EDITION_SET_IS_AIRPORT";
const SET_FLIGHTS_NUMBERS = "LEGACY_TRIP_EDITION_SET_FLIGHTS_NUMBERS";

// Pure Actions
const setData = (data) => ({
    type: DATA,
    data,
});

export const setQuery = (query) => ({
    type: QUERY,
    query,
});

const setLoader = (type, loading) => ({ type, loading })

const setInvalidAddress = (typeAddress) => ({
    type: INVALID_ADDRESS,
    typeAddress
})

const errorHandler = (error) => {
    console.log(error)
    let message = ""
    if(typeof(error) === "object")
        if(error.message)
            message = error.message;
        else
            for(let i = 0; i < error.length ; i++)
                message = message + error[i];
    else message = error;
    if(!message.length)
        message = "there was an error"
    return Swal.fire({
        title: "Error",
        text: `${message}`,
        icon: 'error',
        type: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#D50032",
    });
}

export const setDetails = (detail, key) => dispatch => {
    dispatch({ type: "LEGACY_TRIP_EDITION_"+key.toUpperCase(), [key]: detail });
    let isAirport = false, airportCode;
    if(detail && detail.types && detail.types.includes('airport') && "LEGACY_TRIP_EDITION_"+key.toUpperCase() === "LEGACY_TRIP_EDITION_FROM"){
        isAirport = true;
        airportCode = airportCodes.find(item => detail.formatted_address.match(new RegExp(item.code)) !== null);
    }

    if("LEGACY_TRIP_EDITION_"+key.toUpperCase() == "LEGACY_TRIP_EDITION_FROM"){
        dispatch({ type: SET_IS_AIRPORT, isAirport, airportCode });
        if(!isAirport){
            dispatch(change('tripEditForm', 'flightAirline', null));
            dispatch(change('tripEditForm', 'flightNumber', null));
        }
    }
    dispatch(changeAddress());
};

export const getFlightInfo = (flight) => (dispatch, getStore) => {
    const store = getStore().form.tripEditForm.values;
    let { airportCode } = getStore().legacyTripEdition;
    const { flightAirline, puDate } = store;

    flight = flight.match(/\d{2,4}/g);

    const params = { flightNumber: flight }
    if(airportCode)
        params.airportCode = airportCode.code;
    if(flightAirline)
        params.airlineCode = typeof(flightAirline) === 'object' ? flightAirline.code : flightAirline;
    if(puDate)
        params.date_of_service = moment(puDate).format('MM/DD/YYYY');

    app.service('/api/airlineApi').find({ query: params }).then( data => {
        if(data && data.result != null){
            let flightsNumbers = [{
                index: 0,
                flightNumber: data.result.flightNumber,
                airportCodeDep: data.result.airportCodeDep,
                airportCodeArr: data.result.airportCodeArr,
                date: data.result.date,
                time: data.result.time,
                label: `${data.result.airlineCode}${data.result.flightNumber} - ${data.result.airportCodeDep} - ${data.result.airportCodeArr} - ARR ${moment(`${data.result.date._} ${data.result.time._}`).format('LT')}`
            }];
            dispatch({ type: SET_FLIGHTS_NUMBERS, flightsNumbers });
        }
    }).catch( error => {
        console.error(error);
    })
}

export const onSelectFlight = (key) => (dispatch, getStore) => {
    const store = getStore().legacyTripEdition;
    let values = store.flightsNumbers[key]
    let date = moment(`${values.date._} ${values.time._}`);
    dispatch(change('tripEditForm', 'puTime', {hour:date.format('HH'), minutes: date.format('mm')} ));
    // dispatch(change('tripEditForm', 'puTimeNoTimezone', date.format('MM/DD/YYYY HH:mm') ));
    dispatch(change('tripEditForm', 'flightNumber', values ));
}

export const onSelectAirline = (key) => (dispatch, getStore) => {
    let values = airlineCodes.find(item => item._id == key);
    dispatch(change('tripEditForm', 'flightAirline', values));
}

// Actions
export const find = (id) => (dispatch, getState) => {
    dispatch(setLoader(LOADER, true));
    dispatch(clearData());
    service.get(id).then((data)=>{
        if(data.canceled){
            dispatch(goBack());
            throw 'This trip cannot be edited because it has already been cancelled.';
        }

        let total = 0;
        if(data.refund)
            total = data.refund.reduce((total, item) => total + item.refundAmount, 0);
        let totalCharges = 0;
        if(data.additionalCharges)
            totalCharges = data.additionalCharges.reduce((total,current)=> total + current.amount, 0);
        data.totalRefund = total;
        data.totalCharges = totalCharges;
        data.oldTotal = data.transactionAmount - data.totalRefund + totalCharges;
        data.puDate = data.puTimeNoTimezone ? moment(data.puTimeNoTimezone).format('L') : moment.parseZone(data.puTime).format('L');
        data.puTime = data.puTimeNoTimezone ? moment(data.puTimeNoTimezone).format() : moment.parseZone(data.puTime).format();
        data.dateCompare = data.puTimeNoTimezone ? moment(data.puTimeNoTimezone).format() : moment.parseZone(data.puTime).format();
        data.newTransactionAmount = data.transactionAmount;
        data.oldEmail = data.guestEmail;
        dispatch(setData(data));
        if(data.flightNumber && data.flightAirline)
            dispatch({ type: SET_FLIGHTS_NUMBERS, flightsNumbers: [data.flightNumber] });
        else
            data.no_flight_information = true;
        // delete data.puAddress;
        // delete data.doAddress;
        dispatch(initializeForm('tripEditForm', data));
        const { updateCustomLabel } = breadcrumbActions;
        dispatch(updateCustomLabel(id, data.confirmationNo));
        if(getState().legacyTripEdition.googleMapsError == true){
            Swal.fire({
                type: "error",
                title: 'Google maps error',
                html: '<div> If this continues please contact </div>' +
                    '<a href="mailto:web-admin@connectbbc.com" target="_blank"> web-admin@connectbbc.com </a>'
            })
        }
    })
    .catch((error)=>{
        errorHandler(error)
    }).finally(()=>{
        dispatch(setLoader(LOADER, false));
    })
};

export const chargesList = (search) => (dispatch, getStore) => {
    const { data } = getStore().legacyTripEdition;
    const query = { query: { $skip: 0 } };
    if(data.hourlyDuration) query.query.$or = [ { type: 'Hourly' }, { type: 'Both' } ]
    else query.query.$or = [ { type: 'Standard' }, { type: 'Both' } ]

    if(search) query.query.title = { $regex: _.escapeRegExp(search) }
    return app.service("/api/additionalCharges").find(query).then(response=>{
        return { options: response.data };
    })
}

export const clearData = () => (dispatch) => {
    dispatch(setData({}));
    dispatch(setVehiclesData([]));
    dispatch({ type: SET_FLIGHTS_NUMBERS, flightsNumbers: [] });
    dispatch({ type: "LEGACY_TRIP_EDITION_FROM", from: initialState.from });
    dispatch({ type: "LEGACY_TRIP_EDITION_TO", to: initialState.to });
    dispatch({ type: SET_IS_AIRPORT, isAirport: false, airportCode: undefined });
}

export const editReservation = (values) => (dispatch,getStore) => {
    const { from, to, } = getStore().legacyTripEdition;
    Swal.fire({
        title: "Are you sure?",
        text: `The trip will be edited, the amount of the trip could change`,
        type: "warning",
        confirmButtonText: "Yes",
        confirmButtonColor: "#D50032",
        showCancelButton: true,
    }).then((result) => {
        if (result.value) {
            dispatch(setLoader(LOADER, true));

            const distance = {
                latFrom: from.lat,
                lngFrom: from.lng,
                latTo: to.lat,
                lngTo: to.lng,
            };
            if(values.hourlyDuration) {
                distance.duration = values.hourlyDuration;
                values.isHourly = true;
            }

            values.distance = [distance];

            let newTotalCharges = 0;
            if(values.additionalCharges)
                newTotalCharges = values.additionalCharges.reduce( (total, current) =>
                    total + (current.amount ? parseFloat(current.amount) : 0), 0 );
            values.newTotalCharges = newTotalCharges;

            let formatPuDate = moment(values.puDate).format('YYYY-MM-DD');
            let formatPuTime = values.puTime == 'object'
                ? `${values.puTime.hour}:${values.puTime.minutes}:00`
                : moment(values.puTime).format('HH:mm:ss');
            const newPuTime = moment(`${formatPuDate} ${formatPuTime}`).format('YYYY-MM-DDTHH:mm:ss')

            values.pu_time = [newPuTime]
            values.from = from;
            values.to = to;
            values.postal_code = from.postal_code;
            values.to_postal_code = to.postal_code;
            app.service('api/authorize-charge').patch(values._id, values).then(data=>{
                Swal.fire({
                    title: "Success",
                    text: `The Trip has been edited`,
                    type: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: "#D50032",
                }).then((result) => {
                    dispatch(goBack());
                    dispatch(setData({}))
                })
            }).catch((error) => {
                errorHandler(error)
            }).finally(()=>{
                dispatch(setLoader(LOADER, false))
            });
        }
    })
}

export const changeVehicle = (values) => async (dispatch, getStore) => {
    const store = getStore().legacyTripEdition;
    dispatch(setLoader(LOADER_NEW_PRICE, true));
    let newTransactionAmount = 0;
    const { from, to, data: { hourlyDuration, promoCode, rewardPoints, specialPointToPoint } } = store;
    const { values: formValues } = getStore().form.tripEditForm;
    let { puDate, puTime, puTimeNoTimezone } = formValues;
    if(from && to){
        const query = {
            latFrom: from.lat,
            lngFrom: from.lng,
            latTo: to.lat,
            lngTo: to.lng,
            postal_code: from.postal_code,
            to_postal_code: to.postal_code,
            schedule: `${puDate} ${typeof puTime === 'object' ? `${puTime.hour}:${puTime.minutes}` : moment(puTimeNoTimezone).format('HH:mm')}`,
            promoCode,
            rewardPoints,
        };
        if(hourlyDuration) query.duration = hourlyDuration;
        if(specialPointToPoint) query.specialTrip = specialPointToPoint;

        const result = await app.service('api/distance').get(values.baseRateId, { query });
        newTransactionAmount = result ? result.price : values.price;
    } else {
        newTransactionAmount = values.price;
    }
    dispatch(change('tripEditForm', 'vehicleType', values.vehicleType));
    dispatch(change('tripEditForm', 'vehicleClass', values.vehicleClass));
    dispatch(change('tripEditForm', 'vehiclePhoto', values.vehiclePhoto));
    dispatch(change('tripEditForm', 'baseRateId', values.baseRateId));
    dispatch(change('tripEditForm', 'vehicleLocation', values.vehicleLocation ? values.vehicleLocation : null));
    dispatch(change('tripEditForm', 'vehicleSpecialRate', values.vehicleSpecialRate ? values.vehicleSpecialRate : null));
    dispatch(change('tripEditForm', 'vehicleDescription', values.vehicleDescription));
    dispatch(change('tripEditForm', 'newTransactionAmount', newTransactionAmount));
    dispatch(setLoader(LOADER_NEW_PRICE, false));
}

export const changeHourly = (values) => (dispatch, getStore) => {
    const { data } = getStore().legacyTripEdition;
    data.hourlyDuration = values.value;
    dispatch(setData(data));
    dispatch(changeAddress());
}

export const setCharge = (Charge, Q, index) => (dispatch, getStore) => {
    let values = getStore().form.tripEditForm.values.additionalCharges;

    if(Charge){
        values[index].unitPrice = Charge.price || 0;
        values[index].aditionalId = Charge._id || null;
    }
    if(Q) values[index].Qcharge = parseInt(Q)

    values[index].amount = values[index].unitPrice * values[index].Qcharge;

    dispatch(change('tripEditForm', 'additionalCharges', values))
}

const setVehiclesData = (data) => async (dispatch,getStore) => {
    const store = getStore().legacyTripEdition;
    let newTransactionAmount = 0;
    let vehicles = [];
    for(let index = 0; index < data.length; index++){
        let item = data[index];
        vehicles.push({
            _id: (item.vehicleSpecialRate || item.vehicleLocation) ? (item.info.vehicleDefault._id || item.info.vehicleDefault) : item.info._id,
            vehicleType: item.info.type,
            vehicleClass: item.info.class,
            vehiclePhoto: item.info.photo,
            vehicleDescription: item.info.description,
            baseRateId: item._id,
            vehicleLocation: item.vehicleLocation,
            vehicleSpecialRate: item.vehicleSpecialRate,
            price: item.info.price,
            label: `${item.info.description} - ${item.info.class}`
        })
    }
    const indexVehicle = vehicles.findIndex( item => store.data.vehicleId == item._id);
    if(indexVehicle !== -1) {
        let item = vehicles[indexVehicle];
        const { from, to, data: { hourlyDuration, promoCode, rewardPoints, specialPointToPoint } } = store;
        if(from && to){
            const query = {
                latFrom: from.lat,
                lngFrom: from.lng,
                latTo: to.lat,
                lngTo: to.lng,
                promoCode,
                rewardPoints,
                postal_code: from.postal_code,
                to_postal_code: to.postal_code,
            };
            if(hourlyDuration) query.duration = hourlyDuration;
            if(specialPointToPoint) query.specialTrip = specialPointToPoint;

            const result = await app.service('api/distance').get(item.baseRateId, { query });
            if(result) newTransactionAmount = result.price;
            else newTransactionAmount = item.price;
        } else newTransactionAmount = item.price;
    }
    dispatch({type: VEHICLES_TRIP_EDITION, vehicles});
    dispatch(change('tripEditForm', 'newTransactionAmount', newTransactionAmount));
}

export const changeAddress = () => (dispatch, getStore) => {
    const { from, to, data: { hourlyDuration, specialPointToPoint }, } = getStore().legacyTripEdition;
    const { values } = getStore().form.tripEditForm;
    let {puDate, puTime, rewardPoints, puTimeNoTimezone } = values;

    if(from && from.postal_code && to && to.postal_code){

        dispatch(setLoader(LOADER_NEW_PRICE, true));

        const query = {
            query: {
                state: from.state,
                city: from.city,
                postal_code: from.postal_code,
                to_postal_code: to.potal_code,
                latFrom: from.lat,
                lngFrom: from.lng,
                latTo: to.lat,
                lngTo: to.lng,
                to_city: to.city,
                to_state: to.state,
                to_postal_code: to.postal_code,
                schedule: `${puDate} ${typeof puTime === 'object' ? `${puTime.hour}:${puTime.minutes}` : moment(puTimeNoTimezone).format('HH:mm')}`,
                timeHour: typeof puTime === 'object' ? puTime.hour : moment(puTime).format('HH'),
                timeMinutes: typeof puTime === 'object' ? puTime.minutes : moment(puTime).format('mm')
            }
        };

        if (hourlyDuration) query.query.duration = hourlyDuration;
        if(specialPointToPoint) query.specialTrip = specialPointToPoint;

        app.service('api/distance').find(query).then(res => {
            if (res) {
                if (!res.data.length)
                    Swal.fire({
                        title: 'This is outside of our standard service area',
                        type: 'warning',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                    });
                else
                    dispatch(setVehiclesData(res.data[0].vehicleBaseRates)).then( () => {
                        dispatch(setLoader(LOADER_NEW_PRICE, false));
                    });
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setLoader(LOADER_NEW_PRICE, false));
        });
    } else {
        if(from.postal_code && to.postal_code) setInvalidAddress('both')
        else if(from.postal_code) setInvalidAddress('to')
        else setInvalidAddress('from')
    }
}

const resendEmail = (id) => (dispatch, getStore) => {

    Swal.fire({
        title: "Resend confirmation email?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Send",
        cancelButtonText: "No, cancel",
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        reverseButtons: true,
    }).then((response) => {
        if (response.dismiss) return;

        dispatch(setLoader(LOADER, true));

        service.update(id, {
                action: "RESEND_EMAIL_RECEIPT",
                payload: { sendTo: 'passenger' },
            })
            .then(() => {
                Swal.fire({
                    title: "Email sent",
                    type: "success",
                    confirmButtonColor: "#D50032",
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: "Error",
                    text: err,
                    type: "error",
                    confirmButtonColor: "#D50032",
                });
            })
            .finally(() => {
                dispatch(setLoader(LOADER, false));
            });
    });
};

export const setGoogleMapsMsgError = () => (dispatch) => {
    dispatch({
        type: GOOGLE_MAPS_ERROR,
        googleMapsError: true
    });
}

export const actions = {
    find,
    setCharge,
    setDetails,
    chargesList,
    changeVehicle,
    changeHourly,
    clearData,
    resendEmail,
    editReservation,
    setGoogleMapsMsgError,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
};

// Reducers
const reducers = {
    [LOADER]: (state, { loading }) => ({ ...state, loading }),
    [LOADER_NEW_PRICE]: (state, { loading }) => ({ ...state, loaderNewPrice: loading }),
    [QUERY]: (state, { query }) => ({ ...state, query }),
    [DATA]: (state, { data }) => ({ ...state, data }),
    ["LEGACY_TRIP_EDITION_FROM"]: (state, { from }) => ({ ...state, from }),
    ["LEGACY_TRIP_EDITION_TO"]: (state, { to }) => ({ ...state, to }),
    [INVALID_ADDRESS]: (state, { typeAddress }) => ({ ...state, typeAddress }),
    [VEHICLES_TRIP_EDITION]: (state, { vehicles }) => ({ ...state, vehicles }),
    [GOOGLE_MAPS_ERROR]: (state, { googleMapsError }) => ({ ...state, googleMapsError }),
    [SET_IS_AIRPORT]: (state, { isAirport, airportCode }) => ({ ...state, isAirport, airportCode }),
    [SET_FLIGHTS_NUMBERS]: (state, { flightsNumbers }) => ({ ...state, flightsNumbers })
};

export const initialState = {
    from: {
        formatted_address:null,
        lng:null,
        lat:null,
        city:null,
        state:null,
        postal_code:null,
    },
    to: {
        formatted_address:null,
        lng:null,
        lat:null,
        city:null,
        state:null,
        postal_code:null,
    },
    data: {},
    page: 1,
    query: {},
    typeAddress: {},
    loading: false,
    loaderNewPrice: false,
    vehicles: [],
    googleMapsError: false,
    isAirport: false,
    airportCode: undefined,
    flightsNumbers: [],
};

export default handleActions(reducers, initialState);
