import React from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import { BreakLine } from "../../Utils/tableOptions";
import moment from "moment";
import Table from "Utils/Grid";


import { Field, reduxForm } from "redux-form";
import { validate } from "validate-redux-form";
import { image } from "../../../utility/form-validations";
import { connect } from "react-redux";
import {
    renderDropdownSelection,
    renderImagePicker,
    renderUnstyledFilePicker,
} from "../../Utils/renderField/renderField";
import LoadMask from '../../Utils/LoadMask';
import IconPlus from "../../../icons/plus";
import Search from "./search";
import { settingsTours, settingsToursCreate } from "../../../../routesConfiguration/paths";
const { MEDIA_URL } = process.env;

class VehiclesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.find();
        this.props.loadSetBanner();
    }

    submitImage = (values, bannerId) => {
        this.props.loadBanner(values, bannerId)
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ updateVehicles }) => updateVehicles)){
                    this.props.find(this.props.match.params.id);
                    this.props.loadSetBanner();
                }
            }
        }
    }

    render() {
        const { data, loading, loading_banner, photo, page, setSort, destroy, find, banner, setSearch, userInfo: { permission } } = this.props;
        return (
            <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container">
                        <h1 className="ml-0 ml-sm-auto">
                            <strong>Tours</strong>
                        </h1>
                        <div className="grid-title d-flex flex-row justify-content-center align-items-center">
                            {permission && permission.find(({ updateTours }) => !!updateTours) && (
                            <LoadMask loading={loading_banner} dark blur>
                                <div className="d-flex align-items-center flex-column flex-md-row">
                                    <Field
                                        photo={banner && banner[0] && banner[0].photo}
                                        name="picture"
                                        category="tours/"
                                        component={renderImagePicker}
                                        validateResolution={true}
                                        showImage={false}
                                        alternativeText="Change Main Display"
                                        onChange={(e, dFile)=>{
                                            if (dFile) this.submitImage(
                                                dFile,
                                                banner && banner[0] && banner[0]._id
                                            )
                                        }}
                                        className="uploaderBanner"
                                    />
                                </div>
                            </LoadMask>
                            )}
                            {permission && permission.find(({ createTours }) => !!createTours) && (
                                <Link
                                    className="btn btn-primary ml-auto flex-nowrap flex-sm-wrap"
                                    to={settingsToursCreate}
                                    style={{ padding: "0.375rem", borderRadius: "50%" }}
                                >
                                    <IconPlus className="smallIcon p-1"/>
                                </Link>
                            )}
                        </div>

                        <br />
                        {permission && permission.find(({ readTours }) => !!readTours) && (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-12 col-md-7 col-lg-6 px-0 px-md-3">
                                        <Search onSubmit={setSearch}/>
                                    </div>
                                    <div className="col-12 col-md-5 col-lg-6 px-0 px-md-3 pt-3 pt-md-0 d-flex justify-content-end align-items-center">
                                        <Field
                                            name="sort"
                                            component={renderDropdownSelection}
                                            className="d-flex justify-content-end"
                                            placeholder="Sort by"
                                            clearable
                                            scrolling
                                            options={[
                                                {key: "Caption", text: "Caption", value: "name"},
                                                {key: "Next_date", text: "Next Date", value: "next_date"},
                                                {key: "Pu_addres", text: "PU Address", value: "address"},
                                            ]}
                                            cb={setSort}
                                        />
                                    </div>
                                </div>
                                <br style={{ border: 0,  }}/>
                                <Table
                                    onPageChange={find}
                                    data={data}
                                    page={page}
                                    loading={loading}
                                >
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="_id"
                                        className="hide-column-header"
                                        tdStyle={{ padding: 0 }}
                                        isKey
                                        dataFormat={(cell, row) => (
                                            <div className="d-flex flex-sm-row flex-column">
                                                <div className="col-12 col-sm-4 col-md-3 px-0">
                                                    <img
                                                        src={`${MEDIA_URL}${row.photo}`}
                                                        style={{
                                                            width: "100%",
                                                            background: "transparent",
                                                            border: 0,
                                                            borderRadius: "5px",
                                                            height: "100%",
                                                            objectFit: "cover",
                                                            objectPosition: "center",
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-12 col-sm-8 col-md-9 d-flex flex-column py-2 py-md-4">
                                                    <div className="d-flex flex-column-reverse flex-md-row">
                                                        <span style={{ flex: 1}} className="">
                                                            {row.name &&
                                                                <label style={{ flex: 1}}>
                                                                    <strong> Caption:&nbsp;</strong>
                                                                    {row.name}
                                                                </label>
                                                            }
                                                        </span>
                                                        <span className="d-flex justify-content-end px-0 px-md-3" style={{ marginRight: "-15px" }}>
                                                            {activeFormatter({
                                                                editar: permission.find(({ updateTours }) => !!updateTours) ? settingsTours : undefined,
                                                                eliminar: permission.find(({ removeTours }) => !!removeTours) ? destroy : undefined,
                                                            })(cell, row)}
                                                        </span>
                                                    </div>
                                                    <div className="d-flex flex-column flex-md-row">
                                                        {row.address && (
                                                            <div className="d-flex flex-column flex-sm-row justify-content-start"
                                                                style={{ flex: 1.5 }}
                                                            >
                                                                <label>
                                                                    <strong> PU Address:&nbsp;</strong>
                                                                </label>
                                                                <label style={{ whiteSpace: "break-spaces" }}>{row.address}</label>
                                                            </div>
                                                        )}
                                                        <div className="d-flex flex-column d-none d-md-flex" style={{ flex: 1 }}>
                                                            <div className="flex-row justify-content-start d-none d-md-flex">
                                                                <label> <strong> Next:&nbsp;</strong> </label>
                                                                <div className="d-flex flex-column flex-md-row flex-wrap">
                                                                    {row.dateNext ? (
                                                                        <label style={{ whiteSpace: "break-spaces" }}>
                                                                            {moment(row.dateNext.date).format("MM/DD/YYYY")}
                                                                        </label>
                                                                    ): ( <label> N/A </label> )}
                                                                </div>
                                                            </div>
                                                            <div className="flex-row justify-content-start d-none d-md-flex">
                                                                <label> <strong> Time:&nbsp;</strong> </label>
                                                                <div className="d-flex flex-column flex-md-row flex-wrap">
                                                                    {row.dateNext && row.dateNext.nextHour ? (
                                                                        <label style={{ whiteSpace: "break-spaces" }}>
                                                                            {moment(row.dateNext.nextHour.hour).format('LT')}
                                                                        </label>
                                                                    ) : ( <label> N/A </label> )}
                                                                </div>
                                                            </div>
                                                            <div className="flex-row justify-content-start d-none d-md-flex">
                                                                <label> <strong> Tickets:&nbsp;</strong> </label>
                                                                <div className="d-flex flex-column flex-md-row flex-wrap">
                                                                    {row.dateNext && row.dateNext.nextHour ? (
                                                                        <label style={{ whiteSpace: "break-spaces" }}>
                                                                            {`${row.dateNext.nextHour.availableSeats}/${row.maxCount} (${row.maxCount - row.dateNext.nextHour.availableSeats})`}
                                                                        </label>
                                                                    ) : ( <label> N/A </label>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </Table>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const FormImageVehiclesGrid = reduxForm({
    form: 'imageTour',
    validate: (data) => {
        return validate(data, {
            picture: image(data.photo)("Required field"),
        });
    }
})(VehiclesGrid)

export default connect(null)(FormImageVehiclesGrid);
