import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";


const LOADING = "LOADING_CREW_ADMIN";
const LOADING_FORM = "LOADING_UPDATE_FORM";
const SET_LOCATIONS_DATA = "SET_LOCATIONS_DATA";
const SET_PAGE_LOCATIONS = "SET_PAGE_LOCATIONS";
const ITEM_LOCATIONS = "ITEM_LOCATIONS";
const LOCATIONS_ADDRESS = "LOCATIONS_ADDRESS";
const LOCATIONS_ADDRESS_2 = "LOCATIONS_ADDRESS_2";
const SET_SEARCH_LOCATIONS = "SET_SEARCH_LOCATIONS";

export const find = (page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $sort: { name: 1 },
            $limit: 5,
            $skip: (page - 1) * 5,
            // $collation: { locale: "en" }
        }};
        const search = getStore().crewAdmin.locations.search;
        if(search){
            params.query.$or = [
                { name: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { street: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { city: { $regex: _.escapeRegExp(search), $options: 'i'}},
                { state: { $regex: _.escapeRegExp(search), $options: 'i'}},
            ]
        }

        const data = await app.service('/api/scheduleLocation').find(params);
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (values) => async (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    let { page, address = {}, address2 } = getStore().crewAdmin.locations;
    try {
        const { state, postal_code, city, lat, lng } = address;
        let data = Object.assign({}, { ...values, state, zip: postal_code, city, street: `${address.street_number} ${address.route}`, address2, lat, lng });
        await app.service('/api/scheduleLocation').create(data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let data = await app.service('/api/scheduleLocation').get(id);
        dispatch(initializeForm('locationsForm', { ...data, address2: undefined }));
        await dispatch(setItem({
            ...data,
            address: `${data.street ? `${data.street}, ` : ''}${data.city}, ${data.state} ${data.zip}`,
            address2: data.address2
                ? `${data.address2.street_number ? data.address2.street_number : ''}${data.address2.route ? ` ${data.address2.route}, ` : ''}${data.address2.city}, ${data.address2.state}${data.address2.postal_code ? ` ${data.address2.postal_code}` : ''}`
                : ''
        }));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (values) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    let { page, address = {}, address2 } = getStore().crewAdmin.locations;
    try{
        const { state, postal_code, city, lat, lng } = address;
        let data = Object.assign({}, { ...values, state, zip: postal_code, city, street: `${address.street_number} ${address.route}`, address2, lat, lng });
        await app.service('/api/scheduleLocation').update(data._id, data);
        dispatch(find(page));
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let { page } = getStore().crewAdmin.locations;
    try {
        await app.service('/api/scheduleLocation').remove(id);
        dispatch(find(page))
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const setDetails = (address, address2) => dispatch => {
    dispatch({ type: address2 ? LOCATIONS_ADDRESS_2 : LOCATIONS_ADDRESS, address });
};

export const setSearch = (search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH_LOCATIONS, search });
    dispatch(find());
}

export const clearData = () => dispatch => {
    dispatch(setDetails());
    dispatch(setDetails(undefined, true));
    dispatch(setItem({}));
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE_LOCATIONS, page });
export const setData = (data) => ({ type: SET_LOCATIONS_DATA, data });
export const setItem = (item) => ({ type: ITEM_LOCATIONS, item });

export const actions = {
    find,
    create,
    clearData,
    setDetails,
    setSearch,
    load2Update,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE_LOCATIONS]: (state, { page }) => ({ ...state, page }),
    [SET_LOCATIONS_DATA]: (state, { data }) => ({ ...state, data }),
    [ITEM_LOCATIONS]: (state, { item }) => ({ ...state, item }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [SET_SEARCH_LOCATIONS]: (state, { search }) => ({...state, search }),
    [LOCATIONS_ADDRESS]: (state, { address }) => ({ ...state, address }),
    [LOCATIONS_ADDRESS_2]: (state, { address }) => ({ ...state, address2: address }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    item: {},
    search: undefined,
    address: undefined,
    address2: undefined,
};

export default handleActions(reducers, initialState);
