import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setModalCompanySelect } from '../../../redux/modules/liveryEdition';
import IconCalculator from "../../icons/calculator";
import IconList from "../../icons/list";
import IconLocation from "../../icons/location";
import IconPlus from "../../icons/plus";
import IconSearch from "../../icons/search";
import IconUpload from "../../icons/upload";
import ModalCompanySelect from "../liveryEdition/modalCompanySelect";
import { priceCheck, reservationsAirlineMultiTripSearch, reservationsAirlineSingleTripSearch, reservationsAirlineTrackTrip, reservationsAirlineUploads } from "../../../routesConfiguration/paths";

class Container extends React.Component {

    render() {
        const { userInfo: { permission }, setModalCompanySelect } = this.props;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                <ModalCompanySelect/>
                <div className="col-12 col-xl-10 d-flex flex-wrap">
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={reservationsAirlineSingleTripSearch}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconSearch className="h-100"/>
                            </div>
                            <span className="mt-3">Search</span>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <div
                            onClick={()=>setModalCompanySelect(true)}
                            style={{ color: '#000' }}
                            className="border border-dark clickable rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconPlus className="h-100"/>
                            </div>
                            <span className="mt-3">New</span>
                        </div>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={reservationsAirlineTrackTrip}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconLocation className="h-100"/>
                            </div>
                            <span className="mt-3">Track</span>
                        </Link>
                    </div>
                    {permission && permission.find(({ airlineSchedule }) => airlineSchedule !== 'none') && (
                        <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                            <Link to={reservationsAirlineUploads}
                                style={{ color: '#000' }}
                                className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                            >
                                <div style={{ height: '2.5rem' }}>
                                    <IconUpload className="h-100"/>
                                </div>
                                <span className="mt-3">Manifests</span>
                            </Link>
                        </div>
                    )}
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link to={reservationsAirlineMultiTripSearch}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconList className="h-100"/>
                            </div>
                            <span className="mt-3">Trip List</span>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link
                            to={priceCheck}
                            style={{ color: "#000" }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: "2.5rem" }}>
                                <IconCalculator className="h-100" />
                            </div>
                            <span className="mt-3">Price Check</span>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = (state) => ({
    userInfo: state.login.userInfo,
});

const mdtp = {
    setModalCompanySelect: setModalCompanySelect,
}

export default connect(mstp, mdtp)(Container);
