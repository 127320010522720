import React, { useState }  from "react";
import numeral from "numeral";
import { Field, reduxForm } from "redux-form";
import { TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment";
import Table from "Utils/Grid";
import { BreakLine } from "../../Utils/tableOptions";
import { tourCategories } from "../../../utility/constants";
import { activeFormatter } from "../../Utils/Acciones/Acciones";
import { renderDropdownSelection, renderSwitch, renderField } from "Utils/renderField/renderField";
import renderDatePicker from "Utils/renderField/renderDatePicker";
import Modal from "react-responsive-modal";
import IconSearch from "../../../icons/search";


const renderSearch = ({ id, disabled, input, placeholder, type, cb, }) => {
    return (
        <div className="w-100">
            <input
                id={id}
                {...input}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                className={"form-control"}
                onChange={(data) => {
                    input.onChange(data);
                    if (cb) cb();
                }}
                style={{ "paddingRight": "40px" }}
            />
        </div>
    );
};


const List = ({ find, data, loading, setSearch, clearDates,
    getReservationsList, tourResrv, loadingModal, pageList, permission}) => {

    const [modal_detail, openModal] = useState(false);
    const [rowId, setRowId] = useState(undefined);

    const openDetail = (row) => {
        if(row){
            setRowId(row);
            getReservationsList(row)
            openModal(true);
        } else {
            openModal(false);
        }
    }

    const renderModalDetail = () => (
        <Modal
            open={modal_detail}
            onClose={()=>openDetail()}
        >
            <div className="d-flex flex-column m-4">
                <h2 className="text-left"> {rowId && rowId.name} </h2>
                <hr/>
                <div className="d-flex flex-sm-row flex-column mb-2">
                    <div style={{ flex: 1 }}>
                        <h5> Reservations list </h5>
                        <Field
                            name="searchReservationList"
                            component={renderField}
                            className="form-control"
                            cb={() => getReservationsList(rowId)}
                        />
                    </div>

                    <div className="d-flex flex-column align-items-end justify-content-end"  style={{ flex: 1 }}>
                        <div className="d-flex justify-content-end align-items-end my-2">
                            <Field
                                name="category"
                                component={renderDropdownSelection}
                                className="d-flex justify-content-end"
                                placeholder="Sort by"
                                clearable
                                scrolling
                                options={[
                                    {key: "name", text: "Name", value: "name"},
                                    {key: "date", text: "Date", value: "date"},
                                    {key: "tour", text: "Tour", value: "tour"}
                                ]}
                                cb={() => getReservationsList(rowId)}
                            />
                        </div>
                        <div className="d-flex justify-content-end align-items-end">
                            <label htmlFor="canceled">Show canceled&nbsp;</label>
                            <Field
                                name="canceled"
                                component={renderSwitch}
                                height={22}
                                width={48}
                                cb={() => getReservationsList(rowId)}
                            />
                        </div>
                    </div>
                </div>

                <Table
                    onPageChange={(page) => getReservationsList(rowId, page)}
                    data={tourResrv}
                    loading={loadingModal}
                    page={pageList}
                    sizePerPage={15}
                >
                    <TableHeaderColumn
                        isKey
                        dataField="_id"
                        tdStyle={BreakLine}
                        thStyle={BreakLine}
                        className="hide-column-header"
                        dataFormat={(cell, row) => (
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column" style={{ flex: 1 }}>
                                    <label> <strong> Name: </strong> {row.guestFirstNm} {row.guestLastNm}</label>
                                    <label> <strong> Tour date: </strong> {moment(row.tourDt).format('MM/DD/YYYY')}</label>
                                    <label> <strong> Start Time: </strong> {moment(row.tourHr).format('HH:mm A')}</label>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="d-flex justify-content-end mb-2">
                                        <span className="px-3 py-1" style={{ background: "#C0C0C0", borderRadius: "0.4rem" }}>
                                            {row.noTickets}
                                        </span>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <span className="px-1 px-sm-3 py-1" style={{ background: "#C0C0C0", borderRadius: "0.4rem", fontSize: "0.8rem" }}>
                                            {row.transactionId}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    />
                </Table>
            </div>
        </Modal>
    )


    return(
        <div className="row">
            {renderModalDetail()}
            <div className="col-sm-12 pt-2">
                <div className="grid-container">
                    <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                        <h1 className="text-center">
                            <strong>Tours Reservations</strong>
                        </h1>
                    </div>

                    <hr className="mt-0" />

                    <div className="d-flex flex-column flex-md-row justify-content-md-between mb-1 mb-md-3">
                        <div className="d-flex flex-column">
                            <div className="ui icon input w-100 mb-2">
                                <Field
                                    name='search'
                                    type="text"
                                    placeholder = "Search by name, address, city"
                                    component={renderSearch}
                                    cb={setSearch}
                                    className="form-control"
                                />
                                <i aria-hidden="true" className="icon d-flex align-items-center justify-content-center">
                                    <IconSearch className="menu_icon"/>
                                </i>
                            </div>

                            <div className="d-flex justify-content-around">
                                <Field
                                    name="startDate"
                                    component={renderDatePicker}
                                    placeholder="Start Date"
                                    className="mr-2"
                                    timeFormat={false}
                                    showButton={false}
                                    cb={find}
                                />
                                <Field
                                    name="endDate"
                                    component={renderDatePicker}
                                    placeholder="End Date"
                                    timeFormat={false}
                                    showButton={false}
                                    cb={find}
                                />
                                <div className="d-flex justify-content-end align-items-end">
                                    <button type="button"
                                        className="btn btn-danger ml-1"
                                        onClick={() => clearDates()}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    {permission && permission.find(({ readTourReservations }) => !!readTourReservations) && (
                        <Table
                            onPageChange={find}
                            data={data}
                            loading={loading}
                            onRowClick={openDetail}
                        >
                            <TableHeaderColumn
                                isKey
                                dataField="_id"
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                className="hide-column-header"
                                dataFormat={(cell, row) =>
                                    <div className="d-flex flex-row">
                                        <div className="d-flex flex-column" style={{ flex: 1 }}>
                                            <div className="d-flex flex-column flex-md-row">
                                                {row.name && (
                                                    <div style={{flex: 1}}>
                                                        <label> <strong> Name:&nbsp;</strong> </label>
                                                        {row.name}
                                                    </div>
                                                )}
                                                {row.date && (
                                                    <div style={{flex: 1}}>
                                                        <label> <strong> PU Date:&nbsp;</strong></label>
                                                        {moment(row.date).format("MM/DD/YYYY")}
                                                    </div>
                                                )}
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <div className="d-flex flex-column flex-md-row">
                                                    <div className="d-none d-md-flex" style={{flex: 1 }}>
                                                        {row.address && (
                                                            <React.Fragment>
                                                                <label>
                                                                    <strong> PU Address:&nbsp;</strong>
                                                                </label>
                                                                <label style={{flex: 1, whiteSpace: "break-spaces" }}>
                                                                    {row.address}
                                                                </label>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                    {row.spots && row.spots.length > 0 && (
                                                        <div className="d-flex" style={{ flex: 1 }}>
                                                            <label> <strong> Itinerary:&nbsp;</strong> </label>
                                                            <div className="d-flex flex-column">
                                                                {row.spots.map( spot => (
                                                                    <label key={spot._id}>
                                                                        {`${moment(spot.hour).format('hh:mm A')} - ${row.duration &&
                                                                            moment(spot.hour).add(row.duration, 'h').format('hh:mm A')}`}
                                                                    </label>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column justify-content-start align-content-end mx-md-3">
                                            {row.ticketsSold !== undefined && (
                                                <div className="pt-2 d-flex justify-content-end">
                                                    <span  className="px-3 py-1" style={{ background: "#C0C0C0", borderRadius: "0.4rem" }}>
                                                        {row.ticketsSold}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            />
                        </Table>
                    )}
                </div>
            </div>
        </div>
    )
};

export default reduxForm({
    form: "tourResrvFilter",
})(List);
