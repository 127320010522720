import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { actions } from "../../../../../redux/modules/shuttle";
import LoadMask from "../../../Utils/LoadMask";
import FormCustomer from "./formCustomer";
import {
    getPathWithParams,
    liveryShuttlesCompany,
} from "../../../../../routesConfiguration/paths";

const Container = (props) => {
    const { loading, history } = props;

    return (
        <div className="d-flex flex-wrap justify-content-center pt-4 pt-lg-5">
            <div
                className="col-12 col-md-8 col-xl-6 rounded py-5"
                style={{ border: "1px solid #707070" }}
            >
                <h2 className="px-2 px-md-4 px-xl-5"> Schedule Search </h2>
                <div className="pt-4 pt-md-5 px-2 px-md-4 px-xl-5">
                    <LoadMask loading={loading} blur>
                        <FormCustomer
                            onSubmit={(values) => {
                                history.push(
                                    getPathWithParams(
                                        liveryShuttlesCompany,
                                        "company",
                                        `${values.company}-${moment(
                                            values.date
                                        ).unix()}`
                                    )
                                );
                            }}
                        />
                    </LoadMask>
                </div>
            </div>
        </div>
    );
};

const mstp = (state) => ({
    ...state.shuttle,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(Container);
