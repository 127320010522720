import React from "react";
import Select from "react-select";
import { pendingTransferColumns } from "../../../utility/constants";

export default class ReservationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { values: undefined };
        this.handleSelectChange = this.handleSelectChange.bind(this);
    }

    handleSelectChange(value) {
        this.setState({ value });
    }

    render() {
        const { close, onSubmit } = this.props;
        const { value } = this.state;
        return (
            <div className="d-flex flex-column">
                <div>Select the columns to download</div>
                <Select
                    multi
                    onChange={this.handleSelectChange}
                    options={pendingTransferColumns}
                    placeholder="Select the columns"
                    removeSelected={this.state.removeSelected}
                    simpleValue
                    value={value}
                />
                <div className="d-flex flex-row mt-4 justify-content-between">
                    <button
                        className="btn btn-secondar"
                        onClick={() => {
                            close();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            onSubmit(value);
                        }}
                    >
                        Download
                    </button>
                </div>
            </div>
        );
    }
}
