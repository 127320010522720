import React from "react";
import { connect } from "react-redux";
import GenericNav from "../GenericNav";
import IconCoins from "../../icons/coins";
import IconCalculator from "../../icons/calculator";
import {
    reportsRevenue,
    reportsTripReport,
} from "../../../routesConfiguration/paths";

const Container = (props) => {
    const {
        userInfo: { permission },
    } = props;

    const items = [
        {
            to: reportsRevenue,
            label: "Revenue",
            icon: <IconCoins className="h-100" />,
            show:
                permission &&
                permission.find(({ reports }) => reports !== "none"),
        },
        {
            to: reportsTripReport,
            label: "Trip Report",
            icon: <IconCalculator className="h-100" />,
            show:
                permission &&
                permission.find(({ reports }) => reports !== "none"),
        },
    ];

    return <GenericNav items={items} />;
};

const mathStateToProps = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mathStateToProps, null)(Container);
