import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import LoadMask from '../../Utils/LoadMask';
import TripInfo from './bookModal/TripInfo';
import Total from './bookModal/Total';
import Discount from './bookModal/Discount';
import Passenger from './bookModal/Passenger';
import PaymentForm from './bookModal/Payment';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import ConfMsg from './bookModal/ConfMsg';
import Receipt from './bookModal/Receipt';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import {
    setBook,
    setPassenger,
    setCheckout,
    setQuote,
    cleanDataModal,
} from "../../../../redux/modules/itinerary";
import IconTrash from "../../../icons/trash";
import IconEmail from "../../../icons/email";



const WizardButton = ({ onClick, highlight, available, children }) => (
    <button
        onClick={onClick}
        className={classNames(
            'bg-wizard flex-grow-1 mt-2 mt-md-4 p-2 text-secondary',
            {
                'text-light bg-wizard--selected': highlight,
                'bg-wizard--available': available,
            }
        )}
    >
        {children}
    </button>
);

const Wizard = ({ step, setStep, itinerary, children }) => {

    const routes = [
        {
            route: '/passenger',
            txt: 'PASSENGER INFO',
            highlight: step === 3,
            step: 3,
        },
        {
            route: '/payment',
            txt: 'PAYMENT',
            highlight: step === 4,
            step: 4,
        },
    ];

    return (
        <div className="d-flex flex-column reset-print">
            <div className="d-flex justify-content-center" >
                {/* style={{ backgroundColor: "hsl(206, 17%, 24%)" }}> */}
                <div className="col-12 pb-5 px-1">
                    <div className="d-flex flex-column flex-md-row flex-wrap">
                        {routes.map(({ route, txt, ...wizardProps }, i) =>
                            <WizardButton
                                {...wizardProps}
                                key={i}
                                onClick={() => setStep(routes[i].step)}
                            >
                                {txt}
                            </WizardButton>
                        )}
                        <Nav tabs className="vehicle-select d-none d-md-flex mt-1">
                            <NavItem key={1}>
                                <NavLink
                                    className={classNames({
                                        selected: step === 1,
                                    })}
                                    onClick={() => setStep(1)}
                                >
                                    <div className="d-flex justify-content-between clickable">
                                        <span className="font-info">
                                            Traveling from: {itinerary.puAddress}
                                        </span>
                                        <span className="font-info">
                                            Traveling to: {itinerary.doAddress}
                                        </span>
                                        <span className="font-info">
                                            Date:
                                            {moment(itinerary.puTimeNoTimezone).format('ddd, DD MMMM YYYY')}
                                        </span>
                                        <span className="font-info">
                                            Time:
                                            {moment(itinerary.puTimeNoTimezone).format('LT')}
                                        </span>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    )
};


const ModalQuote = ({
    quote,
    openDetails,
    PDFQuote,
    loading,
    remove,
    sendEmail,
    userInfo: { permission },
    setPassenger,
    setCheckout,
    setBook,
}) => {

    const [state, setState] = useState({ step: 2 });

    const setStep = step => setState(prevState => ({ ...prevState, step }));

    const handleOnBookClick = () => setStep(3);

    const handlePassengerFormSubmit = values => {
        setPassenger(values);
        setStep(4);
    };

    const handlePaymentFormSubmit = values => {
        setCheckout(values);
        setBook().then(isBooked => {
            if (isBooked)
                setStep(5);
        });
    };

    return (
        <LoadMask loading={loading || !quote || Object.keys(quote).length === 0 } blur>
            {state.step === 2 && (
                <div className="p-3 bg-light d-flex flex-column flex-wrap radius-1">
                    <div className="d-flex justify-content-end">
                        <button type="submit" onClick={()=>handleOnBookClick()}
                            style={{ width: 'auto', whiteSpace: 'nowrap' }}
                            className="btn btn-primary px-4 py-3 m-2"
                        >
                            <strong>BOOK NOW</strong>
                        </button>
                    </div>

                    <TripInfo itinerary={quote} />

                    <Discount
                        discount={quote.transactionDiscount}
                        price={quote.tripPrice || quote.transactionAmount}
                        gratuity={quote.gratuity}
                        gratuityPercent={quote.gratuityPercent}
                    />

                    <Total price={quote.transactionAmount} />

                    <div className="d-flex flex-column flex-sm-row mt-3">
                        <div className="ml-sm-auto d-flex flex-column flex-sm-row">
                            <button type="button" onClick={() => remove(quote._id)} className="btn btn-outline-primary px-4 py-3 m-1 d-flex justify-content-center">
                                <IconTrash className="smallIcon action_icon-primary" />&nbsp;
                                <strong>REMOVE</strong>
                            </button>
                            <button type="button" onClick={() => sendEmail(quote._id)} className="btn btn-outline-primary px-4 py-3 m-1 d-flex justify-content-center"
                                style={{ minWidth: "11.1rem" }}
                            >
                                <IconEmail className="smallIcon"/>&nbsp;
                                <strong>SEND EMAIL</strong>
                            </button>
                            <button type="button" onClick={() => openDetails()} className="btn btn-outline-primary px-4 py-3 m-1">
                                <strong>CLOSE</strong>
                            </button>
                            <button className="btn btn-primary m-1 py-3 px-4"
                                onClick={()=>PDFQuote(true)}
                            >
                                <strong> PRINT </strong>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {state.step === 3 && (
                <Wizard step={3} setStep={setStep} itinerary={quote}>
                    <Passenger
                        handlePassengerFormSubmit={handlePassengerFormSubmit}
                        fromAirport={quote.fromAirport}
                    />
                </Wizard>
            )}

            {state.step === 4 && (
                <Wizard step={4} setStep={setStep} itinerary={quote}>
                    <PaymentForm
                        handlePaymentFormSubmit={handlePaymentFormSubmit}
                    />
                </Wizard>
            )}

            {state.step === 5 && (
                <React.Fragment>
                    <ConfMsg />
                    <Receipt inModal/>
                </React.Fragment>
            )}
        </LoadMask>
    );
};

const mdtp = {
    setBook,
    setPassenger,
    setCheckout,
    setQuote,
};

const mstp = (state) => ({
    itinerary: state.itinerary,
    vehicle: state.vehicle.vehicle,
    userInfo: state.login.userInfo
})

export default connect(mstp, mdtp)(ModalQuote);
