import React, { useState } from "react";
import { connect } from "react-redux";
import { reduxForm, Field, FieldArray } from "redux-form";
import {
    renderCurrency,
    renderNumber,
    renderSearchSelect,
} from "../../../../../../Utils/renderField";
import app from "../../../../../../../../libs/apiClient";
import IconPlus from "../../../../../../../icons/plus";
import { IconTrashAlt } from "../../../../../../../icons/trash";
import FormAirlineExpirationRates from "./ExpirationRates";
import _ from "lodash";
import RenderTimePrice from "./renderTimePrice";
import numeral from "numeral";
import moment from "moment";

const RateExpirationItem = (props) => {
    const [showRemove, setShowRemove] = useState(false);

    const removeItem = () => {
        props.remove(props.index);
        setShowRemove(false);
    };

    const { item, readOnly } = props;

    return (
        <div className="col-12 col-lg-6">
            <div className="w-100 my-1 border rounded p-2 pb-4">
                {showRemove ? (
                    <React.Fragment>
                        <h5 className="text-center"> DELETE RECORD? </h5>
                        <div className="d-flex justify-content-center">
                            <button
                                type="button"
                                className="btn btn-danger mx-1 mt-1 py-1"
                                onClick={() => removeItem()}
                            >
                                <small>YES</small>
                            </button>
                            <button
                                type="button"
                                className="btn btn-success mx-1 mt-1 py-1"
                                onClick={() => setShowRemove(false)}
                            >
                                <small>NO</small>
                            </button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="w-100 d-flex justify-content-end">
                            {!readOnly ? (
                                <div
                                    className="mx-1 clickable"
                                    onClick={() => setShowRemove(true)}
                                >
                                    <IconTrashAlt className="smallIcon" />
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                        <div
                            className={`text-center text-truncate ${readOnly ? 'pt-3' : ''}`}
                        >
                            <b> Start Date:</b>&nbsp;{item && item.effective_date}
                        </div>
                        <div className="text-center text-truncate">
                            <b> New Price:</b>&nbsp;{item && `$${numeral(item.new_price).format('0,0.00')}`}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

const renderRatesExpirations = (props) => {
    const {
        fields,
        meta: { error, submitFailed },
        readOnly,
    } = props;
    const [newRateExpiration, setNewRateExpiration] = useState(false);

    return (
        <div className="d-flex flex-wrap">
            {newRateExpiration ? (
                <div className="w-100">
                    <FormAirlineExpirationRates
                        onSubmit={(values) => {
                            fields.push(values);
                            setNewRateExpiration(false);
                        }}
                        cancel={() => setNewRateExpiration(false)}
                    />
                </div>
            ) : fields.length > 0 ? (
                <div className="w-100">
                    <div className="row">
                        {fields.map((itemField, index) => {
                            const item = fields.get(index);
                            return (
                                <RateExpirationItem
                                    key={index}
                                    index={index}
                                    item={item}
                                    remove={fields.remove}
                                    readOnly={readOnly}
                                />
                            );
                        })}
                    </div>
                    {!readOnly ? (
                        <button
                            type="button"
                            className="btn btn-light border my-2"
                            onClick={() => setNewRateExpiration(true)}
                        >
                            <IconPlus className="smallIcon text-secondary" />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
            ) : !readOnly ? (
                <button
                    type="button"
                    className="btn btn-light border rounded shadow bg-white p-2"
                    style={{
                        width: "100px",
                        height: "50px",
                    }}
                    onClick={() => setNewRateExpiration(true)}
                >
                    <IconPlus className="w-100 h-100 text-secondary" />
                </button>
            ) : (
                ""
            )}
            {submitFailed && error && (
                <div className="invalid-feedback-array text-danger align-self-center">
                    {error}
                </div>
            )}
        </div>
    );
};

const Form = (props) => {
    const loadVehicles = (search) => {
        const params = {};
        if (search) {
            params.$or = [
                { type: { $regex: _.escapeRegExp(search), $options: "i" } },
                { class: { $regex: _.escapeRegExp(search), $options: "i" } },
                {
                    vehicleCode: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
            ];
        }

        return app
            .service("/api/vehicle")
            .find({ query: params })
            .then((result) => {
                return { options: result.data };
            })
            .catch((error) => {
                console.log(error);
                return { options: [] };
            });
    };

    const { handleSubmit, readOnly, formValues } = props;

    const submitForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        handleSubmit();
    };

    return (
        <form onSubmit={submitForm} className="row">
            <div className="col-12 form-group">
                <strong> Vehicle </strong>
                <Field
                    name="vehicle"
                    component={renderSearchSelect}
                    loadOptions={loadVehicles}
                    labelKey="type"
                    disabled={readOnly}
                    allKey
                />
            </div>
            <div className="col-12 col-lg-6 form-group">
                <strong> Price </strong>
                <Field
                    name="price"
                    component={renderCurrency}
                    className="form-control"
                    disabled={readOnly}
                />
            </div>
            <div className="col-12 col-lg-6 form-group">
                <strong>Farm-Out Rate</strong>
                <Field
                    name="farm_out_pay_rate"
                    component={renderCurrency}
                    className="form-control"
                    disabled={readOnly}
                />
            </div>
            <div className="col-12 col-lg-6 form-group">
                <strong>Max Passengers</strong>
                <Field
                    name="max_pax"
                    component={renderNumber}
                    disabled={readOnly}
                />
            </div>
            <div className="col-12 col-lg-6 form-group">
                <strong>Min Driver Duration</strong>
                <Field
                    name="min_drive_duration"
                    component={renderNumber}
                    disabled={readOnly}
                    decimalScale={2}
                    suffix="hrs"
                />
            </div>
            <div className="col-12 form-group mt-3">
                <h5 className="bold">Time based prices</h5>
                <FieldArray
                    name="timePrice"
                    component={RenderTimePrice}
                    readOnly={readOnly}
                    formValues={formValues}
                />
            </div>
            <div className="col-12 form-group mt-3">
                <h5 className="bold">Rate Expirations</h5>
                <FieldArray
                    name="rateExpirations"
                    component={renderRatesExpirations}
                    readOnly={readOnly}
                />
            </div>
            <div className="col-12 form-group mt-3">
                <h5 className="bold">Rate History</h5>
                {formValues && formValues.expiredRates ? (
                    <table className="table">
                        <thead>
                            <tr>
                                <th> Price </th>
                                <th> Effective Date </th>
                                <th> Expiration </th>
                            </tr>
                        </thead>
                        <tbody>
                            {formValues.expiredRates.length > 0 ? formValues.expiredRates.map((item, index, arr) => (
                                <tr key={index}>
                                    <td>${numeral(item.new_price).format('0,0.00')}</td>
                                    <td>{item.effective_date}</td>
                                    <td>
                                        {item.is_current ? (
                                            'Current'
                                        ) : (
                                            arr[index-1] ? moment(arr[index-1].effective_date, 'MM/DD/YYYY').subtract(1, 'd').format('MM/DD/YYYY') : ''
                                        )}
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="3" className="text-center bold">
                                        No Data
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                ) : ''}
            </div>
            {readOnly ? (
                <div className="col-12 d-flex flex-column flex-md-row justify-content-end">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => {
                            e.preventDefault();
                            props.cancel(e);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            ) : (
                <div className="col-12 d-flex flex-column flex-md-row justify-content-end">
                    <button type="submit" className="btn btn-primary">
                        SAVE RATE
                    </button>
                </div>
            )}
        </form>
    );
};

const FormAirlineRates = reduxForm({
    form: "airlineRatesForm",
    validate: (data) => {
        const errors = {};
        if (!data.vehicle) errors.vehicle = "Required Field";
        if (!data.price) errors.price = "Required Field";
        if (!data.max_pax) errors.max_pax = "Required Field";
        if (!data.min_drive_duration) errors.minDriver = "Required Field";
        return errors;
    },
})(Form);

const mstp = (state) => ({
    formValues:
        state.form &&
        state.form.airlineRatesForm &&
        state.form.airlineRatesForm.values,
});

export default connect(mstp)(FormAirlineRates);
