import React from "react";
import PropTypes from "prop-types";
import PromoCodeForm from "../Create/PromoCodeForm";

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.props.load2Update(this.props.match.params.id);
    }

    render() {
        const { update, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                {permission && permission.find(({ updatePromo }) => !!updatePromo) && (
                    <PromoCodeForm onSubmit={update} />
                )}
            </div>
        );
    }
}
