import React from "react";
import moment from "moment";

import Form from "./TourForm";

import LoadMask from "../../Utils/LoadMask";

const Create = ({ create, loading, userInfo: { permission } }) => (
    <LoadMask loading={loading} dark blur>
        <div className="row">
            {permission && permission.find(({ createTours }) => !!createTours) && (
                <Form
                    onSubmit={create}
                    initialValues={{
                        virtualItinerary: [
                            { spots: [{ hour: moment().startOf("hour") }] },
                        ],
                        shopping: false,
                        bathroom: false,
                        wifi: false,
                        kidsFriendly: false,
                        featured: false,
                    }}
                />
            )}
        </div>
    </LoadMask>
);

export default Create;
