import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { loadCheckout } from '../../../../../redux/modules/itinerary';
import PaymentForm from './PaymentForm';

class PaymentContainer extends React.Component {

    render() {
        return (
            <PaymentForm
                onSubmit={this.props.handlePaymentFormSubmit}
                cardNumber={this.props.cardNumber}
                country={this.props.country}
                initialValues = {{
                    country: {label: "United States", value: "US"},
                }}
                newPaymentMethod={this.props.newPaymentMethod}
            />
        )
    }
}

PaymentContainer.propTypes = {
    handlePaymentFormSubmit: PropTypes.func.isRequired,
};

const selector = formValueSelector('PaymentForm');
const mstp = state => ({
    loader: state.itinerary.loader,
    paymentInfo: state.itinerary.checkout,
    cardNumber: selector(state, 'card_number'),
    country: selector(state, 'country'),
});

const mdtp = {
    loadCheckout,
};

export default connect(mstp, mdtp)(PaymentContainer);
