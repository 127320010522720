export { default as Grid } from "./Grid";
export { default as Create } from "./Create";
export { default as Update } from "./Update";
export { default as Detail } from './Detail'
export { default as VehiclesUpdate } from './vehiclesUpdate';
export { default as ZipCodesUpdate } from './zipCodesUpdate';
export { default as PromotionalRatesUpdate } from './promotionalRatesUpdate';
export { default as SpecialRatesUpdate } from './promotionalRatesUpdate/specialRates';
export { default as PointToPointUpdate } from './promotionalRatesUpdate/pointToPoint';
export { default as PromoCodeUpdate } from './promotionalRatesUpdate/promoCodes';
