import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import IconAddressBook from '../../icons/addressBookIcon';
import IconLocation from '../../icons/locationIcon';
import IconMapMarkedAlt from '../../icons/mapMarkedAltIcon';
import IconPlane from '../../icons/planeIcon';
import IconPlaneLock from '../../icons/planeLock';
import { settingsAirlinesAccounts, settingsAirlinesAirports, settingsAirlinesContacts, settingsAirlinesLocations, settingsAirlinesOwners, settingsAirlinesZones } from '../../../routesConfiguration/paths';


const NavViewCrewAdmin = (props) => {
    const { userInfo } = props;

    return (
        <div className="d-flex flex-wrap justify-content-center">
            {userInfo &&
            userInfo.permission.some(({ airlineCrew }) => airlineCrew === "full" || airlineCrew === "some") ? (
                <div className="col-12 col-xl-10 d-flex flex-wrap">
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link
                            to={settingsAirlinesAccounts}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconPlaneLock className="h-100"/>
                            </div>
                            <span className="mt-3">Companies</span>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link
                            to={settingsAirlinesZones}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconMapMarkedAlt className="h-100"/>
                            </div>
                            <span className="mt-3">Zones</span>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link
                            to={settingsAirlinesAirports}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconPlane className="h-100"/>
                            </div>
                            <span className="mt-3">Airports</span>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link
                            to={settingsAirlinesContacts}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconAddressBook className="h-100"/>
                            </div>
                            <span className="mt-3">Contacts</span>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link
                            to={settingsAirlinesLocations}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconLocation className="h-100"/>
                            </div>
                            <span className="mt-3">Locations</span>
                        </Link>
                    </div>
                    <div className="col-6 col-md-4 col-xl-3 py-2 py-md-4 px-0 px-md-3">
                        <Link
                            to={settingsAirlinesOwners}
                            style={{ color: '#000' }}
                            className="border border-dark rounded shadow text-decoration-none navCrewOption m-3 py-4 px-3 d-flex flex-column justify-content-center align-items-center hover-primary"
                        >
                            <div style={{ height: '2.5rem' }}>
                                <IconAddressBook className="h-100"/>
                            </div>
                            <span className="mt-3">Account Owner</span>
                        </Link>
                    </div>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

const mstp = (state) => ({
    userInfo: state.login.userInfo,
});

export default connect(mstp)(NavViewCrewAdmin);
