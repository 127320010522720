import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import XLSX from 'xlsx';
import { actions } from "../../../../redux/modules/zipCodesLocations";
import { actions as locationActions } from '../../../../redux/modules/locations';
import FormZipCodes from './formZipCodes';


class DetailContainer extends React.Component {
    componentDidMount() {
        const { getLocationHeader, load2Update, match } = this.props;
        getLocationHeader(match.params.id);
        load2Update(match.params.id);
    }

    downloadZipsFile = (fileTitle, fileZips) => {
        if (!fileZips || !Array.isArray(fileZips)) {
            console.error("fileZips is undefined or not an array");
            return;
        }
    
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: fileTitle,
            Subject: "Zips",
            Author: "BBC Connect",
            CreatedDate: new Date(),
        };
    
        const ws_data = [];
        for (let i = 0; i < fileZips.length; i += 6) {
            ws_data.push(fileZips.slice(i, i + 6));
        }
    
        const titleRow = [fileTitle, '', '', '', '', ''];
        ws_data.unshift(titleRow);
    
        const ws = XLSX.utils.aoa_to_sheet(ws_data);
    
        ws['A1'].s = {
            font: {
                bold: true
            },
            alignment: {
                horizontal: 'center'
            }
        };
    
        const wscols = Array(6).fill({ wch: 15 }); 
        ws['!cols'] = wscols;
    
        XLSX.utils.book_append_sheet(wb, ws, fileTitle);
    
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        const s2ab = (s) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        };
    
        saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${fileTitle}_zips.xlsx`);
    };
    

    render() {
        const { userInfo: { permission }, match: { params }, update, header, zips2Dowload,
        // Zip Codes Vars
        zipCodes, excludeZipCodes, filterZipCodes, filterExcludeZipCodes, loading
    } = this.props;

    if (!zips2Dowload || !Array.isArray(zips2Dowload) || zips2Dowload.length === 0) {
        console.log("data is not ready yet");
        return;
    }
    const fileTitle = header? `${header.city}, ${header.state}` : '';
    const fileZips = zips2Dowload ? zips2Dowload.map(item => item.zip) : [];

        return permission && permission.find(({ readLocations }) => !!readLocations)
            ? <div className="d-flex flex-column p-2 p-md-5 flex-wrap justify-content-center align-items-center">
                <div className="col-12 d-flex justify-content-center align-items-center">
                    {header && (
                        <React.Fragment>
                            <h1>{header.city}, {header.state} ({header.cityCode})</h1>
                        </React.Fragment>
                    )}
                </div>
                <div className="col-12">
                    <FormZipCodes
                        onSubmit={update}
                        zipCodes={zipCodes}
                        excludeZipCodes ={excludeZipCodes}
                        filterZipCodes={filterZipCodes}
                        params={params}
                        loading={loading}
                        // excludeZipCodes={excludeZipCodes}
                        filterExcludeZipCodes={filterExcludeZipCodes}
                        downloadZipsFile={() => this.downloadZipsFile(fileTitle, fileZips)}
                    />
                </div>
            </div>
            : null
    }
}

const mstp = (state) => {
    return {
        ...state.zipCodesLocations,
        userInfo: state.login.userInfo,
        header: state.locations.header,
        zips2Dowload: state.zipCodesLocations.updateData.zipCodes
    };
};

const mdtp = { ...actions, getLocationHeader: locationActions.getLocationHeader };

export default connect(mstp, mdtp)(DetailContainer);
