import React from 'react';
import { Field, reduxForm } from "redux-form";
import classNames from 'classnames';
import IconSearch from '../../../icons/search';

const renderSearch = ({ id, disabled, input, placeholder,
    type, cb, myChange, meta: { touched, error }, }) => {
    const invalid = touched && error;
    return (
        <div className="w-100">
            <input
                id={id}
                {...input}
                placeholder={placeholder}
                type={type}
                disabled={disabled}
                className={classNames("form-control", { "is-invalid": invalid, })}
                onChange={(data) => {
                    input.onChange(data);
                    if (cb) cb();
                }}
                style={{ "paddingRight": "40px" }}
            />
            {invalid && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

let Form = (props) => (
    <div className="ui icon input w-100">
        <Field
            name='search'
            type="text"
            placeholder = "Search by title, address, city"
            component={renderSearch}
            cb={props.onSubmit}
            className="form-control"
        />
        <i aria-hidden="true" className="icon d-flex align-items-center justify-content-center">
            <IconSearch className="menu_icon" />
        </i>
    </div>
)

const Search = reduxForm({
    form: 'searchTours',
    destroyOnUnmount: false,
})(Form);

export default Search;