import { connect } from "react-redux";
import Create from "./BlogPostCreate";
import { create } from "../../../../redux/modules/blogPosts";

const mdtp = { create };

const mstp = (state) => ({
    loading: state.blogPost.loading,
    userInfo: state.login.userInfo,
});

export default connect(mstp, mdtp)(Create);
