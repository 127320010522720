import React from "react";
import Form from "./PromoCodeForm";

export default class Create extends React.Component {
    render() {
        const { create, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div>
                    {permission && permission.find(({ createPromo }) => !!createPromo) && (
                        <Form onSubmit={create} />
                    )}
                </div>
            </div>
        );
    }
}
