import { connect } from "react-redux";
import Create from "./TourCreate";
import { create } from "../../../../redux/modules/tours";

const mdtp = { create };

const mstp = (state) => ({
    loading: state.tours.loading,
    userInfo: state.login.userInfo,
});

export default connect(mstp, mdtp)(Create);
