import React from "react";
import { Field, reduxForm } from "redux-form";
import { validate, validators } from "validate-redux-form";
import { renderField, renderNumber, renderSelectField } from "../../Utils/renderField/renderField";
import { stateOptions } from "../../../utility/constants";

const Form = ({ handleSubmit, cancel }) => (
    <form onSubmit={handleSubmit}>
        <div className="form-group grid-container">
            <div className="grid-titulo padding-15">

                <div className="form-group col-12 mt-4">
                    <h2 className="mb-3 mt-4">
                        <strong>Hotel</strong>
                    </h2>
                </div>

                <div className="padding-15 p-sm-0 py-sm-1 col-12 col-md-4 offset-md-4">
                    <div className="row">
                        <div className="form-group col-12">
                            <label htmlFor="landMark"> LandMark* </label>
                            <Field
                                name="landMark"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12">
                            <label htmlFor="street"> Address* </label>
                            <Field
                                name="street"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12">
                            <label htmlFor="street2"> Address Line 2 </label>
                            <Field
                                name="street2"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12">
                            <label htmlFor="city"> City* </label>
                            <Field
                                name="city"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="street2"> State* </label>
                            <Field
                                name="state"
                                component={renderSelectField}
                                clearable={false}
                                options={stateOptions}
                            />
                        </div>
                        <div className="form-group col-12 col-md-6">
                            <label htmlFor="street2"> Zip* </label>
                            <Field
                                name="zip"
                                component={renderField}
                                type="text"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button
                                    type = "button"
                                    onClick={cancel}
                                    className="btn btn-link m-1"
                                >
                                    Cancel
                                </button>
                                <button type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
);

const HotelForm = reduxForm({
    form: "hotelForm",
    validate: (data) =>
        validate(data, {
            landMark: validators.exists()("Required field"),
            street: validators.exists()("Required field"),
            city: validators.exists()("Required field"),
            state: validators.exists()("Required field"),
            zip: validators.exists()("Required field"),
        }),
})(Form);

export default HotelForm;
