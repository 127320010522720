import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../../../redux/modules/crewAdmin/crewRoutes';
import IconAngleLeft from '../../../../../../icons/angleLeftIcon';
import { change } from 'redux-form';
import LoadMask from '../../../../../Utils/LoadMask';
import RouteForm from '../create/routeForm';


class Container extends React.Component {

    cancel = () => {
        this.props.cleanForm()
        this.props.history.goBack();
    }

    componentDidMount(){
        this.props.load2Update(this.props.match.params.id);
    }

    render(){

        const { loadingForm } = this.props;

        return (
            <div className="h-100 crewContainer px-3">
                <div className="d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>this.cancel()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="d-flex flex-column crewAdminMaxHeight">
                    <div className="flex-1 d-flex flex-wrap justify-content-center">
                        <div className="col-12 col-xl-7 my-2 mt-xl-4 pt-xl-2">
                            <div className="rounded h-100 shadow border border-dark">
                                <div className="d-flex flex-wrap pt-3 px-3 h-100" style={{ maxHeight: 'calc(100vh - 10rem)',  overflowY: 'auto' }}>
                                    <div className="col-12 mb-3">
                                        <h3 className="text-left">Route details</h3>
                                    </div>
                                    <LoadMask loading={loadingForm} blur>
                                        <RouteForm
                                            {...this.props}
                                            cancel={this.cancel}
                                            readOnly={true}
                                        />
                                    </LoadMask>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mstp = state => ({
    ...state.crewAdmin.crewRoutes
})

const mdtp = {
    ...actions,
    change
}

export default connect(mstp, mdtp)(Container);
