import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { renderDatePicker, renderLabeledField, renderNumber, renderPhoneNumber, renderTextArea, renderSelectField } from '../../Utils/renderField/renderField';
import { stateOptions, statusList } from '../../../utility/constants';
import IconPlus from '../../../icons/plus';
import IconDash from '../../../icons/dash';
import IconMinus from '../../../icons/minus';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

const selectStyles = {
    valueContainer: (baseStyles, state) => ({
        ...baseStyles,
        marginTop: '2rem',
    })
}
  

const renderVehicleTypes = (props) => {
    const {
        input,
        meta: { touched, error },
        loading = false,
    } = props;
    const invalid = touched && error;

    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="mr-2">
                    <label htmlFor="sedans" className="mb-0">
                        <Field
                            id="sedans"
                            component={"input"}
                            type="checkbox"
                            name={`${input.name}.sedans`}
                            disabled={loading}
                        />
                        &nbsp;Sedans
                    </label>
                </div>
                <div className="mx-2">
                    <label htmlFor="suv" className="mb-0">
                        <Field
                            id="suv"
                            component={"input"}
                            type="checkbox"
                            name={`${input.name}.suv`}
                            disabled={loading}
                        />
                        &nbsp;Suv's
                    </label>
                </div>
                <div className="mx-2">
                    <label htmlFor="vans" className="mb-0">
                        <Field
                            id="vans"
                            component={"input"}
                            type="checkbox"
                            name={`${input.name}.vans`}
                            disabled={loading}
                        />
                        &nbsp;Vans
                    </label>
                </div>
                <div className="mx-2">
                    <label htmlFor="bus" className="mb-0">
                        <Field
                            id="bus"
                            component={"input"}
                            type="checkbox"
                            name={`${input.name}.bus`}
                            disabled={loading}
                        />
                        &nbsp;Bus
                    </label>
                </div>
                <div className="ml-2">
                    <label htmlFor="coach" className="mb-0">
                        <Field
                            id="coach"
                            component={"input"}
                            type="checkbox"
                            name={`${input.name}.coach`}
                            disabled={loading}
                        />
                        &nbsp;Coach Bus
                    </label>
                </div>
            </div>
            {invalid && <div className="invalid-feedback d-block">{error}</div>}
        </div>
    );
}

const renderDotLicense = (props) => {
    const {
        fields,
        meta: { error, submitFailed },
        loading = false,
    } = props;

    return (
        <div className="w-100 d-flex flex-wrap">
            {fields.map((license, index) => (
                <div className="w-100 d-flex" key={index}>
                    <div className='flex-1 d-flex flex-wrap'>
                        <div className="col-12 col-md-4 col-lg-3 px-0">
                            <div className="flex-1 d-flex flex-row form-group select-labeled">
                                <label htmlFor={`${license}.dl_type`}>DL Type</label>
                                <Field
                                    id={`${license}.dl_type`}
                                    name={`${license}.dl_type`}
                                    component={renderSelectField}
                                    options={[
                                        { value: "non_cdl", label: "Non-CDL" },
                                        { value: "cdl", label: "CDL" },
                                    ]}
                                    placeholder="Non CDL"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 px-0">
                            <div className="flex-1 d-flex flex-row form-group select-labeled">
                                <label htmlFor={`${license}.issue_state`}>Issue State</label>
                                <Field
                                    id={`${license}.issue_state`}
                                    name={`${license}.issue_state`}
                                    component={renderSelectField}
                                    options={stateOptions}
                                    styles={selectStyles}
                                    placeholder="VA"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 px-0">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor={`${license}.license`}>License Number</label>
                                <Field
                                    name={`${license}.license`}
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="A12345678"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-3 px-0">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor={`${license}.expiration`}>Expiration Date</label>
                                <Field
                                    id={`${license}.expiration${index}`}
                                    name={`${license}.expiration`}
                                    component={renderDatePicker}
                                    isOutsideRange={false}
                                    isDayBlocked={() => false}
                                    numberOfMonths={1}
                                    placeholder="12-12-2027"
                                    className="w-100 m-2 p-0"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mx-2 d-flex">
                        <div className="mt-2">
                            <button
                                type="button"
                                className="btn btn-secondary p-0"
                                style={{ width: '2rem', height:'2rem'}}
                                onClick={()=> fields.remove(index)}
                                disabled={loading}
                            >
                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding:'3px' }}>
                                     <IconMinus/>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            <div className="w-100 mx-2 d-flex flex-wrap justify-content-end">
                <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ maxWidth: '3rem' }}
                    onClick={() => fields.push({})}
                    disabled={loading}
                >
                    <IconPlus />
                </button>
            </div>
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger mx-2">
                    {error}
                </div>
            }
        </div>
    )
}

// const renderDotAddresses = (props) => {
//     const {
//         fields,
//         meta: { error, submitFailed },
//         loading = false,
//     } = props;

//     return (
//         <div className="w-100 d-flex flex-wrap">
//             {fields.map((address, index) => (
//                 <React.Fragment key={index}>
//                     <div className="col-12 px-0">
//                         <div className="d-flex form-group">
//                             <label htmlFor={`${address}.address`}>Address</label>
//                             <Field
//                                 name={`${address}.address`}
//                                 component={renderLabeledField}
//                                 type="text"
//                                 placeholder="123456 Some Street"
//                                 className="w-100 m-2"
//                                 disabled={loading}
//                             />
//                         </div>
//                     </div>
//                     <div className="col-12 col-md-4 col-lg-4 px-0">
//                         <div className="d-flex form-group">
//                             <label htmlFor={`${address}.city`}>City</label>
//                             <Field
//                                 name={`${address}.city`}
//                                 component={renderLabeledField}
//                                 type="text"
//                                 placeholder="Fairfax"
//                                 className="w-100 m-2"
//                                 disabled={loading}
//                             />
//                         </div>
//                     </div>
//                     <div className="col-12 col-md-4 col-lg-2 px-0">
//                         <div className="d-flex select-labeled">
//                             <label htmlFor={`${address}.state`}>State</label>
//                             <Field
//                                 name={`${address}.state`}
//                                 component={renderSelectField}
//                                 options={stateOptions}
//                                 placeholder="VA"
//                                 className="w-100 m-2"
//                                 disabled={loading}
//                             />
//                         </div>
//                     </div>
//                     <div className="col-12 col-md-4 col-lg-4 px-0">
//                         <div className="d-flex form-group">
//                             <label htmlFor={`${address}.zip`}>Zip</label>
//                             <Field
//                                 name={`${address}.zip`}
//                                 component={renderLabeledField}
//                                 type="text"
//                                 placeholder="20133"
//                                 className="w-100 m-2"
//                                 disabled={loading}
//                             />
//                         </div>
//                     </div>
//                     <div className="col-12 col-md-4 col-lg-2 px-0">
//                         <div className="d-flex form-group">
//                             <label htmlFor={`${address}.apt`}>Apt/Ste #</label>
//                             <Field
//                                 name={`${address}.apt`}
//                                 component={renderLabeledField}
//                                 type="text"
//                                 placeholder="STE 105"
//                                 className="w-100 m-2"
//                                 disabled={loading}
//                             />
//                         </div>
//                     </div>
//                 </React.Fragment>
//             ))}
//             <div className="w-100 mx-2 d-flex flex-wrap justify-content-end">
//                 <button
//                     type="button"
//                     className="btn btn-secondary"
//                     style={{ maxWidth: '3rem' }}
//                     onClick={() => fields.push({})}
//                     disabled={loading}
//                 >
//                     <IconPlus />
//                 </button>
//             </div>
//             {submitFailed && error &&
//                 <div className="invalid-feedback-array text-danger mx-2">
//                     {error}
//                 </div>
//             }
//         </div>
//     )
// }

const renderDotAddresses = (props) => {
    const {
        fields,
        meta: { error, submitFailed },
        loading = false,
    } = props;

    return (
        <div className="w-100 d-flex flex-wrap">
            {fields.map((address, index) => (
                <div className="w-100 d-flex" key={index}>
                    <div className="flex-1 d-flex flex-wrap">
                        <div className="col-12 px-0">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor={`${address}.address`}>Address</label>
                                <Field
                                    name={`${address}.address`}
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="123456 Some Street"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 px-0">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor={`${address}.city`}>City</label>
                                <Field
                                    name={`${address}.city`}
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="Fairfax"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 px-0">
                            <div className="flex-1 d-flex flex-row form-group select-labeled">
                                <label htmlFor={`${address}.state`}>State</label>
                                <Field
                                    id={`${address}.state`}
                                    name={`${address}.state`}
                                    component={renderSelectField}
                                    options={stateOptions}
                                    styles={selectStyles}
                                    placeholder="VA"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 px-0">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor={`${address}.zip`}>Zip</label>
                                <Field
                                    name={`${address}.zip`}
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="20133"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2 px-0">
                            <div className="flex-1 d-flex flex-row form-group">
                                <label htmlFor={`${address}.apt`}>Apt/Ste #</label>
                                <Field
                                    name={`${address}.apt`}
                                    component={renderLabeledField}
                                    type="text"
                                    placeholder="STE 105"
                                    className="w-100 m-2"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mx-2 d-flex">
                        <div className="mt-2">
                            <button
                                type="button"
                                className="btn btn-secondary p-0"
                                style={{ width: '2rem', height:'2rem'}}
                                onClick={()=> fields.remove(index)}
                                disabled={loading}
                            >
                                <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding:'3px' }}>
                                    <IconMinus />
                                </span>
                               
                            </button>
                        </div>
                    </div>
                </div>
            ))}
            <div className="w-100 mx-2 d-flex flex-wrap justify-content-end">
                <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ maxWidth: '3rem' }}
                    onClick={()=> fields.push({})}
                    disabled={loading}
                >
                    <span>
                        <IconPlus /> 
                    </span>
                   
                </button>
            </div>
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger mx-2">
                    {error}
                </div>
            }
        </div>
    )
}


const renderReferences = (props) => {
    const {
        fields,
        meta: { error, submitFailed },
        loading = false,
    } = props;

    return (
        <div className="w-100 d-flex flex-wrap">
            {fields.map((reference, index) => (
                <React.Fragment key={index}>
                    <div className="col-12 col-md-4 px-0">
                        <div className="d-flex form-group">
                            <label htmlFor={`${reference}.company`}>Name</label>
                            <Field
                                name={`${reference}.company`}
                                component={renderLabeledField}
                                type="text"
                                placeholder="ABC Company"
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 px-0">
                        <div className="d-flex form-group">
                            <label htmlFor={`${reference}.relationship`}>Relationship</label>
                            <Field
                                name={`${reference}.relationship`}
                                component={renderLabeledField}
                                type="text"
                                placeholder="12-12-2019"
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 px-0">
                        <div className="d-flex form-group">
                            <label htmlFor={`${reference}.phone`}>Phone Number</label>
                            <Field
                                name={`${reference}.phone`}
                                component={renderPhoneNumber}
                                type="text"
                                placeholder="(703) 555-5555"
                                className="w-100 m-2"
                                formatTemplate="(###) ###-####"
                                disabled={loading}
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
            <div className="w-100 mx-2 d-flex flex-wrap justify-content-end">
                <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ maxWidth: '3rem' }}
                    onClick={() => {
                        if (fields.length < 2)
                            fields.push({});
                        else fields.remove(fields.length - 1)
                    }}
                    disabled={loading}
                >
                    {fields.length < 2
                        ? <IconPlus />
                        : <IconDash />
                    }
                </button>
            </div>
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger mx-2">
                    {error}
                </div>
            }
        </div>
    )
}

const renderEmploymentHistory = (props) => {

    const {
        fields,
        meta: { error, submitFailed },
        loading = false,
    } = props;

    return (
        <div className="d-flex flex-wrap w-100">
            {fields.map((history, index) => (
                <React.Fragment key={index}>
                    {index > 0 ? <hr className="w-100 mx-2 mb-4" /> : ''}
                    <div className="col-12 col-md-4 col-lg-4 px-0">
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor={`${history}.company_name`}>Company Name</label>
                            <Field
                                name={`${history}.company_name`}
                                component={renderLabeledField}
                                type="text"
                                placeholder="ABC Company"
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 px-0">
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor={`${history}.company_start`}>Start Date</label>
                            <Field
                                id={`${history}.company_start${index}`}
                                name={`${history}.company_start`}
                                component={renderDatePicker}
                                numberOfMonths={1}
                                isOutsideRange={false}
                                isDayBlocked={() => false}
                                placeholder="12-12-2019"
                                className="w-100 m-2 p-0"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 px-0">
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor={`${history}.company_end`}>End Date</label>
                            <Field
                                id={`${history}.company_end${index}`}
                                name={`${history}.company_end`}
                                component={renderDatePicker}
                                numberOfMonths={1}
                                isOutsideRange={false}
                                isDayBlocked={() => false}
                                placeholder="12-12-2024"
                                className="w-100 m-2 p-0"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 px-0">
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor={`${history}.company_position`}>Position Held</label>
                            <Field
                                name={`${history}.company_position`}
                                component={renderLabeledField}
                                type="text"
                                placeholder="Coach Bus Driver"
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-8 px-0">
                        <div className="flex-1 d-flex flex-row form-group">
                            <label htmlFor={`${history}.company_reason_leaving`}>Reason for Leaving</label>
                            <Field
                                name={`${history}.company_reason_leaving`}
                                component={renderLabeledField}
                                type="text"
                                placeholder="Coach Bus Driver"
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                </React.Fragment>
            ))}
            <div className="w-100 mx-2 d-flex flex-wrap justify-content-end">
                <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ maxWidth: '3rem' }}
                    onClick={() => {
                        if (fields.length < 2) {
                            fields.push({});
                        } else {
                            fields.remove(fields.length - 1)
                        }
                    }}
                    disabled={loading}
                >
                    {fields.length < 2
                        ? <IconPlus />
                        : <IconDash />
                    }
                </button>
            </div>
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger mx-2">
                    {error}
                </div>
            }
        </div>
    )
}

const Form = (props) => {
    const {
        handleSubmit,
        onCancel,
        formValues,
        loading = false,
        errorFields = {}
    } = props;

    const fieldRefs = {
        prefix: useRef(null),
        first_name: useRef(null),
        middle_name: useRef(null),
        last_name: useRef(null),
        email: useRef(null),
        address: useRef(null),
        city: useRef(null),
        state: useRef(null),
        zip: useRef(null),
        education: useRef(null),

        position: useRef(null),
        month_salary: useRef(null),
        availability: useRef(null),
        shift: useRef(null),
        start_date: useRef(null),
        dl_type: useRef(null),
        issue_state: useRef(null),
        license_number: useRef(null),
        license_expiration: useRef(null),
        experience: useRef(null),
        hear_about: useRef(null),
        want_work: useRef(null),
    };

    const [formSubmitted, setFormSubmitted] = useState(false)
    
    useEffect(() => {
        if (Object.keys(errorFields).length > 0) {
            const firstErrorField = Object.keys(errorFields)[0];
            if (fieldRefs[firstErrorField] && fieldRefs[firstErrorField].current) {
                fieldRefs[firstErrorField].current.focus();
            }
        }
    }, [formSubmitted]);

    return (
        <form onSubmit={handleSubmit} className="modalItinerary">
            <div className="w-100 d-flex flex-wrap no-print">
                <div className="col-12 d-flex flex-wrap justify-content-between">
                    <div className="col-12 col-md-8 px-0 d-flex align-items-start">
                    </div>
                    <div className="col-12 col-md-4 px-0 mt-3 mt-md-0 select-labeled">
                        <label htmlFor="apply-status">
                            Status
                        </label>
                        <div className="w-100">
                            <Field
                                id="apply-status"
                                name="status"
                                placeholder="Status"
                                component={renderSelectField}
                                options={statusList}
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 my-4">
                    <h4> DRIVER APPLICATION FORM </h4>
                </div>
            </div>
            <div className="border rounded cartListShadow d-flex flex-wrap py-4 px-3 mb-3">
                <div className="w-100 mx-2">
                    <h5>PERSONAL</h5>
                </div>
                <div className="col-12 col-md-4 col-lg-2 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="prefix">Prefix</label>
                        <Field
                            name="prefix"
                            component={renderSelectField}
                            options={[
                                { value: "Mr.", label: "Mr." },
                                { value: "Ms.", label: "Ms." },
                                { value: "Mrs.", label: "Mrs." },
                                { value: "Dr.", label: "Dr." },
                                { value: "Other", label: "Other" },
                            ]}
                            placeholder="Mr."
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.prefix}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-8 col-lg-10 d-flex flex-wrap px-0">
                    <div className="col-12 col-md-4 col-lg-4 px-0">
                        <div className="d-flex form-group">
                            <label htmlFor="first_name">First Name</label>
                            <Field
                                name="first_name"
                                component={renderLabeledField}
                                type="text"
                                placeholder="John"
                                className="w-100 m-2"
                                disabled={loading}
                                inputRef={fieldRefs.first_name}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 px-0">
                        <div className="d-flex form-group">
                            <label htmlFor="middle_name">Middle Name</label>
                            <Field
                                name="middle_name"
                                component={renderLabeledField}
                                type="text"
                                placeholder="James"
                                className="w-100 m-2"
                                disabled={loading}
                                inputRef={fieldRefs.middle_name}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-4 px-0">
                        <div className="d-flex form-group">
                            <label htmlFor="last_name">Last Name</label>
                            <Field
                                name="last_name"
                                component={renderLabeledField}
                                type="text"
                                placeholder="Smith"
                                className="w-100 m-2"
                                disabled={loading}
                                inputRef={fieldRefs.last_name}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="phone">Phone</label>
                        <Field
                            name="phone"
                            component={renderPhoneNumber}
                            type="text"
                            placeholder="(123) 456-7890"
                            className="w-100 m-2"
                            formatTemplate="(###) ###-####"
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-8 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="email">Email Address</label>
                        <Field
                            name="email"
                            component={renderLabeledField}
                            type="text"
                            placeholder="example@website.com"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.email}
                        />
                    </div>
                </div>
                <div className="col-12 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="address">Address</label>
                        <Field
                            name="address"
                            component={renderLabeledField}
                            type="text"
                            placeholder="123456 Some Street"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.address}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="city">City</label>
                        <Field
                            name="city"
                            component={renderLabeledField}
                            type="text"
                            placeholder="Fairfax"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.city}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="state">State</label>
                        <Field
                            name="state"
                            component={renderSelectField}
                            options={stateOptions}
                            placeholder="VA"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.state}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="zip">Zip</label>
                        <Field
                            name="zip"
                            component={renderLabeledField}
                            type="text"
                            placeholder="20133"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.zip}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="apt">Apt/Ste #</label>
                        <Field
                            name="apt"
                            component={renderLabeledField}
                            type="text"
                            placeholder="STE 105"
                            className="w-100 m-2"
                            disabled={loading}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="birth">Date of Birth</label>
                        <Field
                            id="birth"
                            name="birth"
                            component={renderDatePicker}
                            numberOfMonths={1}
                            isOutsideRange={false}
                            isDayBlocked={() => false}
                            placeholder="12-12-1990"
                            className="w-100 m-2 p-0"
                            disabled={loading}
                            inputRef={fieldRefs.birth}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-8 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="education">Education</label>
                        <Field
                            name="education"
                            component={renderSelectField}
                            options={[
                                { value: "high_school", label: "Some High School" },
                                { value: "high_school_diploma", label: "Hight School Diploma or GED" },
                                { value: "college", label: "Some College" },
                                { value: "associate_degree", label: "Associate Degree" },
                                { value: "bachelor_degree", label: "Bachelor's Degree" },
                                { value: "master_degree", label: "Master's Degree" },
                            ]}
                            placeholder="None"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.education}
                        />
                    </div>
                </div>
            </div>
            <div className="border rounded cartListShadow d-flex flex-wrap py-4 px-3 mb-3">
                <div className="w-100 mx-2">
                    <h5>JOB PREFERENCES</h5>
                </div>
                <div className="col-12 col-md-4 col-lg-3 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="position">Position</label>
                        <Field
                            name="position"
                            component={renderSelectField}
                            options={[
                                { value: "airline", label: "Airline" },
                                { value: "limo", label: "Limo" },
                                { value: "charter_bus", label: "Charter Bus" },
                            ]}
                            placeholder="Airline"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.position}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="month_salary">Desired Monthly Salary</label>
                        <Field
                            name="month_salary"
                            component={renderNumber}
                            decimalScale={2}
                            placeholder="$2,000.00"
                            className="w-100 m-2"
                            disabled={loading}
                            prefix="$"
                            labeled
                            inputRef={fieldRefs.month_salary}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="availability">Availability</label>
                        <Field
                            name="availability"
                            component={renderSelectField}
                            options={[
                                { value: "full_time", label: "Full Time" },
                                { value: "part_time", label: "Part Time" },
                                { value: "on_call", label: "On Call" },
                            ]}
                            placeholder="Full Time"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.availability}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="shift">Shift</label>
                        <Field
                            name="shift"
                            component={renderSelectField}
                            options={[
                                { value: "04_16", label: "Daytime (04:00 - 16:00)" },
                                { value: "14_02", label: "Midday (14:00 - 02:00)" },
                                { value: "14_10", label: "Midday Short (14:00 - 10:00)-Bus Only" },
                                { value: "04_10", label: "Early Morning (04:00 - 10:00)" },
                                { value: "10_17", label: "Late Morning (10:00 - 17:00)" },
                                { value: "12_18", label: "Afternoon (12:00 - 18:00)" },
                                { value: "20_02", label: "Evening (20:00 - 02:00)" },
                                { value: "00_04", label: "Midnight (00:00 - 04:00)" },
                                { value: "20_08", label: "Overnight (20:00 - 08:00)" },
                                { value: "04_18", label: "Daytime Extended (04:00 - 18:00)" },
                                { value: "oncall_04_16", label: "On-Call (AM) (04:00 - 16:00)" },
                                { value: "oncall_14_02", label: "On-Call (PM) (14:00 - 02:00)" },
                                { value: "other", label: "Other" },
                            ]}
                            placeholder="20:00 - 08:00"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.shift}
                        />
                    </div>
                </div>
                {formValues && formValues.shift && (formValues.shift.value || formValues.shift) === "other" ? ( 
                    <div className="col-12 col-md-4 px-0">
                        <div className="d-flex form-group">
                            <label htmlFor="custom_shift">Custom Shift</label>
                            <Field
                                id="custom_shift"
                                name="custom_shift"
                                component={renderLabeledField}
                                type="text"
                                placeholder=""
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                ) : ''}
                <div className="col-12 col-md-4 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="start_date">Start Date</label>
                        <Field
                            id="start_date"
                            name="start_date"
                            component={renderDatePicker}
                            numberOfMonths={1}
                            isOutsideRange={false}
                            isDayBlocked={() => false}
                            placeholder="12-12-1990"
                            className="w-100 m-2 p-0"
                            disabled={loading}
                            inputRef={fieldRefs.start_date}
                        />
                    </div>
                </div>
                <div className={`col-12 ${formValues && formValues.shift && (formValues.shift.value || formValues.shift) === "other" ? 'col-md-12' : 'col-md-8'} px-0 d-flex flex-wrap`}>
                    <div className={`col-12 px-0 ${formValues && formValues.shift && (formValues.shift.value || formValues.shift) === "other" ? 'col-md-4' : 'col-md-6'}`}>
                        <div className="d-flex select-labeled">
                            <label htmlFor="work_before">Have you worked with us before?</label>
                            <Field
                                name="work_before"
                                component={renderSelectField}
                                options={[
                                    { value: "YES", label: "YES" },
                                    { value: "NO", label: "NO" }
                                ]}
                                placeholder="No"
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                    <div className={`col-12 px-0 ${formValues && formValues.shift && (formValues.shift.value || formValues.shift) === "other" ? 'col-md-4' : 'col-md-6'}`}>
                        <div className="d-flex select-labeled">
                            <label htmlFor="reason_leaving">Reason for leaving?</label>
                            <Field
                                name="reason_leaving"
                                component={renderSelectField}
                                options={[
                                    { value: "career_advancement", label: "Career Advancement" },
                                    { value: "better_benefits", label: "Better Compensation/Benefits" },
                                    { value: "relocation", label: "Relocation" },
                                    { value: "family/personal", label: "Family/Personal Reasons" },
                                    { value: "new_challenges", label: "Seeking New Challenges" },
                                    { value: "job_dissatisfaction", label: "Job Dissatisfaction" },
                                    { value: "laid/down", label: "Laid Off/Downsizing" },
                                    { value: "health", label: "Health Reasons" },
                                    { value: "terminated", label: "Terminated" },
                                    { value: "retirement", label: "Retirement" },
                                ]}
                                placeholder="None"
                                className="w-100 m-2"
                                disabled={loading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="border rounded cartListShadow d-flex flex-wrap py-4 px-3 mb-3">
                <div className="w-100 mx-2">
                    <h5>DRIVER LICENSE</h5>
                </div>
                <div className="col-12 col-md-4 col-lg-2 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="dl_type">DL Type</label>
                        <Field
                            id="dl_type"
                            name="dl_type"
                            component={renderSelectField}
                            options={[
                                { value: "non_cdl", label: "Non-CDL" },
                                { value: "cdl", label: "CDL" },
                            ]}
                            placeholder="Non CDL"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.dl_type}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="issue_state">Issue State</label>
                        <Field
                            name="issue_state"
                            component={renderSelectField}
                            options={stateOptions}
                            placeholder="VA"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.issue_state}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="license_number">License Number</label>
                        <Field
                            name="license_number"
                            component={renderLabeledField}
                            type="text"
                            placeholder="A12345678"
                            className="w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.license_number}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 px-0">
                    <div className="d-flex form-group">
                        <label htmlFor="license_expiration">Expiration Date</label>
                        <Field
                            id="license_expiration"
                            name="license_expiration"
                            component={renderDatePicker}
                            numberOfMonths={1}
                            isOutsideRange={false}
                            isDayBlocked={() => false}
                            placeholder="12-12-2027"
                            className="w-100 m-2 p-0"
                            disabled={loading}
                            inputRef={fieldRefs.license_expiration}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-2 px-0">
                    <div className="d-flex select-labeled">
                        <label htmlFor="experience">Experience</label>
                        <Field
                            name="experience"
                            component={renderSelectField}
                            options={[
                                { value: "0-1_years", label: "0-1 Years" },
                                { value: "1-3_years", label: "1-3 Years" },
                                { value: "4-7_years", label: "4-7 Years" },
                                { value: "8-10_years", label: "8-10 Years" },
                                { value: "10-more_years", label: "10+ Years" },
                            ]}
                            placeholder="1-3 Years"
                            className="flex-1 w-100 m-2"
                            disabled={loading}
                            inputRef={fieldRefs.experience}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-10 pl-2 pl-lg-4 pt-2 px-0">
                    Vehicle types driven - select all that apply
                    <div className="mt-1">
                        <Field
                            name="vehicle_type"
                            component={renderVehicleTypes}
                            className="w-100"
                            loading={loading}
                        />
                    </div>
                </div>
                <div className="w-100 mx-2 mb-2">
                    <div className="w-100 d-flex">
                        <label>Have you have any accidents in the past 3 years?</label>
                        <div className="ml-4 d-flex">
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="accidents_yes"
                                    name="accidents.yes"
                                    component={"input"}
                                    type="radio"
                                    value="1"
                                    disabled={loading}
                                />
                                <label htmlFor="accidents_yes" className="ml-1 mb-0">
                                    YES
                                </label>
                            </div>
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="accidents_no"
                                    name="accidents.yes"
                                    component={"input"}
                                    type="radio"
                                    value="0"
                                    disabled={loading}
                                />
                                <label htmlFor="accidents_no" className="ml-1 mb-0">
                                    NO
                                </label>
                            </div>
                        </div>
                    </div>
                    <Field
                        name="accidents.reason"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                    />
                </div>
                <div className="w-100 mx-2 mb-2">
                    <div className="w-100 d-flex">
                        <label>Have you have any traffic violations in the past 3 years?</label>
                        <div className="ml-4 d-flex">
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="traffic_violations_yes"
                                    name="traffic_violations.yes"
                                    component={"input"}
                                    type="radio"
                                    value="1"
                                    disabled={loading}
                                />
                                <label htmlFor="traffic_violations_yes" className="ml-1 mb-0">
                                    YES
                                </label>
                            </div>
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="traffic_violations_no"
                                    name="traffic_violations.yes"
                                    component={"input"}
                                    type="radio"
                                    value="0"
                                    disabled={loading}
                                />
                                <label htmlFor="traffic_violations_no" className="ml-1 mb-0">
                                    NO
                                </label>
                            </div>
                        </div>
                    </div>
                    <Field
                        name="traffic_violations.reason"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                    />
                </div>
                <div className="w-100 mx-2 mb-2">
                    <div className="w-100 d-flex">
                        <label>Have you ever been denied a license or permit to operate a vehicle?</label>
                        <div className="ml-4 d-flex">
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="denied_license_yes"
                                    name="denied_license.yes"
                                    component={"input"}
                                    type="radio"
                                    value="1"
                                    disabled={loading}
                                />
                                <label htmlFor="denied_license_yes" className="ml-1 mb-0">
                                    YES
                                </label>
                            </div>
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="denied_license_no"
                                    name="denied_license.yes"
                                    component={"input"}
                                    type="radio"
                                    value="0"
                                    disabled={loading}
                                />
                                <label htmlFor="denied_license_no" className="ml-1 mb-0">
                                    NO
                                </label>
                            </div>
                        </div>
                    </div>
                    <Field
                        name="denied_license.reason"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                    />
                </div>
                <div className="w-100 mx-2 mb-2">
                    <div className="w-100 d-flex">
                        <label htmlFor="suspended_license">Has your license ever been suspended or revoked?</label>
                        <div className="ml-4 d-flex">
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="suspended_license_yes"
                                    name="suspended_license.yes"
                                    component={"input"}
                                    type="radio"
                                    value="1"
                                    disabled={loading}
                                />
                                <label htmlFor="suspended_license_yes" className="ml-1 mb-0">
                                    YES
                                </label>
                            </div>
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="suspended_license_no"
                                    name="suspended_license.yes"
                                    component={"input"}
                                    type="radio"
                                    value="0"
                                    disabled={loading}
                                />
                                <label htmlFor="suspended_license_no" className="ml-1 mb-0">
                                    NO
                                </label>
                            </div>
                        </div>
                    </div>
                    <Field
                        name="suspended_license.reason"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                    />
                </div>
            </div>
            <div className="border rounded cartListShadow d-flex flex-wrap py-4 px-3 mb-3">
                <div className="w-100 mx-2">
                    <h5>EMPLOYMENT HISTORY</h5>
                    <span>Please provide your employment history for the past 3 years (non-CDL drivers) or the past 7 years (CDL drivers).</span>
                </div>
                <FieldArray
                    name="employment_history"
                    component={renderEmploymentHistory}
                    loading={loading}
                />
            </div>
            <div className="border rounded cartListShadow d-flex flex-wrap py-4 px-3 mb-3">
                <div className="w-100 mx-2">
                    <h5>ADDITIONAL INFORMATION</h5>
                </div>
                <div className="w-100 mx-2 mb-2">
                    <div className="w-100 d-flex">
                        <label>Have you ever been convicted of a felony?</label>
                        <div className="ml-4 d-flex">
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="convicted_yes"
                                    name="convicted.yes"
                                    component={"input"}
                                    type="radio"
                                    value="1"
                                    disabled={loading}
                                />
                                <label htmlFor="convicted_yes" className="ml-1 mb-0">
                                    YES
                                </label>
                            </div>
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="convicted_no"
                                    name="convicted.yes"
                                    component={"input"}
                                    type="radio"
                                    value="0"
                                    disabled={loading}
                                />
                                <label htmlFor="convicted_no" className="ml-1 mb-0">
                                    NO
                                </label>
                            </div>
                        </div>
                    </div>
                    <Field
                        name="convicted.reason"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                    />
                </div>
                <div className="w-100 m-2">
                    <div className="w-100 d-flex">
                        <label>Do you have any physical limitations that effect your ability to operate a vehicle or lift bags?</label>
                        <div className="ml-4 d-flex">
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="physical_limitations_yes"
                                    name="physical_limitations.yes"
                                    component={"input"}
                                    type="radio"
                                    value="1"
                                    disabled={loading}
                                />
                                <label htmlFor="physical_limitations_yes" className="ml-1 mb-0">
                                    YES
                                </label>
                            </div>
                            <div className="d-flex mx-2 align-items-center">
                                <Field
                                    id="physical_limitations_no"
                                    name="physical_limitations.yes"
                                    component={"input"}
                                    type="radio"
                                    value="0"
                                    disabled={loading}
                                />
                                <label htmlFor="physical_limitations_no" className="ml-1 mb-0">
                                    NO
                                </label>
                            </div>
                        </div>
                    </div>
                    <Field
                        name="physical_limitations.reason"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                    />
                </div>
                <div className="w-100 m-2">
                    <label htmlFor="hear_about">How did you hear about CONNECT?</label>
                    <Field
                        name="hear_about"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                        inputRef={fieldRefs.hear_about}
                    />
                </div>
                <div className="w-100 m-2">
                    <label htmlFor="want_work">Why do you want to work with CONNECT?</label>
                    <Field
                        name="want_work"
                        component={renderTextArea}
                        rows={4}
                        placeholder="If you answered yes above, please explain here."
                        className="w-100"
                        disabled={loading}
                        inputRef={fieldRefs.want_work}
                    />
                </div>
                <div className="w-100 m-2 d-flex">
                    <label htmlFor="background_check">Are you willing to undergo a background check?</label>
                    <div className="ml-4 d-flex">
                        <div className="d-flex mx-2 align-items-center">
                            <Field
                                id="background_check_yes"
                                name="background_check"
                                component={"input"}
                                type="radio"
                                value="1"
                                disabled={loading}
                            />
                            <label htmlFor="background_check_yes" className="ml-1 mb-0">
                                YES
                            </label>
                        </div>
                        <div className="d-flex mx-2 align-items-center">
                            <Field
                                id="background_check_no"
                                name="background_check"
                                component={"input"}
                                type="radio"
                                value="0"
                                disabled={loading}
                            />
                            <label htmlFor="background_check_no" className="ml-1 mb-0">
                                NO
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="border rounded cartListShadow d-flex flex-wrap py-4 px-3 mb-3">
                <div className="w-100 m-2">
                    <h5>REFERENCES</h5>
                </div>
                <FieldArray
                    name="references"
                    component={renderReferences}
                    loading={loading}
                />
            </div>
            {formValues && formValues.dl_type && formValues.dl_type === 'cdl' && (
                <div className="border rounded cartListShadow d-flex flex-wrap py-4 px-3 mb-3">
                    <div className="w-100 m-2">
                        <h5 className="mb-4">DOT SECTION</h5>
                        <div className="w-100 d-flex justify-content-between">
                            <span>
                                Please list any licenses that you have had in the past 3 years:
                            </span>
                            <label htmlFor="validate_dot_license" className="mb-0">
                                <Field
                                    id="validate_dot_license"
                                    component={"input"}
                                    type="checkbox"
                                    name="validate_dot_license"
                                    disabled={loading}
                                />
                                &nbsp;None
                            </label>
                        </div>
                    </div>
                    <FieldArray
                        name="dot_license"
                        component={renderDotLicense}
                        loading={loading || (formValues && formValues.validate_dot_license)}
                    />
                    <div className="w-100 mt-2 mx-2 d-flex justify-content-between">
                        <span>Please list any other addresses that where you resided in the past 3 years:</span>
                        <label htmlFor="validate_dot_addresses" className="mb-0">
                            <Field
                                id="validate_dot_addresses"
                                component={"input"}
                                type="checkbox"
                                name="validate_dot_addresses"
                                disabled={loading}
                            />
                            &nbsp;None
                        </label>
                    </div>
                    <FieldArray
                        name="dot_addresses"
                        component={renderDotAddresses}
                        loading={loading || (formValues && formValues.validate_dot_addresses)}
                    />
                </div>
            )}
            <div className="my-5 w-100 d-flex flex-wrap justify-content-end">
                <button
                    type="button"
                    className="btn btn-secondary mr-2"
                    disabled={loading}
                    onClick={onCancel}
                >
                    CANCEL
                </button>
                <button
                    type="submit"
                    className={`btn ${loading ? 'btn-secondary' : 'btn-primary'}`}
                    onClick={()=>{setFormSubmitted(!formSubmitted)}}
                    disabled={loading}
                >
                    {loading ? 'LOADING...' : 'SAVE'}
                </button>
            </div>
        </form>
    )
}

const mapStateToProps = (state) => {
    return {
        errorFields: state.form.applyForm && state.form.applyForm.syncErrors
        
    };
};

const ApplyForm = reduxForm({
    form: 'applyForm',
    validate: (data) => {
        const errors = {}
        if (!data.prefix) errors.prefix = "Required Field";
        if (!data.first_name) errors.first_name = "Required Field";
        if (!data.last_name) errors.last_name = "Required Field";
        if (!data.phone) errors.phone = "Required Field";
        if (!data.email) errors.email = "Required Field";
        if (!data.address) errors.address = "Required Field";
        if (!data.city) errors.city = "Required Field";
        if (!data.state) errors.state = "Required Field";
        if (!data.zip) errors.zip = "Required Field";
        if (!data.birth) errors.birth = "Required Field";
        if (!data.education) errors.education = "Required Field";
        if (!data.position) errors.position = "Required Field";
        if (!data.month_salary) errors.month_salary = "Required Field";
        if (!data.availability) errors.availability = "Required Field";
        if (!data.shift) errors.shift = "Required Field";
        if (!data.custom_shift) errors.custom_shift = "Required Field";
        if (!data.start_date) errors.start_date = "Required Field";
        if (data.work_before && data.work_before.value === "YES" && !data.reason_leaving)
            errors.reason_leaving = "Required Field";
        if (!data.dl_type) errors.dl_type = "Required Field";
        if (!data.issue_state) errors.issue_state = "Required Field";
        if (!data.license_number) errors.license_number = "Required Field";
        if (!data.license_expiration) errors.license_expiration = "Required Field";
        if (!data.experience) errors.experience = "Required Field";
        if (data.accidents && data.accidents.yes == '1' && !data.accidents.reason)
            errors.accidents = { reason: "Required Field" };
        if (data.traffic_violations && data.traffic_violations.yes == '1' && !data.traffic_violations.reason)
            errors.traffic_violations = { reason: "Required Field" };
        if (data.denied_license && data.denied_license.yes == '1' && !data.denied_license.reason)
            errors.denied_license = { reason: "Required Field" };
        if (data.suspended_license && data.suspended_license.yes == '1' && !data.suspended_license.reason)
            errors.suspended_license = { reason: "Required Field" };
        if (data.convicted && data.convicted.yes === '1' && !data.convicted.reason)
            errors.convicted = { reason: "Required Field" };
        if (data.physical_limitations && data.physical_limitations.yes === '1' && !data.physical_limitations.reason)
            errors.physical_limitations = { reason: "Required Field" };
        if (!data.hear_about)
            errors.hear_about = "Required Field";
        if (!data.want_work)
            errors.want_work = "Required Field";
        if (!data.background_check_yes)
            errors.background_check_yes = "Required Field";
        if (!data.validate_dot_license && data.dot_license.length > 0){
            errors.dot_license = [];
            for(let i=0; i<data.dot_license.length; i+=1){
                const dot_errors = {}
                if (data.dot_license[i].dl_type && data.dot_license[i].dl_type.value == 'cdl'){
                    if (!data.dot_license[i].issue_state)
                        dot_errors.issue_state = "Required Field";
                    if (!data.dot_license[i].license)
                        dot_errors.license = "Required Field";
                    if (!data.dot_license[i].expiration)
                        dot_errors.expiration = "Required Field";
                }
                errors.dot_license.push(dot_errors);
            }
        }
        return errors;
    }
})(Form);

export default connect(mapStateToProps)(ApplyForm);
