import { connect } from "react-redux";
import ReviewsGrid from "./reviewsGrid";
import { actions } from "../../../../redux/modules/reviews";

const mstpPassenger = (state) => ({
    ...state.reviews,
    userInfo: state.login.userInfo,
    type: 0,
});

const GridPassenger = connect(mstpPassenger, actions)(ReviewsGrid);

const mstpBooking = (state) => ({
    ...state.reviews,
    userInfo: state.login.userInfo,
    type: 1,
});

const GridBooking = connect(mstpBooking, actions)(ReviewsGrid);

const mstpService = (state) => ({
    ...state.reviews,
    userInfo: state.login.userInfo,
    type: 2,
});

const GridService = connect(mstpService, actions)(ReviewsGrid);

export default { GridPassenger, GridBooking, GridService };
