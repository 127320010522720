import React from "react";
import { Field, reduxForm, FieldArray } from "redux-form"
import { connect } from "react-redux";
import { renderField, renderSearchSelect, renderSelectField, renderTextArea } from "../../Utils/renderField";
import { renderFieldCheck } from "../../Utils/renderField/renderField";
import app from "../../../../libs/apiClient";
import _ from "lodash";
import airlineCodes from '../../../utility/airlines.json';
import { renderAirlines, renderVehicles } from "../create/meetingProcedureForm";

const Form = (props) => {
    const loadAirports = (search) => {
        const params = {};
        if (search) {
            params.$or = [
                {
                    name: { $regex: _.escapeRegExp(search), $options: "i" },
                },
                {
                    description: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
                {
                    code: { $regex: _.escapeRegExp(search), $options: "i" },
                },
            ];
        }

        return app
            .service("/api/airport")
            .find({ query: params })
            .then((result) => {
                for (let i = 0; i < result.data.length; i += 1) {
                    result.data[i].label = `${result.data[i].code} - ${result.data[i].name}`;
                }
                return { options: result.data };
            })
            .catch(() => {
                return { options: [] };
            });
    };

    const loadVehicles = (search) => {
        const params = {};
        if (search) {
            params.$or = [
                { type: { $regex: _.escapeRegExp(search), $options: "i" } },
                { class: { $regex: _.escapeRegExp(search), $options: "i" } },
                {
                    vehicleCode: {
                        $regex: _.escapeRegExp(search),
                        $options: "i",
                    },
                },
            ];
        }

        return app
            .service("/api/vehicle")
            .find({ query: params })
            .then((result) => {
                for (let i = 0; i < result.data.length; i += 1)
                    result.data[i].label = `${result.data[i].vehicleCode} ${result.data[i].type}`;
                return { options: result.data };
            })
            .catch((error) => {
                console.log(error);
                return { options: [] };
            });
    };

    const { handleSubmit, cancel, formValues } = props;

    return (
        <form onSubmit={handleSubmit} id="meetingForm">
            <div className="form-group grid-container">
                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                    <div className="row">
                        <div className="col-12 form-group">
                            <label htmlFor="name">Name</label>
                            <Field
                                name="name"
                                component={renderField}
                                placeholder="International at IAD"
                                className="form-control"
                            />
                        </div>
                        <div className="col-12 form-group">
                            <label htmlFor="airport">Airport</label>
                            <Field
                                name="airport"
                                component={renderSearchSelect}
                                placeholder="Select airport"
                                loadOptions={loadAirports}
                                className="form-control"
                                allKey
                            />
                        </div>

                        <div className="col-12 form-group">
                            <div className="d-flex justify-content-between">
                                <label htmlFor="airlines">Airlines</label>
                                <Field
                                    name="selectAllAirlines"
                                    component={renderFieldCheck}
                                    label="Select All"
                                />
                            </div>
                            <FieldArray
                                name="airlines"
                                component={renderAirlines}
                                disabled={
                                    formValues && formValues.selectAllAirlines
                                }
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="vehicles">Vehicles</label>
                            <FieldArray
                                name="vehicles"
                                component={renderVehicles}
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="type">Procedure Type</label>
                            <Field
                                name="type"
                                component={renderSelectField}
                                placeholder="Select meeting procedure"
                                options={[
                                    { label: "Baggage", value: "baggage" },
                                    { label: "Curbside", value: "curbside" },
                                ]}
                            />
                        </div>

                        <div className="col-12 form-group">
                            <label htmlFor="instructions">Instructions</label>
                            <Field
                                name="instructions"
                                component={renderTextArea}
                                cols={3}
                                placeholder="Type the meeting instructions here"
                            />
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <button
                                    className="btn btn-secondary m-1"
                                    type="button"
                                    onClick={cancel}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const MeetFormUpdate = reduxForm({
    form: "meetingProcedureForm",
    validate: (data) => {
        const errors = {};
        if (!data.name) errors.name = "Required Field";
        if (!data.airport) errors.airport = "Required Field";
        if (!data.airline) errors.airline = "Required Field";
        if (!data.vehicle) errors.vehicle = "Required Field";
        if (!data.type) errors.type = "Required Field";
        if (!data.instructions) errors.instructions = "Required Field";
        return errors;
    },
})(Form);

const mstp = (state) => ({
    formValues: state.form.meetingProcedureForm && state.form.meetingProcedureForm.values,
});

export default connect(mstp)(MeetFormUpdate);
