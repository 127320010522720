import React from "react";
import PropTypes from "prop-types";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import Grid from "../../Utils/Grid/materialTable";
import moment from "moment";
import { settingsUserAccountsUsers, settingsUserAccountsUsersNew } from "../../../../routesConfiguration/paths";

function dateFormatter(cell: any) {
    if (!cell) {
        return "";
    }
    try {
        var date = new Date(cell);
        return date.toDateString();
    } catch (e) {
        return cell;
    }
}
function hideId(cell: any) {
    return "";
}

export default class UsersGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
    }

    componentDidMount() {
        this.props.find();

        if (this.tableRef)
            this.tableRef.current.dataManager.changePageSize(10);
    }

    render() {
        const { data, loading, destroy, find, page, userInfo: { superUser } } = this.props;

        return (!superUser
          ? <div className="d-flex w-100 justify-content-center align-items-center">
                <label> You are not a superuser </label>
            </div>
          : <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container material-livery-list">
                        <Grid
                            tableRef={this.tableRef}
                            title="Admin User"
                            columns={[
                                {
                                    title: "Email",
                                    field: "email",
                                },
                                {
                                    title: "First Name",
                                    field: "firstName",
                                },
                                {
                                    title: "Last Name",
                                    field: "lastName",
                                },
                                {
                                    title: "Created At",
                                    field: "createdAt",
                                    render: (row) =>
                                        moment(row.createdAt).format("MM/DD/YYYY")
                                },
                                {
                                    title: "Actions",
                                    field: "_id",
                                    width: "15%",
                                    align: "center",
                                    render: (row) =>
                                        activeFormatter({
                                            editar: settingsUserAccountsUsers,
                                            eliminar: destroy,
                                        })(row._id, row),
                                },
                            ]}
                            actions={[
                                {
                                    icon: () => (
                                        <Link
                                            className="btn btn-primary px-2 py-0 align-self-end rounded-circle"
                                            to={settingsUserAccountsUsersNew}
                                        >
                                            <h3>+</h3>
                                        </Link>
                                    ),
                                    isFreeAction: true,
                                },
                            ]}
                            data={data.data}
                            onPageChange={(p) => find(p + 1)}
                            isLoading={loading}
                            page={page - 1}
                            totalCount={data.total}
                            options={{
                                pageSizeOptions: [],
                                filtering: false,
                                idSynonym: "_id",
                                search: false,
                                pageSize: 10,
                                headerStyle: {
                                    backgroundColor: "#D50032",
                                    color: "#FFF",
                                    fontSize: "1rem",
                                },
                                cellStyle: {
                                    fontSize: "1rem",
                                },
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
