import React, { useState } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { renderTextArea } from "../Utils/renderField/renderField";
import IconTimes from "../../icons/times";

export const NoteModal = ({ name, noteValue, disabled = false }) => {
    const [openModal, setOpenModal] = useState(false);

    const toggleModal = () => setOpenModal(!openModal);
    const handleClose = () => {};

    return (
        <React.Fragment>
            <span
                className="text1 text-primary text-decoration-underline"
                style={{
                    fontSize: "0.7rem",
                    cursor: "pointer",
                }}
                onClick={toggleModal}
            >
                {noteValue ? "Edit note" : "Add note"}
            </span>
            <Modal
                isOpen={openModal}
                toggle={toggleModal}
                centered
                size="lg"
                onClosed={handleClose}
            >
                <ModalBody className="pb-0">
                    <div className="d-flex align-items-start justify-content-end">
                        <Button
                            type="button"
                            color="link"
                            onClick={toggleModal}
                        >
                            <IconTimes className="smallIcon p-1 text-danger" />
                        </Button>
                    </div>

                    <h5 className="text-center text-primary mb-4">
                        Add notes to driver
                    </h5>

                    <div className="flex-1 d-flex align-items-center justify-content-center">
                        <Field
                            name={name}
                            component={renderTextArea}
                            rows={6}
                            cols={50}
                            placeholder="i.e. Pick me up from door 4 lower level"
                            className="w-75"
                            disabled={disabled}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-center my-4 my-lg-5">
                        <Button
                            color="primary"
                            onClick={toggleModal}
                            className="py-3 w-25"
                            disabled={disabled}
                        >
                            SAVE
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

NoteModal.propTypes = {
    name: PropTypes.string,
    noteValue: PropTypes.string,
};
