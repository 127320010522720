import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Create from "./GroupRateCreate";
import { actions, filterZipCodes, filterExcludeZipCodes } from "../../../../redux/modules/locations";

const selector = formValueSelector("location");

const mstp = (state) => {
    const loading = state.locations.loading !== 0;
    const zipCodes = selector(state, "zipCodes");
    const excludeZipCodes = selector(state, "excludeZipCodes");

    return {
        ...state.locations,
        excludeZipCodes,
        zipCodes,
        loading,
        userInfo: state.login.userInfo,
    };
};

const mdtp = { ...actions, filterZipCodes, filterExcludeZipCodes };

export default connect(mstp, mdtp)(Create);
