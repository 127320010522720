import React from "react";
import { connect } from "react-redux";

import Detail from "./ReservationDetail";
import LoadMask from "../../Utils/LoadMask";
import Refund from '../../Refund/List';
import { actions } from "../../../../redux/modules/reservations";
import { actions as editionActions } from "../../../../redux/modules/trip-edition";

class DetailContainer extends React.Component {
    componentDidMount() {
        const { getReservation, match } = this.props;
        getReservation(match.params.id);
    }

    componentWillUnmount(){
        this.props.clear();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readReservations }) => readReservations))
                    this.props.getReservation(this.props.match.params.id);
            }
        }
    }

    render() {
        const { loading, reservation, loadingRefund, userInfo: { permission } } = this.props;

        return permission && permission.find(({ readReservations }) => !!readReservations)
            ? <LoadMask loading={loading || loadingRefund} dark blur>
                {reservation.canceled
                    ? <Refund
                            match={this.props.match}
                            dataNotification={reservation}
                            history={this.props.history}
                        />
                    : <Detail {...this.props} />
                }
            </LoadMask>
            : null
    }
}

const mstp = (state) => ({
    ...state.reservations,
    loadingRefund: state.refund.loading,
    userInfo: state.login.userInfo,
});

const mdtp = { ...actions };

export default connect(mstp, mdtp)(DetailContainer);
