import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../redux/modules/crewAdmin/contacts';
import IconAngleLeft from '../../../icons/angleLeftIcon';
import LoadMask from '../../Utils/LoadMask';
import Pagination from '../../Utils/pagination';
import Modal from 'react-responsive-modal';
import IconEdit from '../../../icons/edit';
import { IconTrashAlt } from '../../../icons/trash';
import ContactForm from './ContactForm';
import ContactRoles from './contactRoles';
import swal from 'sweetalert2';
import { RenderNumber } from '../../Utils/renderField/renderReadField';
import IconWatch from '../../../icons/watch';

class Container extends React.Component {

    state = {
        modal: false,
        modalContact: false,
        edit: false,
    };

    componentDidMount() {
        const { find, page } = this.props;
        find(page);
    }

    removeContact = (id) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this contact? </h3>",
            showCancelButton: true,
            confirmButtonColor: "#D50032",
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE",
        }).then(({ value }) => {
            if (value) {
                const { destroy } = this.props;
                destroy(id);
            }
        });
    };

    editContact = (id) => {
        const { load2Update } = this.props;
        load2Update(id).then(() => {
            this.openModal(true);
        });
    };

    submitModal = (values) => {
        const { update, create } = this.props;
        const { edit } = this.state;
        if (edit) {
            update(values).then(() => {
                this.closeModal();
            });
        } else {
            create(values).then(() => {
                this.closeModal();
            });
        }
    };

    openModal = (edit) => {
        this.setState({ modal: true, edit });
    };

    closeModal = () => {
        this.setState({ modal: false, edit: false });
    };

    openModalContacts = () => {
        this.setState({ modalContact: true });
    };

    closeModalContacts = () => {
        this.setState({ modalContact: false });
    };

    render() {
        const {
            search,
            history,
            data,
            find,
            item,
            loading,
            loadingForm,
            page,
            userInfo: { permission },
        } = this.props;

        const { modal, modalContact, edit } = this.state;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                <Modal open={modal} onClose={this.closeModal}>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 d-flex flex-wrap justify-content-center pt-5 pb-4">
                            <div className="col-12 col-md-8 col-xl-6">
                                <div className="mb-3">
                                    <h3 className="text-left"> {edit ? "Edit Contact" : "New Contact"} </h3>
                                </div>
                                <LoadMask loading={loadingForm} blur>
                                    <ContactForm
                                        onSubmit={this.submitModal}
                                        cancel={this.closeModal}
                                        clearData={this.props.clearData}
                                        setDetails={this.props.setDetails}
                                        address={this.props.address}
                                        item={item}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div className="col-12">
                    <Modal open={modalContact} onClose={this.closeModalContacts}
                        classNames={{ modal: 'd-flex' }}
                        styles={{ modal: { width: '90%', minHeight: '75%' } }}
                    >
                        <div className="w-100 pt-5 pb-4 flex-1">
                            <div className="border h-100">
                                <ContactRoles />
                            </div>
                        </div>
                    </Modal>
                </div>

                <div className="col-12 d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>history.goBack()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="col-12 col-md-10 col-xl-8">
                    <div className="pb-2">
                        {permission && permission.find(({ readAirlineCrew }) => readAirlineCrew == true) ? (
                            <button
                                type="button"
                                className="btn btn-light btn-block border small-shadow d-flex align-items-center justify-content-center mb-4 py-2"
                                onClick={() => this.openModalContacts(true)}
                            >
                                <label className="mb-0 mr-1 clickable">CONTACT ROLES</label>
                                <div className="" style={{ height: 25, width: 25 }}>
                                    <IconWatch />
                                </div>
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100 d-flex mb-1">
                        <h3 className="flex-1 text-left"> Contacts </h3>
                        {permission && permission.find(({ createAirlineCrew }) => createAirlineCrew == true) ? (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => this.openModal()}
                            >
                                ADD
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100 d-flex flex-wrap py-3">
                        {permission && permission.find(({ readAirlineCrew }) => readAirlineCrew == true) ? (
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by name, email or phone"
                                value={search}
                                onChange={(event) => {
                                    event.preventDefault();
                                    this.props.setSearch(event.target.value);
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100">
                        {permission && permission.find(({ readAirlineCrew }) => readAirlineCrew == true) ? (
                            <LoadMask loading={loading} blur>
                                <div className="react-bs-table react-bs-table-bordered">
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            {data.total > 0 &&
                                                data.data.map((item, index) => (
                                                    <tr className="px-3 py-2" key={index}>
                                                        <td className="w-100 d-flex">
                                                            <span className="col-4 px-0 text-truncate"> {item.fullName} </span>
                                                            <span className="col-4 px-0 text-truncate"> {item.email} </span>
                                                            <RenderNumber
                                                                value={item.phone}
                                                                formatTemplate="(###) ###-####"
                                                                className="col-3 px-0 text-truncate"
                                                            />
                                                            <div className="col-1 px-0 d-flex justify-content-center">
                                                                {permission && permission.find(({ updateAirlineCrew }) => updateAirlineCrew == true) ? (
                                                                    <span onClick={() => this.editContact(item._id)}>
                                                                        <IconEdit className="smallIcon px-1 clickable" />
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {permission && permission.find(({ removeAirlineCrew }) => removeAirlineCrew == true) ? (
                                                                    <span onClick={() => this.removeContact(item._id)}>
                                                                        <IconTrashAlt className="smallIcon px-1 clickable" />
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    total={data.total}
                                    page={page}
                                    find={find}
                                    itemsPerPage={5}
                                />
                            </LoadMask>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.contacts,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(Container);
