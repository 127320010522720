import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/crewAdmin/airlines';
import IconAngleLeft from '../../../../icons/angleLeftIcon';
import IconWatch from '../../../../icons/watch';
import LoadMask from '../../../Utils/LoadMask';
import Pagination from '../../../Utils/pagination';
import AirlineForm from '../create/AirlineForm';
import Modal from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { IconTrashAlt } from '../../../../icons/trash';
import swal from 'sweetalert2';
import { getPathWithParams, settingsAirlinesAccountsId } from '../../../../../routesConfiguration/paths';


class Container extends React.Component {
    state = {
        modal: false,
    };

    componentDidMount() {
        this.props.find(this.props.page);
    }

    removeCompany = (id) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this Company? </h3>",
            showCancelButton: true,
            confirmButtonColor: "#D50032",
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE",
        }).then(({ value }) => {
            if (value) {
                const { destroy } = this.props;
                destroy(id);
            }
        });
    };

    submitModal = (values) => {
        this.props.create(values).then(() => {
            this.closeModal();
        });
    };

    openModal = () => {
        this.setState({ modal: true });
    };

    closeModal = () => {
        this.setState({ modal: false });
    };

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readAirlineCrew }) => !!readAirlineCrew))
                    this.props.find(this.props.page);
            }
        }
    }

    render() {
        const {
            search,
            history,
            data,
            find,
            loading,
            loadingForm,
            downloadRoutesReport,
            userInfo: { superUser, permission },
            page,
        } = this.props;

        const { modal } = this.state;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                <Modal open={modal} onClose={this.closeModal}>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 d-flex flex-wrap justify-content-center pt-5 pb-4">
                            <div className="col-12 col-md-8 col-xl-6">
                                <div className="mb-3">
                                    <h3 className="text-left"> New Company </h3>
                                </div>
                                <LoadMask loading={loadingForm} blur>
                                    <AirlineForm
                                        onSubmit={this.submitModal}
                                        cancel={this.closeModal}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div className="col-12 d-flex justify-content-start">
                    <div className="d-flex text-primary d-flex align-items-center clickable" onClick={()=>history.goBack()}>
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="col-12 col-md-10 col-xl-8">
                    <div className="w-100 d-flex mb-1">
                        <h3 className="flex-1 text-left"> Companies </h3>
                        {permission && permission.find(({ createAirlineCrew }) => !!createAirlineCrew) ? (
                            <button type="button" className="btn btn-primary" onClick={()=>this.openModal()}>
                                ADD
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100 d-flex flex-wrap py-3">
                        {permission &&
                        permission.find(({ readAirlineCrew }) => !!readAirlineCrew) ? (
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by name, code or livery id"
                                value={search}
                                onChange={(event) => {
                                    event.preventDefault();
                                    this.props.setSearch(event.target.value);
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100">
                        {permission &&
                        permission.find(({ readAirlineCrew }) => !!readAirlineCrew) ? (
                            <LoadMask loading={loading} blur>
                                <div className="react-bs-table react-bs-table-bordered">
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            {data.total > 0 &&
                                                data.data.map((item, index) => (
                                                    <tr
                                                        className="px-3 py-2"
                                                        key={index}
                                                    >
                                                        <td className="w-100 d-flex">
                                                            <span className="col-5 px-0 text-truncate"> {item.company} </span>
                                                            <span className="col-2 px-0 text-truncate"> ({item.code}) </span>
                                                            <span className="col-4 px-0 text-truncate"> {item.liveryId} </span>
                                                            <span className="col-1 px-0 d-flex justify-content-center">
                                                                <Link
                                                                    to={getPathWithParams(
                                                                        settingsAirlinesAccountsId,
                                                                        "id",
                                                                        item._id
                                                                    )}
                                                                    className="text-dark text-decoration-none px-1"
                                                                >
                                                                    <IconWatch className="smallIcon clickable" />
                                                                </Link>
                                                                {permission &&
                                                                permission.find(({ removeAirlineCrew }) => !!removeAirlineCrew) ? (
                                                                    <span
                                                                        onClick={() =>
                                                                            this.removeCompany(
                                                                                item._id
                                                                            )
                                                                        }
                                                                    >
                                                                        <IconTrashAlt className="smallIcon px-1 clickable" />
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="d-flex">
                                    <div className="d-flex flex-1">
                                        <div className="d-flex clickable" onClick={downloadRoutesReport}>
                                            <span style={{ width: "12px" }}>
                                                <svg
                                                    xmlns="SVG namespace "
                                                    viewBox="0 0 384 512"
                                                >
                                                    <path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z"/>
                                                </svg>
                                            </span>
                                            <span className="pl-2 font-bold" style={{ textDecoration: "underline" }}>
                                                Route Data
                                            </span>
                                        </div>
                                    </div>
                                    <Pagination
                                        total={data.total}
                                        page={page}
                                        find={find}
                                        itemsPerPage={5}
                                    />
                                </div>
                            </LoadMask>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.airlines,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(Container);
