import React, { Component } from "react";
import PropTypes from "prop-types";
import { BootstrapTable } from "react-bootstrap-table";
import LoadMask from "../LoadMask";

export default class Grid extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false,
    };

    parseData(data) {
        // this method parses the data and extracts:
        // total: the total number of rows
        // page: the current page
        // pageSize: the page size
        // rows: rows for the table
        return {
            total: data.total,
            page: Math.floor(data.skip / data.limit + 1),
            pageSize: data.limit,
            rows: data.data,
        };
    }

    render() {
        // state
        const {
            // state
            data,
            page,
            loading,
            // bind
            onPageChange,
            onSortChange,
            onRowClick,
            sizePerPage = 10,
            // other
            ...other
        } = this.props;

        const { rows, total } = this.parseData(data);

        // render
        const options = {
            page,
            sizePerPage,
            hideSizePerPage: true,
            alwaysShowAllBtns: true,
            onRowClick: onRowClick ? onRowClick : () => {},
            noDataText: loading ? "Loading..." : "No data",
            onPageChange: onPageChange || (() => {}),
            onSortChange: onSortChange ? onSortChange : () => {},
        };

        return (
            <LoadMask loading={loading} dark blur>
                <BootstrapTable
                    data={loading ? [] : rows}
                    remote
                    pagination
                    hover
                    fetchInfo={{ dataTotalSize: total }}
                    options={options}
                    {...other}
                />
            </LoadMask>
        );
    }
}
