import React from "react";

const IconWindowMaximize = ({ className }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            viewBox="0 0 161.000000 143.000000"
        >
            <g
                transform="translate(0.000000,143.000000) scale(0.100000,-0.100000)"
                fill="currentColor"
                stroke="none"
            >
                <path
                    d="M136 1395 c-56 -20 -104 -71 -121 -129 -22 -74 -22 -1039 0 -1113 18
-61 74 -117 134 -133 27 -7 244 -10 673 -8 620 3 634 3 673 24 22 11 54 40 70
63 l30 43 0 568 0 568 -30 43 c-16 23 -48 52 -70 63 -39 21 -52 21 -680 23
-526 2 -647 0 -679 -12z m235 -214 c23 -23 29 -38 29 -71 0 -102 -139 -139
-186 -49 -53 103 76 201 157 120z m1010 -20 c10 -13 19 -36 19 -51 0 -15 -9
-38 -19 -51 l-19 -24 -394 -3 c-424 -2 -428 -2 -458 51 -13 24 -13 30 0 54 30
53 34 53 458 51 l394 -3 19 -24z"
                />
            </g>
        </svg>
    );
};

export default IconWindowMaximize;
