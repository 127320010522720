import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import Grid from "./TripsReportGrid";
import {
    find,
    destroy,
    setQuery,
} from "../../../../redux/modules/reservations";

class TripsReportGridContainer extends React.Component {
    state = {
        activeTab: 1,
    };

    startOfDay = moment().hours("0").minutes("0").seconds("0");

    endOfDay = moment().hours("23").minutes("59").seconds("59");

    componentDidMount() {
        const { find, setQuery } = this.props;
        setQuery({
            puTime: {
                $gte: this.startOfDay,
                $lte: this.endOfDay,
            },
        });
        find();
    }

    componentWillUnmount() {
        const { setQuery } = this.props;
        setQuery({});
    }

    handlePastQuery = () => {
        const { setQuery, find } = this.props;

        this.setState({ activeTab: 0 }, () => {
            setQuery({
                puTime: { $lt: this.startOfDay },
            });
            find();
        });
    };

    handleTodayQuery = (change) => {
        const { setQuery, find } = this.props;

        this.setState({ activeTab: 1 }, () => {
            setQuery({
                puTime: {
                    $gte: this.startOfDay,
                    $lte: this.endOfDay,
                },
            });
            change("limitDate", "");
            find();
        });
    };

    handleFutureQuery = (change) => {
        const { setQuery, find } = this.props;

        this.setState({ activeTab: 2 }, () => {
            setQuery({
                puTime: {
                    $gt: this.endOfDay,
                },
            });
            change("limitDate", "");
            find();
        });
    };

    render() {
        return (
            <Grid
                {...this.props}
                {...this.state}
                handlePastQuery={this.handlePastQuery}
                handleTodayQuery={this.handleTodayQuery}
                handleFutureQuery={this.handleFutureQuery}
            />
        );
    }
}

const mstp = (state) => state.reservations;

const mdtp = { find, destroy, setQuery };

export default connect(mstp, mdtp)(TripsReportGridContainer);
