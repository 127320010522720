import React, { useMemo, useState } from 'react';
import { Modal, ModalBody, Tooltip } from 'reactstrap';


import { Field, FieldArray, reduxForm } from "redux-form";
import { combine, validate, validatorFromFunction, validators } from "validate-redux-form";
import {
    renderField,
    renderSelectField,
    renderImagePicker,
} from "Utils/renderField/renderField";
import { renderSwitch, renderDropdownSelection, renderNumber } from "../../Utils/renderField";
import { IconOptions } from '../../../utility/constants';
import { future, required, image } from "../../../utility/form-validations";
import LoadMask from '../../Utils/LoadMask';
import IconPlus from '../../../icons/plus';
import IconTimes from '../../../icons/times';
import { connect } from 'react-redux';
import IconQuestionCircle from '../../../icons/questionCircle';

IconOptions.forEach((item) => item.text = "")

const renderFeatures = ({ fields }) => (
    <div className="row">
        <div className="col-12 px-0">
            <hr />
            <div className="grid-title d-flex flex-colum flex-sm-row justifyu-content-center align-items-stretch align-items-sm-center">
                <h3 className="ml-0 ml-sm-auto">
                    <strong> Features </strong>
                </h3>
                <button
                    type="button"
                    className="btn btn-secondary ml-auto flex-nowrap flex-sm-wrap"
                    onClick={(e) => {e.preventDefault();fields.push({})}}
                    style={{ padding: "0.375rem", borderRadius: "50%" }}
                >
                    <IconPlus className="smallIcon p-1"/>
                </button>
            </div>
            <br />
            <div className="row mx-0">
                {fields.map((item, i) => (
                    <div className="col-12 col-lg-6 col-xl-4 p-1" style={{ border: "1px solid #222" }} key={`holder${i}`}>
                        <div className="d-flex justify-content-end px-1 py-0">
                            <span
                                className="btn btn-secondary px-2 py-0"
                                onClick={(e) =>{e.preventDefault();  fields.remove(i)}}
                            >
                                X
                            </span>
                        </div>
                        <div className="d-table">
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle">
                                    <strong> Icon:&nbsp;</strong>
                                </div>
                                <div className="d-table-cell">
                                    <div className="mb-2 d-flex">
                                        <Field
                                            fluid
                                            selection
                                            search
                                            name={`${item}.icon`}
                                            component={renderDropdownSelection}
                                            className="selectIcons"
                                            placeholder="Select icon"
                                            options={IconOptions}
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle">
                                    <strong> Description:&nbsp;</strong>
                                </div>
                                <div className="d-table-cell w-100">
                                    <div className="mb-2">
                                        <Field
                                            name={`${item}.description`}
                                            component={renderField}
                                            className="form-control"
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-table-row">
                                <div className="d-table-cell align-middle">
                                    <strong>Class:&nbsp;</strong>
                                </div>
                                <div className="d-table-cell w-100">
                                    <div className="mb-2">
                                        <Field
                                            fluid
                                            selection
                                            multiple
                                            name={`${item}.featureType`}
                                            component={renderDropdownSelection}
                                            options={[
                                                {
                                                    key: "Standard",
                                                    text: "Standard",
                                                    value: "Standard",
                                                },
                                                {
                                                    key: "Hourly",
                                                    text: "Hourly",
                                                    value: "Hourly",
                                                },
                                                {
                                                    key: "Airport",
                                                    text: "Airport",
                                                    value: "Airport",
                                                },
                                            ]}
                                            placeholder="Select feature type"
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);


const ModalEdit = (props) => {
    const { handleSubmit, vehicleEdit: { _id, photo, vehicleDefault }, restoreDefault, showRestoreBtn, formValues } = props;

    const [openTooltip, setOpenTooltip] = useState(false);

    const positiveInt = (value) =>
        value && value < 1 ? "Must be at least 1" : undefined;

    const showDefaultHours = () => {
        const windowOverride = !formValues.inFleet
            ? formValues.defaultWindowFo
            : formValues.defaultWindow;
        return windowOverride ? `default (${windowOverride} Hrs)` : "";
    };

    const defaultWindow = useMemo(() => {
        if (!formValues) return "";
        return showDefaultHours();
    }, [formValues]);

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}
            modalClassName="no-scroll-y" className="modal-dialog--big"
        >
            <ModalBody className="modal-body--big"
                style={{ overflowY: "auto", maxHeight: "95vh",
                    marginTop: "1%", marginBottom: "1%", display: "block",
                }}
            >
                <button type="button" onClick={props.toggle}
                    className="modal-body__close"
                >
                    <IconTimes className="smallIcon"/>
                </button>
                <LoadMask loading={props.vehicleLocationLoader} blur>
                    <div className="container-fluid mt-5">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group grid-container">
                                <div className="grid-titulo padding-15">
                                    <h2>
                                        <strong>Vehicle</strong>
                                    </h2>
                                </div>

                                <div className="padding-15 p-sm-0 pt-sm-1 pb-sm-1">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="type">Type</label>
                                            <Field
                                                name="type"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="description">Description</label>
                                            <Field
                                                name="description"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="vehicleCode">Vehicle Code</label>
                                            <Field
                                                name="vehicleCode"
                                                component={renderField}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="capacity">Capacity</label>
                                            <Field
                                                name="capacity"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                                validate={positiveInt}
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="minimumHours">Minimum Hours</label>
                                            <Field
                                                name="minimumHours"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="minimumTransfer">
                                                Minimum Transfer
                                            </label>
                                            <Field
                                                name="minimumTransfer"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="standardDwell">
                                                Standard Dwell
                                            </label>
                                            <Field
                                                name="standardDwell"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="standardBlock">
                                                Standard Block
                                            </label>
                                            <Field
                                                name="standardBlock"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="reductionRate1">
                                                Reduction Rate 1
                                            </label>
                                            <Field
                                                name="reductionRate1"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="reductionRate2">
                                                Reduction Rate 2
                                            </label>
                                            <Field
                                                name="reductionRate2"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="reductionRate3">
                                                Reduction Rate 3
                                            </label>
                                            <Field
                                                name="reductionRate3"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="reductionRate4">
                                                Reduction Rate 4
                                            </label>
                                            <Field
                                                name="reductionRate4"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="class">Luggage Space</label>
                                            <Field
                                                name="luggageSpace"
                                                component={renderField}
                                                type="number"
                                                className="form-control"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <label htmlFor="class">Class</label>
                                            <Field
                                                name="class"
                                                component={renderSelectField}
                                                type="select"
                                                labelKey="label"
                                                valueKey="value"
                                                options={[
                                                    {
                                                        value: "PREMIERE",
                                                        label: "PREMIERE",
                                                    },
                                                    {
                                                        value: "BUSINESS",
                                                        label: "BUSINESS",
                                                    },
                                                    {
                                                        value: "EXPRESS",
                                                        label: "EXPRESS",
                                                    },
                                                ]}
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                                            <Tooltip
                                                isOpen={openTooltip}
                                                target="stopTimeLabel"
                                                toggle={() =>
                                                    setOpenTooltip(!openTooltip)
                                                }
                                            >
                                                Minutes to be added for each stop for this vehicle.
                                            </Tooltip>
                                            <label htmlFor="stopTime">
                                                Stop Time
                                                <span id="stopTimeLabel">
                                                    <IconQuestionCircle className="pl-1 smallIcon" />
                                                </span>
                                            </label>
                                            <Field
                                                name="stopTime"
                                                component={renderNumber}
                                                decimalScale={0}
                                                suffix=" minutes"
                                                placeholder="Stop time"
                                            />
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group"></div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 form-group" style={{zIndex: "0"}}>
                                            <label htmlFor="picture">Photo</label>
                                            <Field
                                                photo={photo}
                                                name="picture"
                                                component={renderImagePicker}
                                                useMediaUrl={false}
                                            />
                                        </div>

                                        <div
                                            className="col-lg-6 col-md-6 col-sm-12 form-group"
                                            style={{ zIndex: "0" }}
                                        >
                                            <label htmlFor="isHourlyVehicle" className="mt-3">
                                                Hourly vehicle?
                                            </label>
                                            <Field
                                                name="isHourlyVehicle"
                                                component={renderSwitch}
                                                className=""
                                            />
                                            <div className="">
                                                <label className="mt-3">
                                                    In Fleet?
                                                </label>
                                                <Field
                                                    name="inFleet"
                                                    component={renderSwitch}
                                                    className=""
                                                />
                                            </div>
                                            <div className="">
                                                <label className="mt-3">
                                                    Booking window override?
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <Field
                                                        name="bookingOverride"
                                                        component={renderSwitch}
                                                        className=""
                                                    />
                                                    <span className="ml-4 d-flex align-items-center mb-2">
                                                        {defaultWindow}
                                                    </span>
                                                </div>
                                            </div>
                                            {formValues &&
                                            formValues.bookingOverride ? (
                                                <div className="">
                                                    <label className="mt-3">
                                                        Hours
                                                    </label>
                                                    <Field
                                                        name="bookingOverrideValue"
                                                        component={renderNumber}
                                                        decimalScale={2}
                                                        suffix="hrs"
                                                    />
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>

                                        <div className="col-12 px-0 px-md-3 form-group">
                                            <div className="col-12 px-0 form-group">
                                                <FieldArray
                                                    name="features"
                                                    component={renderFeatures}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <button
                                                    className="btn btn-secondary m-1"
                                                    type="button" onClick = {props.toggle}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary m-1"
                                                >
                                                    Save
                                                </button>
                                                {showRestoreBtn && (vehicleDefault && vehicleDefault !== _id) && (
                                                    <button
                                                        type="button"
                                                        onClick={()=>restoreDefault(_id)}
                                                        className="btn btn-warning m-1"
                                                    >
                                                        Remove
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </LoadMask>
            </ModalBody>
        </Modal>
    );
}

// In update form, picture is not a required field
export const ModalVehicleEdit = reduxForm({
    form: "vehicleLocationForm",
    validate: (data) => {
        return validate(data, {
            type: validators.exists()("Required field"),
            description: validators.exists()("Required field"),
            vehicleCode: validators.exists()("Required field"),
            reductionRate1: validators.exists()("Required field"),
            reductionRate2: validators.exists()("Required field"),
            reductionRate3: validators.exists()("Required field"),
            reductionRate4: validators.exists()("Required field"),
            luggageSpace: validators.exists()("Required field"),
            //picture: validators.exists()("Required field"),
            bookingOverrideValue: combine(
                validators.exists()("Required field"),
                validatorFromFunction((value, bookingOverride) => {
                    if (bookingOverride) {
                        const windowOverride = !data.inFleet
                            ? data.defaultWindowFo
                            : data.defaultWindow;
                        if (
                            windowOverride &&
                            parseFloat(windowOverride) !==
                                parseFloat(data.bookingOverrideValue)
                        ) {
                            return true;
                        }
                    }
                    return false;
                })(data.bookingOverride)("Invalid Value")
            ),
            class: validators.exists()("Required field"),
            stopTime: validators.number({ min: 0, max: 60 })(
                "Needs to be number between 0 and 60"
            ),
        });
    },
})(ModalEdit);

const mstp = state => ({
    formValues: state.form.vehicleLocationForm && state.form.vehicleLocationForm.values,
})

export default connect(mstp)(ModalVehicleEdit);
