import React from "react";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import CompanyUpdateForm from "../CompanyForm";
import { clientTypes } from "../../../utility/constants";
import {
    patch,
    update,
    load2Update,
    setClientsQuery,
} from "../../../../redux/modules/clients";

const [person, company] = clientTypes;

class UpdateContainer extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { setClientsQuery, load2Update, match } = this.props;
        setClientsQuery({ userType: company });
        load2Update(match.params.id);
    }

    componentWillUnmount() {
        const { setClientsQuery } = this.props;
        setClientsQuery({});
    }

    handleApproval = () => {
        const { patch, match } = this.props;
        Swal.fire({
            title: "Validate this rejected company?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, validate",
            cancelButtonText: "No, cancel",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            reverseButtons: true,
        }).then((result) => {
            if (result.value)
                patch(match.params.id, {
                    isVerified: true,
                    $unset: { rejectionReason: "" },
                });
        });
    };

    handleRejection = () => {
        const { patch, match } = this.props;

        Swal.fire({
            title: "Submit the rejection reason",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "Submit",
            confirmButtonColor: "#D50032",
            cancelButtonColor: "#545b62",
            showLoaderOnConfirm: true,
            preConfirm: async (rejectionReason) => {
                try {
                    return await patch(match.params.id, {
                        rejectionReason,
                        isVerified: false,
                    });
                } catch (err) {
                    Swal.showValidationMessage(`Request failed: ${error}`);
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        });
    };

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readCompanies }) => readCompanies)){
                    this.props.setClientsQuery({ userType: company });
                    this.props.load2Update(this.props.match.params.id);
                }
            }
        }
    }

    render() {
        const { update, isVerified, rejectionReason, userInfo: { permission } } = this.props;

        return (
            <div className="row flex-column align-items-center">
                {!isVerified && !rejectionReason && (
                    <div
                        className="d-flex w-100 flex-column flex-sm-row align-items-sm-center alert alert-dark"
                        role="alert"
                    >
                        Pending company
                        {permission && permission.find(({ updateCompanies }) => !!updateCompanies) && (
                            <React.Fragment>
                                <button
                                    type="button"
                                    onClick={this.handleRejection}
                                    className="btn btn-secondary m-1 ml-sm-auto"
                                >
                                    Reject
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleApproval}
                                    className="btn btn-primary m-1"
                                >
                                    Validate
                                </button>
                            </React.Fragment>
                        )}
                    </div>
                )}

                {isVerified && (
                    <div
                        className="d-flex w-100 flex-column flex-sm-row align-items-sm-center alert alert-dark"
                        role="alert"
                    >
                        Approved company
                        {permission && permission.find(({ updateCompanies }) => !!updateCompanies) && (
                            <button
                                type="button"
                                onClick={this.handleRejection}
                                className="btn btn-secondary m-1 ml-sm-auto"
                            >
                                Reject
                            </button>
                        )}
                    </div>
                )}

                {rejectionReason && (
                    <div
                        className="d-flex w-100 flex-column flex-sm-row align-items-sm-center alert alert-dark"
                        role="alert"
                    >
                        Rejected company
                        {permission && permission.find(({ updateCompanies }) => !!updateCompanies) && (
                            <button
                                type="button"
                                onClick={this.handleApproval}
                                className="btn btn-primary m-1 ml-sm-auto"
                            >
                                Validate
                            </button>
                        )}
                    </div>
                )}

                {permission && permission.find(({ updateCompanies }) => !!updateCompanies) && (
                    <CompanyUpdateForm
                        onSubmit={update}
                        isVerified={isVerified}
                        rejectionReason={rejectionReason}
                    />
                )}

            </div>
        );
    }
}

const selector = formValueSelector("client");
const mstp = (state) => ({
    isVerified: selector(state, "isVerified"),
    rejectionReason: selector(state, "rejectionReason"),
    userInfo: state.login.userInfo,
});

const mdtp = {
    patch,
    update,
    load2Update,
    setClientsQuery,
};

export default connect(mstp, mdtp)(UpdateContainer);
