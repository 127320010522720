import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BookForm from './BookForm';
import {
    setDetails,
    setHourlyItinerary,
    setTransferItinerary,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
} from '../../../../../redux/modules/itinerary';
import {
    setTripItem,
    loadVehicles,
    searchForSpecialRate,
    clearVehicles,
    setTrip,
} from '../../../../../redux/modules/quote';
import swal from 'sweetalert2';


const BookingModal = ({
    itinerary,
    setDetails,
    loadVehicles,
    setTripItem,
    clear,
    vehicle = [],
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
    loadingVehicles,
    handleSearchForSpecialRate,
    tripCartItem,
    removeVehicles,
    vehicles,
    updateTrip,
}) => {

    const onSubmit = values => {
        setTripItem(values).then(()=>{
            loadVehicles();
        }).catch(error=>{
            const msg = error.message || error;
            swal.fire({
                type: "error",
                title: "ERROR!",
                text: `${msg}`
            })
        });
    }


    const onUpdate = async (values) => {
        try {
            await setTripItem(values);
            await updateTrip();
            clear();
        } catch (error) {
            const msg = error.message || error;
            swal.fire({
                type: "error",
                title: "ERROR!",
                text: `${msg}`,
            });
        }
    };

    return (
        <div className="modalItinerary">
            <div className="row justify-content-center mb-1">
                <BookForm
                    onSubmit={(values)=>onSubmit(values)}
                    itinerary={itinerary}
                    vehicle={vehicle}
                    setDetails={setDetails}
                    onSelectAirline={onSelectAirline}
                    getFlightInfo={getFlightInfo}
                    onSelectFlight={onSelectFlight}
                    clearForm={clear}
                    loadingVehicles={loadingVehicles}
                    searchForSpecialRate={handleSearchForSpecialRate}
                    tripCartItem={tripCartItem}
                    removeVehicles={removeVehicles}
                    vehicles={vehicles}
                    updateTrip={onUpdate}
                />
            </div>

        </div>

    );
};

const mdtp = {
    setDetails,
    setTripItem,
    setHourlyItinerary,
    setTransferItinerary,
    loadVehicles,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
    handleSearchForSpecialRate: searchForSpecialRate,
    removeVehicles: clearVehicles,
    updateTrip: setTrip,
};

const mstp = (state) => ({
    itinerary: state.itinerary,
    vehicles: state.quote.vehicles,
    tripCartItem: state.quote.tripCartItem,
    vehicle: state.vehicle.vehicle,
});

export default connect(mstp, mdtp)(BookingModal);
