import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import { actions } from '../../../../../../../redux/modules/crewAdmin/crewRoutes';
import IconPlus from '../../../../../../icons/plus';
import IconWatch from '../../../../../../icons/watch';
import LoadMask from '../../../../../Utils/LoadMask';
import Pagination from '../../../../../Utils/pagination';
import { getFullPathWithParams, getPathWithParams, settingsAirlinesAccountsIdRouteId, settingsAirlinesAccountsIdRouteNew } from '../../../../../../../routesConfiguration/paths';


const RoutesList = (props) => {
    const { data, loading, airlineId, search, setSearch, inactiveRoutes, switchInactiveRoutes, disabled } = props;

    useEffect(() => {
        const params = {};
        if (props.account_group) params.account_group = props.account_group;
        props.find(props.airlineId, props.page, params);
        return () => {
            switchInactiveRoutes(false);
            setSearch();
        };
    }, []);

    return (
        <div className="w-100 d-flex flex-wrap">
            <div className="col-12 d-flex align-items-center justify-content-end">
                <Switch
                    id="inactiveRoutes-switch"
                    onChange={(value) => {
                        switchInactiveRoutes(value, airlineId);
                    }}
                    checked={inactiveRoutes}
                />
                <label className="mb-0 ml-1"> INACTIVE </label>
            </div>
            <div className="col-12 mb-3">
                <input
                    type="text"
                    value={search}
                    className="form-control w-100 my-2"
                    onChange={(event) => {
                        const params = {};
                        if (props.account_group)
                            params.account_group = props.account_group;
                        setSearch(event.target.value, airlineId, params);
                    }}
                    placeholder="Search..."
                />
            </div>
            <div className="col-12 px-0 my-2 d-flex flex-wrap">
                <div className="col-12 d-flex justify-content-between mb-3">
                    <h4>Routes</h4>
                    {!disabled && (
                        <Link
                            to={getPathWithParams(settingsAirlinesAccountsIdRouteNew, "airlineId", props.airlineId)}
                            className="btn btn-light border small-shadow"
                        >
                            <IconPlus className="smallIcon text-secondary"/>
                        </Link>
                    )}
                </div>
                <div className="col-12 px-0">
                    <LoadMask loading={loading} blur>
                        <div className="d-flex flex-wrap">
                            {data && data.total > 0 ? data.data.map((item, index)=>{
                                return (
                                    <div className="col-6 my-2" key={index}>
                                        <div className="d-flex flex-wrap border rounded small-shadow p-3">
                                            <div className="d-flex flex-column">
                                                <h5> {item.name} </h5>
                                                <label> {item.distance} mi / {((item.hours || 0) * 60).toFixed(2)} min </label>
                                                <label> {item.isAOA ? 'AOA' : ''} </label>
                                            </div>
                                            <div className="w-100 d-flex justify-content-end">
                                                <Link
                                                    to={getFullPathWithParams(
                                                        settingsAirlinesAccountsIdRouteId,
                                                        ["airlineId", "id"]
                                                        [props.airlineId, item._id]
                                                    )}
                                                    className="clickable text-dark"
                                                >
                                                    <IconWatch className="smallIcon"/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ''}
                            <div className="col-12">
                                <Pagination
                                    find={(page) => {
                                        const params = {};
                                        if (props.account_group)
                                            params.account_group = props.account_group;
                                        props.find(airlineId, page, params);
                                    }}
                                    total={data.total}
                                    page={props.page}
                                    itemsPerPage={5}
                                />
                            </div>
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    )
}

const mstp = state => ({
    ...state.crewAdmin.crewRoutes,
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(RoutesList);
