import { handleActions } from "redux-actions";
import Swal from "sweetalert2";
import moment from "moment";
import { goBack } from "react-router-redux";
import { initialize as initializeForm, change } from "redux-form";
import airportCodes from '../../common/utility/airports.json';
import airlineCodes from '../../common/utility/airlines.json';
import app from "../../libs/apiClient";
import _ from 'lodash';
import format from "../../common/utility/format";
import { data } from "jquery";

// Constants
const service = app.service("/api/reservation/");

const DATA = "TRIP_EDITION_DATA";
const LOADER = "TRIP_EDITION_LOADER";
const LOADER_NEW_PRICE = "TRIP_EDITION_LOADER_NEW_PRICE";
const QUERY = "TRIP_EDITION_QUERY";
const INVALID_ADDRESS = "TRIP_EDITION_INVALID_ADDRESS";
const VEHICLES_TRIP_EDITION = "VEHICLES_TRIP_EDITION";
const GOOGLE_MAPS_ERROR = "GOOGLE_MAPS_ERROR";
const SET_IS_AIRPORT = "TRIP_EDITION_SET_IS_AIRPORT";
const SET_FLIGHTS_NUMBERS = "TRIP_EDITION_SET_FLIGHTS_NUMBERS";
const CHECKOUT_FORM = "TRIP_EDITION_CHECKOUT_FORM";

// Pure Actions
const setData = (data) => ({
    type: DATA,
    data,
});

export const setQuery = (query) => ({
    type: QUERY,
    query,
});

const setLoader = (type, loading) => ({ type, loading })

const setInvalidAddress = (typeAddress) => ({
    type: INVALID_ADDRESS,
    typeAddress
})

const setShowCheckoutForm = (showCheckoutForm = true) => ({
    type: CHECKOUT_FORM,
    showCheckoutForm,
});

const errorHandler = (error) => {
    console.log(error)
    let message = ""
    if(typeof(error) === "object")
        if(error.message)
            message = error.message;
        else
            for(let i = 0; i < error.length ; i++)
                message = message + error[i];
    else message = error;
    if(!message.length)
        message = "there was an error"
    return Swal.fire({
        title: "Error",
        text: `${message}`,
        icon: 'error',
        type: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#D50032",
    });
}

export const setDetails = (detail, key) => dispatch => {
    dispatch({ type: "TRIP_EDITION_"+key.toUpperCase(), [key]: detail });
    let isAirport = false, airportCode;
    if(detail && detail.types && detail.types.includes('airport') && "TRIP_EDITION_"+key.toUpperCase() === "TRIP_EDITION_FROM"){
        isAirport = true;
        airportCode = airportCodes.find(item => detail.formatted_address.match(new RegExp(item.code)) !== null);
    }

    if("TRIP_EDITION_"+key.toUpperCase() == "TRIP_EDITION_FROM"){
        dispatch({ type: SET_IS_AIRPORT, isAirport, airportCode });
        if(!isAirport){
            dispatch(change('tripEditForm', 'flightAirline', null));
            dispatch(change('tripEditForm', 'flightNumber', null));
        }
    }
    dispatch(changeAddress());
};

export const getFlightInfo = (flight) => (dispatch, getStore) => {
    const store = getStore().form.tripEditForm.values;
    let { airportCode } = getStore().tripEdition;
    const { flightAirline, puDate } = store;

    flight = flight.match(/\d{2,4}/g);

    const params = { flightNumber: flight }
    if(airportCode)
        params.airportCode = airportCode.code;
    if(flightAirline)
        params.airlineCode = typeof(flightAirline) === 'object' ? flightAirline.code : flightAirline;
    if(puDate)
        params.date_of_service = moment(puDate).format('MM/DD/YYYY');

    app.service('/api/airlineApi').find({ query: params }).then( data => {
        if(data && data.result != null){
            let flightsNumbers = [{
                index: 0,
                flightNumber: data.result.flightNumber,
                airportCodeDep: data.result.airportCodeDep,
                airportCodeArr: data.result.airportCodeArr,
                date: data.result.date,
                time: data.result.time,
                label: `${data.result.airlineCode}${data.result.flightNumber} - ${data.result.airportCodeDep} - ${data.result.airportCodeArr} - ARR ${moment(`${data.result.date._} ${data.result.time._}`).format('LT')}`
            }];
            dispatch({ type: SET_FLIGHTS_NUMBERS, flightsNumbers });
        }
    }).catch( error => {
        console.error(error);
    })
}

export const onSelectFlight = (key) => (dispatch, getStore) => {
    const store = getStore().tripEdition;
    let values = store.flightsNumbers[key]
    let date = moment(`${values.date._} ${values.time._}`);
    dispatch(change('tripEditForm', 'puTime', {hour:date.format('HH'), minutes: date.format('mm')} ));
    // dispatch(change('tripEditForm', 'puTimeNoTimezone', date.format('MM/DD/YYYY HH:mm') ));
    dispatch(change('tripEditForm', 'flightNumber', values ));
}

export const onSelectAirline = (key) => (dispatch, getStore) => {
    let values = airlineCodes.find(item => item._id == key);
    dispatch(change('tripEditForm', 'flightAirline', values));
}

// Actions
export const find = (id) => (dispatch, getState) => {
    dispatch(setLoader(LOADER, true));
    dispatch(clearData());
    service.get(id, { query: { $populate: ['stops'] } }).then((data)=>{
        if(data.canceled){
            dispatch(goBack());
            throw 'This trip cannot be edited because it has already been cancelled.';
        }

        let total = 0;
        if(data.refund)
            total = data.refund.reduce((total, item) => total + item.refundAmount, 0);
        let totalCharges = 0;
        if(data.additionalCharges)
            totalCharges = data.additionalCharges.reduce((total,current)=> total + current.amount, 0);
        data.totalRefund = total;
        data.totalCharges = totalCharges;
        data.oldTotal = data.transactionAmount - data.totalRefund + totalCharges;
        data.puDate = data.puTimeNoTimezone ? moment(data.puTimeNoTimezone).format('L') : moment.parseZone(data.puTime).format('L');
        data.puTime = data.puTimeNoTimezone ? moment(data.puTimeNoTimezone).format() : moment.parseZone(data.puTime).format();
        data.dateCompare = data.puTimeNoTimezone ? moment(data.puTimeNoTimezone).format() : moment.parseZone(data.puTime).format();
        data.newTransactionAmount = data.transactionAmount;
        data.oldEmail = data.guestEmail;
        dispatch(setData(data));
        if(data.flightNumber && data.flightAirline)
            dispatch({ type: SET_FLIGHTS_NUMBERS, flightsNumbers: [data.flightNumber] });
        else
            data.no_flight_information = true;
        // delete data.puAddress;
        // delete data.doAddress;
        const initialize = (data, dispatch) =>
            new Promise((resolve, reject) => {
                    dispatch(initializeForm("tripEditForm", data));
                    resolve();
        });

        if (data.stops) {
            const stops = data.stops
                    .filter(({ type }) => type === "S")
                    .map((stop) => {
                        const dataStop = {
                            ...stop,
                            stop: stop.street,
                        };

                        if (stop.arrival_time){
                            const arrival = moment(stop.arrival_time);
                            dataStop.time = {
                                hour: `${arrival.hour()}`,
                                minutes: `${arrival.minutes()}`,
                            };
                        }

                        return dataStop;

                    });
            data.stops = stops;
        }

        initialize(data, dispatch).then(() => dispatch(changeAddress()));
        if(getState().tripEdition.googleMapsError == true){
            Swal.fire({
                type: "error",
                title: 'Google maps error',
                html: '<div> If this continues please contact </div>' +
                    '<a href="mailto:web-admin@connectbbc.com" target="_blank"> web-admin@connectbbc.com </a>'
            })
        }
    })
    .catch((error)=>{
        errorHandler(error)
    }).finally(()=>{
        dispatch(setLoader(LOADER, false));
    })
};

export const chargesList = (search) => (dispatch, getStore) => {
    const { data } = getStore().tripEdition;
    const query = { query: { $skip: 0 } };
    if(data.hourlyDuration) query.query.$or = [ { type: 'Hourly' }, { type: 'Both' } ]
    else query.query.$or = [ { type: 'Standard' }, { type: 'Both' } ]

    if(search) query.query.title = { $regex: _.escapeRegExp(search) }
    return app.service("/api/additionalCharges").find(query).then(response=>{
        return { options: response.data };
    })
}

export const clearData = () => (dispatch) => {
    dispatch(setData({}));
    dispatch(setVehiclesData([]));
    dispatch({ type: SET_FLIGHTS_NUMBERS, flightsNumbers: [] });
    dispatch({ type: "TRIP_EDITION_FROM", from: initialState.from });
    dispatch({ type: "TRIP_EDITION_TO", to: initialState.to });
    dispatch({ type: SET_IS_AIRPORT, isAirport: false, airportCode: undefined });
}

export const editReservation = (values) => async (dispatch, getStore) => {
    const { from, to, showCheckoutForm } = getStore().tripEdition;
    const successSwalMessage = {
        title: "Success",
        text: `The Trip has been edited`,
        type: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#D50032",
    };
    let dataResult;
    await Swal.fire({
        title: "Are you sure?",
        text: `The trip will be edited, the amount of the trip could change`,
        type: "warning",
        confirmButtonText: "Yes",
        confirmButtonColor: "#D50032",
        showCancelButton: true,
    }).then(async (result) => {
        if (result.value) {
            dispatch(setLoader(LOADER, true));

            const distance = {
                latFrom: from.lat,
                lngFrom: from.lng,
                latTo: to.lat,
                lngTo: to.lng,
            };
            if(values.hourlyDuration) {
                distance.duration = values.hourlyDuration;
                values.isHourly = true;
            }

            values.distance = [distance];

            let newTotalCharges = 0;
            if(values.additionalCharges)
                newTotalCharges = values.additionalCharges.reduce( (total, current) =>
                    total + (current.amount ? parseFloat(current.amount) : 0), 0 );
            values.newTotalCharges = newTotalCharges;

            let formatPuDate = moment(values.puDate).format('YYYY-MM-DD');
            let formatPuTime = values.puTime == 'object'
                ? `${values.puTime.hour}:${values.puTime.minutes}:00`
                : moment(values.puTime).format('HH:mm:ss');
            const newPuTime = moment(`${formatPuDate} ${formatPuTime}`).format('YYYY-MM-DDTHH:mm:ss')

            values.pu_time = [newPuTime]
            values.from = from;
            values.to = to;
            values.postal_code = from.postal_code;
            values.to_postal_code = to.postal_code;


            const stops = values && values.stops ? values.stops : [];
            const formattedStops = stops.map((stop) => {
                const dataStop = { ...stop };
                if (dataStop.time && typeof dataStop.time === "object")
                    dataStop.arrival_time = format.setTime(
                        values.puDate,
                        dataStop.time.hour,
                        dataStop.time.minutes
                    );
                return dataStop;
            });

            values.stops = formattedStops;
            await app.service('api/authorize-charge').patch(values._id, values).then(async data=>{
                await Swal.fire(successSwalMessage).then((result) => {
                    dispatch(setData({}));
                    dataResult = data;
                })
            }).catch(async (error) => {
                if (
                    error &&
                    error.data &&
                    error.data.greaterValue &&
                    !showCheckoutForm
                ) {
                    dispatch(setShowCheckoutForm());
                } else if(error && error.data && error.data.changeToSpecialRate){
                    const userResponse = await Swal.fire({
                        title: "Validation Required",
                        text: error.message || "There is a special-rate period on this date/time. Would you like to keep the original base rate or use the special-rate for the selected date?",
                        type: "warning",
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: "USE SPECIAL RATE",
                        confirmButtonColor: "#D50032",
                        cancelButtonText: "KEEP",
                        cancelButtonColor: ""
                    });
                    if (userResponse && (userResponse.value === true || userResponse.dismiss === "cancel")){
                        let newData = Object.assign({}, values);
                        if (userResponse.dismiss === "cancel") {
                            delete newData.vehicleSpecialRate;
                            newData.baseRateId = newData.relatedBaseRateId;
                        }

                        newData.specialRatesMsgValidated = true;
                        await app.service('api/authorize-charge').patch(newData._id, newData).then(async (data) => {
                            await Swal.fire(successSwalMessage).then((result) => {
                                dispatch(setData({}));
                                dataResult = data;
                            });
                        }).catch(errorHandler);
                    }
                } else errorHandler(error);
            }).finally(()=>{
                dispatch(setLoader(LOADER, false))
            });
        }
    })
    return dataResult;
};

const toggleCheckoutForm = (showCheckoutForm = true) => (dispatch) =>
    dispatch(setShowCheckoutForm(showCheckoutForm));

export const changeVehicle = (values) => async (dispatch, getStore) => {
    const store = getStore().tripEdition;
    dispatch(setLoader(LOADER_NEW_PRICE, true));
    let newTransactionAmount = 0;
    const { from, to, data: { hourlyDuration, promoCode, rewardPoints, specialPointToPoint } } = store;
    const { values: formValues } = getStore().form.tripEditForm;
    let { puDate, puTime, puTimeNoTimezone, stops = [] } = formValues;

    const stopsValid =
        !stops.length ||
        stops.every(
            ({ lat, lng }) =>
                lat !== null &&
                lat !== undefined &&
                lng !== null &&
                lng !== undefined
        );

    const coordsStops = stops.map(({ lat, lng }) => `${lat},${lng}`).join("|");

    if (from && to && stopsValid) {
        const query = {
            latFrom: from.lat,
            lngFrom: from.lng,
            latTo: to.lat,
            lngTo: to.lng,
            postal_code: from.postal_code,
            to_postal_code: to.postal_code,
            schedule: `${moment(puDate).format("MM/DD/YYYY")} ${typeof puTime === 'object' ? `${puTime.hour}:${puTime.minutes}` : moment(puTimeNoTimezone).format('HH:mm')}`,
            promoCode,
            rewardPoints,
            stops: coordsStops,
        };
        if(hourlyDuration) query.duration = hourlyDuration;
        if(specialPointToPoint) query.specialTrip = specialPointToPoint;

        const result = await app.service('api/distance').get(values.baseRateId, { query });
        newTransactionAmount = result ? result.price : values.price;
    } else {
        newTransactionAmount = values.price;
    }
    dispatch(change('tripEditForm', 'vehicleType', values.vehicleType));
    dispatch(change('tripEditForm', 'vehicleClass', values.vehicleClass));
    dispatch(change('tripEditForm', 'vehiclePhoto', values.vehiclePhoto));
    dispatch(change('tripEditForm', 'baseRateId', values.baseRateId));
    dispatch(change('tripEditForm', 'relatedBaseRateId', values.relatedBaseRateId));
    dispatch(change('tripEditForm', 'vehicleLocation', values.vehicleLocation ? values.vehicleLocation : null));
    dispatch(change('tripEditForm', 'vehicleSpecialRate', values.vehicleSpecialRate ? values.vehicleSpecialRate : null));
    dispatch(change('tripEditForm', 'vehicleDescription', values.vehicleDescription));
    dispatch(change('tripEditForm', 'newTransactionAmount', newTransactionAmount));
    dispatch(setLoader(LOADER_NEW_PRICE, false));
}

const findSpecialRate = ({ query, item }) => {
    const getWeekDay = (dayName) => {
        if (dayName === "Sunday") return 0;
        if (dayName === "Monday") return 1;
        if (dayName === "Tuesday") return 2;
        if (dayName === "Wednesday") return 3;
        if (dayName === "Thursday") return 4;
        if (dayName === "Friday") return 5;
        if (dayName === "Saturday") return 6;
        return null;
    };

    const today = moment(
        `${moment(query.schedule).format("MM/DD/YYYY")} ${query.timeHour}:${
            query.timeMinutes
        }`
    );

    const indexSpecialRate = item.specialRates.findIndex(
        ({ calendar: { dates, type, time, recurring, repeatTimes = 1 } }) => {
            //Function to verify time in the calendar
            const verifyTime = (time, today) => {
                let startTime = moment(time.startTime, "HH:mm");
                let endTime = moment(time.endTime, "HH:mm");
                let todayTime = moment(today.format("HH:mm"), "HH:mm");
                if (todayTime.isBetween(startTime, endTime, undefined, "[]"))
                    return true;
                else return false;
            };
            // Function to verify if the date is the same in the calendar
            const verifyDate = (date, today, time) => {
                let todayRemovedHours = moment(today.format("MM/DD/YYYY"));
                if (date.isValid() && todayRemovedHours.isSame(date)) {
                    if (time) return verifyTime(time, today);
                    else return true;
                } else return false;
            };
            // Type to increase dates in "for" for repeat
            let typeAdd = recurring
                ? recurring === "weekly"
                    ? "w"
                    : recurring === "monthly"
                    ? "M"
                    : "Y"
                : "Y";

            let foundDate;
            if (type === "Days") {
                foundDate = dates.find(({ day, month, year, dayName }) => {
                    if (repeatTimes === 0) {
                        // FOR UNDEFINITE IN REPEAT TIMES
                        let initialDate =
                            recurring && recurring === "weekly"
                                ? today.day() === getWeekDay(dayName)
                                    ? moment(today.format("MM/DD/YYYY"))
                                    : undefined
                                : moment(
                                      `${
                                          today.month() + 1
                                      }/${day}/${today.year()}`
                                  );

                        if (!initialDate) return false;

                        if (verifyDate(initialDate, today, time)) {
                            return true;
                        }
                    } else {
                        let initialDate =
                            recurring && recurring === "weekly"
                                ? today.day() === getWeekDay(dayName)
                                    ? moment(`${month}/${day}/${year}`)
                                    : undefined
                                : moment(`${month}/${day}/${year}`);

                        if (!initialDate) return false;

                        for (let count = 0; count < repeatTimes; count++) {
                            if (verifyDate(initialDate, today, time)) {
                                return true;
                            }
                            initialDate.add(1, typeAdd);
                        }
                    }
                });
            } else {
                // BY RANGE
                let between =
                    moment(
                        `${dates[0].month}/${dates[0].day}/${dates[0].year}`
                    ).week() !==
                    moment(
                        `${dates[1].month}/${dates[1].day}/${dates[1].year}`
                    ).week()
                        ? today.day() <= getWeekDay(dates[1].dayName)
                        : today.day() >= getWeekDay(dates[0].dayName) &&
                          today.day() <= getWeekDay(dates[1].dayName);
                if (repeatTimes === 0) {
                    // FOR UNDEFINITE IN REPEAT TIMES
                    let startDiff =
                        getWeekDay(dates[0].dayName) - today.day();
                    let endDiff =
                        getWeekDay(dates[1].dayName) - today.day();

                    let startDateTemp = moment(today.format("MM/DD/YYYY")).add(
                        startDiff,
                        "d"
                    );
                    let endDateTemp = moment(today.format("MM/DD/YYYY")).add(
                        endDiff,
                        "d"
                    );

                    let startDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? startDateTemp
                                : undefined
                            : moment(
                                  `${today.month() + 1}/${
                                      dates[0].day
                                  }/${today.year()}`
                              );
                    let endDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? endDateTemp
                                : undefined
                            : moment(
                                  `${today.month() + 1}/${
                                      dates[1].day
                                  }/${today.year()}`
                              );

                    if (startDate && endDate && startDate.isValid()) {
                        let todayRemovedHours = moment(
                            today.format("MM/DD/YYYY")
                        );
                        if (
                            todayRemovedHours.isBetween(
                                startDate,
                                endDate,
                                undefined,
                                "[]"
                            )
                        ) {
                            foundDate = time ? verifyTime(time, today) : true;
                        }
                    }
                } else {
                    const startDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? moment(
                                      `${dates[0].month}/${dates[0].day}/${dates[0].year}`
                                  )
                                : undefined
                            : moment(
                                  `${dates[0].month}/${dates[0].day}/${dates[0].year}`
                              );
                    const endDate =
                        recurring && recurring === "weekly"
                            ? between
                                ? moment(
                                      `${dates[1].month}/${dates[1].day}/${dates[1].year}`
                                  )
                                : undefined
                            : moment(
                                  `${dates[1].month}/${dates[1].day}/${dates[1].year}`
                              );

                    if (startDate && endDate && startDate.isValid()) {
                        for (let count = 0; count < repeatTimes; count++) {
                            let todayRemovedHours = moment(
                                today.format("MM/DD/YYYY")
                            );
                            if (
                                todayRemovedHours.isBetween(
                                    startDate,
                                    endDate,
                                    undefined,
                                    "[]"
                                )
                            ) {
                                foundDate = time
                                    ? verifyTime(time, today)
                                    : true;
                                if (foundDate) break;
                            }
                            startDate.add(1, typeAdd);
                            endDate.add(1, typeAdd);
                        }
                    }
                }
            }
            if (foundDate) return true;
        }
    );
    if (indexSpecialRate !== -1) {
        return item.specialRates[indexSpecialRate].vehicles;
    }
    return [];
};

const searchForSpecialRate = () => async (dispatch, getStore) => {
    const { from, to, vehicles = [] } = getStore().tripEdition;
    const { values } = getStore().form.tripEditForm;
    const { puDate, puTime, puTimeNoTimezone } = values;

    try {
        dispatch(setLoader(LOADER, true));
        let locationResponse = await app.service("api/location").find({
            query: {
                $or: [
                    {
                        "zipCodes.zip": from.postal_code,
                    },
                    {
                        "excludeZipCodes.zip": from.postal_code,
                    },
                ],
            },
        });

        if (locationResponse.total === 0) {
            locationResponse = await app.service("api/location").find({
                query: {
                    $or: [
                        { "zipCodes.zip": to.postal_code },
                        { "excludeZipCodes.zip": to.postal_code },
                    ],
                },
            });
        }

        const location = locationResponse.data[0];

        if (!location) return false;

        const query = {
            schedule: `${moment(puDate).format("MM/DD/YYYY")} ${typeof puTime === 'object' ? `${puTime.hour}:${puTime.minutes}` : moment(puTimeNoTimezone).format('HH:mm')}`,
            timeHour: typeof puTime === 'object' ? puTime.hour : moment(puTime).format('HH'),
            timeMinutes: typeof puTime === 'object' ? puTime.minutes : moment(puTime).format('mm')
        };

        const specialRates = findSpecialRate({ query, item: location });
        const allSpecialIncluded = specialRates.every((special) => {
            return vehicles.find((vehicle) => {
                return String(vehicle.baseRateId) === String(special._id);
            });
        });
        const hasSpecialRate = vehicles.some(
            (vehicle) => vehicle.vehicleSpecialRate
        );

        const someSpecialRemoved = hasSpecialRate && !specialRates.length;

        return allSpecialIncluded && !someSpecialRemoved;
    } catch (error) {
        errorHandler(error);
    } finally {
        dispatch(setLoader(LOADER, false));
    }
};

export const changeHourly = (values) => (dispatch, getStore) => {
    const { data } = getStore().tripEdition;
    data.hourlyDuration = values.value;
    dispatch(setData(data));
    dispatch(changeAddress());
}

export const setCharge = (Charge, Q, index) => (dispatch, getStore) => {
    let values = getStore().form.tripEditForm.values.additionalCharges;

    if(Charge){
        values[index].unitPrice = Charge.price || 0;
        values[index].aditionalId = Charge._id || null;
    }
    if(Q) values[index].Qcharge = parseInt(Q)

    values[index].amount = values[index].unitPrice * values[index].Qcharge;

    dispatch(change('tripEditForm', 'additionalCharges', values))
}

const setVehiclesData = (data) => async (dispatch,getStore) => {
    const store = getStore().tripEdition;
    let newTransactionAmount = 0;
    let vehicles = [];
    for(let index = 0; index < data.length; index++){
        let item = data[index];
        vehicles.push({
            _id: (item.vehicleSpecialRate || item.vehicleLocation) ? (item.info.vehicleDefault._id || item.info.vehicleDefault) : item.info._id,
            vehicleType: item.info.type,
            vehicleClass: item.info.class,
            vehiclePhoto: item.info.photo,
            vehicleDescription: item.info.description,
            baseRateId: item._id,
            relatedBaseRateId: item.relatedBaseRateId,
            vehicleLocation: item.vehicleLocation,
            vehicleSpecialRate: item.vehicleSpecialRate,
            price: item.info.price,
            label: `${item.info.description} - ${item.info.class}`
        })
    }
    const indexVehicle = vehicles.findIndex( item => store.data.vehicleId == item._id);
    if (indexVehicle !== -1) {
        const item = vehicles[indexVehicle];
        const itemIsSpecialRate = !!item.vehicleSpecialRate;
        const dataVehicleIsSpecialRate = !!store.data.vehicleSpecialRate;
        if (itemIsSpecialRate !== dataVehicleIsSpecialRate) {
            dispatch(change("tripEditForm", "vehicleType", item.vehicleType));
            dispatch(change("tripEditForm", "vehicleClass", item.vehicleClass));
            dispatch(change("tripEditForm", "vehiclePhoto", item.vehiclePhoto));
            dispatch(change("tripEditForm", "baseRateId", item.baseRateId));
            dispatch(change("tripEditForm", "relatedBaseRateId", item.relatedBaseRateId || null));
            dispatch(change("tripEditForm", "vehicleLocation", item.vehicleLocation || null));
            dispatch(change("tripEditForm", "vehicleSpecialRate", item.vehicleSpecialRate || null));
            dispatch(change("tripEditForm", "vehicleDescription", item.vehicleDescription));
        }
        newTransactionAmount = item.price;
    }
    dispatch({type: VEHICLES_TRIP_EDITION, vehicles});
    dispatch(change('tripEditForm', 'newTransactionAmount', newTransactionAmount));
}

export const changeAddress = () => (dispatch, getStore) => {
    const { from, to, data: { hourlyDuration, specialPointToPoint }, } = getStore().tripEdition;
    const { values } = getStore().form.tripEditForm;
    const  { puDate, puTime, rewardPoints, puTimeNoTimezone, stops = [] } = values;

    const stopsValid =
        !stops.length ||
        stops.every(
            ({ lat, lng }) =>
                lat !== null &&
                lat !== undefined &&
                lng !== null &&
                lng !== undefined
        );

    const coordsStops = stops.map(({ lat, lng }) => `${lat},${lng}`).join("|");

    if (from && from.postal_code && to && to.postal_code && puDate && puTime && puTimeNoTimezone && stopsValid){

        dispatch(setLoader(LOADER_NEW_PRICE, true));

        const query = {
            query: {
                state: from.state,
                city: from.city,
                postal_code: from.postal_code,
                to_postal_code: to.postal_code,
                latFrom: from.lat,
                lngFrom: from.lng,
                latTo: to.lat,
                lngTo: to.lng,
                to_city: to.city,
                to_state: to.state,
                schedule: `${moment(puDate).format("MM/DD/YYYY")} ${typeof puTime === 'object' ? `${puTime.hour}:${puTime.minutes}` : moment(puTimeNoTimezone).format('HH:mm')}`,
                timeHour: typeof puTime === 'object' ? puTime.hour : moment(puTime).format('HH'),
                timeMinutes: typeof puTime === 'object' ? puTime.minutes : moment(puTime).format('mm'),
                stops: coordsStops,
            }
        };

        if (hourlyDuration) query.query.duration = hourlyDuration;
        if(specialPointToPoint) query.specialTrip = specialPointToPoint;

        app.service('api/distance').find(query).then(res => {
            if (res) {
                if (!res.data.length)
                    Swal.fire({
                        title: 'This is outside of our standard service area',
                        type: 'warning',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                    });
                else
                    dispatch(setVehiclesData(res.data[0].vehicleBaseRates)).then( () => {
                        dispatch(setLoader(LOADER_NEW_PRICE, false));
                    });
            }
        }).catch((error) => {
            console.log(error);
            dispatch(setLoader(LOADER_NEW_PRICE, false));
        });
    } else {
        if(from.postal_code && to.postal_code) setInvalidAddress('both')
        else if(from.postal_code) setInvalidAddress('to')
        else setInvalidAddress('from')
    }
}

const resendEmail = (id) => (dispatch, getStore) => {

    Swal.fire({
        title: "Resend confirmation email?",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Send",
        cancelButtonText: "No, cancel",
        confirmButtonColor: "#D50032",
        cancelButtonColor: "#545b62",
        reverseButtons: true,
    }).then((response) => {
        if (response.dismiss) return;

        dispatch(setLoader(LOADER, true));

        service.update(id, {
                action: "RESEND_EMAIL_RECEIPT",
                payload: { sendTo: 'passenger' },
            })
            .then(() => {
                Swal.fire({
                    title: "Email sent",
                    type: "success",
                    confirmButtonColor: "#D50032",
                });
            })
            .catch((err) => {
                Swal.fire({
                    title: "Error",
                    text: err,
                    type: "error",
                    confirmButtonColor: "#D50032",
                });
            })
            .finally(() => {
                dispatch(setLoader(LOADER, false));
            });
    });
};

export const setGoogleMapsMsgError = () => (dispatch) => {
    dispatch({
        type: GOOGLE_MAPS_ERROR,
        googleMapsError: true
    });
}

export const actions = {
    find,
    setCharge,
    setDetails,
    chargesList,
    changeVehicle,
    changeHourly,
    clearData,
    resendEmail,
    editReservation,
    setGoogleMapsMsgError,
    onSelectAirline,
    getFlightInfo,
    onSelectFlight,
    searchForSpecialRate,
    changeAddress,
    toggleCheckoutForm,
};

// Reducers
const reducers = {
    [LOADER]: (state, { loading }) => ({ ...state, loading }),
    [LOADER_NEW_PRICE]: (state, { loading }) => ({ ...state, loaderNewPrice: loading }),
    [QUERY]: (state, { query }) => ({ ...state, query }),
    [DATA]: (state, { data }) => ({ ...state, data }),
    ["TRIP_EDITION_FROM"]: (state, { from }) => ({ ...state, from }),
    ["TRIP_EDITION_TO"]: (state, { to }) => ({ ...state, to }),
    [INVALID_ADDRESS]: (state, { typeAddress }) => ({ ...state, typeAddress }),
    [VEHICLES_TRIP_EDITION]: (state, { vehicles }) => ({ ...state, vehicles }),
    [GOOGLE_MAPS_ERROR]: (state, { googleMapsError }) => ({ ...state, googleMapsError }),
    [SET_IS_AIRPORT]: (state, { isAirport, airportCode }) => ({ ...state, isAirport, airportCode }),
    [SET_FLIGHTS_NUMBERS]: (state, { flightsNumbers }) => ({ ...state, flightsNumbers }),
    [CHECKOUT_FORM]: (state, { showCheckoutForm }) => ({
        ...state,
        showCheckoutForm,
    }),
};

export const initialState = {
    from: {
        formatted_address:null,
        lng:null,
        lat:null,
        city:null,
        state:null,
        postal_code:null,
    },
    to: {
        formatted_address:null,
        lng:null,
        lat:null,
        city:null,
        state:null,
        postal_code:null,
    },
    data: {},
    page: 1,
    query: {},
    typeAddress: {},
    loading: false,
    loaderNewPrice: false,
    vehicles: [],
    googleMapsError: false,
    isAirport: false,
    airportCode: undefined,
    flightsNumbers: [],
    showCheckoutForm: false,
};

export default handleActions(reducers, initialState);
