import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { countryOptions } from '../../../utility/constants';
import { clearPassenger } from '../../../../redux/modules/itinerary';
import PassengerForm from './PassengerForm';


class PassengerContainer extends React.Component {
    static propTypes = {
        clearPassenger: PropTypes.func,
    };

    render() {

        const { from, from2, phone, country, fromAirport, clearPassenger, handlePassengerFormSubmit, } = this.props;

        return (
            <div className="d-flex justify-content-center bg-light radius-1">
                <PassengerForm
                    from={from}
                    from2={from2}
                    phone={phone}
                    country={country}
                    clear={clearPassenger}
                    fromAirport={fromAirport}
                    onSubmit={handlePassengerFormSubmit}
                    initialValues={{
                        conutry: { label: 'United States', value: 'US' }
                    }}
                />
            </div>
        );
    }
}

const selector = formValueSelector('PassengerForm');
const mstp = state => ({
    ...state.itinerary,
    loading: state.clients.loading,
    loggedIn: state.clients.loggedIn,
    phone: selector(state, 'phone'),
    country: selector(state, 'country'),
});

const mdtp = {
    clearPassenger,
};

export default connect(mstp, mdtp)(PassengerContainer);
