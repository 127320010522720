import app from "../../libs/apiClient";
import { createActions, handleActions } from "redux-actions";
import { goBack } from "react-router-redux";
import { initialize as initializeForm } from "redux-form";
import Swal from 'sweetalert2';
import { actions as breadcrumbActions } from "./breadcrumb";

const service = app.service("/api/reviews");

const LOADING_REVIEWS = "LOADING_REVIEWS";
const SET_DATA_REVIEWS = "SET_DATA_REVIEWS";
const SET_PAGE_REVIEWS = "SET_PAGE_REVIEWS";
const SET_UPDATE_DATA_REVIEWS = "SET_UPDATE_DATA_REVIEWS";
const DATA_PASSENGER = 'DATA_PASSENGER_REVIEWS';
const DATA_BOOKING = 'DATA_PASSENGER_BOOKING';
const DATA_SERVICE = 'DATA_PASSENGER_SERVICE';
const PAGE_PASSENGER_REVIEWS = "PAGE_PASSENGER_REVIEWS";
const PAGE_BOOKING_REVIEWS = "PAGE_BOOKING_REVIEWS";
const PAGE_SERVICE_REVIEWS = "PAGE_SERVICE_REVIEWS";
const ITEM_PASSENGER_REVIEWS = "ITEM_PASSENGER_REVIEWS";
const ITEM_BOOKING_REVIEWS = "ITEM_BOOKING_REVIEWS";
const ITEM_SERVICE_REVIEWS = "ITEM_SERVICE_REVIEWS";

const types = {
    passenger: 0,
    booking: 1,
    service: 2
}

const views = [
    {
        type: types.passenger,
        page: 'pagePassenger',
        service: app.service('/api/reviews'),
        typePage: PAGE_PASSENGER_REVIEWS,
        data: 'dataPassenger',
        typeData: DATA_PASSENGER,
        typeItem: ITEM_PASSENGER_REVIEWS,
    },
    {
        type: types.booking,
        page: 'pageBooking',
        service: app.service('/api/bookingReviews'),
        typePage: PAGE_BOOKING_REVIEWS,
        data: 'dataBooking',
        typeData: DATA_BOOKING,
        typeItem: ITEM_BOOKING_REVIEWS,
    },
    {
        type: types.service,
        page: 'pageService',
        service: app.service('/api/serviceReviews'),
        typePage: PAGE_SERVICE_REVIEWS,
        data: 'dataService',
        typeData: DATA_SERVICE,
        typeItem: ITEM_SERVICE_REVIEWS,
    }
]

const handleError = (error) => {
    let msg;
    if (typeof(error) == 'object')
        msg = error.message;
    else if(typeof(error) == 'string')
        msg = error;
    if(error.length)
        msg = 'There was an error';

    Swal.fire({
        title: "Error",
        text: `${msg}`,
        type: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#D50032",
    })
}
// Actions
const create = () => (dispatch, getState) => {
    const formData = getState().form.user.values;

    dispatch(loading());
    service
        .create(formData)
        .then((response) => {
            dispatch(goBack());
        })
        .catch((e) => {
            console.log("error", e);
            handleError(e);
        })
        .finally(() => {
            dispatch(loading(false));
        });
};

const update = () => async (dispatch, getState) => {
    const formData = getState().form.user.values;

    dispatch(loading());

    try {
        const user = await service.patch(formData._id, formData);
        dispatch(goBack());
    } catch (e) {
        console.log("error", e);
        handleError(e);
    } finally {
        dispatch(loading(false));
    }
};

const destroyReview = (type, id) => async (dispatch, getState) => {
    dispatch(loading());
    try {
        const typeItem = findItem(type);
        await typeItem.service.remove(id);
        dispatch(find(1, type));
    } catch (e) {
        console.log("error", e);
        handleError(e);
    } finally {
        dispatch(loading(false));
    }
};

const findItem = type => views.find( item => item.type === type || `${item.type}` === type );

const find = (page = 1, type) => async (dispatch, getStore) => {
    dispatch(loading());

    try {
        console.log(page);
        const typeItem = findItem(type);
        let filterByQuestionsResponses = {};

        // If bookings
        if (type === 1) {
            filterByQuestionsResponses = {
                $or: [
                    { bookingProcess: { $exists: true } },
                    { enoughtTime: { $exists: true } },
                    { "valuableItems.0": { $exists: true } },
                    { valuableComments: { $exists: true } },
                    { anyQuestions: { $exists: true } },
                    { bookingAgain: { $exists: true } },
                    { "hearAbout.0": { $exists: true } },
                    { forContact: { $exists: true } },
                    { feedback: { $exists: true } },
                ],
            };
            // If Service
        } else if (type === 2) {
            filterByQuestionsResponses = {
                $or: [
                    { experience: { $exists: true } },
                    { feedBackExperience: { $exists: true } },
                    { probabilityToRecomend: { $exists: true } },
                    { reasonScore: { $exists: true } },
                    { additionalFeedback: { $exists: true } },
                    { forContact: { $exists: true } },
                    { testimonial: { $exists: true } },
                ],
            };
        }

        const query = {
            query: {
                $skip: (page - 1) * 10,
                close: true,
                $or: [...filterByQuestionsResponses.$or],
            },
        };
        const { values } = getStore().form.reviewsGridFilter;
        if (values) {
            // const { search, filter, close } = values;
            const { search, testimonial } = values;
            if (search) {
                query.query.$or = [];
                query.query.$or.push(
                    {
                        firstName: { $regex: search, $options: "i" },
                        ...filterByQuestionsResponses,
                    },
                    {
                        lastName: { $regex: search, $options: "i" },
                        ...filterByQuestionsResponses,
                    },
                    {
                        email: { $regex: search, $options: "i" },
                        ...filterByQuestionsResponses,
                    }
                );
                if (!isNaN(parseInt(search))) {
                    query.query.$or.push({
                        liveryNo: parseInt(search),
                        ...filterByQuestionsResponses,
                    });
                    query.query.$or.push({
                        confirmationNo: parseInt(search),
                        ...filterByQuestionsResponses,
                    });
                }
            }

            if (testimonial) query.query.testimonial = true;
            // if(filter){
            //     if(filter === 'liveryNo')
            //         query.query.$sort = { liveryNo: 1 }
            //     else if(filter === 'date')
            //         query.query.$sort = { dateTrip: 1 }
            //     else if(filter === 'passenger')
            //         query.query.$sort = { passenger: 1 }
            // }

            // if(close){
            //     query.query.close = false;
            // }
        }
        const response = await typeItem.service.find(query);
        dispatch(setData(typeItem.typeData, response));
        dispatch(setPage(typeItem.typePage, page));
    } catch (e) {
        console.log("error", e);
        handleError(e);
    } finally {
        dispatch(loading(false));
    }
};

const load2Update = (type, id) => async (dispatch, getState) => {
    dispatch(loading());

    try {
        const typeItem = await findItem(type);
        const us = await typeItem.service.get(id, { $populate: ['tripId'] });

        const { updateCustomLabel } = breadcrumbActions;

        if (us.confirmationNo)
            dispatch(updateCustomLabel(id, us.confirmationNo));

        dispatch(setUpdateData(typeItem.typeItem, us));

        if (type === types.service) {
            const { showTestimonial, testimonialText } = us;
            const data = {};
            if (showTestimonial !== undefined)
                data.showTestimonial = showTestimonial;

            if (testimonialText !== undefined)
                data.testimonialText = testimonialText;

            dispatch(initializeForm("detailServiceReviewForm", data));
        }
    } catch (e) {
        console.log("error", e);
        handleError(e);
    } finally {
        dispatch(loading(false));
    }
};

const patch = (type, id, values) => async (dispatch) => {
    dispatch(loading());

    try {
        if (Object.keys(values).length > 0) {
            const typeItem = findItem(type);
            await typeItem.service.patch(id, values);
        }
        dispatch(goBack());
    } catch (e) {
        console.log("error", e);
        handleError(e);
    } finally {
        dispatch(loading(false));
    }
};

const clearUpdateData = () => ({
    type: SET_UPDATE_DATA_REVIEWS,
    updateData: {}
})

const loading = (loading = true) => ({
    type: LOADING_REVIEWS,
    loading
})

const setData = (type, data) => ({ type, data });
const setPage = (type, page) => ({ type, page });
const setUpdateData = (type, updateData) => ({ type, updateData });

export const actions = {
    create,
    update,
    destroyReview,
    find,
    load2Update,
    clearUpdateData,
    patch,
};

// Reducers
const reducers = {
    [LOADING_REVIEWS]: (state, { loading }) => ({ ...state, loading }),
    [SET_DATA_REVIEWS]: (state, { data }) => ({ ...state, data }),
    [SET_PAGE_REVIEWS]: (state, { page }) => ({ ...state, page }),
    [SET_UPDATE_DATA_REVIEWS]: (state, { updateData }) => ({ ...state, updateData, }),
    [DATA_PASSENGER]: (state, { data }) => ({ ...state, dataPassenger: data }),
    [DATA_BOOKING]: (state, { data }) => ({ ...state, dataBooking: data }),
    [DATA_SERVICE]: (state, { data }) => ({ ...state, dataService: data }),
    [PAGE_PASSENGER_REVIEWS]: (state, { page }) => ({ ...state, pagepassenger: page }),
    [PAGE_BOOKING_REVIEWS]: (state, { page }) => ({ ...state, pageBooking: page }),
    [PAGE_SERVICE_REVIEWS]: (state, { page }) => ({ ...state, pageService: page }),
    [ITEM_PASSENGER_REVIEWS]: (state, { updateData }) => ({ ...state, itemPassenger: updateData }),
    [ITEM_BOOKING_REVIEWS]: (state, { updateData }) => ({ ...state, itemBooking: updateData }),
    [ITEM_SERVICE_REVIEWS]: (state, { updateData }) => ({ ...state, itemService: updateData }),
};

export const initialState = {
    loading: false,
    updateData: {},
    dataPassenger: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataBooking: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    dataService: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    itemPassenger: {},
    itemBooking: {},
    itemService: {},
    pagepassenger: 1,
    pageBooking: 1,
    pageService: 1,
};

export default handleActions(reducers, initialState);
