import React from 'react'

const Spinner = ({ styles }) => {
  return (
    <div className="loader" style={styles}>
    </div>
  )
}

export default Spinner
