import React, { useState } from 'react';
import { connect } from 'react-redux';
import Loader from "react-loader-spinner";
import Modal from 'react-responsive-modal';
import Pagination from '../../../../Utils/pagination';
import LocationForm from './locationForm';
import IconEdit from '../../../../../icons/edit';
import IconCancel from '../../../../../icons/times';
import { IconTrashAlt } from '../../../../../icons/trash';
import { actions } from '../../../../../../redux/modules/crewAdmin/crewLocations';
import LoadMask from '../../../../Utils/LoadMask';
import swal from 'sweetalert2';
import IconCheck from '../../../../../icons/check';


const LocationItem = (props) => {
    // const [edit, setEdit] = useState(false);

    // const editLocation = (id) => {
    //     props.editLocation(id);
    //     setEdit(true);
    // }

    const { item, edit, remove, submitEdit, editLocation, permission } = props;

    return (
        <div className="border rounded border-dark small-shadow p-3 d-flex flex-wrap mt-3">
            {edit && edit == item._id ? (
                <React.Fragment>
                    <div className="col-10 px-0">
                        <LocationForm
                            onSubmit={submitEdit}
                            showSaveBtn={false}
                        />
                    </div>
                    <div className="col-2 px-0 d-flex justify-content-end align-items-start">
                        <button type="submit" form="scheduleAirportLocationForm" className="mx-1 border-0 bg-white p-0">
                            <IconCheck className="smallIcon p-1 text-white bg-success rounded-circle" />
                        </button>
                        <div className="mx-1 clickable" onClick={()=>editLocation()}>
                            <IconCancel className="smallIcon" />
                        </div>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className="col-10 px-0 d-flex flex-wrap">
                        <div className="col-12 col-sm-6 px-0">
                            <strong>Name:</strong>
                        </div>
                        <div className="col-12 col-sm-6 px-0 ps-sm-1 text-truncate">
                            <span>{item.location ? item.location.name : item.airport ? item.airport.name : ''}</span>
                        </div>
                        <div className="col-12 col-sm-6 px-0">
                            <strong>Schedule Desc:</strong>
                        </div>
                        <div className="col-12 col-sm-6 px-0 ps-sm-1">
                            <span>{item.description}</span>
                        </div>
                        <div className="col-12 col-sm-6 px-0">
                            <strong>Type:</strong>
                        </div>
                        <div className="col-12 col-sm-6 px-0 ps-sm-1">
                            <span>{item.location ? 'Hotel' : 'Airport'}</span>
                        </div>
                        <div className="col-12 col-sm-6 px-0">
                            <strong>Code:</strong>
                        </div>
                        <div className="col-12 col-sm-6 px-0 ps-sm-1">
                            <span>{item.location && item.location.zip ? item.location.zip.zone.code : item.airport ? item.airport.code : ''}</span>
                        </div>
                    </div>
                    <div className="col-2 px-0 d-flex justify-content-end align-items-start">
                        {permission &&
                        permission.find(({ removeAirlineCrew }) => !!removeAirlineCrew) ? (
                            <div className="mx-1 clickable" onClick={()=>remove(item._id)}>
                                <IconTrashAlt className="smallIcon" />
                            </div>
                        ) : (
                            ""
                        )}
                        {permission &&
                        permission.find(({ updateAirlineCrew }) => !!updateAirlineCrew) ? (
                            <div className="mx-1 clickable" onClick={()=>editLocation(item._id)}>
                                <IconEdit className="smallIcon" />
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

class LocationsContainer extends React.Component {

    componentDidMount(){
        this.props.find(this.props.airlineId, this.props.page);
    }

    submitModal = (values, edit = false) => {

        if(values.location.type == 'airport'){
            values.airport = Object.assign({},values.location);
            values.location = null;
        }

        if(edit){
            this.props.update(values).then(valid=>{
                if(valid) this.edit();
            });
        } else {
            values.airline = this.props.airlineId;
            this.props.create(values).then(valid=>{
                if(valid) this.closeModal();
            });
        }
    }

    openModal = () => {
        if(this.props.edit)
            this.props.setEdit();
        this.props.setModalForm(true);
    }

    closeModal = () => {
        this.props.setModalForm(false);
    }

    edit = (id) => {
        this.props.setEdit(id);
    }

    remove = (id) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this location from the customer profile? </h3>",
            showCancelButton: true,
            confirmButtonColor: '#D50032',
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE"
        }).then(({ value })=>{
            if(value) {
                this.props.destroy(id);
            }
        })
    }

    render(){

        const { loading, data, search, setSearch, page, find, loadingForm, modalForm, userInfo: { permission } } = this.props;

        return (
            <div className="w-100 p-2">
                <Modal open={modalForm} onClose={this.closeModal}>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 d-flex flex-wrap justify-content-center pt-5 pb-4">
                            <div className="col-12 ">
                                <div className="mb-3">
                                    <h3 className="text-left"> New Location </h3>
                                </div>
                                <LoadMask loading={loadingForm} blur>
                                    <LocationForm
                                        onSubmit={(values) => this.submitModal(values)}
                                        cancel={this.closeModal}
                                    />
                                </LoadMask>
                            </div>
                        </div>
                    </div>
                </Modal>

                <div className="d-flex py-2 justify-content-between">
                    <div className="flex-1 d-flex flex-wrap">
                        <input
                            type="text"
                            className="form-control col-12 col-md-10 col-xl-8"
                            placeholder="Search..."
                            value={search}
                            onChange={(event)=>{
                                setSearch(this.props.airlineId, event.target.value);
                            }}
                        />
                    </div>
                    {permission &&
                    permission.find(({ createAirlineCrew }) => !!createAirlineCrew) ? (
                        <div className="">
                            <button type="button" className="btn btn-primary text-nowrap ml-2" onClick={this.openModal}>
                                NEW LOCATION
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                {loading
                    ? <div className="d-flex justify-content-center align-items-center">
                            <Loader
                                type="Rings"
                                color="#FB885F"
                                height="100"
                                width="100"
                            />
                            <span className="">Loading...</span>
                        </div>
                    : data && data.total > 0
                        ? <div className="">
                            {data.data.map((item, index)=>(
                                <LocationItem
                                    key={index}
                                    item={item}
                                    editLocation={this.edit}
                                    submitEdit={(values) => this.submitModal(values, true)}
                                    remove={this.remove}
                                    edit={this.props.edit}
                                    permission={permission}
                                />
                            ))}
                            <Pagination
                                total={data.total}
                                itemsPerPage={5}
                                find={(page)=>find(this.props.airlineId, page)}
                                page={page}
                            />
                        </div>
                        : <div className="d-flex justify-content-center my-4">
                            <h4> No Locations found </h4>
                        </div>
                }
            </div>
        )
    }
}

const mstp = state => ({
    ...state.crewAdmin.crewLocations,
    userInfo: state.login.userInfo,
})

const mdtp = {
    ...actions
}

export default connect(mstp, mdtp)(LocationsContainer);
