import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/crewAdmin/zones';
import IconAngleLeft from '../../../../icons/angleLeftIcon';
import LoadMask from '../../../Utils/LoadMask';
import Pagination from '../../../Utils/pagination';
import Modal from 'react-responsive-modal';
import IconEdit from '../../../../icons/edit';
import { IconTrashAlt } from '../../../../icons/trash';
import swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { getPathWithParams, settingsAirlinesZonesId, settingsAirlinesZonesNew } from '../../../../../routesConfiguration/paths';

class Container extends React.Component {
    componentDidMount() {
        const { find, page } = this.props;
        find(page);
    }

    removeZone = (id) => {
        swal.fire({
            type: "warning",
            html: "<h3> Are you sure you want to delete this zone? </h3>",
            showCancelButton: true,
            confirmButtonColor: "#D50032",
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE",
        }).then(({ value }) => {
            if (value) {
                const { destroy } = this.props;
                destroy(id);
            }
        });
    };

    editZone = (id) => {
        const { history } = this.props;
        const path = getPathWithParams(settingsAirlinesZonesId, "id", id);
        history.push(path);
    };

    render() {
        const {
            search,
            history,
            data,
            find,
            loading,
            page,
            userInfo: { superUser, permission },
        } = this.props;

        return (
            <div className="d-flex flex-wrap justify-content-center">
                <div className="col-12 d-flex justify-content-start">
                    <div
                        className="d-flex text-primary d-flex align-items-center clickable"
                        onClick={() => history.goBack()}
                    >
                        <IconAngleLeft className="smallIcon" />
                        <span> Back </span>
                    </div>
                </div>
                <div className="col-12 col-md-10 col-xl-8">
                    <div className="w-100 d-flex mb-1">
                        <h3 className="flex-1 text-left"> Zones </h3>
                        {permission && permission.find(({ createAirlineCrew }) => createAirlineCrew == true) ? (
                            <Link
                                to={settingsAirlinesZonesNew}
                                className="btn btn-primary"
                            >
                                ADD
                            </Link>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100 d-flex flex-wrap py-3">
                        {permission && permission.find(({ readAirlineCrew }) => readAirlineCrew == true) ? (
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by name, code or description"
                                value={search}
                                onChange={(event) => {
                                    event.preventDefault();
                                    this.props.setSearch(event.target.value);
                                }}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="w-100">
                        {permission && permission.find(({ readAirlineCrew }) => readAirlineCrew == true) ? (
                            <LoadMask loading={loading} blur>
                                <div className="react-bs-table react-bs-table-bordered">
                                    <table className="table table-bordered table-hover">
                                        <tbody>
                                            {data.total > 0 &&
                                                data.data.map((item, index) => (
                                                    <tr className="px-3 py-2" key={index}>
                                                        <td className="w-100 d-flex">
                                                            <span className="col-5 px-0 text-truncate"> {item.name} </span>
                                                            <span className="col-2 px-0 text-truncate"> ({item.code}) </span>
                                                            <span className="col-4 px-0 text-truncate"> {item.city}&nbsp;{item.state} </span>
                                                            <div className="col-1 px-0 d-flex justify-content-center">
                                                                {permission && permission.find(({ updateAirlineCrew }) => updateAirlineCrew == true) ? (
                                                                    <span onClick={() => this.editZone(item._id)}>
                                                                        <IconEdit className="smallIcon px-1 clickable" />
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                {permission && permission.find(({ removeAirlineCrew }) => removeAirlineCrew == true) ? (
                                                                    <span onClick={() => this.removeZone(item._id)}>
                                                                        <IconTrashAlt className="smallIcon px-1 clickable" />
                                                                    </span>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                                <Pagination
                                    total={data.total}
                                    page={page}
                                    find={find}
                                    itemsPerPage={5}
                                />
                            </LoadMask>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.zones,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
};

export default connect(mstp, mdtp)(Container);
