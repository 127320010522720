import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, initialize as initializeForm } from 'redux-form';

import Passenger from './Passenger';
import { countryOptions } from '../../../../../utility/constants';
import { loadPassenger, clearPassenger } from '../../../../../../redux/modules/itinerary';


class PassengerContainer extends React.Component {
    static propTypes = {
        loadPassenger: PropTypes.func,
        clearPassenger: PropTypes.func,
    };

    componentDidMount() {
        this.props.loadPassenger();
    }

    componentDidUpdate(prevProps) {
        if (this.props.loggedIn !== prevProps.loggedIn)
            this.props.loadPassenger();
    }

    setInitialValues(passengerInfo) {
        return () => {
            const parsedData = {
                _id: passengerInfo._id,
                first_name: passengerInfo.firstName,
                last_name: passengerInfo.lastName,
                email: passengerInfo.email,
                phone: passengerInfo.mobileNo,
                country: countryOptions.find(
                    co => co.value === passengerInfo.country
                ),
            };

            this.setState({ showList: false }, () => {
                this.props.initializeForm('PassengerForm', parsedData);
            });
        };
    }

    render() {
        return (
            <Passenger
                {...this.props}
                setInitialValues={this.setInitialValues}
            />
        );
    }
}

const selector = formValueSelector('PassengerForm');
const mstp = state => ({
    ...state.itinerary,
    loading: state.clients.loading,
    loggedIn: state.clients.loggedIn,
    phone: selector(state, 'phone'),
    country: selector(state, 'country'),
});

const mdtp = {
    loadPassenger,
    clearPassenger,
    initializeForm,
};

export default connect(mstp, mdtp)(PassengerContainer);
