import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import VehicleList from './VehiclesList';
import { setVehicle, loadVehicles } from '../../../../../../redux/modules/vehicle';

class VehicleListContainer extends React.Component {
    static propTypes = {
        handleVehicleSelect: PropTypes.func.isRequired,
    };

    render() {
        const { vehicles, handleVehicleSelect, from, formType, ...restProps } = this.props;

        const ascendingSort = (a, b) => a.info.price - b.info.price;
        if (vehicles  && vehicles.length > 0) vehicles.forEach(vehicle => vehicle.sort(ascendingSort));

        let fromAirport;
        if (from.types) fromAirport = from.types.some(type => type === 'airport');

        return (
            <VehicleList
                {...restProps}
                vehicles={vehicles}
                handleSelect={handleVehicleSelect}
                fromAirport={fromAirport}
                formType={formType}
            />
        );
    }
}

const ms2p = state => ({
    ...state.vehicle,
    from: state.itinerary.from,
    formType: state.itinerary.formType,
});

const md2p = { setVehicle, loadVehicles };

export default connect(ms2p, md2p)(VehicleListContainer);
