import numeral from 'numeral';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray } from 'redux-form';
import IconDash from '../../../../../../../icons/dash';
import IconPlus from '../../../../../../../icons/plus';
import { IconTrashAlt } from '../../../../../../../icons/trash';
import { renderCurrency } from '../../../../../../Utils/renderField';
import { DatePickerAlt, renderDatePicker } from '../../../../../../Utils/renderField/renderField';
import FormTimePrices from './timePricesForm';


const TimePriceItem = (props) => {

    const [showRemove, setShowRemove] = useState(false);

    const removeItem = () => {
        props.remove(props.index);
        setShowRemove(false);
    }

    const { item, readOnly } = props;

    return (
        <div className="col-12 col-lg-6">
            <div className="w-100 my-1 border rounded p-2 pb-4">
                {showRemove ? (
                    <React.Fragment>
                        <h5 className="text-center"> DELETE RECORD? </h5>
                        <div className="d-flex justify-content-center">
                            <button type="button" className="btn btn-danger mx-1 mt-1 py-1" onClick={()=>removeItem()}>
                                <small>YES</small>
                            </button>
                            <button type="button" className="btn btn-success mx-1 mt-1 py-1" onClick={()=>setShowRemove(false)}>
                                <small>NO</small>
                            </button>
                        </div>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="w-100 d-flex justify-content-end">
                            {!readOnly ? (
                                <div className="mx-1 clickable" onClick={()=>setShowRemove(true)}>
                                    <IconTrashAlt className="smallIcon"/>
                                </div>
                            ) : ''}
                        </div>
                        <div className={`text-center text-truncate ${readOnly ? 'pt-3' : ''}`}> <b> Start Time:</b>&nbsp;{item && item.start_time && `${item.start_time.hour}:${item.start_time.minutes}`} </div>
                        <div className={`text-center text-truncate`}> <b> End Time:</b>&nbsp;{item && item.end_time && `${item.end_time.hour}:${item.end_time.minutes}`} </div>
                        <div className="text-center text-truncate"> <b> Price:</b>&nbsp;{item && `$${numeral(item.price).format('0,0.00')}`} </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

export const renderTimePrice = (props) => {

    const { fields, meta: { error, submitFailed }, newTimePrice, setNewTimePrice, readOnly, formValues } = props;
    

    return (
        <div className="d-flex flex-wrap">
            {newTimePrice ? (
                <div className="w-100">
                    <FormTimePrices
                        onSubmit={values => {
                            fields.push(values);
                            setNewTimePrice(false);
                        }}
                        cancel={()=> setNewTimePrice(false)}
                        formValues={formValues}
                    />
                </div>
            ) : (fields.length > 0 ? (
                    <div className="w-100">
                        <div className="row">
                            {fields.map((itemField, index) => {
                                const item = fields.get(index);
                                return (
                                    <TimePriceItem
                                        key={index}
                                        index={index}
                                        item={item}
                                        remove={fields.remove}
                                        readOnly={readOnly}
                                    />
                                )
                            })}
                        </div>
                        {!readOnly ? (
                            <button type="button" className="btn btn-light border my-2" onClick={() => setNewTimePrice(true)}>
                                <IconPlus className="smallIcon text-secondary" />
                            </button>
                        ) : ''}
                    </div>
                ) : !readOnly ? (
                    <button type="button" className="btn btn-light border rounded shadow bg-white p-2"
                        style={{ width: '100px', height: '50px' }}
                        onClick={() => setNewTimePrice(true)}
                    >
                        <IconPlus className="w-100 h-100 text-secondary"/>
                    </button>
                ) : ''
            )}
            {submitFailed && error &&
                <div className="invalid-feedback-array text-danger align-self-center">
                    {error}
                </div>
            }
        </div>
    )
}

const FormAirlineExpirationRates = (props) => {

    const { handleSubmit, cancel, formValues } = props;
    const [newTimePrice, setNewTimePrice] = useState(false);

    const submitForm = (event) => {
        event.stopPropagation();
        event.preventDefault();
        handleSubmit();
    };

    return (
        <form onSubmit={submitForm} className="row">
            <div className="col-12 px-0 px-md-3 col-lg-6 form-group">
                <strong> Effective Date </strong>
                <Field
                    name="effective_date"
                    component={DatePickerAlt}
                    numberOfMonths={1}
                    className="flex-1 w-100 p-0"
                />
            </div>
            <div className="col-12 px-0 px-md-3 col-lg-6 form-group">
                <strong> New Price </strong>
                <Field
                    name="new_price"
                    component={renderCurrency}
                    className="form-control"
                />
            </div>

            <div className="col-12 px-0 px-md-3 form-group">
                <h5 className="bold">Time based prices</h5>
                <FieldArray
                    name="timePrice"
                    component={renderTimePrice}
                    newTimePrice={newTimePrice}
                    setNewTimePrice={setNewTimePrice}
                    formValues={formValues}
                />
            </div>

            {!newTimePrice ? (
                <div className="col-12 px-0 px-md-3 d-flex justify-content-between">
                    <button type="button" onClick={cancel}
                        className="border bg-white small-shadow"
                        style={{ width: "3rem", borderRadius: "8px", border: "1px solid #929292", color: "#929292" }}
                    >
                        <IconDash className="w-100 h-100"/>
                    </button>
                    <button type="button" className="btn btn-secondary" onClick={submitForm}>
                        SAVE
                    </button>
                </div>
            ) : (
                ""
            )}
        </form>
    );
};

const Form = reduxForm({
    form: "airlineExpirationRatesForm",
    validate: (data) => {
        const errors = {};
        if (!data.effective_date) errors.effective_date = "Required Field";
        if (!data.new_price) errors.new_price = "Required Field";
        return errors;
    },
})(FormAirlineExpirationRates);

const mstp = (state) => ({
    formValues:
        state.form &&
        state.form.airlineExpirationRatesForm &&
        state.form.airlineExpirationRatesForm.values,
});

export default connect(mstp)(Form);
