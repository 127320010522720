import React from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import { BreakLine } from "../../Utils/tableOptions";
import Table from "Utils/Grid";
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import IconPlus from '../../../icons/plus';
import Search from './search';
import { settingsUserAccountsPermissions, settingsUserAccountsPermissionsNew } from "../../../../routesConfiguration/paths";
const { MEDIA_URL } = process.env;

export default class Grid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.find();
    }

    render() {
        const { data, loading, destroy, find, page, userInfo: { superUser } } = this.props;

        return (!superUser
          ? <div className="d-flex w-100 justify-content-center align-items-center">
                <label> You are not a superuser </label>
            </div>
          : <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container">
                        <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <h1 className="ml-0 ml-sm-auto">
                                <strong>Permissions</strong>
                            </h1>
                            <Link
                                className="btn btn-primary ml-auto"
                                to={settingsUserAccountsPermissionsNew}
                                style={{ padding: "0.375rem" }}
                            >
                                <IconPlus className="smallIcon p-1"/>
                                Add Permission
                            </Link>
                        </div>
                        <br />
                        {/* <div className="row">
                            <div className="col-12 col-md-7 col-lg-6 px-0 px-md-3">
                                <Search onSubmit={setSearch}/>
                                <br style={{ border: 0,  }}/>
                            </div>
                        </div> */}
                        <Table
                            onPageChange={find}
                            data={data}
                            page={page}
                            loading={loading}
                        >
                            <TableHeaderColumn
                                tdStyle={BreakLine}
                                thStyle={BreakLine}
                                dataField="_id"
                                isKey
                                className="hide-column-header"
                                dataFormat={(cell, row) =>
                                    <div className="d-flex flex-column flex-sm-row justify-content-between">
                                        <div className="d-flex flex-1 flex-column flex-md-row-reverse">
                                            <div className="d-flex justify-content-end justify-content-sm-end">
                                                {activeFormatter({
                                                    editar: settingsUserAccountsPermissions,
                                                    eliminar: destroy,
                                                })(cell, row)}
                                            </div>
                                            <div className="d-flex flex-1 flex-column flex-lg-row pb-3 pb-sm-0">
                                                <div className="d-flex flex-1 flex-column px-4 justify-content-end justify-content-lg-center">
                                                    <div className="d-flex justify-content-start">
                                                        <strong> Name:&nbsp;</strong>
                                                        {row.name && row.name}
                                                    </div>
                                                    <div className="d-flex justify-content-lg-start">
                                                        <strong> Description:&nbsp;</strong>
                                                        {row.description && row.description}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            />
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}
