import React from "react";
import PropTypes from "prop-types";
import { TableHeaderColumn } from "react-bootstrap-table";
import { activeFormatter } from "Utils/Acciones/Acciones";
import { Link } from "react-router-dom";
import { BreakLine } from "../../Utils/tableOptions";
import Table from "Utils/Grid";
import { RenderCurrency } from '../../Utils/renderField/renderReadField';
import IconPlus from '../../../icons/plus';
import IconEdit from '../../../icons/edit';
import Search from './search';
import { Modal, ModalBody } from "reactstrap";
import VehicleLocationForm from './vehicleProfileForm';
import swal from "sweetalert2";
import IconTimes from "../../../icons/times";
import VehicleLocationModal from "./vehicleLocationModal";
import IconCopy from "../../../icons/copy";
import { settingsRatesVehicles, settingsRatesVehiclesNew } from "../../../../routesConfiguration/paths";
const { MEDIA_URL, BACKEND_URL } = process.env;

export default class VehiclesGrid extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        destroy: PropTypes.func.isRequired,
        find: PropTypes.func.isRequired,
    };

    state = {
        loadingModalVehicleProfile: false,
        modalProfileVehicle: false,
        vehicleId: undefined,
    }

    componentDidMount() {
        this.props.find();
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.userInfo && this.props.userInfo){
            const { permission } = this.props.userInfo;
            const { permission: oldPermission } = prevProps.userInfo;
            if(oldPermission && permission && JSON.stringify(oldPermission) !== JSON.stringify(permission)){
                if(permission.find(({ readVehicles }) => !!readVehicles))
                    this.props.find();
            }
        }
    }

    profileLocationBtn = (id, row) => (
        <button className="btn btn-primary p-0" onClick={()=>{ this.getVehicleLocation(id) }} title="Apply profile">
            <IconCopy className="smallIcon p-1" />
        </button>
    )

    getVehicleLocation = (id) => {
        this.setState({ loadingModalVehicleProfile: true });
        this.props.getVehicleLocation(id).then(result => {
            this.setState({ modalProfileVehicle: true, vehicleId: id });
        }).catch(error=>{
            const msg = error.message || error || 'There was an error';
            swal.fire({
                type: "error",
                title: "ERROR!",
                text: `${msg}`
            })
        }).finally(() => {
            this.setState({  loadingModalVehicleProfile: false });
        });

    }

    render() {
        const { data, loading, destroy, find, page, setSearch, userInfo: { permission } } = this.props;

        return (
            <div className="row">
                <div className="col-sm-12 pt-2">
                    <div className="grid-container">
                        <div className="grid-title d-flex flex-column flex-sm-row justify-content-center align-items-stretch align-items-sm-center">
                            <div className="flex-1 d-flex">
                                <h1 className="mx-auto">
                                    <strong>Vehicles</strong>
                                </h1>
                            </div>
                            {permission && permission.find(({ createVehicles }) => !!createVehicles) && (
                                <Link
                                    className="btn btn-primary ml-auto"
                                    to={settingsRatesVehiclesNew}
                                    style={{ padding: "0.375rem", borderRadius: "50%" }}
                                >
                                    <IconPlus className="smallIcon p-1"/>
                                </Link>
                            )}
                        </div>

                        <VehicleLocationModal
                            isOpen={this.state.modalProfileVehicle}
                            close={()=>{
                                this.setState({ modalProfileVehicle: false, vehicleId: undefined });
                                this.props.clearDataModalVehicleLocation()
                            }}
                            onSubmit={this.props.applyProfile}
                            getLocationsVehicle={this.props.getLocationsVehicle}
                            id={this.state.vehicleId}
                            vehicleLocationFormValues={this.props.vehicleLocationFormValues}
                        />

                        <br />
                        {permission && permission.find(({ readVehicles }) => !!readVehicles) && (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-12 col-md-7 col-lg-6 px-0 px-md-3">
                                        <Search onSubmit={setSearch}/>
                                        <br style={{ border: 0,  }}/>
                                    </div>
                                </div>
                                <Table
                                    onPageChange={find}
                                    data={data}
                                    page={page}
                                    loading={loading}
                                >
                                    <TableHeaderColumn
                                        tdStyle={BreakLine}
                                        thStyle={BreakLine}
                                        dataField="_id"
                                        isKey
                                        className="hide-column-header"
                                        dataFormat={(cell, row) =>
                                            <div className="d-flex flex-column flex-sm-row justify-content-between">
                                                <div className="col-12 col-sm-4 col-md-3 col-lg-2 d-flex justify-content-center">
                                                    {row.photo && (
                                                        <img src={`${BACKEND_URL}${row.photo}`}
                                                            style={{
                                                                backgroundColor: "transparent",
                                                                border: 0,
                                                                maxWidth: "13rem",
                                                                width: "100%"
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div className="d-flex flex-column-reverse flex-sm-column flex-md-row-reverse pl-0 pl-sm-5" style={{ flex: 1 }}>
                                                    <div className="d-flex justify-content-center justify-content-sm-end">
                                                        {activeFormatter({
                                                            editar: permission.find(({ updateVehicles }) => !!updateVehicles) ? settingsRatesVehicles : undefined,
                                                            eliminar: permission.find(({ removeVehicles }) => !!removeVehicles) ? destroy : undefined,
                                                            adicional: this.profileLocationBtn
                                                        })(cell, row)}
                                                    </div>
                                                    <div className="d-flex flex-column flex-lg-row pb-3 pb-sm-0" style={{ flex: 1 }}>
                                                        <div className="d-flex flex-column px-4 justify-content-end justify-content-lg-center"
                                                            style={{ flex: 1 }}
                                                        >
                                                            <div className="d-flex justify-content-center justify-content-sm-start">
                                                                <strong> Type:&nbsp;</strong>
                                                                {row.type && row.type}
                                                            </div>
                                                            <div className="d-none d-lg-flex justify-content-lg-start">
                                                                <strong> Description:&nbsp;</strong>
                                                                {row.description && row.description}
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column px-4 justify-content-lg-center" style={{ flex: 1 }}>
                                                            <div className="d-flex justify-content-center justify-content-sm-start">
                                                                <strong> Class:&nbsp;</strong>
                                                                {row.class && row.class}
                                                            </div>
                                                            <div className="d-flex justify-content-center justify-content-sm-start">
                                                                <strong> Base price:&nbsp;</strong>
                                                                <RenderCurrency value={row.defaultRate ? row.defaultRate : 0}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    />
                                </Table>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
