import { handleActions } from 'redux-actions';
import app from "../../libs/apiClient";
import Swal from 'sweetalert2';
import { getDiscount } from './itinerary';
import { initialize } from 'redux-form';

// InitialState
const initialState = {
    vehicles: [],
    vehicle: [],
    loader: false,
    activeVehicle: '1',
};

// actionTypes
export const actionTypes = {
    LOADER: 'VEHICLE_LOADER',
    VEHICLES: 'VEHICLES',
    VEHICLE: 'VEHICLE',
    SET_ACTIVE_VEHICLE: 'SET_ACTIVE_VEHICLE',
};

export const loadVehicles = () => (dispatch, getStore) => {
    const {
        from,
        to,
        itinerary: { duration, schedule, time, bookingCode },
    } = getStore().itinerary;

    const query = {
        state: from.state,
        city: from.city,
        postal_code: from.postal_code,
        latFrom: from.lat,
        lngFrom: from.lng,
        latTo: to.lat,
        lngTo: to.lng,
        to_city: to.city,
        to_state: to.state,
        to_postal_code: to.postal_code,
        schedule,
        timeHour: time.hour,
        promoCode: bookingCode,
        timeMinutes: time.minutes
    };
    const errorMsg = {
        title: 'This is outside of our standard service area',
        html:
            'Please call <a href="tel:1-866-222-7433">1-866-222-7433</a> to get your request booked',
        type: 'warning',
        showConfirmButton: false,
        allowOutsideClick: false,
        footer: '<a href="/">Return to Book Now</a>',
    };

    if (duration) query.duration = duration;
    dispatch(setLoader(true));

    const errorHandler = err => {
        console.log(err)
        if (err.status === 422)
            Swal.fire({
                html:
                    'This trip requires a specialist to book. Please contact our sales team for more information - <a href="/contact">Contact Form</a>',
                type: 'warning',
                showConfirmButton: false,
                allowOutsideClick: false,
                confirmButtonText: 'Yes, delete it!',
                footer: '<a href="/">Return to Book Now</a>',
            });
    };

    app.service('/api/distance').find({ query }).then(res => {
        if (res) {
            if (!res.data.length) Swal.fire(errorMsg);
            else {
                dispatch( setVehicles( res.data.map(items => items.vehicleBaseRates).flat(), 0 ) );
            }
        }
    })
    .catch(errorHandler)
    .finally(() => {
        dispatch(setLoader(false));
    });
};

export const setVehicle = (vehicle, index) => async (dispatch, getStore) => {
    const { bookingCode } = getStore().itinerary;
    const selected = [...getStore().vehicle.vehicle];

    selected[0] = vehicle;

    dispatch({
        type: actionTypes.VEHICLE,
        vehicle: selected,
    });

    if(bookingCode && bookingCode.code && bookingCode.code.length > 0 && bookingCode.discount > 0){
        await dispatch(getDiscount(bookingCode.code))
    }

    dispatch(setActiveVehicle(`1`));

    return true;
};

// PURE ACTIONS
export const setVehicles = (vehicles, index) => ({
    type: actionTypes.VEHICLES,
    vehicles,
    index,
});

export const setLoader = loader => ({
    type: actionTypes.LOADER,
    loader,
});

export const setActiveVehicle = activeVehicle => ({
    type: actionTypes.SET_ACTIVE_VEHICLE,
    activeVehicle,
});

export const actions = {
    loadVehicles,
    setLoader,
    setVehicle,
    setActiveVehicle,
};

// REDUCERS
export const reducers = {
    [actionTypes.VEHICLES]: (state, { vehicles, index }) => {
        let newVehicles = [...state.vehicles];
        newVehicles[index] = vehicles;
        return {
            ...state,
            vehicles: newVehicles,
        };
    },
    [actionTypes.VEHICLE]: (state, { vehicle }) => ({ ...state, vehicle }),
    [actionTypes.LOADER]: (state, { loader }) => ({ ...state, loader }),
    [actionTypes.SET_ACTIVE_VEHICLE]: (state, { activeVehicle }) => ({ ...state, activeVehicle }),
};

export default handleActions(reducers, initialState);
