import React from 'react';
import numeral from 'numeral';

const GridMonths = ({
    reportMonths,
    loadingReportMonths,
    openBookingWithMonth,
}) => (
    <React.Fragment>
        {loadingReportMonths ? (
            <div className="col-12 d-flex justify-content-center my-5">
                <div className="spinner-border" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        ) : (
            <div className="row">
                {reportMonths && reportMonths.data && reportMonths.data.map( (month, index) => (
                    <div key={index} className="card col-6 col-md-3 col-lg-2 py-3"
                        style={{ border: "1px solid #3B3B3B", cursor: "pointer" }}
                        onClick={()=>{
                            openBookingWithMonth(month.month);
                        }}
                    >
                        <h5 className="t-center"> {month.month} </h5>
                        <h6 className="t-center"> Trips booked </h6>
                        <h6 className="t-center bold"> {month.tripsBooked} </h6>
                        <h6 className="t-center"> Total Revenue </h6>
                        <h6 className="t-center bold">
                            {month.revenue ? `$ ${numeral(month.revenue).format("0,0.00")}` : '$ 0.00'}
                        </h6>
                    </div>
                ))}
            </div>
        )}
    </React.Fragment>
);

export default GridMonths;