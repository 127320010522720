import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { renderCurrency, renderField, renderSearchSelect, renderSelectField } from "../../Utils/renderField/renderField";


const Form = ({
    handleSubmit,
    getHotels,
    cancel,
    getAirlines,
    getAirportsMeet,
    showBtnNewHotel = false,
    onClickNewHotel = () => {},
}) => {

    const [airline, setAirline] = useState(undefined);

    return (
        <div className="mt-4 col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
            <form onSubmit={handleSubmit}>
                <div className="form-group grid-container">
                    <div className="grid-titulo padding-15">

                        <div className="form-group col-12 mt-4">
                            <h2 className="mb-3">
                                <strong>Route</strong>
                            </h2>
                        </div>

                        <div className="padding-15 p-sm-0 py-sm-1 col-12">
                            <div className="row">
                                <div className="form-group col-12">
                                    <label> Company Name* </label>
                                    <Field
                                        allKey
                                        name="airline"
                                        component={renderSearchSelect}
                                        loadOptions={getAirlines}
                                        myChange={(e)=>setAirline(e)}
                                        clearable={false}
                                        valueKey="_id"
                                        labelKey="label"
                                        placeholder="Select..."
                                    />
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="form-group col-12">
                                    <label htmlFor="airportMeet"> Airport </label>
                                    <Field
                                        allKey
                                        key={airline && airline._id}
                                        name="airportMeet"
                                        component={renderSearchSelect}
                                        loadOptions={getAirportsMeet}
                                        valueKey="label"
                                        labelKey="label"
                                        label="Airport"
                                        placeholder="Select"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="hotel"> Hotel </label>
                                    <div className="d-flex">
                                        <div className="flex-1">
                                            <Field
                                                allKey
                                                name="hotel"
                                                component={renderSearchSelect}
                                                loadOptions={getHotels}
                                                valueKey="_id"
                                                labelKey="label"
                                                label="Airline"
                                                placeholder="Select hotel"
                                            />
                                        </div>
                                        {showBtnNewHotel && (
                                            <button type="button" className="btn btn-link" onClick={onClickNewHotel}>
                                                Add New
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="airline_airportDescription"> Schedule Airport Description </label>
                                    <Field
                                        name="airline_airportDescription"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="airline_hotelDescription"> Schedule Hotel Description </label>
                                    <Field
                                        name="airline_hotelDescription"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group col-12">
                                    <label htmlFor="hotel_pickUpLocation"> Hotel Pick Up Location </label>
                                    <Field
                                        name="hotel_pickUpLocation"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="group_code"> Group Code </label>
                                    <Field
                                        name="group_code"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="fixed_rate"> Rate 1 </label>
                                    <Field
                                        name="fixed_rate"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="fixed_rate2"> Rate 2 </label>
                                    <Field
                                        name="fixed_rate2"
                                        component={renderCurrency}
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-12 col-md-4">
                                    <label htmlFor="pax_for_rate2"> Count </label>
                                    <Field
                                        name="pax_for_rate2"
                                        component={renderField}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="hours"> Duration </label>
                                    <Field
                                        name="hours"
                                        component={renderField}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group col-12">
                                    <label htmlFor="active"> Active/Inactive </label>
                                    <Field
                                        name="active"
                                        component={renderSelectField}
                                        options={[
                                            { label: "Active", value: true },
                                            { label: "Inactive", value: false }
                                        ]}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <button
                                            type="button"
                                            onClick={cancel}
                                            className="btn btn-link m-1"
                                        >
                                            Cancel
                                        </button>
                                        <button type="submit"
                                            className="btn btn-primary m-1"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

const AirlineRouteForm = reduxForm({
    form: "airlineRouteForm",
    validate: (data) => {
        const errors = {}
            if(!data.airline) errors.airline = "Required field";
            if(!data.airportMeet) errors.airportMeet = "Required field";
            if(!data.hotel) errors.hotel = "Required field";
            if(!data.airline_airport_account) errors.airline_airport_account = "Required field";
            if(!data.airline_airportDescription) errors.airline_airportDescription = "Required field";
            if(!data.airline_hotelDescription) errors.airline_hotelDescription = "Required field";
            if(!data.group_code) errors.group_code = "Required field";
            if(!data.fixed_rate) errors.fixed_rate = "Required field";
            if(!data.hours) errors.hours = "Required field";
            if(data.active === undefined) errors.active = "Required field";
            if(data.pax_for_rate2 || data.fixed_rate2){
                if(!data.fixed_rate2) errors.fixed_rate2 = "Required field";
                if(!data.pax_for_rate2) errors.pax_for_rate2 = "Required field";
            }
        return errors;
    }
})(Form);

export default AirlineRouteForm;
