import { validatorFromFunction } from "validate-redux-form";
import cardValidator from 'card-validator';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import moment from "moment";
import isEmpty from "lodash.isempty";

export const future = (date) =>
    validatorFromFunction((value) => {
        if (date && value instanceof moment) {
            return value.isAfter(date);
        }

        return true;
    });

export const image = validatorFromFunction((value, photo) => {
    if (photo || value) return true;
});

export const required = (value) => (value ? undefined : "Required field");


export const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

export const cardValidators = validatorFromFunction(value => {
    const numberValidation = cardValidator.number(value);

    if (!numberValidation.isValid)
        return false;

    return true;
});

export const phoneValidator = validatorFromFunction((value = '', country) => {
    if(!country)
        return false;

    const phoneNumber = parsePhoneNumberFromString(`${value}`, country);
    if (!phoneNumber)
        return false;

    if (!phoneNumber.isValid())
        return false;

    return true;
});

export const hourValidator = validatorFromFunction((value, date) => {
    if (!value)
        return false;

    if (date && moment(date).isSame(moment(), 'day') ) {
        const hour = moment().set({hour:value.hour, minute: value.minutes});
        if (moment().isAfter(hour))
            return false;
    }

    return true;
});

export const validateAddress = validatorFromFunction((value, address) => {
    if(!address || !(address instanceof Object) || isEmpty(address))
        return false;

    return address.state && address.city && address.lat && address.lng && address.postal_code
})