import React from 'react';
import { connect } from 'react-redux';
import IconWatch from '../../../../../icons/watch';
import { actions } from '../../../../../../redux/modules/crewAdmin/accountGroups';
import Modal from 'react-responsive-modal';
import Loader from "react-loader-spinner";
import LoadMask from '../../../../Utils/LoadMask';
import moment from 'moment';
import Pagination from '../../../../Utils/pagination';
import { change } from 'redux-form';
import { Link } from 'react-router-dom';
import { IconTrashAlt } from "../../../../../icons/trash";
import swal from "sweetalert2";
import { getFullPathWithParams, getPathWithParams, settingsAirlinesAccountsIdAccountGroupsId, settingsAirlinesAccountsIdAccountGroupsNew } from '../../../../../../routesConfiguration/paths';

class Container extends React.Component {
    componentDidMount() {
        const { find, airlineId, page } = this.props;
        find(airlineId, page);
    }

    toDetails = (item) => {
        const { history, airlineId } = this.props;

        const path = getFullPathWithParams(
            settingsAirlinesAccountsIdAccountGroupsId,
            ["airlineId", "id"],
            [airlineId, item._id]
        );
        history.push(path);
    };

    remove = (id) => {
        swal.fire({
            type: "warning",
            html:
                "<h3> Are you sure you want to delete this account group? </h3>",
            showCancelButton: true,
            confirmButtonColor: "#D50032",
            reverseButtons: true,
            cancelButtonText: "CANCEL",
            confirmButtonText: "DELETE",
        }).then(({ value }) => {
            if (value) {
                const { destroy, airlineId } = this.props;
                destroy(id, airlineId);
            }
        });
    };

    render() {
        const {
            search,
            setSearch,
            data,
            loading,
            userInfo: { superUser, permission },
        } = this.props;

        return (
            <div className="h-100 w-100 d-flex flex-column">
                <div className="d-flex flex-wrap mb-2">
                    <div className="col-12 px-0">
                        {permission && permission.find(({ createAirlineCrew }) => !!createAirlineCrew) ? (
                            <div className="d-flex justify-content-end pb-2">
                                <Link
                                    to={getPathWithParams(
                                        settingsAirlinesAccountsIdAccountGroupsNew,
                                        "airlineId",
                                        this.props.airlineId,
                                    )}
                                    type="button"
                                    className="btn btn-primary py-1 px-2 border"
                                >
                                    ADD
                                </Link>
                            </div>
                        ) : (
                            ""
                        )}
                        <input
                            type="text"
                            className="form-control"
                            placeholder="search..."
                            value={search}
                            onChange={(event) => {
                                setSearch(this.props.airlineId, event.target.value)
                            }}
                        />
                    </div>
                </div>
                <div className="flex-grow-1">
                    {loading ? (
                        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                            <Loader
                                type="Rings"
                                color="#FB885F"
                                height="100"
                                width="100"
                            />
                            <span className="">Loading...</span>
                        </div>
                    ) : data && data.total > 0 ? (
                        <div className="w-100">
                            {data.data.map((item, index) => (
                                <div
                                    key={index}
                                    className="border border-dark rounded mb-2 small-shadow d-flex flex-wrap clickable"
                                >
                                    <div className="col-12 px-0">
                                        <div className="">
                                            <div className="flex-1 pt-2 px-3" onClick={() => this.toDetails(item)} />
                                            <div className="d-flex">
                                                <div className="flex-1" onClick={() => this.toDetails(item)} />
                                                {permission &&
                                                permission.find(({ removeAirlineCrew }) => !!removeAirlineCrew) ? (
                                                    <div
                                                        className=""
                                                        onClick={() => this.remove(item._id)}
                                                    >

                                                        <IconTrashAlt className="smallIcon" />
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <div className="pr-3" onClick={() => this.toDetails(item)} />
                                            </div>
                                        </div>
                                        <div
                                            className="pb-3 px-1"
                                            onClick={() => this.toDetails(item)}
                                        >
                                            <div className="d-flex flex-wrap">
                                                <div className="col-6">
                                                    <strong> Contract ID: </strong>
                                                </div>
                                                <span>{item.contractId}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <div className="col-6">
                                                    <strong> Eff. Date: </strong>
                                                </div>
                                                <span>{moment(item.effectiveDate).format('MM/DD/YYYY')}</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <div className="col-6">
                                                    <strong> Exp. Date: </strong>
                                                </div>
                                                <span>{moment(item.expirationDate).format('MM/DD/YYYY')}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <Pagination
                                total={data.total}
                                page={this.props.page}
                                find={(page) => this.props.find(this.props.airlineId, page)}
                                itemsPerPage={3}
                            />
                        </div>
                        ) : (
                            <label> No Account Groups found </label>
                        )
                    }
                </div>
            </div>
        );
    }
}

const mstp = (state) => ({
    ...state.crewAdmin.accountGroups,
    userInfo: state.login.userInfo,
});

const mdtp = {
    ...actions,
    change,
};

export default connect(mstp, mdtp)(Container);
