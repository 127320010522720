import { handleActions } from "redux-actions";
import app from "../../../libs/apiClient";
import { push } from "react-router-redux";
import swal from "sweetalert2";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { errorHandler } from '../../../common/utility/constants';
import _ from "lodash";

const LOADING = "LOADING_CREW_ADMIN_LOCATIONS";
const LOADING_FORM = "LOADING_UPDATE_FORM_LOCATIONS";
const MODAL_FORM = "MODAL_FORM_LOCATIONS";
const SET_EDIT = "EDIT_VIEW_LOCATIONS";
const SET_DATA = "SET_DATA_LOCATIONS_AIRLINE";
const SET_PAGE = "SET_PAGE_LOCATIONS_AIRLINE";
const SET_SEARCH = "SET_SEARCH_LOCATIONS_AIRLINE";

export const find = (airline, page = 1) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    try {
        let params = {  query: {
            $limit: 5,
            $skip: (page - 1) * 5,
            airline,
            $populate: ['airline', 'airport', 'location']
        }};
        const search = getStore().crewAdmin.crewLocations.search;
        if(search){
            const [locations, airports] = await Promise.all([
                app.service('/api/scheduleLocation').find({ query: {
                    $paginate: false,
                    $select: ['_id', 'zip'],
                    $or: [
                        { name: { $regex: _.escapeRegExp(search), $options: 'i'}},
                        { street: { $regex: _.escapeRegExp(search), $options: 'i'}},
                        { city: { $regex: _.escapeRegExp(search), $options: 'i'}},
                        { state: { $regex: _.escapeRegExp(search), $options: 'i'}},
                    ]
                }}),
                app.service('/api/airport').find({ query: {
                    $paginate: false,
                    $select: ['_id'],
                    $or: [
                        { name: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { description: { $regex: _.escapeRegExp(search), $options: 'i' }},
                        { code: { $regex: _.escapeRegExp(search), $options: 'i' }}
                    ]
                }})
            ])
            locations.forEach(item => {item = item._id});
            airports.forEach(item => {item = item._id});
            params.query.$or = [
                { description: { $regex: _.escapeRegExp(search), $options: 'i' }},
                { airport: { $in: airports } },
                { location: { $in: locations } },
            ];
        }

        const data = await app.service('/api/scheduleAirportLocation').find(params);
        for (let i = 0; i < data.data.length; i++) {
            if (data.data[i].location && data.data[i].location.zip) {
                let [zip] = await app.service('/api/zip').find({
                    query: {
                        $paginate: false,
                        zip_code: data.data[i].location.zip,
                        $populate: ['zone'],
                    }
                });
                data.data[i].location.zip = zip;
            }
        }
        dispatch(setPage(page))
        dispatch(setData(data))
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false))
    }
};

export const create = (data) => async (dispatch, getStore) => {
    dispatch(setLoadingForm(true));
    const { page } = getStore().crewAdmin.crewLocations;
    let result = false;
    try {
        await app.service('/api/scheduleAirportLocation').create(data);
        dispatch(find(data.airline, page));
        result = true;
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false));
    }
    return result;
}

export const load2Update = (id) => async (dispatch) => {
    dispatch(setLoadingForm(true))
    try {
        let params = { query: { $populate: ['location', 'airport'] } };
        let data = await app.service('/api/scheduleAirportLocation').get(id, params);
        if(!data.location){
            data.location = data.airport;
            data.location.label = `${data.airport.code} - ${data.airport.name}`;
            delete data.airport;
        } else {
            data.location.label = `${data.location.name}, ${data.location.city}`;
        }
        dispatch(initializeForm('scheduleAirportLocationForm', data));
    } catch(error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
}

export const update = (data) => async (dispatch, getStore) =>{
    dispatch(setLoadingForm(true));
    const { page } = getStore().crewAdmin.crewLocations;
    let result = false;
    try{
        await app.service('/api/scheduleAirportLocation').patch(data._id, data);
        dispatch(find(data.airline, page));
        result = true;
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoadingForm(false))
    }
    return result;
}

export const destroy = (id) => async (dispatch, getStore) => {
    dispatch(setLoading(true));
    let result = false;
    let { crewLocations: { page }, airlines: { item } } = getStore().crewAdmin;
    try {
        await app.service('/api/scheduleAirportLocation').remove(id);
        dispatch(find(item._id, page));
        result = true;
    } catch (error) {
        dispatch(errorHandler(error));
    } finally {
        dispatch(setLoading(false));
    }
    return result;
};

export const setSearch = (airline, search) => (dispatch, getStore) => {
    dispatch({ type: SET_SEARCH, search });
    dispatch(find(airline));
}

export const setModalForm = (modalForm) => (dispatch) =>{
    if(modalForm == false)
        dispatch(destroyForm('scheduleAirportLocationForm'));
    dispatch({ type: MODAL_FORM, modalForm });
}

export const setEdit = id => dispatch => {
    if(id)
        dispatch(load2Update(id));
    else
        dispatch(destroyForm('scheduleAirportLocationForm'));
    dispatch({ type: SET_EDIT, edit: id });
}

export const setLoading = (loading) => ({ type: LOADING, loading });
export const setLoadingForm = (loading) => ({ type: LOADING_FORM, loading });
export const setPage = (page) => ({ type: SET_PAGE, page });
export const setData = (data) => ({ type: SET_DATA, data });

export const actions = {
    find,
    create,
    setEdit,
    setSearch,
    load2Update,
    setModalForm,
    update,
    destroy,
}

const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_PAGE]: (state, { page }) => ({ ...state, page }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [LOADING_FORM]: (state, { loading }) => ({ ...state, loadingForm: loading }),
    [MODAL_FORM]: (state, { modalForm }) => ({ ...state, modalForm }),
    [SET_EDIT]: (state, { edit }) => ({ ...state, edit }),
    [SET_SEARCH]: (state, { search }) => ({...state, search }),
};

const initialState = {
    loading: false,
    loadingForm: false,
    modalForm: false,
    edit: undefined,
    page: 1,
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    search: undefined,
};

export default handleActions(reducers, initialState);
