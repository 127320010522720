import React from "react";
import LoadMask from "../../Utils/LoadMask";
import ItemCart from '../ItemCart';
import IconEmail from "../../../icons/email";
import numeral from 'numeral';


const CreateView = (props) => {

    const { onSubmit, loading, sendEmail, checkout, trips, itinerary, removeTrip, editPassenger, editTrip, history,
        loadingQuoteCart, setSpecialNotes, removeQuote, isUpdate, item, permission
    } = props;

    const getAverage = (trips, discount = false) => {
        let sum = trips.reduce((acum, { vehicle }) => acum + (vehicle ? parseFloat(discount ? vehicle.discountPercent : vehicle.gratuityPercent) : 0), 0);
        const avg = sum > 0 ? sum / trips.length : 0;
        return avg.toFixed(2);
    }

    return (
        <div className="d-flex flex-wrap" style={{ fontSize: '14px' }}>
            <div className="d-flex flex-column flex-md-row w-100">
                {isUpdate && item && item._id && permission && permission.find(({removeReservations}) => !!removeReservations)
                    ? <div className="d-flex flex-column flex-md-row">
                        <button type="button" className="btn btn-warning mr-md-2 my-2" onClick={()=>removeQuote(item._id)}>
                            Delete
                        </button>
                    </div>
                    : ''
                }
                <div className="flex-1 d-flex flex-md-row flex-column justify-content-end">
                    <button
                        type="button"
                        className="btn btn-link mr-md-2 my-2 color-primary"
                        onClick={() => history.goBack()}
                    >
                        cancel
                    </button>
                    <button type="button" className="btn btn-primary ml-md-2 my-2"
                        onClick={onSubmit}
                        disabled={trips.length === 0}
                    >
                        Save
                    </button>
                </div>
            </div>
            <div className="col-12 p-1 d-flex flex-wrap border border-dark">
                <div className="w-100 d-flex justify-content-center">
                    <strong> Cart </strong>
                </div>
                <hr className="w-100 border-dark"/>
                <div className="w-100">
                    <LoadMask loading = {loadingQuoteCart} blur>
                        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                            {trips && trips.length > 0 ? trips.map(( trip, index ) =>
                                <ItemCart
                                    key={index}
                                    trip={trip}
                                    index={index}
                                    total={trips.length}
                                    edit={()=>editTrip(index)}
                                    itinerary={itinerary}
                                    editPassenger={()=>editPassenger(index)}
                                    remove={()=>removeTrip(index)}
                                    setSpecialNotes={()=>setSpecialNotes(index)}
                                />
                            ) : ''}
                        </div>
                        {trips.length > 0
                            ? <hr className="w-100 border-dark"/>
                            : ''
                        }
                        <div className="d-flex p-2 flex-column">
                            <div className="d-flex justify-content-between">
                                <strong>Subtotal:&nbsp;</strong>
                                ${numeral(trips.reduce((acum, { vehicle }) => acum + (vehicle ? vehicle.tripPrice + vehicle.totalTolls : 0), 0)).format('0,00.00')}
                            </div>
                            <div className="d-flex justify-content-between">
                                <strong>Gratuity ({getAverage(trips)}%):&nbsp;</strong>
                                ${numeral(trips.reduce((acum, { vehicle }) => acum + (vehicle ? vehicle.gratuity : 0), 0)).format('0,00.00')}
                            </div>
                            <div className="d-flex justify-content-between">
                                <strong>Discount ({getAverage(trips, true)}%):&nbsp;</strong>
                                ${numeral(trips.reduce((acum, { vehicle }) => acum + (vehicle ? vehicle.discount : 0), 0)).format('0,00.00')}
                            </div>
                            <div className="d-flex justify-content-between">
                                <strong>Total:&nbsp;</strong>
                                ${numeral(trips.reduce((acum, { vehicle }) => acum + (vehicle ? vehicle.price : 0), 0)).format('0,00.00')}
                            </div>
                        </div>
                        <div className="d-flex justify-content-end p-2">
                            <button type="button" style={{ borderColor: '#c7c9c7' }}
                                className="btn btn-outline-primary px-4 py-3 m-1"
                                onClick={()=>sendEmail()}
                                disabled={trips.length === 0}
                            >
                                <IconEmail className="smallIcon" />
                                &nbsp;<b>Email</b>
                            </button>
                            <button type="button"
                                className="btn btn-primary px-4 py-3 m-1"
                                onClick={checkout}
                                disabled={trips.length === 0}
                            >
                                <b>Checkout</b>
                            </button>
                        </div>
                    </LoadMask>
                </div>
            </div>
        </div>
    )
};

export default CreateView;
