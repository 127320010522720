import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import login from "./modules/login";
import locations from "./modules/locations";
import vehicleLocations from './modules/vehicleLocations';
import zipCodesLocations from './modules/zipCodesLocations';
import specialRatesLocations from './modules/specialRatesLocations';
import promoCodesLocations from './modules/promoCodesLocations';
import pointToPoint from './modules/pointToPoint';
import reservations from "./modules/reservations";
import legacyReservations from "./modules/legacy-reservations";
import vehicles from "./modules/vehicles";
import users from "./modules/users";
import clients from "./modules/clients";
import promoCodes from "./modules/promoCodes";
import tours from "./modules/tours";
import message from "./modules/message";
import tourReservations from "./modules/tourReservations";
import revenue from "./modules/revenue";
import blogPost from "./modules/blogPosts";
import refund from "./modules/refund";
import legacyRefund from "./modules/legacy-refund";
import dashboard from "./modules/dashboard";
import additionalCharges from './modules/additionalCharges';
import tripEdition from './modules/trip-edition';
import legacyTripEdition from './modules/legacy-trip-edition';
// import crewAdmin from './modules/crewAdmin';
import airlines from './modules/crewAdmin/airlines';
import airports from './modules/crewAdmin/airports';
import contacts from './modules/crewAdmin/contacts';
import scheduleLocations from './modules/crewAdmin/locations';
import accounts from './modules/crewAdmin/accounts';
import crewLocations from './modules/crewAdmin/crewLocations';
import crewRoutes from './modules/crewAdmin/crewRoutes';
import accountGroups from './modules/crewAdmin/accountGroups';
import scheduleReport from './modules/crewAdmin/scheduleReport';
import contactRoles from './modules/crewAdmin/contactRoles';
import account_owner from './modules/crewAdmin/account_owner';
import zones from './modules/crewAdmin/zones';
import schedule from './modules/schedule';
import itinerary from './modules/itinerary';
import vehicle from './modules/vehicle';
import apply from './modules/apply';
import quote from './modules/quote';
import reviews from './modules/reviews';
import points from  './modules/points';
import roles from './modules/roles';
import shuttle from './modules/shuttle';
import liveryEdition from './modules/liveryEdition';
import airlineLivery from './modules/airlineLivery';
import airlineCrew from  './modules/arilineCrew';
import priceCheck from './modules/priceCheck';
import meetingProcedure from './modules/meetingProcedure';
import breadcrumb from "./modules/breadcrumb";

export default combineReducers({
    form: formReducer,
    login,
    routing,
    locations,
    vehicleLocations,
    zipCodesLocations,
    specialRatesLocations,
    promoCodesLocations,
    pointToPoint,
    itinerary,
    vehicle,
    vehicles,
    reservations,
    legacyReservations,
    users,
    clients,
    promoCodes,
    crewAdmin: combineReducers({
        airlines,
        airports,
        contacts,
        contactRoles,
        locations: scheduleLocations,
        accounts,
        crewLocations,
        crewRoutes,
        accountGroups,
        account_owner,
        scheduleReport,
        zones,
    }),
    meetingProcedure,
    priceCheck,
    airlineLivery,
    schedule,
    tours,
    apply,
    message,
    tripEdition,
    legacyTripEdition,
    tourReservations,
    additionalCharges,
    liveryEdition,
    airlineCrew,
    revenue,
    blogPost,
    refund,
    legacyRefund,
    quote,
    roles,
    dashboard,
    shuttle,
    reviews,
    points,
    breadcrumb,
});
