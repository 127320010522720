import React from "react";
import moment from "moment";
import numeral from "numeral";
import { Link } from "react-router-dom";


const Detail = ({
    item: {
        _id,
        code,
        customerId,
    },
    item,
    history,
    destroy
}) => (
    <div className="row">
        <div className="col-12 d-flex justify-content-between">
            <h3>
                <strong>Shuttle Detail</strong>
            </h3>
        </div>
        <div className="col-12 px-3">
            <hr/>
        </div>
        {code && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Shuttle Code</strong>
                </div>
                <div>{code}</div>
            </div>
        )}
        {customerId && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Livery Customer Id</strong>
                </div>
                <div>{customerId}</div>
            </div>
        )}
        <div className="col-12 d-flex flex-md-row flex-column justify-content-betweeen mt-4">
            <div className="d-flex justify-content-start" style={{ flex: 1 }}>
                <button
                    type="button"
                    className="btn btn-secondary mt-2 mt-md-0"
                    onClick={() => history.goBack()}
                >
                    Back
                </button>
            </div>
        </div>
    </div>
);

export default Detail;
