import React from "react";
import PropTypes from "prop-types";

import Form from "../Create/BlogPostForm";
import LoadMask from "../../Utils/LoadMask";

export default class Update extends React.Component {
    static propTypes = {
        update: PropTypes.func.isRequired,
        load2Update: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { load2Update, match } = this.props;
        load2Update(match.params.id);
    }

    render() {
        const { update, photo, loading, userInfo: { permission } } = this.props;

        return (permission && permission.find(({ updateBlogPost }) => !!updateBlogPost) ? (
            <LoadMask loading={loading} dark blur>
                <Form onSubmit={update} photo={photo}/>
            </LoadMask>
        ) : null);
    }
}
