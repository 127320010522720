import { handleActions } from "redux-actions";
import { initialize as initializeForm, change, destroy as destroyForm } from "redux-form";
import { goBack } from "react-router-redux";
import swal from "sweetalert2";
import _ from "lodash";
import app from "../../libs/apiClient";
import { vehicles, stateOptions } from "../../common/utility/constants";
import moment from 'moment';

const LOADING = "LOC_LOADING";
const SET_DATA = "LOC_SET_DATA";
const SET_UPDATE_DATA = "LOC_SET_UPDATE_DATA";
const SET_VEHICLE_EDIT = "SET_VEHICLE_EDIT";
const VEHICLE_LOCATION_LOADER = "VEHICLE_LOCATION_LOADER";
const MODAL_VEHICLE_LOCATION = "MODAL_VEHICLE_LOCATION";

const service = app.service("/api/location/");
const vehicleService = app.service("/api/vehicle/");

// ------------------------------------
// Pure Actions
// ------------------------------------

export const setData = (data) => ({
    type: SET_DATA,
    data,
});

export const setUpdateData = (updateData) => ({
    type: SET_UPDATE_DATA,
    updateData,
});

const handleError = (error) => {
    console.error(error);
    let msg;
    if (typeof(error) == 'object')
        msg = error.message;
    else if(typeof(error) == 'string')
        msg = error;
    if(error.length)
        msg = 'There was an error';

    swal.fire({
        title: "Error",
        text: `${msg}`,
        type: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#D50032",
    })
}

// ------------------------------------
// Actions
// ------------------------------------

const loading = (loading = true) => (dispatch, getStore) => {
    const loader = getStore().locations.loading;
    if (loading) {
        dispatch({ type: LOADING, loading: loader + 1 });
    } else {
        dispatch({ type: LOADING, loading: loader - 1 });
    }
};

export const create = () => (dispatch, getState) => {
    // get form data
    const formData = getState().form.location.values;
    const { dataToCreateSpecialRate } = getState().locations;

    dispatch(loading());

    if(dataToCreateSpecialRate && dataToCreateSpecialRate.specialRates)
        formData.specialRates = dataToCreateSpecialRate.specialRates

    service.create(formData).then((data) => {
        // go back
        dispatch(goBack());
    })
    .catch((e) => {
        console.log("error", e);
    })
    .finally(() => {
        dispatch(loading(false));
    });
};

export const update = (values) => async (dispatch, getState) => {
    // get form data
    const { updateData } = getState().locations;

    dispatch(loading());

    //Remove the vehicles locations registries of the vehicles removed in form
    // const toRemove = updateData.vehicleBaseRates.filter( (item) =>
    //     (values.vehicleBaseRates.find(({_id}) => _id && JSON.stringify(_id) === JSON.stringify(item._id)) !== undefined) ? false : true
    // )

    // if(toRemove.length > 0){
    //     for(let i = 0; i < toRemove.length; i++){
    //         if(toRemove[i].vehicleLocation)
    //             await app.service('/api/vehicleLocation').remove(toRemove[i].vehicleLocation);
    //     }
    // }

    // Verify if there is a vehicle in the baseRate that was changed but not in the modal
    for(let index = 0; index < values.vehicleBaseRates.length; index++){
        if(values.vehicleBaseRates[index].vehicleLocation){
            if( typeof values.vehicleBaseRates[index].vehicleLocation === 'object') {
                if(JSON.stringify(values.vehicleBaseRates[index].vehicleLocation.vehicleDefault) != JSON.stringify(values.vehicleBaseRates[index].vehicle))
                    delete values.vehicleBaseRates[index].vehicleLocation
                else if(values.vehicleBaseRates[index].vehicleLocation._id){
                    await app.service('/api/vehicleLocation').patch(values.vehicleBaseRates[index].vehicleLocation._id, values.vehicleBaseRates[index].vehicleLocation)
                    values.vehicleBaseRates[index].vehicleLocation = values.vehicleBaseRates[index].vehicleLocation._id;
                } else {
                    const response = await app.service('/api/vehicleLocation').create(values.vehicleBaseRates[index].vehicleLocation);
                    values.vehicleBaseRates[index].vehicleLocation = response._id;
                }
            } else {
                const vehicleLocation = await app.service('/api/vehicleLocation').get(values.vehicleBaseRates[index].vehicleLocation)
                if(JSON.stringify(vehicleLocation.vehicleDefault) != JSON.stringify(values.vehicleBaseRates[index].vehicle))
                    await app.service('/api/vehicleLocation').remove(values.vehicleBaseRates[index].vehicleLocation);
            }
        }
    }

    service.patch(values._id, values).then(() => {
        dispatch(goBack());
    }).catch((e) => {
        console.log("error", e);
    }).finally(() => {
        dispatch(loading(false));
    });
};

export const load2Update = (id) => (dispatch) => {
    dispatch(loading());
    service.get(id, { query: { $select: ['vehicleBaseRates', 'fuelCost'] } }).then( async (response) => {
        dispatch(setUpdateData(response));
        dispatch(initializeForm("vehicleLocationsForm", response));
    }).catch((e) => {
        console.log("error get id", id, e);
    }).finally(() => {
        dispatch(loading(false));
    });
};

const openEditVehicle = (dataIndex, index) => async (dispatch, getStore) => {
    dispatch(openCloseModal(true));
    dispatch(setModalLoading(true));

    try {

        let vehicleEdit;
        if(dataIndex.vehicleLocation){
            if(typeof dataIndex.vehicleLocation === 'object')
                vehicleEdit = dataIndex.vehicleLocation;
            else
                vehicleEdit = await app.service('/api/vehicleLocation').get(dataIndex.vehicleLocation);
        } else if(dataIndex.vehicle){
            vehicleEdit = await app.service('/api/vehicle').get(dataIndex.vehicle);
            vehicleEdit.vehicleDefault = dataIndex.vehicle;
            if(vehicleEdit.features)
                delete vehicleEdit.features;
        } else
            throw 'Error on get vehicle data'

        vehicleEdit.baseRateIndex = index;
        if (!vehicleEdit.stopTime) vehicleEdit.stopTime = 0;
        dispatch(setVehicleEdit(vehicleEdit))
        dispatch(initializeForm('vehicleLocationForm', vehicleEdit));
    } catch(error){
        const msg = typeof error === 'object' ? error.message : error;
        swal.fire({
            type: "error",
            title: "ERROR!",
            text: `${msg}`
        })
        dispatch(openCloseModal(false));
    } finally {
        dispatch(setModalLoading(false));
    }
}

const updateVehicleLocation = (formData) => async (dispatch, getStore) => {
    const data = Object.assign(formData, {
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    })

    // const { updateData } = getStore().vehicleLocations;
    const { values } = getStore().form.vehicleLocationsForm;
    // const formVehicleRateData = values.vehicleBaseRates[data.baseRateIndex];

    try {
        const index = formData.baseRateIndex;
        dispatch(setModalLoading(true));
        const newValues = Object.assign({}, values);
        delete data._id;
        newValues.vehicleBaseRates[
            formData.baseRateIndex
        ].vehicleLocation = data;
        dispatch(
            change(
                "vehicleLocationsForm",
                "vehicleBaseRates",
                newValues.vehicleBaseRates
            )
        );
        if (newValues.vehicleBaseRates[index].vehicleLocation) {
            if (
                typeof newValues.vehicleBaseRates[index].vehicleLocation === "object"
            ) {
                if(JSON.stringify(newValues.vehicleBaseRates[index].vehicleLocation.vehicleDefault) != JSON.stringify(newValues.vehicleBaseRates[index].vehicle))
                    delete newValues.vehicleBaseRates[index].vehicleLocation
                else if(newValues.vehicleBaseRates[index].vehicleLocation._id){
                    await app.service('/api/vehicleLocation').patch(newValues.vehicleBaseRates[index].vehicleLocation._id, newValues.vehicleBaseRates[index].vehicleLocation)
                    newValues.vehicleBaseRates[index].vehicleLocation = newValues.vehicleBaseRates[index].vehicleLocation._id;
                } else {
                    const response = await app.service('/api/vehicleLocation').create(newValues.vehicleBaseRates[index].vehicleLocation);
                    newValues.vehicleBaseRates[index].vehicleLocation = response._id;
                }
            } else {
                const vehicleLocation = await app.service('/api/vehicleLocation').get(newValues.vehicleBaseRates[index].vehicleLocation)
                if(JSON.stringify(vehicleLocation.vehicleDefault) != JSON.stringify(newValues.vehicleBaseRates[index].vehicle))
                    await app.service('/api/vehicleLocation').remove(newValues.vehicleBaseRates[index].vehicleLocation);
            }
        }
        await service.patch(newValues._id, newValues);
        // let idVehicleLocation;
        // if( updateData.vehicleBaseRates[data.baseRateIndex] &&
        //     updateData.vehicleBaseRates[data.baseRateIndex].vehicleLocation)
        // {
        //     idVehicleLocation = updateData.vehicleBaseRates[data.baseRateIndex].vehicleLocation
        //     await app.service('/api/vehicleLocation').patch(idVehicleLocation, data, {
        //         headers: { "content-type": "multipart/form-data" },
        //     });
        // } else {
        //     delete data._id;
        //     const response = await app.service('/api/vehicleLocation').create(data, {
        //         headers: { "content-type": "multipart/form-data" },
        //     });
        //     idVehicleLocation = response._id;
        // }
        // // If exists the index in the updateData
        // if(updateData.vehicleBaseRates[data.baseRateIndex]){
        //     updateData.vehicleBaseRates[data.baseRateIndex].vehicle = formVehicleRateData.vehicle;
        //     updateData.vehicleBaseRates[data.baseRateIndex].rate = formVehicleRateData.rate;
        //     updateData.vehicleBaseRates[data.baseRateIndex].vehicleLocation = idVehicleLocation;
        // } else {
        //     updateData.vehicleBaseRates[data.baseRateIndex] = {
        //         vehicle: formVehicleRateData.vehicle,
        //         rate: formVehicleRateData.rate,
        //         vehicleLocation: idVehicleLocation,
        //     }
        // }
        // await service.patch(updateData._id, { vehicleBaseRates: updateData.vehicleBaseRates });
        // dispatch(setUpdateData(updateData));
        dispatch(closeEditVehicle());
    } catch (e) {
        handleError(e);
    } finally {
        dispatch(setModalLoading(false));
    }
}

// Create a new vehicle location for the location
const createVehicleLocation = (formData) => async (dispatch, getStore) => {
    const data = Object.assign(formData, {
        picture: {
            buffer: formData.picture,
            mimeType: formData.picture && formData.picture.type,
        },
    })

    const { values } = getStore().form.vehicleLocationsForm;
    const formVehicleRateData = values.vehicleBaseRates[data.baseRateIndex];

    try {
        dispatch(setModalLoading(true));
        let idVehicleLocation;
        if(formVehicleRateData.vehicleLocation){
            idVehicleLocation = formVehicleRateData.vehicleLocation
            await app.service('/api/vehicleLocation').patch(idVehicleLocation, data, {
                headers: { "content-type": "multipart/form-data" },
            });
        } else {
            delete data._id;
            const response = await app.service('/api/vehicleLocation').create(data, {
                headers: { "content-type": "multipart/form-data" },
            });
            idVehicleLocation = response._id;
        }
        formVehicleRateData.vehicle = formVehicleRateData.vehicle;
        formVehicleRateData.vehicleLocation = idVehicleLocation;
        dispatch(change('vehicleLocationsForm','vehicleBaseRates', values.vehicleBaseRates))
        dispatch(closeEditVehicle());
    } catch (e){
        handleError(e);
    } finally {
        dispatch(setModalLoading(false));
    }
}

// Remove the vehicle location when the field in the form is removed
// const removeBaseRate = (index, removeField) => async (dispatch, getStore) => {

//     const { values: oldValues } = getStore().form.vehicleLocationsForm;
//     const { updateData } = getStore().vehicleLocations;
//     const dataVehicle = oldValues && oldValues.vehicleBaseRates &&
//         oldValues.vehicleBaseRates[index] && oldValues.vehicleBaseRates[index]
//     const { vehicleLocation: idVehicleLocation } = dataVehicle;

//     removeField(index);

//     if(updateData && Object.keys(updateData).length > 0){
//         updateData.vehicleBaseRates.splice(index, 1);
//         dispatch(setUpdateData(updateData));
//     }

//     if(idVehicleLocation){
//         await app.service('/api/vehicleLocation').remove(idVehicleLocation);
//         if(oldValues._id){
//             const { _id: idBaseRate } = dataVehicle;
//             await service.patch(oldValues._id, { $pull: { vehicleBaseRates: { _id: idBaseRate } } })
//         }
//     }
// }

// If there was a vehicle in the base rate of the location with vehicleLocation param
// We need to verify if is the same in the db, if not, we need to update the data in the db
const verifyBaseRatesCreate = () => async (dispatch, getStore) => {

    const { values, submitSucceeded } = getStore().form.location;
    const arrayWithVehicleLocation = values && values.vehicleBaseRates
        ? values.vehicleBaseRates.filter( item => item.vehicleLocation)
        : [];

    if(!submitSucceeded && arrayWithVehicleLocation.length > 0){
        for(let index = 0; index < arrayWithVehicleLocation.length ; index ++){
            await app.service('/api/vehicleLocation').remove(arrayWithVehicleLocation[index].vehicleLocation);
        }
    }
}

const restoreDefault = (id) => async (dispatch, getStore) => {

    dispatch(setModalLoading(true));
    const { vehicleEdit, updateData } = getStore().vehicleLocations;
    const { values } = getStore().form.vehicleLocationsForm;

    try {
        await app.service('/api/vehicleLocation').remove(id);
        if(values._id){
            delete values.vehicleBaseRates[vehicleEdit.baseRateIndex].vehicleLocation;
            await service.patch(values._id, { vehicleBaseRates: values.vehicleBaseRates });
            dispatch(change('vehicleLocationsForm', 'vehicleBaseRates', values.vehicleBaseRates));
        }
        if(updateData && Object.keys(updateData).length > 0){
            delete updateData.vehicleBaseRates[vehicleEdit.baseRateIndex].vehicleLocation;
            dispatch(setUpdateData(updateData));
        }
        dispatch(openCloseModal(false));
    } catch (error) {
        console.error(error)
    } finally {
        dispatch(setModalLoading(false));
    }
}

const getVehicles = (search) => (dispatch, getStore) => {
    const { values = {} } = getStore().form.location;
    return new Promise( async (res, rej) => {
        const vehicles = [];
        if(values.vehicleBaseRates){
            try {
                let foundSearch = [];
                if(search){
                    let query = { query: { type: { $regex: _.escapeRegExp(search), $options: 'i' } }}
                    let response = await vehicleService.find(query);
                    if(response && response.total > 0)
                        for(let index = 0; index < response.total; index++)
                            foundSearch.push(response.data[index]);
                }

                for(let index = 0; index < values.vehicleBaseRates.length; index++){
                    if(foundSearch.length > 0){
                        let data = foundSearch.find(item => item._id === values.vehicleBaseRates[index].vehicle)
                        if(data)
                            vehicles.push(data)
                    } else {
                        let vehicle = await vehicleService.get(values.vehicleBaseRates[index].vehicle);
                        vehicles.push(vehicle);
                    }
                }
            } catch (e) {
                rej(e);
            }
        }
        res(vehicles);
    })
}

const clear = () => (dispatch) => {
    dispatch(setUpdateData({}));
    dispatch(setDataToCreate({}));
}

const closeEditVehicle = () => (dispatch) => {
    dispatch(setVehicleEdit({}))
    dispatch(openCloseModal(false));
}

const setVehicleEdit = (value) => ({
    type: SET_VEHICLE_EDIT,
    vehicleEdit: value,
})

const setModalLoading = (value) => ({
    type: VEHICLE_LOCATION_LOADER,
    vehicleLocationLoader: value,
})

const openCloseModal = (value) => ({
    type: MODAL_VEHICLE_LOCATION,
    modalVehicleEdit: value
});

// ------------------------------------
// Reducers
// ------------------------------------

export const reducers = {
    [LOADING]: (state, { loading }) => ({ ...state, loading }),
    [SET_DATA]: (state, { data }) => ({ ...state, data }),
    [SET_UPDATE_DATA]: (state, { updateData }) => ({ ...state, updateData }),
    [SET_VEHICLE_EDIT]: ( state, { vehicleEdit }) => ({ ...state, vehicleEdit }),
    [MODAL_VEHICLE_LOCATION]: ( state, { modalVehicleEdit }) => ({ ...state, modalVehicleEdit }),
    [VEHICLE_LOCATION_LOADER]: ( state, { vehicleLocationLoader }) => ({ ...state, vehicleLocationLoader }),
};

export const initialState = {
    data: {
        total: 0,
        limit: 10,
        skip: 0,
        data: [],
    },
    loading: 0,
    updateData: {},
    vehicleEdit: {},
    loaderReport: false,
    modalVehicleEdit: false,
    vehicleLocationLoader: false,
};

export const actions = {
    clear,
    create,
    update,
    load2Update,
    getVehicles,
    restoreDefault,
    // removeBaseRate,
    openEditVehicle,
    verifyBaseRatesCreate,
    updateVehicleLocation,
    createVehicleLocation,
    closeEditVehicle,
};

export default handleActions(reducers, initialState);
