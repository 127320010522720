import React from "react";
import moment from "moment";
import numeral from "numeral";

import { Link } from "react-router-dom";
import AssignmentConfirmation from "./AssignmentConfirmation";
import { formatAuthorizeTransactionStatus } from "../../../utility/constants";
import { getPathWithParams, refundId, reservationsCharterBookingsEditTripId } from "../../../../routesConfiguration/paths";
const reloadSvg = require("../../../../../assets/img/icons/redo.svg");

const ReservationDetail = ({
    reservation: {
        _id,
        userId,
        liveryNo,
        puAddress,
        doAddress,
        puTime,
        duration,
        hourlyDuration,
        flightDetails,
        specialNotes,
        airline,
        flightNo,
        nameSign,
        driverNote,
        promoCode,
        guestFirstNm,
        guestLastNm,
        guestMobile,
        guestEmail,
        quoteId,
        transactionStatus,
        vehicleDescription,
        transactionId,
        confirmationNo,
        transactionAmount,
        puTimeNoTimezone,
        rewardPoints,
        canceled,
        points,
        refund,
    },
    setAssigned,
    handleAction,
    history,
    userInfo: { permission },
    resendEmail,
    destroy
}) => (
    <div className="row">
        {/* {canceled && (
            <div className="col-12 p-4 mb-5 d-flex align-items-center justify-content-center"
                style={{borderRadius: "10px", color: "white", backgroundColor: "#D52732"}}
            >
                <p className="my-0">
                    The trip {confirmationNo} has been canceled. Refund Amount: $ {numeral(refund[0].refundAmount).format("0,0.00")} Transaction ID: {refund[0].transactionId}
                </p>
            </div>
        )} */}
        <div className="col-12 d-flex justify-content-between">
            <h3>
                <strong>Reservation Detail</strong>
            </h3>
        </div>
        <div className="col-12 px-3">
            <hr/>
        </div>
        {confirmationNo && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Confirmation Number</strong>
                </div>
                <div>{confirmationNo}</div>
            </div>
        )}
        {quoteId && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Quote No.</strong>
                </div>
                <div>{quoteId.quoteNo}</div>
            </div>
        )}
        {vehicleDescription && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Vehicle</strong>
                </div>
                <div>{vehicleDescription}</div>
            </div>
        )}
        {puAddress && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Pick up Address</strong>
                </div>
                <div>{puAddress}</div>
            </div>
        )}
        {doAddress && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Drop off Address</strong>
                </div>
                <div>{doAddress}</div>
            </div>
        )}
        {puTime && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Pick up Date</strong>
                </div>
                <div>{puTimeNoTimezone ?
                    moment.parseZone(puTimeNoTimezone).format("MM/DD/YYYY"):
                    moment.parseZone(puTime).format("MM/DD/YYYY")
                }</div>
            </div>
        )}
        {puTime && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Pick-up Time</strong>
                </div>
                <div>
                    {puTimeNoTimezone ?
                        moment.parseZone(puTimeNoTimezone).format("hh:mm A"):
                        moment.parseZone(puTime).format("hh:mm A")
                    }
                </div>
            </div>
        )}
        {duration && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Trip Duration</strong>
                </div>
                <div>{duration}</div>
            </div>
        )}
        <div className="col-sm-6 p-3">
            <div>
                <strong>Hourly Duration</strong>
            </div>
            <div>{hourlyDuration ? `${hourlyDuration} Hours` : "NA"}</div>
        </div>
        {airline && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Airline</strong>
                </div>
                <div>{airline}</div>
            </div>
        )}
        {flightNo && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Flight Number</strong>
                </div>
                <div>{flightNo}</div>
            </div>
        )}
        {nameSign && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Name sign</strong>
                </div>
                <div>{nameSign}</div>
            </div>
        )}
        {driverNote && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Driver Note</strong>
                </div>
                <div>{driverNote}</div>
            </div>
        )}
        {promoCode && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Promo Code</strong>
                </div>
                <div>{promoCode}</div>
            </div>
        )}
        {guestFirstNm && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>First Name</strong>
                </div>
                <div>{guestFirstNm}</div>
            </div>
        )}
        {guestLastNm && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Last Name</strong>
                </div>
                <div>{guestLastNm}</div>
            </div>
        )}
        {guestMobile && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Mobile</strong>
                </div>
                <div>{guestMobile}</div>
            </div>
        )}
        {guestEmail && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Email</strong>
                </div>
                <div>{guestEmail}</div>
            </div>
        )}
        {specialNotes && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Additional Notes</strong>
                </div>
                <div>{specialNotes}</div>
            </div>
        )}
        {flightDetails && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Flight Notes</strong>
                </div>
                <div>{flightDetails}</div>
            </div>
        )}
        {transactionId && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Transaction Id</strong>
                </div>
                <div>{transactionId}</div>
            </div>
        )}
        {transactionAmount && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Transaction Amount</strong>
                </div>
                <div>${numeral(transactionAmount).format("0,0.00")}</div>
            </div>
        )}
        {rewardPoints && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Points used</strong>
                </div>
                <div> {rewardPoints.totalPoints} {`${rewardPoints.totalPoints === 1 ? 'pt.' : 'pts.'}`} - ${numeral(rewardPoints.pointsAmount).format("0,0.00")}</div>
            </div>
        )}
        {points && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Points to be earned</strong>
                </div>
                <div> {points.totalPoints} {`${points.totalPoints === 1 ? 'pt.' : 'pts.'}`} - ${numeral(points.totalEarned).format("0,0.00")}</div>
            </div>
        )}
        {liveryNo && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong>Livery Trip ID</strong>
                </div>
                <div>{liveryNo}</div>
            </div>
        )}
        {transactionStatus && (
            <div className="col-sm-6 p-3">
                <div>
                    <strong> Authorize Transaction Status </strong>
                </div>
                <div>{formatAuthorizeTransactionStatus(transactionStatus)}</div>
            </div>
        )}
        <div className="col-12 d-flex flex-md-row flex-column justify-content-betweeen mt-4">
            <div className="d-flex flex-column flex-md-row justify-content-start" style={{ flex: 1 }}>
                <button
                    type="button"
                    className="btn btn-secondary mt-2 mt-md-0"
                    onClick={() => history.goBack()}
                >
                    Back
                </button>
            </div>
            {permission && permission.find(({ updateReservations }) => !!updateReservations) && (
                <div className="d-flex flex-column flex-md-row justify-content-end">
                    {_id && !canceled && (
                        <React.Fragment>
                            <button type="button" className="btn btn-primary mt-2 mt-md-0 ml-md-3" onClick={()=>resendEmail(_id)}>
                                <img
                                    className="action-img border-0 p-1"
                                    title="Resend"
                                    src={reloadSvg}
                                    alt="resend-email"
                                    style={{ background: "transparent" }}
                                />
                                Resend Confirmation
                            </button>
                            <Link
                                to={getPathWithParams(
                                    reservationsCharterBookingsEditTripId,
                                    "id",
                                    _id,
                                )}
                                className="btn btn-warning mt-2 mt-md-0 ml-md-3 d-flex justify-content-center align-items-center"
                            >
                                Edit
                            </Link>
                            <Link
                                to={getPathWithParams(refundId, "id", _id)}
                                className="btn btn-primary mt-2 mt-md-0 ml-md-3 d-flex justify-content-center align-items-center"
                            >
                                Cancel
                            </Link>
                        </React.Fragment>
                    )}

                    {!liveryNo && !canceled &&(
                        <button
                            type="button"
                            className="btn btn-primary mt-2 mt-md-0 ml-md-3"
                            onClick={() =>
                                AssignmentConfirmation(setAssigned, history.replace)
                            }
                        >
                            Confirm Assigment
                        </button>
                    )}
                </div>
            )}
        </div>
        {refund && (
            <div className="col-12 p-3">
                <h5 style={{fontWeight:"bold"}}> Refunds  </h5>
                <hr/>
                <table className="table table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th> Transaction ID </th>
                            <th> Refund amount </th>
                            <th> Agent Notes </th>
                        </tr>
                    </thead>
                    <tbody>
                        {refund.map((item,index)=>{
                            return (
                                <tr key={item._id}>
                                    <td> {item.transactionId ? item.transactionId : 'no transaction'} </td>
                                    <td> $ {numeral(item.refundAmount).format("0,0.00")} </td>
                                    <td> {item.agentNotes} </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )}
    </div>
);

export default ReservationDetail;
